import React, { useEffect, useState } from 'react';
import validator from 'validator';
import { MenuItem, TextField } from '@material-ui/core';
import { useForm } from '../../../../hooks/useForm';
import { validateRfcValid } from '../../../../helpers/validateForm';
import { useDispatch, useSelector } from 'react-redux';
import {  paymentMethodBilling, wayOfPaymentsBilling } from '../../../../actions/billing/catalogs';


const changedType = [
    {
      value: 'MXN',
      label: 'MXN - Pesos Mexicanos',
    },
    {
      value: 'USD',
      label: 'USD - Dolar Estadounidense',
    }
];

const relationCFDI = [
    {
      value:  'no',
      label: 'No',
    },
    {
      value: 'si',
      label: 'Si',
    }
];

const typeRelation = [
    {
      value: '01',
      key: '01',
      id: 1,
      label: 'Nota de crédito de los documentos relacionados',
    },
    {
      value: '02',
      key: '02',
      id: 2,
      label: 'Nota de débito de los documentos relacionados',
    },
    {
      value: '03',
      key: '03',
      id: 3,
      label: 'Devolución de mercancía sobre facturas o traslados previos',
    },
    {
      value: '04',
      key: '04',
      id: 4,
      label: 'Sustitución de los CFDI previos',
    },
    {
      value: '05',
      key: '05',
      id: 5,
      label: ' Traslados de mercancias facturados previamente',
    },
    {
      value: '06',
      key: '06',
      id: 6,
      label: 'Factura generada por los traslados previos',
    },
    {
      value: '07',
      key: '07',
      id: 7,
      label: 'CFDI por aplicación de anticipo',
    },

];

const BusinessDetails = ({ setformCompletion, formCompletion, setFormDetails, formValid }) => {

    //BusinessDetails
    const { wayOfPaymentBilling } = useSelector(state => state.wayOfPaymentBilling);
    const { paymentMethodsBillingR } = useSelector(state => state.paymentMethodBillingR);

    const dispatch = useDispatch()
    const [msgErrors, setMsgErrors] = useState({});

    const [filas, setFilas] = useState(0); // Inicialmente, tienes una fila
    const [filaData, setFilaData] = useState([]); // Array para almacenar los datos de cada fila

    const { formValues, handleInputChange } = useForm({
        serie: '',
        folio: '',
        typeChanged: '',
        wayToPay: '',
        metodPay: '',
        condition: '',
        relation: '',
        countChanged: 1,
    })

    const { serie, folio, typeChanged, wayToPay, metodPay, condition, relation, countChanged} = formValues

    useEffect(() => {
        dispatch(wayOfPaymentsBilling())
        dispatch(paymentMethodBilling())
    }, [])

    const isFormValid = () => {
        const errors = {}

        if( typeChanged.trim().length === 0 ) {
            errors['msgTypeChanged'] = "Es requerido"
        }
        if( wayToPay.trim().length === 0 ) {
            errors['msgWayToPay'] = "Es requerido"
        }
        if( metodPay.trim().length === 0 ) {
            errors['msgMetodPay'] = "Es requerido"
        }
        if( relation.trim().length === 0 ) {
            errors['msgRelation'] = "Es requerido"
        }
        if( typeChanged === '1' ) {
            if( countChanged.trim().length === 0 ) {
                errors['msgCountChanged'] = "Es requerido"
            }
        }

        if( formValid ) {
            setMsgErrors(errors)
        }

        if(Object.keys(errors).length !== 0 ){
            return false;
          }

        return true;
    }

    useEffect(() => {
        isFormValid()
    }, [formValid])

    useEffect(() => {
        const isValid = isFormValid();

        setformCompletion({
            ...formCompletion,
            businessDetails: isValid
        });
    }, [formValues]);

    useEffect(() => {
        setFormDetails(prevState => {
            return{
                ...prevState,
                Details: {
                    Serie: serie,
                    Folio: folio,
                    Currency: typeChanged,
                    KeyWayOfPayment: wayToPay,
                    KeyPaymentMethod: metodPay,
                    Change: typeChanged == 'USD' ? countChanged : 0.0,
                    WithRelationship: relation,
                    CfdiRelated: null,
                },
            }
        })
    }, [serie,folio,typeChanged, wayToPay, metodPay, countChanged, relation]);

    const handleAddRow = () => {
        // Incrementa el número de filas en 1
        setFilas(filas + 1);

        // Agrega un nuevo objeto con valores iniciales a filaData
        setFilaData([...filaData, { typeRelation: '', uuid: '' }]);
    };

    const handleEliminarFila = (index) => {
        // Crea una copia de filaData y elimina el objeto en el índice especificado
        const nuevasFilasData = [...filaData];
        nuevasFilasData.splice(index, 1);

        // Actualiza tanto filas como filaData
        setFilas(filas - 1);
        setFilaData(nuevasFilasData);
    };

    return (
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2">
            <div className='row'>
                <div className="col-xs-12 col-sm-2 mb-3">
                    <TextField
                        id="outlined-basic"
                        label="Serie"
                        name='serie'
                        variant="outlined"
                        onChange={ handleInputChange }
                        helperText='Campo Opcional'
                        fullWidth={true}
                    />
                </div>
                <div className="col-xs-12 col-sm-2 mb-3">
                    <TextField
                        id="outlined-basic"
                         type="number"
                        label="Folio"
                        name='folio'
                        variant="outlined"
                        onChange={ handleInputChange }
                        helperText='Campo Opcional'
                        fullWidth={true}
                    />
                </div>
            </div>
            <div className='row'>
                <div className="col-xs-4 col-sm-4 mb-3">
                    <TextField
                        id="outlined-select-currency"
                        select
                        label="Cambio*"
                        name='typeChanged'
                        variant="outlined"
                        onChange={ handleInputChange }
                        helperText={ msgErrors.msgTypeChanged }
                        error={ msgErrors.msgTypeChanged !== undefined }
                        fullWidth={true}
                    >
                        {changedType.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                </div>
                <div className="col-xs-4 col-sm-4 mb-3">
                    <TextField
                        id="outlined-select-currency"
                        select
                        label="Forma De Pago*"
                        name='wayToPay'
                        // defaultValue="EUR"
                        variant="outlined"
                        onChange={ handleInputChange }
                        helperText={ msgErrors.msgWayToPay }
                        error={ msgErrors.msgWayToPay !== undefined }
                        fullWidth={true}
                        SelectProps={{
                            MenuProps: {
                              PaperProps: {
                                style: {
                                  maxHeight: 150, // Altura máxima del menú desplegable
                                },
                              },
                            },
                        }}
                    >
                        {wayOfPaymentBilling.map((option) => (
                            <MenuItem key={option.id} value={option.key}>
                                { option.key } - {option.description}
                            </MenuItem>
                        ))}
                    </TextField>
                </div>
                <div className="col-xs-4 col-sm-4 mb-3">
                    <TextField
                        id="outlined-select-currency"
                        select
                        label="Metodo De Pago*"
                        name='metodPay'
                        // defaultValue="EUR"
                        variant="outlined"
                        onChange={ handleInputChange }
                        helperText={ msgErrors.msgMetodPay }
                        error={ msgErrors.msgMetodPay !== undefined }
                        fullWidth={true}
                    >
                        {paymentMethodsBillingR.map((option) => (
                            <MenuItem key={option.id} value={option.key}>
                                 {option.key} - {option.description}
                            </MenuItem>
                        ))}
                    </TextField>
                </div>
            </div>
            {
                typeChanged === 'USD' ? (
                    <div className='row'>
                        <div className="col-xs-12 col-sm-4 mb-3">
                            <TextField
                                id="outlined-basic"
                                type='number'
                                label="Tipo Cambio"
                                name='countChanged'
                                variant="outlined"
                                onChange={ handleInputChange }
                                helperText={ msgErrors.msgCountChanged }
                                error={ msgErrors.msgCountChanged !== undefined }
                                fullWidth={true}
                            />
                        </div>
                     </div>
                )
                :
                (
                    ''
                )
            }
            <div className='row'>
                <div className="col-xs-12 col-sm-12 mb-3">
                    <TextField
                        id="outlined-basic"
                        label="Condición De Pago"
                        name='condition'
                        variant="outlined"
                        onChange={ handleInputChange }
                        helperText='Campo Opcional'
                        fullWidth={true}
                        multiline
                        rows={3}
                    />
                </div>
            </div>
            <div className='row'>
                <div className="col-xs-12 col-sm-4 mb-3">
                    <TextField
                        id="outlined-select-currency"
                        select
                        label="¿Relacionar CFDI?*"
                        name='relation'
                        // defaultValue="EUR"
                        variant="outlined"
                        onChange={ handleInputChange }
                        helperText={ msgErrors.msgRelation }
                        error={ msgErrors.msgRelation !== undefined }
                        fullWidth={true}
                    >
                        {relationCFDI.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                </div>
            </div>
            {
                relation === 'si' ? (
                    <>
                        <div className='row'>
                            <button className='btn btn-success mb-2 ml-4 mt-2' onClick={ handleAddRow }> Relación <i className='fas fa-plus'></i> </button>
                        </div>
                        <div className='row'>
                            <table className="table table-bordered mt-4">
                                <thead>
                                    <tr>
                                        <th className="col-4">Tipo Relación</th>
                                        <th className="col-4">Folio Fiscal</th>
                                        <th className="col-4"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {Array.from({ length: filas }).map((_, index) => (
                                        <tr key={index}>
                                            <td>
                                                <TextField
                                                    id={`typeRelation-${index}`}
                                                    select
                                                    label="Tipo Relación*"
                                                    name={`typeRelation-${index}`}
                                                    variant="outlined"
                                                    onChange={handleInputChange}
                                                    helperText={msgErrors.msgRelation}
                                                    error={msgErrors.msgRelation !== undefined}
                                                    fullWidth={true}
                                                    SelectProps={{
                                                        MenuProps: {
                                                          PaperProps: {
                                                            style: {
                                                              maxHeight: 150, // Altura máxima del menú desplegable
                                                            },
                                                          },
                                                        },
                                                    }}
                                                >
                                                    {typeRelation.map((option) => (
                                                        <MenuItem key={option.id} value={option.label}>
                                                            {option.key} - {option.label}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                            </td>
                                            <td>
                                                <TextField
                                                    id={`outlined-basic-${index}`}
                                                    label="UUID*"
                                                    name={`typeChanged-${index}`}
                                                    variant="outlined"
                                                    onChange={handleInputChange}
                                                    fullWidth={true}
                                                />
                                            </td>
                                            <td>
                                                <button
                                                    className='btn btn-danger'
                                                    onClick={() => handleEliminarFila( index ) }
                                                >
                                                    X
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </>
                )
                :
                (
                    ''
                )
            }
        </div>
    )
}

export default BusinessDetails;
