import { combineReducers } from "redux";
import { coinReducer } from "./catalogs/coinReducer";
import { authReducer } from "./authReducer";
import { IdentifierReducer } from "./taxpayers/identifierReducer";
import { InvoiceTypeReducer } from "./invoiceTypeReducer";
import { lineItemReducer } from "./lineItemReducer";
import { SerieFolioReducer } from "./serieFolioReducer";
import { RegimeReducer } from "./catalogs/regimeReducer";
import { uiReducer } from "./uiReducer";
import { accountTypeReducer } from "./accountTypeReducer";
import { taxpayerReducer } from "./taxpayerReducer";
import { socialMediaTypeReducer } from "./catalogs/socialMediaTypeReducer";
import { ivaTraslated } from "./taxes/ivaTraslated";
import { serviceTypeReducer } from "./catalogs/serviceTypeReducer";
import { zoneTypeReducer } from "./catalogs/zoneTypeReducer";
import { localTaxeReducer } from "./invoices/localTaxeReducer";
import { cfdiRelationTypeReducer } from "./invoices/cfdiRelationTypeReducer";
import { geoReducer } from "./catalogs/geoReducer";
import { cfdiUseReducer } from "./catalogs/cfdiUseReducer";
import { thirdPartyReducer } from "./thirdPartyReducer";
import { unitTypeReducer } from "./catalogs/unitTypeReducer";
import { currencyTypeReducer } from "./catalogs/currencyTypeReducer";
import { rolesReducer } from "./catalogs/rolesReducer";
import { wayOfPaymentReducer } from "./catalogs/wayOfPaymentReducer";
import { incomeReducer } from "./accounting/incomeReducer";
import { deductionTypeReducer } from "./accounting/deductionTypeReducer";
import { expenseTypeReducer } from "./accounting/expenseTypeReducer";
import { expenseReducer } from "./accounting/expenseReducer";
import { certificateReducer } from "./taxpayers/certificateReducer";
import { validationSatReducer } from "./taxpayers/validationSatReducer";
import { IsrRifReducer } from "./accounting/IsrRifReducer";
import { reduccionIsrRifReducer } from "./accounting/reduccionIsrRifReducer";
import { ServiceTypeRetentionReducer } from "./accounting/serviceTypeRetentionReducer";
import { thirdPartyTypeReducer } from "./catalogs/thirdPartyTypeReducer";
import { downloadXMLRequestReducer } from "./accounting/downloadXMLRequestReducer";
import { IsrHonorariumReducer } from "./accounting/IsrHonorariumReducer";
import { IsrSimplifiedReducer } from "./accounting/IsrSimplifiedReducer";
import { retentionReducer } from "./accounting/retentionReducer";
import { IsrPlataformReducer } from "./accounting/IsrPlataformReducer";
import { IsrPlataformLodgingReducer } from "./accounting/IsrPlataformLodgingReducer";
import { IsrPlataformAlienationReducer } from "./accounting/IsrPlataformAlienationReducer";
import { authorizationReportReducer } from "./accounting/authorizationReportReducer";
import { productToSaleReducer } from "./catalogs/productToSaleReducer";
import { documentTaxTaxpayerTypeReducer } from "./catalogs/documentTaxTaxpayerTypeReducer";
import { receptorAccountBankReducer } from "./catalogs/receptorAccountBankReducer";
import { professionTypeReducer } from "./catalogs/professionTypeReducer";
import { crmSaleReducer } from "./crm/crmSaleReducer";
import { crmPreSaleReducer } from "./crm/crmPreSaleReducer";
import { userReducer } from "./userReducer";
import { paymentMethodReducer } from "./catalogs/paymentMethodReducer";
import { PaymentStatusReducer } from "./catalogs/PaymentStatusReducer";
import { packageReducer } from "./packages/packageReducer";
import { isrLeaseReducer } from "./accounting/isrLeaseReducer";
import { keyUnitReducer } from "./catalogs/keyUnitReducer";
import { expenseDepresiationReducer } from "./accounting/expenseDepresiationReducer";
import { accounterReducer } from "./catalogs/accounterReducer";
import { mailboxReducer } from "./mailbox/mailboxReducer";
import { paymentFrecuencieReducer } from "./catalogs/paymentFrecuencieReducer";
import { paymentGatewayReducer } from "./catalogs/paymentGatewayReducer";
import { crmSubscriptionsReducer } from "./crm/crmSubscriptionsReducer";
import { metricReducer } from "./metric/metricReducer";
import { typeRetentionReducer } from "./catalogs/typeRetentionReducer";
import { expenseClassificationsReducer } from "./catalogs/expenseClassificationsReducer";
import { activitiesReducer } from "./catalogs/activitiesReducer";
import { complementReducer } from "./accounting/complementReducer";
import { RegimeBillingReducer } from "./billing/regimeBillingReducer"
import { CfdiUsesBillingReducer } from "./billing/cfdiUsesBillingReducer"
import { WayOfPaymentsBillingReducer } from "./billing/wayOfPaymentBillingReducer"
import { PaymentMethodsBillingReducer } from "./billing/paymentMethodsBillingReducer"
import { RecordInvoicesBillingReducer } from "./billing/recordInvoicesBilling"
import { CustomersBillingReducer } from "./billing/customersBillingReducer";
import { ProductsBillingReducer } from "./billing/productsBillingReducer";
import { TaxDocumentsBilling } from "./billing/taxDocumentsBillingReducer";
import { RegisterActivateReducer } from "./billing/registerAactivateModuleReducer";
import { RegisterActivateBillReducer } from "./billing/registerActivateBillReducer";
import { UserBillinReducer } from "./billing/userBillingReducer";
import { crmPromotionsReducer } from "./crm/crmPromotionsReducer";
import { ReceiverBillingReducer } from "./billing/receiverBillingReducer";
import { activitiesByRegimeReducer } from "./configuration/activitiesByRegimeReducer"
import { regimesConfig } from "./configuration/regimesConfig";
import { preReportUpgrade } from "./configuration/preReport";
import { preReportDetailsData } from "./configuration/preReportDetail";
import { monitorDownloadData } from "./configuration/downloadXML";
import { additionalProceduresData } from "./configuration/adittionalProcedures";
import { diagnosticApiData } from "./configuration/diagnostic";
import { paymentApiData } from "./configuration/paymentValidate";
import { claveProdServReducer } from "./configuration/claveProdServReducer";
import { opinionComplementReducer } from "./opinionComplement/opinionComplementReducer";

import { allClavProdServReducer } from "./configuration/allClavProdServReducer";
import { claveProdServNotValidatedReducer } from "./configuration/claveProdServNotValidatedReducer";
import { authPartnerReducer } from "./authValidatePartner";
import { helpInstructionsReducer } from "./crm/getHelpDataCSV";

export const RootReducer = combineReducers({
  accountType: accountTypeReducer,
  accounter: accounterReducer,
  auth: authReducer,
  authorizationReport: authorizationReportReducer,
  certificate: certificateReducer,
  cfdiRelationType: cfdiRelationTypeReducer,
  cfdiUse: cfdiUseReducer,
  coin: coinReducer,
  crmSale: crmSaleReducer,
  crmPromotion: crmPromotionsReducer,
  complement: complementReducer,
  crmPreSale: crmPreSaleReducer,
  currencytype: currencyTypeReducer,
  customerBilling: CustomersBillingReducer,
  deductionType: deductionTypeReducer,
  documentTaxTaxpayerType: documentTaxTaxpayerTypeReducer,
  downloadXMLRequest: downloadXMLRequestReducer,
  expense: expenseReducer,
  expenseDepresiation: expenseDepresiationReducer,
  expenseType: expenseTypeReducer,
  expenseClassifications: expenseClassificationsReducer,
  identifier: IdentifierReducer,
  income: incomeReducer,
  invoiceType: InvoiceTypeReducer,
  invoiceBilling: RecordInvoicesBillingReducer,
  isrHonorarium: IsrHonorariumReducer,
  isrLease: isrLeaseReducer,
  isrSimplified: IsrSimplifiedReducer,
  isrPlataform: IsrPlataformReducer,
  isrPlataformAlienation: IsrPlataformAlienationReducer,
  isrPlataformLodging: IsrPlataformLodgingReducer,
  isrRif: IsrRifReducer,
  ivaTraslated: ivaTraslated,
  instructions: helpInstructionsReducer,
  keyUnit: keyUnitReducer,
  lineItem: lineItemReducer,
  localTaxe: localTaxeReducer,
  mail: mailboxReducer,
  packageReportAssisted: packageReducer,
  paymentFrecuencie: paymentFrecuencieReducer,
  paymentGateway: paymentGatewayReducer,
  paymentMethod: paymentMethodReducer,
  paymentMethodBillingR: PaymentMethodsBillingReducer,
  subscriptionSale: crmSubscriptionsReducer,
  paymentStatusType: PaymentStatusReducer,
  productToSale: productToSaleReducer,
  productBilling: ProductsBillingReducer,
  professionType: professionTypeReducer,
  receptorAccountBankType: receptorAccountBankReducer,
  reduccionIsrRif: reduccionIsrRifReducer,
  regime: RegimeReducer,
  regimeBilling: RegimeBillingReducer,
  registerBilling: RegisterActivateReducer,
  registerBills: RegisterActivateBillReducer,
  cfdiUsesBilling: CfdiUsesBillingReducer,
  retention: retentionReducer,
  rolesType: rolesReducer,
  serieFolio: SerieFolioReducer,
  serviceType: serviceTypeReducer,
  ServiceTypeRetention: ServiceTypeRetentionReducer,
  socialMediaType: socialMediaTypeReducer,
  taxpayer: taxpayerReducer,
  taxBillingDocuments: TaxDocumentsBilling,
  thirdParty: thirdPartyReducer,
  thirdPartyType: thirdPartyTypeReducer,
  ui: uiReducer,
  unitType: unitTypeReducer,
  userType: userReducer,
  userInvoice: UserBillinReducer,
  userData: ReceiverBillingReducer,
  validationSat: validationSatReducer,
  wayOfPayment: wayOfPaymentReducer,
  wayOfPaymentBilling: WayOfPaymentsBillingReducer,
  zoneType: zoneTypeReducer,
  zipCode: geoReducer,
  metric: metricReducer,
  typeRetention: typeRetentionReducer,
  activities: activitiesReducer,
  activitiesByRegimen: activitiesByRegimeReducer,
  regimesConfig: regimesConfig,
  preReportUpgrade: preReportUpgrade,
  preReportDetailsData: preReportDetailsData,
  monitorDownloadData: monitorDownloadData,
  additionalProceduresData: additionalProceduresData,
  diagnosticApiData: diagnosticApiData,
  paymentApiData: paymentApiData,
  clavProdServ: claveProdServReducer,
  allClavProdServ: allClavProdServReducer,
  clavProdServNotValidated: claveProdServNotValidatedReducer,
  opinionComplement: opinionComplementReducer,
  authPartner: authPartnerReducer
})
