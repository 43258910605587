import React, { useEffect, useState, useContext } from 'react';
import { FormHelperText, TextField } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from '../../../../hooks/useForm';
import { serviceTypesStartLoading } from '../../../../actions/catalogs/serviceTypes';
import { zoneTypesStartLoading } from '../../../../actions/catalogs/zoneTypes';
import { regimesStartLoading } from '../../../../actions/catalogs/regimes';
import { startRegisterTaxpayer, startUpdateTaxpayer } from '../../../../actions/taxpayers';
import { ModalContext } from '../../../modal/ModalContext';
import { TaxAddressForm } from '../taxAddress/TaxAddressForm';
import { validateArrayPresence, validateRfcValid, validateStringPresence, validateNumberPresence } from '../../../../helpers/validateForm';
import moment from 'moment';
import { professionTypesStartLoading } from '../../../../actions/catalogs/professions';

export const TaxpayerForm = ( { taxpayer = null } ) => {
  const { handleModal } = useContext(ModalContext)

  const { formValues, handleInputChange, handleCheckboxTrueFalseChange, handleObjectAddQuitChange,
          handleObjectAddQuitAndFlagChange, setFormValues } = useForm({
    accounting: false,
    address:{
      country: '',
      interior: '',
      outdoor: '',
      postalCode: '',
      street: '',
      suburb: '',
      town: '',
    },
    codePostal: '',
    emailNotification: '',
    heigherIncome: false,
    invoicing: false,
    bussinessName: '',
    phoneMobile: '',
    professionId:0,
    regimeSelecteds: [],
    rfc: '',
    passwordSAT: '',
    serviceType: [],
    startDeclarationAt: '',
    startSatAt: '',
    zoneType: [],
  });

  const dispatch = useDispatch();

  const { address, emailNotification, heigherIncome, bussinessName, phoneMobile,
          regimeSelecteds, rfc, accounting, invoicing, passwordSAT, serviceType,
          startDeclarationAt, startSatAt, zoneType,professionId
        } = formValues;

  const { postalCode } = address;

  const { serviceTypes } = useSelector(state => state.serviceType)
  const { zoneTypes } = useSelector(state => state.zoneType)
  const { regimes } = useSelector(state => state.regime);
  const {professionTypes} = useSelector(state => state.professionType);
  const [msgErrors, setMsgErrors] = useState({});

  useEffect(() => {
    if(taxpayer){
      let regimeSelectedEdit = [];
      let zonesTypesEdit = [];
      let serviceTypeEdit = [];
      let accountingEdit = false;
      let invoicingEdit = false;

      taxpayer.zoneTypes.map(( data ) => (
        zonesTypesEdit.push(data.id)
      ))

      taxpayer.serviceTypes.forEach(( data ) => {
        serviceTypeEdit.push(data.id)
        if(data.key === 'CONTA'){
          accountingEdit = true;
        }else if (data.key === 'FACTU'){
          invoicingEdit = true;
        }
      })

      taxpayer.regimes.map(data => (
        regimeSelectedEdit.push(
          {
            accounting: data.accounting,
            regimeId: data.id,
            invoicing: data.invoicing
          }
        )
      ));

      setFormValues(prevState =>  {
        return{
          ...prevState,
          professionId:taxpayer.profession.id,
          accounting: accountingEdit,
          address: taxpayer.addresses[0],
          emailNotification: taxpayer.emailNotification || '',
          invoicing: invoicingEdit,
          bussinessName: taxpayer.businessName,
          rfc: taxpayer.rfc,
          passwordSAT: taxpayer.passwordSAT,
          phoneMobile: taxpayer.phoneMobile,
          regimeSelecteds: regimeSelectedEdit,
          serviceType: [ ...serviceTypeEdit ],
          startDeclarationAt: moment(taxpayer.startDeclarationAt).format('YYYY-MM-DD'),
          startSatAt: moment(taxpayer.startSatAt).format('YYYY-MM-DD'),
          zoneType: [ ...zonesTypesEdit ],
        }
      })
    }
  }, [taxpayer, setFormValues])

  useEffect(() => {
    if (professionTypes.length === 0) {
      dispatch(professionTypesStartLoading())
    }
  }, [dispatch, professionTypes])

  useEffect(() => {
    if (serviceTypes.length <= 0){
      dispatch( serviceTypesStartLoading() )
    }
  }, [dispatch, serviceTypes])

  useEffect(() => {
    if (zoneTypes.length <= 0){
      dispatch( zoneTypesStartLoading() )
    }
  }, [dispatch, zoneTypes])

  useEffect(() => {
    if (regimes.length <= 0){
      dispatch( regimesStartLoading() )
    }
  }, [dispatch, regimes])

  const handleServiceTypeChange = ( e ) => {
    const data = e.target.dataset;
    let nameInput = '';

    if(data.code === 'CONTA'){
      nameInput = 'accounting';
    }else if (data.code === 'FACTU'){
      nameInput = 'invoicing';
    }

    handleObjectAddQuitAndFlagChange( e, nameInput );
  }

  const handleRegimeChange = (type, e) => {
    const { value } = e.target;

    let changeRegimeSelecteds = {}

    if (regimeSelecteds.length > 0){
      const regimeSelect = regimeSelecteds.filter(e => (e.regimeId === parseInt(value)))[0];
      if(regimeSelect){
        if(regimeSelect[type] !== undefined){
          regimeSelect[type] = !regimeSelect[type]
        }else{
          regimeSelect[type] = true;
        }
        setFormValues(prevState => {
          return{
            ...prevState,
            regimeSelecteds: prevState.regimeSelecteds.map(
              regime => (regime.regimeId === parseInt(value)) ?
              regimeSelect
              :
              regime
            )
          }
        })
      }else{
        changeRegimeSelecteds={
          regimeId: parseInt(value),
          [type]: true
        }
        setFormValues(prevState => {
          return{
            ...prevState,
            regimeSelecteds: [ ...prevState.regimeSelecteds, changeRegimeSelecteds ]
          }
        })
      }
    }else{
      changeRegimeSelecteds={
        regimeId: parseInt(value),
        [type]: true
      }
      setFormValues(prevState => {
        return{
          ...prevState,
          regimeSelecteds: [ ...prevState.regimeSelecteds, changeRegimeSelecteds ]
        }
      })
    }
  }

  const defaultCheckedSelected = ( list, id ) => {
    let flag = false;
    list.find( data => data === id && (flag = true) )
    return flag
  }

  const defaultCheckedSelectedRegime = ( id, type ) => {
    let flag = false;
    regimeSelecteds.find( data => data.regimeId === id && data[type] === true ? (flag = true) : (flag))
    return flag
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    if(isFormValid()){
      const petition = {    
        emailNotification: emailNotification,
        heigherIncome: heigherIncome,
        bussinessName: bussinessName,
        rfc: rfc.toUpperCase(),
        passwordSAT: passwordSAT,
        serviceTypes: serviceType,
        zoneTypes: zoneType,
        phoneMobile: phoneMobile,
        regimes: regimeSelecteds,
        professionId:professionId
      }

      if(accounting){
        petition['startDeclarationAt'] = moment(startDeclarationAt).format('MM/DD/YYYY');
        petition['startSatAt'] = moment(startSatAt).format('MM/DD/YYYY');
      }
      
      if(taxpayer){
        dispatch(startUpdateTaxpayer({
          ...petition,
          profileId: taxpayer.id
        }, handleModal.bind()))
      }else{
        dispatch(startRegisterTaxpayer({
          ...petition,
          address: address,
        }, handleModal.bind()))
      }
    }
  }

  const isFormValid = () => {
    setMsgErrors({});

    const stringsToValidate = { bussinessName, postalCode }
    const arraysToValidate = { serviceType, regimeSelecteds, zoneType }
    const numberToValidate = { professionId: professionId }

    if(accounting){
      stringsToValidate['passwordSAT'] = passwordSAT;
      stringsToValidate['startDeclarationAt'] = startDeclarationAt;
      stringsToValidate['startSatAt'] = startSatAt;
    }

    const validateString = validateStringPresence(stringsToValidate, setMsgErrors);
    const validateRfc = validateRfcValid(rfc, setMsgErrors);
    const validateArray = validateArrayPresence(arraysToValidate, setMsgErrors);
    const validateNumber = validateNumberPresence(numberToValidate, setMsgErrors);

    if( validateString && validateRfc && validateArray && validateNumber){
      return true;
    }
    return false;
  }
 
  return (
    <div>
      <div className="text-center">
        {
          taxpayer ?
          (<h4>Editar Contribuyente</h4>)
          :
          (<h4>Agregar Nuevo Contribuyente</h4>)
        }
      </div>
      <hr/>

      <form className="mt-4" onSubmit={handleSubmit}>
        <div className="row">

          <div className="col-md-6 mb-3">
            <TextField
              label="Nombre o Razón social" variant="outlined" size="small" autoComplete="off" fullWidth={true}
              name="bussinessName"
              value={ bussinessName }
              onChange={ handleInputChange }
              helperText={ msgErrors.bussinessName }
              error={ msgErrors.bussinessName !== undefined }
            />
          </div>

          <div className="col-md-6 mb-3">
            <TextField
              label="RFC" variant="outlined" size="small" autoComplete="off" fullWidth={true}
              name="rfc"
              value={ rfc }
              onChange={ handleInputChange }
              inputProps={
                {style: {textTransform: 'uppercase'}}
              }
              helperText={ msgErrors.rfc }
              error={ msgErrors.rfc !== undefined }
            />
          </div>

          <div className="col-md-6 mb-3">
            <TextField
          label="Profesión" variant="outlined" size="small" autoComplete="off" fullWidth={true}
          select
          SelectProps={{
            native: true,
          }}
          name="professionId"
          value={professionId}
          onChange={ handleInputChange }
          helperText={msgErrors.professionId }
          error={msgErrors.professionId !== undefined }
        >
          <option value='0'>Selecciona profesión</option>
            {
              professionTypes.map((profession, index) => {
                return(
                  <option
                    key={ index }
                    value={ profession.id }
                  > 
                    { profession.description }
                  </option>  
                )
              })
            }
        </TextField>
          </div>

          <div className="col-md-6 mb-3">
            <TextField
              label="Correo Electrónico" 
              id="emailNotification"
              variant="outlined"
              size="small"
              name="emailNotification"
              value={ emailNotification }
              onChange={ handleInputChange }
              autoComplete="off"
              fullWidth={true}
              helperText={ msgErrors.emailNotification }
              error={ msgErrors.emailNotification !== undefined }
            />
          </div>

          <div className="col-md-6 mb-3">
            <TextField
              label="Núm. Teléfono" variant="outlined" size="small" autoComplete="off" fullWidth={true}
              id="phoneMobile"
              name="phoneMobile"
              value={ phoneMobile }
              onChange={ handleInputChange }
            />
          </div>

          {
            !taxpayer && (
              <TaxAddressForm
                address={ address }
                setFormValues={ setFormValues }
              /> 
            )
          }

          <div className="col-md-6 text-center">
            <div className="form-group">
              <label><strong>Tipo de servicio</strong></label>

              <div className="row">
                {
                  serviceTypes.map(( service, index ) => {
                    return (
                      <div className="col-md-6" key={index}>
                        <div className="form-check">
                          <input 
                            className="form-check-input" 
                            type="checkbox"
                            name="serviceType" 
                            id={service.key}
                            onChange={ handleServiceTypeChange }
                            value={ service.id }
                            data-code={ service.key }
                            defaultChecked={ defaultCheckedSelected( serviceType, service.id ) }
                          />
                          <label className="form-check-label" htmlFor={service.key}>
                            { service.description }
                          </label>
                        </div>
                      </div>
                    )
                  })
                }
                <div className="col-md-12 mb-3">
                  {
                    msgErrors.serviceType && (
                      <FormHelperText
                        align="center"
                        style={{ textAlign: 'center' }}
                        error
                      >
                          { msgErrors.serviceType }
                      </FormHelperText>  
                    )
                  }
                </div>
              </div>
            </div>
          </div>
          
          {
            accounting && (
              <div className="col-md-6 mb-3">
                <TextField
                  label="Contraseña S.A.T." variant="outlined" size="small" autoComplete="off" fullWidth={true}
                  name="passwordSAT"
                  type="password"
                  value={ passwordSAT }
                  onChange={ handleInputChange }
                  helperText={ msgErrors.passwordSAT }
                  error={ msgErrors.passwordSAT !== undefined }
                />
              </div>
            )
          }

          {
            accounting && (
              <div className="col-md-6 mb-3">
                <TextField
                  label="Fecha alta SAT" variant="outlined" size="small" autoComplete="off" fullWidth={true}
                  type="date"
                  name="startSatAt"
                  value={ startSatAt }
                  onChange={ handleInputChange }
                  InputLabelProps={{shrink: true,}}
                  helperText={ msgErrors.startSatAt }
                  error={ msgErrors.startSatAt !== undefined }
                />
              </div>
            )
          }

          {
            accounting && (
              <div className="col-md-6">
                <TextField
                  label="Fecha inicio de declaración" variant="outlined" size="small" autoComplete="off" fullWidth={true}
                  type="date"
                  name="startDeclarationAt"
                  value={ startDeclarationAt }
                  onChange={ handleInputChange }
                  InputLabelProps={{shrink: true,}}
                  helperText={ msgErrors.startDeclarationAt }
                  error={ msgErrors.startDeclarationAt !== undefined }
                />
              </div>
            )
          }

          {
            accounting && (
              <div className="col-md-6 text-center">
                <div className="form-group">
                  <label><strong>¿Ingresos mayores a $4,000,000.00?</strong></label>
                  <div className="row">
                    <div className="col-md-6 form-check">
                      <input 
                        className="form-check-input" 
                        type="radio" 
                        name="heigherIncome" 
                        id="lowerIncome"
                        value={false}
                        onChange={handleCheckboxTrueFalseChange}
                        defaultChecked
                      />
                      <label className="form-check-label" htmlFor="lowerIncome">
                        No
                      </label>
                    </div>

                    <div className="col-md-6 form-check">
                      <input
                        className="form-check-input" 
                        type="radio" 
                        name="heigherIncome" 
                        id="heigherIncome" 
                        value={true}
                        onChange={handleCheckboxTrueFalseChange}
                      />
                      <label className="form-check-label" htmlFor="heigherIncome">
                        Si
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            )
          }

          <div className="col-md-6 text-center mb-3">
            <div className="form-group">
              <label><strong>Tipo de zona</strong></label>
              <div className="row">
                {
                  zoneTypes.map(( zone, index ) => {
                    return (
                      <div className="col-md-6" key={index}>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            name="zoneType"
                            id={index}
                            value={zone.id}
                            onChange={ handleObjectAddQuitChange }
                            defaultChecked={ defaultCheckedSelected( zoneType, zone.id ) }
                          />
                          <label className="form-check-label" htmlFor={index}>
                            { zone.description }
                          </label>
                        </div>
                      </div>
                    )
                  })
                }
                <div className="col-md-12">
                  {
                    msgErrors.zoneType && (
                      <FormHelperText
                        align="center"
                        style={{ textAlign: 'center' }}
                        error
                      >
                          { msgErrors.zoneType }
                      </FormHelperText>  
                    )
                  }
                </div>
              </div>
            </div>
          </div>
          {
            accounting && (
              <div className="col-md-12 text-center">
                <div className="form-group">
                  <label><strong>Regímenes de contabilidad</strong></label>
                  <div className="row">
                    {
                      regimes.map((regime) => {
                        return regime.accounting && (
                          <div className="col-md-6 pt-2" key={regime.id}>
                            <div className="form-check">
                              <input 
                                className="form-check-input" 
                                type="checkbox"
                                name="regimesAccounting"
                                id={`accounting${regime.satCode}`}
                                value={regime.id}
                                onChange={ handleRegimeChange.bind(this, 'accounting') }
                                defaultChecked={ defaultCheckedSelectedRegime(regime.id, 'accounting') }
                              />
                              <label className="form-check-label" htmlFor={`accounting${regime.satCode}`}>
                                {regime.officialName}
                              </label>
                            </div>
                          </div>
                        )
                      })
                    }
                    <div className="col-md-12">
                      {
                        msgErrors.regimeSelecteds && (
                          <FormHelperText
                            align="center"
                            style={{ textAlign: 'center' }}
                            error
                          >
                              { msgErrors.regimeSelecteds }
                          </FormHelperText>  
                        )
                      }
                    </div>
                  </div>
                </div>
              </div>
            )
          }

          {
            invoicing && (
              <div className="col-md-12 text-center">
                <div className="form-group">
                  <label><strong>Regímenes de facturación</strong></label>
                  <div className="row">
                    {
                      regimes.map((regime) => {
                        return regime.invoicing && (
                          <div className="col-md-6 pt-2" key={regime.id}>
                            <div className="form-check">
                              <input 
                                className="form-check-input" 
                                type="checkbox"
                                name="regimesInvoicing"
                                id={`invoicing${regime.satCode}`}
                                value={regime.id}
                                onChange={handleRegimeChange.bind(this, 'invoicing')}
                                defaultChecked={defaultCheckedSelectedRegime(regime.id, 'invoicing')}
                              />
                              <label className="form-check-label" htmlFor={`invoicing${regime.satCode}`}>
                                {regime.officialName}
                              </label>
                            </div>
                          </div>
                        )
                      })
                    }
                    <div className="col-md-12">
                      {
                        msgErrors.regimeSelecteds && (
                          <FormHelperText
                            align="center"
                            style={{ textAlign: 'center' }}
                            error
                          >
                              { msgErrors.regimeSelecteds }
                          </FormHelperText>  
                        )
                      }
                    </div>
                  </div>
                </div>
              </div>
            )
          }
        </div>
        <div className="col-md-12 text-right">
          <button type="submit" className="btn btn-primary">Guardar</button>
        </div>
      </form>
    </div>
  )
}
