import { fetchWithApiKey } from "../../helpers/fetch";
import { types } from "../../types/types"

export const paymentGatewaysStartLoading = () => {
  return async(dispatch) => {
    const resp = await fetchWithApiKey('api/Catalogs/c_PaymentGateway');
    const body = await resp.json();
    if (body.success){
      dispatch(paymentGatewaysLoaded(body.data));
    }
  }
}

const paymentGatewaysLoaded = (paymentGateways) => ({
  type: types.paymentGatewayStartLoading,
  payload: paymentGateways
})