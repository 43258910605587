import React, { useEffect, useState } from 'react';
import validator from 'validator';
import { FormControl, MenuItem, Select, TextField } from '@material-ui/core';
import { useForm } from '../../../../hooks/useForm';
import { validateRfcValid } from '../../../../helpers/validateForm';
import { useDispatch, useSelector } from 'react-redux';
import { cfdiUsesBilling, customersBilling, regimesStartLoadingBilling } from '../../../../actions/billing/catalogs';

const Receiver = ({ setformCompletion, formCompletion, setFormDetails, formValid }) => {

    const { regimesBilling } = useSelector(state => state.regimeBilling);
    const { cfdiBilling } = useSelector(state => state.cfdiUsesBilling);
    const { curtomersBillingr } = useSelector(state => state.customerBilling)
    const { invoicesActivation } = useSelector(state => state.invoiceBilling)

    const dispatch = useDispatch()

    const [selectedRadio, setSelectedRadio] = useState('flexRadioDefault2');
    const [personName, setPersonName] = useState([]);

    const [msgErrors, setMsgErrors] = useState({});

    const { formValues, setFormValues, handleInputChange, handleChangeMultiple } = useForm({
        customerName: '',
        socialRz: '',
        rfc: '',
        regimen: '',
        zipCode: '',
        email: '',
        cfdi: ''
    })

    const { customerName, socialRz, rfc, zipCode, email, regimen, cfdi} = formValues

    useEffect(() => {
        dispatch(regimesStartLoadingBilling())
        dispatch(cfdiUsesBilling())
        dispatch(customersBilling(invoicesActivation.data.appKey))
    }, [])

    useEffect(() => {
        if( customerName ) {
            setFormValues(prevState => {
                return{
                    socialRz: customerName.razonSocial,
                    rfc: customerName.rfc,
                    regimen: customerName.regimen.satCode,
                    zipCode: customerName.codigoPostal,
                    email: customerName.correoElectronico,
                    cfdi: '',
                }
            })
        }
    }, [formValues])


    const isFormValid = () => {
        const errors = {}

        if( typeof socialRz === 'string' && socialRz.trim().length === 0 ) {
            errors['msgSocialRz'] = "Es requerido"
        }
        if( typeof rfc === 'string' && !validateRfcValid(rfc, setMsgErrors)){
            errors['msgRfc'] = 'RFC no es valido.';
          }
        if( typeof zipCode === 'string' && zipCode.trim().length === 0 ) {
            errors['msgZipCode'] = "Es requerido"
        }
        if( typeof email === 'string' && !validator.isEmail(email)) {
            errors['msgEmail'] = "'Correo electrónico no es valido.'"
        }
        if ( typeof regimen === 'string' && regimen.trim().length === 0) {
            errors['msgRegimen'] = "Es requerido";
        }
        if( typeof cfdi === 'string' && cfdi.trim().length === 0 ) {
            errors['msgCfdi'] = "Es requerido"
        }

        if( formValid ) {
            setMsgErrors(errors)
        }

        if(Object.keys(errors).length !== 0 ){
            return false;
          }

          return true;
    }

    useEffect(() => {
        isFormValid()
    }, [formValid])

    useEffect(() => {
        const isValid = isFormValid();

        setformCompletion({
            ...formCompletion,
            receiver: isValid
        });
    }, [formValues]);

    useEffect(() => {
        setFormDetails(prevState => {
            return{
                ...prevState,
                Receiver: {
                    BusinessName: socialRz,
                    Rfc: rfc,
                    KeyUseCfdi: cfdi,
                    KeyRegime: regimen,
                    PostalCode: zipCode,
                    Emails: email,
                    Address: ""
                }
            }
        })
    }, [socialRz,rfc, cfdi, regimen, zipCode, email]);

    const handleRadioChange = (event) => {
        setSelectedRadio(event.target.id);
    };

    return (
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2">
            <div className="form-check">
                <input
                    className="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault1"
                    checked={selectedRadio === 'flexRadioDefault1'}
                    onChange={handleRadioChange}
                />
                <label className="form-check-label" htmlFor="flexRadioDefault1">
                    Factura a Público En General
                </label>
            </div>
            <div className="form-check">
                <input
                    className="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault2"
                    checked={selectedRadio === 'flexRadioDefault2'}
                    onChange={handleRadioChange}
                />
                <label className="form-check-label" htmlFor="flexRadioDefault2">
                    Factura a Cliente
                </label>
            </div>
            <div className='row mt-3'>
                {
                    selectedRadio === 'flexRadioDefault2' ? (
                        <>
                            <div className="col-xs-12 col-sm-4 mb-3">
                                <TextField
                                    id="customerName"
                                    select
                                    name='customerName'
                                    label="Clientes"
                                    variant="outlined"
                                    onChange={ handleInputChange }
                                    fullWidth={true}
                                >
                                    {curtomersBillingr.map((customer) => (
                                        <MenuItem key={customer.id} value={customer}>
                                            { customer.nombre } { customer.apellidoP } { customer.apellidoM } {<br/>}
                                            { customer.rfc }
                                        </MenuItem>
                                        ))}
                                </TextField>
                            </div>
                            <div className="col-xs-12 col-sm-8 col-md-5 mb-3">
                                <TextField
                                    id="outlined-basic"
                                    label="Razón Social*"
                                    name='socialRz'
                                    value={ socialRz }
                                    onChange={ handleInputChange }
                                    helperText={ msgErrors.msgSocialRz }
                                    error={ msgErrors.msgSocialRz !== undefined }
                                    variant="outlined"
                                    fullWidth={true}
                                />
                            </div>
                        </>
                    )
                    :
                    (
                        <div className="col-xs-12 col-sm-8 col-md-5 mb-3">
                            <TextField
                                id="outlined-basic"
                                label="Razón Social*"
                                name='socialRz'
                                value="PUBLICO EN GENERAL"
                                onChange={ handleInputChange }
                                helperText={ msgErrors.msgSocialRz }
                                error={ msgErrors.msgSocialRz !== undefined }
                                variant="outlined"
                                fullWidth={true}
                            />
                        </div>
                    )
                }
                <div className="col-xs-12 col-sm-8 col-md-3 mb-3">
                    <TextField
                        id="outlined-basic"
                        label="RFC*"
                        name='rfc'
                        value={ rfc }
                        onChange={ handleInputChange }
                        helperText={ msgErrors.msgRfc }
                        error={ msgErrors.msgRfc !== undefined }
                        variant="outlined"
                        fullWidth={true}
                    />
                </div>
            </div>
            <div className='row'>
                <div className="col-xs-4 col-sm-4 mb-3">
                <TextField
                        id="regimen"
                        select
                        name='regimen'
                        value={ regimen }
                        label="Regimenes*"
                        variant="outlined"
                        onChange={ handleInputChange }
                        helperText={ msgErrors.msgRegimen }
                        error={ msgErrors.msgRegimen !== undefined }
                        fullWidth={true}
                        SelectProps={{
                            MenuProps: {
                              PaperProps: {
                                style: {
                                  maxHeight: 150, // Altura máxima del menú desplegable
                                },
                              },
                            },
                        }}
                    >
                        {regimesBilling.map((option) => (
                            <MenuItem key={option.id} value={option.satCode}>
                                {option.satCode} - { option.officialName }
                            </MenuItem>
                        ))}
                    </TextField>
                </div>
                <div className="col-xs-4 col-sm-4 mb-3">
                    <TextField
                        id="outlined-basic2"
                        label="Cóodigo Postal*"
                        name='zipCode'
                        value={ zipCode }
                        onChange={ handleInputChange }
                        helperText={ msgErrors.msgZipCode }
                        error={ msgErrors.msgZipCode !== undefined }
                        variant="outlined"
                        fullWidth={true}
                    />
                </div>
                <div className="col-xs-4 col-sm-4 mb-3">
                    <TextField
                        id="outlined-basic3"
                        label="Correo Electrónico*"
                        name='email'
                        value={ email }
                        onChange={ handleInputChange }
                        helperText={ msgErrors.msgEmail }
                        error={ msgErrors.msgEmail !== undefined }
                        variant="outlined"
                        fullWidth={true}
                    />
                </div>
            </div>
            <div className='row'>
                <div className="col-xs-12 col-sm-12 mb-3">
                    <TextField
                        id="outlined-select-currency"
                        select
                        label="USO CFDI*"
                        name='cfdi'
                        value={cfdi}
                        onChange={ handleInputChange }
                        helperText={ msgErrors.msgCfdi }
                        error={ msgErrors.msgCfdi !== undefined }
                        variant="outlined"
                        fullWidth={true}
                        SelectProps={{
                            MenuProps: {
                              PaperProps: {
                                style: {
                                  maxHeight: 150, // Altura máxima del menú desplegable
                                },
                              },
                            },
                        }}
                    >
                        {cfdiBilling.map((option) => (
                            <MenuItem key={option.id} value={option.key}>
                                { option.key } - {option.description}
                            </MenuItem>
                        ))}
                    </TextField>
                </div>
            </div>
        </div>
    )
}

export default Receiver;
