import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { paymentStatusStartLoading } from '../../../../../actions/catalogs/paymentStatus';
import { startRegisterCRMSaleRenovation } from '../../../../../actions/crm/renovations';
import { crmSaleRenovationOptainSelected, crmSaleRenovationStartUpdate, crmSaleSelectedClear } from '../../../../../actions/crm/sales';
import { getByEmail } from '../../../../../actions/users';
import { validateNumberPresence, validateStringPresence } from '../../../../../helpers/validateForm';
import { useForm } from '../../../../../hooks/useForm';
import { ADMIN_SALES } from '../../../../constants/Routes';
import { ModuleCommentScreen } from '../../../crm/sales/FormModules/ModuleCommentScreen';
import { ModuleInvoiceScreen } from '../../../crm/sales/FormModules/ModuleInvoiceScreen';
import { ModuleSaleScreen } from '../../../crm/sales/FormModules/ModuleSaleScreen';
import { ModuleUserScreen } from '../../../crm/sales/FormModules/ModuleUserScreen';

export const AdminAccountingSaleFormScreen = () => {
  
  const {saleId} = useParams();

  const { crmSaleSelected } = useSelector(state => state.crmSale);

  const [msgErrors, setMsgErrors] = useState({});

  let history = useHistory();

  const dispatch = useDispatch();

  const {paymentStatusTypes} = useSelector(state => state.paymentStatusType);

  const {formValues, setFormValues, handleInputChange} = useForm({
    comments: '',
    endServiceAt: '',
    invoiceData: {
      cfdiUseId: 0,
      comments: '',
      receptorAccountId: 0,
      wayOfPaymentId: 0,
    },
    salesData:[
      {
        id: new Date().getTime(),
        price: 0.00,
        productId: 0,
      }
    ],
    startServiceAt: '',
    total: 0.00,
    paymentStatusId: '',
    voucher: {
      voucherFiles: '',
      paymentAt: '',
      isLoadedVoucher: false
    },
    userId: '',
  });

  const {comments, endServiceAt, invoiceData, salesData, startServiceAt, total, paymentStatusId, userId, voucher} = formValues;

  useEffect(() => {
    if (saleId !== undefined) {
      dispatch(crmSaleRenovationOptainSelected({id: saleId}))
    }else{
      dispatch(crmSaleSelectedClear())
    }
  }, [dispatch, saleId]);

  useEffect(() => {
    if(crmSaleSelected){
      dispatch(getByEmail(crmSaleSelected.user.email));

      let products = [];
      crmSaleSelected.products.forEach((product) =>{
        products.push({
          id: product.id,
          price: product.total,
          productId: product.product.id,
        })
      });

      setFormValues(prevState => {
        return{
          ...prevState,
          invoiceData: {
            cfdiUseId: crmSaleSelected.invoiceData.cfdiUse.id,
            comments: crmSaleSelected.invoiceData.comments,
            receptorAccountId: crmSaleSelected.invoiceData.receptorAccount.id,
            wayOfPaymentId: crmSaleSelected.invoiceData.wayOfPaypment.id,
          },
          salesData: products,
          endServiceAt: moment(crmSaleSelected.endServiceAt).format('YYYY-MM-DD'),
          startServiceAt: moment(crmSaleSelected.startServiceAt).format('YYYY-MM-DD'),
          total: crmSaleSelected.total,
          voucher: {
            ...prevState.voucher,
            paymentAt: crmSaleSelected.paymentAt ? moment(crmSaleSelected.paymentAt).format('YYYY-MM-DD') : '',
            isLoadedVoucher: crmSaleSelected.voucherPaths ? true : false,
          },
          comments: crmSaleSelected.comments,
          paymentStatusId: crmSaleSelected.paymentStatus.id,
          userId: crmSaleSelected.user.id
        }
      })
    }
  }, [crmSaleSelected, dispatch, setFormValues]);

  useEffect(() => {
    if (paymentStatusTypes.length === 0) {
      dispatch(paymentStatusStartLoading());
    }
  }, [dispatch, paymentStatusTypes]);

  useEffect(() => {
    if (paymentStatusTypes.length > 0 && !crmSaleSelected) {
      const paymentStatus = paymentStatusTypes.filter(e => (e.key === 'StandBy'))[0];
      setFormValues(prevState => {
        return{
          ...prevState,
          paymentStatusId: paymentStatus.id
        }
      })
    }
  }, [crmSaleSelected, paymentStatusTypes, setFormValues]);

  const handleSubmit = (e) => {
    e.preventDefault();

    let formData = new FormData();

    const paymentStatus = paymentStatusTypes.filter(e => e.id === parseInt(paymentStatusId))[0];

    if (paymentStatus.key === 'Accepted') {
      formData.append(`IsAuthorized`, true);
    }

    Object.keys(invoiceData).forEach(function(key) {
      formData.append(`invoiceData.${key}`, invoiceData[key]);
    });

    if (voucher.voucherFiles !== '' && voucher.paymentAt !== '') {
      formData.append(`IsSold`, true);
      formData.append(`voucherFiles`, voucher.voucherFiles);
      formData.append(`paymentAt`, voucher.paymentAt);
    }
    formData.append("StartServiceAt", startServiceAt);
    formData.append("EndServiceAt", endServiceAt);
    formData.append(`total`, total);
    formData.append("paymentStatusId", paymentStatusId);
    formData.append(`comments`, comments);


    if(crmSaleSelected){
      formData.append(`OrderId`, crmSaleSelected.id);

      dispatch(crmSaleRenovationStartUpdate(formData, history, ADMIN_SALES))
    }else{
      if (isFormValid()) {
        salesData.forEach((sale, index) => {
          formData.append(`Products[${index}].productId`, sale.productId)
          formData.append(`Products[${index}].total`, sale.price)
        });

        formData.append("userId", userId);
        
        dispatch(startRegisterCRMSaleRenovation(formData, history, ADMIN_SALES))
      }
    }
  }

  const isFormValid = () => {
    setMsgErrors({});

    let stringToValidate = {
      endServiceAt: endServiceAt,
      startServiceAt: startServiceAt,
    }

    const numberToValidate = {total: total, userId: userId}

    salesData.forEach((saleData, index) => {
      numberToValidate[`productId${index}`] = saleData.productId
      numberToValidate[`price${index}`] = saleData.price
    })

    const validateString = validateStringPresence(stringToValidate, setMsgErrors);
    const validateNumber = validateNumberPresence(numberToValidate, setMsgErrors);

    if(validateString && validateNumber){
      return true;
    }
    //swal('Error al guardar', 'Se detectaron errores, favor de revisar', 'error');
    return false;
  }
  
  return (
    <div className="container">
      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-4">
        <div className="card-header">
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <h5>Datos de renovación contabilidad</h5>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <Link to={ADMIN_SALES} className="btn btn-danger btn-sm float-right">
                <i className="fas fa-arrow-left"></i> Regresar
              </Link>
            </div>
          </div>
        </div>
      </div>
      <form className="mt-4" onSubmit={handleSubmit}>
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <div className="row">
            <ModuleUserScreen
              isRenovation={true}
              msgErrors={msgErrors}
              saleFormValues={formValues}
              saleSetFormValues={setFormValues}
            />

            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <h5>Datos de la venta</h5>
              <hr />
            </div>
            <ModuleSaleScreen
              isAccounting={true}
              isAdmin = {true}
              isSale={false}
              msgErrors={msgErrors}
              saleFormValues={formValues}
              saleSetFormValues={setFormValues}
              saleHandleInputChange={handleInputChange}
            />

            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <h5>Datos de la Factura</h5>
              <hr />
            </div>
            <ModuleInvoiceScreen
              saleFormValues={formValues}
              saleSetFormValues={setFormValues}
            />

            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <h5>Comentarios extras</h5>
              <hr />
            </div>
            <ModuleCommentScreen
              saleFormValues={formValues}
              saleSetFormValues={setFormValues}
            />
            
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 text-right">
              <button className="btn btn-primary">
                Guardar
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}
