import React, { useEffect, useState, useContext } from 'react';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import { useDispatch, useSelector } from 'react-redux';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { diagnosticApi } from '../../../../actions/catalogs/accounters';
import { TableBody, TextField, Box, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { ModalContext } from '../../../modal/ModalContext';
import Swal from 'sweetalert2';
import UploadCSVModal from '../../crm/sales/modals/UploadCSVModal/UploadCSVModal';
import Tooltip from '@material-ui/core/Tooltip';

export const Diagnostic = () => {
    const [loading, setLoading] = useState(false);
    const [showComponent, setShowComponent] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const dispatch = useDispatch();
    const datos = useSelector(state => state.diagnosticApiData.data);
    const dataList = datos ? Object.values(datos) : [];
    const { handleModal } = useContext(ModalContext);
    const totalPages = 100;
    const pagesToShow = 4;
    const [search, setSearch] = useState("");
    const [search2, setSearch2] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    let result = [];

    const handleSearchChange = (event) => {
        setSearch2(event.target.value);
    };

    const handleSearchClick = () => {
        setSearchTerm(search2);
    };

    useEffect(() => {
        if (searchTerm !== '') {
            dispatch(diagnosticApi(null, searchTerm));
        }
    }, [dispatch, searchTerm, pageNumber]);

    const handlePreviousPage = () => {
        if (pageNumber > 1) {
            setPageNumber(pageNumber - 1);
        }
    };

    const handleNextPage = () => {
        if (pageNumber < totalPages - pagesToShow + 1) {
            setPageNumber(pageNumber + 1);
        }
    };

    const handlePageChange = (page) => {
        setPageNumber(page);
    };

    const startPage = pageNumber;
    const endPage = Math.min(startPage + pagesToShow - 1, totalPages);

    useEffect(() => {
        dispatch(diagnosticApi(pageNumber, null));
    }, [dispatch, pageNumber]);

    const searcher = (e) => {
        setSearch(e.target.value);
    };

    if (!search) {
        result = dataList;
    } else {
        result = dataList?.filter((dato) =>
            dato.rfc?.toLowerCase().includes(search.toLowerCase()) ||
            dato.businessName?.toLowerCase().includes(search.toLowerCase()) ||
            dato.email?.toLowerCase().includes(search.toLowerCase()) ||
            (dato.report?.statusDiagnostic?.toLowerCase().includes(search.toLowerCase()) ?? false)
        );
    }


    return (
        <div style={{ margin: '10px', overflow: 'hidden' }}>
            <Box display="flex" alignItems="center" justifyContent='space-between' marginBottom="10px" style={{ width: '100%' }}>
                <Tooltip title="Solo buscará usuarios en la pagina actual">
                    <TextField
                        onChange={searcher}
                        value={search}
                        variant="outlined"
                        placeholder="Buscar usuario en la página actual"
                        style={{ marginRight: '10px', width: '40%' }}
                    />
                </Tooltip>
                <Tooltip title="Buscar usuario en todos los registros existentes">
                    <TextField
                        onChange={handleSearchChange}
                        value={search2}
                        variant="outlined"
                        placeholder="Buscar usuario general"
                        style={{ marginRight: '10px', width: '40%' }}
                    />
                </Tooltip>
                <Button
                    onClick={handleSearchClick}
                    variant="contained"
                    color="primary"
                    style={{ borderRadius: '20px', marginRight: '3px' }}
                >
                    Buscar
                </Button>
                <nav aria-label="Page navigation example">
                    <ul className="pagination justify-content-end" style={{ margin: 0 }}>
                        <li className={`page-item ${pageNumber === 1 ? 'disabled' : ''}`}>
                            <a className="page-link" onClick={handlePreviousPage}>Anterior</a>
                        </li>
                        {[...Array(endPage - startPage + 1)].map((_, i) => (
                            <li className={`page-item ${pageNumber === startPage + i ? 'active' : ''}`} key={i}>
                                <a className="page-link" onClick={() => handlePageChange(startPage + i)}>{startPage + i}</a>
                            </li>
                        ))}
                        <li className={`page-item ${pageNumber >= totalPages - pagesToShow + 1 ? 'disabled' : ''}`}>
                            <a className="page-link" onClick={handleNextPage}>Siguiente</a>
                        </li>
                    </ul>
                </nav>
            </Box>
            <TableContainer style={{ borderRadius: '10px', maxHeight: '780px' }}>
                <Table className="table table-responsive" size="small" aria-label="sticky table" stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell colSpan={12} align="center">
                                <h2 style={{ whiteSpace: 'nowrap' }}>Diagnostico</h2>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableHead>
                        <TableRow>
                            <TableCell class="font-weight-bold" style={{ textAlign: 'center', width: '50px' }}>ID</TableCell>
                            <TableCell class="font-weight-bold" style={{ textAlign: 'center' }}>RFC</TableCell>
                            <TableCell class="font-weight-bold" style={{ textAlign: 'center' }}>Contribuyente</TableCell>
                            <TableCell class="font-weight-bold" style={{ textAlign: 'center' }}>Email</TableCell>
                            <TableCell class="font-weight-bold" style={{ textAlign: 'center' }}>Fecha de solicitud</TableCell>
                            <TableCell class="font-weight-bold" style={{ textAlign: 'center' }}>Fecha de revisión</TableCell>
                            <TableCell class="font-weight-bold" style={{ textAlign: 'center' }}>Estatus</TableCell>
                            <TableCell class="font-weight-bold" style={{ textAlign: 'center' }}>Ejecutó</TableCell>
                            <TableCell class="font-weight-bold" style={{ textAlign: 'center' }}>Estatus del diagnostico</TableCell>
                            <TableCell class="font-weight-bold" style={{ textAlign: 'center' }}>Subir diagnóstico</TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {
                            result.length === 0 ?
                                (<TableRow>
                                    <TableCell colSpan="10">
                                        <div className="alert alert-info text-center" role="alert">
                                            No hay contribuyentes con dichos filtros
                                        </div>
                                    </TableCell>
                                </TableRow>)
                                :
                                (
                                    result.map((report, index) => {
                                        return (
                                            <TableRow key={index}>
                                                <TableCell class="font-weight-bold" style={{ textAlign: 'center', width: '50px' }}>{report.profileId}</TableCell>
                                                <TableCell component="th" scope="row" style={{ color: 'black', textAlign: 'center' }}>{report.rfc}</TableCell>
                                                <TableCell component="th" scope="row" style={{ color: 'black', textAlign: 'center' }}>{report.businessName}</TableCell>
                                                <TableCell component="th" scope="row" style={{ color: 'black', textAlign: 'center' }}>{report.email}</TableCell>
                                                <TableCell component="th" scope="row" style={{ color: 'black', textAlign: 'center' }}>{report.createdAt}</TableCell>
                                                <TableCell component="th" scope="row" style={{ color: 'black', textAlign: 'center' }}>
                                                    {
                                                        report.updateAt === null ?
                                                            <div>
                                                                Sin revisión
                                                            </div>
                                                            :
                                                            <div>
                                                                {report.updateAt}
                                                            </div>
                                                    }
                                                </TableCell>
                                                <TableCell component="th" scope="row" style={{ color: 'black', textAlign: 'center' }}>{report.status}</TableCell>
                                                <TableCell component="th" scope="row" style={{ color: 'black', textAlign: 'center' }}>{report.attended}</TableCell>
                                                <TableCell component="th" scope="row" style={{ color: 'black', textAlign: 'center' }}>{report.statusDiagnostic}</TableCell>
                                                <TableCell align="center">
                                                    {/* {
                                                        report.status === "Pendiente" ?
                                                            ( */}
                                                    <Button variant='contained' color='primary' style={{ marginTop: '2px' }}
                                                        value={report.rfc}
                                                        onClick={() => handleModal(<UploadCSVModal rfcValue={report.rfc} />)}
                                                    >
                                                        Abrir ventana de diagnóstico
                                                    </Button>
                                                    {/* Se comentó porque aún no tenemos información si en algún punto se dejará de poner el botón para subir el diagnostico */}
                                                    {/* )
                                                            :
                                                            (
                                                                <div className="alert alert-success" role="alert">
                                                                    Tramite concluido!
                                                                </div>
                                                            )
                                                    } */}
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })
                                )}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};
