import swal from "sweetalert";
import { types } from "../../types/types";
import { saveModal } from "../../helpers/UseSwal";
import { fetchWithToken } from "../../helpers/fetch";

export const registerNewActivity = ( formValues ) => {
    return async(dispatch) => {
        saveModal(true);

        const resp = await fetchWithToken('api/Classification/AddRegimenActivitiesProfessions', formValues, 'POST');
        const body = await resp.json();

        if(body.success) {
            swal('El alta se realizó con éxito', 'Nueva actividad registrada correctamente','success');
        }else{
            let msgErrors = "";
            if(body.errors){
              Object.keys(body.errors).forEach(function(key) {
                msgErrors += `${body.errors[key]}\n`
              });
            }else{
              msgErrors = body.error;
            }
            swal('Error al guardar', msgErrors, 'error');
          }
          setTimeout(() => {
            saveModal(false);
            window.location.reload();
          }, 2500);
    }
}

export const registerNewClaveProdServ = ( formValues) => {
    return async(dispatch) => {
        saveModal(true);

        const resp = await fetchWithToken('api/Catalogs/Create', formValues, 'POST');
        const body = await resp.json();

        if(body.success) {
            swal('El alta se realizó con éxito', 'Nueva Clave de Producto o Servicio registrada correctamente','success');
        }else{
            let msgErrors = "";
            if(body.errors){
              Object.keys(body.errors).forEach(function(key) {
                msgErrors += `${body.errors[key]}\n`
              });
            }else{
              msgErrors = body.error;
            }
            swal('Error al guardar', msgErrors, 'error');
          }
          setTimeout(() => {
            saveModal(false);
            window.location.reload();
          }, 2500);
    }
}

export const editClaveProdServ = ( formValues) => {
  return async(dispatch) => {
      saveModal(true);

      const resp = await fetchWithToken('api/Catalogs/Update', formValues, 'PUT');
      const body = await resp.json();

      if(body.success) {
          swal('El registro se actualizó con éxito', 'La Clave de Producto o Servicio se actualizó correctamente','success');
      }else{
          let msgErrors = "";
          if(body.errors){
            Object.keys(body.errors).forEach(function(key) {
              msgErrors += `${body.errors[key]}\n`
            });
          }else{
            msgErrors = body.error;
          }
          swal('Error al guardar', msgErrors, 'error');
        }

        setTimeout(() => {
          saveModal(false);
          window.location.reload();
        }, 2500);
  }
}
