import { InputAdornment, TextField } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { keyUnitsStartLoading } from '../../../../../actions/catalogs/keyUnits';
import { lineItemAddNew, lineItemAddToList } from '../../../../../actions/lineItems';
import { useForm } from '../../../../../hooks/useForm';
import { IepsTranslatedTaxes } from './Taxes/IepsTranslatedTaxes';
import { IsrRetainedTaxes } from './Taxes/IsrRetainedTaxes';
import { IvaRetainedTaxes } from './Taxes/IvaRetainedTaxes';
import { IvaTranslatedTaxes } from './Taxes/IvaTranslatedTaxes';

const initialLineItem = {
  amount: 0.00,
  description: '',
  discount: 0.00,
  pretotalWithoutTaxes: 0.00,
  productService: '',
  quantity: 1,
  total: 0.00,
  unitTypeId: '',
  unitPrice: 0.00,
}

const initialIvaTranslated = { amount: 0 };

const initialIepsTranslated = { rate: 0.00, type: 'Tasa', amount: 0 }

const initialIvaRetained = { porcentage: 0.1067, amount: 0 }

const initialIsrRetained = { porcentage: 0.10, amount: 0 }

const initialCheckboxes = {
  CheckIvaTranslated: false,
  CheckIepsTranslated: false,
  CheckIvaRetained: false,
  CheckIsrRetained: false
}

export const LineItemForm = () => {

  const dispatch = useDispatch();

  const { unitTypes } = useSelector(state => state.unitType)

  const { formValues, handleInputChange, setFormValues } = useForm(initialLineItem);
  const { quantity, productService, description, unitTypeId, unitPrice, amount, discount, total } = formValues;

  const [checkboxes, setCheckboxes] = useState(initialCheckboxes);
  const { CheckIvaTranslated, CheckIepsTranslated, CheckIvaRetained, CheckIsrRetained } = checkboxes;

  const [ivaTranslated, setIvaTranslated] = useState( initialIvaTranslated );
  const [iepsTranslated, setIepsTranslated] = useState(initialIepsTranslated);

  const [ivaRetained, setIvaRetained] = useState(initialIvaRetained);
  const [isrRetained, setIsrRetained] = useState(initialIsrRetained);

  useEffect(() => {
    if (unitTypes.length === 0) {
      dispatch(keyUnitsStartLoading())
    }
  }, [unitTypes, dispatch]);

  useEffect(() => {
    if( unitTypes.length > 0 ){
      setFormValues(prevState => {
        return{
          ...prevState,
          unitTypeId: unitTypes[0].id
        }
      })
    }
  }, [ setFormValues, unitTypes ]);

  useEffect(() => {
    const calculeAmount = quantity * unitPrice;
    const calculePretotalWithoutTaxes =  calculeAmount - discount;

    setFormValues(prevState =>  {
      return{
        ...prevState,
        amount: calculeAmount,
        pretotalWithoutTaxes: calculePretotalWithoutTaxes,
        total: calculePretotalWithoutTaxes
      }
    })
  }, [quantity, unitPrice, discount, setFormValues])

  useEffect(() => {
    setFormValues(prevState =>  {
      const calculeTotalWithTaxes = prevState.pretotalWithoutTaxes + ( ivaTranslated.amount + iepsTranslated.amount ) - ( ivaRetained.amount + isrRetained.amount );
      return{
        ...prevState,
        total: calculeTotalWithTaxes
      }
    })
  }, [ivaTranslated.amount, iepsTranslated.amount, ivaRetained.amount, isrRetained.amount, setFormValues])

  const handleCheckboxTrueFalseChange = ( { target } ) => {
    setCheckboxes({
      ...checkboxes,
      [target.name]: !checkboxes[target.name]
    })
  }
  
  const handleSubmit = ( e ) => {
    e.preventDefault();
    dispatch( lineItemAddToList( {
      ...formValues,
      id: new Date().getTime()
    }));
  }

  const handleSaveSubmit = ( e ) => {
    e.preventDefault();
    dispatch( lineItemAddNew({
      ...formValues,
      id: new Date().getTime()
    }));

  }
  
  return (
    <div className="col-md-6">
      <form onSubmit={handleSubmit}>
        <div className="row">

          <div className="col-md-6 mb-3">
            <TextField
              label="Cantidad" 
              variant="outlined"
              size="small"
              name="quantity"
              value={ quantity }
              onChange={ handleInputChange }
              autoComplete="off"
              fullWidth={true}
            />
          </div>

          <div className="col-md-6 mb-3">
          <TextField
              select
              variant="outlined"
              label="Unidad"
              size="small"
              fullWidth={true}
              name="unitTypeId"
              value={ unitTypeId}
              onChange={handleInputChange}
              SelectProps={{
                native: true,
              }}
            >
              {
                unitTypes.length > 0 ? (
                  unitTypes.map((unitType) => {
                    return(
                      <option key={ unitType.id } value={ unitType.id }> { unitType.key } - { unitType.name }</option>
                    )
                  })
                )
                :
                (
                  <option value={0}>No hay registros</option>
                )
              }
          </TextField>
          </div>

          <div className="col-md-12 mb-3">
            <TextField
              label="Clave Prod/Serv" 
              variant="outlined"
              size="small"
              name="productService"
              value={ productService }
              onChange={ handleInputChange }
              autoComplete="off"
              fullWidth={true}
            />
          </div>

          <div className="col-md-12 mb-3">
            <TextField
              label="Concepto" 
              variant="outlined"
              size="small"
              name="description"
              multiline
              value={ description }
              onChange={ handleInputChange }
              autoComplete="off"
              fullWidth={true}
              rows={4}
            />
          </div>

          <div className="col-md-12 mb-3">
            <TextField
              label="Precio unitario" 
              variant="outlined"
              size="small"
              name="unitPrice"
              value={ unitPrice }
              onChange={ handleInputChange }
              autoComplete="off"
              fullWidth={true}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    $
                  </InputAdornment>
                ),
              }}
            />
          </div>

          <div className="col-md-12 mb-3">
            <TextField
              label="Importe" 
              variant="outlined"
              size="small"
              name="amount"
              value={ amount }
              onChange={ handleInputChange }
              autoComplete="off"
              fullWidth={true}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    $
                  </InputAdornment>
                ),
              }}
            />
          </div>

          <div className="col-md-12 mb-3">     
            <TextField
              label="Descuento" 
              variant="outlined"
              size="small"
              name="discount"
              value={ discount }
              onChange={ handleInputChange }
              autoComplete="off"
              fullWidth={true}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    $
                  </InputAdornment>
                ),
              }}
            />
          </div>

          <div className="col-md-12 mb-3">
            <div className="row">
              <div className="col-md-6 text-center">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="CheckIvaTranslated"
                    name="CheckIvaTranslated"
                    onChange={ handleCheckboxTrueFalseChange }
                  />
                  <label className="form-check-label" htmlFor="CheckIvaTranslated">
                    IVA Trasladado
                  </label>
                </div>
              </div>

              <div className="col-md-6 text-center">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="CheckIepsTranslated"
                    name="CheckIepsTranslated"
                    onChange={ handleCheckboxTrueFalseChange }
                  />
                  <label className="form-check-label" htmlFor="CheckIepsTranslated">
                    IEPS Trasladado
                  </label>
                </div>
              </div>
            </div>
          </div>
          
          <IvaTranslatedTaxes
            CheckIvaTranslated={ CheckIvaTranslated }
            ivaTranslated={ ivaTranslated }
            setIvaTranslated={ setIvaTranslated }
            formValues={ formValues }
            initialIvaTranslated={ initialIvaTranslated }
          />

          <IepsTranslatedTaxes
            CheckIepsTranslated={ CheckIepsTranslated }
            iepsTranslated={ iepsTranslated }
            setIepsTranslated={ setIepsTranslated }
            formValues={ formValues }
            initialIepsTranslated={ initialIepsTranslated }
          />

          <div className="col-md-12 mb-3">
            <div className="row">

              <div className="col-md-6 text-center">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="CheckIvaRetained"
                    name="CheckIvaRetained"
                    onChange={ handleCheckboxTrueFalseChange }
                  />
                  <label className="form-check-label" htmlFor="CheckIvaRetained">
                    IVA Retenido
                  </label>
                </div>
              </div>

              <div className="col-md-6 text-center">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="CheckIsrRetained"
                    name="CheckIsrRetained"
                    onChange={ handleCheckboxTrueFalseChange }
                  />
                  <label className="form-check-label" htmlFor="CheckIsrRetained">
                    ISR Retenido
                  </label>
                </div>
              </div>
            </div>
          </div>

          <IvaRetainedTaxes
            CheckIvaRetained={ CheckIvaRetained }
            ivaRetained={ ivaRetained }
            setIvaRetained={ setIvaRetained }
            formValues={ formValues }
            initialIvaRetained={ initialIvaRetained }
          />

          <IsrRetainedTaxes
            CheckIsrRetained={ CheckIsrRetained }
            isrRetained={ isrRetained }
            setIsrRetained={ setIsrRetained }
            formValues={ formValues }
            initialIsrRetained={ initialIsrRetained }
          />

          <div className="col-md-12 mb-3">
            <TextField
              label="Total" 
              variant="outlined"
              size="small"
              name="total"
              value={ total }
              onChange={ handleInputChange }
              autoComplete="off"
              fullWidth={true}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    $
                  </InputAdornment>
                ),
              }}
            />
          </div>

          <div className="col-md-12 text-right">
            <button
              type="button" 
              className="btn btn-success mr-2"
              onClick={ handleSaveSubmit }
            >
              Guardar
            </button>

            <button type="submit" className="btn btn-primary">Agregar</button>
          </div>

        </div>
      </form>
    </div>
  )
}
