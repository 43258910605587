import React from 'react';
import { Link } from 'react-router-dom';
import { ADMIN_ADMINISTRATORS } from '../../constants/Routes';
import { ADMIN_SALES } from '../../constants/Routes';

export const AdminIndexScreen = () => {
    return (
        <div className="container">
            <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <h4 className="card-header text-center">Administración</h4>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <Link to={ADMIN_ADMINISTRATORS} className="btn btn-primary btn-sm mr-1 mt-2">
                        <i className="fas fa-users"></i> Usuarios
                    </Link>
                    <Link to={ADMIN_SALES} className="btn btn-primary btn-sm mr-1 mt-2">
                        <i className="fas fa-plus"></i> Agregar venta
                    </Link>
                    <Link to={ADMIN_SALES} className="btn btn-primary btn-sm mr-1 mt-2" >
                        <i className="fas fa-shopping-cart"></i> Ventas
                    </Link>
                    <Link to={ADMIN_SALES} className="btn btn-primary btn-sm mr-1 mt-2" >
                        <i className="fas fa-coins"></i> Precios
                    </Link>
                    <Link to={ADMIN_SALES} className="btn btn-primary btn-sm mr-1 mt-2" >
                        <i className="fas fa-cart-arrow-down"></i> Vetas externas
                    </Link>
                    <Link to={ADMIN_SALES} className="btn btn-primary btn-sm mr-1 mt-2" >
                        <i className="fas fa-chart-bar"></i> Indicadores
                    </Link>
                    <Link to={ADMIN_SALES} className="btn btn-primary btn-sm mr-1 mt-2" >
                        <i className="fas fa-hand-holding-usd"></i> Bonicaciones y Descuentos
                    </Link>
                    <Link to={ADMIN_SALES} className="btn btn-primary btn-sm mr-1 mt-2" >
                        <i className="fas fa-file-signature"></i> Reportes
                    </Link>
                    <Link to={ADMIN_SALES} className="btn btn-primary btn-sm mr-1 mt-2" >
                        <i className="fas fa-hand-holding-usd"></i> Bonos
                    </Link>
                    <Link to={ADMIN_SALES} className="btn btn-primary btn-sm mr-1 mt-2" >
                        <i className="fas fa-shopping-cart"></i> Asistido
                    </Link>
                    <Link to={ADMIN_SALES} className="btn btn-primary btn-sm mr-1 mt-2" >
                        <i className="fab fa-cc-amazon-pay"></i> Compropago
                    </Link>
                    <Link to={ADMIN_SALES} className="btn btn-primary btn-sm mr-1 mt-2" >
                        <i className="fas fa-shopping-cart"></i> TDR
                    </Link>
                    <hr />
                </div>
            </div>
        </div>
    )
}
