import { types } from "../../types/types";
import { fetchWithToken } from "../../helpers/fetch";

export const opinionComplement = (rfc) => {
    return async (dispatch) => {
        const resp = await fetchWithToken(`api/CustomerSupport/ComplianceOpinionDetails/${rfc}`);
        const body = await resp.json();

        if (body.success) {
            dispatch(opinionComplementRegister(body.data))
        }
    }
}

export const opinionComplementRegister = (opinion) => ({
    type: types.opinionComplement,
    payload: opinion
});