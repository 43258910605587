import { loadingModal } from "../../helpers/UseSwal";
import { fetchWithApiKey, fetchWithToken, fetchWithoutToken } from "../../helpers/fetch";
import { types } from "../../types/types";
import swal from "sweetalert";

export const getActivitiesByRegime = (satCode) => {
    return async(dispatch) => {
        const resp = await fetchWithToken('api/Classification/GetAllActivityByRegime', satCode, 'POST');
        const body = await resp.json();

        if (body.success){
            loadingModal(false);
            dispatch(activitiesByRegime( body.data ));

        }
    }
}

const activitiesByRegime = ( activities ) => ({
    type: types.activitiesByRegimen,
    payload: activities
});

export const getClavProdServByActiviy = (formValues) => {
    return async(dispatch) => {
        const resp = await fetchWithToken('api/Classification/GetAllClassifyByRegimenActivity', formValues, 'POST');
        const body = await resp.json();
        if(body.success){
            loadingModal(false);
            dispatch(clavProdServByActivity( body.data ));
        } else {
            let msgErrors = "";
            if(body.errors){
              Object.keys(body.errors).forEach(function(key) {
                msgErrors += `${body.errors[key]}\n`
              });
            }else{
              msgErrors = body.error;
            }
            swal('Ocurrió un error', msgErrors, 'error');
          }
          setTimeout(() => {
            loadingModal(false)
          }, 2500);
    }
}

const clavProdServByActivity = (classify) => ({
    type: types.clavProdServConfig,
    payload: classify
})

export const getClavProdNotValidated = (formValues) => {
    return async(dispatch) => {
        const resp = await fetchWithToken('api/Classification/GetAllClassifyNotValidatedByRegimenActivity', formValues, 'POST');
        const body = await resp.json();
        if(body.success){
            loadingModal(false);
            dispatch(clavProdServNotValidated( body.data ));
        } else {
            let msgErrors = "";
            if(body.errors){
              Object.keys(body.errors).forEach(function(key) {
                msgErrors += `${body.errors[key]}\n`
              });
            }else{
              msgErrors = body.error;
            }
            swal('Ocurrió un error', msgErrors, 'error');
          }
          setTimeout(() => {
            loadingModal(false)
          }, 2500);
    }
}

const clavProdServNotValidated = (classifyNotValidated) => ({
    type: types.clavProdServNotValidated,
    payload: classifyNotValidated
})

export const getAllClaveProdServ = () => {
    return async(dispatch) => {
        const resp = await fetchWithApiKey('api/Catalogs/c_ClaveProdServs');
        const body = await resp.json();
        if(body.success) {
            dispatch(clavProdServ( body.data ));
        }

    }
};

const clavProdServ = ( allClaveProdServ) => ({
    type: types.clavProdServStartLoading,
    payload: allClaveProdServ
})
