export const certificatesNotFiels = (certificates) => {
  let options = [];
  certificates.forEach(certificate => {
    if (!certificate.isFiel) {
      options.push(certificate);
    }
  });
  return options;
}

export const certificatesIsFiels = (certificates) => {
  let options = [];
  certificates.forEach(certificate => {
    if (certificate.isFiel) {
      options.push(certificate);
    }
  });
  return options;
}

export const complianceOpinion = (taxfiles) => {
  let options = [];
  taxfiles.forEach(taxfile => {
    if (taxfile.documentName === "Opinión de Cumplimiento" || taxfile.documentName === "Acuse de RFC") {
      options.push(taxfile);
    }
  });
  return options;
}

