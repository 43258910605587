import { types } from "../../types/types";

const initialState = {
  paymentMethods: []
}

export const paymentMethodReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.paymentMethodCfdisStartLoading:
      return{
        ...state,
        paymentMethods: [ ...action.payload ]
      }

    default:
      return state;
  }
}
