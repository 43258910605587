import { types } from "../../types/types";

const initialState={
  certificates: [],
  taxfiles: []
}

export const certificateReducer = ( state=initialState, action ) => {
  switch (action.type) {
    case types.certificatesStartRegister:
      return{
        ...state,
        certificates: [
          ...state.certificates,
          ...action.payload
        ]
      }
    
    case types.certificatesStartLoading:
      return{
        ...state,
        certificates: [
          ...action.payload
        ]
      }
    
    case types.taxFilesStartLoading:
      return{
        ...state,
        taxfiles: [
          ...action.payload
        ]
      }

    default:
      return state;
  }
}
