const baseUrlApi = process.env.REACT_APP_API_URL;
const baseUrlApiBilling = process.env.REACT_APP_BILLING_API_URL;
const apiKey = process.env.REACT_APP_API_KEY;
const apiKeyBilling = process.env.REACT_APP_BILLING_API_KEY;

const fetchWithoutToken = (endpoint, data = null, method = 'GET') => {

  const url = `${baseUrlApi}/${endpoint}`;

  if (method === 'GET') {
    return fetch(url);
  } else {
    return fetch(url, {
      method,
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
  }
}


const fetchWithToken = (endpoint, data = null, method = 'GET') => {

  const url = `${baseUrlApi}/${endpoint}`;
  const token = localStorage.getItem('token') || '';
  if (method === 'GET') {
    return fetch(url, {
      method,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    });
  } else {
    return fetch(url, {
      method,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      },
      body: JSON.stringify(data)
    })
      .catch(function (error) {
      });
  }
}


const fetchWithApiKey = (endpoint, data = null, method = 'GET') => {
  const url = `${baseUrlApi}/${endpoint}`;
  if (method === 'GET') {
    return fetch(url, {
      method,
      headers: {
        'Authorization': 'Bearer ' + apiKey
      }
    });
  } else {
    return fetch(url, {
      method,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + apiKey
      },
      body: JSON.stringify(data)
    })
  }
}

const fetchWithApiKeyBilling = (endpoint, data = null, method = 'GET', token) => {
  const url = `${baseUrlApiBilling}/${endpoint}`;
  if (!token) {
    token = apiKeyBilling
  }

  if (method === 'GET') {
    return fetch(url, {
      method,
      headers: {
        'Authorization': 'Bearer ' + token
      }
    });
  } else {
    return fetch(url, {
      method,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      },
      body: JSON.stringify(data)
    })
  }
}

const fetchWithTokenFormData = (endpoint, data = null, method = 'GET') => {

  const url = `${baseUrlApi}/${endpoint}`;
  const token = localStorage.getItem('token') || '';

  let formData = new FormData();

  Object.keys(data).forEach(function (key) {
    formData.append(key, data[key]);
  });

  if (method === 'GET') {
    return fetch(url, {
      method,
      headers: {
        'Authorization': 'Bearer ' + token,
      }
    });
  } else {
    return fetch(url, {
      method,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      },
      body: formData
    })
  }
}

const fetchWithTokenFormDataBilling = (endpoint, formData = null, method = 'GET', token) => {
  const url = `${baseUrlApiBilling}/${endpoint}`;

  if (method === 'GET') {
    return fetch(url, {
      method,
      headers: {
        'Authorization': 'Bearer ' + token,
      }
    });
  } else {
    return fetch(url, {
      method,
      headers: {
        'Authorization': 'Bearer ' + token,
      },
      body: formData
    })
  }
}

const fetchWithTokenFormDataExist = (endpoint, formData = null, method = 'GET') => {
  const url = `${baseUrlApi}/${endpoint}`;
  // const url = `http://localhost:3001${ endpoint }`;
  const token = localStorage.getItem('token') || '';

  if (method === 'GET') {
    return fetch(url, {
      method,
      headers: {
        'Authorization': 'Bearer ' + token,
      }
    });
  } else {
    return fetch(url, {
      method,
      headers: {
        'Authorization': 'Bearer ' + token
      },
      body: formData
    })
  }
}

const fetchWithTokenWithFile = (endpoint, formData = null, method = 'GET') => {
  const url = `${baseUrlApi}/${endpoint}`;
  const token = localStorage.getItem('token') || '';

  if (method === 'GET') {
    return fetch(url, {
      method,
      headers: {
        'Authorization': 'Bearer ' + token,
      }
    });
  } else {
    return fetch(url, {
      method,
      headers: {
        'Authorization': 'Bearer ' + token,
      },
      body: formData
    });
  }
}


export {
  fetchWithApiKey,
  fetchWithoutToken,
  fetchWithApiKeyBilling,
  fetchWithTokenFormData,
  fetchWithTokenFormDataExist,
  fetchWithTokenFormDataBilling,
  fetchWithToken,
  fetchWithTokenWithFile
}
