import { types } from "../../types/types";

const initialState = {
  wayOfPayments: []
}

export const wayOfPaymentReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.wayOfPaymentCfdisStartLoading:
      return{
        ...state,
        wayOfPayments: [ ...action.payload ]
      }

    default:
      return state;
  }
}
