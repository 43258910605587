import React, { useEffect, useState, useContext } from 'react';
import Table from '@material-ui/core/Table';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import { useDispatch, useSelector } from 'react-redux';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { paymentApi } from '../../../../actions/catalogs/accounters';
import { TableBody, TextField, Box } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { ModalContext } from '../../../modal/ModalContext';
import { ValidatePassword } from '../../crm/sales/PreSale/ValidatePassword';
import Tooltip from '@material-ui/core/Tooltip';


export const ValidatePayment = () => {
    const [loading, setLoading] = useState(false);
    const [showComponent, setShowComponent] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const dispatch = useDispatch();
    const { user } = useSelector(state => state.auth);
    const datos = useSelector(state => state.paymentApiData.data);
    const dataList = datos ? Object.values(datos) : [];
    const { handleModal } = useContext(ModalContext);
    const totalPages = 100;
    const pagesToShow = 4;
    const [search, setSearch] = useState("");
    const [search2, setSearch2] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    let result = [];


    useEffect(() => {
        if (!(user.descriptionRole === "Accounter" && user.isBoss === true)) {
            setTimeout(() => {
                window.location.href = '/';
            }, 3000);
        }
    }, [user, Link]);

    const handleSearchChange = (event) => {
        setSearch2(event.target.value);
    };

    const handleSearchClick = () => {
        setSearchTerm(search2);
    };

    useEffect(() => {
        if (searchTerm !== '') {
            dispatch(paymentApi(null, searchTerm));
        }
    }, [dispatch, searchTerm, pageNumber]);

    const handlePreviousPage = () => {
        if (pageNumber > 1) {
            setPageNumber(pageNumber - 1);
        }
    };

    const handleNextPage = () => {
        if (pageNumber < totalPages - pagesToShow + 1) {
            setPageNumber(pageNumber + 1);
        }
    };

    const handlePageChange = (page) => {
        setPageNumber(page);
    };

    const startPage = pageNumber;
    const endPage = Math.min(startPage + pagesToShow - 1, totalPages);

    useEffect(() => {
        dispatch(paymentApi(pageNumber, null));
    }, [dispatch, pageNumber]);

    const searcher = (e) => {
        setSearch(e.target.value);
    };

    if (!search) {
        result = dataList;
    } else {
        result = dataList.filter((dato) =>
            dato.clientName.toLowerCase().includes(search.toLowerCase()) ||
            dato.clientEmail.toLowerCase().includes(search.toLowerCase()) ||
            dato.sellerName.toLowerCase().includes(search.toLowerCase()) ||
            dato.sellerEmail.toLowerCase().includes(search.toLowerCase()) ||
            dato.statusPayment.toLowerCase().includes(search.toLowerCase())
        );
    }


    result = result.sort((a, b) => {
        if (a.statusPayment === "StandBy" && b.statusPayment !== "StandBy") return -1;
        if (a.statusPayment !== "StandBy" && b.statusPayment === "StandBy") return 1;
        const dateA = new Date(a.paymentRequestDate);
        const dateB = new Date(b.paymentRequestDate);
        return dateB - dateA;
    });

    const conluirTramite = (saleId) => {
        handleModal(<ValidatePassword saleId={saleId} />);
    };

    return (
        <div style={{ margin: '10px', overflow: 'hidden' }}>
            {
                !(user.descriptionRole === "Accounter" && user.isBoss === true) ?
                    (
                        <div>
                            Usuario no autorizado para esta vista
                        </div>
                    )
                    :
                    (
                        <div>
                            <Box display="flex" alignItems="center" justifyContent='space-between' marginBottom="10px" style={{ width: '100%' }}>
                                <Tooltip title="Solo buscará usuarios en la pagina actual">
                                    <TextField
                                        onChange={searcher}
                                        value={search}
                                        variant="outlined"
                                        placeholder="Buscar usuario en la página actual"
                                        style={{ marginRight: '10px', width: '40%' }}
                                    />
                                </Tooltip>
                                <Tooltip title="Buscar usuario en todos los registros existentes">
                                    <TextField
                                        onChange={handleSearchChange}
                                        value={search2}
                                        variant="outlined"
                                        placeholder="Buscar usuario general"
                                        style={{ marginRight: '10px', width: '40%' }}
                                    />
                                </Tooltip>
                                <Button
                                    onClick={handleSearchClick}
                                    variant="contained"
                                    color="primary"
                                    style={{ borderRadius: '20px', marginRight: '3px' }}
                                >
                                    Buscar
                                </Button>
                                <nav aria-label="Page navigation example">
                                    <ul className="pagination justify-content-end" style={{ margin: 0 }}>
                                        <li className={`page-item ${pageNumber === 1 ? 'disabled' : ''}`}>
                                            <a className="page-link" onClick={handlePreviousPage}>Anterior</a>
                                        </li>
                                        {[...Array(endPage - startPage + 1)].map((_, i) => (
                                            <li className={`page-item ${pageNumber === startPage + i ? 'active' : ''}`} key={i}>
                                                <a className="page-link" onClick={() => handlePageChange(startPage + i)}>{startPage + i}</a>
                                            </li>
                                        ))}
                                        <li className={`page-item ${pageNumber >= totalPages - pagesToShow + 1 ? 'disabled' : ''}`}>
                                            <a className="page-link" onClick={handleNextPage}>Siguiente</a>
                                        </li>
                                    </ul>
                                </nav>
                            </Box>
                            <TableContainer style={{ borderRadius: '10px', maxHeight: '780px' }}>
                                <Table className="table table-responsive" size="small" aria-label="sticky table" stickyHeader>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell colSpan={12} align="center">
                                                <h2 style={{ whiteSpace: 'nowrap' }}>Validación de pagos</h2>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>

                                    <TableHead>
                                        <TableRow>
                                            <TableCell class="font-weight-bold" style={{ textAlign: 'center', width: '30px' }}>ID</TableCell>
                                            <TableCell class="font-weight-bold" style={{ textAlign: 'center' }}>Nombre del cliente</TableCell>
                                            <TableCell class="font-weight-bold" style={{ textAlign: 'center' }}>correo del cliente</TableCell>
                                            <TableCell class="font-weight-bold" style={{ textAlign: 'center' }}>Nombre del vendedor</TableCell>
                                            <TableCell class="font-weight-bold" style={{ textAlign: 'center' }}>Fecha de solicitud de pago</TableCell>
                                            <TableCell class="font-weight-bold" style={{ textAlign: 'center' }}>Fecha de pago</TableCell>
                                            <TableCell class="font-weight-bold" style={{ textAlign: 'center' }}>Estatus de pago</TableCell>
                                            <TableCell class="font-weight-bold" style={{ textAlign: 'center' }}>Validó pago</TableCell>
                                            <TableCell class="font-weight-bold" style={{ textAlign: 'center' }}>Metodo de pago</TableCell>
                                            <TableCell class="font-weight-bold" style={{ textAlign: 'center' }}>Total</TableCell>
                                            <TableCell class="font-weight-bold" style={{ textAlign: 'center' }}>Datos del movimiento</TableCell>
                                            {/* <TableCell class="font-weight-bold" style={{ textAlign: 'center' }}>Referencia</TableCell> */}
                                            {/* <TableCell class="font-weight-bold" style={{ textAlign: 'center' }}>Comprobante</TableCell> */}
                                            <TableCell class="font-weight-bold" style={{ textAlign: 'center' }}>Validación de pago</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            result.length === 0 ?
                                                (<TableRow>
                                                    <TableCell colSpan="10">
                                                        <div className="alert alert-info text-center" role="alert">
                                                            No hay contribuyentes con dichos filtros
                                                        </div>
                                                    </TableCell>
                                                </TableRow>)
                                                :
                                                (
                                                    result.map((report, index) => {
                                                        return (
                                                            <TableRow key={index} style={{}}>
                                                                <TableCell component="th" scope="row" style={{ color: 'black', textAlign: 'center', width: '30px' }}>{report.idPayment}</TableCell>
                                                                <TableCell component="th" scope="row" style={{ color: 'black', textAlign: 'center' }}>{report.clientName}</TableCell>
                                                                <TableCell component="th" scope="row" style={{ color: 'black', textAlign: 'center' }}>{report.clientEmail}</TableCell>
                                                                <TableCell component="th" scope="row" style={{ color: 'black', textAlign: 'center' }}>{report.sellerName}</TableCell>
                                                                <TableCell component="th" scope="row" style={{ color: 'black', textAlign: 'center' }}>{report.paymentRequestDate}</TableCell>
                                                                <TableCell component="th" scope="row" style={{ color: 'black', textAlign: 'center' }}>{report.paymentDate}</TableCell>
                                                                <TableCell component="th" scope="row" style={{ color: 'black', textAlign: 'center' }}>{report.statusPayment}</TableCell>
                                                                <TableCell component="th" scope="row" style={{ color: 'black', textAlign: 'center' }}>{report.attended}</TableCell>
                                                                <TableCell component="th" scope="row" style={{ color: 'black', textAlign: 'center' }}>{report.paymentWay}</TableCell>
                                                                <TableCell component="th" scope="row" style={{ color: 'black', textAlign: 'center' }}>{report.paymentTotal}</TableCell>
                                                                <TableCell component="th" scope="row" style={{ fontSize: '80%', whiteSpace: 'nowrap', overflow: 'hidden', color: 'black' }}>Cuenta receptora:{report.receptorAccount}<br />Banco receptor: {report.receptorBank}<br />CLABE receptora: {report.receptorClabe}<br />Referencia de pago:{report.paymentReference}<br />Rastreo: {report.paymentTransaction}</TableCell>
                                                                {/* <TableCell component="th" scope="row" style={{ fontSize: '80%', whiteSpace: 'nowrap', overflow: 'hidden', color: 'black' }}>Referencia de pago:{report.paymentReference}<br />Rastreo: {report.paymentTransaction}</TableCell> */}
                                                                {/* <TableCell>
                                                                    {report.linkPdf && report.linkPdf !== "" ? (
                                                                        <a href={report.linkPdf} download>
                                                                            <button>Click to download</button>
                                                                        </a>
                                                                    ) :
                                                                        <h6 style={{ color: 'black', textAlign: 'center' }}>
                                                                            Sin comprobante
                                                                        </h6>
                                                                    }
                                                                </TableCell> */}

                                                                {
                                                                    user.isBoss == true ?
                                                                        (
                                                                            <TableCell align="center">

                                                                                {
                                                                                    report.statusPayment === "StandBy" ?
                                                                                        (
                                                                                            <Button variant='contained' color='primary' style={{ marginTop: '2px' }}
                                                                                                onClick={() =>
                                                                                                    conluirTramite(report.idPayment)
                                                                                                }
                                                                                            >
                                                                                                Validar pago
                                                                                            </Button>
                                                                                        )
                                                                                        :
                                                                                        (
                                                                                            <div className="alert alert-success" role="alert">
                                                                                                ¡Pago verificado!
                                                                                            </div>
                                                                                        )
                                                                                }
                                                                            </TableCell>
                                                                        ) :
                                                                        (
                                                                            <TableCell align="center">

                                                                                <div className="alert alert-success" role="alert">
                                                                                    ¡Usuario no autorizado!
                                                                                </div>

                                                                            </TableCell>
                                                                        )
                                                                }

                                                            </TableRow>
                                                        );
                                                    })
                                                )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                    )
            }
        </div>
    );
};