import React from 'react';

const Home = () => {
    return (
        <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2">
                <p className="font-weight-bold">
                    Por favor complete la información que te pedimos en los pasos del 1 al para que contenmos con toda la información necesaria para calcular tu declaración anual.
                </p>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2">
                <h4>Meses sin actividad</h4>
                <p>
                    Los siguientes meses del año no presentan actividad alguna. Verifica que esta información sea correcta. Si es así, continua al siguiente paso.
                </p>
                <div className="table-responsive">
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th>Mes</th>
                                <th>Total Recibido</th>
                                <th>Total Gastado</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th>Enero 2020</th>
                                <th>$ 0.00</th>
                                <th>$ 0.00</th>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2">
                <h4>Pagos Provisionales</h4>
                <p>
                    Estos son todos los pagos provisionales que has realizado durante el año. Verifica que hayas registrado todas las fechas de pago o declaraciones provisionales en ceros.
                </p>
                <div className="table-responsive">
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th>Mes</th>
                                <th>Fecha de pago</th>
                                <th>IVA</th>
                                <th>ISR</th>
                                <th>Total</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th>Enero 2020</th>
                                <th><p className="alert alert-danger">No tenemos registro de tu pago</p></th>
                                <th>$ 0.00</th>
                                <th>$ 0.00</th>
                                <th>$ 0.00</th>
                                <th>
                                    <button className="btn btn-info btn-sm">Registrar pago</button>
                                </th>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2">
                <p className="font-weight-bold">
                    Es muy importante que hayas realizado todos los pagos provisionales o declaraciones en cero, pues de otra forma no podras presentar la Declaración Anual.
                </p>
            </div>
        </div>
    )
}

export default Home;
