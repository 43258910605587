export const prepareNewSales = (originalData, setFormValueSalesIndex = null) => {
  let actives = 0;
  let notActives = 0;
  let sales = [];
  originalData.forEach(sale => {
    if (sale.isAuthorized) {
      actives++;
    }else{
      notActives++;
    }
    const prepareData={
      ...sale,
      active:sale.isAuthorized == 1 ? 'si': 'no',
      activeApp:sale.activeApp == 1 ? 'si': 'no',
      productos:sale.products[0]
      // email: sale.user.email,
      // fullName: `${sale.user.name} ${sale.user.lastName} ${sale.user.motherLastName}`,
      // rfc: !!sale.user.profiles.length > 0 ? sale.user.profiles[0].rfc : ''
    }
    sales.push(prepareData);
  });

  setFormValueSalesIndex && setFormValueSalesIndex(prevState => {
    return{
      ...prevState,
      newUserActives: actives,
      newUserNotActives: notActives,
    }
  })

  return sales;
}

export const prepareNewSalesNotActive = (originalData, setFormValueSalesIndex = null) => {
  let sales = [];
  originalData.forEach(sale => {
    if (!sale.isAuthorized) {
     
      const prepareData={
        ...sale,
        active:'no',
        productos:sale.products[0]
      }
     sales.push(prepareData);
    }
  });
  setFormValueSalesIndex && setFormValueSalesIndex(prevState => {
    return{
      ...prevState
    }
  })

  return sales;
}

export const prepareNewSalesActive = (originalData, setFormValueSalesIndex = null) => {
  let sales = [];
  originalData.forEach(sale => {
    if (sale.isAuthorized) {
     
      const prepareData={
        ...sale,
        active:'si',
        productos:sale.products[0]
      }
     sales.push(prepareData);
    }
  });
  setFormValueSalesIndex && setFormValueSalesIndex(prevState => {
    return{
      ...prevState
    }
  })

  return sales;
}


export const prepareAccountingSales = (originalData, setFormValueSalesIndex = null) => {
  const accountingProducts = [];
  let actives = 0;
  let notActives = 0;

  originalData.forEach(sale => {
    if(sale.products[0].product.productType.key === 'Accounting'){
      const prepareData={
        ...sale,
        active:sale.isAuthorized == 1 ? 'si': 'no',
        productos: sale.products[0].product,
        userEmail: sale.user.email,
        userFullName: `${sale.user.name} ${sale.user.lastName} ${sale.user.motherLastName}`,
        rfc: !!sale.user.profiles.length > 0 ? sale.user.profiles[0].rfc : ''
      }
      if (sale.isAuthorized) {
        actives++;
      }else{
        notActives++;
      }
      accountingProducts.push(prepareData)
    }
  });

  setFormValueSalesIndex &&
    setFormValueSalesIndex(prevState => {
      return{
        ...prevState,
        accountingActives: actives,
        accoutingNotActives: notActives,
      }
    })
  return accountingProducts;
}

export const prepareInvoicingSales = (originalData, setFormValueSalesIndex = null) => {
  const invoicingProducts = [];
  let actives = 0;
  let notActives = 0;

  originalData.forEach(sale => {
    if(sale.products[0].product.productType.key === 'Stamp'){
      const prepareData={
        ...sale,
        email: sale.user.email,
        fullName: `${sale.user.name} ${sale.user.lastName} ${sale.user.motherLastName}`,
        rfc: !!sale.user.profiles.length > 0 ? sale.user.profiles[0].rfc : ''
      }
      if (sale.isAuthorized) {
        actives++;
      }else{
        notActives++;
      }
      invoicingProducts.push(prepareData)
    }
  });

  setFormValueSalesIndex &&
    setFormValueSalesIndex(prevState => {
      return{
        ...prevState,
        invoicingActives: actives,
        invoicingNotActives: notActives,
      }
    })
  return invoicingProducts;
}