import React from 'react';
import TextField from '@material-ui/core/TextField';

import Perceptions from './DetailsNav/Perceptions';
import Deductions from './DetailsNav/Deductions';
import OtherPayments from './DetailsNav/OtherPayments';
import Disabilities from './DetailsNav/Disabilities';
import Extra from './DetailsNav/Extra';
import Sncf from './DetailsNav/Sncf';

export const DetailsEmployee = () => {
    return (
        <div>
            <h5 className="card-header">Detalles</h5>
            <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-3">
                    <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                            <TextField label="No. Nómina" variant="outlined" size="small" name="names" fullWidth={true}/>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
                            <TextField label="Empleado" variant="outlined" size="small" name="names" fullWidth={true}/>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-4">
                            <TextField label="Curp" variant="outlined" size="small" name="names" fullWidth={true}/>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-4">
                            <TextField label="Días Pagados" variant="outlined" size="small" name="names" fullWidth={true}/>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                            <nav className="mt-4">
                                <div className="nav nav-tabs" id="nav-tab" role="tablist">
                                    <a className="nav-item nav-link active" id="nav-percepciones-tab" data-toggle="tab" href="#nav-percepciones" role="tab" aria-controls="nav-percepciones" aria-selected="true">
                                        Percepciones
                                    </a>
                                    <a className="nav-item nav-link" id="nav-deducciones-tab" data-toggle="tab" href="#nav-deducciones" role="tab" aria-controls="nav-deducciones" aria-selected="true">
                                        Deducciones
                                    </a>
                                    <a className="nav-item nav-link" id="nav-otro-pagos-tab" data-toggle="tab" href="#nav-otro-pagos" role="tab" aria-controls="nav-otro-pagos" aria-selected="true">
                                        Otro Pagos
                                    </a>
                                    <a className="nav-item nav-link" id="nav-incapacidades-tab" data-toggle="tab" href="#nav-incapacidades" role="tab" aria-controls="nav-incapacidades" aria-selected="true">
                                        Incapacidades
                                    </a>
                                    <a className="nav-item nav-link" id="nav-extra-tab" data-toggle="tab" href="#nav-extra" role="tab" aria-controls="nav-extra" aria-selected="true">
                                        Extra
                                    </a>
                                    <a className="nav-item nav-link" id="nav-sncf-tab" data-toggle="tab" href="#nav-sncf" role="tab" aria-controls="nav-sncf" aria-selected="true">
                                        Sncf
                                    </a>
                                </div>
                            </nav>
                            <div className="tab-content" id="nav-tabContent">
                                <div className="tab-pane fade show active" id="nav-percepciones" role="tabpanel" aria-labelledby="nav-percepciones-tab">
                                    <Perceptions />
                                </div>
                                <div className="tab-pane fade" id="nav-deducciones" role="tabpanel" aria-labelledby="nav-deducciones-tab">
                                    <Deductions />
                                </div>
                                <div className="tab-pane fade" id="nav-otro-pagos" role="tabpanel" aria-labelledby="nav-otro-pagos-tab">
                                    <OtherPayments />
                                </div>
                                <div className="tab-pane fade" id="nav-incapacidades" role="tabpanel" aria-labelledby="nav-incapacidades-tab">
                                    <Disabilities />
                                </div>
                                <div className="tab-pane fade" id="nav-extra" role="tabpanel" aria-labelledby="nav-extra-tab">
                                    <Extra />
                                </div>
                                <div className="tab-pane fade" id="nav-sncf" role="tabpanel" aria-labelledby="nav-sncf-tab">
                                    <Sncf />
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <p>Total a pagar: $ 0.00</p>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <button className="btn btn-primary btn-sm float-right">
                                <i className="far fa-eye"></i> Vista Previa
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
