import { types } from "../../types/types";

const initialState = {
  crmPromotions:'',
}

export const crmPromotionsReducer = (state = initialState, action) => {
  
    switch (action.type) {

    case types.crmReferenceCodePromotion:
      return{
        ...state,
        crmPromotions: action.payload,
      }
   
    default:
      return state;
  }
}
