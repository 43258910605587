import React, { useEffect, Fragment, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Checkbox, FormControl, InputLabel, Select, MenuItem, ListItemText, FormHelperText, TextField } from '@material-ui/core';
import swal from 'sweetalert';
import { useForm } from '../../../../../hooks/useForm';
import { LogoContabilizate } from './LogoContabilizate';
import { AvisoPrivacidad } from './AvisoPrivacidad';
import { regimesStartLoading } from '../../../../../actions/catalogs/regimes';
import { professionTypesStartLoading } from '../../../../../actions/catalogs/professions';
import { crmPreSaleStartSelected, startInfoTaxPreSale } from '../../../../../actions/crm/preSales';
import { validateArrayPresence, validateStringPresence, validateNumberPresence } from '../../../../../helpers/validateForm';
import { useParams, useHistory } from 'react-router';
import moment from 'moment'
import { CRM_SALES } from '../../../../constants/Routes';
import { activitiesStartLoading } from '../../../../../actions/catalogs/activities';

export const InformationTaxpayer = () => {

    const dispatch = useDispatch();
    const { token } = useParams();
    let history = useHistory();

    const { professionTypes } = useSelector(state => state.professionType);
    const { regimes } = useSelector(state => state.regime);
    const { crmSalePreSelectedInfo } = useSelector(state => state.crmPreSale);
    const { activities } = useSelector(state => state.activities);

    const [msgErrors, setMsgErrors] = useState({});
    const [disabledText, SetDisabledText] = useState(false);

    const [regimenId, setRegimen] = useState(0);
    const [activityId, setActivity] = useState(0);
    const [nameProfession, setName] = useState('');
    const [professionId, setProfession] = useState(0);
    const [listActivities, setList] = useState([]);
    const [listProfessions, setListProfession] = useState([]);

    useEffect(() => {
        dispatch(activitiesStartLoading());
    }, [])

    const { formValues, setFormValues } = useForm(
        {
            token: '',
            taxPayerData: {
                address: {
                    profileId: 0,
                    street: '',
                    interior: '',
                    outdoor: '',
                    postalCode: '',
                    suburb: '',
                    town: '',
                    country: ''
                },
                serviceTypes: [1],
                zoneTypes: [1],
                bussinessName: '',
                curp: '',
                rfc: '',
                phoneMobile: '',
                passwordSAT: '',
                heigherIncome: true,
                startDeclarationAt: moment().format('YYYY-MM-DD'),
                startSatAt: moment().format('YYYY-MM-DD'),
                emailNotification: '',
                idOrigin: 0,
                regimeIdsActivityIdsProfessionIds: []
            }
        }
    )

    //nuevas variables de acceso a los datos
    const { taxPayerData, address, street, interior, outdoor, postalCode, suburb, town, country,
        serviceTypes, zoneTypes, bussinessName, curp, rfc, phoneMobile, passwordSAT, heigherIncome, startDeclarationAt, startSatAt,
        emailNotification, idOrigin, regimeIdsActivityIdsProfessionIds } = formValues;

    //traemos de la base de datos la información ya registrada
    useEffect(() => {
        if (token) {
            dispatch(crmPreSaleStartSelected(token))
        }
    }, [token])

    useEffect(() => {
        if (crmSalePreSelectedInfo) {
            if (crmSalePreSelectedInfo.stepsJson && crmSalePreSelectedInfo.stepsJson.stepTaxPayerData) {

                let data = crmSalePreSelectedInfo.stepsJson.stepTaxPayerData.profileCreateRequest;
                let regimes = []

                data.regimes.forEach((regime) => {
                    regimes.push(parseInt(regime.regimeId))
                })

                let regimesData = crmSalePreSelectedInfo.stepsJson.stepTaxPayerData.profileCreateRequest.regimeIdsActivityIdsProfessionIds;

                regimesData.map((data) => {
                    setRegimen(data.id);
                    data.activities.map((activity) => {
                        renderActivityAndProfession(activity.id)
                    })
                })

                SetDisabledText(true)
                setFormValues(prevState => {
                    return {
                        ...prevState,
                        taxPayerData: {
                            address: {
                                street: data.address ? (data.address.street ?? '') : '',
                                interior: data.address ? (data.address.interior ?? '') : '',
                                outdoor: data.address ? (data.address.outdoor ?? outdoor) : '',
                                postalCode: data.address ? (data.address.postalCode ?? '') : '',
                                suburb: data.address ? (data.address.suburb ?? '') : '',
                                town: data.address ? (data.address.town ?? '') : '',
                                country: data.address ? (data.address.country ?? '') : ''
                            },
                            serviceTypes: [1],
                            zoneTypes: [1],
                            bussinessName: data && (data.bussinessName ?? ''),
                            curp: data && (data.curp ?? ''),
                            startDeclarationAt: moment(data.startDeclarationAt).format('YYYY-MM-DD'),
                            startSatAt: moment(data.startSatAt).format('YYYY-MM-DD'),
                            emailNotification: data && (data.emailNotification ?? ''),
                            regimeIdsActivityIdsProfessionIds: regimenId && activityId && professionId && (regimesData ?? [])
                        }
                    }
                })
            }
        }
    }, [crmSalePreSelectedInfo])

    //traemos de la db la lista de regimenes y si no hay nada mostramos la data del render
    useEffect(() => {
        if (regimes.length <= 0) {
            dispatch(regimesStartLoading());
            //comprobamos que inserte el regimen que traiga ya registrado
            //setRegimen(2);
        }
        // else {
        //     renderSelectMultiple();
        // }
    }, [dispatch, regimes])

    //traemos los datos de las profesiones de la base de datos
    useEffect(() => {
        if (professionTypes.length === 0) {
            dispatch(professionTypesStartLoading())
        }
    }, [dispatch, professionTypes])

    //funcion para enviar la info
    const handleRegister = (e) => {
        e.preventDefault();
        if (isFormValid()) {
            //console.log({ token, taxPayerData }, history, CRM_SALES)
            dispatch(startInfoTaxPreSale({ token, taxPayerData }, history, CRM_SALES))
        }
    }

    //validacion del formulario
    const isFormValid = () => {
        setMsgErrors({});
        let stringToValidate = {
            emailNotification: taxPayerData.emailNotification,
            curp: taxPayerData.curp,
            bussinessName: taxPayerData.bussinessName,
            postalCode: taxPayerData.address.postalCode,
            street: taxPayerData.address.street,
            interior: taxPayerData.address.interior,
            outdoor: taxPayerData.address.outdoor,
            suburb: taxPayerData.address.suburb,
            town: taxPayerData.address.town,
            country: taxPayerData.address.country,
        }


        // const arraysToValidate = { regimes: regimeIdsActivityIdsProfessionIds }
        const numberToValidate = { professionId: taxPayerData.professionId }
        const regimenToValidate = { regimenId: regimenId }
        const professionToValidate = { professionId: professionId }
        const activityToValidate = { activityId: activityId }

        const validateString = validateStringPresence(stringToValidate, setMsgErrors);
        //const validateAarray = validateArrayPresence(arraysToValidate, setMsgErrors);
        const validateNumber = validateNumberPresence(numberToValidate, setMsgErrors);
        const validateRegimen = validateNumberPresence(regimenToValidate, setMsgErrors);
        const validateProfession = validateNumberPresence(professionToValidate, setMsgErrors);
        const validateActivity = validateNumberPresence(activityToValidate, setMsgErrors);

        if (validateString &&
            //validateAarray &&
            validateNumber &&
            validateRegimen &&
            validateProfession &&
            validateActivity) {
            return true;
        }

        swal('Error al guardar', 'Se detectaron errores, favor de revisar', 'error');
        return false;
    }

    //variable para registrar los cambios dentro de taxPayerData
    const handleInputChange = ({ target }) => {
        const { name, value } = target;
        let newValue = value;
        if (name === 'emailNotification') {
            // Dejar el valor del campo de entrada sin cambios si el nombre es "email"
            newValue = value;
        } else {
            // Convertir el valor del campo de entrada en mayúscula si el nombre no es "email"
            newValue = value.toUpperCase();
        }
        setFormValues(prevState => {
            return {
                ...prevState,
                taxPayerData: {
                    ...prevState.taxPayerData,
                    [name]: newValue
                }
            }
        })
    }

    //variable para editar los campos relacionados de address
    const handleAddressChange = ({ target }) => {
        const { name, value } = target;

        let newValue = value;
        if (name === '') {
            // Dejar el valor del campo de entrada sin cambios si el nombre es "email"
            newValue = value;
        } else {
            // Convertir el valor del campo de entrada en mayúscula si el nombre no es "email"
            newValue = value.toUpperCase();
        }

        setFormValues(prevState => {
            return {
                ...prevState,
                taxPayerData: {
                    ...prevState.taxPayerData,
                    address: {
                        ...prevState.taxPayerData.address,
                        [name]: newValue
                    }
                }

            }
        })
    }

    //selector multiple, registra todos los seleccionados en el arreglo
    const handleChangeSelectMultiple = (e) => {
        const { value } = e.target;
        setFormValues(prevState => {
            return {
                ...prevState,
                regimeIdsActivityIdsProfessionIds: [
                    ...value
                ]
            }
        })
    }

    const handleChangeRegimen = (e) => {
        const { value } = e.target;
        let idRegimen = parseInt(value, 10);
        setRegimen(idRegimen);

        const result = regimes.find((clave) => clave.id === idRegimen);

        if (result && result.activityIds) {
            const activityIds = result.activityIds.split(',').map(id => parseInt(id.trim()));

            if (activityIds) {
                const matchActivities = activities.filter(activity => activityIds.includes(activity.id));
                setList(matchActivities);
            }
        }
    };

    const handleChangeActivity = (e) => {
        const { value } = e.target;
        const idActivity = parseInt(value, 10);

        const matchingActivity = activities.find((activity) => activity.id === idActivity);

        const actividad = idActivity;
        let profesion = 0;


        if (matchingActivity) {
            const professionId = parseInt(matchingActivity.professionIds, 10);

            const matchingProfession = professionTypes.find((profession) => profession.id === professionId);

            if (matchingProfession) {
                profesion = matchingProfession.id;
                setName(matchingProfession.key);
            }
        }

        updateDataRegimes(actividad, profesion);
    };

    const renderActivityAndProfession = (data) => {
        const value = data;
        const idActivity = parseInt(value, 10);

        const matchingActivity = activities.find((activity) => activity.id === idActivity);

        const actividad = idActivity;
        let profesion = 0;


        if (matchingActivity) {
            const professionId = parseInt(matchingActivity.professionIds, 10);

            const matchingProfession = professionTypes.find((profession) => profession.id === professionId);

            if (matchingProfession) {
                profesion = matchingProfession.id;
                setName(matchingProfession.key);
            }
        }

        updateDataRegimes(actividad, profesion);
    };


    const updateDataRegimes = (actividad, profesion) => {
        const newRegimeId = regimenId;
        const newActivityId = actividad;
        const newProfessionId = profesion;
        setActivity(newActivityId);
        setProfession(newProfessionId);

        const newRegime = {
            id: newRegimeId,
            activities: [
                {
                    id: newActivityId,
                    professions: [
                        {
                            id: newProfessionId,
                        },
                    ],
                },
            ],
        };

        setFormValues((prevState) => {
            return {
                ...prevState,
                taxPayerData: {
                    ...prevState.taxPayerData,
                    regimeIdsActivityIdsProfessionIds: [newRegime],
                },
            };
        });
    }

    return (
        <Fragment>
            <div className="container">
                {/* <StepsPreSale /> */}
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 d-flex justify-content-center my-2">
                    <div className="card col-sm-8 shadow mb-4">
                        <div className="card-body">
                            <LogoContabilizate name={"Información del contribuyente"} />

                            <nav className="mt-4 mb-4">
                                <div className="nav  nav-pills" id="nav-tab" role="tablist">
                                    <a className="nav-item nav-link show active border m-1" id="nav-customer-tab" data-toggle="tab" href="#nav-customer" role="tab" aria-controls="nav-customer" aria-selected="true">
                                        Datos del contribuyente
                                    </a>
                                    <a className="nav-item nav-link border m-1" id="nav-fiscal-tab" data-toggle="tab" href="#nav-fiscal" role="tab" aria-controls="nav-fiscal" aria-selected="true">
                                        Domicilio fiscal
                                    </a>
                                </div>
                            </nav>

                            <form className="mt-4" onSubmit={handleRegister}>
                                <div className='row'>
                                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-3">
                                        <div>
                                            <div className="tab-content" id="nav-tabContent">
                                                <div className="tab-pane fade show active " id="nav-customer" role="tabpanel" aria-labelledby="nav-customer-tab" aria-selected="true">

                                                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3">
                                                        <TextField
                                                            label="Nombre o Razón Social*"
                                                            variant="outlined"
                                                            size="small"
                                                            name="bussinessName"
                                                            value={taxPayerData.bussinessName}
                                                            onChange={handleInputChange}
                                                            autoComplete="off"
                                                            fullWidth={true}
                                                            helperText={msgErrors.bussinessName}
                                                            error={msgErrors.bussinessName !== undefined}
                                                            disabled
                                                        />
                                                    </div>

                                                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3">
                                                        <TextField
                                                            label="Correo de Notificación"
                                                            variant="outlined"
                                                            size="small"
                                                            name="emailNotification"
                                                            value={taxPayerData.emailNotification}
                                                            onChange={handleInputChange}
                                                            autoComplete="off"
                                                            fullWidth={true}
                                                            helperText={msgErrors.emailNotification}
                                                            error={msgErrors.emailNotification !== undefined}
                                                            disabled
                                                        />
                                                    </div>

                                                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3">
                                                        <TextField
                                                            label="CURP"
                                                            variant="outlined"
                                                            size="small"
                                                            name="curp"
                                                            value={taxPayerData.curp}
                                                            onChange={handleInputChange}
                                                            autoComplete="off"
                                                            fullWidth={true}
                                                            helperText={msgErrors.curp}
                                                            error={msgErrors.curp !== undefined}
                                                            disabled
                                                        />
                                                    </div>
                                                    {/* regimenes */}
                                                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3">
                                                        {/* <FormControl variant="outlined" size="small" fullWidth={true}
                                                            // helperText={ msgErrors.regimes }
                                                            error={msgErrors.regimes !== undefined}
                                                        >
                                                            <InputLabel htmlFor="regimenes-oulined">
                                                                Regímenes*
                                                            </InputLabel>
                                                            <Select
                                                                multiple
                                                                value={regimeIdsActivityIdsProfessionIds}
                                                                onChange={handleChangeSelectMultiple}
                                                                renderValue={renderSelectMultiple}
                                                                labelWidth={80}
                                                                disabled={disabledText}
                                                                MenuProps={{
                                                                    PaperProps: {
                                                                        style: {
                                                                            maxHeight: 48 * 4.5 + 8,
                                                                            width: 750,
                                                                        },
                                                                    }
                                                                }
                                                                }
                                                                inputProps={{
                                                                    name: 'regimes',
                                                                    id: "regimenes-oulined"
                                                                }}
                                                            >
                                                                <MenuItem disabled value="">
                                                                    <em>Selecciona los que apliquen</em>
                                                                </MenuItem>
                                                                {regimes.map((regime) => {
                                                                    return (regime.accounting &&
                                                                        <MenuItem key={regime.id} value={regime.id}>
                                                                            <Checkbox checked={regimesData.indexOf(regime.id) > -1} />
                                                                            <ListItemText primary={regime.officialName} />
                                                                        </MenuItem>
                                                                    )
                                                                }
                                                                )}
                                                            </Select>
                                                            <FormHelperText>{msgErrors.regimes}</FormHelperText>
                                                        </FormControl> */}
                                                        <TextField
                                                            label="Régimen*" variant="outlined" size="small" autoComplete="off" fullWidth={true}
                                                            select
                                                            SelectProps={{
                                                                native: true,
                                                            }}
                                                            name="regimenId"
                                                            value={regimenId}
                                                            onChange={handleChangeRegimen}
                                                            disabled
                                                            helperText={msgErrors.regimenId}
                                                            error={msgErrors.regimenId !== undefined}
                                                        >
                                                            {
                                                                regimes.map((regimen, index) => {
                                                                    if (regimen.activityIds) {
                                                                        return (
                                                                            <option
                                                                                key={index}
                                                                                value={regimen.id}
                                                                            >
                                                                                {regimen.officialName}
                                                                            </option>
                                                                        )
                                                                    }

                                                                })
                                                            }
                                                        </TextField>
                                                    </div>

                                                    {/* actividad */}
                                                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3">
                                                        <TextField
                                                            label="Actividad*" variant="outlined" size="small" autoComplete="off" fullWidth={true}
                                                            select
                                                            SelectProps={{
                                                                native: true,
                                                            }}
                                                            name="activityId"
                                                            value={activityId}
                                                            onChange={handleChangeActivity}
                                                            disabled
                                                            helperText={msgErrors.activityId}
                                                            error={msgErrors.activityId !== undefined}
                                                        >
                                                            {listActivities.length === 0 ? (
                                                                activities.map((activity, index) => (
                                                                    <option key={index} value={activity.id}>
                                                                        {activity.description}
                                                                    </option>
                                                                ))
                                                            ) : (
                                                                listActivities.map((activity, index) => (
                                                                    <option key={index} value={activity.id}>
                                                                        {activity.description}
                                                                    </option>
                                                                ))
                                                            )}

                                                        </TextField>

                                                    </div>

                                                    {/* profesion */}
                                                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3">
                                                        <TextField
                                                            label="Profesión*" variant="outlined" size="small" autoComplete="off" fullWidth={true}
                                                            name="professionId"
                                                            value={nameProfession}
                                                            disabled={true}
                                                            helperText={msgErrors.professionId}
                                                            error={msgErrors.professionId !== undefined}
                                                        >
                                                        </TextField>
                                                    </div>

                                                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3">
                                                        <TextField
                                                            label="Fecha de alta S.A.T." variant="outlined" size="small" autoComplete="off" fullWidth={true}
                                                            type="date"
                                                            InputLabelProps={{ shrink: true, }}
                                                            name="startSatAt"
                                                            value={taxPayerData.startSatAt}
                                                            onChange={handleInputChange}
                                                            disabled
                                                        />
                                                    </div>

                                                    {/* <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3">
                                                            <TextField
                                                            label="Fecha inicial declaración." variant="outlined" size="small" autoComplete="off" fullWidth={true}
                                                            type="date"
                                                            InputLabelProps={{shrink: true,}}
                                                            name="startDeclarationAt"
                                                            value={taxPayerData.startDeclarationAt}
                                                            onChange={ handleInputChange }
                                                            disabled={disabledText}
                                                            />
                                                    </div> */}
                                                </div>
                                                <div className="tab-pane fade " id="nav-fiscal" role="tabpanel" aria-labelledby="nav-fiscal-tab">
                                                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3">
                                                        <TextField
                                                            label="Código Postal*"
                                                            variant="outlined"
                                                            size="small"
                                                            name="postalCode"
                                                            value={taxPayerData.address.postalCode}
                                                            onChange={handleAddressChange}
                                                            autoComplete="off"
                                                            fullWidth={true}
                                                            helperText={msgErrors.postalCode}
                                                            error={msgErrors.postalCode !== undefined}
                                                            disabled
                                                        />
                                                    </div>
                                                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3">
                                                        <TextField
                                                            label="País"
                                                            variant="outlined"
                                                            size="small"
                                                            name="country"
                                                            value={taxPayerData.address.country}
                                                            onChange={handleAddressChange}
                                                            autoComplete="off"
                                                            fullWidth={true}
                                                            helperText={msgErrors.country}
                                                            error={msgErrors.country !== undefined}
                                                            disabled
                                                        />

                                                    </div>

                                                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3">
                                                        <TextField
                                                            label="Municipio"
                                                            variant="outlined"
                                                            size="small"
                                                            name="suburb"
                                                            value={taxPayerData.address.suburb}
                                                            onChange={handleAddressChange}
                                                            autoComplete="off"
                                                            fullWidth={true}
                                                            helperText={msgErrors.town}
                                                            error={msgErrors.town !== undefined}
                                                            disabled
                                                        />
                                                    </div>

                                                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3">
                                                        <TextField
                                                            label="Colonia"
                                                            variant="outlined"
                                                            size="small"
                                                            name="town"
                                                            value={taxPayerData.address.town}
                                                            onChange={handleAddressChange}
                                                            autoComplete="off"
                                                            fullWidth={true}
                                                            helperText={msgErrors.suburb}
                                                            error={msgErrors.suburb !== undefined}
                                                            disabled
                                                        />
                                                    </div>

                                                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3">
                                                        <TextField
                                                            label="Calle"
                                                            variant="outlined"
                                                            size="small"
                                                            name="street"
                                                            value={taxPayerData.address.street}
                                                            onChange={handleAddressChange}
                                                            autoComplete="off"
                                                            fullWidth={true}
                                                            helperText={msgErrors.street}
                                                            error={msgErrors.street !== undefined}
                                                            disabled
                                                        />
                                                    </div>

                                                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3">
                                                        <TextField
                                                            label="Número Ext."
                                                            variant="outlined"
                                                            size="small"
                                                            name="outdoor"
                                                            value={taxPayerData.address.outdoor}
                                                            onChange={handleAddressChange}
                                                            autoComplete="off"
                                                            fullWidth={true}
                                                            helperText={msgErrors.interior}
                                                            error={msgErrors.interior !== undefined}
                                                            disabled
                                                        />
                                                    </div>

                                                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3">
                                                        <TextField
                                                            label="Número Int."
                                                            variant="outlined"
                                                            size="small"
                                                            name="interior"
                                                            value={taxPayerData.address.interior}
                                                            onChange={handleAddressChange}
                                                            autoComplete="off"
                                                            fullWidth={true}
                                                            helperText={msgErrors.outdoor}
                                                            error={msgErrors.outdoor !== undefined}
                                                            disabled
                                                        />
                                                    </div>
                                                    {/* {!disabledText &&
                                                        <div className="row col-md-12 text-center">
                                                            <div className="col-12">
                                                                <button type="submit" className="btn btn-success">Guardar</button>
                                                            </div>
                                                        </div>
                                                    } */}
                                                    {disabledText &&
                                                        <h3 className='text-info text-center'>Información Completa</h3>
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-3">
                                        <div className="col-12 text-center">
                                            <img className="text-center" height="300" src='rfcPreSale.png' />
                                        </div>
                                    </div>

                                    <AvisoPrivacidad />


                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}
