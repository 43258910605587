import { fetchWithToken } from "../helpers/fetch";
import { types } from "../types/types";
import { loadingModal, saveModal } from "../helpers/UseSwal";
import swal from "sweetalert";

export const getByEmail = (email) => {
  return async (dispatch) => {
    const resp = await fetchWithToken(`api/User/getByEmail/${email}`);
    const body = await resp.json();
    if (body.success) {
      dispatch(getUsersEmailLoaded(body.data))
      dispatch(validateEmailFlagUser(true))
    } else {
      swal("Error", body.error, "error")
      dispatch(validateEmailFlagUser(false))
    }
  }
}

const getUsersEmailLoaded = (users) => ({
  type: types.userGetByMail,
  payload: users
})

export const startRegisterUser = (user, handleModal) => {
  return async (dispatch) => {
    saveModal(true);
    const resp = await fetchWithToken('api/User', user, 'POST');
    const body = await resp.json();
    if (body.success) {
      dispatch(registerUser(body.data))
      handleModal();
    }
    saveModal(false);
  }
}

const registerUser = (user) => ({
  type: types.userAddNew,
  payload: user
})

export const userStartLoading = () => {
  return async (dispatch) => {
    loadingModal(true);
    const resp = await fetchWithToken('api/User');
    const body = await resp.json();
    if (body.success && body.data.length > 0) {
      dispatch(getUsersLoaded(body.data))
    }
    loadingModal(false);
  }
}

export const userType = (id) => {
  return async (dispatch) => {
    loadingModal(true);
    const resp = await fetchWithToken(`api/User/getUserType/${id}`, 'GET');
    const body = await resp.json();
    if (body.success && body.data.length > 0) {
      dispatch(getuserType(body.data))
    }
    loadingModal(false);
  }
}


const getUsersLoaded = (users) => ({
  type: types.userStartLoading,
  payload: users
})

const getuserType = (sellers) => ({
  type: types.userType,
  payload: sellers
})

export const userAddNew = (user) => ({
  type: types.userAddNew,
  payload: user
});

export const userSelectedLoading = (id) => {
  return async (dispatch) => {
    loadingModal(true);
    const resp = await fetchWithToken(`api/User/${id}`, 'GET');
    const body = await resp.json();
    if (body.success) {
      dispatch(userSeletedLoaded(body.data));
    } else {
      dispatch(userSeletedLoaded({}));
    }
    loadingModal(false);
  }
}

const userSeletedLoaded = (user) => ({
  type: types.userSelectedLoaded,
  payload: user
})

export const startUpdateUser = (user, handleModal) => {
  return async (dispatch) => {
    saveModal(true);
    const resp = await fetchWithToken('api/User', user, 'PUT');
    const body = await resp.json();
    if (body.success) {
      dispatch((userUpdated(body.data)));
      handleModal();
    }
    saveModal(false);
  }
}
const userUpdated = (user) => ({
  type: types.userUpdated,
  payload: user
})

export const userDeleted = (user) => ({
  type: types.userDeleted,
  payload: user
})

export const validateEmailFlagUser = (flag) => ({
  type: types.userValidateEmail,
  payload: flag
})
