import { TextField } from '@material-ui/core'
import React from 'react'
import { Fragment } from 'react'
import { useSelector } from 'react-redux';

export const ModuleVoucherScreen = ({msgErrors,saleFormValues, saleSetFormValues}) => {
  
  const {voucher} = saleFormValues;

  const { referenceDataRedPay } = useSelector(state => state.subscriptionSale);

  const handleFileInputChange = ({target}) => {
    const { name, files } = target;

    saleSetFormValues(prevState => {
      return{
        ...prevState,
        voucher:{
          ...prevState.voucher,
          [name]: files[0]
        }
      }
    })
  }

  const handleInputChange = ({target}) => {
    const { name, value } = target;

    saleSetFormValues(prevState=>{
      return{
        ...prevState,
        voucher: {
          ...prevState.voucher,
          [name]: value
        }
      }
    })
  }

  return (
    <Fragment>
      <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-3">
        <TextField variant="outlined" size="small" autoComplete="off" fullWidth={true} 
          type="file"
          name="voucherFiles"
          onChange={ handleFileInputChange }
          helperText={ msgErrors.voucherFiles }
          error={ msgErrors.voucherFiles !== undefined }
        />
        {
          voucher.isLoadedVoucher && (
            <div className="alert alert-info" role="alert">
              Ya hay un comprobante cargado
            </div>
          )
        }
      </div>

      <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-3">
        <TextField
          label={ Object.keys(referenceDataRedPay).length !== 0  ? "Fecha de pago*" : "Fecha de pago" }
          variant="outlined" size="small" autoComplete="off" fullWidth={true}
          type="date"
          InputLabelProps={{shrink: true,}}
          name="paymentAt"
          value={voucher.paymentAt}
          onChange={ handleInputChange }
          helperText={ msgErrors.paymentAt }
          error={ msgErrors.paymentAt !== undefined }
        />
      </div>
    </Fragment>
  )
}
