import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { InputAdornment, TextField } from '@material-ui/core';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { localTaxeDeleteToList, localTaxeUpdateToList } from '../../../../../actions/invoices/localTaxes';

export const TableLocalTaxes = ({ localTaxeTypes }) => {

  const { localTaxeList } = useSelector(state => state.localTaxe);

  const dispatch = useDispatch();

  const handleInputChange = (id, e) => {
    e.preventDefault();

    const name = e.target.name;

    const localTaxe = localTaxeList.filter(e => ( e.id === parseInt(id) ))[0];
    
    localTaxe[name] = e.target.value;

    dispatch(localTaxeUpdateToList(localTaxe));
  }

  const handleSelectChange = (id, e) => {
    e.preventDefault();

    const name = e.target.name;
    const value = e.target.value;

    const localTaxe = localTaxeList.filter(e => ( e.id === parseInt(id) ))[0];
    const localTaxeType = localTaxeTypes.filter(e => ( e.id === parseInt(value) ))[0];

    localTaxe[name] = localTaxeType;

    dispatch(localTaxeUpdateToList(localTaxe));
  }

  const deleteLocalTaxe = (id, e) => {
    e.preventDefault();

    dispatch(localTaxeDeleteToList({ id: id }))
  }

  return (
    <div className="col-md-12">
      <table className="table">
        <thead>
          <tr className="text-center">
            <th scope="col" width="25%">Impuestos</th>
            <th scope="col" width="25%">Tipo</th>
            <th scope="col" width="25%">Tasa</th>
            <th scope="col" width="25%">Importe</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          {
            localTaxeList.map((localTaxe, index) => {
              return (
                <tr className="text-center" key={index}>
                  <td>
                    <TextField
                      label="Impuesto" 
                      variant="outlined"
                      size="small"
                      autoComplete="off"
                      fullWidth={true}
                      name="description"
                      value={localTaxe.description}
                      onChange={ handleInputChange.bind(this, localTaxe.id) }
                    />
                  </td>

                  <td>
                    <TextField
                      select
                      variant="outlined"
                      label="Tipo"
                      size="small"
                      fullWidth={true}
                      SelectProps={{
                        native: true,
                      }}
                      name="localTaxeType"
                      value={ localTaxe.localTaxeType.id }
                      onChange={ handleSelectChange.bind(this, localTaxe.id) }
                    >
                      {
                        localTaxeTypes.map((localTaxeType) => {
                          return(
                            <option key={ localTaxeType.id } value={ localTaxeType.id }>{ localTaxeType.description }</option>
                          )
                        })
                      }
                    </TextField>
                  </td>

                  <td>
                    <TextField
                      label="Tasa" 
                      variant="outlined"
                      size="small"
                      autoComplete="off"
                      fullWidth={true}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            %
                          </InputAdornment>
                        ),
                      }}
                      name="rate"
                      value={localTaxe.rate}
                      onChange={ handleInputChange.bind(this, localTaxe.id) }
                    />
                  </td>

                  <td>
                    <TextField
                      label="Importe" 
                      variant="outlined"
                      size="small"
                      autoComplete="off"
                      fullWidth={true}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            $
                          </InputAdornment>
                        ),
                      }}
                      name="amount"
                      value={localTaxe.amount}
                      onChange={ handleInputChange.bind(this, localTaxe.id) }
                    />
                  </td>

                  <td>
                    <button
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Eliminar contribuyente"
                      className="btn btn-outline-danger px-2 ml-2"
                      onClick={ deleteLocalTaxe.bind(this, localTaxe.id) }
                    >
                      <FontAwesomeIcon className="" icon={"trash"}/>
                    </button>
                  </td>
                </tr>
              )
            })
          }
        </tbody>
      </table>
    </div>
  )
}
