import React, { Fragment, useEffect } from 'react'
import { InputAdornment, TextField } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { obtainIsrPorcetaje } from '../../../../../../../../../helpers/accounting/obtainIsrRifPorcentage';
import NumberFormat from 'react-number-format';

export const RifIsrCalculation = React.memo(({ formValuesDetailsSummary, setFormValuesDetailsSummary, autorizedReport, status }) => {
  const { authorizationReport, authorizations } = useSelector(state => state.authorizationReport);
  const { authorizationPrev } = useSelector(state => state.authorizationReport);

  const { subtotalExpenseAutorized, subtotalIncomesAutorized, taxISRRIF, year ,month} = formValuesDetailsSummary;

  const { calculatedBase, deductionIsr, expensesHigherToIncome, expensesHigherToIncomePrev, ISR, isrDetermined, isrMarginal, reductionAmount, subtotalIncomes, totalIsr, utility } = taxISRRIF;

  const { lowerLimit } = ISR;

  const { isrRifRanges } = useSelector(state => state.isrRif)
  const { taxpayerSelected } = useSelector(state => state.taxpayer)
  const { reduccionIsrRifs } = useSelector(state => state.reduccionIsrRif)

  const isrTotal = authorizations.paymentComplementExpensesSubtotal || authorizationReport.paymentComplementExpensesSubtotal || 0
  const totalISR = parseFloat(subtotalExpenseAutorized) + parseFloat(isrTotal) 

  useEffect(() => {
    let operationDatas={
      utility: 0.00
    };
    let expensesHigherIncome = (parseFloat(subtotalExpenseAutorized) - parseFloat(subtotalIncomesAutorized)).toFixed(2)
    // let utility = (parseFloat(subtotalIncomesAutorized) - parseFloat(subtotalExpenseAutorized)).toFixed(2)
    let utility = (parseFloat(subtotalIncomesAutorized) - parseFloat(totalISR)).toFixed(2) //
    
    if (parseFloat(expensesHigherIncome) < 0) {
      expensesHigherIncome = 0
    }
    if (parseFloat(utility) < 0) {
      utility = 0;
    }
    operationDatas['expensesHigherToIncome'] = expensesHigherIncome;
    operationDatas['utility'] = utility;
    setFormValuesDetailsSummary(prevState => {
      return{
        ...prevState,
        taxISRRIF:{
          ...prevState.taxISRRIF,
          subtotalExpenseAutorized: subtotalExpenseAutorized,
          subtotalIncomesAutorized: subtotalIncomesAutorized,
          ...operationDatas
        }
      }
    })
  }, [setFormValuesDetailsSummary, subtotalExpenseAutorized, subtotalIncomesAutorized, authorizations]);

  useEffect(() => {
    setFormValuesDetailsSummary(prevState => {
      return{
        ...prevState,
        taxISRRIF:{
          ...prevState.taxISRRIF,
          ISR: obtainIsrPorcetaje(isrRifRanges, calculatedBase, year)
        }
      }
    })
  }, [isrRifRanges, setFormValuesDetailsSummary, calculatedBase, year])

  useEffect(() => {
    if(Object.keys(taxpayerSelected).length !== 0){
      let numberYear = 1;
      const taxpayerSatDateAt = new Date(taxpayerSelected.startSatAt);
      let subtotalIncomes = 0;
      let isrDetermined = 0;
      let isrMarginal = 0;
      if (taxpayerSatDateAt.getFullYear() < year) {
        if( parseInt(taxpayerSatDateAt.getMonth() + 1) <= month ){
          numberYear = (parseInt(year)+parseInt(1)) - taxpayerSatDateAt.getFullYear()
        }else{
          numberYear = (year) - taxpayerSatDateAt.getFullYear()
        }
      }
      if(numberYear >= 11 ){
        numberYear=11
      }
      const deductionIsr = reduccionIsrRifs.filter(e => (e.yearNumber === parseInt(numberYear)))[0];
      subtotalIncomes = parseFloat(calculatedBase) - parseFloat(ISR.lowerLimit)

      isrMarginal = subtotalIncomes * ISR.porcentage

      isrDetermined = isrMarginal + parseFloat(ISR.fixedFee)

      const reductionAmount = deductionIsr.porcentage * isrDetermined;
      const totalIsr = (isrDetermined - reductionAmount).toFixed(2);

      setFormValuesDetailsSummary(prevState => {
        return{
          ...prevState,
          taxISRRIF:{
            ...prevState.taxISRRIF,
            isrDetermined: isrDetermined,
            isrMarginal: isrMarginal,
            subtotalIncomes: subtotalIncomes,
            deductionIsr: deductionIsr.porcentage,
            reductionAmount: reductionAmount,
            totalIsr: totalIsr
          }
        }
      })
    }
  }, [calculatedBase, ISR, reduccionIsrRifs, setFormValuesDetailsSummary, taxpayerSelected])

  useEffect(() => {
    let expensesHigherIncomePrev = parseFloat(expensesHigherToIncomePrev);
    let expensesHigherIncomePrevFinal = 0.00;
    let calculatedBase = 0.00;

    if (Object.keys(authorizationPrev).length > 0) {
      const isr = JSON.parse(authorizationPrev.isrJson);
      if (Object.keys(isr).length > 0) {
        if (isr.expensesHigherToIncome > 0 || isr.expensesHigherToIncomePrevFinal > 0) {
          expensesHigherIncomePrev = parseFloat(isr.expensesHigherToIncome) + parseFloat(isr.expensesHigherToIncomePrevFinal)
        }
      }
    }
    
    calculatedBase = (parseFloat(utility) - parseFloat(expensesHigherIncomePrev)).toFixed(2);
    if (calculatedBase < 0) {
      expensesHigherIncomePrevFinal = parseFloat(calculatedBase) * -1;
      calculatedBase = 0
    }
    if(parseFloat(utility) > 0 && calculatedBase < 0){
      expensesHigherIncomePrev = utility;
    }
    
    setFormValuesDetailsSummary(prevState => {
      return{
        ...prevState,
        taxISRRIF:{
          ...prevState.taxISRRIF,
          calculatedBase : calculatedBase,
          expensesHigherToIncomePrev: expensesHigherIncomePrev,
          expensesHigherToIncomePrevFinal: expensesHigherIncomePrevFinal,
        }
      }
    });
  }, [authorizationPrev, setFormValuesDetailsSummary, utility, expensesHigherToIncomePrev])

  const handleInputChangeExpense = (e) => {
    const { name, value } = e.target;

    setFormValuesDetailsSummary(prevState => {
      return{
        ...prevState,
        taxISRRIF:{
          ...prevState.taxISRRIF,
            [name]: value
        }
      }
    })
  }

  return (
    <Fragment>
      <tr>
        <td className="w-50">Ingresos del mes</td>
        <td className="w-25"><NumberFormat value={parseFloat(subtotalIncomesAutorized).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td>
      </tr>
      <tr>
        <td className="w-50">Gastos del mes</td>
        <td className="w-25"><NumberFormat value={parseFloat(totalISR).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td>
      </tr>
      <tr>
        <td className="w-50">Diferencia por gastos mayores</td>
        <td className="w-25"><NumberFormat value={parseFloat(expensesHigherToIncome).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td>
      </tr>
      <tr>
        <td className="w-50">Utilidad</td>
        <td className="w-25"><NumberFormat value={parseFloat(utility).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td>
      </tr>

      <tr>
        <td className="w-50">Diferencia por gastos mayores previos</td>
        {/* <td className="w-25"><NumberFormat value={parseFloat(expensesHigherToIncomePrev).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td> */}
        <td className="w-25">
            {
              status === 'Presentada' ?
              (<TextField
                variant="outlined"
                size="small"
                name="expensesHigherToIncomePrev"
                value={expensesHigherToIncomePrev}
                onChange={handleInputChangeExpense}
                autoComplete="off"
                fullWidth={true}
                InputProps={{
                  startAdornment: (<InputAdornment position="start">$</InputAdornment>),
                }}
                disabled={true}
              />)
              :
              (<TextField
                variant="outlined"
                size="small"
                name="expensesHigherToIncomePrev"
                value={expensesHigherToIncomePrev}
                onChange={handleInputChangeExpense}
                autoComplete="off"
                fullWidth={true}
                InputProps={{
                  startAdornment: (<InputAdornment position="start">$</InputAdornment>),
                }}
                disabled={autorizedReport}
              />)
            }
          </td>
      </tr>

      <tr>
        <td className="w-50">Base para calculo</td>
        <td className="w-25"><NumberFormat value={parseFloat(calculatedBase).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td>
      </tr>

      <tr>
        <td className="w-50">Limite Inferior</td>
        <td className="w-25"><NumberFormat value={parseFloat(lowerLimit).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td>
      </tr>
      <tr>
        <td className="w-50">Excende del limite</td>
        <td className="w-25"><NumberFormat value={parseFloat(subtotalIncomes).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td>
      </tr>
      <tr>
        <td className="w-50">Tasa</td>
        <td className="w-25"><NumberFormat value={parseFloat(ISR.porcentage * 100).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'%'} /></td>
      </tr>
      <tr>
        <td className="w-50">ISR Marginal</td>
        <td className="w-25"><NumberFormat value={parseFloat(isrMarginal).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td>
      </tr>
      <tr>
        <td className="w-50">Cuota Fija</td>
        <td className="w-25"><NumberFormat value={parseFloat(ISR.fixedFee).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td>
      </tr>
      <tr>
        <td className="w-50">ISR determinado</td>
        <td className="w-25"><NumberFormat value={parseFloat(isrDetermined).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td>
      </tr>
      <tr>
        <td className="w-50">Porcentaje reducción</td>
        <td className="w-25"><NumberFormat value={parseFloat(deductionIsr * 100).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'%'} /></td>
      </tr>
      <tr>
        <td className="w-50">Reducción</td>
        <td className="w-25"><NumberFormat value={parseFloat(reductionAmount).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td>
      </tr>
      <tr>
        <td className="w-50"><strong>ISR a pagar</strong></td>
        <td className="w-25"><NumberFormat value={parseFloat(totalIsr).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td>
      </tr>
    </Fragment>
  )
}
)