import { types } from "../../types/types";

const initialState = {
    data: {}
}


export const additionalProceduresData = (state = initialState, action) => {
    switch (action.type) {
        case types.additionalProceduresData:
            return {
                ...state,
                data: { ...action.payload }
            }
        default:
            return state;
    }
}