import { types } from "../../types/types";

const initialState = {
  requestDatas:[],
  periodSelected: null,
  captcha: null,
  sesionSat:false,
  sesionRetentionSat:false,
  sesionRetentionSatLoad:false,
}

export const downloadXMLRequestReducer = (state=initialState, action) => {
  switch (action.type) {
    case types.downloadXMLRequestStartLoading:
      return{
        ...state,
        requestDatas: [...action.payload]
      }
    case types.getRequestByPeriodStartLoading:
      return{
        ...state,
        periodSelected: action.payload
      }
    case types.getDownloadSatStartLoading:
      return{
        ...state,
        captcha: action.payload
      }
    case types.getSessionSat:
      return{
        ...state,
        sesionSat: action.payload
      }
    case types.getSessionRetentionSat:
      return{
        ...state,
        sesionRetentionSat: action.payload,
      }

    default:
      return state;
  }
}


