import React from 'react';
import { TextField } from '@material-ui/core';

export const EmployeeData = () => {
    const handleInputChange = (e) => {}
    return (
        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
            <h5 className="card-header">Datos Generales</h5>
            <div className="row mt-4">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 mt-3">
                            <TextField label="Nombres" variant="outlined" size="small" name="names" fullWidth={true}/>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 mt-3">
                            <TextField label="Apellido Paterno" variant="outlined" size="small" name="name_ap" fullWidth={true}/>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 mt-3">
                            <TextField label="Apellido Materno" variant="outlined" size="small" name="name_am" fullWidth={true}/>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-3">
                            <TextField label="RFC" variant="outlined" size="small" name="rfc" fullWidth={true}/>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-3">
                            <TextField label="CURP" variant="outlined" size="small" name="curp" fullWidth={true}/>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-3">
                            <TextField select variant="outlined" label="Régimen" size="small" 
                                fullWidth={true} name="regimen_id" value="0" onChange={handleInputChange}
                                SelectProps={{native: true,}}>
                                <option value="0">Seleccionar</option>
                                <option value="1">Régimen 1</option>
                                <option value="2">Régimen 2</option>
                            </TextField>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-3">
                            <TextField label="Correo Electrónico" variant="outlined" size="small" name="names" fullWidth={true}/>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 mt-3">
                            <TextField label="Número Celular" variant="outlined" size="small" name="name_ap" fullWidth={true}/>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 mt-3">
                            <TextField label="Número Casa" variant="outlined" size="small" name="name_am" fullWidth={true}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
