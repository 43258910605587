import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import moment from 'moment';
import React, { useState, Fragment, useContext } from 'react'
import { CSVLink } from 'react-csv';
import { useDispatch } from 'react-redux';
import { Pagination } from '../../../../helpers/tables/Pagination';
import { Link } from 'react-router-dom';
import { activeSaleCrm, obtainSaleVouchersStartLoading } from '../../../../actions/crm/sales';
import { UseSwal } from '../../../../hooks/UseSwal';
import { ADMIN_SALE_UPDATE } from '../../../constants/Routes';
import { SaleVouchers } from './SaleVouchers';
import { ModalContext } from '../../../modal/ModalContext';
import { InputSearching } from '../../../../helpers/tables/InputSearching';
import Swal from 'sweetalert2';

export const TableNewSale = ({ setSalesSearch, salesSearch, nameFile, originalSalesSearch }) => {

  const headers = [
    { label: 'Cliente', key: 'userFullName' },
    { label: 'Correo', key: 'userEmail' },
    { label: 'Producto', key: 'productos.description' },
    { label: 'Vigencia inicio', key: 'startServiceAt' },
    { label: 'Vigencia fin', key: 'endServiceAt' },
    { label: 'Fecha de pago', key: 'paymentAt' },
    { label: 'Activo', key: 'active' }
  ]

  const { activationModal } = UseSwal();
  const { handleModal } = useContext(ModalContext);

  const dispatch = useDispatch();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const handleObtainVouchers = (saleId) => {
    dispatch(obtainSaleVouchersStartLoading(saleId));
    handleModal(<SaleVouchers saleId={saleId} />)
  }

  const activeSale = (sale) => {
    activationModal(activeSaleCrm(sale.saleId))
  }

  const commentActiveSale = () => {
    Swal.fire({
      title: "Comentario",
      text: "Escribe porque no se activo la venta",
      input: 'textarea',
      confirmButtonText: 'Enviar',
    }).then((result) => {
      if (result.value) {
        ////console.log(result.value)
      }
    });
  }


  return (

    <div>
      <div className="card-body">
        <div className='row'>
          <div className="col-xs-12 my-auto col-sm-12 col-md-6 col-lg-6 col-xl-6">
            <h5 >{nameFile}</h5>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 text-right ">
            <div className='col-12 mb-2 pr-0'>
              <CSVLink data={salesSearch} headers={headers} filename={nameFile + '.csv'} className="btn btn-success">
                Descargar CSV
              </CSVLink>
            </div>
            <InputSearching
              columnsToSearch={['userEmail', 'userFullName']}
              setDataChange={setSalesSearch}
              originals={originalSalesSearch}
            />
          </div>

        </div>


        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center"><strong>Id</strong></TableCell>
                <TableCell align="center"><strong>Cliente</strong></TableCell>
                <TableCell align="center"><strong>Producto</strong></TableCell>
                <TableCell align="center"><strong>Fecha de pago</strong></TableCell>
                <TableCell align="center"><strong>Fecha inicio</strong></TableCell>
                <TableCell align="center"><strong>Fecha final</strong></TableCell>
                <TableCell align="center"><strong>Comprobantes</strong></TableCell>
                <TableCell align="center"><strong>Activo</strong></TableCell>
                <TableCell align="center"><strong>Acciones</strong></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                salesSearch.length === 0 ?
                  (<TableRow>
                    <TableCell colSpan="10">
                      <div className="alert alert-info text-center" role="alert">
                        No hay ventas con dichos filtros
                      </div>
                    </TableCell>
                  </TableRow>)
                  :
                  (
                    salesSearch.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((sale, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell>{sale.saleId}</TableCell>
                          <TableCell align="center">
                            {sale.userFullName}<br />
                            <strong>{sale.userEmail}</strong><br />
                            {/* {sale.user.phoneMobile} */}
                          </TableCell>
                          <TableCell align="center">
                            <Fragment>
                              {
                                sale.products.map((product, index) => {
                                  return (
                                    <Fragment key={index}>
                                      {product.description}
                                      <strong> {product.unitPrice}</strong><br />
                                    </Fragment>
                                  )
                                })
                              }
                              {/* Total: <strong>{sale.total}</strong> */}
                            </Fragment>
                          </TableCell>

                          <TableCell align="center">
                            {sale.paymentAt ?
                              (
                                moment(sale.paymentAt).format('YYYY-MM-DD')
                              )
                              :
                              (
                                'Sin fecha'
                              )
                            }
                          </TableCell>

                          <TableCell align="center">
                            {sale.startServiceAt ?
                              (
                                moment(sale.startServiceAt).format('YYYY-MM-DD')
                              )
                              :
                              (
                                'Sin fecha'
                              )
                            }
                          </TableCell>

                          <TableCell align="center">
                            {sale.endServiceAt ?
                              (
                                moment(sale.endServiceAt).format('YYYY-MM-DD')
                              )
                              :
                              (
                                'Sin fecha'
                              )
                            }
                          </TableCell>

                          <TableCell align="center">
                            {sale.paymentAt ?
                              (
                                <button
                                  onClick={handleObtainVouchers.bind(this, sale.saleId)}
                                  className="btn btn-primary btn-sm"
                                  data-toggle="tooltip" data-placement="top"
                                  title="Ver comprobantes"
                                ><i className="fas fa-file-invoice-dollar"></i></button>
                              )
                              :
                              (
                                'Sin comprobante'
                              )
                            }
                          </TableCell>

                          <TableCell align="center">
                            {
                              sale.isAuthorized ?
                                (
                                  <span className="badge badge-success">Si</span>
                                ) :
                                (
                                  <span className="badge badge-danger">No</span>
                                )
                            }
                          </TableCell>
                          <TableCell align="center">
                            <Link to={ADMIN_SALE_UPDATE(sale.saleId)} data-toggle="tooltip" data-placement="top"
                              title="Editar venta" className="btn btn-primary btn-sm mr-2">
                              <i className="fas fa-edit"></i>
                            </Link>

                            {
                              !sale.isAuthorized &&
                              <>
                                {/* <button class="btn btn-sm btn-warning m-1"
                                 data-toggle="tooltip" data-placement="top" 
                                 title="Agregar comentario"
                                 onClick={ () => commentActiveSale(sale.id)}>
                                  <i class="fas fa-comments" aria-hidden="true"></i>
                                </button> */}
                                <button data-toggle="tooltip" data-placement="top"
                                  title="Activar venta" className="btn btn-sm btn-success m-1 mt-1"
                                  onClick={() => activeSale(sale)}
                                ><i className="fas fa-unlock"></i></button>


                              </>
                            }
                          </TableCell>
                        </TableRow>
                      )
                    })
                  )
              }
            </TableBody>
          </Table>
        </TableContainer>
        <Pagination
          data={salesSearch}
          page={page}
          rowsPerPage={rowsPerPage}
          setPage={setPage}
          setRowsPerPage={setRowsPerPage}
        />
      </div>
    </div>

  )
}
