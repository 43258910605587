import React from 'react';
import PropTypes from 'prop-types';

import { ADMIN_SALES, CRM_SALES, TAXPAYERS, ACCOUNT_PREREPORTSUPGRADE, ACCOUNT_REPORTS } from '../components/constants/Routes';


import { Route, Redirect } from 'react-router-dom';

export const PublicRoute = ({
  isAuthenticated,
  component: Component,
  role,
  ...rest
}) => {

  const componentReturn = (props) => {
    if (isAuthenticated) {
      if (role === 'Administration') {
        return <Redirect to={ADMIN_SALES} />
      } if (role === 'Guest') {
        return <Redirect to={TAXPAYERS} />
      } if (role === 'Seller') {
        return <Redirect to={CRM_SALES} />
      } if (role === 'Accounter') {
        return <Redirect to={ACCOUNT_REPORTS} />
      } if (role === 'Service Customer') {
        return <Redirect to={CRM_SALES} />
      }
    } else {
      return <Component {...props} />
    }
  }

  return (
    <Route {...rest}
      component={(props) => (
        componentReturn(props)
      )}

    />
  )
}

PublicRoute.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  component: PropTypes.func.isRequired,
  role: PropTypes.string,
}
