import { fetchWithApiKey } from "../../helpers/fetch";
import { types } from "../../types/types"

export const paymentFrecuenciesCfdiStartLoading = () => {
  return async(dispatch) => {
    const resp = await fetchWithApiKey('api/Catalogs/c_paymentfrecuencies');
    const body = await resp.json();
    if (body.success){
      dispatch(paymentFrecuenciesCfdiLoaded(body.data));
    }
  }
}

const paymentFrecuenciesCfdiLoaded = (paymentFrecuencies) => ({
  type: types.paymentFrecuencieCfdisStartLoading,
  payload: paymentFrecuencies
})