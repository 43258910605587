import { types } from "../../types/types";

const initialState = {
  serviceTypeRetentions: []
}

export const ServiceTypeRetentionReducer = ( state = initialState, action ) => {
  switch (action.type) {
    case types.serviceTypeRetentionsStartLoading:
      return{
        ...state,
        serviceTypeRetentions: [ ...action.payload ]
      }

    default:
      return state;
  }
}
