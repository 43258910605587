import { types } from "../../types/types";

const initialState = {
  regimes: []
}

export const RegimeReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.regimenesStartLoading:
      return{
        ...state,
        regimes: [ ...action.payload ]
      }
      
    default:
      return state;
  }
}
