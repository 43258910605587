import { fetchWithApiKey } from "../../helpers/fetch";
import { types } from "../../types/types";

export const receptorAccountBanksStartLoadind = () => {
  return async(dispatch) => {
    const resp = await fetchWithApiKey('api/Catalogs/c_ReceptorAccount');
    const body = await resp.json();
    if (body.success){
      dispatch( receptorAccountBanksLoaded( body.data ) )
    }
  }
}

const receptorAccountBanksLoaded = (accounts) => ({
  type: types.receptorAccountBanksStartLoading,
  payload: accounts  
})