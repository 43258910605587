import React, { useContext } from 'react';
import { ModalContext } from '../../../modal/ModalContext';
import { PositiveBalanceForm } from './PositiveBalanceForm';

export const PositiveBalanceDetailsScreen = () => {

  const { handleModal } = useContext( ModalContext );

  return (
    <div className="row">
      <div className="col-md-12 mt-4">
        <button
          className="btn btn-primary mb-3"
          onClick={ () => handleModal(
            <PositiveBalanceForm />
          )}
        >Agregar Saldos a Favor</button>

        <table className="table">
          <thead>
            <tr>
              <th scope="col">Impuesto</th>
              <th scope="col">Generado en</th>
              <th scope="col">Fecha de presentación</th>
              <th scope="col">Monto</th>
              <th scope="col">Remanente</th>
            </tr>
          </thead>
        </table>
      </div>
    </div>
  )
}
