import { fetchWithApiKey } from "../../helpers/fetch";
import { types } from "../../types/types"

export const rolesStartLoading = () => {
  return async(dispatch) => {
    const resp = await fetchWithApiKey('api/Catalogs/c_Roles');
    const body = await resp.json();
    if (body.success){
      dispatch(rolesLoaded(body.data));
    }
  }
}

const rolesLoaded = (rolesType) => ({
  type: types.rolesStartLoading,
  payload: rolesType
})