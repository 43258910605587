export const initialOptionIsrPlataform = {
  porcentage: 0,
  isrCaused: 0.00,
};

export const obtainIsrPlataformPorcentage = (columns, amount, year) => {
  let option = {};
  columns.forEach(column => {
    if(parseFloat(amount) >= column.lowerLimit && column.year === parseInt(year)){
      const isrCaused = (parseFloat(amount) * column.porcentage).toFixed(6);
      
      option = {
        ...column,
        isrCaused: isrCaused
      }
    }
  });

  if(Object.keys(option).length === 0){
    option = initialOptionIsrPlataform;
  }
  return option;
}
