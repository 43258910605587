import { types } from "../../types/types";

const initialState = {
  paymentStatusTypes: []
}

export const PaymentStatusReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.paymentStatusStartLoading:
      return{
        ...state,
        paymentStatusTypes: [ ...action.payload ]
      }

    default:
      return state;
  }
}
