import React, { useEffect } from 'react';
import { DETAILS_SUMMARY } from '../../../../constants/Routes';
import { Link } from 'react-router-dom';
import { obtainMonths } from '../../../../../helpers/declarationPeriod';
import { useForm } from '../../../../../hooks/useForm';

const ShowAnnualsummary = ({ taxpayerId, regimeSatCode, year }) => {

    const { formValues, setFormValues } = useForm({
        months: []
    });

    const { months } = formValues;

    useEffect(() => {
        setFormValues(prevState => {
            return{
                ...prevState,
                months: obtainMonths(regimeSatCode)
            }
        })
    }, [ regimeSatCode, setFormValues])

    return (
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-4">
            <div className="row">        
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2">
                    <h4 className="card-header">
                      RESUMEN ANUAL {year} - {regimeSatCode}
                    </h4>
                </div>
            </div>
            <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2">
                    <div className="table-responsive">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Mes</th>
                                    <th>Total Recibido</th>
                                    <th>Ingresos Gravables</th>
                                    <th>Gastos Totales</th>
                                    <th>IVA</th>
                                    <th>ISR</th>
                                    <th>Detalles</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    months.map((month, index) => {
                                        return(
                                            <tr key={ index }>
                                                <td>{month.label}</td>
                                                <td>$ 0.00</td>
                                                <td>$ 0.00</td>
                                                <td>$ 0.00</td>
                                                <td>$ 0.00</td>
                                                <td>$ 0.00</td>
                                                <td>
                                                    <Link
                                                        to={DETAILS_SUMMARY( taxpayerId, regimeSatCode, year, (month.month + 1))} data-toggle="tooltip" data-placement="top"
                                                        title="Detalle Mensual" className="btn btn-warning btn-sm"
                                                        > <i className="fas fa-eye"></i>
                                                    </Link>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ShowAnnualsummary;
