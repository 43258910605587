const initialState = {
  reduccionIsrRifs: [
    {
      id: 1,
      porcentage: 1, 
      yearNumber: 1,
    },
    {
      id: 2,
      porcentage: .9 ,
      yearNumber: 2,
    },
    {
      id: 3,
      porcentage: .8,
      yearNumber: 3,
    },
    {
      id: 4,
      porcentage: .7,
      yearNumber: 4,
    },
    {
      id: 5,
      porcentage: .6,
      yearNumber: 5,
    },
    {
      id: 6,
      porcentage: .5,
      yearNumber: 6,
    },
    {
      id: 7,
      porcentage: .4,
      yearNumber: 7,
    },
    {
      id: 8,
      porcentage: .3,
      yearNumber: 8,
    },
    {
      id: 9,
      porcentage: .2,
      yearNumber: 9,
    },
    {
      id: 10,
      porcentage: .1,
      yearNumber: 10,
    },
    {//si es mayor a los 10 años se convierte en %0
      id: 11,
      porcentage: 0,
      yearNumber: 11,
    },
  ]
};

export const reduccionIsrRifReducer = ( state = initialState, action ) => {
  switch (action.type) {
  
    default:
      return state;
  }
}
