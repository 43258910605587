import { fetchWithApiKey } from "../../helpers/fetch";
import { types } from "../../types/types"

export const coinsStartLoading = () => {
  return async(dispatch) => {
    const resp = await fetchWithApiKey('api/Catalogs/c_Monedas');
    const body = await resp.json();
    if (body.success){
      dispatch(coinsLoaded(body.data)); 
    }
  }
}

const coinsLoaded = (coins) => ({
  type: types.coinsStartLoading,
  payload: coins
})