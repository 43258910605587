import { InputAdornment, TextField } from '@material-ui/core';
import React, { useEffect } from 'react'

export const IepsTranslatedTaxes = ( { CheckIepsTranslated, iepsTranslated, setIepsTranslated, formValues, initialIepsTranslated } ) => {

  const { pretotalWithoutTaxes } = formValues;

  const { amount, rate } = iepsTranslated;

  useEffect(() => {
    
    if (CheckIepsTranslated) {
      const amountTaxe = pretotalWithoutTaxes * ( rate / 100 )
  
      setIepsTranslated( prevState => {
        return{
          ...prevState,
          amount: amountTaxe
        }
      })
    }else{
      setIepsTranslated(initialIepsTranslated)
    }


  }, [ CheckIepsTranslated, rate, pretotalWithoutTaxes, setIepsTranslated, initialIepsTranslated ]);

  const handleInputChange = ( { target } ) => {
    setIepsTranslated({
      ...iepsTranslated,
      [target.name]: target.value
    })
  }

  return (
    <div className="col-md-12 mb-3">
      { CheckIepsTranslated &&
        (
          <div className="row">

            <div className="col-md-6">
              <TextField
                label="Monto" 
                variant="outlined"
                size="small"
                name="rate"
                value={ rate }
                onChange={ handleInputChange }
                autoComplete="off"
                fullWidth={true}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      %
                    </InputAdornment>
                  ),
                }}
              />
            </div>

            <div className="col-md-6">
              <div className="input-group input-group-sm">
                <TextField
                  label="Monto" 
                  variant="outlined"
                  size="small"
                  name="amount"
                  value={ amount }
                  onChange={ handleInputChange }
                  autoComplete="off"
                  fullWidth={true}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        $
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            </div>
          </div>
        )
      }
    </div>
  )
}
