import React from 'react';
import { Provider } from 'react-redux';
import { store } from './store/store';

import "./components/styles/Datatables.scss";
import './components/styles/modal.scss';
import "./components/styles/stylesGenerals.scss";

import { ModalProvider } from './components/modal/ModalContext';
import { AppRouter } from './router/AppRouter';


function App() {
  
  return (
    <Provider store={store}>
      <ModalProvider>
        <AppRouter />
      </ModalProvider>
    </Provider>
  );
}

export default App;
