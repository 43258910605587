import { types } from "../../types/types";

const initialState = {
    classifyNotValidated : []
}

export const claveProdServNotValidatedReducer = (state = initialState, action ) => {
    switch (action.type) {
        case types.clavProdServNotValidated:
            return{
                ...state,
                classifyNotValidated: [ ...action.payload]
            }

            default:
                return state;
    }
}
