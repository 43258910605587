import { InputAdornment, TextField } from '@material-ui/core';
import React, { Fragment, useEffect } from 'react';
import NumberFormat from 'react-number-format';
import { useSelector } from 'react-redux';

export const TechnologyPlataformIvaCalculation = ({ formValuesDetailsSummary, setFormValuesDetailsSummary, autorizedReport, status }) => {
  const { authorizationReport, authorizations } = useSelector(state => state.authorizationReport);

  const { authorizationPrev } = useSelector(state => state.authorizationReport);

  const { expenseTotalTaxeTras, taxISRPlataformsTechnologies, taxIVAPlataformsTechnologies } = formValuesDetailsSummary;

  const { totalForIntermediaries, totalForUsers, totalIncomes } = taxISRPlataformsTechnologies;

  const { ivaCaused, ivaExpenseTotal, ivaPeriodsPrevius, ivaToDeclared, optionIva, retentionPlataform, totalIva } = taxIVAPlataformsTechnologies;

  const ivaTotal = authorizations.paymentComplementExpensesIva || authorizationReport.paymentComplementExpensesIva || 0
  const ivaExpenseTotalAcreditable = parseFloat(ivaExpenseTotal)  + parseFloat(ivaTotal)

  useEffect(() => {
    const ivaCaused = (
      parseFloat(totalIncomes) *
      parseFloat(optionIva.porcentage)
    ).toFixed(2);

    setFormValuesDetailsSummary(prevState => {
      return{
        ...prevState,
        taxIVAPlataformsTechnologies:{
          ...prevState.taxIVAPlataformsTechnologies,
          ivaCaused: ivaCaused
        }
      }
    });
  }, [setFormValuesDetailsSummary, totalIncomes, optionIva])

  useEffect(() => {
    const totalIvaExpenses = expenseTotalTaxeTras;//+ total del iva de los detalles de los complementos

    const ivaToDeclared = (
      parseFloat(ivaCaused) -
      parseFloat(ivaExpenseTotalAcreditable) -
      parseFloat(retentionPlataform)
      ).toFixed(2);

    // const ivaToDeclared = (
    //   parseFloat(ivaCaused) -
    //   parseFloat(totalIvaExpenses) -
    //   parseFloat(retentionPlataform)
    // ).toFixed(2);

    setFormValuesDetailsSummary(prevState => {
      return{
        ...prevState,
        taxIVAPlataformsTechnologies:{
          ...prevState.taxIVAPlataformsTechnologies,
          ivaToDeclared: ivaToDeclared,
          ivaExpenseTotal: totalIvaExpenses
        }
      }
    })
  }, [ivaCaused, expenseTotalTaxeTras, retentionPlataform, setFormValuesDetailsSummary, authorizations])

  // useEffect(() => {
  //   const totalIva = (
  //     parseFloat(ivaToDeclared) -
  //     parseFloat(ivaPeriodsPrevius)
  //   ).toFixed(2);

  //   setFormValuesDetailsSummary(prevState => {
  //     return{
  //       ...prevState,
  //       taxIVAPlataformsTechnologies:{
  //         ...prevState.taxIVAPlataformsTechnologies,
  //         totalIva: totalIva
  //       }
  //     }
  //   })
  // }, [ivaToDeclared, ivaPeriodsPrevius, setFormValuesDetailsSummary])

  useEffect(() => {
    let ivaPrev = parseFloat(ivaPeriodsPrevius);
    let totalIvaFinal = 0.00;
    let totalIvaCaused = 0.00;
    if (Object.keys(authorizationPrev).length > 0) {
      const iva = JSON.parse(authorizationPrev.ivaJson);
      if (Object.keys(iva).length > 0) {
        if (parseFloat(iva.totalIva) < 0) {
          if (parseFloat(ivaPeriodsPrevius) !== (parseFloat(iva.totalIva) * -1) && parseFloat(ivaPeriodsPrevius) === 0) {
            ivaPrev = parseFloat(iva.totalIva) * -1;
          }
        }
      }
    }
    totalIvaFinal = (parseFloat(ivaCaused) - parseFloat(ivaExpenseTotalAcreditable) - parseFloat(retentionPlataform) + (ivaPrev * -1)).toFixed(2);
    // totalIvaFinal = (parseFloat(ivaToDeclared) + (ivaPrev * -1)).toFixed(2)
    let newIvaPrevs = ivaPrev * 1;
    newIvaPrevs = isNaN(newIvaPrevs) ? 0 : newIvaPrevs

    setFormValuesDetailsSummary(prevState => {
      return{
        ...prevState,
        taxIVAPlataformsTechnologies:{
          ...prevState.taxIVAPlataformsTechnologies,
          totalIva: totalIvaFinal,
          ivaPeriodsPrevius: newIvaPrevs
        }
      }
    });
  }, [authorizationPrev, authorizations, ivaPeriodsPrevius, setFormValuesDetailsSummary, ivaToDeclared]);

  const handleInputChangeTaxIva = (e) => {
    const { name, value } = e.target;
    setFormValuesDetailsSummary(prevState => {
      return{
        ...prevState,
        taxIVAPlataformsTechnologies: {
          ...prevState.taxIVAPlataformsTechnologies,
          [name]: value
        }
      }
    })
  }

  return (
    
    <Fragment>
      <tr>
        <td className="w-50">Ingresos mediante intermediarios</td>
        <td className="w-50"><NumberFormat value={parseFloat(totalForIntermediaries).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td>
      </tr>
      <tr>
        <td className="w-50">Ingresos mediante usuario</td>
        <td className="w-50"><NumberFormat value={parseFloat(totalForUsers).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td>
      </tr>
      <tr>
        <td className="w-50">Ingresos Totales</td>
        <td className="w-50"><NumberFormat value={parseFloat(totalIncomes).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td>
      </tr>
      <tr>
        <td className="w-50">Tasa</td>
        <td className="w-50"><NumberFormat value={parseFloat(optionIva.porcentage * 100).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'%'} /></td>
      </tr>
      <tr>
        <td className="w-50">IVA causado</td>
        <td className="w-50"><NumberFormat value={parseFloat(ivaCaused).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td>
      </tr>
      <tr>
        <td className="w-50">IVA gastos(Acreditable)</td>
        <td className="w-50"><NumberFormat value={parseFloat( ivaExpenseTotalAcreditable ).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td>
      </tr>
      <tr>
        <td className="w-50">Retenciones de IVA por plataforma tecnológica</td>
        <td className="w-50">
          {
            status === 'Presentada' ?
            (<TextField
              variant="outlined"
              size="small"
              name="retentionPlataform"
              value={retentionPlataform}
              onChange={handleInputChangeTaxIva}
              autoComplete="off"
              fullWidth={true}
              InputProps={{
                startAdornment: (<InputAdornment position="start">$</InputAdornment>),
              }}
              disabled={true}
            />)
            :
            (<TextField
              variant="outlined"
              size="small"
              name="retentionPlataform"
              value={retentionPlataform}
              onChange={handleInputChangeTaxIva}
              autoComplete="off"
              fullWidth={true}
              InputProps={{
                startAdornment: (<InputAdornment position="start">$</InputAdornment>),
              }}
              disabled={autorizedReport}
            />)
          }
        </td>
      </tr>
      <tr>
        <td className="w-50">IVA del periodo a declarar</td>
        <td className="w-50"><NumberFormat value={parseFloat(ivaToDeclared).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td>
      </tr>
      <tr>
        <td className="w-50">IVA a acreditar de periodos anteriores</td>
        <td className="w-50">
          {
            status === 'Presentada' ?
            (<TextField
              variant="outlined"
              size="small"
              name="ivaPeriodsPrevius"
              value={ivaPeriodsPrevius}
              onChange={handleInputChangeTaxIva}
              autoComplete="off"
              fullWidth={true}
              InputProps={{
                startAdornment: (<InputAdornment position="start">$</InputAdornment>),
              }}
              disabled={true}
            />)
            :
            (<TextField
              variant="outlined"
              size="small"
              name="ivaPeriodsPrevius"
              value={ivaPeriodsPrevius}
              onChange={handleInputChangeTaxIva}
              autoComplete="off"
              fullWidth={true}
              InputProps={{
                startAdornment: (<InputAdornment position="start">$</InputAdornment>),
              }}
              disabled={autorizedReport}
            />)
          }
        </td>
      </tr>
      { parseFloat(totalIva) >= 0 ?
        (
          <tr>
            <td className="w-50">IVA a cargo</td>
            <td className="w-50"><NumberFormat value={parseFloat(totalIva).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td>
          </tr>
        )
        :
        (
          <tr>
            <td className="w-50">IVA a favor</td>
            <td className="w-50"><NumberFormat value={(parseFloat(totalIva) * -1).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td>
          </tr>
        )
      }
    </Fragment>
  )
}
