import React, { Fragment } from 'react';
import NumberFormat from 'react-number-format';
import { useSelector } from 'react-redux';

export const HonorariumInformation = ({detailsFormValues}) => {
  const { subtotalExpenseAutorized, subtotalExpenseNotAutorized, subtotalIncomesAutorized, totalExpensePersonalAutorized, taxISRHonorarium, taxIVAHonorarium } = detailsFormValues
  const { taxpayerProfession } = useSelector(state => state.taxpayer);
  const { authorizationReport, authorizations } = useSelector(state => state.authorizationReport);

  const considered = authorizations.paymentComplementExpensesSubtotal || authorizationReport.paymentComplementExpensesSubtotal || 0
  const consideredExpenseTotalAcreditable = parseFloat(subtotalExpenseAutorized)  + parseFloat(considered)

  return (
    <div className="col-md-6">
      <strong>Ingresos:</strong><NumberFormat value={parseFloat(subtotalIncomesAutorized).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /><br/>
      <strong>Deducciones Autorizadas:</strong><NumberFormat value={parseFloat(consideredExpenseTotalAcreditable).toFixed(2) } displayType={'text'} thousandSeparator={true} prefix={'$'} /><br/>
      <strong>Deducciones Personales:</strong><NumberFormat value={parseFloat(totalExpensePersonalAutorized).toFixed(2) } displayType={'text'} thousandSeparator={true} prefix={'$'} /><br/>
      <strong>Deducciones No Autorizadas:</strong><NumberFormat value={parseFloat(subtotalExpenseNotAutorized).toFixed(2) } displayType={'text'} thousandSeparator={true} prefix={'$'} /><br/>
      {
        taxpayerProfession === 'Médico/Dentista' ? (
          <Fragment>
          <strong>Total a pagar de IVA: $0.00</strong><br/>
        </Fragment>
        ):(
          parseFloat(taxIVAHonorarium.totalIVA) >= 0 ?
          (<Fragment>
            <strong>Total a pagar de IVA:</strong><NumberFormat value={parseFloat(taxIVAHonorarium.totalIVA).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /><br/>
          </Fragment>)
          :
          (<Fragment>
            <strong>Total a favor de IVA:</strong><NumberFormat value={parseFloat(parseFloat(taxIVAHonorarium.totalIVA) * -1).toFixed(6)} displayType={'text'} thousandSeparator={true} prefix={'$'} /><br/>
          </Fragment>)
        )
      }
      <strong>Total a pagar de ISR:</strong>
      {
        parseFloat(taxISRHonorarium.ISR.retentionToPay) >= 0 ?
        (
          <Fragment>
            <NumberFormat value={parseFloat(taxISRHonorarium.ISR.retentionToPay).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /><br/>
          </Fragment>
        )
        :
        (
          <Fragment>
            <NumberFormat value={parseFloat(0.00).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /><br/>
          </Fragment>
        )
      }
    </div>
  )
}
