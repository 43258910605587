export const taxpayersObtainInvoicing = ( taxpayers ) => {
  let regimes = [];
  taxpayers.forEach(taxpayer => {
    let flag = false;
    taxpayer.profileRegimes.forEach(profileRegime => {
      if(profileRegime.invoicing){
        flag = true;
      }
    })
    if(flag){
      regimes.push(taxpayer)
    }
  });
  return regimes
}

export const taxpayersObtainAccounting = ( taxpayers ) => {
  let regimes = [];
  taxpayers.forEach(taxpayer => {
    let flag = false;
    taxpayer.profileRegimes.forEach(profileRegime => {
      if(profileRegime.accounting){
        flag = true;
      }
    })
    if(flag){
      regimes.push(taxpayer)
    }
  });
  return regimes
}
