import swal from "sweetalert";
import { fetchWithToken, fetchWithTokenFormDataExist } from "../../helpers/fetch";
import { loadingModal, saveModal } from "../../helpers/UseSwal";
import { types } from "../../types/types";

export const crmRenovationsStartLoading = (date) => {
  return async(dispatch) => {
  // loadingModal(true)
    const resp = await fetchWithToken('api/Admin/orders/get', date, 'POST');
    const body = await resp.json();
    if (body.success){
      dispatch(crmSaleRenovationsLoaded(body.data));
    }
  // loadingModal(false)
  }
}

const crmSaleRenovationsLoaded = (sales) => ({
  type: types.crmSaleRenovationsStartLoading,
  payload: sales
})

export const startRegisterCRMSaleRenovation = (renovation, history, route) => {
  return async() => {
    saveModal(true);
    const resp = await fetchWithTokenFormDataExist('api/Admin/orders/create', renovation, 'POST');
    const body = await resp.json();
    if (body.success){
      history.push(route);
    }else{
      let msgErrors = "";
      if(body.errors){
        Object.keys(body.errors).forEach(function(key) {
          msgErrors += `${body.errors[key]}\n`
        });
      }else{
        msgErrors = body.error;
      }
      swal('Error al guardar', msgErrors, 'error');
    }
    saveModal(false);
  }
}

export const obtainVouchersStartLoading = (saleId) => {
  return async(dispatch) => {
    loadingModal(true)
    const resp = await fetchWithToken(`api/Admin/orders/getVouchers/${saleId}`);
    const body = await resp.json();
    if (body.success){
      dispatch(saleVouchersLoaded({
        saleId: saleId,
        vouchers: body.data
      }));
    }
    loadingModal(false);
  }
}

const saleVouchersLoaded = (vouchers) => ({
  type: types.crmSaleRenovationVouchersStartLoading,
  payload: vouchers
});