import { fetchWithApiKey } from "../../helpers/fetch";
import { loadingModal, loadingModalMsn } from "../../helpers/UseSwal";
import { types } from "../../types/types";
import swal from "sweetalert";
export const productToSalesStartLoading = () => {
  return async(dispatch) => {
    const resp = await fetchWithApiKey( 'api/Catalogs/c_ProductToSale');
    const body = await resp.json();
    if (body.success){
      dispatch( productToSalesLoaded( body.data ) )
    }
  }
}

const productToSalesLoaded = ( products ) => ({
  type: types.productToSaleStartLoading,
  payload: products
})

export const productToPaymentLinkStart = () => {
  return async(dispatch) => {
    
    loadingModal(true)
    const resp = await fetchWithApiKey('api/PaymentGateway/productsToPaymentLink');
    const body = await resp.json();
    if (body.success){
      dispatch( productsToPaymentLinkLoaded( body.data ) )
    }
    loadingModal(false)
  }
}


const productsToPaymentLinkLoaded = ( products ) => ({
  type: types.productsToPaymentLinkStartLoading,
  payload: products
})

 export const generatePaymentLinkStart = (id,email) => {
 
  return async(dispatch) => {
    loadingModalMsn(true,"Generando link")
    const resp = await fetchWithApiKey(`api/PaymentGateway/getPaymentLink/1/${id}/${email}`);
    const body = await resp.json();   
    if (body.success){
     dispatch(productPaymentLink(body.data))
    }else{
      swal('Error', 'Intente de nuevo, por favor', 'error');
    }
    loadingModalMsn(false)
  }
 }

  const productPaymentLink = ( link ) => ({
   type:types.productsToPaymentLink,
   payload: link
})