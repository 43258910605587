import React, { useEffect, useState } from 'react';
import { TextField, InputAdornment } from '@material-ui/core';
import { useForm } from '../../../../../hooks/useForm';
import { validateNumberPresence, validateStringPresence } from '../../../../../helpers/validateForm';
import { useSelector } from 'react-redux';

const formValueDefault = {
    currencyExchangeRate: '',
    currencyId: '',
    currencySatCode: '',
    folio: '',
    paidAmount: 0.00,
    partilityNumber: 1,
    previusAmount: 0.00,
    serie: '',
    unpaidAmount: 0.00,
    uuid: '',
}

export const DocumentRelation = ({ paymentId, setFormPaidComplement }) => {
    
    const { currencyTypes } = useSelector(state => state.currencytype)

    const { formValues, handleInputChange, setFormValues } = useForm(formValueDefault);

    const { currencyExchangeRate, currencyId, currencySatCode, folio, paidAmount, partilityNumber, previusAmount, serie, unpaidAmount, uuid } = formValues;
    const [msgErrors, setMsgErrors] = useState({});

    useEffect(() => {
        if(currencyTypes.length > 0){
            setFormValues(prevState => {
                return{
                    ...prevState,
                    currencyId: currencyTypes[0].id,
                    currencySatCode: currencyTypes[0].satCode   
                }
            })
        }
    }, [setFormValues, currencyTypes])

    const handleCurrencyChange = ( { target } ) => {
        const { value } = target;

        const currencyType = currencyTypes.filter(e => ( e.id === parseInt(value) ))[0];

        setFormValues(prevState => {
            return{
                ...prevState,
                'currencyId': value,
                currencySatCode: currencyType.satCode,
                CurrencyExchangeRate: ''
            }
          })
    }

    const addDocumentRelated = (e) => {
        e.preventDefault();

        if ( isFormValid() ) {
            setFormPaidComplement(prevState => {
                return{
                    ...prevState,
                    dataPayments: prevState.dataPayments.map(
                        e => ( e.id === paymentId ) ?
                        { ...e,
                          documentRelateds: [
                              ...e.documentRelateds,
                              {...formValues, id: new Date().getTime()}
                          ]
                        }
                        :
                        e
                    )
                }
            })
        }
    }

    const isFormValid = () => {
        setMsgErrors({});
        const datasToValidate = { uuid };
        const datasNumbers = { paidAmount, partilityNumber, previusAmount, unpaidAmount };

        const validateStrings = validateStringPresence(datasToValidate, setMsgErrors);
        const validateNumbers = validateNumberPresence( datasNumbers, setMsgErrors );

        if (validateStrings && validateNumbers){
            return true;
        }
        return false;
    }

    return (
        <div className="mt-2">
            <div className="card-header">
                <h5>Agregar Documento Relacionado</h5>
            </div>
            <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <TextField 
                        className="mt-4"
                        label="Folio Fiscal (UUID)" 
                        variant="outlined" 
                        size="small" 
                        name="uuid"
                        value={ uuid }
                        onChange={ handleInputChange } 
                        fullWidth={true}
                        helperText={ msgErrors.uuid }
                        error={ msgErrors.uuid !== undefined }
                    />
                    <TextField
                        className="mt-4"
                        label="Serie"
                        variant="outlined"
                        size="small" 
                        name="serie"
                        vale={ serie }
                        fullWidth={true}
                        onChange={ handleInputChange }
                    />
                    <TextField
                        className="mt-4"
                        label="Folio"
                        variant="outlined"
                        size="small" 
                        name="folio"
                        fullWidth={true}
                        value={ folio }
                        onChange={ handleInputChange }
                    />
                    <TextField className="mt-4" select variant="outlined" label="Moneda" 
                        size="small" fullWidth={true} name='currencyId' 
                        value={currencyId} onChange={handleCurrencyChange} SelectProps={{native: true,}}
                    >
                        { currencyTypes.length > 0 ? (
                            currencyTypes.map((currencyType, index) => {
                                return(
                                    <option
                                        key={ index }
                                        value={ currencyType.id }
                                    >
                                       { currencyType.satCode } - { currencyType.description }
                                    </option>
                                )
                            })
                          )
                          :
                          (
                            <option value={0}>No hay registros</option>
                          )
                        }
                    </TextField>

                    { currencySatCode !== 'MXN' && (
                        <TextField
                            className="mt-4"
                            label="Tipo de cambio"
                            variant="outlined" 
                            size="small"
                            name="currencyExchangeRate"
                            value={ currencyExchangeRate }
                            fullWidth={true}
                            InputLabelProps={{shrink: true,}}
                            onChange={ handleInputChange }
                        />
                      )
                    }
                </div>
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <TextField
                        type="number"
                        className="mt-4"
                        label="Número de Parcialidad" 
                        variant="outlined" 
                        size="small" 
                        fullWidth={true}
                        name="partilityNumber"
                        value={ partilityNumber }
                        onChange={ handleInputChange }
                        helperText={ msgErrors.partilityNumber }
                        error={ msgErrors.partilityNumber !== undefined }
                    />
                    <TextField
                        type="number"
                        className="mt-4"
                        label="Importe Saldo Anterior"
                        variant="outlined"
                        size="small"
                        name="previusAmount" 
                        value={ previusAmount } 
                        onChange={ handleInputChange }
                        autoComplete="off"
                        fullWidth={true} 
                        InputProps={{startAdornment: (<InputAdornment position="start">$</InputAdornment>),}}
                        helperText={ msgErrors.previusAmount }
                        error={ msgErrors.previusAmount !== undefined }
                    />
                    <TextField
                        type="number"
                        className="mt-4"
                        label="Importe Pagado"
                        variant="outlined"
                        size="small"
                        name="paidAmount"
                        value={ paidAmount }
                        onChange={ handleInputChange }
                        autoComplete="off"
                        fullWidth={true} 
                        InputProps={{startAdornment: (<InputAdornment position="start">$</InputAdornment>),}}
                        helperText={ msgErrors.paidAmount }
                        error={ msgErrors.paidAmount !== undefined }
                    />
                    <TextField
                        type="number"
                        className="mt-4"
                        label="Importe Saldo Insoluto"
                        variant="outlined"
                        size="small"
                        name="unpaidAmount"
                        value={ unpaidAmount }
                        onChange={ handleInputChange }
                        autoComplete="off" fullWidth={true} 
                        InputProps={{startAdornment: (<InputAdornment position="start">$</InputAdornment>),}}
                        helperText={ msgErrors.unpaidAmount }
                        error={ msgErrors.unpaidAmount !== undefined }
                    />
                </div>
                <div className="col-md-12 text-right mt-4">
                    <button className="btn btn-primary" onClick={ addDocumentRelated }>
                        Agregar
                    </button>
                </div>
            </div>
        </div>
    )
}
