import { types } from "../../types/types";

const initialState = {
    classify : []
}

export const claveProdServReducer = (state = initialState, action ) => {
    switch (action.type) {
        case types.clavProdServConfig:
            return{
                ...state,
                classify: [ ...action.payload]
            }

            default:
                return state;
    }
}
