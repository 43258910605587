import swal from "sweetalert";
import { fetchWithToken } from "../../helpers/fetch";
import { loadingModal, saveModal } from "../../helpers/UseSwal";
import { types } from "../../types/types";

export const startExpenseClassifications = () => {
  return async(dispatch) => {
    loadingModal(true)
    const resp = await fetchWithToken('api/AccountingSettings/Expenses/Classifications/Get');
    const body = await resp.json();
    if (body.success){
      dispatch( expenseClassificationsLoaded( body.data ) )
    }
    loadingModal(false)
  }
}

const expenseClassificationsLoaded = ( data ) => ({
  type: types.expenseClassificationsStartLoading,
  payload: data
})

export const selectExpenseClassifications = (productKey) => {
  return async(dispatch) => {
    loadingModal(true)
    const resp = await fetchWithToken(`api/AccountingSettings/Expenses/Classifications/Get/${productKey}`);
    const body = await resp.json();
    if (body.success){
      dispatch( expenseClassificationsSelect( body.data[0] ))
    }
    loadingModal(false)
  }
}

const expenseClassificationsSelect = ( data ) => ({
  type: types.expenseClassificationsSelect,
  payload: data
})

export const expenseClassificationAction = (expense,action,history,route) => {
  return async(dispatch) => {
    saveModal(true)
    const resp = await fetchWithToken('api/AccountingSettings/Expenses/Classifications', expense, 'POST');
    const body = await resp.json();
    if (body.success){
    
      if(action){
        swal("Exito","Se actualizo correctamente","success")
        dispatch( expenseClassificationsUpdate( body.data ))
       
      }else{
        swal("Exito","Se guardo correctamente","success")
        dispatch( expenseClassificationsAdd( body.data ) )
        history.push(route)
      }
      
    }else{
      swal("Error",body.error,"error")
    }
    saveModal(false)
  }
}

const expenseClassificationsAdd = ( data ) => ({
  type: types.expenseClassificationAdd,
  payload: data
})

const expenseClassificationsUpdate = ( data ) => ({
  type: types.expenseClassificationUpdate,
  payload: data
})