import { types } from "../../types/types";

const initialState = {
  invoicesActivation: {}
}

export const RecordInvoicesBillingReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.invoicesActive:
      return{
        ...state,
        invoicesActivation: { ...action.payload }
      }
      
    default:
      return state;
  }
}
