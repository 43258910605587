import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useSelector } from 'react-redux';

export const LineItemList = () => {

  const { lineItems } = useSelector(state => state.lineItem)

  return (
    <div className="col-md-6">
      <div className="form-group row">
        <label className="col-md-2 pt-2"><strong>Buscar:</strong></label>
        <div className="col-md-10">
          <input className="form-control" />
        </div>
      </div>
      
      <div>
        <ul className="list-group list-group-flush">
          {
            lineItems.map( ( lineItem, index ) => {
              return (
                <li className="list-group-item" key={ index }>
                  <div className="row">
                    <div className="col-md-10">
                      { lineItem.concept }
                    </div>
                    <div className="col-md-2 text-right">
                      <button
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Eliminar"
                        className="btn btn-sm btn-outline-danger px-2 ml-2"
                      >
                        <FontAwesomeIcon className="fa-lg" icon={"trash"}/>
                      </button>
                    </div>
                  </div>
                  
                </li>
              )
            })

          }
        </ul>
      </div>

    </div>
  )
}
