const regexRfc = new RegExp("^([A-ZÑ&]{3,4}) ?(?:- ?)?(\\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\\d|3[01])) ?(?:- ?)?([A-Z\\d]{2})([A\\d])$");
const regexPhone = new RegExp("^(?!0+$)(\\+\\d{1,3}[- ]?)?(?!0+$)\\d{10,15}$");

export const validateStringPresence = (list, setMsgErrors) => {
  const errors = {};
  Object.keys(list).forEach(function(key) {
    if (list[key].trim().length === 0) {
      errors[key] = "Es requerido.";
    }
  });
  return serErrors(errors, setMsgErrors);
}

export const validateRfcValid = (rfc, setMsgErrors) => {
  const errors = {};
  if (!regexRfc.test(rfc.toUpperCase())) {
    errors['rfc'] = "RFC no es valido.";
  }
  return serErrors(errors, setMsgErrors);
}
export const validatePhoneValid = (phoneMobile, setMsgErrors) => {
  const errors = {};
  if (!regexPhone.test(phoneMobile.toUpperCase())) {
    errors['phoneMobile'] = "Telefono no valido.";
  }
  return serErrors(errors, setMsgErrors);
}

export const validateEmailValid = (email, setMsgErrors) => {
  const errors = {};
  if(!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email))){
    errors['email'] = "Telefono no valido.";
  }
  return serErrors(errors, setMsgErrors);
}

export const validateNumberPresence = (list, setMsgErrors) => {
  const errors = {};
  Object.keys(list).forEach(function(key) {
    if (list[key] <= 0) {
      errors[key] = "Es requerido.";
    }
  });
  return serErrors(errors, setMsgErrors);
}

export const validateArrayPresence = (list, setMsgErrors) => {
  const errors = {};
  Object.keys(list).forEach(function(key) {
    if (list[key].length <= 0) {
      errors[key] = "Es requerido.";
    }
  });
  return serErrors(errors, setMsgErrors);
}

export const validateFilePresence = ( list, setMsgErrors ) =>{
  const errors = {};
  Object.keys(list).forEach(function(key) {
    if(list[key] === ''){
      errors[key] = "Es requerido.";
    }
  });
  return serErrors(errors, setMsgErrors);
}

const serErrors = (errors, setMsgErrors) => {
  setMsgErrors(prevState => {
    return{
      ...prevState,
      ...errors
    }
  });

  if(Object.keys(errors).length !== 0){
    return false;
  }
  return true;
}
