import { types } from "../../types/types";

const initialState={
  usersData: [],
  usersLogged: 0,
  usersRegistered: 0
}

export const metricReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.metricApp:
      return{
        ...state,
        usersData: action.payload.usersData,
        usersLogged: action.payload.usersLogged,
        usersRegistered: action.payload.usersRegistered
      }
  
    default:
      return state;
  }
}