import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@material-ui/core'
import moment from 'moment'
import React, { useEffect, useState, useContext, Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { obtainMonths, obtainYears } from '../../../../helpers/declarationPeriod'
import { useForm } from '../../../../hooks/useForm'
import { FORM_PRESALE, FORM_PRESALE_DETAILS } from '../../../constants/Routes';
import { CSVLink } from 'react-csv';
import { InputSearching } from '../../../../helpers/tables/InputSearching';
import { Pagination } from '../../../../helpers/tables/Pagination'
import { crmPreSalesStartLoading, sendEmailNotifyInfo, activateAccount } from '../../../../actions/crm/preSales'
import { ModalContext } from '../../../modal/ModalContext'
import { ListProduct } from './PreSale/ListProduct'
import swal from 'sweetalert'
import { DetailsPromotion } from './PreSale/DetailsPromotion'
import { ChangeSellertModal } from '../../administrators/reports/ChangeSellerModal'
import { userSellers } from '../../../../helpers/clasificateUsers'
import { userType } from '../../../../actions/users'
import { getCSVWithRFC } from '../../../../actions/crm/sales'
import UploadCSVModal from './modals/UploadCSVModal/UploadCSVModal'

export const CrmPreSalesIndexScreen = () => {

  const headers = [
    { label: 'Cliente', key: 'userFullName' },
    { label: 'RFC', key: 'userRfc' },
    { label: 'Producto', key: 'products[0].description' },
    { label: 'Vendedor', key: 'sellerFullName' },
    { label: 'Fecha de pago', key: 'paymentAt' },
    { label: 'Venta Autorizada', key: 'active' },
    { label: 'Cuenta Activa', key: 'activeApp' },
    { label: 'Total', key: 'products[0].total' },
  ]

  const { crmPreSales } = useSelector(state => state.crmPreSale);

  const { user } = useSelector(state => state.auth);
  const { sellers } = useSelector(state => state.userType)
  const isBoss = user.isBoss;
  const dispatch = useDispatch();
  const { handleModal } = useContext(ModalContext);

  const { formValues, setFormValues, handleInputChange } = useForm({
    dataDownload: [],
    monthsOb: obtainMonths(),
    monthSelected: '',
    years: obtainYears().reverse(),
    yearselected: '',
  });

  const { monthSelected, monthsOb, years, yearselected } = formValues;

  // Para el envío de datos para activación
  const today = new Date();
  const dd = String(today.getDate()).padStart(2, '0');
  const mm = String(today.getMonth() + 1).padStart(2, '0'); // Enero es 0!
  const yyyy = today.getFullYear();

  const todayFormatted = yyyy + '-' + mm + '-' + dd;

  const crypto = require('crypto');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const [preSales, setPreSales] = useState([])
  const [preSalesSearch, setPreSalesSearch] = useState([])

  useEffect(() => {
    dispatch(crmPreSalesStartLoading())
  }, [user.token]);


  useEffect(() => {
    if (crmPreSales.length > 0) {
      setPreSales(crmPreSales)
      setPreSalesSearch(crmPreSales)
    }
  }, [crmPreSales, preSales])

  useEffect(() => {
    const date = new Date();
    setFormValues(prevState => {
      return {
        ...prevState,
        monthSelected: date.getMonth() + 1,
        yearselected: date.getFullYear()
      }
    })
  }, [setFormValues]);

  const sendEmailInfo = (token, ciec) => {

    dispatch(sendEmailNotifyInfo(token, ciec))
  }

  const activateVoucher = (evt, accountData) => {
    dispatch(activateAccount(accountData));
  }


  useEffect(() => {
    if (sellers.length === 0) {
      dispatch(userType(3));
    }
  }, [sellers, dispatch]);


  const copyPass = (pass) => {
    swal('Exito', 'Contraseña copiada en portapapeles', 'success');
    navigator.clipboard.writeText(pass)
  }

  return (
    <div>
      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <div className="card-header">
          <h5>Preventas</h5>
        </div>
      </div>

      <div className="col-md-12 mt-2">
        <div className="row">
          <div className="col-md-2 invisible">
            <TextField select variant="outlined" label="Mes" size="small"
              fullWidth={true} name="monthSelected"
              value={monthSelected} onChange={handleInputChange}
              SelectProps={{ native: true, }}>
              {
                monthsOb.map((month, index) => {
                  return (
                    <option key={index} value={month.month + 1}>
                      {month.label}
                    </option>
                  )
                })
              }
            </TextField>
          </div>

          <div className="col-md-2 invisible">
            <TextField select variant="outlined" label="Año" size="small"
              fullWidth={true} name="yearselected"
              value={yearselected} onChange={handleInputChange}
              SelectProps={{ native: true, }}>
              {
                years.map((yearItem, index) => {
                  return (
                    <option key={index} value={yearItem}>
                      {yearItem}
                    </option>
                  )
                })
              }
            </TextField>
          </div>

          <div className="col-md-2 invisible">
            <CSVLink data={preSalesSearch} filename={"ventas.csv"} headers={headers} className="btn btn-success">
              Descargar CSV
            </CSVLink>
          </div>

          {/* <div className="col-md-6 text-right">
            <Link to={FORM_PRESALE} className="btn btn-info ml-2 float-right">
              <i className="fas fa-plus"></i> Pre-Venta
            </Link>
          </div> */}
        </div>
      </div>
      <div className="col-md-12 mt-2">
        <div className='row'>
          <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 float-right'>
            <InputSearching
              columnsToSearch={['email', 'name', 'rfc', 'lastName', 'motherLastName']}
              setDataChange={setPreSalesSearch}
              originals={preSales}
            />
          </div>
        </div>
      </div>
      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center"><strong>Id</strong></TableCell>
                <TableCell align="center"><strong>Cliente</strong></TableCell>
                <TableCell align="center"><strong>Credenciales</strong></TableCell>
                <TableCell align="center"><strong>Producto</strong></TableCell>
                <TableCell align="center"><strong>Promoción</strong></TableCell>
                <TableCell align="center"><strong>Fecha de pre-registro</strong></TableCell>
                <TableCell align="center"><strong>Vendedor</strong></TableCell>
                <TableCell align="center"><strong>Códio Aliado</strong></TableCell>
                <TableCell align="center"><strong>Proceso</strong></TableCell>
                <TableCell align="center"><strong>Acciones</strong></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                preSalesSearch.length === 0 ?
                  (<TableRow>
                    <TableCell colSpan="10">
                      <div className="alert alert-info text-center" role="alert">
                        No hay pre-ventas con dichos filtros
                      </div>
                    </TableCell>
                  </TableRow>)
                  :
                  (
                    preSalesSearch.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((presale, index) => {
                      const payDescription = presale.stepsJson?.stepPayment?.description
                      const promotionJsonString = (presale.promotionJson)
                      let message = "Sin promoción";
                      let totalDiscount = "";
                      if (promotionJsonString) {
                        try {
                          const promotionJson = JSON.parse(promotionJsonString);
                          if (promotionJson.ObjectPromotion && promotionJson.ObjectPromotion.Message) {
                            message = promotionJson.ObjectPromotion.Message;
                            totalDiscount = `Pagará $${promotionJson.ObjectPromotion.TotalDiscount}`;
                          }
                        } catch (e) {
                        }
                      }
                      // console.log(message);
                      const payPrice = presale.stepsJson?.stepPayment?.unitPrice
                      const reference = presale.stepsJson?.stepPayment?.paymentLink?.reference || '';
                      const total = presale.stepsJson?.stepPayment?.unitPrice || '';
                      const token = presale.stepsJson?.stepPayment?.token || '';
                      const card_bin = presale.phone || '';
                      const idToHash = todayFormatted + presale.id;
                      const cust_addr = presale.stepsJson?.stepPayment?.paymentLink?.cust?.addr || 'Plaza de la República 53';
                      const cust_city = presale.stepsJson?.stepPayment?.paymentLink?.cust?.city || 'CDMX'
                      const cust_state = presale.stepsJson?.stepPayment?.paymentLink?.cust?.state || 'MX'
                      const cust_zip = presale.stepsJson?.stepPayment?.paymentLink?.cust?.zip || '06030'
                      const codigo = presale.stepsJson?.stepPartnership?.code || 'Sin código de aliado'
                      const cust_country = presale.stepsJson?.stepPayment?.paymentLink?.cust?.country || '06030'
                      const hash = crypto.createHash('sha256').update(idToHash).digest('hex');

                      return (
                        <TableRow key={index}>
                          <TableCell>{presale.id}</TableCell>
                          <TableCell align="center">
                            {presale.name} {presale.lastName} {presale.motherLastName}<br />
                            <strong>{presale.email}</strong><br />
                            <strong>{presale.referenceCode}</strong><br />
                          </TableCell>
                          <TableCell align="center">
                            <strong>{presale.rfc}</strong> <br />
                            {presale.passwordSAT &&
                              <i className=" btn fas fa-copy text-primary enable-pointer-cursor-for-buttons" onClick={() => copyPass(presale.passwordSAT)}> <span className='text-dark' >**********</span></i>
                            }
                          </TableCell>
                          <TableCell align="center">
                            <Fragment>
                              <strong className='font-bold'>{payDescription}</strong> <br />
                              <strong>${payPrice}</strong> <br />
                              <button
                                className="btn btn-secondary m-1" target="_blank"
                                data-toggle="tooltip" data-placement="top" title="Seleccionar producto"
                                onClick={() => handleModal(
                                  <ListProduct
                                    presale={presale}
                                  />
                                )}
                                disabled={presale?.stepsJson?.stepPayment?.paid}
                              >
                                <i className="fas text-white fa-link"></i>
                              </button>
                            </Fragment>
                          </TableCell>
                          <TableCell align="center">
                            <div class="d-flex flex-column">
                              <div class="p-2" style={{ fontWeight: 'bold' }}>{totalDiscount}</div>
                              <div class="p-2" style={{ fontWeight: 'bold' }}>{message}</div>
                              <div class="p-2">
                                {
                                  presale.promotionJson != null ?

                                    (
                                      <button
                                        className='btn btn-primary'
                                        onClick={() => handleModal(
                                          <DetailsPromotion presale={presale} />
                                        )
                                        }
                                      >
                                        <i className="fas text-white fa-gift"></i>
                                      </button>
                                    )
                                    :
                                    (
                                      <button
                                        className='btn btn-primary'
                                        disabled={true}
                                        onClick={() => handleModal(
                                          <DetailsPromotion />
                                        )
                                        }
                                      >
                                        <i className="fas text-white fa-gift"></i>
                                      </button>

                                    )
                                }
                              </div>
                            </div>
                          </TableCell>

                          <TableCell align="center">
                            {presale.createdAt ?
                              (
                                moment(presale.createdAt).format('YYYY-MM-DD')
                              )
                              :
                              (
                                'Sin fecha'
                              )
                            }
                          </TableCell>

                          <TableCell align="center">
                            {/* Dueño:
                            {presale.seller.fullName}<br /> */}
                            {/* Monitor: */}
                            {presale.seller.fullName}<br />
                            {isBoss &&
                              <button className="btn btn-sm btn-info" target="_blank"
                                data-toggle="tooltip" data-placement="top" title="Reasignar contador"
                                onClick={() => handleModal(
                                  <ChangeSellertModal
                                    accounters={sellers}
                                    accountantId={presale.seller.id}
                                    packagesToChange={[presale]}
                                  />
                                )}>Reasignar vendedor</button>
                            }
                          </TableCell>
                          <TableCell align="center">
                            <span>
                              {codigo}
                            </span>
                          </TableCell>

                          <TableCell align="center">
                            {presale.stepsJson ?
                              <div>
                                {presale.stepsJson.stepCiec ? <span className="badge badge-success"> Contraseña Sat Completo</span> : <span className="badge badge-info"> Contraseña Sat Abierto</span>}<br />
                                {presale.stepsJson.stepTaxFiles ? <span className="badge badge-success">Sellos Completo</span> : <span className="badge badge-info">Sellos Abierto</span>}<br />
                                {presale.stepsJson.stepTaxDocuments?.taxDocumentData?.length == 2 ? <span className="badge badge-success">Archivos Completos</span> : <span className="badge badge-info"> Archivos Abierto</span>}<br />
                                {/* {presale.stepsJson.stepTaxDocuments?.taxDocumentData?.length == 2 && presale.stepsJson.stepTaxFiles?.taxFilesData?.length == 4 ?   <span  className="badge badge-success">Archivos Completos</span> : <span  className="badge badge-info"> Archivos Abierto</span>}<br/> */}
                                {presale.stepsJson.stepTaxPayerData ? <span className="badge badge-success">Información Completo</span> : <span className="badge badge-info">Información Abierto</span>}<br />
                                {presale.stepsJson.stepPayment && presale.stepsJson.stepPayment?.paid ? <span className="badge badge-success">Pago Completo</span> : <span className="badge badge-info"> Pago Abierto</span>}<br />
                              </div>
                              :
                              <span className="badge badge-info">Abierto</span>
                            }
                          </TableCell>
                          <TableCell align="center">

                            {presale.stepsJson?.stepCiec == null ||
                              presale.stepsJson?.stepTaxPayerData == null ||
                              presale.stepsJson?.stepPayment?.paid == null ||
                              presale.stepsJson?.stepPayment?.paid == false ?
                              <Link to={FORM_PRESALE_DETAILS(presale.token)} data-toggle="tooltip" data-placement="top" target="_blank"
                                title="Detalles PreVenta" className="btn btn-primary m-1">
                                <i className="fas fa-edit"></i>
                              </Link>
                              :
                              <Link to={FORM_PRESALE_DETAILS(presale.token)} data-toggle="tooltip" data-placement="top" target="_blank"
                                title="Detalles PreVenta" className={`btn btn-primary m-1  custom-cursor-not-allowed disabled`} tyle={{ cursor: 'not-allowed' }}
                              >
                                <i className="fas fa-edit"></i>
                              </Link>
                            }

                            {presale.stepsJson?.stepCiec == null ||
                              presale.stepsJson?.stepTaxPayerData == null ||
                              presale.stepsJson?.stepPayment?.paid == null ||
                              presale.stepsJson?.stepPayment?.paid == false ?
                              <button data-toggle="tooltip" data-placement="top"
                                title="Contraseña SAT" className="btn btn-info m-1"
                                onClick={sendEmailInfo.bind(this, presale.token, true)}
                              ><i className="fas fa-envelope"></i></button>
                              :
                              <button data-toggle="tooltip" data-placement="top"
                                title="Contraseña SAT" className={`btn btn-info m-1 disabled custom-cursor-not-allowed`} style={{ cursor: 'not-allowed' }}
                              ><i className="fas fa-envelope"></i></button>
                            }

                            {presale.stepsJson?.stepCiec == null ||
                              presale.stepsJson?.stepTaxPayerData == null ||
                              presale.stepsJson?.stepPayment?.paid == null ||
                              presale.stepsJson?.stepPayment?.paid == false ?
                              <button data-toggle="tooltip" data-placement="top"
                                title="Archivo fiscales" className="btn btn-success m-1"
                                onClick={sendEmailInfo.bind(this, presale.token, false)}
                              ><i className="fas fa-envelope"></i></button>
                              :
                              <button data-toggle="tooltip" data-placement="top"
                                title="Archivo fiscales" className={`btn btn-success m-1 disabled custom-cursor-not-allowed`} style={{ cursor: 'not-allowed' }}
                              ><i className="fas fa-envelope"></i></button>
                            }
                            {/* {!user.isBoss ? (
                              <button
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Validar pago"
                                className="btn btn-warning m-1 disabled custom-cursor-not-allowed"
                                style={{ cursor: 'not-allowed' }}
                                disabled
                              >
                                <i className="fas fa-check"></i>
                              </button>
                            ) : (
                              presale.stepsJson?.stepPayment?.paid === false &&
                                presale.stepsJson?.stepCiec &&
                                presale.stepsJson.stepTaxPayerData ? (
                                <form onSubmit={(event) => {
                                  event.preventDefault();

                                  const formData = new FormData();
                                  formData.append('event', 'card');
                                  formData.append('status', 'paid');
                                  formData.append('auth_code', todayFormatted);
                                  formData.append('reference', reference);
                                  formData.append('id', todayFormatted + presale.id);
                                  formData.append('hash', hash);
                                  formData.append('total', total);
                                  formData.append('token', hash);
                                  formData.append('cancelurl', 'https://contabox.contabilizate.com/ventas');
                                  formData.append('card_bin', card_bin);
                                  formData.append('card_bran', 'BancBox');
                                  formData.append('card_category', 'electron');
                                  formData.append('card_type', 'debit');
                                  formData.append('card_country', 'México');
                                  formData.append('cad_issuer', 'Voucher');
                                  formData.append('env', 'production');
                                  formData.append('cust_fname', presale.name);
                                  formData.append('cust_lname', presale.lastName);
                                  formData.append('cust_lname_2', presale.motherLastName);
                                  formData.append('cust_address', cust_addr);
                                  formData.append('cust_city', cust_city);
                                  formData.append('cust_state', cust_state);
                                  formData.append('cust_zip', cust_zip);
                                  formData.append('cust_country', cust_country);
                                  formData.append('card_last', card_bin);
                                  formData.append('card_owner', `${presale.name} ${presale.lastName} ${presale.motherLastName}`);

                                  activateVoucher(event, formData);
                                }}>
                                  <button
                                    type="submit"
                                    className="btn btn-warning m-1"
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="Validar pago"
                                  >
                                    <i className="fas fa-check"></i>
                                  </button>
                                </form>
                              ) : (
                                <button
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title="Validar pago"
                                  className="btn btn-warning m-1 disabled custom-cursor-not-allowed"
                                  style={{ cursor: 'not-allowed' }}
                                  disabled
                                >
                                  <i className="fas fa-check"></i>
                                </button>
                              )
                            )} */}

                            {/* <button
                              className='btn btn-success m-1'
                              data-placement="top"
                              value={presale.rfc}
                              onClick={() => handleModal(<UploadCSVModal rfcValue={presale.rfc} />)}
                            >
                              <i className="fa fa-file-excel-o"></i>
                            </button> */}
                          </TableCell>
                        </TableRow>
                      )
                    })
                  )
              }
            </TableBody>
          </Table>
        </TableContainer>
        <Pagination
          data={preSalesSearch}
          page={page}
          rowsPerPage={rowsPerPage}
          setPage={setPage}
          setRowsPerPage={setRowsPerPage}
        />
      </div>
    </div>
  )
}
