const initialState = {
  IsrPlataformLodgingRanges: [
    {
      id: 1,
      lowerLimit: 0.01,
      upperLimit: 5500,
      porcentage: .02,
      year: 2020,
    },
    {
      id: 2,
      lowerLimit: 5500.1,
      upperLimit: 15000,
      porcentage: .03,
      year: 2020,
    },
    {
      id: 3,
      lowerLimit: 15000.1,
      upperLimit: 35000,
      porcentage: .05,
      year: 2020,
    },
    {
      id: 4,
      lowerLimit: 35000.1,
      upperLimit: null,
      porcentage: .10,
      year: 2020,
    },
    {
      id: 1,
      lowerLimit: 0.01,
      upperLimit: null,
      porcentage: .04,
      year: 2021,
    },
    {
      id: 1,
      lowerLimit: 0.01,
      upperLimit: null,
      porcentage: .04,
      year: 2022,
    },
    {
      id: 1,
      lowerLimit: 0.01,
      upperLimit: null,
      porcentage: .04,
      year: 2023,
    },
    {
      id: 1,
      lowerLimit: 0.01,
      upperLimit: null,
      porcentage: .04,
      year: 2024,
    }
  ]
}

export const IsrPlataformLodgingReducer = (state = initialState, action) => {
  switch (action.type) {

    default:
      return state;
  }
}
