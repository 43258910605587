import { types } from "../types/types";

const initialState = {
  loading: false,
  msgErrorsApi: [],
  openModal: false,
}

export const uiReducer = ( state = initialState, action) => {
  switch (action.type) {
    case types.uiSetError:
      return {
        ...state,
        msgErrorsApi: {
          ...action.payload
        }
      }

    case types.uiRemoveError:
      return {
        ...state,
        msgErrorsApi: {}
      }

    case types.uiStartLoading:
      return {
        ...state,
        loading: true
      }
    
    case types.uiFinishLoading:
      return {
        ...state,
        loading: false
      }

    default:
      return state;
  }
}