import React, { useEffect } from 'react'
import { TextField,FormGroup,FormControlLabel,Checkbox } from '@material-ui/core';
import { useForm } from '../../../../../../../../hooks/useForm';
import './styles/formDownloadedSat.scss'

export const ExpenseFormDownloadedSat = ({ expenseTypes, formValuesExpense, handleInputChangeExpense,handleCheckboxTrueFalseChange, handleSubmit, wayOfPayments }) => {

  const { formValues, setFormValues } = useForm({
    client: ' - '
  })

  const { client } = formValues;

  const { concepts, expenseTypeId, paymentAt, stampAt, thirdParty, total, wayOfPaymentId,globalAuthorization } = formValuesExpense;

  useEffect(() => {
    if(thirdParty){
      setFormValues(prevState => {
        return{
          ...prevState,
          client: `${ thirdParty.rfc } - ${ thirdParty.name }`
        }
      })
    }
  }, [setFormValues, thirdParty])

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-md-12 mb-3">
            <TextField label="Cliente" variant="outlined" size="small" autoComplete="off" fullWidth={true}
              value={ client }
              disabled
            />
          </div>
          <div className="col-md-12 mb-3">
            <TextField label="Conceptos" variant="outlined" size="small" autoComplete="off" fullWidth={true}
              select
              SelectProps={{
                native: true,
              }}
              readOnly
            >
              {
                concepts.map((concept, i) => {
                  return (
                    <option
                      key={i}
                    >
                      { concept.claveProdServ ? `${concept.claveProdServ} - ` : ''}
                      { concept.descripcion}
                    </option>
                  )
                })
              }
            </TextField>
          </div>
          <div className="col-md-6 mb-3">
            <TextField label="Fecha de facturación" variant="outlined" size="small" autoComplete="off" fullWidth={true}
              type="date"
              name="stampAt"
              value={ stampAt }
              disabled
            />
          </div>
          <div className="col-md-6 mb-3">
            <TextField label="Fecha de pago" variant="outlined" size="small" autoComplete="off" fullWidth={true}
              type="date"
              name="paymentAt"
              value={ paymentAt }
              onChange={ handleInputChangeExpense }
            />
          </div>

          <div className="col-md-6 mb-3">
            <TextField
                label="Forma de pago" variant="outlined" size="small" autoComplete="off" fullWidth={true}
                select
                id="wayOfPayment"
                name="wayOfPaymentId"
                value={ wayOfPaymentId }
                SelectProps={{
                  native: true,
                }}
                disabled
                InputLabelProps={{
                  shrink: true,
                  style: { color: "blue" },
                }}
              >
                {
                  wayOfPayments.length > 0 ?
                  (
                    wayOfPayments.map((wayofPayment, index) => {
                      return(
                        <option
                          key={ index }
                          value={ wayofPayment.id }
                        >
                          { wayofPayment.description }
                        </option>  
                      )
                    })
                  )
                  :
                  (
                    <option>
                      No hay registros
                    </option>
                  )
                }
              </TextField>
          </div>

          <div className="col-md-12 mb-3">
            <TextField label="Tipo de gasto" variant="outlined" size="small" autoComplete="off" fullWidth={true}
              select
              name="expenseTypeId"
              value={ expenseTypeId }
              onChange={ handleInputChangeExpense }
              SelectProps={{
                native: true,
              }}
            >
              {
                expenseTypes.map((expenseType, i) => {
                  return (
                    <option
                      key={i}
                      value={ expenseType.id }
                    >
                      { expenseType.name }
                    </option>
                  )
                })
              }
            </TextField>
          </div>
          <div className="col-md-12 mb-3">
             <div className="form-check mr-2">
                <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="primary"
                              name="globalAuthorization"
                              value={globalAuthorization}
                              onChange={handleCheckboxTrueFalseChange}
                              checked={globalAuthorization}
                            />
                          }
                          label="Clasificar todos los gastos con mismo proveedor y forma de pago"
                        />
                </FormGroup>
             </div>
          </div> 
          <div className="col-md-12 mb-3">
            <TextField label="total" variant="outlined" size="small" autoComplete="off" fullWidth={true}
              value={total}
              inputProps={
                {style: {textAlign: 'center'}}
              }
              disabled
            />
          </div>
          <div className="col-md-12 mb-3 text-right">
            <button className="btn btn-primary">
              Guardar
            </button>
          </div>
        </div>
      </form>
    </div>
  )
}
