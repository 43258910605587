const initialState = {
  IsrPlataformAlienationRanges: [
    {
      id: 1,
      lowerLimit: 0.01,
      upperLimit: 1500,
      porcentage: .004,
      year: 2020,
    },
    {
      id: 2,
      lowerLimit: 1500.1,
      upperLimit: 5000,
      porcentage: .005,
      year: 2020,
    },
    {
      id: 3,
      lowerLimit: 5000.1,
      upperLimit: 10000,
      porcentage: .009,
      year: 2020,
    },
    {
      id: 4,
      lowerLimit: 10000.1,
      upperLimit: 25000,
      porcentage: .011,
      year: 2020,
    },
    {
      id: 5,
      lowerLimit: 25000.1,
      upperLimit: 100000,
      porcentage: .02,
      year: 2020,
    },
    {
      id: 6,
      lowerLimit: 100000.1,
      upperLimit: null,
      porcentage: .054,
      year: 2020,
    },
    {
      id: 1,
      lowerLimit: 0.01,
      upperLimit: null,
      porcentage: .01,
      year: 2021,
    },
    {
      id: 1,
      lowerLimit: 0.01,
      upperLimit: null,
      porcentage: .01,
      year: 2022,
    },
    {
      id: 1,
      lowerLimit: 0.01,
      upperLimit: null,
      porcentage: .01,
      year: 2023,
    },
    {
      id: 1,
      lowerLimit: 0.01,
      upperLimit: null,
      porcentage: .01,
      year: 2024,
    }
  ]
}

export const IsrPlataformAlienationReducer = (state = initialState, action) => {
  switch (action.type) {

    default:
      return state;
  }
}
