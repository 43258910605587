import { TextField } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useForm } from '../../../../../../../../../hooks/useForm';
import NumberFormat from 'react-number-format'; 

const NoDeductibles = ({notConsideredExpenses, setFormValuesDetailsSummary}) => {

  const { formValues, setFormValues } = useForm({
    ivaTranslateds: 0.00,
    subtotal: 0.00,
    total: 0.00,
    totalIVATraslateds: {},
  });

  const { ivaTranslateds, subtotal, total, totalIVATraslateds } = formValues;

  useEffect(() => {
    let totalSum = 0.00;
    let subtotalSum = 0.00;
    let ivaTrasSum = 0.00;

    if(notConsideredExpenses.length > 0){
      notConsideredExpenses.forEach((expense, i) => {
        totalSum = (parseFloat(totalSum) + parseFloat(expense.total)).toFixed(2);
        if (expense.total > 0){
          subtotalSum = (parseFloat(subtotalSum) + parseFloat(expense.subTotal)).toFixed(2);
        }
        
        let totalIvaTras = 0.00;

        expense.concepts.forEach((concept) => {
          if(concept.impuestos && concept.impuestos.traslados && concept.impuestos.traslados.length > 0){
            concept.impuestos.traslados.forEach(taxe => {
              if (taxe.impuesto === '002') {
                let importe = taxe.importe === undefined ? 0.00 : taxe.importe
                totalIvaTras = (parseFloat(totalIvaTras) + parseFloat(importe)).toFixed(2);
              }
            })
          }
        })
        ivaTrasSum = (parseFloat(ivaTrasSum) + parseFloat(totalIvaTras)).toFixed(2);
        
        setFormValues(prevState => {
          return{
            ...prevState,
            totalIVATraslateds: {
              ...prevState.totalIVATraslateds,
              [i]: totalIvaTras
            }
          }
        })
      });
      setFormValues(prevState => {
        return{
          ...prevState,
          ivaTranslateds: ivaTrasSum,
          subtotal: subtotalSum,
          total: totalSum
        }
      });
    }else{
      setFormValues(prevState => {
        return{
          ...prevState,
          ivaTranslateds: 0.00,
          subtotal: 0.00,
          total: 0.00
        }
      });
    }

    setFormValuesDetailsSummary(prevState => {
      return{
        ...prevState,
        subtotalExpenseNotAutorized: subtotalSum,
        totalExpenseNotAutorized: totalSum,
      }
    })
  }, [notConsideredExpenses, setFormValues, setFormValuesDetailsSummary])

  return (
    <div className="row">
      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2">
        <div className="card">
          <div className="card-header">
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <h5>No deducibles</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2">
        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr className="text-center">
                <th>Fecha de pago</th>
                <th>Proveedor</th>
                <th>Folio</th>
                <th>Conceptos</th>
                <th>Clasificación</th>
                <th>Subtotal</th>
                <th>IVA</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              {
                notConsideredExpenses.map((notConsidered, index) => {
                  return (parseFloat(notConsidered.total) > 0 &&
                    <tr key={index} className="text-center">
                      <td> {notConsidered.paymentAt} </td>
                      <td>
                        { notConsidered.issuedRfc }
                        <br />
                        { notConsidered.issuedFullName }
                      </td>
                      <td>{notConsidered.folio}</td>
                      <td>
                        <TextField
                          label="Concepto" variant="outlined" size="small" autoComplete="off" fullWidth={true}
                          select
                          SelectProps={{
                            native: true,
                          }}
                          readOnly
                        >
                          {
                            notConsidered.concepts.map((concept, i) => {
                              return (
                                <option
                                  key={i}
                                >
                                  { concept.claveProdServ ? `${concept.claveProdServ} - ` : '' }
                                  { concept.descripcion }
                                </option>
                              )
                            })
                          }
                        </TextField>
                      </td>
                      <td><strong>{ notConsidered.description }</strong></td>
                      <td><NumberFormat value={parseFloat(notConsidered.subTotal).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td>
                      <td><NumberFormat value={parseFloat(totalIVATraslateds[index]).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td>
                      <td><NumberFormat value={parseFloat(notConsidered.total).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td>
                    </tr>
                  )
                })
              }
              <tr className="text-center">
                <td colSpan="5" className="text-right"><strong>Totales</strong></td>
                <td><NumberFormat value={parseFloat(subtotal).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td>
                <td><NumberFormat value={parseFloat(ivaTranslateds).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td>
                <td><NumberFormat value={parseFloat(total).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default NoDeductibles;
