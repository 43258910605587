import React, { useEffect, Fragment, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TextField } from '@material-ui/core';
import swal from 'sweetalert';
import { useForm } from '../../../../../hooks/useForm'
import { LogoContabilizate } from './LogoContabilizate';
import { crmPreSaleStartSelected, paymentPreSale } from '../../../../../actions/crm/preSales';
import { validateStringPresence, validatePhoneValid, validateEmailValid } from '../../../../../helpers/validateForm';
import { useParams, useHistory } from 'react-router';
import { CRM_SALES } from '../../../../constants/Routes';

export const Payment = () => {

    const dispatch = useDispatch();
    const { token } = useParams();
    let history = useHistory();

    const { crmSalePreSelectedInfo } = useSelector(state => state.crmPreSale);

    const [msgErrors, setMsgErrors] = useState({});
    const [msgNumberCard, setMsgNumberCard] = useState(undefined);
    const [msgYearCard, setMsgYearCard] = useState(undefined);
    const [msgMonthCard, setMsgMonthCard] = useState(undefined);
    const [msgCvvCard, setMsgCvvCard] = useState(undefined);
    const [typeCard, setTypeCard] = useState('');
    const [disabledText, SetDisabledText] = useState(false);
    const [productPayment, setProductPayment] = useState({})

    useEffect(() => {
        if (token) {
            dispatch(crmPreSaleStartSelected(token))
        }
    }, [token])


    useEffect(() => {
        if (crmSalePreSelectedInfo) {
            if (crmSalePreSelectedInfo.stepsJson && crmSalePreSelectedInfo.stepsJson.stepPayment) {
                if (crmSalePreSelectedInfo.stepsJson.stepPayment) {
                    setProductPayment(crmSalePreSelectedInfo.stepsJson.stepPayment)
                }
                if (crmSalePreSelectedInfo.stepsJson.stepPayment?.paymentLink && crmSalePreSelectedInfo.stepsJson.stepPayment.paid) {
                    SetDisabledText(true)
                }

            }

        }
    }, [crmSalePreSelectedInfo])

    const copyPass = (link) => {
        swal('Exito', 'Liga de pago copiada en portapapeles', 'success');
        navigator.clipboard.writeText(link)
    }

    return (
        <Fragment>
            {/* <div className="container">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 d-flex justify-content-center my-2">
                    <div className="card col-sm-8 shadow mb-4">
                        <div >
                            <LogoContabilizate name={"Liga de pago"} />

                            {productPayment?.paymentLink &&
                             <h5 className='text-info'>{productPayment.paymentLink?.concept} ${productPayment?.paymentLink?.items[0].unitPrice}</h5>
                            }
                           
                            { crmSalePreSelectedInfo?.payLink && !productPayment.paid &&
                              <i className=" btn fas fa-copy text-left text-primary enable-pointer-cursor-for-buttons" onClick={ () => copyPass(crmSalePreSelectedInfo.payLink)}> <span className='text-dark'> {crmSalePreSelectedInfo.payLink} </span></i>         
                            }
                             
                            <div className="col-12 text-center mb-3">
                                <div className="col-12 text-center">
                                    <img  className="text-center" height="300" src='welcomePreSale.png' />
                                </div>
                            </div>
                            {disabledText &&
                            <>
                                <h3 className='text-info text-center'>Pago realizado</h3> 
                            </>
                            }
                        </div>
                    </div>
                </div>
            </div> */}
        </Fragment>
    )
}