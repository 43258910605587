import { fetchWithApiKey } from "../../helpers/fetch";
import { types } from "../../types/types";

export const ExpenseTypesStartLoading = () => {
  return async(dispatch) => {
    const resp = await fetchWithApiKey( 'api/Catalogs/c_ExpenseType');
    const body = await resp.json();
    if (body.success){
      dispatch(expenseTypesLoaded(body.data))
    }
  }
}

const expenseTypesLoaded = (expenseTypes) => ({
  type: types.expenseTypesStartLoading,
  payload: expenseTypes
})