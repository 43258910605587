import { types } from "../../types/types";

const initialState = {
  expenseTypes: []
}

export const expenseTypeReducer = (state=initialState, action) => {
  switch (action.type) {
    case types.expenseTypesStartLoading:
      return{
        ...state,
        expenseTypes: [ ...action.payload ]
      }

    default:
      return state;
  }
}