import { types } from "../../types/types";

const initialState = {
    opinion: []
}

export const opinionComplementReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.opinionComplement:
            return {
                ...state,
                opinion: [...action.payload]
            }

        default:
            return state;
    }
}