import { types } from '../types/types';

export const serieFolioAddNew = ( serieFolio ) => ({
  type: types.serieFolioAddNew,
  payload: serieFolio
});

export const serieFolioUpdated = (serieFolio) => ({
  type: types.serieFolioUpdated,
  payload: serieFolio
})

export const serieFolioDeleted = (serieFolio) => ({
  type: types.serieFolioDeleted,
  payload: serieFolio
})
