import { IconButton, InputAdornment, TextField } from "@material-ui/core"
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTokenEmail } from "../../../../../actions/crm/preSales";
import { useForm } from "../../../../../hooks/useForm";

export const GenerarToken = () => {

    const { user } = useSelector(state => state.auth);
    const dispatch = useDispatch()

    const [showPassword, setShowPassword] = useState(false);
    const [msgErrors, setMsgErrors] = useState({});

    const { formValues, handlePasswordToken } = useForm({
        passwordToken: '',
    }
    ); 
    const { passwordToken } = formValues;

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    
    const handleSendPassword = ( e ) => {
        e.preventDefault();

        if( isFormValid() ){
            dispatch( getTokenEmail( passwordToken ))
        }
    }

    const isFormValid = () => {
        const errors = {}
        if (passwordToken.trim().length === 0) {
            errors['msgPasswordToken'] = "Es requerido."
        }
        setMsgErrors( errors );

        if(Object.keys(errors).length !== 0 ){
            return false;
          }
      
        return true;
    }

    return (
        <div>
            <div className="col-12 mb-3">
                <label> Ingresa tu contraseña para generar un Token - { user.userName }</label>
                <TextField
                    type={showPassword ? 'text' : 'password'}
                    label="Contraseña" variant="outlined" size="small" autoComplete="off" fullWidth={true}
                    name="passwordToken"
                    onChange={ handlePasswordToken }
                    helperText={ msgErrors.msgPasswordToken }
                    error={ msgErrors.msgPasswordToken !== undefined }
                    InputProps={{
                        endAdornment: (
                          <InputAdornment position='end'>
                            <IconButton
                              aria-label='toggle password visibility'
                              onClick={handleClickShowPassword}
                              onMouseUp={handleMouseDownPassword}
                            >
                              {showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                >         
                </TextField>
                <form onSubmit={ handleSendPassword }>
                    <button 
                        className="btn float-right btn-info mt-3"
                    > 
                        Enviar  
                    </button>
                </form>
            </div>
        </div>
  )
}
