import React from 'react';

export const AvisoPrivacidad = () => {

    return (                    
        <div>
            <div className="col-md-12">
                <a href='https://contabilizate.com/' target="_blank">Todos tus datos están protegidos, para más información consulta nuestro aviso de privacidad</a>          
            </div>
            <hr />
        </div>

    )
}
