import { fetchWithApiKey } from "../../helpers/fetch";
import { types } from "../../types/types";

export const activitiesStartLoading = () => {
  return async(dispatch) => {
    const resp = await fetchWithApiKey('api/Catalogs/c_Activities');
    const body = await resp.json();
    if (body.success){
      dispatch( activitiesLoaded( body.data ) )
    }
  }
}

const activitiesLoaded = ( accounters ) => ({
  type: types.activitiesStartLoading,
  payload: accounters
})