import { types } from "../../types/types";

const initialState = {
    regimes: []
}

export const regimesConfig = (state = initialState, action) => {
    switch (action.type) {
        case types.regimesConfig:
            return{
                ...state,
                regimes: [ ...action.payload]
            }

        default:
            return state;
    }
}
