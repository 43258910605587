import React from 'react';
import { TextField } from '@material-ui/core';

const Disabilities = () => {
    return (
        <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2">
                <p>Incapacidad por riesgo de trabajo</p>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-2">
                <TextField label="Días de incapacidad" variant="outlined" size="small" name="name_nomina" fullWidth={true}/>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-2">
                <TextField label="Descuentos por incapacidad" variant="outlined" size="small" name="name_nomina" fullWidth={true}/>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2">
                <hr/>
                <p>Incapacidad por enfermedad</p>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-2">
                <TextField label="Días de incapacidad" variant="outlined" size="small" name="name_nomina" fullWidth={true}/>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-2">
                <TextField label="Descuento por incapacidad" variant="outlined" size="small" name="name_nomina" fullWidth={true}/>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2">
                <hr/>
                <p>Incapacidad por Maternidad</p>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-2">
                <TextField label="Días de incapacidad" variant="outlined" size="small" name="name_nomina" fullWidth={true}/>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-2">
                <TextField label="Descuento por incapacidad" variant="outlined" size="small" name="name_nomina" fullWidth={true}/>
            </div>
        </div>
    )
}

export default Disabilities;
