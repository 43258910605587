import { fetchWithApiKey, fetchWithApiKeyBilling, fetchWithToken } from "../../helpers/fetch";
import { types } from "../../types/types";

export const userStartLoadingBilling = (token) => {
  return async (dispatch) => {
    const resp = await fetchWithApiKeyBilling('api/User', null, 'GET', token);
    const body = await resp.json();
    if (body.success) {
      dispatch(userBillingLoaded(body.data))
    } else {
      //console.log('ERROR CONEXION -', body)
    }
  }
}

const userBillingLoaded = (userData) => ({
  type: types.userBillingLoaded,
  payload: userData
})

export const regimesStartLoadingBilling = () => {
  return async (dispatch) => {
    const resp = await fetchWithApiKeyBilling('api/Catalogo/Regimes');
    const body = await resp.json();
    if (body.success) {
      dispatch(regimeBillingLoaded(body.data))
    } else {
      //console.log('ERROR CONEXION -', body)
    }
  }
}

export const receiverDataStartLoading = (idUser, token) => {
  return async (dispatch) => {
    const resp = await fetchWithApiKeyBilling('api/user?idUser=' + idUser, null, 'GET', token);
    const body = await resp.json();
    if (body.success) {
      dispatch(receiverData([body.data]))
    } else {
      //console.log('ERROR CONEXION -', body)
    }
  }
}

const receiverData = (receiverData) => ({
  type: types.receiverData,
  payload: receiverData
})

const regimeBillingLoaded = (regimes) => ({
  type: types.regimenesBilling,
  payload: regimes
})

export const wayOfPaymentsBilling = () => {
  return async (dispatch) => {
    const resp = await fetchWithApiKeyBilling('api/Catalogo/WayOfPayments', 'JIJIJ');
    const body = await resp.json();
    if (body.success) {
      dispatch(wayOfPaymentBillingLoaded(body.data))
    } else {
      //console.log('ERROR CONEXION -', body)
    }
  }
}

const wayOfPaymentBillingLoaded = (dataPayments) => ({
  type: types.wayOfPaymentBillingStartLoading,
  payload: dataPayments
})

export const paymentMethodBilling = () => {
  return async (dispatch) => {
    const resp = await fetchWithApiKeyBilling('api/Catalogo/PaymentMethods');
    const body = await resp.json();
    if (body.success) {
      dispatch(paymentMethodsLoaded(body.data))
    } else {
      //console.log('ERROR CONEXION -', body)
    }
  }
}

const paymentMethodsLoaded = (methods) => ({
  type: types.paymentMethodsBillingStartLoading,
  payload: methods
})

export const cfdiUsesBilling = () => {
  return async (dispatch) => {
    const resp = await fetchWithApiKeyBilling('api/Catalogo/CfdiUses');
    const body = await resp.json();
    if (body.success) {
      dispatch(cfdiUsesBillingLoaded(body.data))
    } else {
      //console.log('ERROR CONEXION -', body)
    }
  }
}

const cfdiUsesBillingLoaded = (cfdis) => ({
  type: types.cfdiUseBillingStartLoading,
  payload: cfdis
})

export const reportsBilling = () => {
  return async (dispatch) => {
    const resp = await fetchWithApiKeyBilling('api/Catalogo/Reports');
    const body = await resp.json();
    if (body.success) {
      //console.log('BODY-Reportes', body)
      // dispatch( reportsBillingLoaded( body.data ) )
    } else {
      //console.log('ERROR CONEXION -', body)
    }
  }
}

// const reportsBillingLoaded = ( reports ) => ({
//   type: types.regimenesStartLoading,
//   payload: reports
// })

export const productsBilling = (token) => {
  return async (dispatch) => {
    const resp = await fetchWithApiKeyBilling('api/Productos', null, 'GET', token);
    const body = await resp.json();
    if (body.success) {
      dispatch(productsBillingLoaded(body.data))
    } else {
      //console.log('ERROR CONEXION -', body)
    }
  }
}

const productsBillingLoaded = (prodcuts) => ({
  type: types.productBillingLoading,
  payload: prodcuts
})

export const customersBilling = (token) => {
  return async (dispatch) => {
    const resp = await fetchWithApiKeyBilling('api/Clientes', null, 'GET', token);
    const body = await resp.json();
    if (body.success) {
      dispatch(customersBillingLoaded(body.data))
    } else {
      //console.log('ERROR CONEXION -', body)
    }
  }
}

const customersBillingLoaded = (customers) => ({
  type: types.customerBillingLoading,
  payload: customers
})

export const recordInvoices = (id) => {
  return async (dispatch) => {
    const resp = await fetchWithToken(`api/Invoice/GetRecordinvoices/${id}`);
    const body = await resp.json();
    dispatch(recordInvoicesData(body))
  }
}

const recordInvoicesData = (record) => ({
  type: types.invoicesActive,
  payload: record
})
