import React, { useEffect } from 'react';

export const IvaRetainedTaxes = ({ CheckIvaRetained, ivaRetained, setIvaRetained, formValues, initialIvaRetained }) => {

  const { pretotalWithoutTaxes } = formValues;

  const { amount, porcentage } = ivaRetained;

  useEffect(() => {
    if (CheckIvaRetained) {
      const amountTaxe = pretotalWithoutTaxes * porcentage
  
      setIvaRetained( prevState => {
        return{
          ...prevState,
          amount: amountTaxe
        }
      })
    } else {
      setIvaRetained( initialIvaRetained )
    }
  }, [ CheckIvaRetained, porcentage, pretotalWithoutTaxes, setIvaRetained, initialIvaRetained ])

  const handleInputChange = ( { target } ) => {
    setIvaRetained({
      ...ivaRetained,
      [target.name]: target.value
    })
  }

  return (
    <div className="col-md-12 mb-3">
      {
        CheckIvaRetained && 
        (
          <div className="row mb-3">
            <div className="col-md-2">
              <strong>IVA</strong>
            </div>

            <div className="col-md-5">
              <div className="input-group input-group-sm">
                <select
                  className="form-control"
                  name="porcentage"
                  value={ porcentage }
                  onChange={ handleInputChange }
                >
                  <option value={0.1067}>10.67%</option>
                  <option value={0.16}>16%</option>
                  <option value={0.04}>4%</option>
                </select>
              </div>
            </div>

            <div className="col-md-5">
              <div className="input-group input-group-sm">
                <input
                  type="number"
                  className="form-control"
                  name="amount"
                  value={ amount }
                  onChange={ handleInputChange }
                />
              </div>
            </div>
          </div>
        )
      }
    </div>
  )
}
