import { types } from "../../types/types";


const initialState = {
  cfdiRelationTypes: [
    {
      id: 1,
      description: 'Nota de crédito de los documentos relacionados',
      sat_code: '01'
    },
    {
      id: 2,
      description: 'Nota de débito de los documentos relacionados',
      sat_code: '02'
    },
    {
      id: 3,
      description: 'Devolución de mercancía sobre facturas o traslados previos',
      sat_code: '03'
    },
    {
      id: 4,
      description: 'Sustitución de los CFDI previos',
      sat_code: '04'
    }
  ],
  cfdiRelationTypeId: 0,
  cfdiRelationsList: []
}

export const cfdiRelationTypeReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.cfdiRelatedsTypeAddToList:
      return {
        ...state,
        cfdiRelationsList: [
          ...state.cfdiRelationsList,
          action.payload
        ]
      }
    
    case types.cfdiRelatedsTypeDeleteToList:
      return {
        ...state,
        cfdiRelationsList: state.cfdiRelationsList.filter(
          e => ( e.id !== action.payload )
        ),
      }

    case types.cfdiRelatedsChangeTypeSelected:
      return {
        ...state,
        cfdiRelationTypeId: action.payload.id
      }
    

    default:
      return state;
  }
}
