import { TextField, InputAdornment } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { productToSalesStartLoading } from '../../../../../actions/catalogs/productToSales';
import { crmReferenceRedPayFinish } from '../../../../../actions/crm/subscriptions';

export const ModuleSaleScreen = ({isAdmin = false, isAccounting = false, isInvoicing = false, isSale = true, msgErrors, saleFormValues, saleSetFormValues, saleHandleInputChange}) => {

  const {paymentStatusTypes} = useSelector(state => state.paymentStatusType);
  const {productToSales} = useSelector(state => state.productToSale);
  const { crmSaleSelected } = useSelector(state => state.crmSale);

  const { referenceDataRedPay, referenceDataRedPayFinish } = useSelector(state => state.subscriptionSale);
  
  const [assisted,setAssisted]=useState(false);
  const [saleAccounting,setSaleAccounting] = useState(false);

  const dispatch = useDispatch();

  const { endServiceAt, salesData, startServiceAt, paymentStatusId, total } = saleFormValues;

  useEffect(() => {
    if (productToSales.length === 0) {
      dispatch(productToSalesStartLoading());
    }
  }, [dispatch, productToSales]);

  useEffect(() => {
    let totalSales = 0;
    salesData.forEach(sale => {
      totalSales = (parseFloat(totalSales) + parseFloat(sale.price)).toFixed(6)
    })

    saleSetFormValues(prevState => {
      return{
        ...prevState,
        total: totalSales
      }
    })
  }, [salesData, saleSetFormValues]);

  /* const handleAddProduct = (e) => {
    e.preventDefault();
    
    saleSetFormValues(prevState => {
      return{
        ...prevState,
        salesData: [
          ...prevState.salesData,
          {
            id: new Date().getTime(),
            productId: 0,
            price: 0,
            startServiceAt: '',
            endServiceAt: ''
          }
        ]
      }
    })
  }

  const handleADeleteProduct = (id, e) => {
    e.preventDefault();
    
    saleSetFormValues(prevState => {
      return{
        ...prevState,
        salesData: prevState.salesData.filter(
          e => ( e.id !== id )
        )
      }
    })
  } */

  useEffect(() =>{
    if(Object.keys(referenceDataRedPay).length !== 0 && referenceDataRedPayFinish){
      let id=referenceDataRedPay.productId;
      const product = productToSales.filter( e => ( e.id === id ))[0];
        changeTypeAssisted(product)
        dispatch(crmReferenceRedPayFinish())
    }
  },[saleAccounting,assisted,referenceDataRedPay]);

  const handleInputChangeSale = (id, e) => {
    const name = e.target.name;
    const value = e.target.value;

    saleSetFormValues(prevState => {
      return{
        ...prevState,
        salesData: prevState.salesData.map(
          e => ( e.id === id ) ? 
          {
            ...e,
            [name]: value
          } 
          : 
          e
        )
      }
    })
  }
  const changeTypeAssisted = (product) =>{
    if(product.productType.key === "Accounting"){
      setSaleAccounting(true)
      if(product.isAssisted){
        setAssisted(true)
      }else{
        setAssisted(false)
      }
    }else{
      setSaleAccounting(false)
    }  
  }

  const handleInputChangeProduct = (id, e) => {
    const value = e.target.value;

    const product = productToSales.filter( e => ( e.id === parseInt(value) ))[0];

    changeTypeAssisted(product)

    saleSetFormValues(prevState => {
      return{
        ...prevState,
        salesData: prevState.salesData.map(
          e => ( e.id === id ) ? 
          {
            ...e,
            productId: value,
            price: product.unitPrice
          } 
          : 
          e
        )
      }
    })
  }

  return (
    <Fragment>
      { isAccounting  && saleAccounting && salesData[0].price > 0 &&
      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3">
          {assisted  ? (
            <span class="text-success"><i class="fas fa-check"> Asistido</i></span>
          ):(
            <span class="text-danger"><i class="fas fa-times"> No asistido</i></span>
          )
          }
      </div>
      }
      {
        salesData.map((sale, index) => {
          return(
            <Fragment key={index}>
              <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-3">
                <TextField
                  label="Producto*" variant="outlined" size="small" autoComplete="off" fullWidth={true}
                  select
                  SelectProps={{
                    native: true,
                  }}
                  name="productId"
                  value={sale.productId}
                  onChange={ handleInputChangeProduct.bind(this, sale.id) }
                  helperText={ msgErrors[`productId${index}`] }
                  error={ msgErrors[`productId${index}`] !== undefined }
                  disabled={ !!crmSaleSelected  || Object.keys(referenceDataRedPay).length !== 0  }
                >
                  <option value='0'>Selecciona un producto</option>
                  {
                    productToSales.map((productToSale, j) => {
                      if (isAccounting) {
                        return(productToSale.productType.key === 'Accounting' && productToSale.isSubscription != true &&
                          <option
                            key={ j }
                            value={ productToSale.id }
                          > 
                            { productToSale.description } - ${productToSale.unitPrice}
                          </option>  
                        )
                      }else{
                        if(isInvoicing){
                          return(productToSale.productType.key === 'Stamp' && productToSale.isSubscription != true &&
                            <option
                              key={ j }
                              value={ productToSale.id }
                            > 
                              { productToSale.description } - ${productToSale.unitPrice}
                            </option>  
                          )
                        }else{
                          return(
                            <option
                              key={ j }
                              value={ productToSale.id }
                            > 
                              { productToSale.description } - ${productToSale.unitPrice}
                            </option>  
                          )
                        }
                      }
                      
                    })
                  }
                </TextField>
              </div>
              <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-3">
                <TextField
                  label="Importe*" variant="outlined" size="small" autoComplete="off" fullWidth={true}
                  type="number"
                  name="price"
                  value={parseFloat(sale.price).toFixed(2)}
                  onChange={ handleInputChangeSale.bind(this, sale.id) }
                  helperText={ msgErrors[`price${index}`] }
                  error={ msgErrors[`price${index}`] !== undefined }
                  disabled={ !!crmSaleSelected  || Object.keys(referenceDataRedPay).length !== 0 }
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,

                  }}
                />
              </div>
              {/* {
                index !== 0  &&
                (
                  <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3">
                    <button type="button" className="btn btn-outline-danger" onClick={handleADeleteProduct.bind(this, sale.id)}
                      disabled={ !!crmSaleSelected }
                    >
                      <i className="fas fa-minus"></i> eliminar producto
                    </button>
                  </div>
                )
              } */}

            </Fragment>
          )
        })
      }

      {/* <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3 text-right">
        <button type="button" className="btn btn-outline-primary" onClick={handleAddProduct}
          disabled={ !!crmSaleSelected }
        >
          <i className="fas fa-plus"></i> Agregar producto
        </button>
      </div> */}
      
      <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-3">
        <TextField
          label="Fecha inicial servicio*" variant="outlined" size="small" autoComplete="off" fullWidth={true}
          type="date"
          InputLabelProps={{shrink: true,}}
          name="startServiceAt"
          value={startServiceAt}
          onChange={ saleHandleInputChange }
          helperText={ msgErrors.startServiceAt }
          error={ msgErrors.startServiceAt !== undefined }
          disabled={ !!crmSaleSelected }
        />
      </div>

      <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-3">
        <TextField
          label="Fecha Final servicio*" variant="outlined" size="small" autoComplete="off" fullWidth={true}
          type="date"
          InputLabelProps={{shrink: true,}}
          name="endServiceAt"
          value={endServiceAt}
          onChange={ saleHandleInputChange }
          helperText={ msgErrors.endServiceAt }
          error={ msgErrors.endServiceAt !== undefined }
          disabled={ !!crmSaleSelected }
        />
      </div>

      <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-3">
        {
          (isAdmin && !isSale) &&
          <TextField
            label="Estatus del pago" variant="outlined" size="small" autoComplete="off" fullWidth={true}
            name="paymentStatusId"
            select
            SelectProps={{
              native: true,
            }}
            value={paymentStatusId}
            onChange={ saleHandleInputChange }
          >
            <option value='0' disabled>Selecciona un estatus</option>
            {
              paymentStatusTypes.map((status, index) => {
                return(
                  <option
                    key={ index }
                    value={ status.id }
                  > 
                    { status.description }
                  </option>  
                )
              })
            }
          </TextField>
        }
      </div>
      
      <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-3">
        <TextField
          label="Total*" variant="outlined" size="small" autoComplete="off" fullWidth={true}
          type="number"
          name="total"
          value={parseFloat(total).toFixed(2)}
          onChange={ saleHandleInputChange }
          helperText={ msgErrors.total }
          error={ msgErrors.total !== undefined }
          disabled={ !!crmSaleSelected  || Object.keys(referenceDataRedPay).length !== 0  }

          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
        />
      </div>
    </Fragment>
  )
}

ModuleSaleScreen.propTypes = {
  saleFormValues: PropTypes.object.isRequired,
  saleSetFormValues: PropTypes.func.isRequired
}