import { types } from "../../types/types";

const initialState = {
  retentions: [],
  finishRetentions:false
}

export const retentionReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.retentionsStartLoading:
      return{
        ...state,
        retentions: [ ...action.payload ]
      }
      case types.retentionsFinishLoading:
        return{
          ...state,
          finishRetentions: action.payload
        }
  

    default:
      return state;
  }
}
