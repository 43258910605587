import React, { Fragment, useState,useEffect } from 'react';
import { TextField } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { crmReferenceRedPay, crmReferenceRedPayReset } from '../../../../../actions/crm/subscriptions';
import moment from 'moment'
export const ModulePaymentReference = ({ saleFormValues,saleSetFormValues }) => {

  const dispatch = useDispatch();
  const { referenceDataRedPay } = useSelector(state => state.subscriptionSale);
  const {productToSales} = useSelector(state => state.productToSale);
  const { salesData} = saleFormValues;

  const [ paymentReference, setPaymentReference] = useState('');
  const [ paymentAuthorization, setPaymentAuthorization] = useState('');

   useEffect(() => {
    if(Object.keys(referenceDataRedPay).length !== 0){
    saleSetFormValues(prevState => {
      return{
        ...prevState,
        GatewayPaymentsId:referenceDataRedPay.gatewayPaymentsId
      }
      
    })
    }
  }, [salesData, saleSetFormValues]);

  useEffect(() => {
    if(Object.keys(referenceDataRedPay).length !== 0){
    let id=referenceDataRedPay.productId;
      const product = productToSales.filter( e => ( e.id === id ))[0];
      saleSetFormValues(prevState => {
        return{
          ...prevState,
          salesData: prevState.salesData.map(
            e => ( e.productId === id ) ? 
             e
            : 
            {
              ...e,
              productId: product.id,
              price: product.unitPrice,
            }
          )
        }
      })
    
      saleSetFormValues(prevState=>{
        return{
          ...prevState,
          voucher: {
            ...prevState.voucher,
              paymentAt:moment(referenceDataRedPay.paymentAt).format('YYYY-MM-DD'),
          }
        }
      })
    }
  }, [referenceDataRedPay, dispatch, saleSetFormValues]);

  useEffect ( () => {
    clearReference()
  },[])


  const handleReferenceChange = ({ target }) => {
    const { value } = target;
    setPaymentReference(value)
  }
  const handleAuthorizationChange = ({ target }) => {
    const { value } = target;
    setPaymentAuthorization(value)
  }

  const searchReference = () => {
   // let reference = paymentReference+"-"+paymentAuthorization;
    let reference = paymentReference+"-MercadoPago";
    dispatch(crmReferenceRedPay({reference: reference}))
  }

  const clearReference = () => {
    setPaymentReference('')
    setPaymentAuthorization('')
    dispatch(crmReferenceRedPayReset())
    saleSetFormValues(prevState => {
      return{
        ...prevState,
        GatewayPaymentsId:0,
        voucher: {
          voucherFiles: '',
          paymentAt: '',
          isLoadedVoucher: false
        },
        salesData:[
          {
            id: new Date().getTime(),
            price: 0,
            productId: 0,
          }
        ],
      }
    })
  }

  return (
    <Fragment >
      <div className='row  ml-2'>
          <div className='col-sm-12 col-md-8 col-lg-6 row '>
            <div className="col-5 p-0 mb-3 ">
              <TextField
                  label="Referencia de Pago" variant="outlined" size="small" autoComplete="off" fullWidth={true} 
                  name="paymentReference"
                  value={ paymentReference }
                  onChange={ handleReferenceChange }
                  disabled={ Object.keys(referenceDataRedPay).length !== 0  }
                />
            </div>
            <div className="col-md-4 col-lg-6 mb-3 text-sm-right text-md-left">
            { Object.keys(referenceDataRedPay).length === 0 ? 
                (
                <button type="button" className="btn btn-outline-primary" onClick={searchReference}>
                Buscar <i className="fas fa-search"></i>
              </button>
              ):(
                <button type="button" className="btn btn-outline-primary" onClick={ clearReference }>
                Editar <i className="fas fa-edit"></i>
              </button>
              )
            }
          </div>
            {/* <div className='my-1 text-center'>
              <span color='black'>-</span>
            </div>
            <div className="col-5 mb-3 p-0">
              <TextField
                label="Autorizacion de Pago" variant="outlined" size="medium" size="small" autoComplete="off" fullWidth={true} 
                name="paymentAuthorization"
                value={ paymentAuthorization }
                onChange={ handleAuthorizationChange }
                disabled={ Object.keys(referenceDataRedPay).length !== 0  }
              />  
            </div> */}
          </div>
      </div>
    </Fragment>
  )
}
