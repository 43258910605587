import React, { useContext, useEffect, useState } from 'react';
import { FormControlLabel, FormHelperText, Radio, RadioGroup, TextField } from '@material-ui/core';
import { useForm } from '../../../../hooks/useForm';
import { TaxAddressForm } from '../taxAddress/TaxAddressForm';
import { validateStringPresence, validateRfcValid, validateArrayPresence } from '../../../../helpers/validateForm';
import { useDispatch, useSelector } from 'react-redux';
import { ThirdPartyStartRegister } from '../../../../actions/thirdParties';
import { ModalContext } from '../../../modal/ModalContext';
import { thirdPartyTypesStartLoading } from '../../../../actions/catalogs/thirdPartyTypes';

export const ThirdPartyForm = ({ thirdParty = null, formAccounting = false, taxpayerId = null }) => {

  const { handleModal } = useContext(ModalContext);
  const { thirdPartyTypes } = useSelector(state => state.thirdPartyType)
  const dispatch = useDispatch();

  const { formValues, handleCheckboxTrueFalseChange, handleInputChange, handleObjectAddQuitChange, setFormValues } = useForm({
    address: {
      country: '',
      interior: '',
      outdoor: '',
      postalCode: '',
      street: '',
      suburb: '',
      town: '',
    },
    idFiscal: '',
    curp: '',
    businessName: '',
    rfc: '',
    isNational: true,
    thirdPartieTypes: []
  });

  const { address, idFiscal, curp, businessName, rfc, isNational, thirdPartieTypes } = formValues;

  const { postalCode } = address;

  const [msgErrors, setMsgErrors] = useState({});

  useEffect(() => {
    if (thirdPartyTypes.length === 0) {
      dispatch(thirdPartyTypesStartLoading());
    }
  }, [dispatch, thirdPartyTypes])

  useEffect(() => {
    if (!isNational) {
      setFormValues(prevState => {
        return {
          ...prevState,
          rfc: 'XEXX010101000'
        }
      })
    } else {
      setFormValues(prevState => {
        return {
          ...prevState,
          rfc: ''
        }
      })
    }
  }, [setFormValues, isNational])

  useEffect(() => {
    if (!formAccounting) {
      const thirdPartyType = thirdPartyTypes.filter(e => (e.key === 'CLIE'))[0]
      setFormValues(prevState => {
        return {
          ...prevState,
          thirdPartieTypes: [thirdPartyType.id]
        }
      })
    }
  }, [formAccounting, setFormValues, thirdPartyTypes])

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isFormValid()) {
      if (thirdParty) {
        //console.log('Se va a actualizar');
      }
      else {
        const register = {
          address: address,
          businessName: businessName,
          cp: address.postalCode,
          curp: curp,
          idFiscal: idFiscal,
          isNational: isNational,
          profileId: taxpayerId,
          rfc: rfc.toUpperCase(),
          thirdPartieTypes: thirdPartieTypes,
        };
        dispatch(ThirdPartyStartRegister(register, handleModal.bind()))
      }
    }
  }

  const isFormValid = () => {
    setMsgErrors({});
    const datasToValidate = { businessName, postalCode }
    const arrayToValidateLength = { thirdPartieTypes }

    const validateString = validateStringPresence(datasToValidate, setMsgErrors);
    const validateRfc = validateRfcValid(rfc, setMsgErrors);
    const validateArraySize = validateArrayPresence(arrayToValidateLength, setMsgErrors)

    if (validateString && validateRfc && validateArraySize) {
      return true;
    }
    return false;
  }

  return (
    <div>
      <div className="text-center mb-4">
        <h4>Agregar cliente</h4>
      </div>
      <hr />
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-md-12 d-flex justify-content-center">
            <RadioGroup row name="isNational" value={isNational} onChange={handleCheckboxTrueFalseChange}>
              <FormControlLabel
                value={true}
                control={<Radio color="primary" />}
                label="Nacional"
              />
              <FormControlLabel
                value={false}
                control={<Radio color="primary" />}
                label="Extranjero"
              />
            </RadioGroup>
          </div>

          <div className="col-md-6 mb-3">
            <TextField
              label="Nombre o Razón social"
              id="name"
              variant="outlined"
              size="small"
              name="businessName"
              autoComplete="off"
              value={businessName}
              onChange={handleInputChange}
              fullWidth={true}
              helperText={msgErrors.businessName}
              error={msgErrors.businessName !== undefined}
            />
          </div>

          <div className="col-md-6 mb-3">
            <TextField
              label="RFC"
              id="rfc"
              variant="outlined"
              size="small"
              name="rfc"
              autoComplete="off"
              value={rfc}
              onChange={handleInputChange}
              fullWidth={true}
              inputProps={
                { style: { textTransform: 'uppercase' } }
              }
              helperText={msgErrors.rfc}
              error={msgErrors.rfc !== undefined}
            />
          </div>

          {!isNational &&
            (
              <div className="col-md-6 mb-3">
                <TextField
                  label="ID Fiscal"
                  id="idFiscal"
                  variant="outlined"
                  size="small"
                  name="idFiscal"
                  autoComplete="off"
                  value={idFiscal}
                  onChange={handleInputChange}
                  fullWidth={true}
                />
              </div>
            )
          }

          <div className="col-md-6 mb-3">
            <TextField
              label="CURP"
              id="curp"
              variant="outlined"
              size="small"
              name="curp"
              autoComplete="off"
              value={curp}
              onChange={handleInputChange}
              fullWidth={true}
            />
          </div>

          <div className="col-md-6 mb-3">
            <TextField
              label="Correo electrónico"
              id="email"
              variant="outlined"
              size="small"
              name="email"
              autoComplete="off"
              fullWidth={true}
            />
          </div>

          <TaxAddressForm
            address={address}
            setFormValues={setFormValues}
            msgErrors={msgErrors}
          />

          {
            formAccounting && (
              <div className="col-md-12 d-flex justify-content-center">
                {
                  thirdPartyTypes.map((thirdPartyType, index) => {
                    return (
                      <div className="form-check mr-2" key={index}>
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="thirdPartieTypes"
                          id={thirdPartyType.key}
                          value={thirdPartyType.id}
                          onChange={handleObjectAddQuitChange}
                        />
                        <label className="form-check-label" htmlFor={thirdPartyType.key}>
                          <strong>{thirdPartyType.description}</strong>
                        </label>
                      </div>
                    )
                  })
                }
              </div>
            )
          }
          <div className="col-md-12">
            {
              msgErrors.thirdPartieTypes && (
                <FormHelperText
                  align="center"
                  style={{ textAlign: 'center' }}
                  error
                >
                  {msgErrors.thirdPartieTypes}
                </FormHelperText>
              )
            }
          </div>

          <div className="col-md-12 text-right">
            <button type="submit" className="btn btn-primary">Guardar</button>
          </div>

        </div>
      </form>
    </div>
  )
}
