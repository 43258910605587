import React, { Fragment } from 'react';

export const LogoContabilizate = ({name}) => {

    return (
        <Fragment>
             <div className="text-center">
                <img  className="text-center" height="100" src='logocontabilizate.png' />
            </div>
            <div className="pb-1">
                <h3 className='text-start'>{name}</h3>
            </div>
        </Fragment>
    )
}
