import React, { useContext, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ListProduct } from '../../crm/sales/PreSale/ListProduct'
import { ModalContext } from '../../../modal/ModalContext'
import { UploadFileCsdBilling } from './UploadFileCsdBilling'
import { userStartLoadingBilling } from '../../../../actions/billing/catalogs'

export const UploadFiles = () => {

    const { invoicesActivation } = useSelector(state => state.invoiceBilling)
    const { userInvoices } = useSelector(state => state.userInvoice)

    const nameProfile = invoicesActivation?.data?.profile?.businessName
    const dispatch = useDispatch()
    const { handleModal } = useContext(ModalContext);

    useEffect(() => {
        dispatch(userStartLoadingBilling(invoicesActivation.data.appKey));
        //console.log(invoicesActivation)
    }, [])

    return (
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2 shadow-lg p-3 mb-2 bg-white rounded">
            <div className='row'>
                {
                    (!invoicesActivation.data) ? (
                        <div className='container col-xs-12 col-sm-12 col-md-6 text-center'>
                            <div>
                                <h5 className='text-danger'> <b>Aun no tienes Activado tu Módulo de Facturación</b> </h5>
                                <h5> <b> Dirigete a la pestana de Facturación para activarlo.</b> </h5>
                                <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-4 shadow-lg p-3 mb-2 bg-white rounded'>
                                    <h5><b> Para tener acceso a suvir los Sellos Fiscales es necesario activar un perfil.</b></h5>
                                </div>
                            </div>
                            <div>
                                <img className="text-center" height="300" src='chekList.png' />
                            </div>
                        </div>
                    )
                        :
                        (
                            <>
                                <div className='container col-xs-12 col-sm-12 col-md-6 '>
                                    <div className='row'>
                                        <div className='col-xs-12 col-sm-12 col-md-6 text-center'>
                                            <img src='logo.jpg' alt='Logo' width='150' height='150' />
                                        </div>
                                        <div className='col-xs-12 col-sm-12 col-md-6'>
                                            <div className='row'>
                                                <h6> <b>RFC: </b> {invoicesActivation.data.rfc}  </h6>
                                                <h6> <b> {nameProfile}  </b>  </h6>
                                            </div>
                                            <div className='row'>
                                                <button className='btn btn-outline-secondary mb-2 ml-2'> Modificar </button>
                                                <button
                                                    className="btn btn-outline-secondary mb-2" target="_blank"
                                                    onClick={() => handleModal(
                                                        <UploadFileCsdBilling />
                                                    )}
                                                >
                                                    CSD
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='container col-xs-12 col-sm-12 col-md-6 text-center'>
                                    <h6> <b>Appkey: </b> {invoicesActivation.data.appKey}  </h6>
                                </div>

                                <div className="alert alert-danger col-md-12 text-center" role="alert">
                                    <b> Los archivos fiscales CSD son obligatorios para emitir facturas. </b>
                                </div>
                            </>
                        )
                }
            </div>
        </div>
    )
}
