import { types } from "../../types/types";

const initialState = {
  cfdiUses: []
}

export const cfdiUseReducer = ( state = initialState, action ) => {
  switch (action.type) {
    
    case types.cfdiUseStartLoading:
      return{
        ...state,
        cfdiUses: [ ...action.payload ]
      }

    default:
      return state;
  }
}
