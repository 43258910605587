import React from 'react';
import PropTypes from 'prop-types';
import { useEffect, useState } from "react";
import { getCSVWithRFC, getHelpData, uploadFileCSV } from '../../../../../../actions/crm/sales';
import { useDispatch, useSelector } from "react-redux";
import { Container, Typography, Button } from '@material-ui/core';

const UploadCSVModal = ({ rfcValue }) => {

	const dispatch = useDispatch();

	const IsUpdate = true;

	const { Instructions } = useSelector(state => state.instructions);

	useEffect(() => {
		dispatch(getHelpData());
	}, []);

	const getCSV = () => {
		dispatch(getCSVWithRFC(rfcValue, isAnnual));
	};

	const [selectedFile, setSelectedFile] = useState(null);
	const [isAnnual, setisAnnual] = useState(false);

	const handleFileChange = (event) => {
		setSelectedFile(event.target.files[0]);
	};

	const handleCheckboxChange = () => {
		setisAnnual(!isAnnual);
	};

	const handleUpload = () => {
		dispatch(uploadFileCSV(selectedFile, rfcValue, isAnnual, IsUpdate));
	};

	return (
		<div>
			<div className="gap-2">
				<div className="d-flex flex-column justify-content-center align-items-center gap-2 pt-4">
					<div>
						<h5 className=''>Descarga de la plantilla</h5>
					</div>
					<div className="form-check">
						<input
							className="form-check-input"
							type="checkbox"
							id="checkId"
							checked={isAnnual}
							onChange={handleCheckboxChange}
						/>
						<label className="form-check-label" htmlFor="checkId">
							Es Anual?
						</label>
					</div>
					{/* <div className="form-check">
						<input
							className="form-check-input"
							type="checkbox"
							id="checkId"
							checked={IsUpdate}
							onChange={handleCheckboxChangeUpdate}
						/>
						<label className="form-check-label" htmlFor="checkId">
							Es actualizable?
						</label>
					</div> */}
					<div className='d-flex flex-row justify-content-center align-content-center align-items-center gap-2'>
						<div className='pr-2'>
							<button
								data-toggle="tooltip"
								data-placement="top"
								title="Descargar CSV"
								className="btn btn-success m-1 custom-cursor-not-allowed"
								onClick={getCSV}
							>
								Descargar plantilla
							</button>
						</div>
					</div>
				</div>
				<div className="">
					<div className="d-flex flex-column justify-content-center align-items-center gap-2 pt-4">
						<div>
							<h6>Subida de la plantilla</h6>
						</div>
						<input
							accept=".csv"
							style={{ display: 'none' }}
							id="contained-button-file"
							type="file"
							onChange={handleFileChange}
						/>
						<label htmlFor="contained-button-file">
							<Button variant="contained" color="primary" component="span">
								Seleccionar archivo
							</Button>
						</label>
						{selectedFile && (
							<div variant="body1" style={{ marginTop: '16px' }}>
								Plantilla seleccionada: {selectedFile.name}
							</div>
						)}
						<Button
							variant="contained"
							color="primary"
							style={{ marginTop: '16px' }}
							onClick={handleUpload}
							disabled={!selectedFile}
						>
							Subir plantilla
						</Button>
						{
							isAnnual && (
								<div className='pt-4'>
									*La descarga del documento y la subida del mismo aplicarán para la declaración anual *
								</div>
							)
						}
						{
							!isAnnual && (
								<div className='pt-4'>
									*La descarga del documento y la subida del mismo no aplicarán para la declaración anual *
								</div>
							)
						}
					</div>
				</div>
			</div>
		</div>
	);
};

UploadCSVModal.propTypes = {};

export default UploadCSVModal;
