import React, { Fragment } from 'react';
import { TextField } from '@material-ui/core';

export const TaxAddressForm = ({ address, isDisabled, msgErrors = {}, setFormValues }) => {

  const { country, interior, outdoor, postalCode, street, suburb, town } = address;

  const handleAddressChange = ({ target }) => {
    const { name, value } = target;
    setFormValues(prevState => {
      return{
        ...prevState,
        address: {
         ...prevState.address,
         [name]: value 
        }
      }
    })
  }

  return (
    <Fragment>
      <div className="col-md-6 mb-3">
        <TextField
          label="Código Postal*" variant="outlined" size="small" autoComplete="off" fullWidth={true}
          name="postalCode"
          value={ postalCode }
          onChange={ handleAddressChange }
          helperText={ msgErrors.postalCode }
          error={ msgErrors.postalCode !== undefined }
          disabled={isDisabled}
        />
      </div>

      <div className="col-md-6 mb-3">
        <TextField
          label="Pais" variant="outlined" size="small" autoComplete="off" fullWidth={true}
          name="country"
          value={ country }
          onChange={ handleAddressChange }
          disabled={isDisabled}
        />
      </div>

      <div className="col-md-6 mb-3">
        <TextField
          label="Municipio" variant="outlined" size="small" autoComplete="off" fullWidth={true}
          name="suburb"
          value={ suburb }
          onChange={ handleAddressChange }
          disabled={isDisabled}
        />
      </div>

      <div className="col-md-6 mb-3">
        <TextField
          label="Colonia" variant="outlined" size="small" autoComplete="off" fullWidth={true}
          name="town"
          value={ town }
          onChange={ handleAddressChange }
          disabled={isDisabled}
        />
      </div>

      <div className="col-md-6 mb-3">
        <TextField
          label="Calle" variant="outlined" size="small" autoComplete="off" fullWidth={true}
          name="street"
          value={ street }
          onChange={ handleAddressChange }
          disabled={isDisabled}
        />
      </div>

      <div className="col-md-6 mb-3">
        <TextField
          label="Número Ext." variant="outlined" size="small" autoComplete="off" fullWidth={true}
          name="outdoor"
          value={ outdoor }
          onChange={ handleAddressChange }
          disabled={isDisabled}
        />
      </div>

      <div className="col-md-6 mb-3">
        <TextField
          label="Número Int." variant="outlined" size="small" autoComplete="off" fullWidth={true}
          name="interior"
          value={ interior }
          onChange={ handleAddressChange }
          disabled={isDisabled}
        />
      </div>
    </Fragment>
  )
}
