import { Checkbox, FormControl, FormHelperText, InputLabel, ListItemText, MenuItem, Select, TextField } from '@material-ui/core';
import React, { Fragment, useEffect,useContext} from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { professionTypesStartLoading } from '../../../../../actions/catalogs/professions';
import { regimesStartLoading } from '../../../../../actions/catalogs/regimes';
import { serviceTypesStartLoading } from '../../../../../actions/catalogs/serviceTypes';
import { zoneTypesStartLoading } from '../../../../../actions/catalogs/zoneTypes';
import { ModalContext } from '../../../../modal/ModalContext';
import { ModuleValidarionPasswordSat } from './ModuleValidationPasswordSat';
export const ModuleTaxpayerScreen = ({msgErrors, saleFormValues, saleSetFormValues,isAccounting = false, isInvoicing = false}) => {
  
  const {customerData, profileData} = saleFormValues;

  const {phoneMobile} = customerData;
  const inputRef= React.useRef();
  const {professionTypes} = useSelector(state => state.professionType);
  const { regimes } = useSelector(state => state.regime);
  const {serviceTypes} = useSelector(state => state.serviceType);
  const {zoneTypes} = useSelector(state => state.zoneType);
  const { crmSaleSelected } = useSelector(state => state.crmSale);
  const { loadingValidationSat,sesionSat,captcha } = useSelector(state => state.validationSat)
  const { handleModal } = useContext(ModalContext);
  const dispatch = useDispatch();

  useEffect(() => {
    saleSetFormValues(prevState => {
      return{
        ...prevState,
        profileData: {
          ...prevState.profileData,
          phoneMobile: phoneMobile
        }
      }
    })
  }, [phoneMobile, saleSetFormValues])

  useEffect(() => {
    if (serviceTypes.length === 0) {
      dispatch(serviceTypesStartLoading())
    }
  }, [dispatch, serviceTypes]);

  useEffect(() => {
    if (serviceTypes.length > 0) {
      const serviceType = serviceTypes.filter(e => (e.key === 'CONTA'))[0];
      saleSetFormValues(prevState => {
        return{
          ...prevState,
          profileData: {
            ...prevState.profileData,
            serviceTypes: serviceType.id
          }
        }
      })
    }
  }, [saleSetFormValues, serviceTypes])

  useEffect(() => {
    if (zoneTypes.length === 0) {
      dispatch(zoneTypesStartLoading())
    }
  }, [dispatch, zoneTypes]);

  useEffect(() => {
    if (regimes?.length <= 0){
      dispatch(regimesStartLoading())
    }
  }, [dispatch, regimes])

  useEffect(() => {
    if (zoneTypes.length > 0) {
      const zoneType = zoneTypes.filter(e => (e.key === 'CENTR'))[0];
      saleSetFormValues(prevState => {
        return{
          ...prevState,
          profileData: {
            ...prevState.profileData,
            zoneTypes: zoneType.id
          }
        }
      })
    }
  }, [saleSetFormValues, zoneTypes]);

  useEffect(() => {
    if (professionTypes.length === 0) {
      dispatch(professionTypesStartLoading())
    }
  }, [dispatch, professionTypes])

  useEffect(() => {
    if(loadingValidationSat){
      setTimeout(() => {
        inputRef.current.focus();
      }, 100);
    }
  }, [loadingValidationSat,captcha])

  const mostrarModal = () => {
 if(profileData.rfc.length && profileData.passwordSAT){
    handleModal(
      <ModuleValidarionPasswordSat 
        rfcContribuyente={profileData.rfc}
        passwordSat={profileData.passwordSAT}
      />)
    }
  }
  const handleInputChange = ({target}) => {
    const {name, value} = target;

    saleSetFormValues(prevState => {
      return{
        ...prevState,
        profileData: {
          ...prevState.profileData,
          [name]: value
        }
      }
    })
  }

  const handleChangeSelectMultiple = (e) => {
    const { value } = e.target;

    saleSetFormValues(prevState => {
      return{
        ...prevState,
        profileData: {
          ...prevState.profileData,
          regimes: [
            ...value
          ]
        }
      }
    })
  }

  const renderSelectMultiple = () => {
    if (profileData.regimes.length === 0) {
      return <em>Seleccionar regímen</em>
    }

    let render = '';
    profileData.regimes.forEach(regimeId => {
      const regime = regimes.filter(e => (e.id === regimeId))[0];

      render += `${regime.officialName}, `
    });

    return render;

  }

  return (
    <Fragment>
      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3">
        <h5>Datos del contribuyente</h5>
        <hr />
      </div>

      <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-3">
        <TextField
          label="Nombre o Razón social*" variant="outlined" size="small" autoComplete="off" fullWidth={true}
          name="bussinessName"
          value={ profileData.bussinessName }
          onChange={ handleInputChange }
          helperText={ msgErrors.bussinessName }
          error={ msgErrors.bussinessName !== undefined }
          disabled={!!crmSaleSelected}
        />
      </div>

      <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-3">
        <TextField
          label="Profesión" variant="outlined" size="small" autoComplete="off" fullWidth={true}
          select
          SelectProps={{
            native: true,
          }}
          name="professionId"
          value={profileData.professionId}
          onChange={ handleInputChange }
          helperText={msgErrors.professionId }
          error={msgErrors.professionId !== undefined }
          disabled={!!crmSaleSelected}
        >
          <option value='0'>Selecciona profesión</option>
            {
              professionTypes.map((profession, index) => {
                return(
                  <option
                    key={ index }
                    value={ profession.id }
                  > 
                    { profession.description }
                  </option>  
                )
              })
            }
        </TextField>
      </div>
      {/* <div className="col-12">
        { loadingValidationSat  ? (
            sesionSat ? (
              <p class="text-success">Contraseña SAT Validada</p>
            ):(
              <p class="h5 text-danger">No se pudo validar la contraseña SAT, revise RFC, Contraseña y Captcha. Reintente.</p>
            )
         ):null }
      </div> */}
    
      <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-3">
        <TextField
          label="RFC*" variant="outlined" size="small" autoComplete="off" fullWidth={true}
          name="rfc"
          inputProps={
            {style: {textTransform: 'uppercase'}}
          }
          value={ profileData.rfc }
          onChange={ handleInputChange }
          helperText={ msgErrors.rfc }
          error={ msgErrors.rfc !== undefined }
          disabled={!!crmSaleSelected}
          inputRef={inputRef}
        />
      </div>

      {/* <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-3">
        <TextField
          label={ isAccounting ? "Contraseña S.A.T.*" : "Contraseña S.A.T." } variant="outlined" size="small" autoComplete="off" fullWidth={true}
          name="passwordSAT"
          type="password"
          value={ profileData.passwordSAT }
          onChange={ handleInputChange }
          disabled={!!crmSaleSelected}
          helperText={ msgErrors.passwordSAT }
          error={ msgErrors.passwordSAT !== undefined }
          onBlur={(e) => {
           mostrarModal()
          }}
        />
      </div> */}

      <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-3 text-center">
        <FormControl variant="outlined" size="small" fullWidth={ true }
          // helperText={ msgErrors.regimes }
          error={ msgErrors.regimes !== undefined }
        >
          <InputLabel htmlFor="regimenes-oulined">
            Regímenes*
          </InputLabel>
          <Select
            multiple
            value={profileData.regimes}
            onChange={handleChangeSelectMultiple}
            renderValue={renderSelectMultiple}
            labelWidth={80}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 48 * 4.5 + 8,
                  width: 750,
                },
              }}
            }
            inputProps={{
              name:'regimes',
              id:"regimenes-oulined"
            }}
            disabled={!!crmSaleSelected}
          >
            <MenuItem disabled value="">
              <em>Selecciona los que apliquen</em>
            </MenuItem>
            {regimes.map((regime) => {
                if (isAccounting) { 
                  return(regime.accounting &&
                    <MenuItem key={regime.id} value={regime.id}>
                    <Checkbox checked={profileData.regimes.indexOf(regime.id) > -1}/>
                    <ListItemText primary={regime.officialName} />
                  </MenuItem>
                    )
                }else{
                  if(isInvoicing){
                    return(regime.invoicing &&
                    <MenuItem key={regime.id} value={regime.id}>
                    <Checkbox checked={profileData.regimes.indexOf(regime.id) > -1}/>
                    <ListItemText primary={regime.officialName} />
                  </MenuItem>
                    )
                  }else{
                    return(
                      <MenuItem key={regime.id} value={regime.id}>
                      <Checkbox checked={profileData.regimes.indexOf(regime.id) > -1}/>
                      <ListItemText primary={regime.officialName} />
                    </MenuItem>
                      )
                  }
                }
              }  
            )}
          </Select>
          <FormHelperText>{msgErrors.regimes}</FormHelperText>
        </FormControl>
      </div>

      <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-3">
        <TextField
          label="Fecha de alta S.A.T.*" variant="outlined" size="small" autoComplete="off" fullWidth={true}
          type="date"
          InputLabelProps={{shrink: true,}}
          name="startSatAt"
          value={profileData.startSatAt}
          helperText={ msgErrors.startSatAt }
          error={ msgErrors.startSatAt !== undefined }
          onChange={ handleInputChange }
        />
      </div>
    </Fragment>
  )
}
