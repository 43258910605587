import { TextField } from '@material-ui/core';
import React from 'react';

export const DataAccountPayment = ({ paymentId, accountBenefector, accountBeneficiary, setFormPaidComplement }) => {

    const handleAccountBenefactorChange = ( type, { target } ) => {
        const { name, value } = target;

        setFormPaidComplement(prevState => {
            return{
                ...prevState,
                dataPayments: prevState.dataPayments.map(
                    e => ( e.id === paymentId ) ? { ...e, [type]: { ...e[type], [name]: value  } } : e
                )
            }
        })
    }

    return (
        <div className="mt-2">
            <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <div className="card-header">
                        <h5>Datos de la Cuenta que realiza el pago</h5>
                    </div>
                    <TextField
                        className="mt-4"
                        label="RFC de entidad operadora"
                        variant="outlined"
                        size="small" 
                        name="rfc"
                        fullWidth={true}
                        value={ accountBenefector.rfc }
                        onChange={ handleAccountBenefactorChange.bind( this, 'accountBenefector' ) }
                    />

                    <TextField
                        className="mt-4"
                        label="Número cuenta"
                        variant="outlined"
                        size="small" 
                        name="accountNumber"
                        fullWidth={true}
                        value={ accountBenefector.accountNumber }
                        onChange={ handleAccountBenefactorChange.bind( this, 'accountBenefector' ) }
                    />
                </div>
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <div className="card-header">
                        <h5>Datos de la Cuenta del Beneficiario</h5>
                    </div>
                    <TextField
                        className="mt-4"
                        label="RFC de entidad operadora"
                        variant="outlined"
                        size="small" 
                        name="rfc"
                        fullWidth={true}
                        value={ accountBeneficiary.rfc }
                        onChange={ handleAccountBenefactorChange.bind( this, 'accountBeneficiary' ) }
                    />

                    <TextField
                        className="mt-4"
                        label="Número cuenta"
                        variant="outlined"
                        size="small" 
                        name="accountNumber"
                        fullWidth={true}
                        value={ accountBeneficiary.accountNumber }
                        onChange={ handleAccountBenefactorChange.bind( this, 'accountBeneficiary' ) }
                    />
                </div>
            </div>
        </div>
    )
}
