import swal from "sweetalert";
import { fetchWithApiKey, fetchWithApiKeyBilling, fetchWithToken, fetchWithTokenFormDataBilling } from "../../helpers/fetch";
import { saveModal } from "../../helpers/UseSwal";
import { types } from "../../types/types";

export const registerTaxDocumentBilling = (files,history,route,token) => {
    return async(dispatch) => {
      saveModal(true);
      
      const resp = await fetchWithTokenFormDataBilling('api/TaxFiles/upload', files, 'PUT', token);
      const body = await resp.json();
         
        if (body.success){
            swal({
            title: 'Archivo cargado',
            text: "Desea cargar otro archivo",
            icon: "success",
            buttons: ["Aceptar"],
            dangerMode: true,
            })
            .then((isCopy) => {
            if (!isCopy) {
                history.push(route)
            } 
        });
        dispatch(billingLoadFiles(body.data.stepTaxDocument))
        }else{
            let msgErrors = "";
            if(body.errors){
                Object.keys(body.errors).forEach(function(key) {
                msgErrors += `${body.errors[key]}\n`
                });
            }else{
                msgErrors = body.error;
            }
            swal('Error al guardar', msgErrors, 'error');
        }
        saveModal(false);
    }
}

const billingLoadFiles= (files) => ({
    type: types.taxDocumentBilling,
    payload: files
})