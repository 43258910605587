import { useState } from 'react';

export const useForm = (initialState = {}) => {

  const [formValues, setFormValues] = useState(initialState);

  const reset = (initialValues) => {
    setFormValues(initialValues);
  }

  const handleInputChange = ({ target }) => {
    if (target.name === 'email' || target.name === 'emailConfirm' || target.name === 'socialMediaId' || target.name === 'password' || target.name === 'newPassword' || target.name === 'passwordSat' || target.name === ' emailNotification') {
      setFormValues({
        ...formValues,
        [target.name]: target.value,
      });

    } else if (target.name === 'rfc') {
      const value = target.value.slice(0, 13).toUpperCase();
      setFormValues({
        ...formValues,
        [target.name]: value,
      });
    }
    else {
      setFormValues({
        ...formValues,
        [target.name]: typeof target.value === 'string' ? target.value.toUpperCase() : target.value
      });
    }
  };

  const handleCheckboxTrueFalseChange = ({ target }) => {
    setFormValues({
      ...formValues,
      [target.name]: !formValues[target.name]
    })
  }

  const handleObjectAddQuitChange = (event) => {
    const { name, value } = event.target;

    const index = formValues[name].indexOf(value);

    if (index !== -1) {
      formValues[name].splice(index, 1);
      setFormValues({
        ...formValues,
        [name]: formValues[name]
      });
    } else {
      setFormValues({
        ...formValues,
        [name]: [...formValues[name], value]
      })
    }
  }

  const handleObjectAddQuitAndFlagChange = (event, nameFlag) => {
    const { name, value } = event.target;
    const index = formValues[name].indexOf(parseInt(value));

    if (index !== -1) {
      formValues[name].splice(index, 1);
      setFormValues({
        ...formValues,
        [nameFlag]: !formValues[nameFlag],
        [name]: formValues[name]
      });
    } else {
      setFormValues({
        ...formValues,
        [nameFlag]: !formValues[nameFlag],
        [name]: [...formValues[name], parseInt(value)]
      })
    }
  }

  const handleArrayInputChange = (id, field, event) => {
    const { name, value } = event.target;

    setFormValues(prevState => {
      return {
        ...prevState,
        [field]: prevState[field].map(
          e => (e.id === id) ? { ...e, [name]: value } : e
        )
      }
    })
  }

  const handleFileInputChange = ({ target }) => {
    const { name, files } = target;

    setFormValues({
      ...formValues,
      [name]: files[0],
    })
  }

  const handleChangeMultiple = ({ target }) => {
    const { options } = target;
    const value = [];
    for (let i = 0, l = options.length; i < l; i += 1) {
      if (options[i].selected) {
        value.push(options[i].value);
      }
    }
    setFormValues({
      ...formValues,
      [target.name]: target.value
    })
  };
  const handleFileFlujoInputChange = (event) => {
    const files = event.target.filePDF;
    let arrayFles = []
    Array.from(files).map(file => {
      arrayFles.push(file)
    });

    setFormValues(prevState => {
      return {
        ...prevState,
        filePDF: arrayFles
      }
    })
  }

  const handlePasswordFieles = ({ target }) => {
    const { name, value } = target;
    setFormValues({ ...formValues, [name]: value })
  }

  const handlePasswordCSD = ({ target }) => {
    const { name, value } = target;
    setFormValues({ ...formValues, [name]: value })
  }

  const handlePasswordToken = ({ target }) => {
    const { name, value } = target;
    setFormValues({ ...formValues, [name]: value })
  }

  const handleEmail = ({ target }) => {
    const { name, value } = target;
    setFormValues({ ...formValues, [name]: value })
  }

  const handleInputChangedByToken = ({ target }) => {
    const { name, value } = target;
    setFormValues({ ...formValues, [name]: value })
  }

  const handleInputChangedByReferenceCode = ({ target }) => {
    const { name, value } = target;
    setFormValues({ ...formValues, [name]: value })
  }

  return {
    formValues,
    handleArrayInputChange,
    handleFileInputChange,
    handleFileFlujoInputChange,
    handleInputChange,
    handleCheckboxTrueFalseChange,
    handleObjectAddQuitChange,
    handleObjectAddQuitAndFlagChange,
    handleChangeMultiple,
    reset,
    handlePasswordFieles,
    handlePasswordCSD,
    handlePasswordToken,
    setFormValues,
    handleEmail,
    handleInputChangedByToken,
    handleInputChangedByReferenceCode,
  }
}
