import React from 'react';
import { Link } from 'react-router-dom';
import * as ROUTES from '../../../../constants/Routes';
import { GeneralDataPayroll } from './GeneralDataPayroll';
import { EmployeePayroll } from './EmployeePayroll';
import { DetailsEmployee } from './DetailsEmployee';
import { TotalPlayroll } from './TotalPlayroll';

export const PayrollDetailsScreen = () => {
    return (
        <div className="container">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <div className="card">
                    <div className="card-header">
                        <div className="row">
                            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                <h5>Nómina Enero</h5>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                <Link to={ROUTES.PAYROLL} className="btn btn-danger btn-sm float-right">
                                    <i className="fas fa-arrow-left"></i> Regresar
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-4">
                <GeneralDataPayroll />
            </div>
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-4">
                <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <EmployeePayroll />
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <DetailsEmployee />
                    </div>
                </div>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-4">
                <TotalPlayroll />
            </div>
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-4">
                <button href="" className="btn btn-sm btn-primary">
                    <i className="fas fa-file-import"></i> Importar de Excel
                </button>
                <button href="" className="btn btn-sm btn-primary ml-2">
                    <i className="fas fa-paper-plane"></i> Enviar CFDIs
                </button>
                <button href="" className="btn btn-sm btn-primary ml-2">
                    <i className="fas fa-cloud-download-alt"></i> Descargar CFDIs
                </button>
                <button href="" className="btn btn-sm btn-primary ml-2">
                    <i className="fas fa-plus"></i> Generar CFDIs
                </button>
                <button href="" className="btn btn-sm btn-primary ml-2">
                    <i className="far fa-save"></i> Guardar Cambios
                </button>
                <button href="" className="btn btn-sm btn-primary ml-2">
                    <i className="fas fa-times"></i> Cerrar
                </button>
            </div>
        </div>
    )
}
