import React from 'react';
import { RelatedDocumentsDetailsForm } from './RelatedDocumentsDetailsForm';
import { TableRelatedDocuments } from './TableRelatedDocuments';

export const RelatedDocumentsDetailsScreen = () => {
  return (
    <div className="col-md-12">
      <h5 className="card-header">Documentos relacionados</h5>
      <div className="card-body">
        
        <RelatedDocumentsDetailsForm />

        <TableRelatedDocuments />

      </div>
    </div>
  )
}
