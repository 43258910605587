import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getMetricApp } from '../../../../actions/metric/metric';
import { UserRegister } from './UserRegister';


export const IndexMetric = () => {

  const {usersData} = useSelector(state => state.metric);
  const dispatch = useDispatch();

  useEffect(() => {
     if (usersData.length === 0) {
      dispatch(getMetricApp())
    }
  }, [dispatch,usersData]);


  return (
    <div>
      <div className="container">
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <div className="card-header">
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <h5>MÉTRICAS MOBILE APP</h5>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <nav className="mt-4">
            <div className="nav nav-tabs" id="nav-tab" role="tablist">
                <a className="nav-item nav-link active" id="nav-userRegister-tab" data-toggle="tab" href="#nav-userRegister" role="tab" aria-controls="nav-accounting" aria-selected="true">
                Usuarios Registrados
                </a>
                
            </div>
          </nav>

          <div className="tab-content" id="nav-tabContent">
            <div className="tab-pane fade show active" id="nav-userRegister" role="tabpanel" aria-labelledby="nav-userRegister-tab">
              <UserRegister/>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}