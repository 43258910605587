import { types } from "../../types/types";

const initialState = {
  mailboxGet: [],
  mailboxRead: {}
  
}

export const mailboxReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.mailboxStartLoading:
      return{
        ...state,
        mailboxGet: [ ...action.payload ],
      }

    case types.mailboxStartUpdate:
      
      const auxmail = state.mailboxGet.filter(
        e => +action.payload.id !== e.id
      );
      return{
        ...state,
        mailboxGet: auxmail
      }
  
    default:
      return state;
  }
}