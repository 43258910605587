export const prepareTaxpayers = (originalData, regimes) => {
  const taxpayers = [];
  originalData.forEach(taxpayer => {
    const prepareData={
      ...taxpayer,
      regimes: [],
      satCodes: [],
      job:false,
      assistedId: taxpayer.profile.id,
      email: taxpayer.user.email,
      sellerFullName: `${taxpayer.sale.sellerName} ${taxpayer.sale.sellerLastName} ${taxpayer.sale.sellerMotherLastName}`,
      fullName: `${taxpayer.user.name} ${taxpayer.user.lastName} ${taxpayer.user.motherLastName}`,
      rfc: taxpayer.profile.rfc,
      accountantFullName: `${taxpayer.accountant.name} ${taxpayer.accountant.lastName} ${taxpayer.accountant.motherLastName}`,
      accountantId: taxpayer.accountant.id,
      activeDate: taxpayer.sale.activateAt
    }

    taxpayer.profileRegimes.forEach(regime => { 
      // if (regime.accounting) {
        // const regimeSelected = regimes.filter(e => (e.id === parseInt(regime.regimeId)))[0];
        // if(regimeSelected !== undefined){
          if(regime.regimen && regime.statics){
          prepareData['regimes'].push(regime.regimen);
          prepareData['satCodes'].push(regime.regimen.satCode)
          prepareData['estadisticas']=regime.statics
          prepareData['regimeSelectedId'] = regime.regimen.id
          prepareData['regimeSatCode'] = regime.regimen.satCode
          }
      // }
    });
    if(taxpayer.profileRegimes.length > 0){
      taxpayers.push(prepareData)
    }
    
  });
  return taxpayers;
}