import React, { Fragment } from 'react';
import { ServiceAlienationScreen } from './TechnologyPlataform/ServiceAlienationScreen';
import { ServiceGroundScreen } from './TechnologyPlataform/ServiceGroundScreen';
import { ServiceLodgingScreen } from './TechnologyPlataform/ServiceLodgingScreen';

export const TechnologyPlatformsIsrCalculation = ({ formValuesDetailsSummary, setFormValuesDetailsSummary, autorizedReport, status }) => {  
  return (
    <Fragment>
      <tr>
        <td colSpan="2">
          <ul className="nav nav-tabs" id="myTab" role="tablist">
            <li className="nav-item">
              <a className="nav-link active" id="service-ground-tab" data-toggle="tab" href="#ground" role="tab" aria-controls="ground" aria-selected="true">Servicio terrestre</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" id="service-lodging-tab" data-toggle="tab" href="#lodging" role="tab" aria-controls="lodging" aria-selected="false">Servicio hospedaje</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" id="service-alienation-tab" data-toggle="tab" href="#alienation" role="tab" aria-controls="alienation" aria-selected="false">Enajenación y prestación servicios</a>
            </li>
          </ul>

          <div className="tab-content" id="myTabContent">
            <div className="tab-pane fade show active" id="ground" role="tabpanel" aria-labelledby="service-ground-tab">
              <ServiceGroundScreen
                formValuesDetailsSummary={formValuesDetailsSummary}
                setFormValuesDetailsSummary={setFormValuesDetailsSummary}
                autorizedReport={autorizedReport}
                status={status}
              />
            </div>
            <div className="tab-pane fade" id="lodging" role="tabpanel" aria-labelledby="service-lodging-tab">
              <ServiceLodgingScreen
                formValuesDetailsSummary={formValuesDetailsSummary}
                setFormValuesDetailsSummary={setFormValuesDetailsSummary}
                autorizedReport={autorizedReport}
                status={status}
              />
            </div>
            <div className="tab-pane fade" id="alienation" role="tabpanel" aria-labelledby="service-alienation-tab">
              <ServiceAlienationScreen
                formValuesDetailsSummary={formValuesDetailsSummary}
                setFormValuesDetailsSummary={setFormValuesDetailsSummary}
                autorizedReport={autorizedReport}
                status={status}
              />
            </div>
          </div>
        </td>
      </tr>
    </Fragment>
  )
}
