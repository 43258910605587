import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core'
import { Pagination } from '@material-ui/lab'
import React, { useContext } from 'react'
import { ModalContext } from '../../../../modal/ModalContext';
import { DetailsProducts } from './DetailsProducts'

export const FormProducts = () => {

  const { handleModal } = useContext( ModalContext );


  return (
    <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2">
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                <h5>Usuarios Administradores</h5>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                <button
                                    type='button'
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="Editar Serie"
                                    className="btn btn-sm btn-outline-primary px-2"
                                    onClick={ () => handleModal(
                                        <DetailsProducts />
                                    )}
                                > Agregar Producto
                                    <FontAwesomeIcon className="fa-lg" icon={"shopping-cart"}/>
                                </button>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2">
                <div className="card">
                    <div className="card-body">
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell aling="center">ID</TableCell>
                                        <TableCell aling="center">Descripcion</TableCell>
                                        <TableCell aling="center">Precio</TableCell>
                                        <TableCell aling="center">Total de meses</TableCell>
                                        <TableCell aling="center">Total de sellos</TableCell>
                                        <TableCell aling="center">Asistido</TableCell>
                                        <TableCell aling="center">Activo</TableCell>
                                        <TableCell aling="center">Frecuencia</TableCell>
                                        <TableCell aling="center">Tipo de referencia</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                  
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Pagination
                            // data={usersAdmin}
                            // page={page}
                            // rowsPerPage={rowsPerPage}
                            // setPage={setPage}
                            // setRowsPerPage={setRowsPerPage}
                        />
                    </div>
                </div>
            </div>
        </div>
  )
}
