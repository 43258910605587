import { types } from "../../types/types";

const initialState = {
    clavProdServ: []
}

export const allClavProdServReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.clavProdServStartLoading:
            return {
                ...state,
                clavProdServ: [ ...action.payload]
            }

        default:
            return state;
    }
}
