import React, { useState } from 'react';
import { useDispatch } from "react-redux";
import { TextField } from '@material-ui/core';
import { useForm } from '../../../../hooks/useForm';
import { recoveryPassword } from '../../../../actions/auth';
import validator from 'validator';
import '../Style.scss';
import { useParams } from 'react-router';
import Swal from 'sweetalert2';

export const ChangePassword = () => {

  const dispatch = useDispatch();

  const { token } = useParams();

  const { formValues, handleInputChange } = useForm({
    password: '',
    newPassword: '',
    token: token
  });

  const { password, newPassword } = formValues;

  const [msgErrors, setMsgErrors] = useState({});
  const [passwordsMatch, setPasswordsMatch] = useState(true); // Variable de estado para almacenar si las contraseñas coinciden

  const handleSubmit = (e) => {
    e.preventDefault();

    if (isFormValid()) {
      dispatch(recoveryPassword(formValues))
    } else {
      Swal.fire({
        title: "Error.",
        text: `Las contraseñas no coinciden.`,
        confirmButtonText: 'OK',
      })
    }
  }

  const isFormValid = () => {
    const errors = {};

    if (password.trim().length === 0) {
      errors['msgPassword'] = 'Es requerido.';
    }
    if (password !== newPassword) {
      errors['msgNewPassword'] = 'Las contraseñas no coinciden, favor de verificar.';
      setPasswordsMatch(false);
    } else {
      setPasswordsMatch(true);
    }

    setMsgErrors(errors);

    return Object.keys(errors).length === 0;
  }

  return (
    <div className="container">
      <div className="col-12 d-flex justify-content-center mt-2 animate__animated animate__fadeIn">
        <div className="card col-md-8 shadow">
          <div className="card-body">
            <div className="text-center pb-1">
              <h3>Cambiar Contraseña</h3>
            </div>

            <form onSubmit={handleSubmit}>

              <div className="col-md-12 mb-3">
                <TextField
                  type="password"
                  label="Contraseña"
                  variant="outlined"
                  size="small"
                  name="password"
                  value={password}
                  onChange={handleInputChange}
                  autoComplete="off"
                  fullWidth={true}
                  helperText={msgErrors.msgPassword}
                  error={msgErrors.msgPassword !== undefined}
                />
              </div>

              <div className="col-md-12 mb-3">
                <TextField
                  type="password"
                  label="Confirmar contraseña"
                  id="newPassword"
                  variant="outlined"
                  size="small"
                  name="newPassword"
                  value={newPassword}
                  onChange={handleInputChange}
                  autoComplete="off"
                  fullWidth={true}
                  helperText={passwordsMatch ? msgErrors.msgNewPassword : "Las contraseñas no coinciden."} // Mostrar mensaje de error solo si las contraseñas no coinciden
                  error={!passwordsMatch}
                />
              </div>

              <div className="col-md-12 text-right mb-3">
                <button className="btn btn-primary">
                  <i className="far fa-paper-plane"></i> Cambiar contraseña
                </button>
              </div>

            </form>

          </div>
        </div>
      </div>
    </div>
  )
}
