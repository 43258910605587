import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { NavLink, useParams } from 'react-router-dom';
import { taxpayerSelectedLoading } from '../../../../../actions/taxpayers';
import { useForm } from '../../../../../hooks/useForm';
import * as ROUTES from '../../../../constants/Routes';
import { AditionalDataDetails } from '../aditionalDatas/AditionalDataDetails';
import { IssuerDetails } from '../issuer/IssuerDetails';
import { LineItemsDetails } from '../lineItems/LineItemsDetails';
import { LocalTaxesDetailsScreen } from '../localTaxes/LocalTaxesDetailsScreen';
import { RecipientDetails } from '../recipient/RecipientDetails';
import { RelatedDocumentsDetailsScreen } from '../relatedDocuments/RelatedDocumentsDetailsScreen';

export const InvoiceFormScreen = () => {
  
  const { taxpayerId } = useParams();
  
  const dispatch = useDispatch();

  const {formValues, handleInputChange, setFormValues} = useForm({
    regimeId: '',
    thirdPartyId: '',
    cfdiUseId: '',
  });

  const { regimeId, cfdiUseId, thirdPartyId } = formValues;

  useEffect(() => {
    dispatch(taxpayerSelectedLoading(taxpayerId));
  }, [dispatch, taxpayerId])
  
  return (
    <div className="container">
      <div className="row">
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <h4 className="card-header">Emisión de Factura</h4>
        </div>
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2">
          <NavLink to={ROUTES.INVOICES} className="btn btn-danger btn-sm float-right">
            <i className="fas fa-arrow-left"></i> Regresar
          </NavLink>
        </div>
      </div>
      <div className="row mt-2">
        <div className="col-md-6">
          <IssuerDetails
            formData={{
              regimeId: regimeId
            }}
            invoiceHandleInputChange={ handleInputChange }
            invoiceSetFormValues={ setFormValues }
          />
        </div>

        <div className="col-md-6">
          <RecipientDetails
            formData={{
              thirdPartyId,
              cfdiUseId,
            }}
            handleInputChange={ handleInputChange }
            setFormValues={ setFormValues }
          />
        </div>

        <LineItemsDetails />

        <LocalTaxesDetailsScreen />

        <RelatedDocumentsDetailsScreen />

        <AditionalDataDetails />
      </div>
    </div>
  )
}
