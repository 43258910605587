import { TextField, FormGroup,FormControlLabel,Checkbox } from '@material-ui/core'
import React, { useEffect } from 'react'
import { Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { cfdiUsesStartLoading } from '../../../../../actions/catalogs/cfdiUses'
import { paymentFrecuenciesCfdiStartLoading } from '../../../../../actions/catalogs/paymentFrecuencie'
import { paymentGatewaysStartLoading } from '../../../../../actions/catalogs/paymentGateways'
import { receptorAccountBanksStartLoadind } from '../../../../../actions/catalogs/receptorAccountBanks'
import { wayOfPaymentsCfdiStartLoading } from '../../../../../actions/catalogs/wayOfPaymentsCfdi'


export const ModuleInvoiceScreen = ({saleFormValues, saleSetFormValues, handleCheckboxTrueFalseChange}) => {

  const {cfdiUses} = useSelector(state => state.cfdiUse)
  const { receptorAccountBanksTypes } = useSelector(state => state.receptorAccountBankType)
  const {wayOfPayments} = useSelector(state => state.wayOfPayment)
  const { paymentFrecuencies } = useSelector(state => state.paymentFrecuencie)
  const { paymentGateways } = useSelector(state => state.paymentGateway)

  const dispatch = useDispatch();

  const { invoiceData, InvoiceTaxPayer, taxPayer, isSubscription, Frecuency, GatewayId } = saleFormValues;

  useEffect(() => {
    if(cfdiUses.length === 0){
      dispatch(cfdiUsesStartLoading());
    }
  }, [cfdiUses, dispatch])

  useEffect(() => {
    if (receptorAccountBanksTypes.length === 0) {
      dispatch(receptorAccountBanksStartLoadind());
    }
  }, [dispatch, receptorAccountBanksTypes])

  useEffect(() => {
    if(wayOfPayments.length === 0){
      dispatch(wayOfPaymentsCfdiStartLoading());
    }
  }, [dispatch, wayOfPayments])

  useEffect(() => {
    if(paymentFrecuencies.length === 0){
      dispatch(paymentFrecuenciesCfdiStartLoading());
    }
  }, [dispatch, paymentFrecuencies])

  useEffect(() => {
    if(paymentGateways.length === 0){
      dispatch(paymentGatewaysStartLoading());
    }
  }, [dispatch, paymentGateways])

  useEffect(() => {
    if(cfdiUses.length > 0){
      const cfdiUse = cfdiUses.filter(e => (e.key === 'G03'))[0];
      saleSetFormValues(prevState => {
        return{
          ...prevState,
          invoiceData: {
            ...prevState.invoiceData,
            cfdiUseId: cfdiUse.id
          }
        }
      })
    }
    
  }, [cfdiUses, saleSetFormValues])

  useEffect(() => {
    if(wayOfPayments.length > 0){
      const wayOfPayment = wayOfPayments[0];
      saleSetFormValues(prevState => {
        return{
          ...prevState,
          invoiceData: {
            ...prevState.invoiceData,
            wayOfPaymentId: wayOfPayment.id
          }
        }
      })
    }
  }, [wayOfPayments, saleSetFormValues])

  useEffect(() => {
    if (receptorAccountBanksTypes.length > 0) {
      const account = receptorAccountBanksTypes[0];
      saleSetFormValues(prevState => {
        return{
          ...prevState,
          invoiceData: {
            ...prevState.invoiceData,
            receptorAccountId: account.id
          }
        }
      })
    }
  }, [receptorAccountBanksTypes, saleSetFormValues])

  useEffect(() => {
    if(paymentFrecuencies.length > 0){
      const paymentFrecuencie = paymentFrecuencies[0];
      saleSetFormValues(prevState => {
        return{
          ...prevState,
            Frecuency: paymentFrecuencie.key
        }
      })
    }
  }, [paymentFrecuencies, saleSetFormValues])

  useEffect(() => {
    if(paymentGateways.length > 0){
      const paymentGateway = paymentGateways[0];
      saleSetFormValues(prevState => {
        return{
          ...prevState,
            GatewayId: paymentGateway.id
        }
      })
    }
  }, [paymentGateways, saleSetFormValues])

  const handleInputChange = ({target}) => {
    const {name, value} = target;

    saleSetFormValues(prevState=>{
      return{
        ...prevState,
        invoiceData: {
          ...prevState.invoiceData,
          [name]: value
        },
        taxPayer: {
          ...prevState.taxPayer,
          [name]: value
        },
        [name]: value
      }
    })
  }

  return (
    <Fragment>
      <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-3">
        <TextField
          label="Uso de CFDI" variant="outlined" size="small" autoComplete="off" fullWidth={true}
          select
          SelectProps={{
            native: true,
          }}
          name="cfdiUseId"
          value={invoiceData.cfdiUseId}
          onChange={ handleInputChange }
          // helperText={ msgErrors.passwordSAT }
          // error={ msgErrors.passwordSAT !== undefined }
        >
          <option value='0' disabled>Selecciona Uso de CFDI</option>
            {
              cfdiUses.map((cfdiUse, index) => {
                return(
                  <option
                    key={ index }
                    value={ cfdiUse.id }
                  > 
                    { cfdiUse.description }
                  </option>  
                )
              })
            }
        </TextField>
      </div>

      <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-3">
        <TextField
          label="Forma de pago" variant="outlined" size="small" autoComplete="off" fullWidth={true}
          name="wayOfPaymentId"
          select
          SelectProps={{
            native: true,
          }}
          value={invoiceData.wayOfPaymentId}
          onChange={ handleInputChange }
          // helperText={ msgErrors.passwordSAT }
          // error={ msgErrors.passwordSAT !== undefined }
        >
          <option value='0' disabled>Selecciona Forma de pago</option>
          {
            wayOfPayments.map((wayOfPayment, index) => {
              return(
                <option
                  key={ index }
                  value={ wayOfPayment.id }
                > 
                  { wayOfPayment.description }
                </option>  
              )
            })
          }
        </TextField>
      </div>
      <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-3">
        <TextField
          label="Información adicional forma de pago" variant="outlined" size="small" autoComplete="off" fullWidth={true}
          name="comments"
          value={invoiceData.comments}
          onChange={ handleInputChange }
          // helperText={ msgErrors.passwordSAT }
          // error={ msgErrors.passwordSAT !== undefined }
        />
      </div>

      <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-3">
        <TextField
          label="Cuenta receptora" variant="outlined" size="small" autoComplete="off" fullWidth={true}
          select
          SelectProps={{
            native: true,
          }}
          name="receptorAccountId"
          value={invoiceData.receptorAccountId}
          onChange={ handleInputChange }
          // helperText={ msgErrors.passwordSAT }
          // error={ msgErrors.passwordSAT !== undefined }
        >
          <option value='0' disabled>Selecciona cuenta</option>
          {
            receptorAccountBanksTypes.map((account, index) => {
              return(
                <option
                  key={ index }
                  value={ account.id }
                > 
                  { account.number } - { account.bank.key }
                </option>  
              )
            })
          }
        </TextField>
      </div>

      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 text-left">
        <div className="form-group">
          <label><strong>¿Se facturará a otro contribuyente?</strong></label>
            <div className="form-check mr-2">
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        name="InvoiceTaxPayer"
                        value={InvoiceTaxPayer}
                        onChange={handleCheckboxTrueFalseChange}
                        checked={InvoiceTaxPayer}
                      />
                    }
                    label="Si"
                  />
                </FormGroup>
              </div>
        </div>
      </div>
      <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-3">
        {
          InvoiceTaxPayer && (
            <TextField
              label="Nombre" variant="outlined" size="small" autoComplete="off" fullWidth={true}
              name="name"
              value={taxPayer.name}
              onChange={ handleInputChange }
            />
          )
        }
      </div>
      <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-3">
        {
          InvoiceTaxPayer && (
            <TextField
              label="Rfc" variant="outlined" size="small" autoComplete="off" fullWidth={true}
              name="rfc"
              value={taxPayer.rfc}
              onChange={ handleInputChange }
            />
          )
        }
      </div>

      {/* <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 text-left">
        <div className="form-group">
          <label><strong>¿Es una suscripción?</strong></label>
            <div className="form-check mr-2">
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        name="isSubscription"
                        value={isSubscription}
                        onChange={handleCheckboxTrueFalseChange}
                        checked={isSubscription}
                      />
                    }
                    label="Si"
                  />
                </FormGroup>
              </div>
        </div>
        </div>
        <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-3">
              {
                isSubscription && 
              <TextField
                label="Pasarela" variant="outlined" size="small" autoComplete="off" fullWidth={true}
                name="GatewayId"
                select
                SelectProps={{
                  native: true,
                }}
                value={GatewayId}
                onChange={ handleInputChange }
              >
                <option value='0' disabled>Selecciona pasarela de pago</option>
                {
                  paymentGateways.map((paymentGateway, index) => {
                    return(
                      <option
                        key={ index }
                        value={ paymentGateway.id }
                      > 
                        { paymentGateway.name }
                      </option>  
                    )
                  })
                }
              </TextField>
              }
              </div>
              <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-3">
              {
                isSubscription && 
                <TextField
                  label="Frecuencia de pago" variant="outlined" size="small" autoComplete="off" fullWidth={true}
                  name="Frecuency"
                  select
                  SelectProps={{
                    native: true,
                  }}
                  value={Frecuency}
                  onChange={ handleInputChange }
                >
                  <option value='0' disabled>Selecciona Frecuencia de pago</option>
                  {
                    paymentFrecuencies.map((paymentFrecuencie, index) => {
                      return(
                        <option
                          key={ index }
                          value={ paymentFrecuencie.key }
                        > 
                          { paymentFrecuencie.description }
                        </option>  
                      )
                    })
                  }
                </TextField>
              }
              </div>
               */}
        
    </Fragment>
  )
}
