import { fetchWithToken } from "../../helpers/fetch";
import { loadingModal } from "../../helpers/UseSwal";
import { types } from "../../types/types";

export const retentionStartLoading = (period) => {
  return async(dispatch) => {
    loadingModal(true)
    const resp = await fetchWithToken(`api/RetentionDownloaded/get/${period.profileId}/${period.paymentAt}`);
    const body = await resp.json(); 
    dispatch(retentionsFinish(true))
    if (body.success){
      dispatch(retentionsLoaded(body.data))
    }
    loadingModal(false)
  }
}

const retentionsLoaded = (retentions) => ({
  type: types.retentionsStartLoading,
  payload: retentions
})

const retentionsFinish= (flag) => ({
  type: types.retentionsFinishLoading,
  payload: flag
})