import { TextField } from '@material-ui/core';
import React, { Fragment, useEffect } from 'react'
import { useForm } from '../../../../../hooks/useForm'

export const ModuleCSDScreen = ({saleFormValues, saleSetFormValues}) => {

  const {taxCsdFiles} = saleFormValues;

  const { formValues, handleCheckboxTrueFalseChange } = useForm({
    csdPresent: false
  });

  const { csdPresent } = formValues;

  useEffect(() => {
    if(csdPresent){
      saleSetFormValues(prevState => {
        return{
          ...prevState,
          taxCsdFiles: {
            fileCer: '',
            fileKey: '',
            isFiel: false,
            password: ''
          }
        }
      })
    }else{
      saleSetFormValues(prevState => {
        return{
          ...prevState,
          taxCsdFiles: {}
        }
      })
    }
  }, [csdPresent, saleSetFormValues]);

  const handleFileInputChange = ({target}) => {
    const { name, files } = target;

    saleSetFormValues(prevState => {
      return{
        ...prevState,
        taxCsdFiles:{
          ...prevState.taxCsdFiles,
          [name]: files[0]
        }
      }
    })
  }

  const handleInputChange = ({target}) => {
    const { name, value } = target;

    saleSetFormValues(prevState => {
      return{
        ...prevState,
        taxCsdFiles:{
          ...prevState.taxCsdFiles,
          [name]: value
        }
      }
    })
  }

  return (
    <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-3">
      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3">
        <h5>Certificado de sello digital</h5>
        <hr />
      </div>

      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 text-center">
        <div className="form-group">
          <label><strong>¿El contribuyente cuenta con un CSD vigente?</strong></label>
          <div className="row">
            <div className="col-md-6 form-check">
              <input 
                className="form-check-input" 
                type="radio" 
                name="csdPresent" 
                id="csdNo"
                value={false}
                onChange={handleCheckboxTrueFalseChange}
                defaultChecked
              />
              <label className="form-check-label" htmlFor="csdNo">
                No
              </label>
            </div>

            <div className="col-md-6 form-check">
              <input
                className="form-check-input" 
                type="radio" 
                name="csdPresent" 
                id="csdYes" 
                value={true}
                onChange={handleCheckboxTrueFalseChange}
              />
              <label className="form-check-label" htmlFor="csdYes">
                Si
              </label>
            </div>
          </div>
        </div>
      </div>

      {
        csdPresent && (
          <Fragment>
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3">
              <TextField label="Certificado (.cer)" variant="outlined" size="small" autoComplete="off" fullWidth={true} 
                InputLabelProps={{shrink: true,}}
                type="file"
                name="fileCer"
                onChange={ handleFileInputChange }
                // helperText={ msgErrors.fileCer }
                // error={ msgErrors.fileCer !== undefined }
                inputProps={{ accept: '.cer' }}
              />
            </div>
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3">
              <TextField label="Llave privada (.key)" variant="outlined" size="small" autoComplete="off" fullWidth={true} 
                InputLabelProps={{shrink: true,}}
                type="file"
                name="fileKey"
                onChange={ handleFileInputChange }
                // helperText={ msgErrors.fileCer }
                // error={ msgErrors.fileCer !== undefined }
                inputProps={{ accept: '.key' }}
              />
            </div>
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 text-center">
              <TextField 
                label="Contraseña" variant="outlined" size="small" autoComplete="off" fullWidth={true}
                type="password"
                name="password"
                value={taxCsdFiles.password || ''}
                onChange={handleInputChange}
              />
            </div>
          </Fragment>
        )
      }
    </div>
  )
}
