import 'moment/locale/es-mx';
import moment from 'moment';

export const obtainYears = () => {
  const currentYear = new Date().getFullYear()
  const years = [];

  for (let i = 2017; i <= currentYear; i++) {
    years.push(i);
  }

  return years;
}

export const obtainMonths = ( satCode = null ) => {
  const months = [];

  if ( satCode !== null && satCode === '621') {
    for (let i = 0; i < 12; i += 2) {
      months.push( {
        month: i,
        period: 'bimonthly',
        label: `${(moment().month(i).format("MMMM")).toUpperCase()} - ${(moment().month(i + 1).format("MMMM")).toUpperCase()}`
        
      });
    } 
  }else{
    for (let i = 0; i < 12; i++) {
      months.push({
        month: i,
        period: 'month',
        label: (moment().month(i).format("MMMM")).toUpperCase()
      });
    }
  }

  return months;
}

export const obtainMonth = (month) => {
  return (moment().month(month).format("MMMM")).toUpperCase();
}