import React from 'react';

const OtherPayments = () => {
    return (
        <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2">
                <button className="btn btn-primary btn-sm float-right">
                    <i className="fas fa-plus"></i> Agregar Otro Pago
                </button>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2">
            <div className="table-responsive">
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th scope="col">Concepto</th>
                                <th scope="col">Importe</th>
                                <th scope="col">Operación</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>ISR</td>
                                <td>$ 0.00</td>
                                <td>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default OtherPayments;
