import React, { useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { identifierDeleted } from '../../../../actions/identifiers';
import swal from 'sweetalert';
import { ModalContext } from '../../../modal/ModalContext';
import { IdentifierForm } from './IdentifierForm';

export const IdentifiersDetailsScreen = () => {

  const dispatch = useDispatch();

  const { accountIdentifiers } = useSelector(state => state.identifier)

  const { handleModal } = useContext(ModalContext)

  const deleteIdentifier = (identifierId, e) => {
    swal({
      title: "¿Estas seguro?",
      text: "Una vez eliminado ¡no podrá recuperar su información!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
    .then((willDelete) => {
      if (willDelete) {
        
        dispatch( identifierDeleted({ id: identifierId }))

        swal("¡El identificador ha sido eliminado!", {
          icon: "success",
        });
      }
    })
  }

  return (
    <div className="row">
      <div className="col-md-12 mt-4">
        <button
          className="btn btn-primary mb-3"
          onClick={() => handleModal(
            <IdentifierForm/>
          )}
        >Agregar Identificador</button>

        <table className="table">
          <thead>
            <tr>
              <th scope="col">Identificador</th>
              <th scope="col">Tipo de cuenta</th>
              <th scope="col">Acciones</th>
            </tr>
          </thead>
          
          <tbody>
            {
              accountIdentifiers.length === 0 ? 
              ( <tr>
                  <td colSpan="4">
                    <div className="alert alert-primary text-center mb-0" role="alert">
                      No se ha agregado información
                    </div>
                  </td>
                </tr> ) :

              (accountIdentifiers.map((accountIdentifier) => {
                return(
                  <tr key={accountIdentifier.id}>
                    <td>{ accountIdentifier.account }</td>
                    <td>{ accountIdentifier.account}</td>
                    <td>
                      <button
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Editar Serie"
                        className="btn btn-sm btn-outline-primary px-2"
                        onClick={ () => handleModal(
                          <IdentifierForm 
                            identifier={ accountIdentifier } 
                          />
                        )}
                      >
                        <FontAwesomeIcon className="fa-lg" icon={"pencil-alt"}/>
                      </button>

                      <button
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Eliminar Serie"
                        className="btn btn-sm btn-outline-danger px-2 ml-2"
                        onClick={ deleteIdentifier.bind(this, accountIdentifier.id ) }
                      >
                        <FontAwesomeIcon className="fa-lg" icon={"trash"}/>
                      </button>
                    </td>
                  </tr>
                )
              }
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}
