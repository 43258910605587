import { fetchWithApiKey } from "../../helpers/fetch";
import { types } from "../../types/types"

export const wayOfPaymentsCfdiStartLoading = () => {
  return async(dispatch) => {
    const resp = await fetchWithApiKey('api/Catalogs/c_FormaPago');
    const body = await resp.json();
    if (body.success){
      dispatch(wayOfPaymentsCfdiLoaded(body.data)); 
    }
  }
}

const wayOfPaymentsCfdiLoaded = (wayOfPayments) => ({
  type: types.wayOfPaymentCfdisStartLoading,
  payload: wayOfPayments
})