import { FormControl, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Billing } from './Billing';
import BusinessDetails from './BusinessDetails';
import Concepts from './Concepts';
import Receiver from './Receiver';
import Transmitter from './Transmitter';
import { BillingActivation } from './BillingActivation';
import { UploadFiles } from './UploadFiles';
import { recordInvoices } from '../../../../actions/billing/catalogs';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const Index = () => {
  const { user } = useSelector(state => state.auth);
  const [buttonActive, setButtonActive] = useState('billing');
  const { invoicesActivation } = useSelector(state => state.invoiceBilling);

  const dispatch = useDispatch();

  const handleButtonClick = (buttonId) => {
    setButtonActive(buttonId);
  };

  useEffect(() => {
    dispatch(recordInvoices(user.id));
  }, [])

  const componentsMap = {
    'billing': <Billing />,
    'apartado2': <UploadFiles />,
    'apartado3': '',
    'apartado4': '',
  };

  const selectedComponent = componentsMap[buttonActive] || null;

  return (
    <div className='container'>
      <div className='row'>
        <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 shadow-lg p-3 mb-2 bg-white rounded'>
          <h4 className='card-header'> Facturación </h4>
        </div>
        <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 shadow-lg p-3 mb-5 bg-white rounded'>
          {
            invoicesActivation.success = false && (
              <button className={`btn ${buttonActive === 'billing' ? 'btn-success' : 'btn-secondary'} ml-2 mt-4`} onClick={() => handleButtonClick('billing')}>
                Facturación <i className='fas fa-file'></i>
              </button>
            )
          }
          {
            invoicesActivation.success = false && (
              <button className={`btn ${buttonActive === 'apartado2' ? 'btn-success' : 'btn-secondary'} ml-2 mt-4`} onClick={() => handleButtonClick('apartado2')}>
                Administración <i className='fas fa-user'></i>
              </button>
            )
          }
          <button className={`btn ${buttonActive === 'apartado3' ? 'btn-success' : 'btn-secondary'} ml-2 mt-4`} onClick={() => handleButtonClick('apartado3')}>
            Cancelaciones <i className='fas fa-ban'></i>
          </button>
          <button className={`btn ${buttonActive === 'apartado4' ? 'btn-success' : 'btn-secondary'} ml-2 mt-4`} onClick={() => handleButtonClick('apartado4')}>
            Apartado 4
          </button>
        </div>
      </div>
      <div className='row'>
      </div>
      {selectedComponent}
    </div>
  );
};


export default withRouter(Index);
