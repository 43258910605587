import React, { useEffect, useState, useContext } from 'react';
import { useForm } from '../../../../../../../../hooks/useForm';
import { useDispatch, useSelector } from 'react-redux';
import { validateNumberPresence, validateStringPresence } from '../../../../../../../../helpers/validateForm';
import { incomeRegisterStart, incomeUpdateStart } from '../../../../../../../../actions/accounting/incomes';
import { ModalContext } from '../../../../../../../modal/ModalContext';
import { searchThirdPartyByIdForeign, thirdPartyObtainClients } from '../../../../../../../../helpers/thirdParty';
import { IncomeFormDownloadedSat } from './IncomeFormDownloadedSat';
import { IncomeFormNotDowloadedSat } from './IncomeFormNotDowloadedSat';
import { taxpayerSelectedLoading } from '../../../../../../../../actions/taxpayers';
import moment from 'moment';
import { serviceTypeRetentionsStart } from '../../../../../../../../actions/accounting/serviceTypeRetentions';
import { wayOfPaymentsCfdiStartLoading } from '../../../../../../../../actions/catalogs/wayOfPaymentsCfdi';
import { thirdPartiesStartLoading } from '../../../../../../../../actions/thirdParties';

export const IncomeForm = ({ regimeSatCode, taxpayerId, incomeEdit=null }) => {
  
  const { handleModal } = useContext(ModalContext)

  const dispatch = useDispatch();

  const { accountIdentifiers } = useSelector(state => state.identifier);
  const { serviceTypeRetentions } = useSelector(state => state.ServiceTypeRetention)
  const { ivaTraslateds } = useSelector(state => state.ivaTraslated);
  const { taxpayerSelected } = useSelector(state => state.taxpayer)
  const { thirdParties } = useSelector(state => state.thirdParty);
  const { wayOfPayments } = useSelector(state => state.wayOfPayment);
  
  const { formValues, handleInputChange, handleCheckboxTrueFalseChange, setFormValues } = useForm({
    accountIdentifierId: '',
    concept: '',
    concepts: [],
    folio: '',
    serviceTypeRetentionId: 0,
    isrRetained: 0.00,
    isrRetainedCheck: true,
    ivaRetained: 0.00,
    ivaRetainedCheck: true,
    ivaTraslated: 0.00,
    ivaTraslatedId: '',
    paymentAt: '',
    satDownloaded: false,
    serie: '',
    stampAt: '',
    subtotal: 0.00,
    taxesRetaineds: [],
    taxesTraslateds: [],
    thirdPartyClients: [],
    thirdParty: null,
    thirdPartyId: 0,
    thirdPartyForign: false,
    total: 0.00,
    wayOfPaymentId: '',
  });

  const { concept, folio,
          isrRetainedCheck, ivaRetainedCheck, ivaTraslatedId,
          taxesRetaineds, taxesTraslateds, paymentAt, serie, serviceTypeRetentionId, subtotal, thirdPartyId, thirdParty,
          thirdPartyClients, thirdPartyForign, total, wayOfPaymentId
        } = formValues;

  const [msgErrors, setMsgErrors] = useState({});

  useEffect(() => {
    dispatch(taxpayerSelectedLoading(taxpayerId));
  }, [dispatch, taxpayerId])

  useEffect(() => {
    dispatch(thirdPartiesStartLoading(taxpayerId));
  }, [dispatch, taxpayerId])

  useEffect(() => {
    if(serviceTypeRetentions.length === 0){
      dispatch(serviceTypeRetentionsStart());
    }
  }, [dispatch, serviceTypeRetentions])

  useEffect(() => {
    if(wayOfPayments.length === 0){
      dispatch(wayOfPaymentsCfdiStartLoading());
    }
  }, [dispatch, wayOfPayments])

  useEffect(() => {
    if(incomeEdit){
      let precargeDatas = {}
      if (incomeEdit.satDownloaded) {
        precargeDatas={
          //accountIdentifierId: incomeEdit.accountIdentifierId,
          concepts: [ ...incomeEdit.concepts ],
          paymentAt: moment(incomeEdit.paymentAt).format('YYYY-MM-DD'),
          satDownloaded: incomeEdit.satDownloaded,
          serviceTypeRetentionId: incomeEdit.retentionTypeId,
          stampAt: moment(incomeEdit.stampAt).format('YYYY-MM-DD'),
          thirdParty: {
            name: incomeEdit.receivedFullName,
            rfc: incomeEdit.receivedRfc,
          },
          subtotal: parseFloat(incomeEdit.subTotal),
          total: parseFloat(incomeEdit.total),
          wayOfPaymentId: incomeEdit.wayOfPayment.id,
        }
      }else{
        if(thirdParties.length > 0){
          const foundThirdParty = searchThirdPartyByIdForeign(thirdParties, incomeEdit.thirdPartie.id)
          const { flag, thirdParty } = foundThirdParty;
          precargeDatas={
            // accountIdentifierId: incomeEdit.accountIdentifierId,
            concept: incomeEdit.concepts[0].descripcion,
            folio: incomeEdit.folio,
            satDownloaded: incomeEdit.satDownloaded,
            serie: incomeEdit.serie,
            serviceTypeRetentionId: incomeEdit.retentionTypeId,
            subtotal: incomeEdit.subTotal,
            paymentAt: moment(incomeEdit.paymentAt).format('YYYY-MM-DD'),
            thirdPartyId: incomeEdit.thirdPartie.id,
            thirdPartyForign: flag,
            thirdParty: { ...thirdParty },
            total: incomeEdit.total,
            wayOfPaymentId: incomeEdit.wayOfPayment.id
          }
        }
      }
      setFormValues(prevState => {
        return{
          ...prevState,
          ...precargeDatas
        }
      })
    }
    if(thirdParties.length > 0){
      const thirdPartiesClients = thirdPartyObtainClients(thirdParties);

      setFormValues(prevState => {
        return{
          ...prevState,
          thirdPartyClients: [...thirdPartiesClients]
        }
      })
    }
  }, [incomeEdit, thirdParties, setFormValues])

  useEffect(() => {
    if( serviceTypeRetentions.length > 0 && !incomeEdit){
      setFormValues(prevState => {
        return{
          ...prevState,
          serviceTypeRetentionId: serviceTypeRetentions.filter(e => (e.key === '01'))[0].id
        }
      })
    }
  }, [ incomeEdit, serviceTypeRetentions, regimeSatCode, setFormValues])

  useEffect(() => {
    if( wayOfPayments.length > 0 && !incomeEdit){
      setFormValues(prevState => {
        return{
          ...prevState,
          wayOfPaymentId: wayOfPayments[0].id
        }
      })
    }
  }, [incomeEdit, setFormValues, wayOfPayments])

  useEffect(() => {
    if( accountIdentifiers.length > 0 && !incomeEdit ){
      setFormValues(prevState => {
        return{
          ...prevState,
          accountIdentifierId: accountIdentifiers[0].id
        }
      })
    }
  }, [accountIdentifiers, incomeEdit, setFormValues])

  useEffect(() => {
    if ( ivaTraslateds.length > 0 && !thirdPartyForign ) {
      if(incomeEdit && !incomeEdit.satDownloaded){

        let isrRetainedCheck = false;
        let ivaRetainedCheck = false;
        
        if (incomeEdit.concepts[0].impuestos.retenciones) {
          incomeEdit.concepts[0].impuestos.retenciones.forEach( tax =>{
            if(tax.impuesto === '001'){
              isrRetainedCheck = true;
            }else{
              ivaRetainedCheck = true;
            }
          })
        }

        let ivaTraslatedId = ivaTraslateds[0].id;

        if(incomeEdit.concepts[0].impuestos.traslados.length > 0){
          ivaTraslatedId = ivaTraslateds.filter(e => ( e.rate === parseFloat(incomeEdit.concepts[0].impuestos.traslados[0].tasaOCuota)))[0].id;          
        }else{
          ivaTraslatedId = ivaTraslateds.filter(e => ( e.name === 'Exento'))[0].id;
        }

        setFormValues(prevState => {
          return{
            ...prevState,
            ivaTraslatedId: ivaTraslatedId,
            isrRetainedCheck: isrRetainedCheck,
            ivaRetainedCheck: ivaRetainedCheck,
          }
        })
      }
      if(!incomeEdit){
        setFormValues(prevState => {
          return{
            ...prevState,
            ivaTraslatedId: ivaTraslateds[0].id
          }
        })
      }
    }
  }, [incomeEdit, ivaTraslateds, setFormValues, thirdPartyForign])

  useEffect(() => {
      const ivaTraslated = ivaTraslateds.filter(e => (e.id === parseInt(ivaTraslatedId)))[0];
      if (ivaTraslated) {

        //ivaTraslated.name === 'Exento' && (ivaTraslated.rate = 0)
        let subtotalIvaTraslated = 0;
        let subtotalISRRetained = 0;
        let subtotalIVARetained = 0;

        const taxesRetaineds = [];
        const taxesTraslateds = [];

        if (!thirdPartyForign) {
          subtotalIvaTraslated = parseFloat(parseFloat(subtotal) * parseFloat(ivaTraslated.rate)).toFixed(2);

          ivaTraslated.name !== 'Exento' && (
            taxesTraslateds.push({
              ...ivaTraslated,
              amount: subtotalIvaTraslated,
              base: 0,
              importe: subtotalIvaTraslated,
              importeSpecified: true,
              impuesto: "002",
              nameTax: ivaTraslated.name,
              tasaOCuota: ivaTraslated.rate,
              tasaOCuotaSpecified: true,
              tipoFactor: "Tasa",
            })
          )

          if (isrRetainedCheck) {
            if(regimeSatCode === '626'){
              subtotalISRRetained = parseFloat(parseFloat(subtotal) * .0125).toFixed(2)
            }else{
              subtotalISRRetained = parseFloat(parseFloat(subtotal) * .1).toFixed(2)
            }
            
            taxesRetaineds.push({
              base: 0,
              importe: subtotalISRRetained,
              impuesto: "001",
              tasaOCuota: 0.00,
              tasaOCuotaSpecified: true,
              tipoFactor: "Tasa",
            })
          }
          
          if (ivaRetainedCheck) {
            subtotalIVARetained = parseFloat((subtotalIvaTraslated / 3) * 2).toFixed(2);
            taxesRetaineds.push({
              amount: subtotalIVARetained,
              base: 0,
              importe: subtotalIVARetained,
              impuesto: "002",
              tasaOCuota: 0.00,
              tasaOCuotaSpecified: true,
              tipoFactor: "Tasa",
            })
          }
        }

        let totalWithDiscounts = parseFloat((parseFloat(subtotal) + parseFloat(subtotalIvaTraslated)) - (parseFloat(subtotalIVARetained) + parseFloat(subtotalISRRetained))).toFixed(2)

        setFormValues(prevState =>{
          return{
            ...prevState,
            ivaTraslated: subtotalIvaTraslated || 0.00,
            ivaRetained: subtotalIVARetained || 0.00,
            isrRetained: subtotalISRRetained || 0.00,
            total: totalWithDiscounts || 0.00,
            taxesRetaineds: [ ...taxesRetaineds ],
            taxesTraslateds: [ ...taxesTraslateds ]
          }
        })
      }
  }, [isrRetainedCheck, ivaTraslatedId, ivaTraslateds, ivaRetainedCheck, setFormValues, subtotal, thirdPartyForign])

  const handleThirdPartyChange = ( { target } ) => {
    const { value } = target;

    const foundThirdParty = searchThirdPartyByIdForeign(thirdParties, value);

    const { flag, thirdParty } = foundThirdParty;

    setFormValues(prevState => {
      return{
        ...prevState,
        thirdPartyId: parseInt(value),
        thirdPartyForign: flag,
        thirdParty: { ...thirdParty }
      }
    });
  }

  const handleSubmit = ( e ) => {
    e.preventDefault();
    if(incomeEdit){
      if(incomeEdit.satDownloaded){
        const update = {
          authorizations: incomeEdit.authorization.map(
            e => (e.regimeSatCode === regimeSatCode) ?
            {
              ...e,
              regimeSatCode: regimeSatCode,
              retentionTypeId: serviceTypeRetentionId
            }
            :
            e
          ),
          IncomeId: incomeEdit.id,
          paymentAt: moment(paymentAt).format('MM/DD/YYYY'),
          profileId: taxpayerId,
          stampAt: incomeEdit.stampAt,
          subtotal: (parseFloat(subtotal) > 0 ? subtotal : total),
          total: total,
        }
        dispatch(incomeUpdateStart(update, handleModal));
      }else{
        if(isFormValid()){
          const update = {
            // accountIdentifierId: accountIdentifierId,
            authorizations:[
              {
                authorized: incomeEdit['authorization'][0]['authorized'],
                regimeSatCode: regimeSatCode,
                retentionTypeId: serviceTypeRetentionId
              }
            ],
            concepts: [
              {
                impuestos:{
                  traslados: taxesTraslateds,
                  retenciones: taxesRetaineds,
                },
                claveProdServ: null,
                descripcion: concept,
                subtotal: (parseFloat(subtotal) > 0 ? subtotal : total),
                total: total,
              }
            ],
            folio: folio,
            IncomeId: incomeEdit.id,
            IssuedFullName: taxpayerSelected.businessName,
            issuedRfc: taxpayerSelected.rfc,
            paymentAt: paymentAt,
            profileId: taxpayerId,
            receivedFullName: thirdParty.businessName,
            receivedRfc: thirdParty.rfc,
            thirdPartieId: thirdParty.id,
            satDownloaded: false,
            serie: serie,
            stampAt: paymentAt,
            subtotal: (parseFloat(subtotal) > 0 ? subtotal : total),
            total: total,
            wayOfPaymentId: wayOfPaymentId,
          }
          dispatch(incomeUpdateStart(update, handleModal.bind()));
        }
      }
    }else{
      if(isFormValid()){
        const register = {
          authorizations: [
            {
              authorized: true,
              regimeSatCode: regimeSatCode,
              retentionTypeId: serviceTypeRetentionId
            }
          ],
          concepts: [
            {
              impuestos: {
                traslados: taxesTraslateds,
                retenciones: taxesRetaineds
              },
              claveProdServ: null,
              descripcion: concept,
              subtotal: (parseFloat(subtotal) > 0 ? subtotal : total),
              total: total,
            }
          ],
          folio: folio,
          IssuedFullName: taxpayerSelected.businessName,
          issuedRfc: taxpayerSelected.rfc,
          receivedFullName: thirdParty.businessName,
          receivedRfc: thirdParty.rfc,
          satDownloaded: false,
          serie: serie,
          stampAt: moment(paymentAt).format('MM/DD/YYYY'),
          subtotal: (parseFloat(subtotal) > 0 ? subtotal : total),
          paymentAt: moment(paymentAt).format('MM/DD/YYYY'),
          profileId: taxpayerId,
          thirdPartieId: thirdParty.id,
          total: total,
          wayOfPaymentId: wayOfPaymentId,
        }
        dispatch(incomeRegisterStart(register, handleModal.bind()));
      }
    }
  }

  const isFormValid = () => {
    setMsgErrors({});

    const datasStringToValidate = { concept, paymentAt }
    const datasNumbersToValidate = { total, thirdPartyId }
    const validateString = validateStringPresence( datasStringToValidate, setMsgErrors );
    const validateNumbers = validateNumberPresence( datasNumbersToValidate, setMsgErrors );

    if( validateString && validateNumbers ){
      return true;
    }
    return false;
  }

  return (
    <div>
      <div className="text-center">
        {
          incomeEdit ? 
          ( <h4>Editar Ingreso</h4> )
          :
          ( <h4>Agregar Nuevo Ingreso</h4> )
        }
        
      </div>
      <hr/>
      {
        incomeEdit ? 
        (
           incomeEdit.satDownloaded ?
           (
            <IncomeFormDownloadedSat
              accountIdentifiers= { accountIdentifiers }
              formValuesIncome={ formValues }
              handleInputChangeIncome={ handleInputChange }
              handleSubmit={ handleSubmit }
              serviceTypeRetentions={ serviceTypeRetentions }
              regimeSatCode={ regimeSatCode }
              wayOfPayments={ wayOfPayments }
            />
           )
           :
           (
            <IncomeFormNotDowloadedSat
              accountIdentifiers={ accountIdentifiers }
              formValues={ formValues }
              handleInputChange={ handleInputChange }
              handleThirdPartyChange={ handleThirdPartyChange }
              handleSubmit={ handleSubmit }
              handleCheckboxTrueFalseChange={ handleCheckboxTrueFalseChange }
              msgErrors={ msgErrors }
              serviceTypeRetentions={ serviceTypeRetentions }
              ivaTraslateds={ ivaTraslateds }
              regimeSatCode={ regimeSatCode }
              thirdParties={ thirdPartyClients }
              wayOfPayments={ wayOfPayments }
            />
           )
        )
        :
        (
          <IncomeFormNotDowloadedSat
            accountIdentifiers={ accountIdentifiers }
            formValues={ formValues }
            handleInputChange={ handleInputChange }
            handleThirdPartyChange={ handleThirdPartyChange }
            handleSubmit={ handleSubmit }
            handleCheckboxTrueFalseChange={ handleCheckboxTrueFalseChange }
            serviceTypeRetentions={ serviceTypeRetentions }
            ivaTraslateds={ ivaTraslateds }
            msgErrors={ msgErrors }
            regimeSatCode={ regimeSatCode }
            thirdParties={ thirdPartyClients }
            wayOfPayments={ wayOfPayments }
          />
        )
      }

    </div>
  )
}
