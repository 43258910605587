import { useState } from 'react';

export const UseModal = () => {

  const [modal, setModal] = useState(false);

  const [modalContent, setModalContent] = useState("El contenido del modal");
  const [bigModal, setBigModal] = useState(false);

  const handleModal = (content = false, bigModal = false) => {
    setModal(!modal);
    if(content){
      setModalContent(content);
      setBigModal(bigModal);
    }
  };

  return { modal, handleModal, modalContent, bigModal };
};
