import React from 'react';
import { UseModal } from '../../hooks/UseModal';
import ModalComponent from "./ModalComponent";

let ModalContext = null;

const { Provider } = (ModalContext = React.createContext());

const ModalProvider = ({ children }) => {

  const { modal, handleModal, modalContent, bigModal } = UseModal();

  return (
    <Provider value={{ modal, handleModal, modalContent, bigModal }} >
      <ModalComponent
      />
      {children}
    </Provider>
  );

}

export { ModalContext, ModalProvider };

