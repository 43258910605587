import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, withRouter } from 'react-router-dom';
import { useForm } from '../../../hooks/useForm';
import { startLogin } from '../../../actions/auth';
import { removeError } from '../../../actions/ui';
import * as ROUTES from '../../constants/Routes';
import validator from 'validator';
import { TextField } from '@material-ui/core';
import './Style.scss';

const LoginScreen = () => {

  const dispatch = useDispatch();

  const { loading, msgErrorsApi } = useSelector(state => state.ui)

  const { formValues, handleInputChange } = useForm({
    email: '',
    password: ''
  });

  const { email, password } = formValues;

  const [msgErrors, setMsgErrors] = useState({});

  const handleLogin = (e) => {
    e.preventDefault();

    dispatch(removeError());

    if (isFormValid()) {
      dispatch(startLogin(formValues))
    }
  }

  const isFormValid = () => {
    const errors = {};

    if (!validator.isEmail(email)) {
      errors['email'] = 'Correo electrónico no es valido.';
    }
    if (password.trim().length === 0) {
      errors['password'] = 'Es requerido.';
    }

    setMsgErrors(errors);

    if (Object.keys(errors).length !== 0) {
      return false;
    }

    return true;
  }

  return (
    <div className="container">
      <div className="col-12 d-flex justify-content-center mt-2">
        <div className="card col-md-8 shadow animate__animated animate__fadeInLeft">
          <div className="card-body">
            <div className="text-center pb-1">
              <h1>Iniciar sesión</h1>
            </div>
            <form className="mt-4" onSubmit={handleLogin}>
              <div className="col-md-12 mb-3">
                <TextField
                  label="Correo Electrónico"
                  variant="outlined"
                  size="small"
                  name="email"
                  value={email}
                  onChange={handleInputChange}
                  autoComplete="off"
                  fullWidth={true}
                  helperText={msgErrors.email}
                  error={msgErrors.email !== undefined}
                />
              </div>

              <div className="col-md-12 mb-3">
                <TextField
                  type="password"
                  label="Contraseña"
                  id="password"
                  variant="outlined"
                  size="small"
                  name="password"
                  value={password}
                  onChange={handleInputChange}
                  autoComplete="off"
                  fullWidth={true}
                  helperText={msgErrors.password}
                  error={msgErrors.password !== undefined}
                />
              </div>

              {msgErrorsApi.password !== undefined &&
                (
                  <div className="d-flex justify-content-center">
                    <div className=" alert alert-danger text-center">
                      {msgErrorsApi.password} o {msgErrorsApi.email}
                    </div>
                  </div>
                )
              }

              <div className="row col-md-12">
                <div className="col-12 text-right">
                  <button type="submit" className="btn btn-success" disabled={loading}>
                    <i className="fas fa-sign-in-alt"></i> Ingresar
                  </button>
                </div>
              </div>
              <hr />
            </form>
            <div className="row col-md-12">
              <div className="col-md-6 text-center">
                <strong>¿Olvidaste tu contraseña?</strong>
                <p>
                  No te preocupes,
                  <NavLink to={ROUTES.RECUPERATE_PASSWORD}>
                    haz click
                  </NavLink>.
                </p>
              </div>
              <div className="col-md-6 text-center">
                <strong>¿Eres un nuevo usuario?</strong>
                <p>
                  <NavLink to={ROUTES.REGISTER2}>
                    Haz click aqui para registrarte.
                  </NavLink>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default withRouter(LoginScreen);
