import { fetchWithToken } from "../../helpers/fetch";
import { loadingModal } from "../../helpers/UseSwal";
import { types } from "../../types/types";

export const getMetricApp = () => {
  return async(dispatch) => {
    loadingModal(true)
    const resp = await fetchWithToken(`api/Mobile/getMetrics`);
    const body = await resp.json();

    if (body.success){
      dispatch(metricApp(body.data))
    }
    loadingModal(false)
  }
}

const metricApp = (data) => ({
    type: types.metricApp,
    payload: data
  })