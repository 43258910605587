import React, { Fragment, useContext, useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { InputSearching } from '../../../../../helpers/tables/InputSearching';
import { Pagination } from '../../../../../helpers/tables/Pagination';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { ADMIN_ACCOUNTING_SALE_FORM, ADMIN_ACCOUNTING_SALE_UPDATE } from '../../../../constants/Routes';
import { obtainVouchersStartLoading } from '../../../../../actions/crm/renovations';
import { ModalContext } from '../../../../modal/ModalContext';
import { RenovationVouchers } from '../RenovationVouchers';
import { activeRenovationCrm } from '../../../../../actions/crm/sales';
import { UseSwal } from '../../../../../hooks/UseSwal';
import { prepareAccountingSales } from '../../../../../helpers/sales/prepareDataToSearch';

const AdminAccountingIndexScreen = ({setFormValueSalesIndex}) => {

  const { handleModal } = useContext( ModalContext );

  const {crmSalesRenovations} = useSelector(state => state.crmSale);
  const { isBoss, descriptionRole } = useSelector(state => state.auth.user);

  const dispatch = useDispatch();

  const [salesAccounting, setSalesAccounting] = useState([]);
  const [salesSearch, setSalesSearch] = useState([]);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const { activationModal } = UseSwal();

  useEffect(() => {
    if (crmSalesRenovations.length > 0) {
      setSalesAccounting(prepareAccountingSales(crmSalesRenovations.reverse(), setFormValueSalesIndex));
      setSalesSearch(prepareAccountingSales(crmSalesRenovations.reverse(), setFormValueSalesIndex))
    }
  }, [crmSalesRenovations, setFormValueSalesIndex, setSalesAccounting]);

  const handleObtainVouchers = (saleId) =>{
    dispatch(obtainVouchersStartLoading(saleId));

    handleModal(<RenovationVouchers saleId={saleId} />)
  }

  return (
    <div className="row">
      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2">
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <h5>Planes Vigentes</h5>
              </div>

              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 text-right">
                { (isBoss || descriptionRole === 'Seller') &&
                  (<Link to={ADMIN_ACCOUNTING_SALE_FORM} className="btn btn-primary">
                    <i className="fas fa-plus"></i> Agregar renovación
                  </Link>)
                }
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2">
        <div className="card">
          <div className="card-body">
            <InputSearching
              columnsToSearch={ [ 'email', 'fullName' ] }
              setDataChange={ setSalesSearch }
              originals={ salesAccounting }
            />

            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="center"><strong>Id</strong></TableCell>
                    <TableCell align="center"><strong>Cliente</strong></TableCell>
                    <TableCell align="center"><strong>Producto</strong></TableCell>
                    <TableCell align="center"><strong>Fecha de pago</strong></TableCell>
                    <TableCell align="center"><strong>Fecha inicio</strong></TableCell>
                    <TableCell align="center"><strong>Fecha final</strong></TableCell>
                    <TableCell align="center"><strong>Comprobantes</strong></TableCell>
                    <TableCell align="center"><strong>Activo</strong></TableCell>
                    <TableCell align="center"><strong>Acciones</strong></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    salesSearch.length === 0 ?
                    (
                      <TableRow>
                        <TableCell colSpan="9">
                          <div className="alert alert-info text-center" role="alert">
                            No se encontraron renovaciones
                          </div>
                        </TableCell>
                      </TableRow>
                    )
                    :
                    (
                      salesSearch.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((sale, index) => {
                        return(
                          <TableRow key={index}>
                            <TableCell>{sale.id}</TableCell>
                            <TableCell align="center">
                             {sale.userFullName} <br />
                              <strong>{sale.userEmail}</strong><br />
                              {sale.user.phoneMobile}
                            </TableCell>
                            <TableCell align="center">
                              <Fragment>
                                {
                                  sale.products.map((product, index) => {
                                    return(
                                      <Fragment key={index}>
                                        {product.product.description}
                                        <strong> {product.product.unitPrice}</strong><br/>
                                      </Fragment>
                                    )
                                  })
                                }
                                Total: <strong>{sale.total}</strong>
                              </Fragment>
                            </TableCell>
                            <TableCell align="center">
                              { sale.paymentAt ? 
                                (
                                  moment(sale.paymentAt).format('YYYY-MM-DD')
                                )
                                :
                                (
                                  'Sin fecha'
                                )
                              }
                            </TableCell>

                            <TableCell align="center">
                              { sale.startServiceAt ? 
                                (
                                  moment(sale.startServiceAt).format('YYYY-MM-DD')
                                )
                                :
                                (
                                  'Sin fecha'
                                )
                              }
                            </TableCell>

                            <TableCell align="center">
                              { sale.endServiceAt ? 
                                (
                                  moment(sale.endServiceAt).format('YYYY-MM-DD')
                                )
                                :
                                (
                                  'Sin fecha'
                                )
                              }
                            </TableCell>

                            <TableCell align="center">
                              { sale.paymentAt ? 
                                (
                                  <button
                                    onClick={handleObtainVouchers.bind(this, sale.id)}
                                    className="btn btn-primary btn-sm"
                                    data-toggle="tooltip" data-placement="top"
                                    title="Ver comprobantes"
                                  ><i className="fas fa-file-invoice-dollar"></i></button>
                                )
                                :
                                (
                                  'Sin comprobante'
                                )
                              }
                            </TableCell>

                            <TableCell align="center">
                              {
                                sale.isAuthorized ?
                                (
                                  <span className="badge badge-success">Si</span>
                                ):
                                (
                                  <span className="badge badge-danger">No</span>
                                )
                              }
                            </TableCell>
                            <TableCell align="center">
                              <Link to={ADMIN_ACCOUNTING_SALE_UPDATE(sale.id)} data-toggle="tooltip" data-placement="top"
                                title="Editar renovación" className="btn btn-primary btn-sm mr-2">
                                <i className="fas fa-edit"></i>
                              </Link>

                              {
                                !sale.isAuthorized &&
                                <button data-toggle="tooltip" data-placement="top"
                                  title="Activar venta" className="btn btn-sm btn-success mr-2 mt-1"
                                  onClick={ () => activationModal( activeRenovationCrm(sale.id) ) }
                                ><i className="fas fa-unlock"></i></button>
                              }
                            </TableCell>
                          </TableRow>
                        )
                      })
                    )
                  }
                </TableBody>
              </Table>
            </TableContainer>
            <Pagination
              data={salesSearch}
              page={page}
              rowsPerPage={rowsPerPage}
              setPage={setPage}
              setRowsPerPage={setRowsPerPage}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default AdminAccountingIndexScreen;
