import { Table, TableBody, TableContainer, TableHead, TableRow, TableCell } from '@material-ui/core'
import React from 'react'

const pdfFiles = [
    {
      link: '../../helpers/manuals/ManualPreventa.pdf',
      descripcion: 'Modula de Ventas - Preventa',
    },
    {
      link: 'ruta/al/archivo2.pdf',
      descripcion: 'Descripción del archivo 2',
    },
    // Agrega más objetos para más archivos PDF
  ];
  
export const ModuleHelpers = () => {

  return (
    <div style={{width: "500px", height: "300px"}}>
        <h4 className="text-center">Manuales de Contabox</h4>
        <hr />
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2">
            <div className="card">
                <div className="card-body">
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow >
                                    <TableCell aling="center"> {`Manuales ( PDF )`}</TableCell>
                                    <TableCell aling="center">Descripción</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {pdfFiles.map((file, index) => (
                                    <TableRow key={index}>
                                        <TableCell align="center">
                                            <a href={file.link} target="_blank" rel="noopener noreferrer">
                                                Ver PDF
                                            </a>
                                        </TableCell>
                                        <TableCell align="center">
                                            {file.descripcion}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </div>
        </div>
    </div>
  )
}
