import React, { useEffect, useRef, useState } from 'react';
import { NavLink } from 'react-router-dom';
import * as ROUTES from '../../../constants/Routes';
import { DETAILS_EMPLOYEE } from '../../../constants/Routes';
import { Link } from 'react-router-dom';
import { FormControl, InputLabel, MenuItem, Select, TextField, } from '@material-ui/core';
import { useForm } from '../../../../hooks/useForm';
import { productsBilling } from '../../../../actions/billing/catalogs';
import { useDispatch, useSelector } from 'react-redux';


const currencies = [
    {
      value: 'Tasa',
      label: 'Tasa',
    },
    {
      value: 'Cuota',
      label: 'Cuota',
    },
    {
      value: 'Exento',
      label: 'Exento',
    }
];
const percentagee = [
    {
        value: '16',
        label: '16 %',
      },
      {
        value: '08',
        label: '08 %',
      },
      {
        value: '0',
        label: '0 %',
      }
];
const taxes = [
    {
      value: 'true',
      label: 'Si',
    },
    {
      value: 'false',
      label: 'No',
    }
];


const Concepts = ({ setformCompletion, formCompletion, setFormDetails, formValid }) => {

    const { productsBillingr } = useSelector(state => state.productBilling)
    const { invoicesActivation } = useSelector(state => state.invoiceBilling)

    const [msgErrors, setMsgErrors] = useState({});
    const [filas, setFilas] = useState([]);

    let ivaRef = useRef(0);
    let subTotalRef = useRef(0);
    let discountRef = useRef('');
    let totalRef = useRef(0);
    let rateFree = useRef(0);;

    const dispatch = useDispatch();

    const { formValues, setFormValues, handleInputChange } = useForm({
        products: '',
        KeyProductService: '',
        NoIdentification: '',
        Quantity: '',
        KeyUnit: '',
        Description: '',
        UnitPrice: 0,
        ObjetoImp: '2',
        FactorType: '',
        RateFee: rateFree,
        Discount: 0,
        RetainedTasaCuotaIVA: 0.0,
        RetainedTasaCuotaISR: 0.0,
        IEPSRatefee: 0,
        tax: '',
        AccountThird: null
    })

    const { products, KeyProductService, NoIdentification, KeyUnit, Quantity, UnitPrice, Discount, AccountThird,
            FactorType, RateFee, tax, Description, IEPSRatefee, RetainedTasaCuotaIVA, RetainedTasaCuotaISR, ObjetoImp } = formValues

    const isFormValid = () => {
        const errors = {}

        if( typeof KeyProductService === 'string' && KeyProductService.trim().length === 0 ) {
            errors['msgKeyProductService'] = "Es requerido"
        }
        if( typeof KeyUnit === 'string' && KeyUnit.trim().length === 0 ) {
            errors['msgKeyUnit'] = "Es requerido"
        }
        if( typeof Quantity === 'string' && Quantity?.trim().length === 0 ) {
            errors['msgQuantity'] = "Es requerido"
        }
        if( typeof UnitPrice === 'string' && UnitPrice?.trim().length === 0 ) {
            errors['msgUnitPrice'] = "Es requerido"
        }
        if( typeof FactorType === 'string' && FactorType.trim().length === 0 ) {
            errors['msgFactorType'] = "Es requerido"
        }
        if( typeof RateFee === 'string' && RateFee.trim().length === 0 ) {
            errors['msgRateFee'] = "Es requerido"
        }
        if( typeof tax === 'string' && tax.trim().length === 0 ) {
            errors['msgTax'] = "Es requerido"
        }
        if( typeof Description === 'string' && Description.trim().length === 0 ) {
            errors['msgDescription'] = "Es requerido"
        }
        // if( filas.length === 0 ) {
        //     errors['msgFilas'] = "Es requerido"
        // }

        if( formValid ) {
            setMsgErrors(errors)
        }

        if(Object.keys(errors).length !== 0 ){
            return false;
          }

          return true;
    }

    useEffect(() => {
        const appKey = invoicesActivation.data.appKey;
        dispatch(productsBilling(appKey))

    }, [])

    useEffect(() => {
        if( products ) {
            setFormValues(prevState => {
                return{
                    KeyProductService: products.claveProductoServicio,
                    KeyUnit: products.claveUnidad,
                    UnitPrice: String(products.valorUnitario),
                    Description: products.descripcion,
                    NoIdentification: products.NoIdentification,
                    Quantity: products.Quantity,
                    Discount: products.Discount,
                    FactorType: products.FactorType,
                    tax: products.tax,
                    IEPSRatefee: products.IEPSRatefee,
                    ObjetoImp: products.objetoImpuesto,
                    AccountThird: null
                }
            })
        }
    }, [formValues])

    useEffect(() => {
        isFormValid()
    }, [formValid])

    useEffect(() => {
        const isValid = isFormValid();

        setformCompletion({
            ...formCompletion,
            concepts: isValid
        });
    }, [formValues]);

    const handleFormular = () => {
        let iva = 0;
        let discountForm = 0;
        let ivaTotal = 0;
        let subtotal = 0;
        let total = 0;
        let deteined = 0;

        filas.forEach((row) => {
            const filaSubtotal = parseFloat(row.UnitPrice) * parseFloat(row.Quantity);
            subtotal += filaSubtotal;
            subTotalRef = subtotal

            iva = (parseFloat(row.RateFee * 100) / 100) * filaSubtotal;
            ivaTotal += iva;
            ivaRef = ivaTotal

            discountForm += parseFloat(row.Discount)
            discountRef = discountForm

            total = (subtotal + ivaTotal) - discountForm
            totalRef = total

            deteined += parseFloat(row.UnitPrice)
        });

        return (
            ( filas.length > 0 ) ? (
                <table className="table">
                    <tbody>
                        <tr>
                            <td className="table-active align-middle">Subtotal</td>
                            <td className="table-primary align-middle"> ${  parseFloat(subtotal).toFixed(2) }  </td>
                        </tr>
                        <tr>
                            <td className="table-active">IVA</td>
                            <td className="table-primary">${  parseFloat(ivaTotal).toFixed(2) }</td>
                        </tr>
                        <tr>
                            <td className="table-active">Retenciones</td>
                            <td className="table-primary">${  parseFloat(deteined).toFixed(2) }</td>
                        </tr>
                        {
                            discountForm > 0 ? (
                                <tr>
                                    <td className="table-active">Descuento*</td>
                                    <td className="table-primary">${  parseFloat(discountForm).toFixed(2) }</td>
                                </tr>
                            )
                            :
                            (
                                ''
                                )
                        }
                        <tr>
                            <td className="table-active">Total</td>
                            <td className="table-primary">${  parseFloat(total).toFixed(2) }</td>
                        </tr>
                    </tbody>
                </table>
            )
            :
            ''
        )
    }

    useEffect(() => {
        setFormDetails(prevState => {
            return{
                ...prevState,
                Imports: {
                    SubTotal: subTotalRef,
                    Discount: discountRef,
                    IvaTraslated: ivaRef,
                    Total: totalRef,
                },
                Concepts: filas
            }
        })
    }, [formValues,  filas]);

    const handleAddRow = ( e ) => {
        e.preventDefault()
        const nuevaFila = { KeyProductService, KeyUnit, Quantity, UnitPrice, Discount,
                            RateFee, Description, IEPSRatefee, RetainedTasaCuotaIVA,
                            RetainedTasaCuotaISR, FactorType, NoIdentification, ObjetoImp, AccountThird };

        if(isFormValid()) {
            setFilas([...filas, nuevaFila]);
            handleFormular()
        }
    };

    const handleDeleteRow = (index) => {
        const nuevasFilas = [...filas];
        nuevasFilas.splice(index, 1);
        handleFormular()
        setFilas(nuevasFilas);
    };

    return (
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2">
            <div className='row'>
                <div className="col-xs-12 col-sm-4 mb-3">
                    <TextField
                        id="products"
                        select
                        name='products'
                        label="Productos"
                        variant="outlined"
                        onChange={ handleInputChange }
                        fullWidth={true}
                        SelectProps={{
                            MenuProps: {
                              PaperProps: {
                                style: {
                                  maxHeight: 200,
                                },
                              },
                            },
                        }}
                    >
                        {productsBillingr.map((product) => (
                            <MenuItem key={product.id} value={product}>
                                { product.descripcion } { <br/>}
                                { product.RetainedTasaCuotaISR}
                                Clave: { product.claveProductoServicio } | VALOR: ${  parseFloat(product.valorUnitario).toFixed(2) }
                            </MenuItem>
                        ))}
                    </TextField>
                </div>
                <div className="col-xs-12 col-sm-8 col-md-4 mb-3">
                    <TextField
                        id="outlined-basic"
                        label="Clave Producto Servicio *"
                        name='KeyProductService'
                        value={ KeyProductService }
                        variant="outlined"
                        onChange={ handleInputChange }
                        helperText={ msgErrors.msgKeyProductService }
                        error={ msgErrors.msgProKeyProductService !== undefined }
                        fullWidth={true}
                    />
                </div>
                <div className="col-xs-12 col-sm-8 col-md-4 mb-3">
                    <TextField
                        id="outlined-basic"
                        label="No.Identificación"
                        name='NoIdentification'
                        variant="outlined"
                        onChange={ handleInputChange }
                        fullWidth={true}
                    />
                </div>
                <div className="col-xs-12 col-sm-8 col-md-3 mb-3">
                    <TextField
                        id="outlined-basic"
                        label="Clave Unidad *"
                        name='KeyUnit'
                        value={ KeyUnit }
                        variant="outlined"
                        onChange={ handleInputChange }
                        helperText={ msgErrors.msgKeyUnit}
                        error={ msgErrors.msgKeyUnit !== undefined }
                        fullWidth={true}
                    />
                </div>
                <div className="col-xs-12 col-sm-8 col-md-3 mb-3">
                    <TextField
                        id="outlined-basic"
                        type="number"
                        label="Cantidad *"
                        name='Quantity'
                        variant="outlined"
                        onChange={ handleInputChange }
                        helperText={ msgErrors.msgQuantity }
                        error={ msgErrors.msgQuantity !== undefined }
                        fullWidth={true}
                    />
                </div>
                <div className="col-xs-12 col-sm-8 col-md-3 mb-3">
                    <TextField
                        id="outlined-basic"
                        type="number"
                        label="Valor *"
                        name='UnitPrice'
                        value={ UnitPrice }
                        variant="outlined"
                        onChange={ handleInputChange }
                        helperText={ msgErrors.msgUnitPrice }
                        error={ msgErrors.msgUnitPrice !== undefined }
                        fullWidth={true}
                    />
                </div>
                <div className="col-xs-12 col-sm-8 col-md-3 mb-3">
                    <TextField
                        id="outlined-basic"
                        type="number"
                        label="Descuento"
                        name='Discount'
                        //value={ Discount }
                        variant="outlined"
                        onChange={ handleInputChange }
                        helperText={ msgErrors.msgDiscount }
                        error={ msgErrors.msgDiscount !== undefined }
                        fullWidth={true}
                    />
                </div>
                <div className="col-xs-12 col-sm-8 col-md-4 mb-3">
                    <TextField
                        id="outlined-select-currency"
                        select
                        label="Tipo Factor *"
                        name='FactorType'
                        // defaultValue="EUR"
                        variant="outlined"
                        onChange={ handleInputChange }
                        helperText={ msgErrors.msgFactorType }
                        error={ msgErrors.msgFactorType !== undefined }
                        fullWidth={true}
                    >
                        {currencies.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                </div>
                <div className="col-xs-12 col-sm-8 col-md-4 mb-3">
                    <TextField
                        id="outlined-select-currency"
                        select
                        label="Porcentaje *"
                        name='RateFee'
                        // defaultValue="EUR"
                        variant="outlined"
                        type='number'
                        onChange={ handleInputChange }
                        helperText={ msgErrors.msgRateFee }
                        error={ msgErrors.msgRateFee!== undefined }
                        fullWidth={true}
                    >
                        {percentagee.map((option) => (
                            <MenuItem key={option.value} value={option.value / 100}>
                            {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                </div>
                <div className="col-xs-12 col-sm-8 col-md-4 mb-3">
                    <TextField
                        id="outlined-select-currency"
                        select
                        label="Impuesto *"
                        name='tax'
                        variant="outlined"
                        onChange={ handleInputChange }
                        helperText={ msgErrors.msgTax }
                        error={ msgErrors.msgTax !== undefined }
                        fullWidth={true}
                    >
                        {taxes.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                            {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                </div>
                {
                    tax === 'true' ? (
                        <>
                            <div className="col-xs-12 col-sm-8 col-md-4 mb-3">
                                <TextField
                                    id="IEPSRatefee"
                                    type='number'
                                    label="IEPS %"
                                    name='IEPSRatefee'
                                    defaultValue="0"
                                    variant="outlined"
                                    onChange={ handleInputChange }
                                    helperText={ msgErrors.msgIEPSRatefee }
                                    error={ msgErrors.msgIEPSRatefee !== undefined }
                                    fullWidth={true}
                                />
                            </div>
                            <div className="col-xs-12 col-sm-8 col-md-4 mb-3">
                                <TextField
                                    id="RetainedTasaCuotaIVA"
                                    label="IVA Retenido%"
                                    name='RetainedTasaCuotaIVA'
                                    defaultValue="0"
                                    variant="outlined"
                                    onChange={ handleInputChange }
                                    helperText={ msgErrors.msgIva }
                                    error={ msgErrors.msgIva !== undefined }
                                    fullWidth={true}
                                />
                            </div>
                            <div className="col-xs-12 col-sm-8 col-md-4 mb-3">
                                <TextField
                                    id="RetainedTasaCuotaISR"
                                    label="ISR Retenedio"
                                    name='RetainedTasaCuotaISR'
                                    defaultValue="0"
                                    variant="outlined"
                                    onChange={ handleInputChange }
                                    helperText={ msgErrors.msgIsr }
                                    error={ msgErrors.msgIsr !== undefined }
                                    fullWidth={true}
                                />
                            </div>
                        </>
                    )
                    :
                    (
                        ''
                    )
                }
                <div className="col-xs-12 col-sm-8 col-md-8 mb-3">
                    <TextField
                        id="outlined-basic"
                        label="Descripción *"
                        name='Description'
                        value={ Description }
                        variant="outlined"
                        onChange={ handleInputChange }
                        helperText={ msgErrors.msgDescription }
                        error={ msgErrors.msgDescription !== undefined }
                        fullWidth={true}
                        multiline
                        rows={2}
                    />
                </div>
                <div className="col-xs-12 col-sm-8 col-md-2 mb-3">
                    <button className='btn btn-secondary'> Limpiar <i className='fas fa-trash'></i> </button>
                </div>
                <div className="col-xs-12 col-sm-8 col-md-2 mb-3">
                    <button
                        className='btn btn-info'
                        onClick={ handleAddRow }
                    >
                        Agregar <i className='fas fa-plus' ></i>
                    </button>
                </div>
            </div>
            <div className='row'>
                <table className="table table-bordered mt-4">
                    {
                        ( filas.length > 0 ) ? (
                            <thead>
                                <tr>
                                    <th className="col-1">Cantidad</th>
                                    <th className="col-1">Unidad</th>
                                    <th className="col-3">Descripción</th>
                                    <th className="col-2">Precio Unitario</th>
                                    <th className="col-3">Impuesto</th>
                                    <th className="col-1">Descuentos</th>
                                    <th className="col-1"></th>
                                </tr>
                            </thead>
                        )
                        :
                        (
                            ''
                        )
                    }
                    { filas.map((fila, index) => (
                        <tbody>
                            <tr key={index}>
                                <td> { fila.Quantity } </td>
                                <td> { fila.KeyUnit} </td>
                                <td> { fila.Description } </td>
                                <td> ${ parseFloat(fila.UnitPrice).toFixed(2) } </td>
                                <td>
                                    {fila.RetainedTasaCuotaISR > 0 && (
                                        <>
                                            {fila.FactorType} (001) | { fila.RetainedTasaCuotaISR * 100}% ISR Retenido
                                            <br />
                                        </>
                                    )}
                                    {
                                        fila.FactorType} (002) |  {fila.RateFee * 100

                                    }%
                                    IVA
                                    <br />
                                    {fila.RetainedTasaCuotaIVA > 0 && (
                                        <>
                                            {fila.FactorType} (002) | { fila.RetainedTasaCuotaIVA * 100}% IVA Retenido
                                            <br />
                                        </>
                                    )}
                                </td>
                                <td> ${ parseFloat(fila.Discount).toFixed(2) } </td>
                                <td>
                                    <button
                                        className='btn btn-danger'
                                        onClick={() => handleDeleteRow( index ) }
                                    >
                                        X
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    ))}
                </table>
            </div>
            <div className='row'>
                <div className='col-6'>

                </div>
                <div className='col-6'>
                   {
                    handleFormular()
                   }
                </div>
            </div>
        </div>
    )
}

export default Concepts;
