import React, { useEffect } from 'react';
import ShowAccounting from './administrators/ShowAccounting';
import ShowVendor from './vendors/ShowVendor';
import ShowAccounts from './accounts/ShowAccounts';
import ShowCustomers from './customers/ShowCustomers';
import { useDispatch, useSelector } from 'react-redux';
import { userStartLoading } from '../../../../actions/users';
import { userType } from '../../../../actions/users';

export const UserAdminsIndexScreen = () => {

  const { users } = useSelector(state => state.userType)

  const { isBoss } = useSelector(state => state.auth.user);

  const dispatch = useDispatch();

  useEffect(() => {
    if (users.length === 0) {
      dispatch(userStartLoading())
    }
  }, [users, dispatch])

  return (
    <div>
      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3">
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-4">
          <div className="card-header">
            <h5>Usuarios</h5>
          </div>
        </div>

        <nav className="mt-4">
          <div className="nav nav-tabs" id="nav-tab" role="tablist">

            {isBoss &&
              <>
                <a className="nav-item nav-link " id="nav-admin-tab" data-toggle="tab" href="#nav-admin" role="tab" aria-controls="nav-admin" aria-selected="true">
                  Administradores
                </a>
                <a className="nav-item nav-link" id="nav-vendor-tab" data-toggle="tab" href="#nav-vendor" role="tab" aria-controls="nav-vendor" aria-selected="true">
                  Vendedores
                </a>
                <a className="nav-item nav-link" id="nav-account-tab" data-toggle="tab" href="#nav-account" role="tab" aria-controls="nav-account" aria-selected="true">
                  Contadores
                </a>
              </>
            }
            <a className="nav-item nav-link active" id="nav-customers-tab" data-toggle="tab" href="#nav-customers" role="tab" aria-controls="nav-customers" aria-selected="true">
              Clientes
            </a>
          </div>
        </nav>

        <div className="tab-content" id="nav-tabContent">
          {isBoss &&
            <>
              <div className="tab-pane fade" id="nav-admin" role="tabpanel" aria-labelledby="nav-admin-tab">
                <ShowAccounting />
              </div>
              <div className="tab-pane fade" id="nav-vendor" role="tabpanel" aria-labelledby="nav-accounting-tab">
                <ShowVendor />
              </div>
              <div className="tab-pane fade" id="nav-account" role="tabpanel" aria-labelledby="nav-accounting-tab">
                <ShowAccounts />
              </div>
            </>
          }
          <div className="tab-pane fade show active" id="nav-customers" role="tabpanel" aria-labelledby="nav-accounting-tab">
            <ShowCustomers />
          </div>
        </div>

      </div>
    </div>
  )
}
