import { TextField } from '@material-ui/core';
import React, { useEffect, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { serieFolioAddNew, serieFolioUpdated } from '../../../../actions/serieFolios';
import { useForm } from '../../../../hooks/useForm';
import { ModalContext } from '../../../modal/ModalContext';

export const SerieFolioForm = ({ serieFolio = null }) => {

  const { invoiceTypes } = useSelector(state => state.invoiceType);

  const { handleModal } = useContext( ModalContext );

  const dispatch = useDispatch();

  const { formValues, setFormValues, handleInputChange } = useForm({
    serie: '',
    folio: '',
    invoiceTypeId: invoiceTypes[0].id,
  });

  const { serie, folio, invoiceTypeId } = formValues;

  useEffect(() => {
    if (serieFolio) {
      setFormValues(
        serieFolio
      )
    }
  }, [serieFolio, setFormValues])

  const handleSubmit = (e) => {
    e.preventDefault();
    const invoiceType = invoiceTypes.filter(e => ( e.id === parseInt(invoiceTypeId) ))
    if(serieFolio){
      dispatch(serieFolioUpdated({
        ...formValues,
        invoiceType: invoiceType[0]
      }))
    }else{
      dispatch(serieFolioAddNew({
        ...formValues,
        id: new Date().getTime(),
        invoiceType: invoiceType[0]
      }));
    }
    handleModal();
  }

  return (
    <div>
      <h4>Crear nueva serie y folio</h4>
      <hr/>

      <form onSubmit={ handleSubmit }>
        <div className="form-group">
          <TextField
            label="Serie" 
            variant="outlined"
            size="small"
            name="serie"
            value={ serie }
            onChange={ handleInputChange }
            autoComplete="off"
            fullWidth={true}
          />
        </div>

        <div className="form-group">
          <TextField
            label="Folio Inicial" 
            variant="outlined"
            size="small"
            name="folio"
            value={ folio }
            onChange={ handleInputChange }
            autoComplete="off"
            fullWidth={true}
          />
        </div>

        <div className="form-group">
          <TextField
            select
            variant="outlined"
            label="Tipo"
            size="small"
            fullWidth={true}
            name="invoiceTypeId"
            value={invoiceTypeId}
            onChange={handleInputChange}
            SelectProps={{
              native: true,
            }}
          >
            {
              invoiceTypes.map( (invoiceType) => {
                return (
                  <option key={invoiceType.id}
                    value={ invoiceType.id }
                  >{invoiceType.description}</option>
                );
              })
            }
          </TextField>
        </div>

        <div className="col-md-12 text-right pr-0">
          <button type="submit" className="btn btn-primary ml-2">Guardar</button>
        </div>
      </form>
    </div>
  )
}
