import React from 'react'
import { NavLink } from 'react-router-dom';
import * as ROUTES from '../../../constants/Routes';

import { PayrollData } from './PayrollData/PayrollData';

export const PayrollFormScreen = () => {
    return (
        <div className="container">
            <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <h4 className="card-header">Recibo de Nómina</h4>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2">
                    <NavLink to={ROUTES.PAYROLL} className="btn btn-danger btn-sm float-right">
                        <i className="fas fa-arrow-left"></i> Regresar
                    </NavLink>
                </div>
            </div>
            <div className="row mt-2">
                <PayrollData />
            </div>
        </div>
    )
}
