import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';

import { LOGIN } from '../components/constants/Routes';

export const PrivateAdminRoute = ({
    isAuthenticated,
    component: Component,
    role,
    ...rest
}) => {

  const componentReturn = (props) => {
    if(isAuthenticated){
      if(role === 'Administration' || role === 'Seller' || role === 'Accounter' || role === 'Service Customer'){
        return <Component { ...props } /> 
      }
    }else{
      return <Redirect to={LOGIN} />
    }
  }

  return (
    <Route { ...rest }
      component={(props) => (componentReturn(props))}
    />
  )
}

PrivateAdminRoute.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  component: PropTypes.func.isRequired,
  role: PropTypes.string,
}
