import { types } from "../../types/types";

export const cfdiRelatedsTypeAddToList = ( cfdiRelated ) => ({
  type: types.cfdiRelatedsTypeAddToList,
  payload: cfdiRelated
})

export const cfdiRelatedsChangeTypeSelected = ( cfdiRelationTypeId ) => ({
  type: types.cfdiRelatedsChangeTypeSelected,
  payload: cfdiRelationTypeId
});

export const cfdiRelatedsTypeDeleteToList = ( id ) => ({
  type: types.cfdiRelatedsTypeDeleteToList,
  payload: id
})
