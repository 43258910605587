import React from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const TableDocumentRelations = ({ paymentId, dataPayment, setFormPaidComplement }) => {
  
  const { documentRelateds } = dataPayment;

  const deleteDocumentRelated = (documentRelatedId, event) => {
    setFormPaidComplement(prevState => {
      return{
        ...prevState,
        dataPayments: prevState.dataPayments.map(
          e => ( e.id === paymentId ) ?
          { 
            ...e,
            documentRelateds: e.documentRelateds.filter(
              docRel => ( docRel.id !== documentRelatedId )
            )
          }
          :
          e
        )
      }
    })
  }
  
  return (
    <div className="row">
      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <Table className="" aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">UUID</TableCell>
              <TableCell align="center">Folio</TableCell>
              <TableCell align="center">Parcialidad</TableCell>
              <TableCell align="center">Anterior</TableCell>
              <TableCell align="center">Pagado</TableCell>
              <TableCell align="center">Insoluto</TableCell>
              <TableCell align="center"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            { documentRelateds.map((documentRelated, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell align="center"> { documentRelated.uuid } </TableCell>
                    <TableCell align="center"> { documentRelated.serie } - { documentRelated.Folio } </TableCell>
                    <TableCell align="center"> { documentRelated.partilityNumber } </TableCell>
                    <TableCell align="center"> { documentRelated.previusAmount } </TableCell>
                    <TableCell align="center"> { documentRelated.paidAmount } </TableCell>
                    <TableCell align="center"> { documentRelated.unpaidAmount } </TableCell>
                    <TableCell align="center">
                      <button
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Eliminar contribuyente"
                        className="btn btn-sm btn-outline-danger px-2 ml-2"
                        onClick={ deleteDocumentRelated.bind(this, documentRelated.id) }
                      >
                        <FontAwesomeIcon className="fa-lg" icon={"trash"}/>
                      </button>
                    </TableCell>
                  </TableRow>
                )
              })
            }
          </TableBody>
        </Table>
      </div>
    </div>
  )
}
