import { types } from "../../types/types";

const initialState = {

  localTaxeTypes: [
    {
      id: 1,
      description: 'Trasladado'
    },
    {
      id: 2,
      description: 'Retenido'
    }
  ],

  localTaxeList: []

}

export const localTaxeReducer = ( state = initialState, action ) => {
  switch (action.type) {
    case types.localTaxesAddToList:
      return{
        ...state,
        localTaxeList: [
          ...state.localTaxeList,
          action.payload
        ]
      }
    
    case types.localTaxesUpdateToList:
      return{
        ...state,
        localTaxeList: state.localTaxeList.map(
          e => ( e.id === action.payload.id ) ? action.payload : e
        )
      }

    case types.localTaxesDeleteToList:
      return {
        ...state,
        localTaxeList: state.localTaxeList.filter(
          e => ( e.id !== action.payload.id )
        )
      }

    default:
      return state;
  }
}
