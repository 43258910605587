import { TextField } from '@material-ui/core';
import React, { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { startServiceUpdate } from '../../../../actions/crm/sales';
import { useForm } from '../../../../hooks/useForm';
import { ModalContext } from '../../../modal/ModalContext';
import moment from 'moment';

export const ChangeDateServiceModal = ({sale}) => {

  const dispatch = useDispatch();

  const { handleModal } = useContext(ModalContext)

  const { formValues, handleInputChange } = useForm({
    id: sale.id,
    /* startService: '',
    endService: '', */
    startServiceAt:  moment(sale.startServiceAt).format('YYYY-MM-DD'),
    endServiceAt: moment(sale.endServiceAt).format('YYYY-MM-DD'),
    comment: '',
  })

  const { startServiceAt,endServiceAt,comment } = formValues;
  
  /* useEffect(prevState => {
    return{
        ...prevState,
        startService: moment(startService).format('YYYY-MM-DD'),
        endService: moment(endService).format('YYYY-MM-DD'),
      }
  }, [startService, setFormValues]) */

  const handleSubmit = (e) => {
    e.preventDefault();
        dispatch(startServiceUpdate({
            ...formValues
        }, handleModal.bind()))
  }

  return (
    <div style={{width: "500px"}}>
      <h4 className="text-center">Cambiar fecha de servicio</h4>
      <hr />
      <form onSubmit={handleSubmit}>
        <div className="row">
            <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-3">
                <TextField
                label="Fecha inicial servicio" variant="outlined" size="small" autoComplete="off" fullWidth={true}
                type="date"
                InputLabelProps={{shrink: true,}}
                name="startServiceAt"
                value={startServiceAt}
                onChange={ handleInputChange  }
                />
            </div>

            <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-3">
                <TextField
                label="Fecha Final servicio" variant="outlined" size="small" autoComplete="off" fullWidth={true}
                type="date"
                InputLabelProps={{shrink: true,}}
                name="endServiceAt"
                value={endServiceAt}
                onChange={ handleInputChange }
                />
            </div>
        </div>
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3">
              <TextField
                required
                label="Comentarios" 
                variant="outlined"
                size="small"
                name="comment"
                value={ comment }
                onChange={ handleInputChange }
                autoComplete="off"
                fullWidth={true}
              />
          </div>
        <div className="col-md-12 text-right">
          <button className="btn btn-primary">
            Guardar
          </button>
        </div>
      </form>

    </div>
  )
}