import { TextField } from '@material-ui/core'
import React from 'react'

export const DetailsProducts = () => {
  return (
    <div>
        <div className="container col-12 mb-3">
            <label className='mb-3'> Detalles del producto </label>
            <div className="mb-3">
                <TextField
                    label="Descripcion" variant="outlined" size="small" autoComplete="off" fullWidth
                    name=""
                    onChange={''}
                    helperText={''}
                    error={''}
                    className="w-100" 
                />
            </div>
            <div className="mb-3">
                <TextField
                    label="Precio" variant="outlined" size="small" autoComplete="off" fullWidth={true}
                    name=""
                    onChange={ '' }
                    helperText={ '' }
                    error={ '' }
                >         
                </TextField>
            </div>
            <div className="mb-3">
                <TextField
                    label="Total de meses" variant="outlined" size="small" autoComplete="off" fullWidth={true}
                    name=""
                    onChange={ '' }
                    helperText={ '' }
                    error={ '' }
                >         
                </TextField>
            </div>
            <div className="mb-3">
                <TextField
                    label="Total de sellos" variant="outlined" size="small" autoComplete="off" fullWidth={true}
                    name=""
                    onChange={ '' }
                    helperText={ '' }
                    error={ '' }
                >         
                </TextField>
            </div>
            <div className="mb-3">
                <TextField
                    label="Frecuencia" variant="outlined" size="small" autoComplete="off" fullWidth={true}
                    name=""
                    onChange={ '' }
                    helperText={ '' }
                    error={ '' }
                >         
                </TextField>
            </div>
            <div className="mb-3">
                <TextField
                    label="Tipo de referencia" variant="outlined" size="small" autoComplete="off" fullWidth={true}
                    name=""
                    onChange={ '' }
                    helperText={ '' }
                    error={ '' }
                >         
                </TextField>
            </div>
            <form onSubmit={ '' }>
            <button
            className="btn btn-info btn-lg btn-block mt-3"
        >
            Enviar
        </button>
            </form>
        </div>
    </div>
  )
}
