import { types } from "../../types/types";

const initialState = {
  userInvoices: []
}

export const UserBillinReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.userBillingLoaded:
      return{
        ...state,
        userInvoices: [ ...action.payload ]
      }
      
    default:
      return state;
  }
}
