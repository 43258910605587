import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useForm } from '../../../../../hooks/useForm';

const initialData = {
  subtotal: 0,
  total: 0,
}

export const TotalDataForm = () => {

  const { lineItemList  } = useSelector(state => state.lineItem);

  const { formValues, handleInputChange, setFormValues } = useForm(initialData); 

  const { subtotal } = formValues;

  useEffect(() => {
    let subtotalCalculate = 0;

    lineItemList.map(function(lineItem) {

      subtotalCalculate += lineItem.amount;

      setFormValues( prevState => {
        return {
          ...prevState,
          subtotal: subtotalCalculate
        }
      })

      return null;
    });


  }, [lineItemList, setFormValues])

  return (
    <div className="col-md-6 text-right">

      <div className="form-group row">
        <label className="col-md-8 pt-2"><strong>Subtotal</strong></label>
        <div className="col-md-4">
          <input
            className="form-control"
            name="subtotal"
            value={ subtotal }
            onChange={ handleInputChange }
          />
        </div>
      </div>

      <div className="form-group row">
        <label className="col-md-8 pt-2"><strong>IVA 16%</strong></label>
        <div className="col-md-4">
          <input className="form-control" />
        </div>
      </div>

      <div className="form-group row">
        <label className="col-md-8 pt-2"><strong>Total</strong></label>
        <div className="col-md-4">
          <input className="form-control" />
        </div>
      </div>


    </div>
  )
}
