import { types } from '../../types/types';

const initialState = {
  zipCodeInformation: {}
}

export const geoReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.zipCodeSearchLoading:
      return{
        ...state,
        zipCodeInformation: { ...action.payload }
      }
    
    case types.zipCodeClearLoading:
      return{
        ...state,
        zipCodeInformation: {}
      }
  
    default:
      return state;
  }
}
