import React from 'react'
import { Link } from 'react-router-dom'
import { CRM_SALES } from '../../../constants/Routes'
import { FormProducts } from './ProductsModules/FormProducts'

export const AddProducts = () => {
  return (
    <div className="container">
      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-4">
        <div className="card-header">
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <h5>Datos del producto</h5>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <Link to={CRM_SALES} className="btn btn-danger btn-sm float-right">
                <i className="fas fa-arrow-left"></i> Regresar
              </Link>
            </div>
          </div>
        </div>
      </div>

      <form className="mt-4" onSubmit={''}>
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-3">
          <nav className="mt-4 mb-4">
            <div className="nav  nav-pills" id="nav-tab" role="tablist">
              <a className="nav-item nav-link show active border m-1" id="nav-customer-tab" data-toggle="tab" href="#nav-customer" role="tab" aria-controls="nav-customer" aria-selected="true">
                Crear un producto
              </a>
              <a className={`nav-item nav-link border m-1`}  id="nav-sale-tab" data-toggle="tab" href="#nav-sale" role="tab" aria-controls="nav-sale" aria-selected="true"> 
                <strong > Lista de productos </strong>
              </a>
            </div>
          </nav>
          <div className="tab-content" id="nav-tabContent">
              <div className="tab-pane fade show active " id="nav-customer" role="tabpanel" aria-labelledby="nav-customer-tab" aria-selected="true">
                <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3">
                        <h5>Producto</h5>
                        <hr />
                    </div>
                    <FormProducts />
                </div>
              </div>
              <div className="tab-pane fade" id="nav-sale" role="tabpanel" aria-labelledby="nav-sale-tab">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-1">
                  <h5>Lista</h5>
                  <hr />
                </div >
              </div>    
            </div>
        </div>
      </form>
    </div>
  )
}
