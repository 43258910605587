import { TextField } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useForm } from '../../../../../../../../../hooks/useForm';
import NumberFormat from 'react-number-format'; 

const CanceledDeductions = ({canceledExpenses, setFormValuesDetailsSummary}) => {

  const { setFormValues } = useForm({
    ivaTranslateds: 0.00,
    subtotal: 0.00,
    total: 0.00,
  });


  useEffect(() => {
    let totalSum = 0.00;

    if(canceledExpenses.length > 0){
      canceledExpenses.forEach((expense, i) => {
        totalSum = (parseFloat(totalSum) + parseFloat(expense.total)).toFixed(2);
        
        setFormValues(prevState => {
          return{
            ...prevState,
          }
        })
      });
      setFormValues(prevState => {
        return{
          ...prevState,
          total: totalSum
        }
      });
    }else{
      setFormValues(prevState => {
        return{
          ...prevState,
          ivaTranslateds: 0.00,
          subtotal: 0.00,
          total: 0.00
        }
      });
    }

    setFormValuesDetailsSummary(prevState => {
      return{
        ...prevState,
        totalExpenseNotAutorized: totalSum,
      }
    })
  }, [canceledExpenses, setFormValues, setFormValuesDetailsSummary])

  return (
    <div className="row">
      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2">
        <div className="card">
          <div className="card-header">
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <h5>Gastos Cancelados</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2">
        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr className="text-center">
                <th>UUID</th>
                <th>Fecha de timbrado</th>
                <th>Fecha de pago</th>
                <th>Proveedor</th>
                <th>Folio</th>
                <th>Conceptos</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              {
                canceledExpenses.map((canceledDeduction, index) => {
                  return (parseFloat(canceledDeduction.total) > 0 &&
                    <tr key={index} className="text-center">
                      <td>{canceledDeduction.uuid}</td>
                      <td>{canceledDeduction.stampAt}</td>
                      <td>{canceledDeduction.paymentAt} </td>
                      <td>
                        { canceledDeduction.issuedRfc }
                        <br />
                        { canceledDeduction.issuedFullName }
                      </td>
                      <td>{canceledDeduction.folio}</td>
                      <td>
                        <TextField
                          label="Concepto" variant="outlined" size="small" autoComplete="off" fullWidth={true}
                          select
                          SelectProps={{
                            native: true,
                          }}
                          readOnly
                        >
                          {
                            canceledDeduction.concepts.map((concept, i) => {
                              return (
                                <option
                                  key={i}
                                >
                                  { concept.claveProdServ ? `${concept.claveProdServ} - ` : '' }
                                  { concept.descripcion }
                                </option>
                              )
                            })
                          }
                        </TextField>
                      </td>
                      <td><NumberFormat value={parseFloat(canceledDeduction.total).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default CanceledDeductions;