import { types } from "../../types/types";
import { saveModal } from "../../helpers/UseSwal";
import { fetchWithToken } from "../../helpers/fetch";
import swal from "sweetalert";

import { loadingModal, loadingModalMsn } from "../../helpers/UseSwal";

export const reclassifyData = ( data ) => {
    return async(dispatch) => {
        const resp = await fetchWithToken('api/ReClassify/MassiveClassify', data, 'POST');
        const body = await resp.json();

        if(body.success){
            loadingModalMsn(false);
            swal('Reclasificación exitosa', 'La reclasificación se realizó exitosamente','success');
            setTimeout(() => {
                window.location.reload();
            }, 3000);
        } else {
            swal('Ocurrió un error', 'Ocurrió un error al realizar la reclasificación, contacte al administrador','error');
        }
    }
}
