import React from 'react';
import { TextField } from '@material-ui/core';

export const EmployeeInfo = () => {
    return (
        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
            <h5 className="card-header">Datos Domicilio</h5>
            <div className="row mt-4">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-3">
                            <TextField label="Calle" variant="outlined" size="small" name="street" fullWidth={true}/>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 mt-3">
                            <TextField label="Codigo Postal" variant="outlined" size="small" name="postal_code" fullWidth={true}/>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 mt-3">
                            <TextField label="Número Interior" variant="outlined" size="small" name="num_int" fullWidth={true}/>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 mt-3">
                            <TextField label="Número Exterior" variant="outlined" size="small" name="num_out" fullWidth={true}/>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 mt-3">
                            <TextField label="Colonia" variant="outlined" size="small" name="suburb" fullWidth={true}/>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 mt-3">
                            <TextField label="Cuidad" variant="outlined" size="small" name="city" fullWidth={true}/>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 mt-3">
                            <TextField label="Municipio" variant="outlined" size="small" name="municipality" fullWidth={true}/>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 mt-3">
                            <TextField label="Estado" variant="outlined" size="small" name="state" fullWidth={true}/>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 mt-3">
                            <TextField label="Referencia" variant="outlined" size="small" name="reference" fullWidth={true}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
