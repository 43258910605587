import React, { useEffect } from 'react';
import { TextField, InputAdornment } from '@material-ui/core';
import { useSelector } from 'react-redux';

export const PaymentData = ( { dataPayment, handleArrayInputChange, setFormValues } ) => {

    const { wayOfPayments } = useSelector(state => state.wayOfPayment);
    const { currencyTypes } = useSelector(state => state.currencytype)

    const { currencyExchangeRate, currencyId, currencySatCode, id, operationNumber, paymentAt, paymentTotal, wayOfPaymentId } = dataPayment;

    useEffect(() => {
        if (wayOfPayments.length > 0) {
            setFormValues(prevState => {
                return{
                    ...prevState,
                    dataPayments: prevState.dataPayments.map(
                        e => ( e.id === id ) ? { ...e, wayOfPaymentId: wayOfPayments[0].id } : e
                    )
                }
            })
        }
    }, [id, setFormValues, wayOfPayments])

    useEffect(() => {
        if(currencyTypes.length > 0){
            setFormValues(prevState => {
                return{
                    ...prevState,
                    dataPayments: prevState.dataPayments.map(
                        e => ( e.id === id ) ? { ...e, currencyId: currencyTypes[0].id, currencySatCode: currencyTypes[0].satCode} : e
                    )
                }
            })
        }
    }, [id, setFormValues, currencyTypes])

    const handleCurrencyChange = ( { target } ) => {
        const { name, value } = target;

        const currencyType = currencyTypes.filter(e => ( e.id === parseInt(value) ))[0];

        setFormValues(prevState => {
            return{
                ...prevState,
                dataPayments: prevState.dataPayments.map(
                    e => ( e.id === id ) ? { ...e, [name]: value, currencySatCode: currencyType.satCode, CurrencyExchangeRate: '' } : e
                )
            }
          })
    }
    
    return (
        <div className="mt-2">
            <div className="card-header">
                <h5>Datos del pago</h5>
            </div>
            <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <TextField
                        select
                        className="mt-4"
                        variant="outlined"
                        label="Forma de pago"
                        size="small"
                        fullWidth={true}
                        name="wayOfPaymentId" 
                        value={ wayOfPaymentId }
                        onChange={handleArrayInputChange.bind(this, id, 'dataPayments')} SelectProps={{native: true,}}
                    >
                        { wayOfPayments.length > 0 ? (
                            wayOfPayments.map((wayOfPayment, index) => {
                                return(
                                    <option
                                        key={ index }
                                        value={ wayOfPayment.id }
                                    >
                                       { wayOfPayment.satCode } - { wayOfPayment.description }
                                    </option>
                                )
                            })
                          )
                          :
                          (
                            <option value={0}>No hay registros</option>
                          )
                        }
                    </TextField>
                    <TextField
                        select
                        className="mt-4"
                        variant="outlined"
                        label="Moneda" 
                        size="small"
                        fullWidth={true}
                        name="currencyId" 
                        value={ currencyId }
                        onChange={ handleCurrencyChange }
                        SelectProps={{native: true,}}
                    >
                        { currencyTypes.length > 0 ? (
                            currencyTypes.map((currencyType, index) => {
                                return(
                                    <option
                                        key={ index }
                                        value={ currencyType.id }
                                    >
                                       { currencyType.satCode } - { currencyType.description }
                                    </option>
                                )
                            })
                          )
                          :
                          (
                            <option value={0}>No hay registros</option>
                          )
                        }
                    </TextField>
                    { currencySatCode !== 'MXN' && (
                        <TextField
                            className="mt-4"
                            label="Tipo de cambio"
                            variant="outlined" 
                            size="small"
                            name="CurrencyExchangeRate"
                            value={ currencyExchangeRate }
                            fullWidth={true}
                            InputLabelProps={{shrink: true,}}
                            onChange={handleArrayInputChange.bind(this, id, 'dataPayments')}
                        />
                      )
                    }
                </div>
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <TextField 
                        className="mt-4"
                        label="Fecha de pago"
                        variant="outlined"
                        size="small"
                        name="paymentAt"
                        fullWidth={true}
                        type="date"
                        value={ paymentAt }
                        onChange={ handleArrayInputChange.bind(this, id, 'dataPayments') }
                        InputLabelProps={{shrink: true,}}
                    />
                    <TextField
                        className="mt-4"
                        label="Número de operación"
                        variant="outlined"
                        size="small" 
                        name="operationNumber"
                        fullWidth={true}
                        value={ operationNumber }
                        onChange={ handleArrayInputChange.bind(this, id, 'dataPayments') }
                    />
                    <TextField
                        className="mt-4"
                        label="Monto Total del Pago" 
                        variant="outlined" 
                        size="small"
                        name="paymentTotal"
                        value={ paymentTotal }
                        onChange={ handleArrayInputChange.bind(this, id, 'dataPayments') }
                        autoComplete="off"
                        fullWidth={true} 
                        InputProps={{startAdornment: (<InputAdornment position="start">$</InputAdornment>),}}
                    />
                </div>
            </div>
        </div>
    )
}
