import { types } from "../../types/types";

const initialState = {
  productsBillingr: []
}

export const ProductsBillingReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.productBillingLoading:
      return{
        ...state,
        productsBillingr: [ ...action.payload ]
      }
      
    default:
      return state;
  }
}
