import React, { useContext } from 'react';
import ReactDOM from "react-dom";
import Modal from 'react-modal';
import { CustomCenterModalStyle, CustomBigModalStyle } from '../helpers/CustomModalStyles';
import { ModalContext } from './ModalContext';

Modal.setAppElement('#root');

const ModalComponent = () => {

  const { modalContent, handleModal, modal, bigModal } = useContext(ModalContext);

  if (modal){
    return ReactDOM.createPortal(
      <Modal
        isOpen={ modal }
        onRequestClose={handleModal}
        closeTimeoutMS={200}
        style={ bigModal ? CustomBigModalStyle : CustomCenterModalStyle}
        overlayClassName="modal-fondo"
      >
        
        { modalContent }

      </Modal>,
      document.querySelector("#root")
    );
  }else return null;
};

export default ModalComponent;