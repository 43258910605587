import { InputAdornment, TextField } from '@material-ui/core';
import React, { Fragment, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { obtainIsrPlataformPorcentage } from '../../../../../../../../../../helpers/accounting/obtainIsrPlataformPorcentaje';
import NumberFormat from 'react-number-format';

export const ServiceLodgingScreen = ({formValuesDetailsSummary, setFormValuesDetailsSummary, autorizedReport, status}) => {

  const { IsrPlataformLodgingRanges } = useSelector(state => state.isrPlataformLodging);

  const { taxISRPlataformsTechnologies, year } = formValuesDetailsSummary;

  const { serviceLodging } = taxISRPlataformsTechnologies;

  const { ISR, subtotalServiceLodgingClasified, totalIsr,
          totalService, totalRetained, totalServiceLodgingFinal, totalLodgingPlataform
        } = serviceLodging;

  useEffect(() => {
    let totalServiceLodging = (parseFloat(subtotalServiceLodgingClasified) - parseFloat(totalLodgingPlataform)).toFixed(2);
    if (parseFloat(totalServiceLodging) <= 0) {
      totalServiceLodging = 0.00
    }
    
    const totalLodgingService = (
      parseFloat(totalLodgingPlataform) +
      parseFloat(totalServiceLodging)
    ).toFixed(2);

    const totalForIntermediaries = (
      parseFloat(totalLodgingPlataform)
    ).toFixed(2);

    const totalForUsers = (
      parseFloat(totalServiceLodging)
    ).toFixed(2);

    setFormValuesDetailsSummary(prevState => {
      return{
        ...prevState,
        taxISRPlataformsTechnologies: {
          ...prevState.taxISRPlataformsTechnologies,
          serviceLodging: {
            ...prevState.taxISRPlataformsTechnologies.serviceLodging,
            totalForIntermediaries: totalForIntermediaries,
            totalForUsers: totalForUsers,
            totalService: totalLodgingService,
            totalServiceLodgingFinal: totalServiceLodging,
          }
        }
      }
    })
  }, [setFormValuesDetailsSummary, subtotalServiceLodgingClasified, totalLodgingPlataform])
  
  useEffect(() => {
    setFormValuesDetailsSummary(prevState => {
      return{
        ...prevState,
        taxISRPlataformsTechnologies: {
          ...prevState.taxISRPlataformsTechnologies,
          serviceLodging: {
            ...prevState.taxISRPlataformsTechnologies.serviceLodging,
            ISR: obtainIsrPlataformPorcentage(IsrPlataformLodgingRanges, totalService, year),
          }
        },
      }
    })
  }, [IsrPlataformLodgingRanges, setFormValuesDetailsSummary, totalService, year])

  useEffect(() => {
    const totalIsr = (
      parseFloat(ISR.isrCaused) -
      parseFloat(totalRetained)
    ).toFixed(2)

    setFormValuesDetailsSummary(prevState => {
      return{
        ...prevState,
        taxISRPlataformsTechnologies: {
          ...prevState.taxISRPlataformsTechnologies,
          serviceLodging: {
            ...prevState.taxISRPlataformsTechnologies.serviceLodging,
            totalIsr: totalIsr
          }
        },
      }
    })
  }, [ISR, totalRetained, setFormValuesDetailsSummary])

  const handleInputChangeLodging = (e) => {
    const { name, value } = e.target;

    setFormValuesDetailsSummary(prevState => {
      return{
        ...prevState,
        taxISRPlataformsTechnologies:{
          ...prevState.taxISRPlataformsTechnologies,
          serviceLodging:{
            ...prevState.taxISRPlataformsTechnologies.serviceLodging,
            [name]: value
          }
        }
      }
    })
  }

  return (
    <table className="table">
      <tbody>
        <tr>
          <td className="w-50">Ingresos por intermediarios</td>
          <td className="w-50">
            {
              status === 'Presentada' ?
              (<TextField
                variant="outlined"
                size="small"
                name="totalLodgingPlataform"
                value={totalLodgingPlataform}
                onChange={handleInputChangeLodging}
                autoComplete="off"
                fullWidth={true}
                InputProps={{
                  startAdornment: (<InputAdornment position="start">$</InputAdornment>),
                }}
                disabled={true}
              />)
              :
              (<TextField
                variant="outlined"
                size="small"
                name="totalLodgingPlataform"
                value={totalLodgingPlataform}
                onChange={handleInputChangeLodging}
                autoComplete="off"
                fullWidth={true}
                InputProps={{
                  startAdornment: (<InputAdornment position="start">$</InputAdornment>),
                }}
                disabled={autorizedReport}
              />)
            }
          </td>
        </tr>
        <tr>
          <td className="w-50">Ingresos directos</td>
          <td className="w-50"><NumberFormat value={parseFloat(totalServiceLodgingFinal).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td>
        </tr>
        <tr>
          <td className="w-50">Ingresos totales</td>
          <td className="w-50"><NumberFormat value={parseFloat(totalService).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td>
        </tr>
        <tr>
          <td className="w-50">Tasa</td>
          <td className="w-50"><NumberFormat value={parseFloat(ISR.porcentage * 100).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'%'} /></td>
        </tr>
        <tr>
          <td className="w-50">ISR Causado</td>
          <td className="w-50"><NumberFormat value={parseFloat(ISR.isrCaused).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td>
        </tr>
        <tr>
          <td className="w-50">Retenciones por plataformas tecnológicas</td>
          <td className="w-50">
            {
              status === 'Presentada' ?
              (<TextField
                variant="outlined"
                size="small"
                name="totalRetained"
                value={totalRetained}
                onChange={handleInputChangeLodging}
                autoComplete="off"
                fullWidth={true}
                InputProps={{
                  startAdornment: (<InputAdornment position="start">$</InputAdornment>),
                }}
                disabled={true}
              />)
              :
              (<TextField
                variant="outlined"
                size="small"
                name="totalRetained"
                value={totalRetained}
                onChange={handleInputChangeLodging}
                autoComplete="off"
                fullWidth={true}
                InputProps={{
                  startAdornment: (<InputAdornment position="start">$</InputAdornment>),
                }}
                disabled={autorizedReport}
              />)
            }
          </td>
        </tr>
        <tr>
          {
            totalIsr >= 0 ? (
              <Fragment>
                <td className="w-50">ISR a cargo</td>
                <td className="w-50"><NumberFormat value={parseFloat(totalIsr).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td>
              </Fragment>
            )
            :
            (
              <Fragment>
                <td className="w-50">ISR a cargo</td>
                <td className="w-50"><NumberFormat value={0.00} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td>
              </Fragment>
            )
          }
        </tr>
      </tbody>
    </table>
  )
}
