import React from 'react';

export const TotalPlayroll = () => {
    return (
        <div>
            <h5 className="card-header">Detalles de la Nómina</h5>
            <div className="container">
                <div className="row mt-2 text-center">
                    <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                        <p>Empleados Agregados: 2</p>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                        <p>Total de CFDIs Emitidos: 0</p>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                        <p>Total a pagar: $ 0.00</p>
                    </div>
                </div>
            </div>
        </div>
    )
}
