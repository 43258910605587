import React from 'react'
import { LeaseIsrBlindCalculation } from './isrOptions/LeaseIsrBlindCalculation';
import { HonirariumBussinessIsrCalculation } from './isrOptions/HonirariumBussinessIsrCalculation';
import { RifIsrCalculation } from './isrOptions/RifIsrCalculation';
import { TechnologyPlatformsIsrCalculation } from './isrOptions/TechnologyPlatformsIsrCalculation';
import { SimplifiedIsrCalculation } from './isrOptions/SimplifiedIsrCalculation';

export const IsrTaxCalculation = ({ formValuesDetailsSummary, setFormValuesDetailsSummary, regimeSatCode, autorizedReport, status }) => {
  
  return (
    <div className="col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5">
      <h5 className="card-header">ISR</h5>
      <div className="row">
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2">
          <table className="table">
            <tbody>
              {
                regimeSatCode === '621' && (
                  <RifIsrCalculation 
                    autorizedReport={autorizedReport}  
                    formValuesDetailsSummary={ formValuesDetailsSummary }
                    setFormValuesDetailsSummary={ setFormValuesDetailsSummary }
                    status={status}
                  />
                )
              }
              {
                regimeSatCode === '612' && (
                  <HonirariumBussinessIsrCalculation
                    autorizedReport={autorizedReport}
                    formValuesDetailsSummary={ formValuesDetailsSummary }
                    setFormValuesDetailsSummary={ setFormValuesDetailsSummary }
                    status={status}
                  />
                )
              }
              {
                regimeSatCode === '625' && (
                  <TechnologyPlatformsIsrCalculation
                    autorizedReport={autorizedReport}
                    formValuesDetailsSummary={ formValuesDetailsSummary }
                    setFormValuesDetailsSummary={ setFormValuesDetailsSummary }
                    status={status}
                  />
                )
              }
              {
                regimeSatCode === '606' && (
                  <LeaseIsrBlindCalculation
                    autorizedReport={autorizedReport}
                    formValuesDetailsSummary={ formValuesDetailsSummary }
                    setFormValuesDetailsSummary={ setFormValuesDetailsSummary }
                  />
                )
              }
              {
                regimeSatCode === '626' && (
                  <SimplifiedIsrCalculation
                    autorizedReport={autorizedReport}
                    formValuesDetailsSummary={ formValuesDetailsSummary }
                    setFormValuesDetailsSummary={ setFormValuesDetailsSummary }
                    status={status}
                  />
                )
              }
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}
