import React, { Fragment, useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import { useSelector } from 'react-redux';

export const PlataformInformation = ({ detailsFormValues }) => {

  const { authorizationReport, authorizations } = useSelector(state => state.authorizationReport);
  const { subtotalExpenseAutorized, totalExpensePersonalAutorized, subtotalExpenseNotAutorized, taxISRPlataformsTechnologies, taxIVAPlataformsTechnologies } = detailsFormValues;
  const { serviceAlienation, serviceGround, serviceLodging, totalIncomes } = taxISRPlataformsTechnologies

  const [totalIsr, setTotalIsr] = useState(0.00);

  const considered = authorizations.paymentComplementExpensesSubtotal || authorizationReport.paymentComplementExpensesSubtotal || 0
  const consideredExpenseTotalAcreditable = parseFloat(subtotalExpenseAutorized) + parseFloat(considered)

  useEffect(() => {
    let totalIsr = parseFloat(
      parseFloat(serviceAlienation.totalIsr) +
      parseFloat(serviceGround.totalIsr) +
      parseFloat(serviceLodging.totalIsr)
    ).toFixed(2)

    if (parseFloat(totalIsr) < 0) {
      totalIsr = 0.00;
    }
    setTotalIsr(totalIsr)
  }, [serviceAlienation, serviceGround, serviceLodging, setTotalIsr])

  // //console.log('IVA:', taxIVAPlataformsTechnologies.totalIva )
  return (
    <div className="col-md-6">
      <strong>Ingresos:</strong><NumberFormat value={parseFloat(totalIncomes).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /><br />
      <strong>Ingresos por Servicio Terrestre:</strong><NumberFormat value={parseFloat(serviceGround?.totalService).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /> <br />
      <strong>Ingresos por Servicio Hospedaje:</strong><NumberFormat value={parseFloat(serviceLodging?.totalService).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /> <br />
      <strong>Ingresos por Enajenación:</strong><NumberFormat value={(parseFloat(serviceAlienation?.totalLendingPlataform) + parseFloat(serviceAlienation.totalAlienationPlataform)).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /><br />
      <strong>Deducciones Autorizadas:</strong><NumberFormat value={parseFloat(consideredExpenseTotalAcreditable).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /><br />
      <strong>Deducciones Personales:</strong><NumberFormat value={parseFloat(totalExpensePersonalAutorized).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /><br />
      <strong>No deducibles:</strong><NumberFormat value={parseFloat(subtotalExpenseNotAutorized).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /><br />
      {
        parseFloat(taxIVAPlataformsTechnologies.totalIva) >= 0 ?
          (<Fragment>
            <strong>Total a pagar de IVA:</strong><NumberFormat value={parseFloat(taxIVAPlataformsTechnologies.totalIva).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /><br />
          </Fragment>)
          :
          (<Fragment>
            <strong>Total a favor de IVA:</strong><NumberFormat value={parseFloat(parseFloat(taxIVAPlataformsTechnologies.totalIva) * -1).toFixed(6)} displayType={'text'} thousandSeparator={true} prefix={'$'} /><br />
          </Fragment>)
      }
      <strong>Total a pagar de ISR:</strong>
      <NumberFormat value={parseFloat(totalIsr).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} />
      <br />
    </div>
  )
}
