import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';
import { crmSaleOptainSelected, crmSaleSelectedClear, crmSaleStartUpdate } from '../../../../../actions/crm/sales';
import { getByEmail } from '../../../../../actions/users';
import { useForm } from '../../../../../hooks/useForm';
import { ADMIN_SALES } from '../../../../constants/Routes';
import { ModuleCommentScreen } from '../../../crm/sales/FormModules/ModuleCommentScreen';
import { ModuleInvoiceScreen } from '../../../crm/sales/FormModules/ModuleInvoiceScreen';
import { ModuleSaleScreen } from '../../../crm/sales/FormModules/ModuleSaleScreen';
import { ModuleUserScreen } from '../../../crm/sales/FormModules/ModuleUserScreen';

export const AdminNewSaleFormScreen = () => {
  const {saleId} = useParams();
  
  const { crmSaleSelected } = useSelector(state => state.crmSale);

  const [msgErrors] = useState({});

  let history = useHistory();

  const dispatch = useDispatch();

  const {formValues, setFormValues, handleInputChange} = useForm({
    comments: '',
    endServiceAt: '',
    invoiceData: {
      cfdiUseId: 0,
      comments: '',
      receptorAccountId: 0,
      wayOfPaymentId: 0,
    },
    salesData:[
      {
        id: new Date().getTime(),
        price: 0.00,
        productId: 0,
      }
    ],
    startServiceAt: '',
    total: 0.00,
    paymentStatusId: '',
    voucher: {
      voucherFiles: '',
      paymentAt: '',
      isLoadedVoucher: false
    },
    userId: '',
  });

  const {comments, endServiceAt, invoiceData, startServiceAt, total } = formValues;

  useEffect(() => {
    if (saleId !== undefined) {
      dispatch(crmSaleOptainSelected({id: saleId}))
    }else{
      dispatch(crmSaleSelectedClear())
    }
  }, [dispatch, saleId]);

  useEffect(() => {
    if(crmSaleSelected){
      dispatch(getByEmail(crmSaleSelected.user.email));

      let products = [];
      crmSaleSelected.products.forEach((product) =>{
        products.push({
          id: product.id,
          price: product.total,
          productId: product.product.id,
        })
      });

      setFormValues(prevState => {
        return{
          ...prevState,
          invoiceData: {
            cfdiUseId: crmSaleSelected.invoiceData.cfdiUse.id,
            comments: crmSaleSelected.invoiceData.comments,
            receptorAccountId: crmSaleSelected.invoiceData.receptorAccount.id,
            wayOfPaymentId: crmSaleSelected.invoiceData.wayOfPaypment.id,
          },
          salesData: products,
          endServiceAt: moment(crmSaleSelected.endServiceAt).format('YYYY-MM-DD'),
          startServiceAt: moment(crmSaleSelected.startServiceAt).format('YYYY-MM-DD'),
          total: crmSaleSelected.total,
          voucher: {
            ...prevState.voucher,
            paymentAt: crmSaleSelected.paymentAt ? moment(crmSaleSelected.paymentAt).format('YYYY-MM-DD') : '',
            isLoadedVoucher: crmSaleSelected.voucherPaths ? true : false,
          },
          comments: crmSaleSelected.comments,
          userId: crmSaleSelected.user.id
        }
      })
    }
  }, [crmSaleSelected, dispatch, setFormValues]);

  const handleSubmit = (e) => {
    e.preventDefault();

    let formData = new FormData();

    Object.keys(invoiceData).forEach(function(key) {
      formData.append(`invoiceData.${key}`, invoiceData[key]);
    });

    formData.append("StartServiceAt", startServiceAt);
    formData.append("EndServiceAt", endServiceAt);
    formData.append(`total`, total);
    formData.append(`comments`, comments);

    if(crmSaleSelected){
      formData.append(`id`, crmSaleSelected.id);

      dispatch(crmSaleStartUpdate(formData, history, ADMIN_SALES))
    }
  }

  return (
    <div className="container">
      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-4">
        <div className="card-header">
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <h5>Datos de la venta</h5>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <Link to={ADMIN_SALES} className="btn btn-danger btn-sm float-right">
                <i className="fas fa-arrow-left"></i> Regresar
              </Link>
            </div>
          </div>
        </div>
      </div>
      <form className="mt-4" onSubmit={handleSubmit}>
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <div className="row">
            <ModuleUserScreen
              isRenovation={true}
              msgErrors={msgErrors}
              saleFormValues={formValues}
              saleSetFormValues={setFormValues}
            />

            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <h5>Datos de la venta</h5>
              <hr />
            </div>
            <ModuleSaleScreen
              isAdmin={true}
              msgErrors={msgErrors}
              saleFormValues={formValues}
              saleSetFormValues={setFormValues}
              saleHandleInputChange={handleInputChange}
            />

            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <h5>Datos de la Factura</h5>
              <hr />
            </div>
            <ModuleInvoiceScreen
              saleFormValues={formValues}
              saleSetFormValues={setFormValues}
            />

            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <h5>Comentarios extras</h5>
              <hr />
            </div>
            <ModuleCommentScreen
              saleFormValues={formValues}
              saleSetFormValues={setFormValues}
            />
            
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 text-right">
              <button className="btn btn-primary">
                Guardar
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}
