import swal from "sweetalert";
import { fetchWithTokenFormDataExist, fetchWithToken } from "../../helpers/fetch";
import { saveModal } from "../../helpers/UseSwal";
import { types } from "../../types/types";

export const TaxdocumentsStartRegister = (taxDocument, handleModal = null) => {
  return async (dispatch) => {
    saveModal(true);
    const resp = await fetchWithTokenFormDataExist('api/TaxDocuments/upload', taxDocument, 'PUT');
    const body = await resp.json();
    if (body.success) {
      if (handleModal) {
        handleModal();
      } else {
        swal('Exito', 'Se cargo el archivo correctamente', 'success');
      }
      const keyDocument = body.data[0].documentType.key;
      const reportId = taxDocument.get("PreAuthorizedReportId")
      if (keyDocument === 'Declaracion' || keyDocument === 'LineaCaptura' || keyDocument === 'Declaracion0') {
        let data = [{
          documentContent: body.data[0].content,
          documentExtension: body.data[0].documentExtension,
          documentName: body.data[0].documentType.description
        }]
        dispatch(taxStatusReport('Presentada'))
        dispatch(taxDocumentLoadeds(data, reportId))
      }
    } else {
      if (body.data === 'Finished') {
        swal(body.error, '', 'error');
      } else {
        swal('Error al guardar', 'Comprueba la información de tus archivos', 'error');
      }
    }
    saveModal(false);
  }
}

const taxStatusReport = (status) => ({
  type: types.statusReportUdapte,
  payload: status
})

export const TaxDocumentsStartLoading = (reportId) => {
  return async (dispatch) => {
    const resp = await fetchWithTokenFormDataExist(`api/TaxDocuments/GetByIdReport/${reportId}`);
    const body = await resp.json()
    if (body.success) {
      dispatch(taxDocumentLoadeds(body.data, reportId))
      saveModal(true);
    } else {
      // //console.log('ERRROR')
      //swal('Error al guardar', 'Comprueba la información de tus archivos', 'error');
    }
    saveModal(false);
  }
}

const taxDocumentLoadeds = (taxDocuments, reportId) => ({
  type: types.preAuthorizationTaxDocumentsStartLoading,
  payload: {
    taxDocuments: taxDocuments,
    reportId: reportId
  }
})

export const taxFilesStartLoading = (profileId) => {
  return async (dispatch) => {
    // loadingModal(true);
    const resp = await fetchWithToken(`api/TaxDocuments/GetByProfileId/${profileId}`);
    const body = await resp.json();
    if (body.success) {
      dispatch(loadedtaxFiles(body.data))
    }
    // loadingModal(false);
  }
}

const loadedtaxFiles = (taxfiles) => ({
  type: types.taxFilesStartLoading,
  payload: taxfiles
})