import { types } from "../types/types";

const initialState = {
  lineItems: [],
  lineItemList: []
}

export const lineItemReducer = ( state = initialState, action ) => {
  switch (action.type) {
    case types.lineItemAddToList:
      return {
        ...state,
        lineItemList: [
          ...state.lineItemList,
          action.payload
        ]
      }

    case types.lineItemDeletedToList:
      return {
        ...state,
        lineItemList: state.lineItemList.filter(
          e => ( e.id !== action.payload.id )
        ),
      }

    case types.lineItemAddNew:
      return {
        ...state,
        lineItems: [
          ...state.lineItems,
          action.payload
        ]
      }
  
    default:
      return state;
  }
}
