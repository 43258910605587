import { TextField } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { crmRenovationsStartLoading } from '../../../../actions/crm/renovations';
import { crmSalesStartLoading } from '../../../../actions/crm/sales';
import { obtainMonths, obtainYears } from '../../../../helpers/declarationPeriod';
import { useForm } from '../../../../hooks/useForm';
import AdminAccountingIndexScreen from './accounting/AdminAccountingIndexScreen';
import { AdminInvocingIndexScreen } from './invoicing/AdminInvocingIndexScreen';
import { AdminNewSalesIndexScreen } from './newUsers/AdminNewSalesIndexScreen';

export const AdminSalesIndexScreen = () => {

  const { formValues, setFormValues, handleInputChange } = useForm({
    accountingActives: 0,
    accoutingNotActives: 0,
    dataDownload: [],
    invoicingActives: 0,
    invoicingNotActives: 0,
    monthsOb: obtainMonths(),
    monthSelected: '',
    newUserActives: 0,
    newUserNotActives: 0,
    years: obtainYears().reverse(),
    yearselected: '',
  });

  const dispatch = useDispatch();

  const { accoutingNotActives, invoicingNotActives, monthSelected, monthsOb, newUserNotActives, years, yearselected } = formValues;

  useEffect(() => {
    if (monthSelected !== '' && yearselected !== '') {
      dispatch(crmRenovationsStartLoading({
        month: monthSelected,
        year: yearselected
      }))

      dispatch(crmSalesStartLoading({
        month: monthSelected,
        year: yearselected
      }))
    }
  }, [dispatch, monthSelected, yearselected]);


  useEffect(() => {
    const date = new Date();
    setFormValues(prevState => {
      return {
        ...prevState,
        monthSelected: date.getMonth() + 1,
        yearselected: date.getFullYear()
      }
    })
  }, [setFormValues]);

  return (
    <div>
      <div className="container">
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <div className="card-header">
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <h5>Administración / Ventas</h5>
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-12 mt-2">
          <div className="row">
            <div className="col-md-2">
              <TextField select variant="outlined" label="Mes" size="small"
                fullWidth={true} name="monthSelected"
                value={monthSelected} onChange={handleInputChange}
                SelectProps={{ native: true, }}>
                {
                  monthsOb.map((month, index) => {
                    return (
                      <option key={index} value={month.month + 1}>
                        { month.label}
                      </option>
                    )
                  })
                }
              </TextField>
            </div>

            <div className="col-md-2">
              <TextField select variant="outlined" label="Año" size="small"
                fullWidth={true} name="yearselected"
                value={yearselected} onChange={handleInputChange}
                SelectProps={{ native: true, }}>
                {
                  years.map((yearItem, index) => {
                    return (
                      <option key={index} value={yearItem}>
                        { yearItem}
                      </option>
                    )
                  })
                }
              </TextField>
            </div>
          </div>
        </div>

        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <nav className="mt-4">
            <div className="nav nav-tabs" id="nav-tab" role="tablist">
              <a className="nav-item nav-link active" id="nav-users-new-tab" data-toggle="tab" href="#nav-users-new" role="tab" aria-controls="nav-users-new" aria-selected="true">
                Usuarios nuevos - <span className="badge badge-danger">{newUserNotActives}</span>
              </a>
              <a className="nav-item nav-link" id="nav-accounting-tab" data-toggle="tab" href="#nav-accounting" role="tab" aria-controls="nav-accounting" aria-selected="true">
                Contabilidad - <span className="badge badge-danger">{accoutingNotActives}</span>
              </a>
              <a className="nav-item nav-link" id="nav-facturacion-tab" data-toggle="tab" href="#nav-facturacion" role="tab" aria-controls="nav-facturacion" aria-selected="true">
                Facturación - <span className="badge badge-danger">{invoicingNotActives}</span>
              </a>
            </div>
          </nav>

          <div className="tab-content" id="nav-tabContent">
            <div className="tab-pane fade" id="nav-accounting" role="tabpanel" aria-labelledby="nav-accounting-tab">
              <AdminAccountingIndexScreen
                setFormValueSalesIndex={setFormValues}
              />
            </div>
            <div className="tab-pane fade" id="nav-facturacion" role="tabpanel" aria-labelledby="nav-facturacion-tab">
              <AdminInvocingIndexScreen
                setFormValueSalesIndex={setFormValues}
              />
            </div>

            <div className="tab-pane fade show active" id="nav-users-new" role="tabpanel" aria-labelledby="nav-users-new-tab">
              <AdminNewSalesIndexScreen
                setFormValueSalesIndex={setFormValues}
                formValues={formValues}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
