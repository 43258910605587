import { types } from "../../types/types"

const initialState = {
    Instructions: []
}

export const helpInstructionsReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.csvInstructions:
            return {
                Instructions: [ ...action.payload]
            }
        default:
            return state;
    }
}
