const initalState = {
  accountTypes: [
    {
      id: 1,
      description: 'Cuenta de ahorro'
    },
    {
      id: 2,
      description: 'Cuenta de cheques'
    },
    {
      id: 3,
      description: 'Tarjeta de crédito'
    },
    {
      id: 4,
      description: 'Tarjeta de débito'
    }
  ]
};

export const accountTypeReducer = ( state = initalState, action ) => {
  return state;
}
