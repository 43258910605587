import { types } from "../../types/types";

const initialState = {
  paymentFrecuencies: []
}

export const paymentFrecuencieReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.paymentFrecuencieCfdisStartLoading:
      return{
        ...state,
        paymentFrecuencies: [ ...action.payload ]
      }

    default:
      return state;
  }
}
