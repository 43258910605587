import { fetchWithApiKey } from "../../helpers/fetch";
import { loadingModal } from "../../helpers/UseSwal";
import { types } from "../../types/types";

export const documentTaxTaxpayerTypesStartLoading = () => {
  return async(dispatch) => {
    loadingModal(true)
    const resp = await fetchWithApiKey('api/Catalogs/c_TaxDocumentType');
    const body = await resp.json();
    if (body.success){
      dispatch( documentTaxTaxpayerTypesLoaded( body.data ) )
    }
    loadingModal(false)
  }
}

const documentTaxTaxpayerTypesLoaded = ( documentTypes ) => ({
  type: types.documentTaxTaxpayerTypesStartLoading,
  payload: documentTypes
})