import React, { useContext, useEffect, useState } from 'react'
import { ImageList } from '../../../layout/ImageList';
import { useSelector } from 'react-redux';
import { ModalContext } from '../../../modal/ModalContext';

export const SaleVouchers = ({saleId}) => {
  const { handleModal } = useContext( ModalContext );

  const { crmSales } = useSelector(state => state.crmSale);

  const [images, setImages] = useState([]);


  useEffect(() => {
    const sale = crmSales.filter(e => e.saleId === parseInt(saleId))[0];
    if (sale.vouchers !== undefined) {
      setImages(sale.vouchers);
    }
  }, [crmSales, saleId, setImages])

  return (
    <div>
      <p className="text-center">
        <strong>Listado de comprobantes</strong>
      </p>
      {images.length > 0 ?
    (
      <ImageList
      images={images}
    />
    ):(
      <div className="alert alert-info text-center" role="alert">
        Sin comprobante
      </div>
    )}

      <div className="col-md-12 text-right pr-0">
        <button className="btn btn-danger" onClick={ () => handleModal(false)}>
          Cerrar
        </button>
      </div>
    </div>
  )
}
