import React, { useEffect, useState, useContext } from 'react';
import moment from 'moment'
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@material-ui/core';
import { useSelector } from 'react-redux';
import { userAccounts } from '../../../../../helpers/clasificateUsers';
import { Pagination } from '../../../../../helpers/tables/Pagination';
import { UserForm } from '../userForm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ModalContext } from '../../../../modal/ModalContext';

const ShowAccounts = () => {

    const { handleModal } = useContext( ModalContext );
    
    const {users} = useSelector(state => state.userType)

    const [usersAccount, setUsersAccount] = useState([]);

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);

    useEffect(()=>{
        setUsersAccount(userAccounts(users))
    },[users, setUsersAccount])

    return (
        <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2">
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                <h5>Usuarios Contadores</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2">
                <div className="card">
                    <div className="card-body">
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell aling="center">ID</TableCell>
                                        <TableCell aling="center">Nombre</TableCell>
                                        <TableCell aling="center">Correo</TableCell>
                                        <TableCell aling="center">Activo</TableCell>
                                        <TableCell aling="center">Es Jefe</TableCell>
                                        <TableCell aling="center">F. Registro</TableCell>
                                        <TableCell aling="center">Operaciones</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                    usersAccount.length === 0 ?
                                    (<TableRow>
                                        <TableCell colSpan="10">
                                        <div className="alert alert-info text-center" role="alert">
                                            No hay administradores
                                        </div>
                                        </TableCell>
                                    </TableRow>)
                                    :
                                    (
                                        usersAccount.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((user, index) => {
                                        return(
                                            <TableRow key={index}>
                                            <TableCell>{user.id}</TableCell>
                                            <TableCell align="center">
                                                {user.name} {user.lastName} {user.motherLastName}
                                            </TableCell>
                                            <TableCell align="center">
                                                <strong>{user.email}</strong><br />
                                            </TableCell>
                                            <TableCell align="center">
                                                { user.isActive ? 
                                                (
                                                    <span className="badge badge-success">Si</span>
                                                ):
                                                (
                                                    <span className="badge badge-danger">No</span>
                                                )
                                                }
                                            </TableCell>
                                            <TableCell align="center">
                                                { user.isBoss ? 
                                                (
                                                    <span className="badge badge-success">Si</span>
                                                ):
                                                (
                                                    <span className="badge badge-danger">No</span>
                                                )
                                                }
                                            </TableCell>

                                            <TableCell align="center">
                                                { user.createdAt ? 
                                                (
                                                    moment(user.createdAt).format('YYYY-MM-DD')
                                                )
                                                :
                                                (
                                                    'Sin fecha'
                                                )
                                                }
                                            </TableCell>
                                            <TableCell align="center">
                                                <button
                                                    data-toggle="tooltip"
                                                    data-placement="top"
                                                    title="Editar Serie"
                                                    className="btn btn-sm btn-outline-primary px-2"
                                                    onClick={ () => handleModal(
                                                    <UserForm 
                                                        userGuest={ user }
                                                    />
                                                    )}
                                                >
                                                    <FontAwesomeIcon className="fa-lg" icon={"pencil-alt"}/>
                                                </button>
                                            </TableCell>
                                            </TableRow>
                                        )
                                        })
                                    )
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Pagination
                            data={usersAccount}
                            page={page}
                            rowsPerPage={rowsPerPage}
                            setPage={setPage}
                            setRowsPerPage={setRowsPerPage}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ShowAccounts;
