import React from 'react';
import { Link } from 'react-router-dom';
import { NEW_PAIDCOMPLEMENT } from '../../../../constants/Routes';

const ShowPaidComplement = ( { taxpayerId } ) => {
    return(
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2">
            <div className="form-row">
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <Link to={NEW_PAIDCOMPLEMENT(taxpayerId)} className="btn btn-primary mt-2">
                        <i className="fas fa-plus"></i> Crear Complemento Pago
                    </Link>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                    <input type="text" className="form-control mt-2" placeholder="Buscar" />
                </div>
                <div className="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                    <button className="btn btn-info mt-2"> <i className="fas fa-search"></i> Buscar</button>
                </div>
            </div>
        </div>
    );
}

export default ShowPaidComplement;
