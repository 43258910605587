import { types } from "../../types/types";

const initialState = {
    data: {}
}


export const preReportDetailsData = (state = initialState, action) => {
    switch (action.type) {
        case types.preReportDetailsData:
            return {
                ...state,
                data: [...action.payload]
            }
        case 'CLEAR_PRE_REPORT_DETAILS_DATA':
            return {
                ...state,
                data: [],
            };
        default:
            return state;
    }
}