import { Base64 } from 'js-base64';
import JSZip from 'jszip';
import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { certificatesStartLoading } from '../../../../actions/taxpayers/certificates';
import { certificatesIsFiels } from '../../../../helpers/certificate';
import { useForm } from '../../../../hooks/useForm';
import { ModalContext } from '../../../modal/ModalContext';
import { CertificateForm } from './CertificateForm';
import { saveAs } from 'file-saver';

export const EfirmaDetailsScreen = ({ taxpayerId, isAdmin = false }) => {

  const { handleModal } = useContext(ModalContext);

  const {certificates} = useSelector(state => state.certificate)

  const dispatch = useDispatch();

  const { formValues, setFormValues } = useForm({
    fielOptions: []
  })

  const { fielOptions } = formValues;

  useEffect(() => {
    if (certificates.length === 0) {
      dispatch(certificatesStartLoading(taxpayerId))
    }

    setFormValues(prevState => {
      return{
        ...prevState,
        fielOptions: certificatesIsFiels(certificates)
      }
    })
  }, [certificates, dispatch, setFormValues, taxpayerId]);

  const downloadFiel = (fiel, e) => {
    e.preventDefault();
    
    var zip = new JSZip();
    
    zip.file("password.txt", Base64.decode(fiel.password));
    zip.file("certificado.cer", fiel.cerContent, {base64: true});
    zip.file("key.key", fiel.keyContent, {base64: true});

    zip.generateAsync({type:"blob"}).then(function(content) {
      saveAs(content, "fiel.zip");
    });
  }

  return (
    <div className="col-md-6 mt-4">
      {
        isAdmin ? (
          <h3>E-firmas</h3>
        )
        :
        (
        <button 
          className="btn btn-primary mb-3"
          onClick={() => handleModal(
            <CertificateForm
              taxpayerId={ taxpayerId }
              typeFile='fielCer'
            />
          )}
        >
          Agregar Efirma
        </button>
        )
      }
      <div className='table-responsive'>
      <table className="table table-striped">
        <thead>
          <tr>
            <td>Número de serie</td>
            <td>Expira el</td>
            <td className="text-center">Acciones</td>
          </tr>
        </thead>
        <tbody>
          {
            fielOptions.length === 0 ? (
              <tr>
                <td colSpan="3">
                  <div className="alert alert-primary text-center mb-0" role="alert">
                    No se ha agregado información
                  </div>
                </td>
              </tr>
            )
            :
            (
              fielOptions.map((fiel, index) => {
                return(
                  <tr key={index}>
                    <td>{fiel.serialNumber}</td>
                    <td>{fiel.expiredAt}</td>
                    <td className="text-center">
                      <button className="btn btn-primary" data-toggle="tooltip" data-placement="bottom" title="Descargar Archivos" onClick={downloadFiel.bind(this, fiel)}>
                        <i className="fas fa-file-archive"></i>
                      </button>
                    </td>
                  </tr>
                )
              })
            )
          }
        </tbody>
      </table>
      </div>
    </div>
  )
}
