import React, { Fragment, useContext, useEffect, useState } from 'react';
import { ModalContext } from '../../../../../../../../modal/ModalContext';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import NumberFormat from 'react-number-format';
import { Details } from './Details';
import AuthorizaedDeductionsComplement from '../../expenses/options/authorizaedDeductionsComplement';
import { Label } from '@material-ui/icons';
import { changeAuthorizationPreAuthorized } from '../../../../../../../../../actions/accounting/preAuthorizedReports';


export const Complement = ({ formValuesDetailsSummary, setFormValuesDetailsSummary, autorizedReport = false, status, refreshComplementPayments }) => {

  const params = useParams()
  const { regimeSatCode, taxpayerId, month, year, token } = useParams();

  const { complements, complementExpense } = useSelector(state => state.complement);
  const { authorizationReport, authorizations } = useSelector(state => state.authorizationReport)
  const { period } = authorizationReport;
  const { handleModal } = useContext(ModalContext);
  const dispatch = useDispatch();

  const [authorization, setAuthorization] = useState([]);
  const complementsData = authorizations.complementsExpenses || authorizationReport.complementsExpenses || [];
  const [complementAutorized, setComplementAutorized] = useState([])


  const [monthString, setMonthString] = useState('');
  const [yearString, setYearString] = useState('');
  const [monthNumber, setMonthNumber] = useState('');

  const yearComplement = year || yearString || '';
  const monthComplement = month || monthNumber || '';

  useEffect(() => {
    if (token && period) {
      const parts = period.split('-');
      setMonthString(parts[0]?.replace('Mes de', '').trim());
      setYearString(parseInt(parts[1]?.trim(), 10));

      setAuthorization(authorizationReport)
    } else {
      setAuthorization(authorizations)
    }
    setComplementAutorized(complementsData)
  }, [authorizations, authorizationReport, period, token])

  useEffect(() => {
    if (token && monthString) {
      setMonthNumber(new Date(`${monthString} 1, 2000`).getMonth() + 1);
    }
  }, [monthString])

  const changedConsideredComplement = (e, complementDetail, complementDetails) => {

    // //console.log('complementDetails', complementDetails)
    // //console.log('UNO-SOLO', complementDetail)
    // return false 

    const listComplements = []

    const authorized = e.target.checked
    const complementId = complementDetail[0]?.id;

    complementDetails.forEach(complement => {
      const newAuthorize = {
        ProfileId: taxpayerId,
        PaymentComplementId: complement.id,
        Authorization: [
          {
            authorized: authorized,
            regimeSatCode: regimeSatCode,
            paymentComplementTypeId: 17
          }
        ]
      }
      listComplements.push(newAuthorize)
    })

    dispatch(changeAuthorizationPreAuthorized(listComplements, refreshComplementPayments))

    // complementDetails.forEach( detail => {
    //   const newAuthorize = {
    //     ProfileId: taxpayerId,
    //     PaymentComplementId: detail.id,
    //     Authorization: [
    //       {
    //         authorized: authorized,
    //         regimeSatCode: regimeSatCode,
    //         paymentComplementTypeId: 17
    //       }
    //     ]
    //   }
    //   dispatch(changeAuthorizationPreAuthorized({
    //     complement_id: complementId,
    //     authorization: newAuthorize
    //   }, 
    //     refreshComplementPayments
    //   ))
    // })
  }

  const changedCompDetailOnSuccess = () => {
    refreshComplementPayments()
  }

  const changedConsideredComplementDetail = (e, complementDetail, details) => {

    const authorized = e.target.checked
    const complementId = complementDetail?.id;

    const newAuthorize = {
      ProfileId: taxpayerId,
      PaymentComplementId: complementId,
      Authorization: [
        {
          authorized: authorized,
          regimeSatCode: regimeSatCode,
          paymentComplementTypeId: 17
        }
      ]
    }
    dispatch(changeAuthorizationPreAuthorized([newAuthorize], changedCompDetailOnSuccess))
  }


  //const { newComplements,setNewComplements} = useState([])
  // useEffect (() => {
  // sacar el iva si no viene uy si viene comparar que sea igual
  //  //console.log(complementExpense)
  //  let totalivadetails=200
  //declarar un array
  //   foreach al Array{complement

  //     complement['iva'] = Importe a pagar / 1.6
  //     totalivadetails = totaldetatilarray+totalivadetails
  // iva= xlm.nodo.iva
  // if(xlm.nodo.iva == 0){
  //   iva= xlm.total/1.16
  // }
  //}
  // setNewComplements(auxArray)
  //   setFormValuesDetailsSummary(prevState => {
  //     return{
  //       ...prevState,
  //         totalivadetails: total
  //       }
  //     }
  //   });
  //},[complementExpense])
  // //console.log('period', period)


  return (
    <div className="row">
      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2">
        <div className="card">
          <div className="card-header">
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <h5>Complementos Relacionados</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2">
        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr className="text-center">
                <th>Check</th>
                <th>Fecha de emisión</th>
                <th>Status</th>
                <th>Proveedor</th>
                <th>Folio Fiscal</th>
                <th>Clave de producto y servicios</th>
                <th>Descripción</th>
                <th>Forma de pago</th>
                <th>Metodo de pago</th>
                <th>Clasificación</th>
                <th>Subtotal</th>
                <th>Descuento</th>
                <th>Iva</th>
                <th>Total</th>
                <th>Importe a pagar</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {complementsData.length === 0
                ?
                (<tr>
                  <td colSpan="14">
                    <div className="alert alert-primary text-center mb-0" role="alert">
                      No se ha agregado información
                    </div>
                  </td>
                </tr>)
                : (
                  complementsData.map((complement, index) => {
                    const complementsDetails = complement.details || []
                    const complementDetail = complementsDetails.filter(detail => {
                      const stampAt = new Date(detail.stampAt)
                      const yearDetail = stampAt.getFullYear()
                      const monthDetail = stampAt.getMonth() + 1
                      return yearDetail == yearComplement && monthDetail == monthComplement
                    })
                    return (
                      <tr key={index} className={`text-center ${complement.withMaster === false && complement.details !== 'null' && "text-success"}`}>
                        <td>
                          {
                            complement.details !== null && (
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  name="typeOwner"
                                  checked={complement.authorizationFull}
                                  onChange={(e) => { changedConsideredComplement(e, complementDetail, complementsDetails) }}
                                  disabled={false}
                                />
                              </div>
                            )
                          }
                        </td>
                        <td>{complement.paymentAt}</td>
                        <td style={{ color: complement.statusComplementParent === 'Retrasado' ? 'red' : complement.statusComplementParent === 'Al Corriente' ? 'yellow' : complement.statusComplementParent === 'Pagado' ? 'green' : '', fontWeight: 'bold' }}>{complement.statusComplementParent}</td>
                        <td>
                          {complement.IssuedRfc}
                          <br />
                          {complement.issuedFullName}
                        </td>
                        <td>{complement.RelationedUUID}</td>
                        <td>{complement.claveProdServ}</td>
                        <td>{complement.descripcion}</td>
                        <td><strong>{complement.wayOfPayment?.description}</strong></td>
                        <td><strong>{complement.paymentMethod?.description}</strong></td>
                        <td>{complement.clasificacion}</td>
                        <td>
                          <NumberFormat value={parseFloat(complement.subtotal).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                        </td>
                        <td>
                          <NumberFormat value={parseFloat(complement.descuento).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                        </td>
                        <td>
                          <NumberFormat value={parseFloat(complement.iva).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                        </td>
                        <td>
                          <NumberFormat value={parseFloat(complement.montoTotalFactura).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                        </td>
                        <td>
                          <NumberFormat value={parseFloat(complement.impSaldoInsoluto).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                        </td>
                        <td>
                          {
                            complement.withMaster === true && complement.details !== null && (
                              <button className="btn btn-warning"
                                onClick={() => handleModal(
                                  <Details
                                    details={complement.details}
                                    monthComplement={monthComplement}
                                    yearComplement={yearComplement}
                                    changedConsideredComplementDetail={changedConsideredComplementDetail}
                                  // autorizedReport={autorizedReport}
                                  // status={status}
                                  />
                                )}
                              >
                                <i className='fas fa-edit'></i>
                              </button>
                            )
                            || complement.withMaster === false && complement.details !== null && (
                              <Fragment>
                                {/* <label> Sin padres </label>  */}
                                <button className="btn btn-warning"
                                  onClick={() => handleModal(
                                    <Details
                                      details={complement.details}
                                    // autorizedReport={autorizedReport}
                                    // status={status}
                                    />
                                  )}
                                >
                                  <i className='fas fa-edit'></i>
                                </button>
                              </Fragment>
                            )
                            || complement.withMaster === true && complement.details === null && (
                              "Sin detalles"
                            )
                          }
                        </td>
                      </tr>
                    )
                  })
                )
              }
            </tbody>
          </table>
        </div>
      </div>

      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2">
        <nav>
          <div className="nav nav-tabs" id="nav-tab" role="tablist">
            <a className="nav-item nav-link active" id="nav-AuthorizedDeductions-tab" data-toggle="tab" href="#nav-AuthorizedDeductions" role="tab" aria-controls="nav-AuthorizedDeductions" aria-selected="true">
              <strong>Deducciones Autorizadas</strong>
            </a>
          </div>
        </nav>
      </div>

      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2">
        <div className="tab-content" id="nav-tabContent">
          <div className="tab-pane fade show active" id="nav-AuthorizedDeductions" role="tabpanel" aria-labelledby="nav-AuthorizedDeductions-tab">
            <AuthorizaedDeductionsComplement
              complementAutorized={complementAutorized}
              authorizations={authorization}
              monthComplement={monthComplement}
              yearComplement={yearComplement}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
