import React from 'react';
import { useForm } from '../../../../../hooks/useForm';
import { AditionalDataForm } from './AditionalDataForm'
import { TotalDataForm } from './TotalDataForm'

export const AditionalDataDetails = () => {
  
  const {formValues, handleInputChange, setFormValues} = useForm({
    wayOfPaymentId: '',
    paymentMethodId: '',
    coinId: '',
  });

  const { wayOfPaymentId, paymentMethodId, coinId } = formValues;
  return (
    <div className="col-md-12">
      <h5 className="card-header">Datos de la factura</h5>
      <div className="card-body">
        <div className="row">
          <AditionalDataForm 
            formData={{
              wayOfPaymentId,
              paymentMethodId,
              coinId
            }}
            handleInputChange={ handleInputChange }
            setFormValues={ setFormValues }
          />

          <TotalDataForm />
        </div>
      </div>
    </div>
  )
}
