import React, { useContext, useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { TableBody } from '@material-ui/core';
import { Table, TableCell, TableHead, TableContainer, TableRow, TextField } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { VIEW_CONFIG_ACTIVITIES } from '../../../../../constants/Routes';
import { regimesConfigLoading, regimesStartLoading } from '../../../../../../actions/catalogs/regimes';
import { loadingModal } from '../../../../../../helpers/UseSwal';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
	circuloRojo: {
		width: '30px', /* Tamaño más pequeño del círculo */
		height: '30px', /* Tamaño más pequeño del círculo */
		borderRadius: '50%', /* Hace que el div sea circular */
		backgroundColor: 'red', /* Color de fondo rojo */
		color: 'white', /* Color de texto blanco */
		display: 'flex',
		placeItems: 'center',
		alignItems: 'center',
		justifyContent: 'center',
		fontSize: '14px', /* Tamaño del texto */
	},
});

const ConfiguracionCatalogos = ({ }) => {
	const { regimes } = useSelector(state => state.regimesConfig);

	const dispatch = useDispatch();

	useEffect(() => {
		loadingModal(true);
		dispatch(regimesConfigLoading());
	}, [dispatch]);

	const classes = useStyles();

	return (
		<div>
			<div className='col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12'>
				<div className='mt-2 ml-4'>
					<div className='row d-flex justify-content-start'>
						<div className='col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12'>
							<h4>
								Configuración de catálogos
							</h4>
						</div>
					</div>
				</div>
				<div className='col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-4'>
					<div className='card'>
						<div className='card-body'>
							<div className='row'>
								<div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
									<h5>Listado de Regímenes</h5>
								</div>
								<div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
									<TableContainer>
										<Table>
											<TableHead>
												<TableRow>
													<TableCell align="center">
														<strong>ID</strong>
													</TableCell>
													<TableCell align="center">
														<strong>Régimen</strong>
													</TableCell>
													<TableCell align="center">
														<strong>Total de Claves de Producto Registradas en Automático</strong>
													</TableCell>
												</TableRow>
											</TableHead>
											<TableBody>
												{
													regimes.map((regimen) => {
														return (
															<TableRow key={regimen.id}>
																<TableCell align="center">
																	<h6>{regimen.satCode}</h6>
																</TableCell>
																<TableCell align="center">
																	<Link to={VIEW_CONFIG_ACTIVITIES(regimen.satCode)}>
																		<h6>{regimen.officialName}</h6>
																	</Link>
																</TableCell>
																<TableCell align="center">
																	<div className='d-flex align-items-center justify-content-center'>
																		<h6 className={classes.circuloRojo}>{regimen.total}</h6>
																	</div>
																</TableCell>
															</TableRow>
														)
													})
												}
											</TableBody>
										</Table>
									</TableContainer>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

ConfiguracionCatalogos.propTypes = {};

export default ConfiguracionCatalogos;
