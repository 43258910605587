import { InputAdornment, TextField,FormGroup,FormControlLabel,Checkbox } from '@material-ui/core'
import React from 'react';

export const ExpenseFormNotDownloadedSat = ({expenseTypes, formValues, handleInputChange, handleCheckboxTrueFalseChange = null,handleThirdPartyChange, handleSubmit,
  msgErrors, ivaTraslateds, thirdParties, wayOfPayments}) => {
  
  const { expenseTypeId, folio, ivaTraslated,
    ivaTraslatedId, paymentAt, serie, subtotal,
    thirdPartyId, total, wayOfPaymentId,globalAuthorization 
  } = formValues;
  
  return (
    <div>
      <form onSubmit={ handleSubmit }>
        <div className="row">
          <div className="col-md-6 mb-3">
            <TextField
              label="Cliente" variant="outlined" size="small" autoComplete="off" fullWidth={true}
              select
              name="thirdPartyId"
              value={ thirdPartyId }
              onChange={ handleThirdPartyChange }
              SelectProps={{
                native: true,
              }}
              helperText={ msgErrors.thirdPartyId }
              error={ msgErrors.thirdPartyId !== undefined }
            >
              <option value='0' disabled>Selecciona un cliente</option>
              {
                thirdParties.map((thirdParty, index) => {
                  return(
                    <option
                      key={ index }
                      value={ thirdParty.id }
                    > 
                      { thirdParty.rfc } - { thirdParty.businessName }
                    </option>  
                  )
                })
              }
            </TextField>
          </div>
          <div className="col-md-6 mb-3">
            <TextField
              label="Serie" variant="outlined" size="small" autoComplete="off" fullWidth={true}
              name="serie"
              value={ serie }
              onChange={ handleInputChange }
            />
          </div>
          <div className="col-md-6 mb-3">
            <TextField
              label="Folio" variant="outlined" size="small" autoComplete="off" fullWidth={true}
              name="folio"
              value={ folio }
              onChange={ handleInputChange }
            />
          </div>
          <div className="col-md-6 mb-3">
            <TextField
              label="Fecha de pago" variant="outlined" size="small" autoComplete="off" fullWidth={true}
              type="date"
              name="paymentAt"
              value={ paymentAt }
              onChange={ handleInputChange }
              InputLabelProps={{shrink: true,}}
              helperText={ msgErrors.paymentAt }
              error={ msgErrors.paymentAt !== undefined }
            />
          </div>
          <div className="col-md-6 mb-3">
            <TextField
              label="Tipo de gasto" variant="outlined" size="small" autoComplete="off" fullWidth={true}
              select
              name="expenseTypeId"
              value={ expenseTypeId }
              onChange={ handleInputChange }
              SelectProps={{
                native: true,
              }}
            >
              {
                expenseTypes.map((expenseType, index) => {
                  return(
                    <option
                      key={ index }
                      value={ expenseType.id }
                    > 
                      { expenseType.name }
                    </option>  
                  )
                })
              }
            </TextField>
          </div>
          {handleCheckboxTrueFalseChange &&
          <div className="col-md-12 mb-3">
             <div className="form-check mr-2">
                <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="primary"
                              name="globalAuthorization"
                              value={globalAuthorization}
                              onChange={handleCheckboxTrueFalseChange}
                              checked={globalAuthorization}
                            />
                          }
                          label="Clasificar todos los gastos con mismo proveedor y forma de pago"
                        />
                </FormGroup>
             </div>
          </div> 
          }
          {/* <div className="col-md-6 mb-3">
            <TextField
              label="Cuenta asociada" variant="outlined" size="small" autoComplete="off" fullWidth={true}
              select
              name="accountIdentifierId"
              value={ accountIdentifierId }
              onChange={ handleInputChange }
              SelectProps={{
                native: true,
              }}
            >
              {
                accountIdentifiers.map((accountidentifier, index) => {
                  return(
                    <option
                      key={ index }
                      value={ accountidentifier.id }
                    > 
                      { accountidentifier.account }
                    </option>  
                  )
                })
              }
            </TextField>
          </div> */}
          <div className="col-md-6 mb-3">
            <TextField
              label="Forma de pago" variant="outlined" size="small" autoComplete="off" fullWidth={true}
              select
              name="wayOfPaymentId"
              value={ wayOfPaymentId }
              onChange={ handleInputChange }
              SelectProps={{
                native: true,
              }}
            >
              {
                wayOfPayments.map((wayofPayment, index) => {
                  return(
                    <option
                      key={ index }
                      value={ wayofPayment.id }
                    > 
                      { wayofPayment.key } - { wayofPayment.description }
                    </option>  
                  )
                })
              }
            </TextField>
          </div>
          <div className="col-md-6 mb-3">
            <TextField
              label="Subtotal" variant="outlined" size="small" autoComplete="off" fullWidth={true}
              name="subtotal"
              value={ subtotal }
              onChange={ handleInputChange }
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    $
                  </InputAdornment>
                ),
              }}
            />
          </div>

          <div className="col-md-6 mb-3">
            <TextField
              label="Tipo de IVA" variant="outlined" size="small" autoComplete="off" fullWidth={true}
              select
              name="ivaTraslatedId"
              value={ ivaTraslatedId }
              onChange={ handleInputChange }
              SelectProps={{
                native: true,
              }}
            >
              {
                ivaTraslateds.map((ivaTraslated, index) => {
                  return(
                    <option
                      key={ index }
                      value={ ivaTraslated.id }
                    > 
                      { ivaTraslated.name }
                    </option>  
                  )
                })
              }
            </TextField>
          </div>

          <div className="col-md-6 mb-3">
            <TextField
              label="IVA" variant="outlined" size="small" autoComplete="off" fullWidth={true}
              name="ivaTraslated"
              value={ ivaTraslated }
              onChange={ handleInputChange }
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    $
                  </InputAdornment>
                ),
              }}
            />
          </div>

          <div className="col-md-6 mb-3">
            <TextField
              label="Total" variant="outlined" size="small" autoComplete="off" fullWidth={true}
              type="number"
              name="total"
              value={ total }
              onChange={ handleInputChange }
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    $
                  </InputAdornment>
                ),
              }}
              helperText={ msgErrors.total }
              error={ msgErrors.total !== undefined }
            />
          </div>
          
          <div className="col-md-12 text-right">
            <button className="btn btn-primary">Guardar</button>
          </div>
        </div>
      </form>
    </div>
  )
}
