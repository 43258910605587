import React, { useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ModalContext } from '../../../modal/ModalContext';
import { SerieFolioForm } from './SerieFolioForm';
import { serieFolioDeleted } from '../../../../actions/serieFolios';
import swal from 'sweetalert';

export const SeriesFoliosDetailsScreen = () => {

  const { handleModal } = useContext( ModalContext );

  const { seriesFolios } = useSelector(state => state.serieFolio);

  const dispatch = useDispatch();

  const deleteSerieFolio = (id, e) => {
    swal({
      title: "¿Estas seguro?",
      text: "Una vez eliminado ¡no podrá recuperar su información!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
    .then((willDelete) => {
      if (willDelete) {

        dispatch(serieFolioDeleted({ id: id }))

        swal("¡La serie y folio ha sido eliminado!", {
          icon: "success",
        });
      }
    })
  }

  return (
    <div className="row">
      <div className="col-md-12 mt-4">
        <button
          className="btn btn-primary mb-3"
          onClick={ () => handleModal(
            <SerieFolioForm/>
          )}
        >Agregar Serie y Folio</button>

        <table className="table">
          <thead>
            <tr>
              <th scope="col">Serie</th>
              <th scope="col">Folio inicial</th>
              <th scope="col">Tipo</th>
              <th scope="col">Acciones</th>
            </tr>
          </thead>

          <tbody>
            {
              seriesFolios.length === 0 ? 
              ( <tr>
                  <td colSpan="4">
                    <div className="alert alert-primary text-center mb-0" role="alert">
                      No se ha agregado información
                    </div>
                  </td>
                </tr> ) :
              ( seriesFolios.map((serieFolio) => {
                return(
                  <tr key={ serieFolio.id }>
                    <td>{ serieFolio.serie }</td>
                    <td>{ serieFolio.folio }</td>
                    <td>{ serieFolio.invoiceType.description }</td>
                    <td>
                      <button
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Editar Serie"
                        className="btn btn-sm btn-outline-primary px-2"
                        onClick={ () => handleModal(
                          <SerieFolioForm 
                            serieFolio={ serieFolio } 
                          />
                        )}
                      >
                        <FontAwesomeIcon className="fa-lg" icon={"pencil-alt"}/>
                      </button>

                      <button
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Eliminar Serie"
                        className="btn btn-sm btn-outline-danger px-2 ml-2"
                        onClick={ deleteSerieFolio.bind(this, serieFolio.id ) }
                      >
                        <FontAwesomeIcon className="fa-lg" icon={"trash"}/>
                      </button> 
                    </td>
                  </tr>
                )
              }))
            }
          </tbody>
        </table>
      </div>
    </div>
  )
}
