import React, { useEffect } from 'react'

export const IsrRetainedTaxes = ( { CheckIsrRetained, isrRetained, setIsrRetained, formValues, initialIsrRetained } ) => {
  
  const { pretotalWithoutTaxes } = formValues;

  const { amount, porcentage } = isrRetained;

  useEffect(() => {
    if (CheckIsrRetained) {
      const amountTaxe = pretotalWithoutTaxes * porcentage
  
      setIsrRetained( prevState => {
        return{
          ...prevState,
          amount: amountTaxe
        }
      })
    } else {
      setIsrRetained( initialIsrRetained )
    }
  }, [ CheckIsrRetained, porcentage, pretotalWithoutTaxes, setIsrRetained, initialIsrRetained ]);

  const handleInputChange = ( { target } ) => {
    setIsrRetained({
      ...isrRetained,
      [target.name]: target.value
    })
  }

  return (
    <div className="col-md-12 mb-3">
      {
        CheckIsrRetained && 
        (
          <div className="row mb-3">
            <div className="col-md-2">
              <strong>ISR</strong>
            </div>

            <div className="col-md-5">
              <div className="input-group input-group-sm">
                <select
                  className="form-control"
                  name="porcentage"
                  value={ porcentage }
                  onChange={ handleInputChange }
                >
                  <option value={0.10}>10%</option>
                  <option value={0.20}>20%</option>
                </select>
              </div>
            </div>

            <div className="col-md-5">
              <div className="input-group input-group-sm">
                <input
                  type="number"
                  className="form-control"
                  name="amount"
                  value={ amount }
                  onChange={ handleInputChange }
                />
              </div>
            </div>
          </div>
        )
      }
    </div>
  )
}
