import { types } from "../../types/types";

const initialState = {
  accountIdentifiers: [],
  accountIdentifier: null
};

export const IdentifierReducer = (state = initialState, action) => {
  switch (action.type) {

    case types.identifierAddNew:
      return {
        ...state,
        accountIdentifiers: [
          ...state.accountIdentifiers,
          action.payload
        ]
      }

    case types.identifierSetSelected:
      return {
        ...state,
        accountIdentifier: action.payload
      }

    case types.identifierClearSelected:
      return {
        ...state,
        accountIdentifier: null
      }

    case types.identifierUpdated:
      return {
        ...state,
        accountIdentifiers: state.accountIdentifiers.map(
          e => ( e.id === action.payload.id ) ? action.payload : e
        )
      }

    case types.identifierDeleted:
      return {
        ...state,
        accountIdentifiers: state.accountIdentifiers.filter(
          e => ( e.id !== action.payload.id )
        ),
        accountIdentifier: null
      }

    default:
      return state;
  }
}
