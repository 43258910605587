
export const clasificateRegimesInvoicing = (regimes, profileRegimes) => {
  const regimesInvoicing = [];
  profileRegimes.forEach(profileRegime => {
    if(profileRegime.invoicing){
      const regime = regimes.filter(e => (e.id === parseInt(profileRegime.regimeId)))[0];
      regimesInvoicing.push(regime)
    }
  });
  return regimesInvoicing;
}