const initialState = {
  deductionTypes: [
    {
      id: 1,
      name: 'Deducción autorizada (mensual)',
      deductionCode: 'DA'
    },
    {
      id: 2,
      name: 'Deducción personal (anual)',
      deductionCode: 'DP'
    },
    {
      id: 3,
      name: 'No deducible',
      deductionCode: 'ND'
    }
  ]
};

export const deductionTypeReducer = ( state = initialState, action ) => {
  switch (action.type) {
  
    default:
      return state;
  }
}