import { types } from "../types/types";

const initialState = {
  invoiceTypes: [
    {
      id: 1,
      description: 'Recibo de Honorarios',
      satCode: 'I',
      key: 'fee_receipt'
    },
    {
      id: 2,
      description: 'Recibo de Arrendamiento',
      satCode: 'I',
      key: 'lease_receipt'
    },
    {
      id: 3,
      description: 'Factura',
      satCode: 'I',
      key: 'invoice'
    }
  ]
};

export const InvoiceTypeReducer = ( state = initialState, action ) => {
  switch (action.type) {
    case types.invoiceTypeSearchId:
      return {
        invoiceType: state.invoiceTypes.filter(
          e => ( e.id === action.payload.id )
        )
      }
    default:
      return state;
  }
}
