export const initialoptionIsrHonorarium = {
  fixedFee: 0,
  isrDetermined: 0,
  isrMonthsBefore: 0,
  isrRetentions: 0,
  lowerLimit: 0,
  marginalTax: 0,
  monthBeforeRetention: 0,
  porcentage: 0,
  surplusLowerLimit: 0,
  taxeBeforeRetentions: 0,
  retentionToPay: 0
}

export const obtainIsrHonorariumPorcentage = (columns, amount, month, year, retentionPrevMonths, retentionPaidPrev, isrRetention) => {
  let option = {};
  columns.forEach(column => {
    if(parseFloat(amount) >= column.lowerLimit && parseFloat(amount)<=column.upperLimit  && column.month == parseInt(month) && column.year ==parseInt(year)){
      const surplusLowerLimit = (parseFloat(amount) - parseFloat(column.lowerLimit)).toFixed(6);
      const marginalTax = (parseFloat(surplusLowerLimit) * parseFloat(column.porcentage)).toFixed(6);
      const taxeBeforeRetentions = (parseFloat(marginalTax) + parseFloat(column.fixedFee)).toFixed(6);
      const isrRetentions = parseFloat(isrRetention);
      
      let retentionToPay = parseFloat(taxeBeforeRetentions) - (parseFloat(isrRetentions) +  parseFloat(retentionPrevMonths) + parseFloat(retentionPaidPrev))

      if(retentionToPay < 0){
        retentionToPay = 0.00;
      } 

      option = {
        ...column,
        isrRetentions: isrRetentions,
        marginalTax: marginalTax,
        surplusLowerLimit: surplusLowerLimit,
        taxeBeforeRetentions: taxeBeforeRetentions,
        retentionToPay: retentionToPay
      }
    }
  });

  if(Object.keys(option).length === 0){
    option = initialoptionIsrHonorarium;
  }

  return option;
}