import { types } from "../../types/types";

const initialState = {
  activities: []
}

export const activitiesReducer = ( state = initialState, action ) => {
  switch (action.type) {

    case types.activitiesStartLoading:
      return{
        ...state,
        activities: [ ...action.payload ]
      }

    default:
      return state;
  }
}
