import { types } from "../../types/types";

const initialState = {
  documentTaxTaxpayerTypes: []
};

export const documentTaxTaxpayerTypeReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.documentTaxTaxpayerTypesStartLoading:
      return{
        ...state,
        documentTaxTaxpayerTypes: [...action.payload]
      }

    default:
      return state;
  }
}
