import { TextField } from '@material-ui/core'
import React, { useEffect } from 'react'
import { useForm } from '../../../../../../../../hooks/useForm';
import './styles/formDownloadedSat.scss'

export const IncomeFormDownloadedSat = ({ formValuesIncome, handleInputChangeIncome, handleSubmit, serviceTypeRetentions, regimeSatCode, wayOfPayments }) => {

  const { formValues, setFormValues } = useForm({
    client: ' - '
  });

  const { client } = formValues;

  const { concepts, paymentAt, serviceTypeRetentionId, stampAt, thirdParty, total, wayOfPaymentId } = formValuesIncome;

  useEffect(() => {
    if(thirdParty){
      setFormValues(prevState => {
        return{
          ...prevState,
          client: `${ thirdParty.rfc } - ${ thirdParty.name }`
        }
      })
    }
  }, [setFormValues, thirdParty])

  return (
    <div>
      <form onSubmit={ handleSubmit }>
        <div className="row">
          <div className="col-md-12 mb-3">
            <TextField label="Cliente" variant="outlined" size="small" autoComplete="off" fullWidth={true}
              value={ client }
              disabled
            />
          </div>
          <div className="col-md-12 mb-3">
            <TextField label="Conceptos" variant="outlined" size="small" autoComplete="off" fullWidth={true}
              select
              SelectProps={{
                native: true,
              }}
              readOnly
            >
              {
                concepts.map((concept, i) => {
                  return (
                    <option
                      key={i}
                    >
                      { concept.claveProdServ ? `${concept.claveProdServ} - ` : ''}
                      { concept.descripcion}
                    </option>
                  )
                })
              }
            </TextField>
          </div>
          <div className="col-md-6 mb-3">
            <TextField label="Fecha de facturación" variant="outlined" size="small" autoComplete="off" fullWidth={true}
              type="date"
              name="stampAt"
              value={ stampAt }
              disabled
            />
          </div>

          <div className="col-md-6 mb-3">
            <TextField label="Fecha de pago" variant="outlined" size="small" autoComplete="off" fullWidth={true}
              type="date"
              name="paymentAt"
              value={ paymentAt }
              onChange={ handleInputChangeIncome }
            />
          </div>
      
          <div className="col-md-6 mb-3 text-primary">
            <TextField
                label="Forma de pago" variant="outlined" size="small" autoComplete="off" fullWidth={true}
                select
                id="wayOfPayment"
                name="wayOfPaymentId"
                value={ wayOfPaymentId }
                SelectProps={{
                  native: true,
                }}
                InputLabelProps={{
                  shrink: true,
                  style: { color: "blue" },
                }}
                disabled
              >
                {
                  wayOfPayments.length > 0 ?
                  (
                    wayOfPayments.map((wayofPayment, index) => {
                      return(
                        <option
                          key={ index }
                          value={ wayofPayment.id }
                        >
                          { wayofPayment.description }
                        </option>  
                      )
                    })
                  )
                  :
                  (
                    <option>
                      No hay registros
                    </option>
                  )
                }
              </TextField>
          </div>
          {
            regimeSatCode === '625' &&
            <div className="col-md-12 mb-3">
              <TextField
                label="Tipo de ingreso" variant="outlined" size="small" autoComplete="off" fullWidth={true}
                select
                name="serviceTypeRetentionId"
                value={ serviceTypeRetentionId }
                onChange={ handleInputChangeIncome }
                SelectProps={{
                  native: true,
                }}
              >
                {
                  serviceTypeRetentions.length > 0 ?
                  (
                    serviceTypeRetentions.map((serviceTypeRetention, index) => {
                      return(
                        <option
                          key={ index }
                          value={ serviceTypeRetention.id }
                        > 
                          { serviceTypeRetention.description }
                        </option>  
                      )
                    })
                  )
                  :
                  (
                    <option>
                      No se han cargado datos
                    </option>
                  )
                }
              </TextField>
            </div>
          }

          <div className="col-md-12 mb-3">
            <TextField label="total" variant="outlined" size="small" autoComplete="off" fullWidth={true}
              value={ total }
              inputProps={
                {style: {textAlign: 'center'}}
              }
            />
          </div>

          <div className="col-md-12 mb-3 text-right">
            <button className="btn btn-primary">
              Guardar
            </button>
          </div>
        </div>
      </form>

    </div>
  )
}
