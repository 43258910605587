import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './Fontawesome';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'bootstrap/dist/css/bootstrap.min.css';
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";

//import $ from 'jquery';
//import Popper from 'popper.js';

ReactDOM.render(<App />, document.getElementById('root'));
