import React, { Fragment } from 'react';
import NumberFormat from 'react-number-format';
import { useSelector } from 'react-redux';

export const RifInformation = ({detailsFormValues}) => {
  const { subtotalExpenseAutorized, subtotalExpenseNotAutorized, subtotalIncomesAutorized, totalExpensePersonalAutorized, taxIVARIF, taxISRRIF } = detailsFormValues
  const { authorizationReport, authorizations } = useSelector(state => state.authorizationReport);

  const considered = authorizations.paymentComplementExpensesSubtotal || authorizationReport.paymentComplementExpensesSubtotal || 0
  const consideredExpenseTotalAcreditable = parseFloat(subtotalExpenseAutorized)  + parseFloat(considered)

  return (
    <div className="col-md-6">
      <strong>Ingresos:</strong><NumberFormat value={parseFloat(subtotalIncomesAutorized).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /><br/>
      <strong>Deducciones Autorizadas:</strong><NumberFormat value={parseFloat(consideredExpenseTotalAcreditable).toFixed(2) } displayType={'text'} thousandSeparator={true} prefix={'$'} /><br/>
      <strong>Deducciones Personales:</strong><NumberFormat value={parseFloat(totalExpensePersonalAutorized).toFixed(2) } displayType={'text'} thousandSeparator={true} prefix={'$'} /><br/>
      <strong>Deducciones No Autorizadas:</strong><NumberFormat value={parseFloat(subtotalExpenseNotAutorized).toFixed(2) } displayType={'text'} thousandSeparator={true} prefix={'$'} /><br/>
      {
        parseFloat(taxIVARIF.totalIva) >= 0 ?
        (<Fragment>
          <strong>Total a pagar de IVA:</strong><NumberFormat value={parseFloat(taxIVARIF.totalIva).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /><br/>
        </Fragment>)
        :
        (<Fragment>
          <strong>Total a favor de IVA:</strong><NumberFormat value={parseFloat(parseFloat(taxIVARIF.totalIva) * -1).toFixed(6)} displayType={'text'} thousandSeparator={true} prefix={'$'} /><br/>
        </Fragment>)
      }
      <strong>Total a pagar de ISR:</strong>
      {
        parseFloat(taxISRRIF.totalIsr) >= 0 ?
        (
          <Fragment>
            <NumberFormat value={parseFloat(taxISRRIF.totalIsr).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /><br/>
          </Fragment>
        )
        :
        (
          <Fragment>
            <NumberFormat value={parseFloat(0.00).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /><br/>
          </Fragment>
        )
      }
    </div>
  )
}
