import { types } from "../../types/types";

export const localTaxeAddToList = ( localTaxe ) => ({
  type: types.localTaxesAddToList,
  payload: localTaxe
});

export const localTaxeUpdateToList = ( localTaxe ) => ({
  type: types.localTaxesUpdateToList,
  payload: localTaxe
})

export const localTaxeDeleteToList = ( localTaxe ) => ({
  type: types.localTaxesDeleteToList,
  payload: localTaxe
})