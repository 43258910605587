import React, { useEffect, Fragment, useState } from 'react';
import { useForm } from '../../../../../../../hooks/useForm';
import { loadingModalMsn } from '../../../../../../../helpers/UseSwal';
import { reclassifyData } from '../../../../../../../actions/configuration/reclasify';
import { TextField, Checkbox, InputLabel, Select, FormControl, MenuItem, ListItemText } from '@material-ui/core';
import { useDispatch } from 'react-redux';

const ReclassifyModal = ({ idRegimen, idActivity, regimen, actividad}) => {

    const { formValues, setFormValues } = useForm({
        regimenId: idRegimen,
        activityId: idActivity,
        onlyNotClassified: false,
        onlyNotDeductible: false
    });

    const dispatch = useDispatch();

    const { regimenId, activityId, onlyNotClassified, onlyNotDeductible } = formValues;

    const reclassifyAct = (e) => {
        e.preventDefault();
        loadingModalMsn(true);
        dispatch(reclassifyData({ ...formValues }));
    };

    const handleInputChange = ({ target }) => {
        const { name, value, type } = target;

        const newValue = type === 'number' ? parseInt(value, 10) : value;

        setFormValues(prevState => {
            return {
                ...prevState,
                [name]: newValue
            }
        })
    };

    const hangleCheck = ({ target }) => {
        const { name, checked } = target;

        setFormValues(prevState => {
            return {
                ...prevState,
                [name]: checked
            }
        });
    };

    return (
        <div style={{ width: "450px" }}>
            <div className='text-center'>
                <h5>Reclasificación</h5>
            </div>
            <hr />
            <form onSubmit={reclassifyAct}>
                <div className='d-flex row justify-content-center align-items-center'>
                    <h6 style={{ color: 'red' }}>¡Alerta!</h6>
                </div>
                <div className='d-flex row justify-content-center align-items-center'>
                    <h7>
                        Se aplicará la reclasificación a todos los gastos{' '}
                        {onlyNotClassified || onlyNotDeductible ? 'omitiendo los:' : ''}
                    </h7>
                </div>
                <div className="d-flex flex-column align-items-center pt-2">
                    {onlyNotClassified && (
                        <p>NO Clasificados</p>
                    )}
                    {onlyNotDeductible && (
                        <p>NO Deducibles</p>
                    )}
                </div>
                <div className='d-flex row justify-content-center align-items-center'>
                    <h7>de todos los Pre-Reportes que se encuentren en:</h7>
                </div>
                <div className='d-flex flex-column gap-3 justify-content-center align-items-center'>
                    <h7>Estado: Diferente a Presentada</h7>
                    <h7>Regimen: {regimen}</h7>
                    <h7>Actividad: {actividad}</h7>
                </div>
                <div className='d-flex row justify-content-center align-items-center'>
                    <h7 style={{ color: 'red' }}>Este proceso es irreversible</h7>
                </div>
                <div className='d-flex row justify-content-center align-items-center pt-3'>
                    <h7>Si no deseas continuar, da click a fuera de este recuadro</h7>
                </div>
                <hr/>
                <div className="d-flex row justify-content-center align-items-center">
                    <div className="col-6 d-flex mb-3 gap-10 justify-content-center align-items-center">
                        <Checkbox
                            name='onlyNotClassified'
                            value={onlyNotClassified}
                            onChange={hangleCheck}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />
                        <label>Omitir los No Clasificados</label>
                    </div>
                    <div className="col-6 d-flex mb-3 gap-10 justify-content-center align-items-center">
                        <Checkbox
                            name='onlyNotDeductible'
                            value={onlyNotDeductible}
                            onChange={hangleCheck}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />
                        <label>Omitir los No deducibles</label>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 d-flex justify-content-end align-items-end">
                        <button className="btn btn-primary">
                            Reclasificar datos
                        </button>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default ReclassifyModal;
