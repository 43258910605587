import { fetchWithToken } from "../../helpers/fetch";
import { saveModal } from "../../helpers/UseSwal";
import { types } from "../../types/types";

export const incomeRegisterStart = (income, handleModal) => {
  return async(dispatch) => {
    saveModal(true);
    const resp = await fetchWithToken('api/Income/create', income, 'POST');
    const body = await resp.json();    
    if (body.success){
      dispatch(registerIncome(body.data))
      handleModal();
    }
    saveModal(false);
  }
}

const registerIncome = ( income ) => ({
  type: types.incomeStartRegister,
  payload: income
});

export const incomeUpdateStart = (income, handleModal) => {
  return async(dispatch) => {
    saveModal(true);
    const resp = await fetchWithToken('api/Income/update', income, 'POST');
    const body = await resp.json();    
    if (body.success){
      dispatch(updateIncome(body.data))
      handleModal();
    }
    saveModal(false);
  }
}

const updateIncome = ( income ) => ({
  type: types.incomeStartUpdate,
  payload: income
})

export const incomesStartLoading = (period) => {
  return async(dispatch) => {
    const resp = await fetchWithToken(`api/Income/get/${period.profileId}/${period.paymentAt}/${period.regimeSatCode}`);
    const body = await resp.json();
    dispatch(incomesFinish(true))
    if (body.success){
      dispatch(incomesFinishStatus("Se cargaron correctamente"))
      dispatch(incomesLoaded(body.data.sort(function(a,b){return new Date(a.stampAt) - new Date(b.stampAt)})))
    }else{
      dispatch(incomesFinishStatus(body.error))
    }
  }
}

const incomesLoaded = (incomes) => ({
  type: types.incomeStartLoading,
  payload: incomes
})
const incomesFinish = (flag) => ({
  type: types.incomeFinishLoading,
  payload: flag
})
const incomesFinishStatus = (msn) => ({
  type: types.incomeFinishStatus,
  payload: msn
})

export const changeAuthorizationIncome = ( income ) => {
  return async(dispatch) => {
    const resp = await fetchWithToken('api/Income/updateAuthorization', income, 'POST');
    const body = await resp.json();    
    if (body.success){
      dispatch(incomeChangeAuthorization(income))
    }
  }
}

const incomeChangeAuthorization = ( income ) => ({
  type: types.incomeStartChangeAutorization,
  payload: income
})
