import { TextField } from '@material-ui/core';
import React, { Fragment } from 'react'

export const ModuleCommentScreen = ({saleFormValues, saleSetFormValues}) => {
  
  const {comments} = saleFormValues;
  
  const handleInputCommentChange = ({target}) => {
    const { name, value } = target;

    saleSetFormValues(prevState=>{
      return{
        ...prevState,
        [name]: value
      }
    })
  } 

  return (
    <Fragment>
      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3">
        <TextField
          label="Comentarios" variant="outlined" size="small" autoComplete="off" fullWidth={true}
          multiline
          rows={4}
          name="comments"
          value={comments}
          onChange={ handleInputCommentChange }
          // helperText={ msgErrors.passwordSAT }
          // error={ msgErrors.passwordSAT !== undefined }
        />
      </div>
    </Fragment>
  )
}
