import { TextField, Tooltip } from '@material-ui/core';
import React from 'react';

export const PositiveBalanceForm = () => {
  
  const handleSubmit = (e) => {
    e.preventDefault();
  }

  return (
    <div>
      <h4>Agregar Saldo a Favor</h4>
      <hr/>

      <form onSubmit={ handleSubmit }>
        <div className="col-md-12 mb-3">
          <Tooltip title="Impuesto de donde proviene el saldo a favor" placement="top">
            <TextField
              label="Impuesto" 
              id="taxe"
              variant="outlined"
              size="small"
              name="taxe"
              // value={ email }
              // onChange={ handleInputChange }
              autoComplete="off"
              fullWidth={true}
              // helperText={ msgErrors.email }
              // error={ msgErrors.email !== undefined }
            />
          </Tooltip>
        </div>

        <div className="col-md-12 mb-3">
          <Tooltip title="Mes en que se originó el saldo a favor" placement="top">
            <TextField
              label="Mes" 
              id="month"
              variant="outlined"
              size="small"
              name="month"
              // value={ email }
              // onChange={ handleInputChange }
              autoComplete="off"
              fullWidth={true}
              // helperText={ msgErrors.email }
              // error={ msgErrors.email !== undefined }
            />
          </Tooltip>
        </div>

        <div className="col-md-12 mb-3">
          <Tooltip title="Año en que se originó el saldo a favor" placement="top">
            <TextField
              label="Año" 
              id="year"
              variant="outlined"
              size="small"
              name="year"
              // value={ email }
              // onChange={ handleInputChange }
              autoComplete="off"
              fullWidth={true}
              // helperText={ msgErrors.email }
              // error={ msgErrors.email !== undefined }
            />
          </Tooltip>
        </div>

        <div className="col-md-12 mb-3">
          <Tooltip title="Fecha en que presentaste la declaración del periodo o ejercicio donde se originó el saldo a favor" placement="top">
            <TextField
              label="Fecha" 
              id="date"
              variant="outlined"
              size="small"
              name="date"
              // value={ email }
              // onChange={ handleInputChange }
              autoComplete="off"
              fullWidth={true}
              // helperText={ msgErrors.email }
              // error={ msgErrors.email !== undefined }
            />
          </Tooltip>
        </div>

        <div className="col-md-12 mb-3 text-center">
          ¿Haz utilizado este saldo a favor?
          
          <div className="row">
            <div className="col-md-6 form-check">
              <input 
                className="form-check-input" 
                type="radio" 
                name="higherIncome" 
                id="lowerIncome"
                value={false}
                //onChange={handleCheckboxTrueFalseChange}
                defaultChecked
              />
              <label className="form-check-label" htmlFor="lowerIncome">
                No
              </label>
            </div>

            <div className="col-md-6 form-check">
              <input
                className="form-check-input" 
                type="radio" 
                name="higherIncome" 
                id="higherIncome" 
                value={true}
                //onChange={handleCheckboxTrueFalseChange}
              />
              <label className="form-check-label" htmlFor="higherIncome">
                Si
              </label>
            </div>
          </div>
        </div>

        <div className="col-md-12 mb-3">
          <TextField
            label="Monto original" 
            id="amountOriginal"
            variant="outlined"
            size="small"
            name="amountOriginal"
            // value={ email }
            // onChange={ handleInputChange }
            autoComplete="off"
            fullWidth={true}
            // helperText={ msgErrors.email }
            // error={ msgErrors.email !== undefined }
          />
        </div>

        <div className="col-md-12 mb-3">
          <TextField
            label="Remanente" 
            id="remainder"
            variant="outlined"
            size="small"
            name="remainder"
            // value={ email }
            // onChange={ handleInputChange }
            autoComplete="off"
            fullWidth={true}
            // helperText={ msgErrors.email }
            // error={ msgErrors.email !== undefined }
          />
        </div>


      </form>

    </div>
  )
}
