import swal from "sweetalert";
import { fetchWithApiKey, fetchWithApiKeyBilling, fetchWithToken } from "../../helpers/fetch";
import { loadingModalMsn } from "../../helpers/UseSwal";
import { types } from "../../types/types";

export const startRegisterBilling = ( user,history,route ) => {
  loadingModalMsn(true,"Registrando Perfil")
  return async(dispatch) => {
    const resp = await fetchWithToken('api/Invoice/ActivateModuloFacturizate', user, 'POST');
    const body = await resp.json();
    if (body.success){
      swal('Exito', 'Se ha registrado exitosamente', 'success');
      history.push(route);
      dispatch( registerActive( body.data ) )
    }else{
        swal('Error', 'Intente de nuevo, por favor', 'error');
      }
      loadingModalMsn(false)
    }
  }

  const registerActive  = ( record ) => ({
    type: types.registerActivateModule,
    payload: record
  })