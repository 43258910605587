import { types } from "../types/types"

const initialState={
  thirdParties: []
}

export const thirdPartyReducer = (state = initialState, action) => {
  switch ( action.type ) {
    case types.thirdPartyStartRegister:
      return{
        ...state,
        thirdParties: [
          ...state.thirdParties,
          action.payload
        ]
      }
    
    case types.thirdPartyStartLoading:
      return{
        ...state,
        thirdParties: [
          ...action.payload
        ]
      }

    default:
      return state
  }
}
