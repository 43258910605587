import React, { Fragment, useEffect } from 'react'
import { useSelector } from 'react-redux';
import { obtainIsrSimplifiedPorcentage } from '../../../../../../../../../helpers/accounting/obtainIsrSimplifiedPorcentage';
import { obtainMonth } from '../../../../../../../../../helpers/declarationPeriod';
import NumberFormat from 'react-number-format';

export const SimplifiedIsrCalculation = ({ formValuesDetailsSummary, setFormValuesDetailsSummary, status }) => {

  const { authorizations } = useSelector(state => state.authorizationReport);
  const {IsrSimplifiedRanges} = useSelector(state => state.isrSimplified);
  const { authorizationPrev } = useSelector(state => state.authorizationReport);
  
  const {isrJson} = authorizations;

  const { subtotalExpenseAutorized, subtotalIncomesAutorized, taxISRSimplified, totalISRIncomeAutorized, month, year } = formValuesDetailsSummary;

  const { expensesAccumulateds, expensesAccumulatedsTotal, expensesSubtotalAutorized, incomesAccumulateds, incomesAccumulatedsTotal, incomesSubtotalAutorized, 
            nameMonth,  subtotalIncomesRate16, ISR, retentionPrevMonths, retentionPaidPrev} = taxISRSimplified;

  const subtotalISR = parseFloat(subtotalExpenseAutorized) + parseFloat(authorizations.paymentComplementExpensesSubtotal || 0) 

  useEffect(() => {
    setFormValuesDetailsSummary(prevState => {
      return{
        ...prevState,
        taxISRSimplified:{
          ...prevState.taxISRSimplified,
          nameMonth: obtainMonth(month - 1)
        }
      }
    })  
  }, [month, setFormValuesDetailsSummary]);

 // Cuando no se tiene nada en db
  useEffect(() => {
    if (Object.keys(isrJson).length <= 10000) {
      let incomesAutorized = subtotalIncomesAutorized;
      let incomesAccumulatedTotal = (parseFloat(incomesAccumulateds) + parseFloat(incomesAutorized)).toFixed(2);

      let expenseAutorized = subtotalExpenseAutorized;
      let expensesAccumulatedTotal = (parseFloat(expensesAccumulateds) + parseFloat(expenseAutorized)).toFixed(2);

      setFormValuesDetailsSummary(prevState =>{
        return{
          ...prevState,
          taxISRSimplified: {
            ...prevState.taxISRSimplified,
            expensesAccumulatedsTotal: expensesAccumulatedTotal,
            expensesSubtotalAutorized: expenseAutorized,
            incomesSubtotalAutorized: incomesAutorized,
            incomesAccumulatedsTotal: incomesAccumulatedTotal
          }
        }
      })
    }
  }, [expensesAccumulateds, incomesAccumulateds, isrJson, setFormValuesDetailsSummary, subtotalExpenseAutorized, subtotalIncomesAutorized]);

  useEffect(() => {
    setFormValuesDetailsSummary(prevState => {
      return{
        ...prevState,
        taxISRSimplified:{
          ...prevState.taxISRSimplified,
          ISR: obtainIsrSimplifiedPorcentage(IsrSimplifiedRanges, incomesSubtotalAutorized, totalISRIncomeAutorized)
        }
      }
    })
  }, [IsrSimplifiedRanges,incomesSubtotalAutorized, retentionPrevMonths, retentionPaidPrev, month, setFormValuesDetailsSummary, totalISRIncomeAutorized, year]);


  return (
    <Fragment>
      <tr>
        <td className="w-50">Ingresos del mes de { nameMonth } { year }</td>
        <td className="w-50"><NumberFormat value={parseFloat(incomesSubtotalAutorized).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td>
      </tr>
      <tr>
          <td className="w-50">Tasa</td>
          <td className="w-50"><NumberFormat value={parseFloat(ISR.porcentage).toFixed(3)} displayType={'text'} thousandSeparator={true} prefix={'%'} /></td>
      </tr>

      <tr>
        <td className="w-50">Impuestos antes de retenciones</td>
        <td className="w-50"><NumberFormat value={parseFloat(ISR.taxeBeforeRetentions).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td>
      </tr>

      <tr>
        <td className="w-50">ISR de retenciones</td>
        <td className="w-50"><NumberFormat value={parseFloat(ISR.isrRetentions).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td>
      </tr>

      <tr>
        <td className="w-50">ISR a pagar</td>
        <td className="w-50"><NumberFormat value={parseFloat(ISR.retentionToPay).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td>
      </tr>

    </Fragment>
  )
}