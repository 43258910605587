import React, { Fragment, useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TextField } from '@material-ui/core';
import { useParams } from 'react-router';
import { useForm } from '../../../../../../../../hooks/useForm';
import { ModalContext } from '../../../../../../../modal/ModalContext';
import { ThirdPartyForm } from '../../../../../thirdParties/ThirdPartyForm';
import { IncomeForm } from './IncomeForm';
import ConsideredIncome from './options/consideredIncome';
import IncomeNotConsidered from './options/incomeNotConsidered';
import CanceledIncome from './options/canceledIncome';
import { clasificateIncomes, consideredsIncomes, notConsideredsIncomes, canceledsIncomes } from '../../../../../../../../helpers/clasificateDownloadAccounting';
import { changeAuthorizationIncome } from '../../../../../../../../actions/accounting/incomes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NumberFormat from 'react-number-format';


const Income = ({ formValuesDetailsSummary, setFormValuesDetailsSummary, autorizedReport = false, status }) => {

  const { taxpayerId } = useParams();

  const { handleModal } = useContext(ModalContext);

  const { incomes, statusIncomeDownload } = useSelector(state => state.income);

  const dispatch = useDispatch();

  const {formValues, setFormValues} = useForm({
    regimeIncomes: [],
    consideredIncomes: [],
    notConsideredIncomes: [],
    canceledIncomes: [],
  });

  const { consideredIncomes, notConsideredIncomes, canceledIncomes, regimeIncomes } = formValues;

  const { month, regimeSatCode, subtotalIncomesAutorized, totalIncomesNotAutorized, year } = formValuesDetailsSummary;

  useEffect(() => {
    if (incomes.length > 0) {
      const clasifications = clasificateIncomes(incomes, month, regimeSatCode, year);
      setFormValues(prevState => {
        return{
          ...prevState,
          regimeIncomes:[ ...clasifications ]
        }
      })
    }
  }, [autorizedReport, formValuesDetailsSummary.regimeSatCode, formValuesDetailsSummary.month, formValuesDetailsSummary.year, incomes, regimeSatCode, setFormValues, month, year]);

  useEffect(() => {
    if(regimeIncomes.length > 0){
      const considereds = consideredsIncomes(regimeIncomes);
      const notConsidereds = notConsideredsIncomes(regimeIncomes);
      const canceleds = canceledsIncomes(regimeIncomes);
      setFormValues(prevState => {
        return{
          ...prevState,
          consideredIncomes: [ ...considereds ],
          notConsideredIncomes: [ ...notConsidereds ],
          canceledIncomes: [...canceleds]
        }
      })
    }
  }, [regimeIncomes, setFormValues]);

  const changeConsideredIncome = (income, e) => {
    const newAuthorize = income.authorization.map(
      authorize => (authorize.regimeSatCode === regimeSatCode) ?
        {
          ...authorize,
          authorized: !authorize.authorized
        }
        :
        authorize
    );
    dispatch(changeAuthorizationIncome({
      incomeId: income.id,
      authorization: newAuthorize
    }));
  }

  return (
    <div className="row">
      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2">
        <div className="card">
          <div className="card-header">
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <h5>Ingresos Relacionados</h5>
                <b>{statusIncomeDownload}</b>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2">
        {
          !autorizedReport && status !== 'Presentada' && (
            <Fragment>
              <button className="btn btn-sm btn-primary float-right ml-3"
                onClick={() => 
                  handleModal(<ThirdPartyForm
                    formAccounting={true}
                    taxpayerId={taxpayerId}
                />, true)}>
                <i className="fas fa-plus"></i> Agregar Cliente
              </button>

              <button className="btn btn-sm btn-success float-right"
                onClick={() => handleModal(
                  <IncomeForm
                    regimeSatCode={regimeSatCode}
                    taxpayerId={taxpayerId}
                    month={month}
                    year={year}
                  />, true)}
              >
                <i className="fas fa-plus"></i> Agregar Ingreso
              </button>
            </Fragment>
          )
        }
      </div>
      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2">
        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr className="text-center">
                <th>Considerar</th>
                <th>UUID</th>
                <th>Fecha de timbrado</th>
                <th>Fecha de cobro</th>
                <th>Cliente</th>
                <th>Conceptos</th>
                <th>Folio</th>
                <th>Forma de pago</th>
                <th>Metodo de pago</th>
                <th>Total Recibido</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {regimeIncomes.length === 0 ?
                (<tr>
                  <td colSpan="8">
                    <div className="alert alert-primary text-center mb-0" role="alert">
                      No se ha agregado información
                    </div>
                  </td>
                </tr>) :
                (
                  regimeIncomes.map((income, index) => {
                    return (
                      <tr key={index} className="text-center">
                        <td>
                          {  !autorizedReport && status !== 'Presentada' && (
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name="typeOwner"
                                defaultChecked={ income.authorized }
                                onChange={ changeConsideredIncome.bind(this, income) }
                                disabled={ autorizedReport }
                              />
                            </div>)
                          }   
                        </td>
                        <td>{income.uuid}</td>
                        <td>{income.stampAt}</td>
                        <td>{income.paymentAt}</td>
                        <td>
                          {income.receivedRfc}
                          <br />
                          {income.receivedFullName}
                        </td>
                        <td>
                          <TextField
                            label="Concepto" variant="outlined" size="small" autoComplete="off" fullWidth={true}
                            select
                            SelectProps={{
                              native: true,
                            }}
                            readOnly
                          >
                            {
                              income.concepts.map((concept, i) => {
                                return (
                                  <option
                                    key={i}
                                  >
                                    { concept.claveProdServ ? `${concept.claveProdServ} - ` : ''}
                                    { concept.descripcion}
                                  </option>
                                )
                              })
                            }
                          </TextField>
                        </td>
                        <td>{income.folio}</td>
                        <td><strong>{income.wayOfPayment.description}</strong></td>
                        <td><strong>{income.paymentMethod.description}</strong></td>
                        <td>
                          <NumberFormat value={parseFloat(income.total).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} />                          
                        </td>
                        <td>
                          {
                            !autorizedReport && status !== 'Presentada' &&(
                              <button className="btn btn-warning"
                                onClick={() => handleModal(
                                  <IncomeForm
                                    regimeSatCode={regimeSatCode}
                                    taxpayerId={taxpayerId}
                                    month={month}
                                    year={year}
                                    incomeEdit={income}
                                  />, true)}
                              >
                                <FontAwesomeIcon className="fas" icon={"edit"}/>
                              </button>
                            )
                          }
                        </td>
                      </tr>
                    )
                  })
                )
              }
            </tbody>
          </table>
        </div>
      </div>
      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2">
        <nav>
          <div className="nav nav-tabs" id="nav-tab" role="tablist">
            <a className="nav-item nav-link active" id="nav-consideredincome-tab" data-toggle="tab" href="#nav-consideredincome" role="tab" aria-controls="nav-consideredincome" aria-selected="true">
              <strong>Ingresos Considerados</strong> - <span className="badge badge-success"> $ { subtotalIncomesAutorized }</span>
            </a>
            <a className="nav-item nav-link" id="nav-incomenotconsidered-tab" data-toggle="tab" href="#nav-incomenotconsidered" role="tab" aria-controls="nav-incomenotconsidered" aria-selected="true">
              <strong>Ingresos No Considerados</strong> - <span className="badge badge-warning"> $ { totalIncomesNotAutorized }</span>
            </a>
            <a className="nav-item nav-link" id="nav-canceledincome-tab" data-toggle="tab" href="#nav-canceledincome" role="tab" aria-controls="nav-canceledincome" aria-selected="true">
              <strong>Ingresos Cancelados</strong>
            </a>
          </div>
        </nav>
      </div>
      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2">
        <div className="tab-content" id="nav-tabContent">
          <div className="tab-pane fade show active" id="nav-consideredincome" role="tabpanel" aria-labelledby="nav-consideredincome-tab">
            <ConsideredIncome
              autorizedReport={autorizedReport}
              consideredIncomes={consideredIncomes}
              formValuesDetailsSummary={formValuesDetailsSummary}
              setFormValuesDetailsSummary={setFormValuesDetailsSummary}
            />
          </div>
          <div className="tab-pane fade" id="nav-incomenotconsidered" role="tabpanel" aria-labelledby="nav-incomenotconsidered-tab">
            <IncomeNotConsidered
              notConsideredIncomes={ notConsideredIncomes }
              setFormValuesDetailsSummary={ setFormValuesDetailsSummary }
            />
          </div>
          <div className="tab-pane fade" id="nav-canceledincome" role="tabpanel" aria-labelledby="nav-canceledincome-tab">
            <CanceledIncome
              canceledIncomes={ canceledIncomes }
              setFormValuesDetailsSummary={ setFormValuesDetailsSummary }
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Income;
