import { fetchWithApiKey } from "../../helpers/fetch";
import { types } from "../../types/types";

export const paymentStatusStartLoading = () => {
  return async(dispatch) => {
    const resp = await fetchWithApiKey('api/Catalogs/c_PaymentStatus');
    const body = await resp.json();
    if (body.success){
      dispatch( paymentStatusLoaded( body.data ) )
    }
  }
}

const paymentStatusLoaded = (statusTypes) => ({
  type: types.paymentStatusStartLoading,
  payload: statusTypes
})