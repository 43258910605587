import React, { useEffect, useState } from 'react';
import { TableBody } from '@material-ui/core';
import { Table, TableCell, TableHead, TableContainer, TableRow, TextField } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { opinionComplement } from '../../../../actions/opinionComplement/opinionComplement';

const ObtenerRFCDesdeURL = () => {
    const location = useLocation();
    const { pathname } = location;
    const lastSlashIndex = pathname.lastIndexOf('/');

    const valoresDespuesDeLaBarra = lastSlashIndex !== -1 ? pathname.substring(lastSlashIndex + 1) : null;
    return (valoresDespuesDeLaBarra);
};

const ConfiguracionCatalogos2 = ({ }) => {
    const { opinion } = useSelector(state => state.opinionComplement);
    const [selectedMonth, setSelectedMonth] = useState('');
    const rfc = ObtenerRFCDesdeURL();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(opinionComplement(rfc))
    }, [rfc]);

    // Filtrar opiniones y obligaciones por mes seleccionado
    const filteredOpinions = opinion.filter(item => item.opinions === selectedMonth);
    //console.log(filteredOpinions)
    const filteredObligations = filteredOpinions.length > 0 ? filteredOpinions[0].obligations : [];
    //console.log(filteredObligations)

    return (
        <div>
            <div className='mt-2 ml-4'>
                <div className='row d-flex justify-content-start'>
                    <div className='col-12'>
                        <h4>Opiniones de cumplimiento</h4>
                    </div>
                </div>
            </div>
            <div className='row mt-4'>
                <div className='col-md-3' style={{ marginLeft: '40px' }}>
                    <div >
                        <h5>Listado de Opiniones</h5>
                    </div>
                    <div>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center"><strong>Mes</strong></TableCell>
                                        <TableCell align="center"><strong>URL</strong></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {opinion.map((opinion, index) => (
                                        <TableRow key={index}>
                                            <TableCell align="center">
                                                <h6>{opinion.opinions}</h6>
                                            </TableCell>
                                            <TableCell align="center">
                                                <a href={opinion.urlPDF} style={{ textDecoration: 'none', color: 'inherit' }}>
                                                    <button style={{ padding: '10px 20px', backgroundColor: '#4CAF50', color: 'white', border: 'none', borderRadius: '5px', cursor: 'pointer' }}>
                                                        Ir al PDF
                                                    </button>
                                                </a>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                </div>
                <div className='col-md-8'>
                    <div>
                        <h5>Listado de Obligaciones</h5>
                    </div>
                    <div>
                        <select
                            value={selectedMonth}
                            onChange={e => setSelectedMonth(e.target.value)}
                            style={{
                                padding: '8px 16px',
                                fontSize: '16px',
                                borderRadius: '5px',
                                border: '1px solid #ccc',
                                outline: 'none',
                                width: '200px'
                            }}
                        >
                            <option value="">Seleccionar mes</option>
                            {opinion.map((item, index) => (
                                <option key={index} value={item.opinions}>{item.opinions}</option>
                            ))}
                        </select>

                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center"><strong>Obligación</strong></TableCell>
                                        <TableCell align="center"><strong>Períodos</strong></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {filteredObligations.map((obligation, index) => (
                                        <TableRow key={index}>
                                            <TableCell align="center">
                                                <h6>{obligation.obligation}</h6>
                                            </TableCell>
                                            <TableCell align="center">
                                                <div style={{ display: 'grid', gridTemplateColumns: 'repeat(5, 1fr)', gap: '8px', maxHeight: '100px', overflowY: 'auto' }}>
                                                    {obligation.periods.map((period, index) => (
                                                        <div key={index} style={{ padding: '4px', border: '1px solid #ccc', borderRadius: '4px', margin: '2px' }}>
                                                            {period}
                                                        </div>
                                                    ))}
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ConfiguracionCatalogos2;