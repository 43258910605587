import { types } from "../../types/types";

const initialState = {
  paymentGateways: []
}

export const paymentGatewayReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.paymentGatewayStartLoading:
      return{
        ...state,
        paymentGateways: [ ...action.payload ]
      }

    default:
      return state;
  }
}
