import React, { Fragment, useEffect } from 'react';
import { InputAdornment, TextField } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import { useSelector } from 'react-redux';

export const LeaseIvaCalculation = ({ formValuesDetailsSummary, setFormValuesDetailsSummary, autorizedReport, status }) => {

  const { authorizationReport, authorizations } = useSelector(state => state.authorizationReport);
  const { authorizationPrev } = useSelector(state => state.authorizationReport);

  const { taxIVALease, totalIVAExpenseAutorized, totalIVARetainedIncomeAutorized, totalIVATraslatedIncomeAutorized } = formValuesDetailsSummary;

  const { IVAMonthsPrev, subtotalIvaLease, totalIVA, totalIVAExpense, totalIvaTraslated, totalRateinedIncomeIVA } = taxIVALease;

  const ivaTotal = authorizations.paymentComplementExpensesIva || authorizationReport.paymentComplementExpensesIva || 0
  const ivaExpenseTotalAcreditable = parseFloat(totalIVAExpense) + parseFloat(ivaTotal)

  useEffect(() => {
    const totalIvaCaused = totalIVATraslatedIncomeAutorized;

    const subtotalIvaLease = (parseFloat(totalIvaCaused) - parseFloat(totalIVARetainedIncomeAutorized) - parseFloat(totalIVAExpenseAutorized)).toFixed(6)
    setFormValuesDetailsSummary(prevState => {
      return {
        ...prevState,
        taxIVALease: {
          ...prevState.taxIVALease,
          totalRateinedIncomeIVA: totalIVARetainedIncomeAutorized,
          totalIVAExpense: totalIVAExpenseAutorized,
          totalIvaTraslated: totalIvaCaused,
          subtotalIvaLease: subtotalIvaLease
        }
      }
    })
  }, [setFormValuesDetailsSummary, totalIVARetainedIncomeAutorized, totalIVAExpenseAutorized, totalIVATraslatedIncomeAutorized])

  useEffect(() => {
    let ivaPrev = parseFloat(IVAMonthsPrev);
    let totalIvaFinal = 0.00;
    let subTotalIvaFinal = 0.00

    if (Object.keys(authorizationPrev).length > 0) {
      const iva = JSON.parse(authorizationPrev.ivaJson);
      if (Object.keys(iva).length > 0) {
        if (parseFloat(iva.totalIVA) < 0) {
          if (parseFloat(IVAMonthsPrev) !== (parseFloat(iva.totalIva) * -1) && parseFloat(IVAMonthsPrev) === 0) {
            ivaPrev = parseFloat(iva.totalIVA) * -1;
          }
        }
      }
    }
    //   //console.log('IVAMonthsPrev',IVAMonthsPrev)
    // //console.log('subtotalIvaLease',subtotalIvaLease)
    // //console.log('totalIVA',totalIVA)
    // //console.log('totalIVAExpense',totalIVAExpense)
    // //console.log('totalIvaTraslated',totalIvaTraslated)
    // //console.log('totalRateinedIncomeIVA',totalRateinedIncomeIVA)
    // //console.log('ivaExpenseTotalAcreditable',ivaExpenseTotalAcreditable)

    totalIvaFinal = (parseFloat(totalIvaTraslated) - parseFloat(totalRateinedIncomeIVA) - parseFloat(ivaExpenseTotalAcreditable) + (ivaPrev * -1)).toFixed(2);
    // totalIvaFinal = (parseFloat(subtotalIvaLease) + (ivaPrev * -1)).toFixed(2);
    isNaN(totalIvaFinal) && (totalIvaFinal = 0)
    isNaN(ivaPrev) && (ivaPrev = 0)

    let newIvaPrevs = ivaPrev * 1;
    setFormValuesDetailsSummary(prevState => {
      return {
        ...prevState,
        taxIVALease: {
          ...prevState.taxIVALease,
          totalIVA: totalIvaFinal,
          IVAMonthsPrev: newIvaPrevs
        }
      }
    });
  }, [authorizationPrev, IVAMonthsPrev, setFormValuesDetailsSummary, subtotalIvaLease, authorizations]);

  const handleInputChangeTaxIva = (e) => {
    const { name, value } = e.target;
    setFormValuesDetailsSummary(prevState => {
      return {
        ...prevState,
        taxIVALease: {
          ...prevState.taxIVALease,
          [name]: value
        }
      }
    })
  }

  return (
    <Fragment>
      <tr>
        <td className="w-50">IVA Causado</td>
        <td className="w-25"><NumberFormat value={parseFloat(totalIvaTraslated).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td>
      </tr>
      <tr>
        <td className="w-50">IVA Retenido</td>
        <td className="w-25"><NumberFormat value={parseFloat(totalRateinedIncomeIVA).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td>
      </tr>
      <tr>
        <td className="w-50">IVA Acreditable</td>
        <td className="w-25"><NumberFormat value={parseFloat(ivaExpenseTotalAcreditable).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td>
      </tr>
      <tr>
        <td className="w-50">IVA a favor de periodos anteriores</td>
        {/*  <td className="w-25"><NumberFormat value={parseFloat(IVAMonthsPrev).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td> */}
        <td className="w-25">
          {
            status === 'Presentada' ?
              (<TextField
                variant="outlined"
                size="small"
                name="IVAMonthsPrev"
                value={IVAMonthsPrev}
                onChange={handleInputChangeTaxIva}
                autoComplete="off"
                fullWidth={true}
                InputProps={{
                  startAdornment: (<InputAdornment position="start">$</InputAdornment>),
                }}
                disabled={true}
              />)
              :
              (<TextField
                variant="outlined"
                size="small"
                name="IVAMonthsPrev"
                value={IVAMonthsPrev}
                onChange={handleInputChangeTaxIva}
                autoComplete="off"
                fullWidth={true}
                InputProps={{
                  startAdornment: (<InputAdornment position="start">$</InputAdornment>),
                }}
                disabled={autorizedReport}
              />)
          }
        </td>
      </tr>
      {parseFloat(totalIVA) > 0 ?
        (
          <tr>
            <td className="w-50">IVA a pagar</td>
            <td className="w-25"><NumberFormat value={parseFloat(totalIVA).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td>
          </tr>
        )
        :
        (
          <tr>
            <td className="w-50">IVA a Favor</td>
            <td className="w-25"><NumberFormat value={(parseFloat(totalIVA) * -1).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td>
          </tr>
        )
      }
    </Fragment>
  )
}
