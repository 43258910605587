import React, { useState } from 'react'
import swal from "sweetalert";
import { useDispatch, useSelector } from 'react-redux';
import BusinessDetails from './BusinessDetails';
import Concepts from './Concepts';
import Receiver from './Receiver';
import Transmitter from './Transmitter';
import { useEffect } from 'react';
import { BillingActivation } from './BillingActivation';
import { customersBilling, productsBilling, recordInvoices } from '../../../../actions/billing/catalogs';
import { useForm } from '../../../../hooks/useForm';
import { startRegisterBill } from '../../../../actions/billing/invoiceActivateBilling';


export const Billing = () => {

    //Invoices/ Facturacion
    const { user } = useSelector(state => state.auth);
    const { bills } = useSelector(state => state.registerBills)
    const { invoicesActivation } = useSelector(state => state.invoiceBilling)

    const [formValid, setFormValid] = useState(false);
    const [currentTab, setCurrentTab] = useState('nav-transmitter');

    const { formValues, setFormValues } = useForm({
        "CFDI": "40",
        "GenerateAmount": false,
        "WhoEmit": false,
        "Transmitter": {
            "BusinessName": "",
            "Rfc": "",
            "KeyRegime": "",
            "PostalCode": "",
            "Emails": ""
        },
        "Receiver": {
            "BusinessName": "",
            "Rfc": "",
            "KeyUseCfdi": "",
            "KeyRegime": "",
            "PostalCode": "",
            "Emails": "",
            "Address": ""
        },
        "Details": {
            "Serie": "",
            "Folio": "",
            "Currency": "",
            "KeyWayOfPayment": "99",
            "KeyPaymentMethod": "",
            "Change": 0.0,
            "WithRelationship": "",
            "CfdiRelated": null
        },
        "Imports": {
            "SubTotal": 0.0,
            "Discount": 0.0,
            "IvaTraslated": 0.0,
            "Total": 0.0
        },
        "Concepts": [],
        "ComplementType": 0,
        "DocumentAt": "0001-01-01T00:00:00"
    })

    const dispatch = useDispatch();

    const [formCompletion, setformCompletion] = useState({
        trasmitter: false,
        receiver: false,
        businessDetails: false,
        concepts: false
    })

    const handleNextTab = (  ) => {
        if (currentTab === 'nav-transmitter') {
          setCurrentTab('nav-receiver');
        } else if (currentTab === 'nav-receiver') {
          setCurrentTab('nav-business_details');
        } else if (currentTab === 'nav-business_details') {
          setCurrentTab('nav-concepts');
        }
    };

    useEffect(() => {
        dispatch(recordInvoices( user.id ));
    }, [])

    const handleBilling = (e) => {
        e.preventDefault();
        setFormValid(true);

        const allValuesTrue = Object.values(formCompletion).every(value => value === true);

        if (allValuesTrue) {
            const appKey = invoicesActivation.data.appKey;
            dispatch(startRegisterBill(formValues, appKey));
        } else {
            swal('Error', 'No estan completos todos los campos obligatorios', 'error');
        }
      }

  return (
    <>

        {
            ( invoicesActivation.data ) ? (
                <>
                    <div className="tab-content shadow-lg p-3 bg-white rounded" id="nav-tabContent">
                        <div>
                            <h5> Saldo de Folios: { invoicesActivation.data.folios} </h5>
                        </div>
                        {
                            (!invoicesActivation.data.completeData) ? (
                                <div>
                                    <h6 className='text-danger'> Auún no puedes realizar tu factura por falta de los <b> Sellos Fiscales. </b> Dirigete a la pestaña de <b>Administración</b> para subirlos.  </h6>
                                </div>
                            )
                            :
                            (
                                ''
                            )
                        }
                    </div>

                    <nav className="mt-2">
                        <div className="nav nav-tabs" id="nav-tab" role="tablist">
                        <button
                            className={`nav-item nav-link ${currentTab === 'nav-transmitter' ? 'active' : ''}`}
                            onClick={() => setCurrentTab('nav-transmitter')}
                        >
                            Información Emisor <i className={`fas ${formCompletion.trasmitter === true ? 'fa-check text-success' : 'fa-solid fa-exclamation text-warning'}`}></i>
                        </button>
                        <button
                            className={`nav-item nav-link ${currentTab === 'nav-receiver' ? 'active' : ''}`}
                            onClick={() => setCurrentTab('nav-receiver')}
                        >
                            Información Receptor  <i className={`fas ${formCompletion.receiver === true ? 'fa-check text-success' : 'fa-solid fa-exclamation text-warning'}`}></i>
                        </button>
                        <button
                            className={`nav-item nav-link ${currentTab === 'nav-business_details' ? 'active' : ''}`}
                            onClick={() => setCurrentTab('nav-business_details')}
                        >
                            Detalles Comerciales  <i className={`fas ${formCompletion.businessDetails === true ? 'fa-check text-success' : 'fa-solid fa-exclamation text-warning'}`}></i>
                        </button>
                        <button
                            className={`nav-item nav-link ${currentTab === 'nav-concepts' ? 'active' : ''}`}
                            onClick={() => setCurrentTab('nav-concepts')}
                        >
                            Conceptos  <i className={`fas ${formCompletion.concepts === true ? 'fa-check text-success' : 'fa-solid fa-exclamation text-warning'}`}></i>
                        </button>
                        </div>
                    </nav>
                    <div className="tab-content shadow-lg p-3 mb-5 bg-white rounded" id="nav-tabContent">
                        <div className={`tab-pane fade ${currentTab === 'nav-transmitter' ? 'show active' : ''}`} id="nav-transmitter" role="tabpanel" aria-labelledby="nav-transmitter-tab">
                            <Transmitter setformCompletion={ setformCompletion } formCompletion={ formCompletion } setFormDetails={setFormValues} formValid={formValid} />
                            <div className="d-flex justify-content-end mr-3">
                                <button
                                    className='btn btn-primary'
                                    onClick={handleNextTab}
                                >
                                    RECEPTOR <i className='fas fa-angle-right' ></i>
                                </button>
                            </div>
                        </div>
                        <div className={`tab-pane fade ${currentTab === 'nav-receiver' ? 'show active' : ''}`} id="nav-receiver" role="tabpanel" aria-labelledby="nav-receiver-tab">
                            <Receiver  setformCompletion={ setformCompletion } formCompletion={ formCompletion } setFormDetails={setFormValues} formValid={formValid} />
                            <div className="d-flex justify-content-end mr-3">
                                <button
                                    className='btn btn-primary'
                                    onClick={handleNextTab}
                                >
                                    DETALLES <i className='fas fa-angle-right' ></i>
                                </button>
                            </div>
                        </div>
                        <div className={`tab-pane fade ${currentTab === 'nav-business_details' ? 'show active' : ''}`} id="nav-business_details" role="tabpanel" aria-labelledby="nav-business_details-tab">
                            <BusinessDetails  setformCompletion={ setformCompletion } formCompletion={ formCompletion } setFormDetails={setFormValues} formValid={formValid} />
                            <div className="d-flex justify-content-end mr-3">
                                <button
                                    className='btn btn-primary'
                                    onClick={handleNextTab}
                                >
                                    CONCEPTOS <i className='fas fa-angle-right' ></i>
                                </button>
                            </div>
                        </div>
                        <div className={`tab-pane fade ${currentTab === 'nav-concepts' ? 'show active' : ''}`} id="nav-concepts" role="tabpanel" aria-labelledby="nav-concepts-tab">
                            <Concepts  setformCompletion={ setformCompletion } formCompletion={ formCompletion } setFormDetails={setFormValues} formValid={formValid} />
                        </div>
                        <div className='row'>
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2">
                                <div className="d-flex justify-content-end mt-4 mr-3">
                                    <button
                                        className='btn btn-secondary mr-2'
                                        disabled={ !invoicesActivation.data.completeData }
                                    >
                                        GUARDAR <i className='fas fa-check' ></i>
                                    </button>
                                    <button
                                        className='btn btn-success'
                                        onClick={ handleBilling }
                                        disabled={ !invoicesActivation.data.completeData }
                                    >
                                        FACTURAR <i className='fas fa-paper-plane' ></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )
            :
            <BillingActivation />
        }

    </>
  )
}
