import { types } from "../../types/types";

const initialState = {
  professionTypes:[]
}

export const professionTypeReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.professionsStartLoading:
      return{
        ...state,
        professionTypes:[...action.payload]
      }
  
    default:
      return state;
  }
}
