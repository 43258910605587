import React, { Fragment, useEffect } from 'react';
import { InputAdornment, TextField } from '@material-ui/core';
import { useSelector } from 'react-redux';
import NumberFormat from 'react-number-format';

export const RifIvaCalculation = React.memo(({ formValuesDetailsSummary, setFormValuesDetailsSummary, autorizedReport, status }) => {
  
  const { authorizationReport, authorizations } = useSelector(state => state.authorizationReport);
  const { authorizationPrev } = useSelector(state => state.authorizationReport);
  const { taxpayerSelected } = useSelector(state => state.taxpayer)
  const { reduccionIsrRifs } = useSelector(state => state.reduccionIsrRif)

  const { subtotalIncomesGeneralPublic, subtotalIncomesRate0, subtotalIncomesRate16,
          subtotalExpenseRate0, subtotalExpenseRate8, subtotalExpenseRate16, subtotalSumRateExento,
          taxIVARIF, totalIVARetainedIncomeAutorized, totalIvaTraslatedExpense16, totalIvaTraslatedExpense8 , year , month} = formValuesDetailsSummary;

  const { deductionIsr, IVAMonthsPrev, ivaReduced, ivaCharged, ivaCreditable, ivaPublicGeneral, ivaRetained, ivaToCharge,
          subtotalExpense0, subtotalExpense16, subtotalExpenseForeign, subtotalIncomes0, subtotalIncomes16,
          subtotalIncomesExento, subtotalIncomesPublicGeneral, totalIva, totalLIVA, totalTax8Expense, totalTax16Expense } = taxIVARIF;

  const ivaTotal = authorizations.paymentComplementExpensesIva || authorizationReport.paymentComplementExpensesIva || 0
  const totalIVA = parseFloat(ivaCreditable) + parseFloat(ivaTotal) 
  
  useEffect(() => {
    let ivaRetained = totalIVARetainedIncomeAutorized;

    let subtotalIncomes0 = subtotalIncomesRate0;
    let subtotalIncomes16 = subtotalIncomesRate16;
    let subtotalIncomesPublicGeneral = subtotalIncomesGeneralPublic;
    let subtotalIncomesExento = subtotalSumRateExento;

    let subtotalExpense0 = subtotalExpenseRate0;
    let subtotalExpense16 = subtotalExpenseRate16;
    let subtotalExpenseForeign = subtotalExpenseRate8;

    let totalTax16Expense = totalIvaTraslatedExpense16;
    let totalTax8Expense = totalIvaTraslatedExpense8;

    const ivaCharged = (parseFloat(subtotalIncomes16) * .16).toFixed(2);
    
    const ivaPublicGeneral = (parseFloat(subtotalIncomesGeneralPublic) * .16 / 2).toFixed(2);
    
    setFormValuesDetailsSummary(prevState => {
      return{
        ...prevState,
        taxIVARIF:{
          ...prevState.taxIVARIF,
          ivaCharged: ivaCharged,
          ivaPublicGeneral: ivaPublicGeneral,
          ivaRetained: ivaRetained,
          subtotalExpense0: subtotalExpense0,
          subtotalExpense16: subtotalExpense16,
          subtotalExpenseForeign: subtotalExpenseForeign,
          subtotalIncomes0: subtotalIncomes0,
          subtotalIncomes16: subtotalIncomes16,
          subtotalIncomesExento: subtotalIncomesExento,
          subtotalIncomesPublicGeneral: subtotalIncomesPublicGeneral,
          totalTax8Expense: totalTax8Expense,
          totalTax16Expense: totalTax16Expense
        }
      }
    })
  }, [setFormValuesDetailsSummary, subtotalExpenseRate0, subtotalExpenseRate16, subtotalExpenseRate8,
      subtotalIncomesRate0, subtotalIncomesRate16, subtotalIncomesGeneralPublic, subtotalSumRateExento,
      totalIVARetainedIncomeAutorized, totalIvaTraslatedExpense16, totalIvaTraslatedExpense8]);

  useEffect(() => {
    if(Object.keys(taxpayerSelected).length !== 0){
      let numberYear = 1;
      const taxpayerSatDateAt = new Date(taxpayerSelected.startSatAt);
      if (taxpayerSatDateAt.getFullYear() < year) {
        if( parseInt(taxpayerSatDateAt.getMonth() + 1) <= month ){
          numberYear = (parseInt(year)+parseInt(1)) - taxpayerSatDateAt.getFullYear()
        }else{
          numberYear = (year) - taxpayerSatDateAt.getFullYear()
        }
      }
      if(numberYear >= 11 ){
        numberYear=11
      }
      const deductionIsr = reduccionIsrRifs.filter(e => (e.yearNumber === parseInt(numberYear)))[0];
      const ivaReduced = deductionIsr.porcentage * ivaPublicGeneral;
      
      setFormValuesDetailsSummary(prevState => {
        return{
          ...prevState,
          taxIVARIF: {
            ...prevState.taxIVARIF,
            deductionIsr: deductionIsr.porcentage,
            ivaReduced: ivaReduced
          }
        }
      })
    }
  }, [ivaPublicGeneral, reduccionIsrRifs, setFormValuesDetailsSummary, taxpayerSelected])

  useEffect(() => {
    let ivaCreditable = 0;
    let ivaToCharge = 0;
    let paymentReduce = parseFloat(ivaPublicGeneral) - parseFloat(ivaReduced);
    if (parseFloat(subtotalIncomes16) > 0 || parseFloat(subtotalIncomes0) > 0 || parseFloat(subtotalIncomesPublicGeneral) > 0 ) {
      let totalIncomes = (parseFloat(subtotalIncomes16) + parseFloat(subtotalIncomes0) + parseFloat(subtotalIncomesPublicGeneral));
      let porcentageIncomes16 = ((parseFloat(subtotalIncomes16) * 100) / parseFloat(totalIncomes))
      let porcentageIncomes0 = ((parseFloat(subtotalIncomes0) * 100) / parseFloat(totalIncomes))
      
      if (isNaN(porcentageIncomes16)) {
        porcentageIncomes16 = 0
      }
  
      if (isNaN(porcentageIncomes0)) {
        porcentageIncomes0 = 0
      }
  
      let totalPorcentage = ((parseFloat(porcentageIncomes16) + parseFloat(porcentageIncomes0)) / 100);
      ivaCreditable = (parseFloat(totalTax16Expense) * parseFloat(totalPorcentage) + (parseFloat(totalTax8Expense) * parseFloat(totalPorcentage)))
      ivaToCharge = (parseFloat(ivaCharged) - parseFloat(ivaCreditable))+parseFloat(paymentReduce)
    }else{
      ivaCreditable = (parseFloat(subtotalExpense16) * 0.16 + (parseFloat(subtotalExpenseForeign) * 0.08))
      ivaToCharge = (parseFloat(ivaCharged) - parseFloat(ivaCreditable))+parseFloat(paymentReduce)
    }
    setFormValuesDetailsSummary(prevState => {
      return{
        ...prevState,
        taxIVARIF: {
          ...prevState.taxIVARIF,
          ivaCreditable: ivaCreditable,
          ivaToCharge: ivaToCharge
        }
      }
    })
  }, [ivaCharged, setFormValuesDetailsSummary, subtotalIncomes0, subtotalIncomes16, subtotalIncomesPublicGeneral, subtotalIncomesExento, totalTax8Expense, totalTax16Expense]);

  useEffect(() => {
    let ivaPrev = parseFloat(IVAMonthsPrev);
    let totalIvaFinal = 0.00;

    if (Object.keys(authorizationPrev).length > 0) {
      const iva = JSON.parse(authorizationPrev.ivaJson);
      if (Object.keys(iva).length > 0) {
        if (parseFloat(iva.totalIva) < 0) {
          if (parseFloat(IVAMonthsPrev) !== (parseFloat(iva.totalIva) * -1) && parseFloat(IVAMonthsPrev) === 0) {
          ivaPrev = parseFloat(iva.totalIva) * -1;
          }
        }
      }
    }
    totalIvaFinal = (parseFloat(ivaToCharge) + (ivaPrev * -1)).toFixed(2);

    isNaN(totalIvaFinal) && (totalIvaFinal = 0)
    isNaN(ivaPrev) && (ivaPrev = 0)

    let newIvaPrevs = ivaPrev * 1;
    setFormValuesDetailsSummary(prevState => {
      return{
        ...prevState,
        taxIVARIF:{
          ...prevState.taxIVARIF,
          totalIva: totalIvaFinal,
          IVAMonthsPrev: newIvaPrevs
        }
      }
    });
  }, [authorizationPrev, IVAMonthsPrev, setFormValuesDetailsSummary, ivaToCharge]);

  const handleInputChangeTaxIva = (e) => {
    const { name, value } = e.target;
    setFormValuesDetailsSummary(prevState => {
      return{
        ...prevState,
        taxIVARIF: {
          ...prevState.taxIVARIF,
          [name]: value
        }
      }
    })
  }

  return (
    <Fragment>
      <tr>
        <td className="w-50">Ingresos por ventas al público en general</td>
        <td className="w-25"><NumberFormat value={parseFloat(subtotalIncomesPublicGeneral).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td>
      </tr>
      <tr>
        <td className="w-50">Ingresos facturados a la tasa del 16%</td>
        <td className="w-25"><NumberFormat value={parseFloat(subtotalIncomes16).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td>
      </tr>
      <tr>
        <td className="w-50">Ingresos facturados a la tasa del 0%</td>
        <td className="w-25"><NumberFormat value={parseFloat(subtotalIncomes0).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td>
      </tr>
      <tr>
        <td className="w-50">Ingresos Exentos</td>
        <td className="w-25"><NumberFormat value={parseFloat(subtotalIncomesExento).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td>
      </tr>
      <tr>
        <td className="w-50">Compras y gastos pagados con tasa 16%</td>
        <td className="w-25"><NumberFormat value={parseFloat(subtotalExpense16).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td>
      </tr>
      <tr>
        <td className="w-50">Compras y gastos pagados en la región fronteriza</td>
        <td className="w-25"><NumberFormat value={parseFloat(subtotalExpenseForeign).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td>
      </tr>
      <tr>
        <td className="w-50">Compras y gastos pagados a la tasa del 0%</td>
        <td className="w-25"><NumberFormat value={parseFloat(subtotalExpense0).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td>
      </tr>
      <tr>
        <td className="w-50">Proporcion utilizada conforme a la LIVA</td>
        <td className="w-25"><NumberFormat value={parseFloat(totalLIVA).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td>
      </tr>
      <tr>
        <td className="w-50">IVA retenido</td>
        <td className="w-25"><NumberFormat value={parseFloat(ivaRetained).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td>
      </tr>
      <tr>
        <td className="w-50">IVA por público en general</td>
        <td className="w-25"><NumberFormat value={parseFloat(ivaPublicGeneral).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td>
      </tr>

      <tr>
        <td className="w-50">Porcentaje reducción</td>
        <td className="w-25"><NumberFormat value={parseFloat(deductionIsr * 100).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'%'} /></td>
      </tr>
      <tr>
        <td className="w-50">Reducción</td>
        <td className="w-25"><NumberFormat value={parseFloat(ivaReduced).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td>
      </tr>
      <tr>
        <td className="w-50">IVA cobrado</td>
        <td className="w-25"><NumberFormat value={parseFloat(ivaCharged).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td>
      </tr>

      <tr>
        <td className="w-50">IVA acreditable</td>
        <td className="w-25"><NumberFormat value={parseFloat(totalIVA).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td>
      </tr>
      <tr>
        <td className="w-50">IVA a cargo</td>
        <td className="w-25"><NumberFormat value={parseFloat(ivaToCharge).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td>
      </tr>
      <tr>
        <td className="w-50">IVA a favor de periodos anteriores</td>
        {/* <td className="w-25"><NumberFormat value={parseFloat(IVAMonthsPrev).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td> */}
        <td className="w-50">
          {
            status === 'Presentada' ?
            (<TextField
              variant="outlined"
              size="small"
              name="IVAMonthsPrev"
              value={IVAMonthsPrev}
              onChange={handleInputChangeTaxIva}
              autoComplete="off"
              fullWidth={true}
              InputProps={{
                startAdornment: (<InputAdornment position="start">$</InputAdornment>),
              }}
              disabled={true}
            />)
            :
            (<TextField
              variant="outlined"
              size="small"
              name="IVAMonthsPrev"
              value={IVAMonthsPrev}
              onChange={handleInputChangeTaxIva}
              autoComplete="off"
              fullWidth={true}
              InputProps={{
                startAdornment: (<InputAdornment position="start">$</InputAdornment>),
              }}
              disabled={autorizedReport}
            />)
          }
        </td>
      </tr>

      { parseFloat(totalIva) > 0 ? 
        (
          <tr>
            <td className="w-50">IVA a pagar</td>
            <td className="w-25"><NumberFormat value={parseFloat(totalIva).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td>
          </tr>
        )
        :
        (
          <tr>
            <td className="w-50">IVA a Favor</td>
            <td className="w-25"><NumberFormat value={(parseFloat(totalIva) * -1).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td>
          </tr>
        )
      }
    </Fragment>
  )
}
)