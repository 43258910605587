import React, { useContext, useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { TableBody } from '@material-ui/core';
import { Table, TableCell, TableHead, TableContainer, TableRow, TextField } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';

import { VIEW_CONFIG_ACTIVITIES, VIEW_CONFIG_CLASSIFY } from '../../../../../constants/Routes';
import { ModalContext } from '../../../../../modal/ModalContext';
import NewActivityByRegimenModal from '../modals/NewActivityByRegimenModal/NewActivityByRegimenModal';
import { getActivitiesByRegime } from '../../../../../../actions/catalogs/configurationCatalogs';
import { loadingModal, loadingModalMsn } from '../../../../../../helpers/UseSwal';
import { reclassifyData } from '../../../../../../actions/configuration/reclasify';
import { makeStyles } from '@material-ui/core/styles';
import { useForm } from '../../../../../../hooks/useForm';
import ReclassifyModal from '../modals/ReclassifyModal/ReclassifyModal';

const useStyles = makeStyles({
	circuloRojo: {
		width: '30px',
		height: '30px',
		borderRadius: '50%',
		backgroundColor: 'red',
		color: 'white',
		display: 'flex',
		placeItems: 'center',
		alignItems: 'center',
		justifyContent: 'center',
		fontSize: '14px',
	},
});

const ConfiguracionActividades = ({ }) => {

	const { handleModal } = useContext(ModalContext);
	const { activities } = useSelector(state => state.activitiesByRegimen);

	const dispatch = useDispatch();

	let params = useParams();

	useEffect(() => {
		loadingModal(true);
		dispatch(getActivitiesByRegime({ satCode: params.idRegimen }));
	}, [dispatch, getActivitiesByRegime, params.idRegimen]);

	const reclassifyAct = (idAct, idRegimen) => {
		loadingModalMsn(true);
		dispatch(reclassifyData({ regimenId: idRegimen, activityId: idAct }));
	}

	const classes = useStyles();

	return (
		<div>
			<div className='col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12'>
				<div className='mt-2 ml-4'>
					<div className='row d-flex justify-content-start'>
						<div className='col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12'>
							<h4>
								Configuración de catálogos
							</h4>
						</div>
					</div>
				</div>
				<div className='col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-4'>
					<div className='card'>
						<div className='card-body'>
							<div className='row'>
								<div className='col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12'>
									<div className='row d-flex justify-content-between'>
										<div className="col-md-10">
											<h5>Listado de Actividades</h5>
										</div>
										<div className='col-md-2 float-right d-flex justify-content-end'>
											<button className='btn btn-sm btn-primary d-flex align-items-center'
												data-toggle="tooltip" data-placement="top" title=" Nueva actividad"
												onClick={() => handleModal(<NewActivityByRegimenModal idRegimen={params.idRegimen} />)}>
												<h6 style={{ margin: 0 }}>
													<i className="fas fa-plus" style={{ marginRight: '5px' }}></i> Catálogo
												</h6>
											</button>
										</div>

									</div>
								</div>

								<div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-5">
									<TableContainer>
										<Table>
											<TableHead>
												<TableRow>
													<TableCell align="center">
														<strong>ID</strong>
													</TableCell>
													<TableCell align="center">
														<strong>Actividad</strong>
													</TableCell>
													<TableCell align="center">
														<strong>Profesión</strong>
													</TableCell>
													<TableCell align="center">
														<strong>Total de Nuevas Claves de Producto Registradas en Automático</strong>
													</TableCell>
													<TableCell align="center">
														<strong>Acciones</strong>
													</TableCell>
												</TableRow>
											</TableHead>
											<TableBody>
												{
													activities.map((actividades) => {
														return (
															<TableRow>
																<TableCell align="center">
																	<h6>{actividades.id}</h6>
																</TableCell>
																<TableCell align="center">
																	<h6>{actividades.description}</h6>
																</TableCell>
																<TableCell align="center">
																	<h6>{actividades.professions.map((profesion) => {
																		return (
																			<h6>{profesion.description}</h6>
																		)
																	})}</h6>
																</TableCell>
																<TableCell align="center">
																	<div className='d-flex align-items-center justify-content-center'>
																		<h6 className={classes.circuloRojo}>{actividades.total}</h6>
																	</div>
																</TableCell>
																<TableCell align="center">
																	<div>
																		<Link to={VIEW_CONFIG_CLASSIFY(params.idRegimen, actividades.id)}>
																			<button type="button" class="btn btn-primary" title="Configurar actividades" style={{ marginRight: '5px' }}>
																				<i>Editar actividad</i>
																			</button>
																		</Link>
																		<button type="button" class="btn btn-secondary" title="Reclasificar reglas"
																			onClick={() => handleModal(<ReclassifyModal idRegimen={actividades.idRegimen} idActivity={actividades.id}
																				regimen={params.idRegimen}
																				actividad={actividades.description}
																			>
																			</ReclassifyModal>)}>
																			<i>Reclasificar actividad</i>
																		</button>
																	</div>
																</TableCell>
															</TableRow>
														)
													})
												}
											</TableBody>
										</Table>
									</TableContainer>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

ConfiguracionActividades.propTypes = {};

export default ConfiguracionActividades;
