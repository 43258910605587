import React, { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { startRegisterTaxpayerAddresses, startUpdateTaxpayerAddresses } from '../../../../actions/taxpayers/TaxAddresses';
import { validateStringPresence } from '../../../../helpers/validateForm';
import { useForm } from '../../../../hooks/useForm';
import { ModalContext } from '../../../modal/ModalContext';
import { TaxAddressForm } from './TaxAddressForm';

export const TaxAddressFormAloneScreen = ({ taxAddress = null, taxpayerId }) => {
  
  const { handleModal } = useContext(ModalContext);

  const dispatch = useDispatch();

  const {formValues, setFormValues} = useForm({
    address:{
      country: '',
      interior: '',
      outdoor: '',
      postalCode: '',
      street: '',
      suburb: '',
      town: '',
    }
  });

  const { address } = formValues;

  const { country, interior, outdoor, postalCode, street, suburb, town } = address;

  const [msgErrors, setMsgErrors] = useState({});

  useEffect(() => {
    if(taxAddress){
      setFormValues(prevState => {
        return{
          ...prevState,
          address: { ...taxAddress }
        }
      })
    }
  }, [setFormValues, taxAddress])

  const handleSubmit = (e) => {
    e.preventDefault();

    if(isFormValid()){
      let petition = {
        country: country, 
        street: street,
        interior: interior,
        outdoor: outdoor,
        postalCode: postalCode,
        profileId: taxpayerId,
        suburb: suburb,
        town: town,
      }

      if(taxAddress){
        petition.addressId = taxAddress.id
        dispatch(startUpdateTaxpayerAddresses(petition, handleModal.bind()));
      }else{
        dispatch(startRegisterTaxpayerAddresses(petition, handleModal.bind()));
      }
    }
  }

  const isFormValid = () => {
    setMsgErrors({});

    const stringsToValidate = { postalCode }

    const validateString = validateStringPresence(stringsToValidate, setMsgErrors);
    
    if(validateString){
      return true;
    }
    return false;
  }

  return (
    <div>
      <div className="text-center">
        {
          taxAddress ?
          (
            <h4>Actualizar dirección</h4>
          )
          :
          (
            <h4>Agregar nueva dirección</h4>
          )
        }
      </div>
      <hr/>

      <form className="mt-4" onSubmit={handleSubmit}>
        <div className="row">
          <TaxAddressForm
            address={address}
            msgErrors={msgErrors}
            setFormValues={setFormValues}
          />
        </div>

        <div className="col-md-12 text-right">
          <button type="button" className="btn btn-danger" onClick={handleModal}>Cerrar</button>
          <button type="submit" className="btn btn-primary ml-2">Guardar</button>
        </div>
      </form>
    </div>
  )
}
