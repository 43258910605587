const initialState = {
    typeRetention: ['26','25']
  }
  
  export const typeRetentionReducer = ( state = initialState, action ) => {
    switch (action.type) {
      default:
        return state;
    }
  }
  