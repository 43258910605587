import React from 'react';
import { withRouter } from 'react-router-dom';
import ShowPayroll  from './showPayroll';
import ShowEmployee  from './showEmployee';

const Index = () => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <h4 className="card-header">Emisión Recibos de Nómina</h4>
        </div>
      </div>
      <nav className="mt-4">
        <div className="nav nav-tabs" id="nav-tab" role="tablist">
          <a className="nav-item nav-link active" id="nav-payroll-tab" data-toggle="tab" href="#nav-payroll" role="tab" aria-controls="nav-payroll" aria-selected="true">
            Nominas
          </a>
          <a className="nav-item nav-link" id="nav-employee-tab" data-toggle="tab" href="#nav-employee" role="tab" aria-controls="nav-employee" aria-selected="true">
            Empleados
          </a>
        </div>
      </nav>
      <div className="tab-content" id="nav-tabContent">
        <div className="tab-pane fade show active" id="nav-payroll" role="tabpanel" aria-labelledby="nav-payroll-tab">
          <ShowPayroll />
        </div>
        <div className="tab-pane fade" id="nav-employee" role="tabpanel" aria-labelledby="nav-employee-tab">
          <ShowEmployee />
        </div>
      </div>
    </div>
  );
}

export default withRouter(Index);