import { fetchWithToken } from "../helpers/fetch";
import { loadingModal, saveModal } from "../helpers/UseSwal";
import { types } from "../types/types"

export const ThirdPartyStartRegister = (thirdParty, handleModal) => {
  return async(dispatch) => {
    saveModal(true);
    const resp = await fetchWithToken('api/ThirdParties/create', thirdParty, 'POST');
    const body = await resp.json();
    if (body.success){
      dispatch(ThirdPartyRegister(body.data))
      handleModal();
    }
    saveModal(false);
  }
}

const ThirdPartyRegister = ( thirdParty ) => ({
  type: types.thirdPartyStartRegister,
  payload: thirdParty
})

export const thirdPartiesStartLoading = (taxpayerId) => {
  return async(dispatch) => {
    loadingModal(true);
    const resp = await fetchWithToken(`api/ThirdParties/get/${taxpayerId}`);
    const body = await resp.json();
    if (body.success){
      dispatch(thirdPartiesLoaded(body.data))
    }
    loadingModal(false);
  }
}

const thirdPartiesLoaded = (thirdParties) => ({
  type: types.thirdPartyStartLoading,
  payload: thirdParties
})