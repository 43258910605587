import { fetchWithToken } from "../../helpers/fetch";
import { saveModal } from "../../helpers/UseSwal";
import { types } from "../../types/types";

export const startRegisterTaxpayerAddresses = (address, handleModal) => {
  return async(dispatch) => {
    saveModal(true);
    const resp = await fetchWithToken('api/Address/add', address, 'POST');
    const body = await resp.json();
    if (body.success){
      dispatch(registerAddress( body.data ));
      handleModal();
    }
    saveModal(false);
  }
}

const registerAddress = (address) => ({
  type: types.taxpayerAddressesStartRegister,
  payload: address
})

export const startUpdateTaxpayerAddresses = (address, handleModal) => {
  return async(dispatch) => {
    saveModal(true);
    const resp = await fetchWithToken('api/Address/update', address, 'POST');
    const body = await resp.json();
    if (body.success){
      dispatch(updateAddress( body.data ));
      handleModal();
    }
    saveModal(false);
  }
}

const updateAddress = (address) => ({
  type: types.taxpayerAddressesStartUpdate,
  payload: address
})