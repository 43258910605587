import React from 'react';
import { TextField } from '@material-ui/core';

export const InputSearching = ({ columnsToSearch, setDataChange, originals }) => {

  const handleInputSearchChange = ({ target }) => {
    const { value } = target;
    if (value.length > 2) {
      setDataChange(searchInArray(value));
    } else {
      setDataChange(originals);
    }
  }

  const searchInArray = (search) => {
    let newData = [];
    originals.forEach((original) => {
      let flag = false;
      columnsToSearch.forEach((column) => {
        original[column]?.toLowerCase().includes(search.toLowerCase()) && (flag = true)
      })
      if (flag) {
        newData.push(original)
      }
    })
    return newData;
  }

  return (
    <TextField
      variant="outlined" label="Busqueda" size="small" fullWidth={true}
      name="search"
      onChange={handleInputSearchChange}
    />
  )
}
