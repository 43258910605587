import { fetchWithApiKey } from "../../helpers/fetch";
import { types } from "../../types/types"

export const paymentMethodsCfdiStartLoading = () => {
  return async(dispatch) => {
    const resp = await fetchWithApiKey('api/Catalogs/c_MetodoPago');
    const body = await resp.json();
    if (body.success){
      dispatch(paymentMethodsCfdiLoaded( body.data ));
    }
  }
}

const paymentMethodsCfdiLoaded = (paymentMethods) => ({
  type: types.paymentMethodCfdisStartLoading,
  payload: paymentMethods
})
