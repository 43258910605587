import { TextField } from '@material-ui/core';
import React, { Fragment, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { documentTaxTaxpayerTypesStartLoading } from '../../../../../actions/catalogs/documentTaxTaxpayerTypes';

export const ModuleFilesScreen = ({saleFormValues, saleSetFormValues}) => {
  
  const {TaxFilesInfo} = saleFormValues;

  const {documentTaxTaxpayerTypes} = useSelector(state => state.documentTaxTaxpayerType)

  const dispatch = useDispatch();

  useEffect(() => {
    if(documentTaxTaxpayerTypes.length === 0){
      dispatch(documentTaxTaxpayerTypesStartLoading());
    }
  }, [dispatch, documentTaxTaxpayerTypes]);

  const handleFileInputChange = (id, e) => {
    const name = e.target.name;
    const files = e.target.files;

    saleSetFormValues(prevState => {
      return{
        ...prevState,
        TaxFilesInfo: prevState.TaxFilesInfo.map(
          e => ( e.id === id ) ? 
          {
            ...e,
            [name]: files[0]
          } 
          : 
          e
        )
      }
    })
  }

  const handleInputChange = (id, e) => {
    const name = e.target.name;
    const value = e.target.value;

    saleSetFormValues(prevState=>{
      return{
        ...prevState,
        TaxFilesInfo: prevState.TaxFilesInfo.map(
          e => ( e.id === id ) ? 
          {
            ...e,
            [name]: value
          } 
          : 
          e
        )
      }
    })
  }

  const handleAddFile = (e) => {
    e.preventDefault();
    
    saleSetFormValues(prevState => {
      return{
        ...prevState,
        TaxFilesInfo: [
          ...prevState.TaxFilesInfo,
          {
            DocumentFile: '',
            id: new Date().getTime(),
            DocumentTypeId: 0,
          }
        ]
      }
    })
  }

  const handleDeleteFiles = (id, e) => {
    e.preventDefault();

    saleSetFormValues(prevState => {
      return{
        ...prevState,
        TaxFilesInfo: prevState.TaxFilesInfo.filter(
          e => ( e.id !== id )
        )
      }
    })
  }

  return (
    <Fragment>
      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3">
        <h5>Archivos requeridos</h5>
        <hr />
      </div>

      {
        TaxFilesInfo.map((taxFile, index) => {
          return(
            <Fragment key={index}>
              <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-3">
                <TextField
                  label="Tipo de documento" variant="outlined" size="small" autoComplete="off" fullWidth={true}
                  select
                  SelectProps={{
                    native: true,
                  }}
                  name="DocumentTypeId"
                  value={taxFile.DocumentTypeId}
                  onChange={ handleInputChange.bind(this, taxFile.id) }
                  // helperText={ msgErrors.passwordSAT }
                  // error={ msgErrors.passwordSAT !== undefined }
                >
                  <option value='0'>Selecciona un tipo</option>
                  {
                    documentTaxTaxpayerTypes.map((documentType, j) => {
                      return(
                        <option
                          key={ j }
                          value={ documentType.id }
                        > 
                          { documentType.description }
                        </option>  
                      )
                    })
                  }
                </TextField>
              </div>

              <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-3">
                <TextField label="Archivo (.PDF)" variant="outlined" size="small" autoComplete="off" fullWidth={true} 
                  InputLabelProps={{shrink: true,}}
                  type="file"
                  name="DocumentFile"
                  onChange={ handleFileInputChange.bind(this, taxFile.id) }
                  // helperText={ msgErrors.fileCer }
                  // error={ msgErrors.fileCer !== undefined }
                  inputProps={{ accept: '.pdf' }}
                />
              </div>

              {
                index !== 0 &&
                (
                  <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3">
                    <button type="button" className="btn btn-outline-danger" onClick={handleDeleteFiles.bind(this, taxFile.id)}>
                      <i className="fas fa-minus"></i> eliminar archivo
                    </button>
                  </div>
                )
              }
            </Fragment>
          )
        })
      }

      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3 text-right">
        <button type="button" className="btn btn-outline-primary" onClick={handleAddFile}>
          <i className="fas fa-plus"></i> Agregar Archivo
        </button>
      </div>

    </Fragment>
  )
}
