import { fetchWithToken } from "../../helpers/fetch";
import { loadingModalMsn } from "../../helpers/UseSwal";
import { types } from "../../types/types";

export const validationPasswordSat = (data,handleModal = null,msn =null,flag=null) => {
    return async(dispatch) => {
      if(msn){
      loadingModalMsn(true,msn);
      }
      else{
        loadingModalMsn(true);
      }
      const resp = await fetchWithToken('api/DownloadXmlSAT/validatePasswdSat', data, 'POST');
      const body = await resp.json();
      if (!body.success && body.data){
        dispatch(getSesionSat(false)) 
        dispatch(getDownloadSatLoaded(body.data)); 
      } 
      else{
        dispatch(getSesionSat(true))
      }

      if(handleModal || flag){
      handleModal();
      }
      if(flag){
        dispatch(loading(true))
      }
    
      loadingModalMsn(false);
    }
  }

  const getDownloadSatLoaded = (flag) => ({
    type: types.taxpayerStartValidationSat,
    payload: flag
  })

  const getSesionSat = (flag) => ({
    type: types.getSessionSat,
    payload: flag
  })

  const loading = (flag) => ({
    type: types.sessionSatTaxpayerLoading,
    payload: flag
  })
