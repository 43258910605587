import { types } from "../types/types";

const initialState = {
  taxpayerSelected: {},
  taxpayerProfession:'Otro',
  taxpayers: [],
  taxpayersAssisteds: [],
  taxpayeAssitedFinish:[],
  searchTaxpayerAssitedFinish:false,
  dashboard:{},
  byAccountant: [],
  taxpayerReportStatus: []
}

export const taxpayerReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.taxpayerStartRegister:
      return {
        ...state,
        taxpayers: [
          ...state.taxpayers,
          action.payload
        ]
      }

    case types.taxpayerStartLoading:
      return{
        ...state,
        taxpayers: [ ...action.payload ]
      }
    
    case types.taxpayerChangeActivation:
      return {
        ...state,
        taxpayers: state.taxpayers.map(
          e => ( e.id === action.payload ) ? 
            {
              ...e,
              active: !e.active
            } 
          : 
            e
        )
      }

    case types.taxpayerSelectedLoaded:
      return {
        ...state,
        taxpayerSelected: {
          ...action.payload
        },
        taxpayerProfession:action.payload.profession ? action.payload.profession.key : state.taxpayerProfession
      }
    case types.taxpayerActivity:
      return {
        ...state,
        taxpayerProfession:action.payload.profession ? action.payload.profession.key : state.taxpayerProfession
      }

    case types.taxpayerStartUpdate:
      return{
        ...state,
        taxpayers:[],
        taxpayerSelected: {
          ...action.payload
        }
      }
    
    case types.taxpayerAddressesStartRegister:
      return{
        ...state,
        taxpayerSelected:{
          ...state.taxpayerSelected,
          addresses: [
            ...state.taxpayerSelected.addresses,
            action.payload
          ]
        }
      }

    case types.taxpayerAddressesStartUpdate:
      return{
        ...state,
        taxpayerSelected:{
          ...state.taxpayerSelected,
          addresses: state.taxpayerSelected.addresses.map(
            e => ( e.id === action.payload.id ) ? action.payload : e
          )
        }
      }
    
    case types.taxpayerAssistedsStartLoading:
      return{
        ...state,
        taxpayersAssisteds: [ ...action.payload.details.sort(function(a,b){return new Date(b.sale.activateAt) - new Date(a.sale.activateAt)})],
        dashboard: { ...action.payload.dashboard},
        byAccountant: [ ...action.payload.dashboard.byAccountant]
      }
    
    case types.updateDashInfo:
      return{
        ...state,
        taxpayersAssisteds: state.taxpayersAssisteds.map(
          e => (e.id === action.payload.id) ? 
          {
            ...action.payload
          }
           : e
        )
      }
      case types.updateDashInfoPush:
        return{
          ...state,
          taxpayersAssisteds: [action.payload,...state.taxpayersAssisteds]
        }

    case types.updatePackageToPresent:
      return{
        ...state
      }
    
    case types.taxpayerAssistedChangeAccountant:
      return{
        ...state,
        taxpayersAssisteds: state.taxpayersAssisteds.map(
          e => (e.id === action.payload.packageId) ? 
          {
            ...e,
            accountant: action.payload
          }
          :
          e
        )
      }

      case types.taxpayerAssistedChangePasswordSat:
        return{
          ...state,
          taxpayersAssisteds: state.taxpayersAssisteds.map(
            e => (e.profile.id === action.payload.id) ? 
            {...e, profile: {
                ...e.profile,
                passwordSAT: action.payload.passwordSAT
            }} : e
          )
        }

      case types.serviceDateUpdate:
        return{
          ...state,
          taxpayersAssisteds: state.taxpayersAssisteds.map(
            e => (e.sale.id === action.payload.id) ? {...e, sale: action.payload} : e
          )
        }

      case types.taxpayerAssistedsFinishStartLoading:
        return{
          ...state,
          taxpayeAssitedFinish:action.payload
        }
      
        case types.taxpayerAssistedsFinishSearch:
          return{
            ...state,
            searchTaxpayerAssitedFinish: action.payload
          }
        case types.taxpayerAssisteds:
          return{
            ...state,
            taxpayerAssisteds:state.taxpayersAssisteds.unshift(action.payload)
          }
        case types.taxpayerReport:
          return{
            ...state,
            taxpayerReportStatus:action.payload
          }

    default:
      break;
  }
  return state;
}
