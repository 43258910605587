
const initialState = {
  ivaTraslateds: [
    {
      id: 1,
      name: '16%',
      rate: 0.160000,
      tax: '002',
      type: 'Tasa',
    },
    {
      id: 2,
      name: "8%",
      rate: 0.08,
      tax: '002',
      type: "Tasa",
    },
    {
      id: 3,
      name: "0%",
      rate: 0.00,
      tax: '002',
      type: "Exento",
    },
    {
      id: 4,
      name: "Exento",
      rate: 0.00,
      tax: '002',
      type: "Exento",
    },
  ]
};

export const ivaTraslated = ( state = initialState, action ) => {
  switch (action.type) {
  
    default:
      return state;
  }
}
