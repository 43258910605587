import { TextField } from '@material-ui/core';
import React, { useState, useContext} from 'react';
import { useDispatch } from 'react-redux';
import { TaxdocumentsStartRegister } from '../../../../actions/taxpayers/TaxDocuments';
import { validateFilePresence } from '../../../../helpers/validateForm';
import { useForm } from '../../../../hooks/useForm';
import { ModalContext } from '../../../modal/ModalContext';

export const ConstancyOpinion = ({profileId}) => {

 
  const dispatch = useDispatch();

  const {formValues, handleFileInputChange, handleInputChange} = useForm({
    documentTaxId: 0,
    files: '',
  });

  const [msgErrors, setMsgErrors] = useState({});

  const { documentTaxId, files } = formValues;
  const { handleModal } = useContext(ModalContext);
  
  const handleSubmit = (e) => {
    e.preventDefault();
    let documentId= 1;
    if(isFormValid()) {
      if(documentTaxId === 0){
         documentId= 8;
      }

      let formData = new FormData();
      formData.append('Files', files);
      formData.append('taxDocumentTypeId', documentId );
      formData.append('profileId', profileId);
      dispatch(TaxdocumentsStartRegister(formData, handleModal.bind()))
    }
  }

  const isFormValid = () => {
    setMsgErrors({});
    const datasFilesToValidate = { files }
    const validateFiles = validateFilePresence( datasFilesToValidate, setMsgErrors );
    if( validateFiles ){
      return true;
    }
    return false;
  }

  return (
    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3">
      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3">
        <h5>Constancia y opinión de cumplimiento</h5>
        <hr />
      </div>
           <form onSubmit={handleSubmit}>
             <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12'>
                <TextField
                          select
                          variant="outlined"
                          label="Tipo"
                          size="small"
                          fullWidth={true}
                          name="documentTaxId"
                          value={documentTaxId}
                          onChange={handleInputChange}
                          SelectProps={{
                            native: true,
                          }}
                        >
                          <option value={0} >Constancia de Situación Fiscal</option>
                          <option value={1} >Opinión del cumplimiento</option>
                </TextField>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-3 mb-3">
              <TextField label="Archivo" variant="outlined" size="small" autoComplete="off" fullWidth={true} 
                InputLabelProps={{shrink: true,}}
                type="file"
                name="files"
                onChange={ handleFileInputChange }
                helperText={ msgErrors.files }
                error={ msgErrors.files !== undefined }
                inputProps={{ accept: '.PDF,.TXT' }}
              />
            </div>
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3 text-right pr-0">
                <button className="btn btn-outline-primary">
                  Guardar
            </button>          
          </div>
          </form>
    </div>
  )
}
