import React from 'react';

const ShowFixed = () => {
    return(
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-4">
            <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2">
                    <h4 className="card-header">RESUMEN ANUAL</h4>
                </div>
            </div>
        </div>
    )
}

export default ShowFixed;