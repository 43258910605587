import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

export const EmployeePayroll = () => {
    const top100Films = [
        { title: 'Valentin', year: 1994 },
        { title: 'Nayeli', year: 1972 },
        { title: 'Gabriel', year: 1974 },
        { title: 'Ricardo', year: 2008 }
    ];
    const handleInputChange = (e) => {}
    return (
        <div>
            <h5 className="card-header">Empleados</h5>
            <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-3">
                    <TextField select variant="outlined" label="Mostrar Empleados" size="small" 
                        fullWidth={true} name="empleados_id" value="0" onChange={handleInputChange}
                        SelectProps={{native: true,}}>
                        <option value="0">Seleccionar</option>
                        <option value="1">Todos los empleados</option>
                        <option value="2">Empleados Facturados</option>
                        <option value="3">Empleados Sin Facturar</option>
                    </TextField>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2">
                    <Autocomplete
                        freeSolo id="free-solo-2-demo" disableClearable
                        options={top100Films.map((option) => option.title)}
                        renderInput={(params) => (
                        <TextField {...params} label="Buscar empleado" margin="normal" size="small" variant="outlined"
                            InputProps={{ ...params.InputProps, type: 'search' }}
                        />
                        )}
                    />
                </div>
            </div>
        </div>
    )
}
