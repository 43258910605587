import React from 'react';
import { TextField } from '@material-ui/core';

export const GeneralDataPayroll = () => {
    const handleInputChange = (e) => {}
    return (
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <h5 className="card-header">Datos Generales</h5>
            <div className="row mt-4">
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                            <TextField label="Nombre Nómina" variant="outlined" size="small" name="name_nomina" fullWidth={true}/>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-3">
                            <TextField select variant="outlined" label="Tipo de Nómina" size="small" 
                                fullWidth={true} name="Tnomina_id" value="0" onChange={handleInputChange}
                                SelectProps={{native: true,}}>
                                <option value="0">Seleccionar</option>
                                <option value="1">Ordinaria</option>
                                <option value="2">Extraordinaria</option>
                            </TextField>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-3">
                            <TextField select variant="outlined" label="Periodicidad" size="small" 
                                fullWidth={true} name="periodicidad_id" value="0" onChange={handleInputChange}
                                SelectProps={{native: true,}}>
                                <option value="0">Seleccionar</option>
                                <option value="1">Diario</option>
                                <option value="2">Semanal</option>
                                <option value="3">Catorcenal</option>
                                <option value="4">Quincenal</option>
                                <option value="5">Mensual</option>
                                <option value="6">Bimestral</option>
                                <option value="7">Unidad Obra</option>
                                <option value="8">Comisión</option>
                                <option value="9">Precio Alzado</option>
                                <option value="10">Otra Periodicidad</option>
                            </TextField>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-3">
                            <TextField select variant="outlined" label="Serie" size="small" 
                                fullWidth={true} name="serie" value="0" onChange={handleInputChange}
                                SelectProps={{native: true,}}>
                                <option value="0">Seleccionar</option>
                                <option value="1">NOM</option>
                            </TextField>
                        </div>
                    </div>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                            <TextField label="Fecha Inicial" variant="outlined" size="small"
                                        name="name" fullWidth={true} type="date" InputLabelProps={{shrink: true,}}
                            />
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-3">
                            <TextField label="Fecha Final" variant="outlined" size="small"
                                        name="name" fullWidth={true} type="date" InputLabelProps={{shrink: true,}}
                            />
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-3">
                            <TextField label="Fecha de pago" variant="outlined" size="small"
                                        name="name" fullWidth={true} type="date" InputLabelProps={{shrink: true,}}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
