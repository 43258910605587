import { TablePagination } from '@material-ui/core'
import React from 'react'

export const Pagination = ({data, page, rowsPerPage, setPage, setRowsPerPage}) => {

  const handleChangePage = (e, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = ({target}) => {
    setRowsPerPage(+target.value);
    setPage(0);
  };

  return (
    <TablePagination
      rowsPerPageOptions={[25, 50, 100, 150, 200]}
      component="div"
      count={data.length}
      rowsPerPage={rowsPerPage}
      page={page}
      onChangePage={handleChangePage}
      onChangeRowsPerPage={handleChangeRowsPerPage}
    />
  )
}
