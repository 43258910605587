import { Checkbox, FormControlLabel } from '@material-ui/core';
import React, { Fragment, useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { obtainLeasePorcentaje } from '../../../../../../../../../helpers/obtainLeasePorcentage';
import NumberFormat from 'react-number-format';
import { ModalContext } from '../../../../../../../../modal/ModalContext';
import { Depresiations } from '../../expenses/options/depresiations';
import DepresiationsTable from '../../expenses/options/depresiationsTable';

export const LeaseIsrBlindCalculation = ({ formValuesDetailsSummary, setFormValuesDetailsSummary }) => {

  const { depresiations, subtotalPredialAutorized, subtotalExpenseAutorized, subtotalIncomesAutorized, taxISRLease, totalISRIncomeAutorized, month, year } = formValuesDetailsSummary;

  const { blindDeduction, blindPorcentage, depresiationFixedAsset, ISR, isBlindDeduction, subtotalPredialExpense, taxableBase, totalISR, TotalISRRetained } = taxISRLease;

  const { authorizationReport, authorizations } = useSelector(state => state.authorizationReport);
  const { authorizationPrev } = useSelector(state => state.authorizationReport);
  const { isrLeaseRanges } = useSelector(state => state.isrLease);

  const { isrJson } = authorizations;
  const { handleModal } = useContext(ModalContext);

  const isrTotal = authorizations.paymentComplementExpensesSubtotal || authorizationReport.paymentComplementExpensesSubtotal || 0
  const subtotalISR = parseFloat(subtotalExpenseAutorized) + parseFloat(isrTotal)

  useEffect(() => {
    const subtotalIncomes = subtotalIncomesAutorized;
    if (isBlindDeduction) {
      const blindDeduction = (parseFloat(subtotalIncomes) * parseFloat(blindPorcentage)).toFixed(2);
      let taxableBase = (parseFloat(subtotalIncomes) - parseFloat(blindDeduction) - parseFloat(subtotalPredialAutorized)).toFixed(2);

      if (parseFloat(taxableBase) < 0) {
        taxableBase = 0.00;
      }

      setFormValuesDetailsSummary(prevState => {
        return {
          ...prevState,
          taxISRLease: {
            ...prevState.taxISRLease,
            blindDeduction: blindDeduction,
            subtotalPredialExpense: subtotalPredialAutorized,
            taxableBase: taxableBase,
            TotalISRRetained: totalISRIncomeAutorized,
            subtotalIncomesAutorized: subtotalIncomesAutorized,
            subtotalExpenseAutorized: subtotalExpenseAutorized
          }
        }
      })
    } else {
      const subtotalExpenses = (parseFloat(subtotalExpenseAutorized)).toFixed(2);
      const taxableBase = (parseFloat(subtotalIncomes) - parseFloat(subtotalExpenses) - parseFloat(depresiationFixedAsset)).toFixed(2);

      setFormValuesDetailsSummary(prevState => {
        return {
          ...prevState,
          taxISRLease: {
            ...prevState.taxISRLease,
            subtotalExpenses: subtotalExpenses,
            subtotalPredialExpense: 0.00,
            taxableBase: taxableBase,
            TotalISRRetained: totalISRIncomeAutorized,
            subtotalIncomesAutorized: subtotalIncomesAutorized,
            subtotalExpenseAutorized: subtotalExpenseAutorized
          }
        }
      })
    }
  }, [blindPorcentage, depresiationFixedAsset, isrJson, setFormValuesDetailsSummary, subtotalIncomesAutorized, isBlindDeduction, subtotalExpenseAutorized, subtotalPredialAutorized, totalISRIncomeAutorized]);

  useEffect(() => {
    setFormValuesDetailsSummary(prevState => {
      return {
        ...prevState,
        taxISRLease: {
          ...prevState.taxISRLease,
          ISR: obtainLeasePorcentaje(isrLeaseRanges, taxableBase, year)
        }
      }
    })
  }, [isrLeaseRanges, setFormValuesDetailsSummary, taxableBase, year]);

  useEffect(() => {
    if (depresiations.length > 0) {
      setFormValuesDetailsSummary(prevState => {
        return {
          ...prevState,
          taxISRLease: {
            ...prevState.taxISRLease,
            depresiations: [
              ...prevState.taxISRLease.depresiations,
              ...depresiations
            ]
          }
        }
      })
    }
  }, [depresiations])

  useEffect(() => {
    let depresiationFixedAssetTotal = 0.00;
    let newDepresiations = [];

    if (Object.keys(authorizationPrev).length > 0) {
      const isr = JSON.parse(authorizationPrev.isrJson);

      if (Object.keys(isr).length > 0) {
        isr.depresiations.forEach(depresiation => {
          const updateAt = new Date(depresiation.updateAt)
          const date = new Date(year, month, 0);
          let newDepresiation = depresiation;
          if (depresiation.monthReal >= 0 && depresiation.monthReal <= depresiation.months) {

            if (updateAt !== date && date > updateAt) {
              newDepresiation['depresiationReal'] = (parseFloat(depresiation.depresiationReal) + parseFloat(depresiation.depresiationForMonth)).toFixed(6);
              newDepresiation['monthReal'] = parseInt(depresiation.monthReal) + 1;
              newDepresiation['updateAt'] = date;
              depresiationFixedAssetTotal = (parseFloat(depresiationFixedAssetTotal) + parseFloat(depresiation.depresiationForMonth)).toFixed(6);
            }
          }
          newDepresiations.push(newDepresiation)
        });
      }
    }
    setFormValuesDetailsSummary(prevState => {
      return {
        ...prevState,
        taxISRLease: {
          ...prevState.taxISRLease,
          depresiations: newDepresiations,
          depresiationFixedAsset: depresiationFixedAssetTotal,
        }
      }
    })
  }, [authorizationPrev, month, setFormValuesDetailsSummary, year])

  useEffect(() => {
    const totalISR = (parseFloat(ISR.plusFixedFee) - parseFloat(TotalISRRetained)).toFixed(2);

    setFormValuesDetailsSummary(prevState => {
      return {
        ...prevState,
        taxISRLease: {
          ...prevState.taxISRLease,
          totalISR: totalISR
        }
      }
    })
  }, [ISR, isrJson, setFormValuesDetailsSummary, TotalISRRetained])

  const handleCheckboxTrueFalseChange = () => {
    setFormValuesDetailsSummary(prevState => {
      return {
        ...prevState,
        taxISRLease: {
          ...prevState.taxISRLease,
          isBlindDeduction: !prevState.taxISRLease.isBlindDeduction
        }
      }
    }
    )
  }

  return (
    <Fragment>
      <tr>
        <td></td>
        <td>
          <FormControlLabel
            control={<Checkbox checked={isBlindDeduction} onChange={handleCheckboxTrueFalseChange} name="isBlindDeduction" />}
            label="Deducción Ciega"
          />
        </td>
      </tr>


      <tr>
        <td className="w-50">Ingresos del mes</td>
        <td className="w-25"><NumberFormat value={parseFloat(subtotalIncomesAutorized).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td>
      </tr>
      {
        isBlindDeduction ? (
          <tr>
            <td className="w-50">Deducción ciega</td>
            <td className="w-25"><NumberFormat value={parseFloat(blindDeduction).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td>
          </tr>
        )
          :
          (
            <tr>
              <td className="w-50">Deducción de gastos</td>
              <td className="w-25"><NumberFormat value={parseFloat(subtotalISR).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td>
            </tr>
          )
      }
      {
        isBlindDeduction ? (
          <tr>
            <td className="w-50">Impuesto Predial</td>
            <td className="w-25"><NumberFormat value={parseFloat(subtotalPredialExpense).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td>
          </tr>
        )
          :
          (
            <tr>
              <td className="w-50">Depreciación de activos fijos</td>
              <td className="w-50"><NumberFormat value={parseFloat(depresiationFixedAsset).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                <button data-toggle="tooltip"
                  data-placement="top"
                  title="Agregar depreciación" className="btn btn-sm btn-outline-primary float-right align-middle"
                  onClick={() => handleModal(
                    <Depresiations
                      formValuesDetailsSummary={formValuesDetailsSummary}
                      setFormValuesDetailsSummary={setFormValuesDetailsSummary}
                    />
                  )}>
                  <i className="fas fa-plus"></i> Agregar
                </button>
              </td>
            </tr>
          )
      }

      <tr>
        <td className="w-50">Base grabable</td>
        <td className="w-25"><NumberFormat value={parseFloat(taxableBase).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td>
      </tr>

      <tr>
        <td className="w-50">limite inferior</td>
        <td className="w-25"><NumberFormat value={parseFloat(ISR.lowerLimit).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td>
      </tr>

      <tr>
        <td className="w-50">=</td>
        <td className="w-25"><NumberFormat value={parseFloat(ISR.subtractLimitLower).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td>
      </tr>

      <tr>
        <td className="w-50">Tasa</td>
        <td className="w-25"><NumberFormat value={parseFloat(ISR.porcentage * 100).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'%'} /></td>
      </tr>

      <tr>
        <td className="w-50">=</td>
        <td className="w-25"><NumberFormat value={parseFloat(ISR.forPorcentage).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td>
      </tr>

      <tr>
        <td className="w-50">cuota Fija</td>
        <td className="w-25"><NumberFormat value={parseFloat(ISR.fixedFee).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td>
      </tr>

      <tr>
        <td className="w-50">A cargo</td>
        <td className="w-25"><NumberFormat value={parseFloat(ISR.plusFixedFee).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td>
      </tr>

      <tr>
        <td className="w-50">Menos ISR retenido</td>
        <td className="w-25"><NumberFormat value={parseFloat(TotalISRRetained).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td>
      </tr>

      {parseFloat(totalISR) > 0 ?
        (
          <tr>
            <td className="w-50">ISR a Pagar</td>
            <td className="w-25"><NumberFormat value={parseFloat(totalISR).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td>
          </tr>
        )
        :
        (
          <tr>
            <td className="w-50">ISR a Pagar</td>
            <td className="w-25"><NumberFormat value={0.00} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td>
          </tr>
        )
      }
      <DepresiationsTable
        formValuesDetailsSummary={formValuesDetailsSummary}
      />
    </Fragment>
  )
}
