import { types } from "../../types/types";

const initialState = {
  moduleActivate: {}
}

export const RegisterActivateReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.registerActivateModule:
      return{
        ...state,
        moduleActivate: { ...action.payload }
      }
      
    default:
      return state;
  }
}
