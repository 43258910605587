import { types } from "../../types/types";

const initialState={
  packageReportsAssisteds: []
}

export const packageReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.packageReportsAssistedStartLoading:
      return{
        ...state,
        packageReportsAssisteds: [ ...action.payload ]
      }
  
    default:
      return state;
  }
}
