import { types } from "../../types/types";

const initialState = {
  crmPreSales:[],
  crmSalePreSelectedInfo: null,
  captcha:null
}


export const crmPreSaleReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.crmPreSalesStartLoading:
      return{
        ...state,
        crmPreSales:action.payload ,
      }
    case types.crmPreSalesRegister:
    return{
        ...state,
        crmPreSales: [ ...state.crmPreSales,action.payload ],
        
    }
    case types.crmSalePreSelected:
      return{
        ...state,
        crmSalePreSelectedInfo: action.payload,
      }
    case types.crmSalePreValidateSat:
      return{
        ...state,
        captcha: action.payload,
      }
    case types.crmPreSaleLink:
      return{
        ...state,
        crmPreSales: state.crmPreSales.map(
          e => (e.token === action.payload.token) ?
            {
              ...e,
              stepsJson:{
                ...e.stepsJson,
                stepPayment:action.payload.stepsJson.stepPayment
              }
            }
          :
            e
        )
      }
    case types.crmPreSaleInfoTax:
      return{
        ...state,
        captcha: action.payload,
      }
      case types.crmPreSaleFiles:
        return{
          ...state,
          crmPreSales: state.crmPreSales.map(
            e => (e.token === action.payload.token) ?
              {
                ...e,
                stepsJson:{
                  ...e.stepsJson,
                  stepTaxDocument:action.payload
                }
              }
            :
              e
          ),
          crmSalePreSelectedInfo:{
            ...state.crmSalePreSelectedInfo,
            stepsJson:{
              ...state.crmSalePreSelectedInfo.stepsJson,
              stepTaxDocuments:action.payload
            }

          }
          
        }
      case types.crmPreSaleFilesCSD:
      return{
        ...state,
        crmPreSales: state.crmPreSales.map(
          e => (e.token === action.payload.token) ?
            {
              ...e,
              stepsJson:{
                ...e.stepsJson,
                stepFiles:action.payload
              }
            }
          :
            e
        )
      }
    default:
      return state;
  }
}
