import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { CSVLink } from 'react-csv';
import { useSelector } from 'react-redux';
import { InputSearching } from '../../../../helpers/tables/InputSearching';
import { Pagination } from '../../../../helpers/tables/Pagination';

export const UserRegister= () => {

  const headers = [
    {label: 'Id', key: 'id'},
    {label: 'Nombre', key: 'name'},
    {label: 'Apellido Paterno', key: 'lastName'},
    {label: 'Apellido Materno', key: 'motherLastName'},
    {label: 'Email', key: 'email'},
    {label: 'Teléfono', key: 'phone'},   
    {label: 'Social Media', key: 'socialMedia'},
    {label: 'Fecha de registro', key: 'createdAt'}, 
  ]

  const {usersData,usersRegistered,usersLogged} = useSelector(state => state.metric);

  const [userSearch, setUserSearch] = useState([]);
  const [newUserRegister, setNewUserRegister] = useState([]);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  useEffect(() => {
      setUserSearch(usersData)
      setNewUserRegister(usersData)

  }, [usersData]);

  return (
    <div className="row">
      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2">
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <div className='col-9 bg-success border rounded-lg row m-0 align-items-center'> <i className="fas fa-user-check fa-3x p-1"></i> <h5 className='a-contabox text-center'> Usuarios Logueados {usersLogged}</h5>  </div>
                <div className='col-9 bg-secondary border rounded-lg row m-0 align-items-center'><i className="fas fa-id-card fa-3x p-1"></i> <h5 className='a-contabox text-center'> Usuarios Registrados {usersRegistered}</h5>  </div>
                
                
              </div>
              <div className="col-md-6  text-right">
              <CSVLink  data={userSearch} filename={"UsuariosRegistrados.csv"} headers={headers} className="btn btn-success">
                Descargar CSV
              </CSVLink>
              <div className="mt-2">
                 <InputSearching
                    columnsToSearch={ [ 'name', 'lastName', 'email' ] }
                    setDataChange={ setUserSearch }
                    originals={ newUserRegister }
                  />
              </div>
              
            </div>
            </div>
          </div>
        </div>
      </div>

      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2">
        <div className="card">
          <div className="card-body">
            

            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="center"><strong>Id</strong></TableCell>
                    <TableCell align="center"><strong>Nombre</strong></TableCell>
                    <TableCell align="center"><strong>Apellido Paterno</strong></TableCell>
                    <TableCell align="center"><strong>Apellido Materno</strong></TableCell>
                    <TableCell align="center"><strong>Email</strong></TableCell>
                    <TableCell align="center"><strong>Teléfono</strong></TableCell>
                    <TableCell align="center"><strong>Social Media</strong></TableCell>
                    <TableCell align="center"><strong>Fecha de registro</strong></TableCell>
                    <TableCell align="center"><strong>Activo</strong></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                   userSearch.length === 0 ?
                    (
                      <TableRow>
                      <TableCell colSpan="9">
                        <div className="alert alert-info text-center" role="alert">
                          No se encontraron registros
                        </div>
                      </TableCell>
                    </TableRow>
                    )
                    :
                    (
                      userSearch.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((user, index) => { 
                        return( user.role &&
                          <TableRow key={index}>
                            <TableCell>{user.id}</TableCell>
                            <TableCell align="center">
                              {user.name}<br />
                            </TableCell>
                            <TableCell align="center">
                              {user.lastName}<br />
                            </TableCell>
                            <TableCell align="center">
                              {user.motherLastName}<br />
                            </TableCell>
                            <TableCell align="center">
                              {user.email}<br />
                            </TableCell>
                            <TableCell align="center">
                              {user.phone}<br />
                            </TableCell>
                            <TableCell align="center">
                              {user.socialMedia}<br />
                            </TableCell>

                            <TableCell align="center">
                              { moment(user.createdAt).format('YYYY-MM-DD') }
                            </TableCell>

                            <TableCell align="center">
                              {
                                user.isActive ?
                                (
                                  <span className="badge badge-success">Si</span>
                                ):
                                (
                                  <span className="badge badge-danger">No</span>
                                )
                              }
                            </TableCell>
                          </TableRow>
                        )
                      })
                    )
                  }
                </TableBody>
              </Table>
            </TableContainer>
            <Pagination
              data={userSearch}
              page={page}
              rowsPerPage={rowsPerPage}
              setPage={setPage}
              setRowsPerPage={setRowsPerPage}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
