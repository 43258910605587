const initialState = {
    IsrSimplifiedRanges: [
      {
        lowerLimit: 1.00,
        upperLimit: 25000.00,
        porcentage: 0.01,
      },
      {
        lowerLimit: 25000.01,
        upperLimit: 50000.00,
        porcentage: 0.011,
      },
      {
        lowerLimit: 50000.01,
        upperLimit: 83333.33,
        porcentage: 0.015,
      },
      {
        lowerLimit: 83333.34,
        upperLimit: 208333.33,
        porcentage: 0.02,
      },
      {
        lowerLimit: 208333.34,
        upperLimit: 3500000.00,
        porcentage: 0.025,
      },
    ]
  }

  export const IsrSimplifiedReducer = (state = initialState, action) => {
    switch (action.type) {

      default:
        return state
    }
  }
