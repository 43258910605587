import { fetchWithApiKey } from "../../helpers/fetch";
import { types } from "../../types/types";

export const socialMediaTypeActives = () => {
  return async(dispatch) => {
    const resp = await fetchWithApiKey( 'api/Catalogs/c_SocialMediaType');
    const body = await resp.json();
    if (body.success){
      dispatch( SMTLoadActives( body.data ) )
    }
  }
}

const SMTLoadActives = (socialMediaTypes) => ({
  type: types.socialMediaTypeLoadActives,
  payload: socialMediaTypes
})