import { CircularProgress, TextField } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { socialMediaTypeActives } from '../../../../../actions/catalogs/socialMediaTypes';
import { Autocomplete } from '@material-ui/lab';
import { getByEmail } from '../../../../../actions/users';
import swal from 'sweetalert';
import { ModuleValidateEmail } from './ModuleValidateEmail';

export const ModuleUserScreen = ({isRenovation = false, msgErrors, saleFormValues, saleSetFormValues}) => {

  
  const {SMTActives} = useSelector(state => state.socialMediaType);
  const {crmSaleSelected} = useSelector(state => state.crmSale);
  const {getMailUsers, validateEmailUser} = useSelector(state => state.userType);
 
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [loadEmail, setLoadEmail] = useState(false);

  const loading = open && getMailUsers.length === 0;

  useEffect(() => {
    if (SMTActives.length === 0 && !isRenovation) {
      dispatch(socialMediaTypeActives());
    }
  }, [dispatch, isRenovation, SMTActives])

  useEffect(() => {
    if (SMTActives.length > 0) {
      const SMT = SMTActives.filter(e => (e.description === 'VENDEDOR'))[0];
      saleSetFormValues(prevState => {
        return{
          ...prevState,
          customerData:{
            ...prevState.customerData,
            socialMediaId: SMT.id
          }
        }
      })
    }
  }, [saleSetFormValues, SMTActives])

  useEffect(() => {
      swal("Advertencia","Ingrese el correo para validar si existe un pre-registro, una vez que obtenga una respuesta ingrese el correo de notificación","warning")
  }, [])

  const handleChangeCustomer = ({target}) => {
    let value = '';

    if(target.dataset.optionIndex !== undefined){
      value = getMailUsers[target.dataset.optionIndex].id;
    }

    saleSetFormValues(prevState => {
      return{
        ...prevState,
        userId: value
      }
    })
  }
  
  const handleInputChange = ({target}) => {
    const {name, value} = target;

    saleSetFormValues(prevState => {
      return{
        ...prevState,
        customerData:{
          ...prevState.customerData,
          [name]: value
        }
      }
    })
  }
  const handleEmailChange = ({target}) =>{
    const {value} = target;
    if(value.length >= 3){
      dispatch(getByEmail(value));
    }
  }

  

  return (
    <Fragment>
      {
        isRenovation ? 
        (
          <Fragment>
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3">
              {
                !!crmSaleSelected ? 
                (
                  <TextField
                    label="Cliente" variant="outlined" size="small" autoComplete="off" fullWidth={true}
                    name="name"
                    value={`${crmSaleSelected.user.email} - ${crmSaleSelected.user.name} ${crmSaleSelected.user.lastName} ${crmSaleSelected.user.motherLastName}`}
                    disabled={ !!crmSaleSelected }
                  />
                )
                :
                (
                  <Autocomplete
                    size="small"
                    open={open}
                    onOpen={() => {
                      setOpen(true);
                    }}
                    onClose={() => {
                      setOpen(false);
                    }}
                    getOptionLabel={(user) => `${user.email} - ${user.fullName}`}
                    options={getMailUsers}
                    loading={loading}
                    onChange={handleChangeCustomer}
                    renderInput={(params) => (
                      <TextField label="Cliente" variant="outlined" size="small"
                        {...params}
                        onChange={handleEmailChange}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <Fragment>
                              {loading ? <CircularProgress color="inherit" size={20} /> : null}
                              {params.InputProps.endAdornment}
                            </Fragment>
                          ),
                        }}
                        helperText={ msgErrors.userId }
                        error={ msgErrors.userId !== undefined }
                      />
                    )}
                  />
                ) 
              }
            </div>
          </Fragment>
        )
        :
        (
          <Fragment>
            <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 mb-3">
              <TextField
                label="Nombre*" variant="outlined" size="small" autoComplete="off" fullWidth={true}
                name="name"
                value={saleFormValues.customerData.name}
                onChange={ handleInputChange }
                helperText={ msgErrors.name }
                error={ msgErrors.name !== undefined }
                disabled={ !!crmSaleSelected }
               inputProps={{ style: { textTransform: "uppercase" } }}
              />
            </div>

            <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 mb-3">
              <TextField
                label="Apellido Paterno*" variant="outlined" size="small" autoComplete="off" fullWidth={true}
                name="lastName"
                value={saleFormValues.customerData.lastName}
                onChange={ handleInputChange }
                helperText={ msgErrors.lastName }
                error={ msgErrors.lastName !== undefined }
                disabled={ !!crmSaleSelected }
                inputProps={{ style: { textTransform: "uppercase" } }}
              />
            </div>

            <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 mb-3">
              <TextField
                label="Apellido Materno*" variant="outlined" size="small" autoComplete="off" fullWidth={true}
                name="motherLastName"
                value={saleFormValues.customerData.motherLastName}
                onChange={handleInputChange}
                helperText={ msgErrors.motherLastName }
                error={ msgErrors.motherLastName !== undefined }
                disabled={ !!crmSaleSelected }
                inputProps={{ style: { textTransform: "uppercase" } }}
              />
            </div>

            <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 mb-3">
              <TextField 
                label="Teléfono*" variant="outlined" size="small" autoComplete="off" fullWidth={true}
                type="number"
                name="phoneMobile"
                value={saleFormValues.customerData.phoneMobile}
                onChange={handleInputChange}
                helperText={ msgErrors.phoneMobile }
                error={ msgErrors.phoneMobile !== undefined }
                disabled={ !!crmSaleSelected }
                onInput = {(e) =>{
                  e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,10)
                }}
              />
            </div>

            <div className=" col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 mb-3">
              <div className='row '>
                <div className='col-10'>
                  <TextField 
                    label="Correo Electrónico*" variant="outlined" size="small" autoComplete="off" fullWidth={true}
                    name="email"
                    value={saleFormValues.customerData.email}
                    onChange={handleInputChange}
                    helperText={ msgErrors.email }
                    error={ msgErrors.email !== undefined }
                    disabled={ !!crmSaleSelected || validateEmailUser }
    
                  />
                </div>
                <div className='col-2'>
                  <ModuleValidateEmail
                  saleFormValues={saleFormValues}
                  saleSetFormValues={saleSetFormValues}
                  />
                </div>
              </div>
            </div>

            <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 mb-3">
              <TextField 
                label="Correo de Notificación*" variant="outlined" size="small" autoComplete="off" fullWidth={true}
                name="emailNotification"
                value={saleFormValues.customerData.emailNotification}
                onChange={handleInputChange}
                helperText={ msgErrors.emailNotification }
                error={ msgErrors.emailNotification !== undefined }
                disabled={ !!crmSaleSelected }
              />
            </div>

            <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 mb-3">
              <TextField 
                hidden
                label="Contraseña*" variant="outlined" size="small" autoComplete="off" fullWidth={true}
                name="password"
                value={saleFormValues.profileData.rfc}
              //  onChange={handleInputChange}
                helperText={ msgErrors.password }
                error={ msgErrors.password !== undefined }
                disabled={ true }
              />
            </div>
          </Fragment>
        )
      }
    </Fragment>
  )
}

ModuleUserScreen.propTypes = {
  saleFormValues: PropTypes.object.isRequired,
  saleSetFormValues: PropTypes.func.isRequired
}