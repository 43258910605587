import { types } from "../../types/types";

const initialState = {
  authorizations:{
    id:0,
    profileId: 0, 
    satCode: '',
    period: '',
    comments: '',
    isrJson: {},
    ivaJson: {},
    isrFavor: 0.00,
    ivaFavor:0.00,
    token: '',
    resume: {},
    sendReport: '',
    taxDocuments: [],
    status:''
  },
  authorizationPrev: {},
  authorizationReport: {},
  autorizationProfileReports: [],
  periods: {},
  profile: {},
  finishPackage:false,
  taxpayerFinishPackage:''
};

export const authorizationReportReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.preAuthorizationReportStartRegister:
      return{
        ...state,
        authorizations:{
          ...state.authorizations,
          ...action.payload
        }
      }
    
    case types.finishPackage:
      return{
        ...state,
        finishPackage:true,
        taxpayerFinishPackage:action.payload
      }

    case types.preAuthorizationReportStartMonthPrevRegister:
      return{
        ...state,
        authorizationPrev:{
          ...state.authorizationPrev,
          ...action.payload
        }
      }

      case types.preAuthorizationReportDepreciation:
        return{
          ...state,
          authorizationPrev:{
            ...state.authorizationPrev,
            isrJson:action.payload
          }
        }
    
    case types.preAuthorizationReportUpdateRegister:
      return{
        ...state,
        authorizations:{
          ...state.authorizations,
          ...action.payload
        }
      }
    
    case types.preAuthorizationReportCheckToken:
      return{
        ...state,
        ...action.payload
      }
    
    case types.preAuthorizationReportUpdateToken:
      return{
        ...state,
        authorizationReport: {
          ...state.authorizationReport,
          commentJson: [
            ...state.authorizationReport.commentJson,
            {
              ...action.payload,
              whoComments: action.payload.whoComment,
            }
          ]
        }
      }
      case types.authorizationReportStatus:
        return{
          ...state,
          authorizationReport: {
            ...state.authorizationReport,
            isAuthorized:action.payload
          }
        }
    case types.statusReportUdapte:
      return{
        ...state,
        authorizations:{
          ...state.authorizations,
          status:action.payload
        }
      }
    case types.preAuthorizationReportGetByProfile:
      return{
        ...state,
        autorizationProfileReports:[
          ...action.payload
        ]
      }

    case types.preAuthorizationTaxDocumentsStartLoading:
      return{
        ...state,
        authorizations: {
          ...state.authorizations,
          taxDocuments: action.payload.taxDocuments
        }
      }

    default:
      return state;
  }
}
