import swal from "sweetalert";
import { fetchWithToken } from "../helpers/fetch";
import { loadingModal, saveModal } from "../helpers/UseSwal";
import { types } from "../types/types";

export const startRegisterTaxpayer = (taxpayer, handleModal) => {
  return async(dispatch) => {
    saveModal(true);
    const resp = await fetchWithToken('api/Profile/add', taxpayer, 'POST');
    const body = await resp.json();
    if (body.success){
      dispatch(registerTaxpayer(body.data))
      handleModal();
    }
    saveModal(false);
  }
}

const registerTaxpayer = (taxpayer) => ({
  type: types.taxpayerStartRegister,
  payload: taxpayer
})

export const taxpayerStartLoading = () => {
  return async(dispatch) => {
    loadingModal(true);
    const resp = await fetchWithToken('api/Profile/get', 'POST');
    const body = await resp.json();
    if (body.success && body.data.length > 0) {
      dispatch(taxpayerLoaded(body.data))
    }
    loadingModal(false);
  }
}

const taxpayerLoaded = ( taxpayers ) => ({
  type: types.taxpayerStartLoading,
  payload: taxpayers
})

export const taxpayerSelectedLoading = ( id, satCode ) => {
  return async(dispatch) => {
    const resp = await fetchWithToken(`api/Profile/get/${id}/${satCode}`, 'GET');
    const body = await resp.json();
    if ( body.success ) {
      dispatch( taxpayerSeletedLoaded( body.data ) );
    }else{
      dispatch( taxpayerSeletedLoaded( {} ) );
    }
  }
}

const taxpayerSeletedLoaded = ( taxpayer ) => ({
  type: types.taxpayerSelectedLoaded,
  payload: taxpayer
})

export const taxpayerActivationChange = ( id ) => {
  return async(dispatch) => {
    const resp = await fetchWithToken( 'v1/TaxPayers', { id: id }, 'DELETE');
    const body = await resp.json();
    if (body.success) {
      dispatch( taxpayerActivationChanged( id ) )
      swal("¡El registro ha sido cambiado!", {
        icon: "success",
      });
    }else{
      swal("¡El registro no pudo cambiar!", {
        icon: "error",
      });
    }
  }
}

const taxpayerActivationChanged = (id) => ({
  type: types.taxpayerChangeActivation,
  payload: id
});

export const startUpdateTaxpayer = (taxpayer, handleModal) => {
  return async(dispatch) => {
    saveModal(true);
    const resp = await fetchWithToken('api/Profile/update', taxpayer, 'POST');
    const body = await resp.json();
    if (body.success){
      dispatch((updatedTaxpayer(body.data)));
      dispatch(ChangePasswordSat(body.data));
      handleModal();
    }
    saveModal(false);
  }
}

const updatedTaxpayer = (taxpayer) => ({
  type: types.taxpayerStartUpdate,
  payload: taxpayer
})

const ChangePasswordSat = (data) => ({
  type: types.taxpayerAssistedChangePasswordSat,
  payload: data
})

export const taxpayerAssitedsStartLoading = () => {
  return async(dispatch) => {
    loadingModal(true);
    const resp = await fetchWithToken('api/Admin/reports/assisted');
    const body = await resp.json();
    if (body.success){
      dispatch(taxpayerAssistedsLoaded(body.data))
    }
    loadingModal(false);
  }
}

const taxpayerAssistedsLoaded = (taxpayers) => ({
  type: types.taxpayerAssistedsStartLoading,
  payload: taxpayers
})
export const taxpayerAssistedFinish = (rfc) => {
  return async(dispatch) => {
    loadingModal(true);
    const resp = await fetchWithToken(`api/Admin/reports/assisted/${rfc}`);
    const body = await resp.json();
    if (body.success){
      dispatch(taxpayerAssistedsFinishLoaded(body.data.details))
      dispatch(taxpayeAssitedFinish(true))
    }
    loadingModal(false);
  }
}
const taxpayerAssistedsFinishLoaded = (taxpayers) => ({
  type: types.taxpayerAssistedsFinishStartLoading,
  payload: taxpayers
})

export const searchtaxpayerAssisted = () => {
  return async(dispatch) => {
      dispatch(taxpayeAssitedFinish(false))
  }
}
const taxpayeAssitedFinish = (flag) => ({
  type: types.taxpayerAssistedsFinishSearch,
  payload: flag
})

export const taxpayerStatusLoading = (month,year,regimeSatCode,status) => {
  return async(dispatch) => {
    loadingModal(true);
    const resp = await fetchWithToken(`api/Admin/reports/assisted/${month}/${year}/${regimeSatCode}/${status}`);
    const body = await resp.json();
    if (body.success){
      if(body.data.length > 0 ){
        dispatch(taxpayerStatusLoaded(body.data))
      }else{
        swal("","No hay información con dichos filtros","warning")
      }
    }else{
      swal("",body.error,"error")
    }
    loadingModal(false);
  }
}
const taxpayerStatusLoaded = (taxpayers) => ({
  type: types.taxpayerReport,
  payload: taxpayers
})
