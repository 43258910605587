import { TextField } from '@material-ui/core'
import React from 'react'
import { useForm } from '../../../../hooks/useForm'
import { useDispatch, useSelector } from 'react-redux'
import { registerTaxDocumentBilling } from '../../../../actions/billing/uploadFilesBilling'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { BILLING } from '../../../constants/Routes'
import { useState } from 'react'
import { validateArrayPresence } from '../../../../helpers/validateForm'

export const UploadFileCsdBilling = () => {

    const dispacth = useDispatch()

    const { user } = useSelector(state => state.auth);
    const { invoicesActivation } = useSelector(state => state.invoiceBilling)
    const { documentsBilling } = useSelector(state => state.taxBillingDocuments)
    
    const [msgErrors, setMsgErrors] = useState({});

    let history = useHistory(); 

    const { formValues, setFormValues, handleInputChange } = useForm({
        passwordCSD: '',
        files: []
    })

    const { files, passwordCSD } = formValues

    const handleUploadFiles = ( e ) => {
        e.preventDefault()
        setMsgErrors({})

        let formData = new FormData(); 
        
        const datasFilesToValidate = { files }
        const validateFiles = validateArrayPresence(datasFilesToValidate, setMsgErrors );

        const appkey = invoicesActivation.data.appKey
        const profileId =  invoicesActivation.data.invoicerUserId
        const nameBusiness = invoicesActivation.data.profile.businessName
            
        formData.append('IdUser', profileId);
        formData.append('BusinessName', nameBusiness);
        formData.append('Rfc', invoicesActivation.data.rfc);
        for ( let i = 0; i < files.length; i++ ) {
            formData.append( 'Files', files[i] );
        }
        formData.append('Password', passwordCSD);
        formData.append('IsFiel', false);
       
        dispacth( registerTaxDocumentBilling( formData, history, BILLING,  appkey ))
    }

    const handleChange = (event) => {
        const files = event.target.files;
        let arrayFles = [];
        Array.from(files).map(file => {
            arrayFles.push(file)
        });

        setFormValues(prevState => {
                return{
                ...prevState,
                files: arrayFles 
                }
            })
    }

    return (
        <div className='container ' style={{ width: '500px', height: '400px' }}>
            <div className='row'>
                <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2'>
                    <h4> CARGAR CSD </h4>
                </div>
                <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2'>
                    <TextField
                            id="passwordCSD"
                            label="Contraseña de CSD*"
                            name='passwordCSD' 
                            variant="outlined" 
                            onChange={ handleInputChange }
                            // helperText={ msgErrors.msgTransmitter }
                            // error={ msgErrors.msgTransmitter !== undefined }
                            fullWidth={true} 
                        />
                </div>
                <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-4'>
                    <TextField label="Archivos .key, .cer, .txt" variant="outlined" size="small" autoComplete="off" fullWidth={true} 
                        InputLabelProps={{shrink: true,}}
                        type="file"
                        name="files"
                        onChange={ handleChange }
                        // helperText={ msgErrors.files }
                        // error={ msgErrors.files !== undefined }
                        inputProps={{ accept: '.KEY,.CER,.TXT',multiple:true }}
                    />
                </div>
                {
                /* {
                    ( files.length > 0 ) ? ( */}
                        <div>
                            <button 
                                className='btn btn-primary ml-4 mt-4'
                                onClick={ handleUploadFiles }
                            >
                                Subir Archivos
                            </button>
                        </div>
                    {/* )
                    :
                    (
                        ''
                    ) */}
                
            </div>
        </div>
    )
}
