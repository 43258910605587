import { TextField } from '@material-ui/core';
import React, { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import { changeSellerAssistedStart } from '../../../../actions/admin/accountants';
import { useForm } from '../../../../hooks/useForm';
import { ModalContext } from '../../../modal/ModalContext';

export const ChangeSellertModal = ({ accountantId, accounters, packagesToChange, handleChangeAccountant = null }) => {
    const dispatch = useDispatch();

    const { handleModal } = useContext(ModalContext)

    const { formValues, setFormValues } = useForm({
        accounterId: accountantId,
        id: 0,
    })

    const [packages] = useState(packagesToChange)

    const { accounterId } = formValues;

    const handleInputChange = (e) => {
        const { value } = e.target;

        if (value !== '0') {
            const account = accounters.find(e => e.id === parseInt(value));

            if (account) {
                setFormValues(prevState => {
                    return {
                        ...prevState,
                        accounterId: account.id,
                    };
                });
            }
        } else {
            setFormValues(prevState => {
                return {
                    ...prevState,
                    accounterId: 0,
                };
            });
        }
    };


    const handleSubmit = (e) => {
        e.preventDefault();

        packages.forEach(packageInfo => {
            dispatch(changeSellerAssistedStart({
                sellerId: formValues.accounterId,
                preSaleId: packageInfo.id
            }));
        });

        handleModal();

        const value = {
            target: {
                name: 'accounterId',
                value: 0,
            }
        }

        if (handleChangeAccountant) {
            handleChangeAccountant(value)
        }
    }


    return (
        <div style={{ width: "500px" }}>
            <h4 className="text-center">Reasignar vendedor</h4>
            <hr />
            <form onSubmit={handleSubmit}>
                <div className="col-md-12 mb-3">
                    <TextField
                        label="Contador" variant="outlined" size="small" autoComplete="off" fullWidth={true}
                        select
                        SelectProps={{
                            native: true,
                        }}
                        name="accounterId"
                        value={accounterId}
                        onChange={handleInputChange}
                    >
                        <option value='0'>Selecciona un vendedor</option>
                        {
                            accounters.map((accounter, j) => {
                                return (
                                    accounter.isActive &&
                                    <option
                                        key={j}
                                        value={accounter.id}
                                    >
                                        {accounter.fullName.toUpperCase()}
                                    </option>
                                )
                            })
                        }
                    </TextField>
                </div>
                <div className="col-md-12 text-right">
                    <button className="btn btn-primary">
                        Guardar
                    </button>
                </div>
            </form>

        </div>
    )
}