import { InputAdornment, TextField } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

export const IvaTranslatedTaxes = ({ CheckIvaTranslated, ivaTranslated, setIvaTranslated, formValues, initialIvaTranslated }) => {

  const { ivaTraslateds } = useSelector(state => state.ivaTraslated);

  const { pretotalWithoutTaxes } = formValues; 

  const { amount, rate, id } = ivaTranslated;

  useEffect(() => {
    if (CheckIvaTranslated) {
      let firstIvaTraslated = {};
      
      let amountTaxe = pretotalWithoutTaxes * rate;

      if(!rate && rate !== 0.00){
        firstIvaTraslated = { ...ivaTraslateds[0] };
        amountTaxe = pretotalWithoutTaxes * firstIvaTraslated.rate;
        setIvaTranslated({
          ...firstIvaTraslated,
          amount: amountTaxe
        })
      }
      setIvaTranslated(prevState => {
        return{
          ...prevState,
          amount: amountTaxe
        }
      })
    }else{
      setIvaTranslated( initialIvaTranslated )
    }
  }, [ CheckIvaTranslated, rate, pretotalWithoutTaxes, setIvaTranslated, initialIvaTranslated, ivaTraslateds ])

  const handleInputChange = ( { target } ) => {
    const ivaTraslated = ivaTraslateds.filter(e => ( e.id === parseInt(target.value) ))

    setIvaTranslated(prevState => {
      return{
        ...prevState,
        rate: ivaTraslated[0].rate,
        name: ivaTraslated[0].name,
        type: ivaTraslated[0].type,
        id: ivaTraslated[0].id
      }
    })
  }

  return (
    <div className="col-md-12">
      {
        CheckIvaTranslated &&
        (
          <div className="row mb-3">

            <div className="col-md-6">
              <div className="input-group input-group-sm">
                <TextField
                  select
                  variant="outlined"
                  label="Porcentaje"
                  size="small"
                  fullWidth={true}
                  name="id"
                  value={id}
                  onChange={handleInputChange}
                  SelectProps={{
                    native: true,
                  }}
                >
                  {
                    ivaTraslateds.map((ivaTraslated) => {
                      return(
                        <option
                          key={ ivaTraslated.id }
                          value={ ivaTraslated.id }
                        >
                          { ivaTraslated.name }
                        </option>
                      )
                    })
                  }
                </TextField>
              </div>
            </div>

            <div className="col-md-6">
              <div className="input-group input-group-sm">
                <TextField
                  label="Monto" 
                  variant="outlined"
                  size="small"
                  name="amount"
                  value={ amount }
                  onChange={ handleInputChange }
                  autoComplete="off"
                  fullWidth={true}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        $
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            </div>
          </div>
        )
      }
    </div>
  )
}
