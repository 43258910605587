import React, { useEffect } from 'react'
import { TextField } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux';
import { obtainMonths, obtainYears } from '../../../helpers/declarationPeriod';
import { useForm } from '../../../hooks/useForm'
import { CrmSalesIndexScreen } from './sales/CrmSalesIndexScreen';
import { CrmPreSalesIndexScreen } from './sales/CrmPreSalesIndexScreen';

export const CrmPreSalesIndex = () => {

  const { checking, user } = useSelector(state => state.auth)

  const { formValues, setFormValues, handleInputChange } = useForm({
    dataDownload: [],
    monthsOb: obtainMonths(),
    monthSelected: '',
    years: obtainYears().reverse(),
    yearselected: '',
  });

  const dispatch = useDispatch();

  const { monthSelected, monthsOb, years, yearselected } = formValues;

//   useEffect(() => {
//     if (monthSelected !== '' && yearselected !== '') {
//       dispatch(crmRenovationsStartLoading({
//         month: monthSelected,
//         year: yearselected
//       }))
//     }
//   }, [dispatch, monthSelected, yearselected]);

  useEffect(() => {
    const date = new Date();
    setFormValues(prevState => {
      return {
        ...prevState,
        monthSelected: date.getMonth() + 1,
        yearselected: date.getFullYear()
      }
    })
  }, [setFormValues])

  return (
    <div>
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <nav className="mt-4 ml-2">
            <div className="nav nav-tabs" id="nav-tab" role="tablist">
              <a className="nav-item nav-link active" id="nav-facturacion-tab" data-toggle="tab" href="#nav-facturacion" role="tab" aria-controls="nav-facturacion" aria-selected="true">
                Preventas
              </a>
              <a className="nav-item nav-link" id="nav-accounting-tab" data-toggle="tab" href="#nav-accounting" role="tab" aria-controls="nav-accounting" aria-selected="true">
                Ventas
                </a>
            </div>
          </nav>

          <div className="tab-content" id="nav-tabContent">
            <div className="tab-pane fade show active" id="nav-facturacion" role="tabpanel" aria-labelledby="nav-facturacion-tab">
             <CrmPreSalesIndexScreen/>
            </div>
            <div className="tab-pane fade" id="nav-accounting" role="tabpanel" aria-labelledby="nav-accounting-tab">
              <CrmSalesIndexScreen/>
            </div>
          </div>
        </div>
    </div>
  )
}
