import React from 'react'

export const ViewCommentsModal = ({comments}) => {
  return (
    <div className="text-center" style={{width: "500px"}}>
      <h4>Comentarios</h4>
      <hr />
      {comments}
    </div>
  )
}
