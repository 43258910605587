import React, { Fragment, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { taxpayerSelectedLoading } from '../../../../../actions/taxpayers';
import { IssuerDetails } from '../issuer/IssuerDetails';
import { RecipientDetails } from '../recipient/RecipientDetails';
import { PaymentData } from '../PaidComplement/PaymentData';
import { DocumentRelation } from '../PaidComplement/DocumentRelation';
import { DataAccountPayment } from '../PaidComplement/DataAccountPayment';
import { MorePaidComplement } from '../PaidComplement/MorePaidComplement';
import { INVOICES } from '../../../../constants/Routes';
import { useForm } from '../../../../../hooks/useForm';
import { TableDocumentRelations } from '../PaidComplement/TableDocumentRelations';

const initialPayment = {
    currencyExchangeRate: '',
    currencyId: '',
    currencySatCode: '',
    id: new Date().getTime(),
    operationNumber: '',
    paymentAt: '',
    paymentTotal: '',
    wayOfPaymentId: '',
    documentRelateds: [],
    accountBenefector: { rfc: '', accountNumber: '' },
    accountBeneficiary: { rfc: '', accountNumber: '' }
}

export const PaidComplementScreen = () => {
    const { taxpayerId } = useParams();
    
    const dispatch = useDispatch();

    const {formValues, handleArrayInputChange, handleInputChange, setFormValues} = useForm({
        regimeId: '',
        thirdPartyId: '',
        cfdiUseId: '',
        dataPayments: [ initialPayment ]
    });
    
    const { regimeId, cfdiUseId, dataPayments, thirdPartyId } = formValues;    

    useEffect(() => {
        dispatch(taxpayerSelectedLoading(taxpayerId));
    }, [dispatch, taxpayerId])

    return (
        <div className="container">
            <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <h4 className="card-header">Emisión de Complemento de pago</h4>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2">
                    <Link to={INVOICES} className="btn btn-danger btn-sm float-right">
                    <i className="fas fa-arrow-left"></i> Regresar
                    </Link>
                </div>
            </div>
            <div className="row mt-2">
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <IssuerDetails
                        formData={{
                            regimeId: regimeId
                        }}
                        handleInputChange={ handleInputChange }
                        setFormValues={ setFormValues }
                    />
                </div>
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <RecipientDetails
                        formData={{
                            thirdPartyId,
                            cfdiUseId,
                        }}
                        handleInputChange={ handleInputChange }
                        setFormValues={ setFormValues }
                    />
                </div>
                
                { dataPayments.length > 0 ? (
                    dataPayments.map((dataPayment, index) => {
                        return(
                            <Fragment key={ index }>
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                    <PaymentData
                                        dataPayment={ dataPayment }
                                        handleArrayInputChange={ handleArrayInputChange }
                                        handleInputChange= { handleInputChange }
                                        setFormValues={ setFormValues }
                                    />
                                </div>
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                    <DocumentRelation
                                        paymentId={ dataPayment.id }
                                        setFormPaidComplement = { setFormValues }
                                    />

                                    <TableDocumentRelations
                                        paymentId={ dataPayment.id }
                                        dataPayment= { dataPayment }
                                        setFormPaidComplement = { setFormValues }
                                    />
                                </div>
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                    <DataAccountPayment
                                        paymentId = { dataPayment.id }
                                        accountBenefector = { dataPayment.accountBenefector }
                                        accountBeneficiary = { dataPayment.accountBeneficiary }
                                        setFormPaidComplement = { setFormValues }
                                    />
                                </div>
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                    <MorePaidComplement />
                                </div>
                            </Fragment>
                        )
                    })
                    
                  )
                  :
                  (
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <div className="alert alert-danger col-md-12 text-center" role="alert">
                            No existe información de algún contribuyente de facturación
                        </div>
                    </div>
                  )
                  
                }

                
            </div>
        </div>
    )
}