import React, { useEffect, useContext } from 'react';
import { useParams, withRouter } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IdentifiersDetailsScreen } from '../identifiers/IdentifiersDetailsScreen';
import { CertificateDetailsScreen } from '../taxFiles/CertificateDetailsScreen';
import { EfirmaDetailsScreen } from '../taxFiles/EfirmaDetailsScreen';
import { SeriesFoliosDetailsScreen } from '../seriesFolios/SeriesFoliosDetailsScreen';
import { PositiveBalanceDetailsScreen } from '../positiveBalance/PositiveBalanceDetailsScreen'; 
import { taxpayerSelectedLoading } from '../../../../actions/taxpayers';
import { TaxpayerForm } from './TaxpayerForm';
import { ModalContext } from '../../../modal/ModalContext';
import { TaxAddressDetailsScreen } from '../taxAddress/TaxAddressDetailsScreen';

const TaxpayerDetailsScreen = () => {

  const { handleModal } = useContext( ModalContext );

  const { taxpayerSelected } = useSelector(state => state.taxpayer)
  
  const { taxpayerId } = useParams();

  const dispatch = useDispatch();

  const { businessName, rfc, emailNotification, phoneMobile, regimes, serviceTypes ,profession} = taxpayerSelected;

  useEffect(() => {
    dispatch( taxpayerSelectedLoading(taxpayerId) );
  }, [dispatch, taxpayerId])
  
  return (
    <div className="container">
      <div className="row mt-2">

        { Object.keys(taxpayerSelected).length === 0 ? 
          (
            <div className="alert alert-danger col-md-12 text-center" role="alert">
              No existe información del contribuyente consultado
            </div>
          )
          :
          (
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-12 mb-3">
                  <button
                    className="btn btn-primary"
                    onClick={()=> handleModal(<TaxpayerForm taxpayer={ taxpayerSelected }/>, true)}
                  >
                    Editar datos
                  </button>
                </div>
                
                <div className="col-md-4">
                  <p>
                    <FontAwesomeIcon icon={"user"}></FontAwesomeIcon> Nombre/Razón social
                    <br/>
                    <span className="text-muted">
                      { businessName }
                    </span>
                  </p>
                </div>

                <div className="col-md-4">
                  <p>
                    <FontAwesomeIcon icon={"address-card"}></FontAwesomeIcon> RFC
                    <br/>
                    <span className="text-muted">
                      { rfc }                 
                    </span>
                  </p>
                </div>
                <div className="col-md-4">
                  <p>
                  <FontAwesomeIcon icon={"user-tie"} />Profesión
                    <br/>
                    <span className="text-muted">
                      { profession.description }                 
                    </span>
                  </p>
                </div>

                <div className="col-md-4">
                  <p>
                    <FontAwesomeIcon icon={"phone-alt"}></FontAwesomeIcon> Teléfono
                    <br/>
                    <span className="text-muted">
                      { phoneMobile || 'No proporcionado' }                 
                    </span>
                  </p>
                </div>

                <div className="col-md-4">
                  <p>
                    <FontAwesomeIcon icon={"envelope"}></FontAwesomeIcon> Correo
                    <br/>
                    <span className="text-muted">
                      { emailNotification || 'No proporcionado' }                 
                    </span>
                  </p>
                </div>

                { regimes.length > 0 && (
                    <div className="col-md-4">
                      <p>
                        <FontAwesomeIcon icon={"address-book"}></FontAwesomeIcon> Régimenes
                        <br/>
                        <span className="text-muted">
                          {
                            regimes.map((regime, index) =>{
                              return(
                                <li key={ index }>
                                  { regime.officialName }
                                </li>
                              )
                            })
                          }
                        </span>
                      </p>
                    </div>
                  )
                }

                <div className="col-md-4">
                  <p>
                    <FontAwesomeIcon icon={"address-card"}></FontAwesomeIcon> Tipo de contribuyente
                    <br/>
                    <span className="text-muted">
                      {
                        serviceTypes.map((service, index) => {
                          return(
                            <li key={ index }>
                              { service.description }
                            </li>
                          )
                        })
                      }          
                    </span>
                  </p>
                </div>
              </div>

              <nav className="mt-4">
                <div className="nav nav-tabs" id="nav-tab" role="tablist">
                  <a className="nav-item nav-link active" id="nav-identifiers-tab" data-toggle="tab" href="#nav-identifiers" role="tab" aria-controls="nav-identifiers" aria-selected="true">
                    Identificadores
                  </a>
                  <a className="nav-item nav-link" id="nav-positive-balance-tab" data-toggle="tab" href="#nav-positive-balance" role="tab" aria-controls="nav-positive-balance" aria-selected="true">
                    Saldos a Favor
                  </a>
                  <a className="nav-item nav-link" id="nav-serie-folios-tab" data-toggle="tab" href="#nav-serie-folios" role="tab" aria-controls="nav-serie-folios" aria-selected="true">
                    Series y Folios
                  </a>
                  <a className="nav-item nav-link" id="nav-fiscal-files-tab" data-toggle="tab" href="#nav-fiscal-files" role="tab" aria-controls="nav-fiscal-files" aria-selected="true">
                    Documentos Fiscales
                  </a>
                  <a className="nav-item nav-link" id="nav-address-tax-tab" data-toggle="tab" href="#nav-address-tax" role="tab" aria-controls="nav-address-tax" aria-selected="true">
                    Direcciones Fiscales
                  </a>
                </div>
              </nav>
              <div className="tab-content" id="nav-tabContent">
                <div className="tab-pane fade show active" id="nav-identifiers" role="tabpanel" aria-labelledby="nav-identifiers-tab">
                  <IdentifiersDetailsScreen />
                </div>
                <div className="tab-pane fade" id="nav-positive-balance" role="tabpanel" aria-labelledby="nav-positive-balance-tab">
                  <PositiveBalanceDetailsScreen />
                </div>
                <div className="tab-pane fade" id="nav-serie-folios" role="tabpanel" aria-labelledby="nav-serie-folios-tab">
                  <SeriesFoliosDetailsScreen />
                </div>
                <div className="tab-pane fade" id="nav-fiscal-files" role="tabpanel" aria-labelledby="nav-fiscal-files-tab">
                  <div className="row">
                    <CertificateDetailsScreen
                      taxpayerId={ taxpayerId }
                    />

                    <EfirmaDetailsScreen
                      taxpayerId={ taxpayerId }
                    />
                  </div>
                </div>
                <div className="tab-pane fade" id="nav-address-tax" role="tabpanel" aria-labelledby="nav-address-tax-tab">
                  <TaxAddressDetailsScreen />
                </div>

              </div>  
            </div>
          )
        }
      </div>
    </div>
  )
}

export default withRouter( TaxpayerDetailsScreen );