export const initialoptionIsr = {
  amount: 0,
  fixedFee: 0,
  isrDetermined: 0,
  porcentage: 0,
  lowerLimit: 0,
}

export const obtainIsrPorcetaje = ( columns, amount, year ) => {
  let option = {}
  columns.forEach(column => {
    if(parseFloat(amount) >= column.lowerLimit && column.year === parseInt(year)){
      const calculateAmount = (parseFloat(amount) * parseFloat(column.porcentage)).toFixed(6);
      const isrDetermined = (parseFloat(calculateAmount) + parseFloat(column.fixedFee)).toFixed(6) 
      option = {
        ...column,
        amount: calculateAmount,
        isrDetermined: isrDetermined
      }
    }
  });

  if(Object.keys(option).length === 0){
    option = initialoptionIsr;
  }

  return option;
}