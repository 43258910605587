import React, { useEffect } from 'react';
import { TextField } from '@material-ui/core';
import NumberFormat from 'react-number-format'; 

const CanceledIncome = ({ canceledIncomes, setFormValuesDetailsSummary }) => {

  useEffect(() => {
    setFormValuesDetailsSummary(prevState => {
      return{
        ...prevState,
      }
    })
  }, [canceledIncomes, setFormValuesDetailsSummary])

  return (
    <div className="row">
      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2">
        <div className="card">
          <div className="card-header">
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <h5>Ingresos Cancelados</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2">
        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th>UUID</th>
                <th>Fecha de timbrado</th>
                <th>Fecha de cobro</th>
                <th>Cliente</th>
                <th>Conceptos</th>
                <th>Folio</th>
                <th>Total Recibido</th>
              </tr>
            </thead>
            <tbody>
              {
                canceledIncomes.map((canceledIncome, index) => {
                  return (
                    <tr key={ index } className="text-center">
                      <td> { canceledIncome.uuid } </td>
                      <td> { canceledIncome.stampAt}</td>
                      <td> { canceledIncome.paymentAt } </td>
                      <td>
                        {canceledIncome.receivedRfc}
                        <br />
                        {canceledIncome.receivedFullName}
                      </td>
                      <td>
                        <TextField
                          label="Concepto" variant="outlined" size="small" autoComplete="off" fullWidth={ true }
                          select
                          SelectProps={{
                            native: true,
                          }}
                          readOnly
                        >
                          {
                            canceledIncome.concepts.map((concept, i) => {
                              return (
                                <option
                                  key={ i }
                                >
                                  { concept.claveProdServ ? `${ concept.claveProdServ } - ` : '' } 
                                  { concept.descripcion }
                                </option>
                              )
                            })
                          }
                        </TextField>
                      </td>
                      <td>{ canceledIncome.folio }</td>
                      <td><NumberFormat value={parseFloat(canceledIncome.total).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default CanceledIncome;