import { createStore, applyMiddleware, compose } from "redux";
import thunk from 'redux-thunk';
import { RootReducer } from "../reducers/rootReducer";

const composeEnhancers = (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

export const store = createStore(
  RootReducer,
  composeEnhancers(
    applyMiddleware( thunk )
  )
);