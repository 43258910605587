import { TextField } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useForm } from '../../../../../../../../../hooks/useForm';
import NumberFormat from 'react-number-format';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { complementsAddExpense } from '../../../../../../../../../actions/accounting/complement';

const AuthorizaedDeductions = ({ consideredExpenses, formValuesDetailsSummary, setFormValuesDetailsSummary }) => {

  const { expenseDepresiationTypes } = useSelector(state => state.expenseDepresiation)

  const { month, year, regimeSatCode } = formValuesDetailsSummary;
  const dispatch = useDispatch();
  const { formValues, setFormValues } = useForm({
    ivaTranslateds: 0.00,
    subtotal: 0.00,
    total: 0.00,
    totalIVATraslateds: {},
  });

  const { ivaTranslateds, subtotal, total, totalIVATraslateds } = formValues;

  useEffect(() => {
    let totalSum = 0.00;
    let subtotalSum = 0.00;
    let ivaTrasSum = 0.00;
    let subPredialSum = 0.00;

    let totalSumRate0 = 0.00;
    let subtotalSumRate0 = 0.00;
    let totalSumRate8 = 0.00;
    let subtotalSumRate8 = 0.00;
    let totalSumRate16 = 0.00;
    let subtotalSumRate16 = 0.00;

    let totalTax8 = 0.00;
    let totalTax16 = 0.00;

    let totalSumRateTras = 0.00;

    let depresiations = [];

    let complements = [];

    let informationDiots = [];

    if (consideredExpenses.length > 0) {
      consideredExpenses.forEach((expense, i) => {
        let paymentMethod = expense.paymentMethod.key
        let wayOfPayment = expense.wayOfPayment.key
        //console.log(paymentMethod)
        if (paymentMethod === 'PPD') {
          complements.push(expense)
          return false;
        }

        let informationDiot = {
          rfc: expense.issuedRfc,
          information: {
            thirdPartyType: 'Proveedor Nacional',
            operationType: 'Otros',
            fiscalId: null,
            foreignName: null,
            countryCode: null,
            country: null,
            nationality: null,
            expenses1516: 0.00,
            expenses15: 0.00,
            amountPaidNotCreditable1516: 0.00,
            expenses1011: 0.00,
            expenses10: 0.00,
            expenseForeing: 0.00,
            amountPaidNotCreditable1011: 0.00,
            amountPaidNotCreditableForeing: 0.00,
            imported1516: 0.00,
            importedNotCreditable1516: 0.00,
            imported1011: 0.00,
            importedNotCreditable1011: 0.00,
            importedExents: 0.00,
            expenses0: 0.00,
            expensesExents: 0.00,
            ivaRetained: 0.00,
            ivaDiscount: 0.00,
          }
        }

        let informationDiotLoaded = informationDiots.filter(e => e.rfc === expense.issuedRfc)[0]

        if (expense.thirdPartie !== null && !expense.thirdPartie.isNational) {
          //console.log("es extranjero")
          informationDiot['information']['thirdPartyType'] = 'Proveedor Extranjero';
          informationDiot['information']['fiscalId'] = expense.thirdPartie.idFiscal;
          informationDiot['information']['foreignName'] = expense.thirdPartie.businessName;
        }

        if (informationDiotLoaded) {
          informationDiot = informationDiotLoaded
        }



        let totalIvaTras = 0.00;

        if (!expense.expenseFixedAssetType || regimeSatCode === '625' || regimeSatCode === '621' || regimeSatCode === '626') {
          totalSum = (parseFloat(totalSum) + parseFloat(expense.total)).toFixed(6);
          subtotalSum = (parseFloat(subtotalSum) + parseFloat(expense.subTotal)).toFixed(6);
          if (expense.expenseKeyType === 'PREDIAL') {
            subPredialSum = (parseFloat(subPredialSum) + parseFloat(expense.subTotal)).toFixed(6);
          }
          expense.concepts.forEach((concept) => {

            let discount = concept.descuentoSpecified ? concept.descuento : 0

            expense.subTotal = parseFloat(expense.subTotal) - discount;

            subtotalSum = parseFloat(subtotalSum) - discount;

            if (concept.impuestos) {

              if (concept.impuestos.traslados == null) {
                informationDiot['information']['expensesExents'] = (parseFloat(informationDiot['information']['expensesExents']) + parseFloat(expense.subTotal)).toFixed(2);
              } else {

                concept.impuestos.traslados.forEach(taxe => {
                  if (taxe.impuesto === '002') {
                    let importe = taxe.importe === undefined ? 0.00 : taxe.importe

                    totalIvaTras = (parseFloat(totalIvaTras) + parseFloat(importe)).toFixed(2);
                    totalSumRateTras = (parseFloat(totalSumRateTras) + parseFloat(importe)).toFixed(2);
                    //Solo si es rif se agraga el del 8% y para cuando no es se agrega al del 16
                    if (taxe.tasaOCuota === 0.16) {
                      totalSumRate16 = (parseFloat(totalSumRate16) + parseFloat(expense.total)).toFixed(2);
                      subtotalSumRate16 = (parseFloat(subtotalSumRate16) + parseFloat(taxe.base)).toFixed(2);
                      totalTax16 = (parseFloat(totalTax16) + parseFloat(importe)).toFixed(2);

                      if (expense.issuedRfc === 'XEXX010101000') {
                        informationDiot['information']['expenses1516'] = (parseFloat(informationDiot['information']['expenses1516']) + parseFloat(expense.subTotal)).toFixed(2)
                      }

                      informationDiot['information']['expenses1516'] = (parseFloat(informationDiot['information']['expenses1516']) + parseFloat(taxe.base)).toFixed(2)
                    }
                    if (taxe.tasaOCuota === 0) {
                      if (!expense.satDownloaded) {
                        subtotalSumRate0 = (parseFloat(subtotalSumRate0) + parseFloat(expense.subTotal) - discount).toFixed(2);
                        informationDiot['information']['expenses0'] = (parseFloat(informationDiot['information']['expenses0']) + parseFloat(expense.subTotal) - discount).toFixed(2)
                      } else {
                        subtotalSumRate0 = (parseFloat(subtotalSumRate0) + parseFloat(taxe.base)).toFixed(2);
                        informationDiot['information']['expenses0'] = (parseFloat(informationDiot['information']['expenses0']) + parseFloat(taxe.base)).toFixed(2)
                      }
                      totalSumRate0 = (parseFloat(totalSumRate0) + parseFloat(expense.total)).toFixed(2);
                    }
                    if (taxe.tasaOCuota === 0.08 && regimeSatCode === '621') {
                      totalSumRate8 = (parseFloat(totalSumRate8) + parseFloat(expense.total)).toFixed(2);
                      subtotalSumRate8 = (parseFloat(subtotalSumRate8) + parseFloat(taxe.base)).toFixed(2);
                      totalTax8 = (parseFloat(totalTax8) + parseFloat(importe)).toFixed(2)

                      informationDiot['information']['expenseForeing'] = (parseFloat(informationDiot['information']['expenseForeing']) + parseFloat(taxe.base)).toFixed(2);
                    }
                    if (taxe.tasaOCuota === 0.08 && regimeSatCode !== '621') {
                      totalSumRate16 = (parseFloat(totalSumRate16) + parseFloat(expense.total)).toFixed(2);
                      subtotalSumRate16 = (parseFloat(subtotalSumRate16) + parseFloat(taxe.base)).toFixed(2);
                      totalTax16 = (parseFloat(totalTax16) + parseFloat(importe)).toFixed(2);

                      if (expense.issuedRfc === 'XEXX010101000') {
                        informationDiot['information']['expenseForeing'] = (parseFloat(informationDiot['information']['expenseForeing']) + parseFloat(expense.subTotal)).toFixed(2)
                      }

                      informationDiot['information']['expenseForeing'] = (parseFloat(informationDiot['information']['expenseForeing']) + parseFloat(taxe.base)).toFixed(2);
                    }
                  }
                })
              }
            }
            else {
              subtotalSumRate0 = (parseFloat(subtotalSumRate0) + parseFloat(concept.importe)).toFixed(2);
              totalSumRate0 = (parseFloat(totalSumRate0) + parseFloat(concept.importe)).toFixed(2);
            }
          })
        }

        if (expense.expenseFixedAssetType && (regimeSatCode === '612' || regimeSatCode === '606')) {
          const expenseDepresiationType = expenseDepresiationTypes.filter(e => (e.expenseKey === expense.expenseKeyType))[0];
          let depresiation = {};
          let expenseSubtotal = parseFloat(expense.subTotal);

          if (expenseDepresiationType.maxAmount && parseFloat(expenseSubtotal) >= parseFloat(expenseDepresiationType.maxAmount)) {
            expenseSubtotal = parseFloat(expenseDepresiationType.maxAmount);
            totalIvaTras = expenseSubtotal * .16;
            expense.subTotal = expenseSubtotal;
            expense.total = expenseSubtotal + totalIvaTras;
          } else {
            totalIvaTras = expenseSubtotal * .16;
          }

          const depresiationTheMonth = (expenseSubtotal * expenseDepresiationType.porcentage) / 12;

          const date = new Date(year, month, 0);

          depresiation['expenseKeyType'] = expense.expenseKeyType;
          depresiation['months'] = expenseDepresiationType.depresiationMonths;
          depresiation['depresiationForMonth'] = depresiationTheMonth;
          depresiation['monthReal'] = 0;
          depresiation['depresiationReal'] = 0;

          depresiation['clasificateAt'] = date;

          depresiation['updateAt'] = date;

          depresiations.push(depresiation)
        }

        ivaTrasSum = (parseFloat(ivaTrasSum) + parseFloat(totalIvaTras)).toFixed(2);

        setFormValues(prevState => {
          return {
            ...prevState,
            totalIVATraslateds: {
              ...prevState.totalIVATraslateds,
              [i]: totalIvaTras
            }
          }
        })

        if (!informationDiotLoaded) {
          informationDiots.push(informationDiot);
        } else {
          let index = informationDiots.findIndex(e => e.rfc === expense.issuedRfc)
          informationDiots[index] = informationDiot
        }

      });
      dispatch(complementsAddExpense(complements))
      setFormValues(prevState => {
        return {
          ...prevState,
          ivaTranslateds: ivaTrasSum,
          subtotal: subtotalSum,
          total: totalSum
        }
      });
    } else {
      setFormValues(prevState => {
        return {
          ...prevState,
          ivaTranslateds: '0.00',
          subtotal: '0.00',
          total: '0.00'
        }
      });
    }

    setFormValuesDetailsSummary(prevState => {
      return {
        ...prevState,
        total: totalSum,
        depresiations: depresiations,
        informationDiots: informationDiots,
        subtotalExpenseAutorized: subtotalSum,
        totalExpenseAutorized: totalSum,
        subtotalExpenseRate0: subtotalSumRate0,
        totalExpenseRate0: totalSumRate0,
        subtotalExpenseRate8: subtotalSumRate8,
        totalExpenseRate8: totalSumRate8,
        subtotalExpenseRate16: subtotalSumRate16,
        totalExpenseRate16: totalSumRate16,
        expenseTotalTaxeTras: totalSumRateTras,
        totalIVAExpenseAutorized: ivaTrasSum,
        subtotalPredialAutorized: subPredialSum,
        totalIvaTraslatedExpense16: totalTax16,
        totalIvaTraslatedExpense8: totalTax8
      }
    })
  }, [consideredExpenses, expenseDepresiationTypes, month, regimeSatCode, setFormValues, setFormValuesDetailsSummary, year])

  return (
    <div className="row">
      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2">
        <div className="card">
          <div className="card-header">
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <h5>Deducciones Autorizadas</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2">
        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr className="text-center">
                <th>Fecha de pago</th>
                <th>Proveedor</th>
                <th>Folio</th>
                <th>Conceptos</th>
                <th>Clasificación</th>
                <th>Subtotal</th>
                <th>IVA</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              {
                consideredExpenses.map((considered, index) => {
                  return (parseFloat(considered.total) > 0 &&
                    <tr key={index} className={`text-center ${considered.paymentMethod.key === 'PPD' && "text-info"}`}>
                      <td> {considered.paymentAt} </td>
                      <td>
                        {considered.issuedRfc}
                        <br />
                        {considered.issuedFullName}
                      </td>
                      <td>{considered.folio}</td>
                      <td>
                        <TextField
                          label="Concepto" variant="outlined" size="small" autoComplete="off" fullWidth={true}
                          select
                          SelectProps={{
                            native: true,
                          }}
                          readOnly
                        >
                          {
                            considered.concepts.map((concept, i) => {
                              return (
                                <option
                                  key={i}
                                >
                                  {concept.claveProdServ ? `${concept.claveProdServ} - ` : ''}
                                  {concept.descripcion}
                                </option>
                              )
                            })
                          }
                        </TextField>
                      </td>
                      <td><strong>{considered.description}</strong></td>
                      <td><NumberFormat value={parseFloat(considered.subTotal).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td>
                      {/* <td><NumberFormat value={parseFloat(considered.concepts[0]?.impuestos?.traslados[0]?.importe).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td> */}
                      <td><NumberFormat value={parseFloat(totalIVATraslateds[index]).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td>
                      <td><NumberFormat value={parseFloat(considered.total).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td>
                    </tr>
                  )
                })
              }
              <tr className="text-center">
                <td colSpan="5" className="text-right"><strong>Totales</strong></td>
                <td><NumberFormat value={parseFloat(subtotal).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td>
                <td><NumberFormat value={parseFloat(ivaTranslateds).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td>
                <td><NumberFormat value={parseFloat(total).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default AuthorizaedDeductions;
