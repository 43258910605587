import React, { Fragment } from 'react';
import { TaxAddressForm } from '../../../customer/taxAddress/TaxAddressForm';

export const ModuleAddressScreen = ({isDiasabled = false, saleFormValues, saleSetFormValues,msgErrors}) => {
  
  const {address} = saleFormValues;
  
  return (
    <Fragment>
      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3">
        <h5>Domicilio fiscal</h5>
        <hr />
      </div>

      <TaxAddressForm
        address={address}
        setFormValues={saleSetFormValues}
        isDisabled={isDiasabled}
        msgErrors={msgErrors}
      />
    </Fragment>
  )
}
