import React, { useEffect } from 'react';
import { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

const Retention = ({formValuesDetailsSummary, setFormValuesDetailsSummary, autorizedReport = false}) => {
  const {regimeSatCode} = useParams();

  const { retentions } = useSelector(state => state.retention);
  const { typeRetention } = useSelector(state => state.typeRetention);

  const { taxISRPlataformsTechnologies, taxIVAPlataformsTechnologies } = formValuesDetailsSummary;

  
  // const [retentionPlataforms, setRetentionPlataforms] = useState([]);


  useEffect(() => {
    // setRetentionPlataforms([]);
    let totalPassengerPlataform = 0.00;
    let totalDealerPlataform = 0.00;
    let isrGround = 0.00;
    
    let totalLodgingPlataform = 0.00;
    let isrLodging = 0.00;
    
    let totalAlienationPlataform = 0.00;
    let totalLendingPlataform = 0.00;
    let isrAlienation = 0.00;
    
    let ivaPlataform = 0.00;

    if (regimeSatCode === '625') {
      if (retentions.length > 0) {
        retentions.forEach(retention => {
          let isrConsidered = false;
          if (typeRetention.includes(retention.cveRetenc.key.toString())) {
            // setRetentionPlataforms(prevState=> {return[...prevState, retention]});
            retention.complement.Any.forEach(complement => {            
              if (complement['ServiciosPlataformasTecnologicas'] !== undefined) {
                if(Array.isArray(complement['ServiciosPlataformasTecnologicas']['Servicios']['DetallesDelServicio'])){
                  complement['ServiciosPlataformasTecnologicas']['Servicios']['DetallesDelServicio'].forEach(detalle => {

                    if (detalle['@TipoDeServ'] === '01') {
                      if (detalle['ImpuestosTrasladadosdelServicio'] === undefined){
                        totalPassengerPlataform = (parseFloat(totalPassengerPlataform) + parseFloat(detalle['@PrecioServSinIVA'])).toFixed(2)
                      }
                      if (detalle['ImpuestosTrasladadosdelServicio'] !== undefined) {
                      totalPassengerPlataform = (parseFloat(totalPassengerPlataform) + parseFloat(detalle['ImpuestosTrasladadosdelServicio']['@Base'])).toFixed(2)
                      }
                      retention.totals.ImpRetenidos.forEach(tax => {
                        if (!isrConsidered) {
                          if (tax.Impuesto === 0) {
                            isrGround = (parseFloat(isrGround) + parseFloat(parseFloat(tax.montoRet))).toFixed(2);
                            isrConsidered = true;
                          }
                          if (tax.ImpuestoRet === 0) {
                            isrGround = (parseFloat(isrGround) + parseFloat(parseFloat(tax.MontoRet))).toFixed(2);
                            isrConsidered = true;
                          }
                        }
                      })
                    }
                    if(detalle['@TipoDeServ'] === '02' || detalle['@TipoDeServ'] === '03'){
                      if (detalle['ImpuestosTrasladadosdelServicio'] === undefined){
                        totalDealerPlataform = (parseFloat(totalDealerPlataform) + parseFloat(detalle['@PrecioServSinIVA'])).toFixed(2)
                      }
                      if (detalle['ImpuestosTrasladadosdelServicio'] !== undefined) {
                        totalDealerPlataform = (parseFloat(totalDealerPlataform) + parseFloat(detalle['ImpuestosTrasladadosdelServicio']['@Base'])).toFixed(2) 
                      }
                      retention.totals.ImpRetenidos.forEach(tax => {
                        if (!isrConsidered) {
                          if (tax.Impuesto === 0) {
                            isrGround = (parseFloat(isrGround) + parseFloat(tax.montoRet)).toFixed(2);
                            isrConsidered = true;
                          }
                          if (tax.ImpuestoRet === 0) {
                            isrGround = (parseFloat(isrGround) + parseFloat(tax.MontoRet)).toFixed(2);
                            isrConsidered = true;
                          }
                        }
                      })
                    }
                    if(detalle['@TipoDeServ'] === '04'){
                      if (detalle['ImpuestosTrasladadosdelServicio'] === undefined){
                        totalLodgingPlataform = (parseFloat(totalLodgingPlataform) + parseFloat(detalle['@PrecioServSinIVA'])).toFixed(2)
                      }
                      if (detalle['ImpuestosTrasladadosdelServicio'] !== undefined) {
                      totalLodgingPlataform = (parseFloat(totalLodgingPlataform) + parseFloat(detalle['ImpuestosTrasladadosdelServicio']['@Base'])).toFixed(2)
                      }
                      retention.totals.ImpRetenidos.forEach(tax => {
                        if (!isrConsidered) {
                          if (tax.Impuesto === 0) {
                            isrLodging = (parseFloat(isrLodging) + parseFloat(tax.montoRet)).toFixed(2);
                            isrConsidered = true;
                          }
                          if (tax.ImpuestoRet === 0) {
                            isrLodging = (parseFloat(isrLodging) + parseFloat(tax.MontoRet)).toFixed(2);
                            isrConsidered = true;
                          }
                        }
                      })
                    }
                    if(detalle['@TipoDeServ'] === '05'){
                      if (detalle['ImpuestosTrasladadosdelServicio'] === undefined){
                        totalAlienationPlataform = (parseFloat(totalAlienationPlataform) + parseFloat(detalle['@PrecioServSinIVA'])).toFixed(2)
                      }
                      if (detalle['ImpuestosTrasladadosdelServicio'] !== undefined) {
                      totalAlienationPlataform = (parseFloat(totalAlienationPlataform) + parseFloat(detalle['ImpuestosTrasladadosdelServicio']['@Base'])).toFixed(2)
                      }
                      retention.totals.ImpRetenidos.forEach(tax => {
                        if (!isrConsidered) {
                          if (tax.Impuesto === 0) {
                            isrAlienation = (parseFloat(isrAlienation) + parseFloat(tax.montoRet)).toFixed(2);
                            isrConsidered = true;
                          }
                          if (tax.ImpuestoRet === 0) {
                            isrAlienation = (parseFloat(isrAlienation) + parseFloat(tax.MontoRet)).toFixed(2);
                            isrConsidered = true;
                          }
                        }
                      })
                    }
                    if(detalle['@TipoDeServ'] === '06'){
                      if (detalle['ImpuestosTrasladadosdelServicio'] === undefined){
                        totalLendingPlataform = (parseFloat(totalLendingPlataform) + parseFloat(detalle['@PrecioServSinIVA'])).toFixed(2)
                      }
                      if (detalle['ImpuestosTrasladadosdelServicio'] !== undefined) {
                      totalLendingPlataform = (parseFloat(totalLendingPlataform) + parseFloat(detalle['ImpuestosTrasladadosdelServicio']['@Base'])).toFixed(2)
                      }
                      retention.totals.ImpRetenidos.forEach(tax => {
                        if (!isrConsidered) {
                          if (tax.Impuesto === 0) {
                            isrAlienation = (parseFloat(isrAlienation) + parseFloat(tax.montoRet)).toFixed(2);
                            isrConsidered = true;
                          }
                          if (tax.ImpuestoRet === 0) {
                            isrAlienation = (parseFloat(isrAlienation) + parseFloat(tax.MontoRet)).toFixed(2);
                            isrConsidered = true;
                          }
                        }
                      })
                    }                
                  });
                }
                else{
                  let detalle = complement['ServiciosPlataformasTecnologicas']['Servicios']['DetallesDelServicio'];
                  if (detalle['@TipoDeServ'] === '01') {
                    if (detalle['ImpuestosTrasladadosdelServicio'] === undefined){
                      totalPassengerPlataform = (parseFloat(totalPassengerPlataform) + parseFloat(detalle['@PrecioServSinIVA'])).toFixed(2)
                    }
                    if (detalle['ImpuestosTrasladadosdelServicio'] !== undefined) {
                    totalPassengerPlataform = (parseFloat(totalPassengerPlataform) + parseFloat(detalle['ImpuestosTrasladadosdelServicio']['@Base'])).toFixed(2)
                    }
                    retention.totals.ImpRetenidos.forEach(tax => {
                      if (!isrConsidered) {
                        if (tax.Impuesto === 0) {
                          isrGround = (parseFloat(isrGround) + parseFloat(tax.montoRet)).toFixed(2)
                          isrConsidered = true;
                        }
                        if (tax.ImpuestoRet === 0) {
                          isrGround = (parseFloat(isrGround) + parseFloat(tax.MontoRet)).toFixed(2)
                          isrConsidered = true;
                        }
                      }
                    })
                  }
                  if(detalle['@TipoDeServ'] === '02' || detalle['@TipoDeServ'] === '03'){
                    if (detalle['ImpuestosTrasladadosdelServicio'] === undefined){
                      totalDealerPlataform = (parseFloat(totalDealerPlataform) + parseFloat(detalle['@PrecioServSinIVA'])).toFixed(2)
                    }
                    if (detalle['ImpuestosTrasladadosdelServicio'] !== undefined) {
                      totalDealerPlataform = (parseFloat(totalDealerPlataform) + parseFloat(detalle['ImpuestosTrasladadosdelServicio']['@Base'])).toFixed(2) 
                    }
                    retention.totals.ImpRetenidos.forEach(tax => {
                      if (!isrConsidered) {
                        if (tax.Impuesto === 0) {
                          isrGround = (parseFloat(isrGround) + parseFloat(tax.montoRet)).toFixed(2);
                          isrConsidered = true;
                        }
                        if (tax.ImpuestoRet === 0) {
                          isrGround = (parseFloat(isrGround) + parseFloat(tax.MontoRet)).toFixed(2);
                          isrConsidered = true;
                        }
                      }
                    })
                  }
                  if(detalle['@TipoDeServ'] === '04'){
                    if (detalle['ImpuestosTrasladadosdelServicio'] === undefined){
                      totalLodgingPlataform = (parseFloat(totalLodgingPlataform) + parseFloat(detalle['@PrecioServSinIVA'])).toFixed(2)
                    }
                    if (detalle['ImpuestosTrasladadosdelServicio'] !== undefined) {
                    totalLodgingPlataform = (parseFloat(totalLodgingPlataform) + parseFloat(detalle['ImpuestosTrasladadosdelServicio']['@Base'])).toFixed(2)
                    }
                    retention.totals.ImpRetenidos.forEach(tax => {
                      if (!isrConsidered) {
                        if (tax.Impuesto === 0) {
                          isrLodging = (parseFloat(isrLodging) + parseFloat(tax.montoRet)).toFixed(2);
                          isrConsidered = true;
                        }
                        if (tax.ImpuestoRet === 0) {
                          isrLodging = (parseFloat(isrLodging) + parseFloat(tax.MontoRet)).toFixed(2);
                          isrConsidered = true;
                        }
                      }
                    })
                  }
                  if(detalle['@TipoDeServ'] === '05'){
                    if (detalle['ImpuestosTrasladadosdelServicio'] === undefined){
                      totalAlienationPlataform = (parseFloat(totalAlienationPlataform) + parseFloat(detalle['@PrecioServSinIVA'])).toFixed(2)
                    }
                    if (detalle['ImpuestosTrasladadosdelServicio'] !== undefined) {
                    totalAlienationPlataform = (parseFloat(totalAlienationPlataform) + parseFloat(detalle['ImpuestosTrasladadosdelServicio']['@Base'])).toFixed(2)
                    }
                    retention.totals.ImpRetenidos.forEach(tax => {
                      if (!isrConsidered) {
                        if (tax.Impuesto === 0) {
                          isrAlienation = (parseFloat(isrAlienation) + parseFloat(tax.montoRet)).toFixed(2);
                          isrConsidered = true;
                        }
                        if (tax.ImpuestoRet === 0) {
                          isrAlienation = (parseFloat(isrAlienation) + parseFloat(tax.MontoRet)).toFixed(2);
                          isrConsidered = true;
                        }
                      }
                    })
                  }
                  if(detalle['@TipoDeServ'] === '06'){
                    if (detalle['ImpuestosTrasladadosdelServicio'] === undefined){
                      totalLendingPlataform = (parseFloat(totalLendingPlataform) + parseFloat(detalle['@PrecioServSinIVA'])).toFixed(2)
                    }
                    if (detalle['ImpuestosTrasladadosdelServicio'] !== undefined) {
                    totalLendingPlataform = (parseFloat(totalLendingPlataform) + parseFloat(detalle['ImpuestosTrasladadosdelServicio']['@Base'])).toFixed(2)
                    }
                    retention.totals.ImpRetenidos.forEach(tax => {
                      if (!isrConsidered) {
                        if (tax.Impuesto === 0) {
                          isrAlienation = (parseFloat(isrAlienation) + parseFloat(tax.montoRet)).toFixed(2);
                          isrConsidered = true;
                        }
                        if (tax.ImpuestoRet === 0) {
                          isrAlienation = (parseFloat(isrAlienation) + parseFloat(tax.MontoRet)).toFixed(2);
                          isrConsidered = true;
                        }
                      }
                    })
                  }
                }
              }
            })
  
            retention.totals.ImpRetenidos.forEach(tax => {
              if (tax.Impuesto === 1) {
                ivaPlataform = (parseFloat(ivaPlataform) + parseFloat(tax.montoRet)).toFixed(2);
              }
              if (tax.ImpuestoRet === 1) {
                ivaPlataform = (parseFloat(ivaPlataform) + parseFloat(tax.MontoRet)).toFixed(2);
              }
            })
          }
  
          const isrJsonRetention = taxISRPlataformsTechnologies
  
          const ivaJsonRetention = taxIVAPlataformsTechnologies
  
          let totalPassengerPlataformFinal = totalPassengerPlataform

          if ( parseFloat(isrJsonRetention.serviceGround.totalPassengerPlataform) !== 0 && parseFloat(isrJsonRetention.serviceGround.totalPassengerPlataform) > parseFloat(totalPassengerPlataform)) {
            totalPassengerPlataformFinal = parseFloat(isrJsonRetention.serviceGround.totalPassengerPlataform).toFixed(2)
          }
  
          let totalDealerPlataformFinal = totalDealerPlataform
          if ( parseFloat(isrJsonRetention.serviceGround.totalDealerPlataform) !== 0 && parseFloat(isrJsonRetention.serviceGround.totalDealerPlataform) > parseFloat(totalDealerPlataform)) {
            totalDealerPlataformFinal = parseFloat(isrJsonRetention.serviceGround.totalDealerPlataform).toFixed(2)
          }
  
          let isrGroundFinal = isrGround;
          if (parseFloat(isrJsonRetention.serviceGround.totalRetained) !== 0 && parseFloat(isrJsonRetention.serviceGround.totalRetained) !== parseFloat(isrGround)) {
            isrGroundFinal = parseFloat(isrJsonRetention.serviceGround.totalRetained).toFixed(2)
          }
  
          let totalLodgingPlataformFinal = totalLodgingPlataform
          if ( parseFloat(isrJsonRetention.serviceLodging.totalLodgingPlataform) !== 0 && parseFloat(isrJsonRetention.serviceLodging.totalLodgingPlataform) > parseFloat(totalLodgingPlataform)) {
            totalLodgingPlataformFinal = parseFloat(isrJsonRetention.serviceLodging.totalLodgingPlataform).toFixed(2)
          }
  
          let isrLodgingFinal = isrLodging;
          if (parseFloat(isrJsonRetention.serviceLodging.totalRetained) !== 0 && parseFloat(isrJsonRetention.serviceLodging.totalRetained) !== parseFloat(isrLodging)) {
            isrLodgingFinal = parseFloat(isrJsonRetention.serviceLodging.totalRetained).toFixed(2)
          }
  
          let totalLendingPlataformFinal = totalLendingPlataform
          if ( parseFloat(isrJsonRetention.serviceAlienation.totalLendingPlataform) !== 0 && parseFloat(isrJsonRetention.serviceAlienation.totalLendingPlataform) > parseFloat(totalLendingPlataform)) {
            totalLendingPlataformFinal = parseFloat(isrJsonRetention.serviceAlienation.totalLendingPlataform).toFixed(2)
          }
  
          let totalAlienationPlataformFinal = totalAlienationPlataform
          if ( parseFloat(isrJsonRetention.serviceAlienation.totalAlienationPlataform) !== 0 && parseFloat(isrJsonRetention.serviceAlienation.totalAlienationPlataform) > parseFloat(totalAlienationPlataform)) {
            totalAlienationPlataformFinal = parseFloat(isrJsonRetention.serviceAlienation.totalAlienationPlataform).toFixed(2)
          }
  
          let isrAlienationFinal = isrAlienation;
          if (parseFloat(isrJsonRetention.serviceAlienation.totalRetained) !== 0 && parseFloat(isrJsonRetention.serviceAlienation.totalRetained) !== parseFloat(isrAlienation)) {
            isrAlienationFinal = parseFloat(isrJsonRetention.serviceAlienation.totalRetained).toFixed(2)
          }
  
          let ivaPlataformFinal = ivaPlataform;
          if (parseFloat(ivaJsonRetention.retentionPlataform) !== 0 && parseFloat(ivaJsonRetention.retentionPlataform) !== parseFloat(ivaPlataform)) {
            ivaPlataformFinal = parseFloat(ivaJsonRetention.retentionPlataform).toFixed(2)
          }
  
          setFormValuesDetailsSummary(prevState => {
            return{
              ...prevState,
              taxISRPlataformsTechnologies: {
                ...prevState.taxISRPlataformsTechnologies,
                serviceAlienation: {
                  ...prevState.taxISRPlataformsTechnologies.serviceAlienation,
                  totalLendingPlataform: totalLendingPlataformFinal,
                  totalAlienationPlataform: totalAlienationPlataformFinal,
                  totalRetained: isrAlienationFinal
                },
                serviceGround: {
                  ...prevState.taxISRPlataformsTechnologies.serviceGround,
                  totalPassengerPlataform: totalPassengerPlataformFinal,
                  totalDealerPlataform: totalDealerPlataformFinal,
                  totalRetained: isrGroundFinal
                },
                serviceLodging: {
                  ...prevState.taxISRPlataformsTechnologies.serviceLodging,
                  totalLodgingPlataform: totalLodgingPlataformFinal,
                  totalRetained: isrLodgingFinal
                }
              },
              taxIVAPlataformsTechnologies:{
                ...prevState.taxIVAPlataformsTechnologies,
                retentionPlataform: ivaPlataformFinal
              }
            }
          })
        });
      }
    }
  }, [retentions, setFormValuesDetailsSummary,typeRetention]);

  /* useEffect(() => {
    if(retentions.length > 0){
      const canceleds = canceledsRetentions(retentions);
      setFormValues(prevState => {
        return{
          ...prevState,
          canceledRetentions: [...canceleds]
        }
      })
    }
  }, [retentions, setFormValues]); */

  return (
    <div className="row">
      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2">
        <div className="card">
          <div className="card-header">
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <h5>Retenciones</h5>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2">
        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr className="text-center">
                <th>UUID</th>
                <th>Fecha de timbrado</th>
                <th>Retenedor</th>
                <th>Folio</th>
                <th>Tipo Retención</th>
                <th>IVA</th>
                <th>ISR</th>
                <th>Total Recibido</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {
                retentions.length === 0 ?
                (
                  <tr>
                    <td colSpan="8">
                      <div className="alert alert-primary text-center mb-0" role="alert">
                        No se ha encontrado información
                      </div>
                    </td>
                  </tr>
                )
                :
                (
                  retentions.map(retention => {
                    return( retention.totals.ImpRetenidos && typeRetention.includes(retention.cveRetenc.key.toString()) &&
                      <tr key={retention.id}>
                        <td align="center">{retention.uuid}</td>
                        <td align="center">{retention.tfdStampedAt}</td>
                        <td align="center">
                          {retention.issuedFullName}<br/>
                          {retention.issuedRfc}
                        </td>
                        <td align="center">
                          {retention.folioInt}
                        </td>
                        <td align="center">
                          {retention.cveRetenc.description}
                        </td>
                        <td>
                          { retention.totals.ImpRetenidos.map((total, index) => {
                             switch ( retention.retentionVersion) {
                              case "RETEN10":
                               return (
                                total.Impuesto === 1 &&  
                                  <Fragment key={index}>
                                  {parseFloat(total.montoRet)}
                                  </Fragment>
                                )
                              case "RETEN20":
                                return  (
                                  total.ImpuestoRet === 1  && 
                                    <Fragment key={index}>
                                    {parseFloat(total.MontoRet)}
                                    </Fragment>
                                )
                              }
                          })
                          }
                        </td>
                        <td>
                           { retention.totals.ImpRetenidos.map((total, index) => {
                             switch ( retention.retentionVersion) {
                                case "RETEN10":
                                 return (
                                  total.Impuesto === 0 &&  
                                    <Fragment key={index}>
                                    {parseFloat(total.montoRet)}
                                    </Fragment>
                                  )
                                case "RETEN20":
                                  return  (
                                    total.ImpuestoRet === 0  && 
                                      <Fragment key={index}>
                                      {parseFloat(total.MontoRet)}
                                      </Fragment>
                                  )
                                }
                          })
                          } 
                        </td>
                        <td align="center">
                          ${retention.totals.montoTotOperacion ?? retention.totals.MontoTotOperacion}
                        </td>
                      </tr>
                    )
                  })
                )
              }
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default Retention;
