import { types } from "../../types/types";

const initialState = {
  keyUnits: []
}

export const keyUnitReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.keyUnitsStartLoading:
      return{
        ...state,
        keyUnits: [ ...action.payload ]
      }

    default:
      return state;
  }
}