import React, { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DETAILS_TAXPAYER } from '../../../constants/Routes';
import { ModalContext } from '../../../modal/ModalContext';
import { TaxpayerForm } from './TaxpayerForm';
import { useDispatch, useSelector } from 'react-redux';
import { taxpayerActivationChange, taxpayerStartLoading } from '../../../../actions/taxpayers';
import { UseSwal } from '../../../../hooks/UseSwal';

const TaxpayerScreen = () => {
  const { handleModal } = useContext( ModalContext );
  const { taxpayers } = useSelector(state => state.taxpayer)

  const { changeActivationModal } = UseSwal();

  const dispatch = useDispatch();

  useEffect(() => {
      dispatch( taxpayerStartLoading() );
  }, [])

  return(
    <div className="container">

      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <div className="card-header">
            <h5>CONTRIBUYENTES</h5>
        </div>
      </div>

      <div className="col-md-12 mt-2">
        <button className="btn btn-primary float-right mb-3" onClick={()=> handleModal(<TaxpayerForm />, true)}>
          <FontAwesomeIcon className="fas" icon={"plus"}/> Agregar Contribuyente
        </button>
      </div>

      <div className="table-responsive col-md-12">
        <table className="table table-striped">
          <thead>
            <tr>
              <th scope="col">Nombre</th>
              <th scope="col">RFC</th>
              <th scope="col">Tipo servicio</th>
              <th scope="col">Estatus</th>
              <th scope="col">Acciones</th>
            </tr>
          </thead>
          <tbody>
            { taxpayers.length === 0 ? 
              (<tr>
                <td colSpan="6">
                  <div className="alert alert-primary text-center mb-0" role="alert">
                    No se ha agregado información
                  </div>
                </td>
              </tr>)
              :
              (
                taxpayers.map((taxpayer, index) => {
                  return(
                    <tr key={ index }>
                      <td>{taxpayer.businessName}</td>
                      <td>{taxpayer.rfc}</td>
                      <td>
                        {
                          taxpayer.serviceTypes.map((service, index) => {
                            return(
                              <div key={ index }>
                                <span className="badge badge-pill badge-info">
                                  { service.description }
                                </span><br />
                              </div>
                            )
                          })
                        }
                      </td>
                      <td>
                        <span className='badge badge-pill badge-success'>
                          Activo
                        </span>
                      </td>
                      <td>
                        <div className="row">
                          <Link
                            to={DETAILS_TAXPAYER(taxpayer.id)}
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Detalle del contribuyente"
                            className="btn btn-outline-primary btn-sm"
                          >
                            <FontAwesomeIcon className="fa-lg" icon={"user"}/>
                          </Link>
                          <button
                            data-toggle="tooltip"
                            data-placement="top"
                            title={ taxpayer.active ? "Desactivar contribuyente" : "Activar contribuyente" }
                            className={`btn btn-sm ml-2 ${ taxpayer.active ? "btn-outline-danger" : "btn-outline-success" }`}
                            onClick={ () => changeActivationModal( taxpayerActivationChange(taxpayer.id) ) }
                          >
                            <FontAwesomeIcon className="fa-lg" icon={ taxpayer.active ? "user-lock" : "user-check" }/>
                          </button>
                        </div>
                      </td>
                    </tr>
                  )
                })
              )
            }
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default TaxpayerScreen;