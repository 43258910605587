import { fetchWithToken } from "../../helpers/fetch";
import { saveModal } from "../../helpers/UseSwal";
import { types } from "../../types/types";

export const expenseRegisterStart = (expense, handleModal) => {
  return async (dispatch) => {
    saveModal(true);
    const resp = await fetchWithToken('api/Expense/create', expense, 'POST');
    const body = await resp.json();
    if (body.success) {
      dispatch(registerExpense(body.data))
      handleModal();
    }
    saveModal(false);
  }
}

export const registerExpense = (expense) => ({
  type: types.expenseStartRegister,
  payload: expense
});

export const expenseStartLoading = (period, refreshComplementPayments) => {
  return async (dispatch) => {
    const resp = await fetchWithToken(`api/Expense/get/${period.profileId}/${period.paymentAt}/${period.regimeSatCode}`);
    const body = await resp.json();
    dispatch(expensesFinish(true))
    if (body.success) {
      dispatch(expensesFinishStatus("Se cargaron correctamente", "success"))
      dispatch(expensesLoaded(body.data.sort(function (a, b) { return new Date(a.stampAt) - new Date(b.stampAt) })))
      refreshComplementPayments && refreshComplementPayments()
    } else {
      dispatch(expensesFinishStatus(body.error))
    }
  }
}

const expensesLoaded = (expenses) => ({
  type: types.expenseStartLoading,
  payload: expenses
})
const expensesFinish = (flag) => ({
  type: types.expenseFinishLoading,
  payload: flag
})
const expensesFinishStatus = (msn) => ({
  type: types.expenseFinishStatus,
  payload: msn
})

export const expenseUpdateStart = (expense, handleModal, refreshComplementPayments) => {
  ////console.log(expense.authorizations[0].expenseTypeId);
  return async (dispatch) => {
    saveModal(true);
    const resp = await fetchWithToken('api/Expense/update', expense, 'POST');
    const body = await resp.json();
    if (body.success) {
      dispatch(updateExpense(body.data))
      refreshComplementPayments && refreshComplementPayments()
      handleModal();
    }
    saveModal(false);
  }
}

const updateExpense = (expense) => ({
  type: types.expenseStartUpdate,
  payload: expense
})

export const changeAuthorizationExpense = (expense, refreshComplementPayments) => {
  return async (dispatch) => {
    const resp = await fetchWithToken('api/Expense/updateAuthorization', expense, 'POST');
    const body = await resp.json();
    if (body.success) {
      dispatch(expenseChangeAuthorization(expense))
      refreshComplementPayments && refreshComplementPayments()
    }
  }
}

const expenseChangeAuthorization = (expense) => ({
  type: types.expenseStartChangeAutorization,
  payload: expense
})