import { Base64 } from 'js-base64';
import JSZip from 'jszip';
import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { certificatesStartLoading } from '../../../../actions/taxpayers/certificates';
import { certificatesNotFiels } from '../../../../helpers/certificate';
import { useForm } from '../../../../hooks/useForm';
import { ModalContext } from '../../../modal/ModalContext';
import { CertificateForm } from './CertificateForm';
import { saveAs } from 'file-saver';

export const CertificateDetailsScreen = ({ taxpayerId, isAdmin = false }) => {

  const { handleModal } = useContext(ModalContext);

  const {certificates} = useSelector(state => state.certificate)

  const dispatch = useDispatch();

  const { formValues, setFormValues } = useForm({
    certificatesOptions: [],
    counterPassing: 0,
  })

  const { certificatesOptions, counterPassing } = formValues;

  useEffect(() => {
    if(certificates.length === 0 && counterPassing === 0){
      dispatch(certificatesStartLoading(taxpayerId))

      setFormValues(prevState => {
        return{
          ...prevState,
          counterPassing: counterPassing + 1
        }
      })
    }

    setFormValues(prevState => {
      return{
        ...prevState,
        certificatesOptions: certificatesNotFiels(certificates)
      }
    })
  }, [certificates, counterPassing, dispatch, setFormValues, taxpayerId]);

  const downloadCertificate = (certificate, e) => {
    e.preventDefault();
    
    var zip = new JSZip();
    
    zip.file("password.txt", Base64.decode(certificate.password));
    zip.file("certificado.cer", certificate.cerContent, {base64: true});
    zip.file("key.key", certificate.keyContent, {base64: true});

    zip.generateAsync({type:"blob"}).then(function(content) {
      saveAs(content, "Certificados.zip");
    });
  }

  return (
    <div className="col-md-6 mt-4">
      {
        isAdmin ? (
        <h3>Certificados</h3>
        )
        :
        (
          <button 
            className="btn btn-primary mb-3"
            onClick={() => handleModal(
              <CertificateForm
                taxpayerId={ taxpayerId }
              />
            )}
          >
            Agregar certificado
          </button>
        )
      }
      <div className='table-responsive'>
      <table className="table table-striped">
        <thead>
          <tr>
            <td>Número de serie</td>
            <td>Expira el</td>
            <td className="text-center">Acciones</td>
          </tr>
        </thead>
        <tbody>
          {
            certificatesOptions.length === 0 ? (
              <tr>
                <td colSpan="3">
                  <div className="alert alert-primary text-center mb-0" role="alert">
                    No se ha agregado información
                  </div>
                </td>
              </tr>
            )
            :
            (
              certificatesOptions.map((certificate, index) => {
                return(
                  <tr key={index}>
                    <td>{certificate.serialNumber}</td>
                    <td>{certificate.expiredAt}</td>
                    <td className="text-center">
                      <button className="btn btn-primary" data-toggle="tooltip" data-placement="bottom" title="Descargar Archivos" onClick={downloadCertificate.bind(this, certificate)}>
                        <i className="fas fa-file-archive"></i>
                      </button>
                    </td>
                  </tr>
                )
              })
            )
          }
        </tbody>
      </table>
      </div>
    </div>
  );
}