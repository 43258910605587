import React, { Fragment, useContext, useEffect, useState } from 'react';
import { ModalContext } from '../../../../../../modal/ModalContext';
import { useSelector, useDispatch } from 'react-redux';
import { ChangeRegimenModal } from './ChangeRegimenModal';
import { useForm } from '../../../../../../../hooks/useForm';
import { reclassifyData } from '../../../../../../../actions/configuration/reclasify';


export const TaxpayerInformation = React.memo(({ regimeSatCode, taxpayer, year, month }) => {

  const { handleModal } = useContext(ModalContext);

  const [regime, setRegime] = useState(null);
  const [activities, setActivity] = useState([]);
  const [idAct, setIdAct] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (Object.keys(taxpayer).length !== 0) {
      const regime = taxpayer.regimes.find(e => e.satCode === regimeSatCode);

      setActivity(taxpayer.profileRegimes);
      setRegime(regime);

    }
  }, [regimeSatCode, taxpayer]);

  return (
    <div className="col-md-12">
      <strong>Datos del contribuyente</strong> <br />
      <strong>Nombre contribuyente:</strong> {taxpayer.businessName} <br />
      <strong>RFC contribuyente:</strong> {taxpayer.rfc} <br />
      {
        regime && (
          <Fragment>
            <strong>Regimen:</strong> {regime.satCode} - {regime.officialName} <br />
          </Fragment>
        )
      }
      {
        <Fragment>
          <strong>
            Actividad(es):{" "}
            {activities.map((actividad) => (
              <span key={actividad.id}>
                {actividad.activities.map((activity, index) => (
                  <span className='d-flex row justify-content-start pl-3' key={index}>
                    <span style={{ fontWeight: "normal" }}>{activity.id} - </span>
                    <span style={{ fontWeight: "normal" }}> {activity.description}</span>
                    {index < actividad.activities.length - 1 ? <span style={{ fontWeight: "normal" }}>, </span> : null}
                  </span>
                ))}
              </span>

            ))}
          </strong>
        </Fragment>
      }
      <Fragment>
        <button className="btn btn-sm btn-primary float-right ml-3"
          onClick={() => handleModal(
            <ChangeRegimenModal
              taxpayer={taxpayer}
              year={year}
              month={month}
            />
          )}
        >
          Cambiar regimenes
        </button>


      </Fragment>

    </div>
  )
}
)
