import { types } from "../../types/types";

const initialState = {
  wayOfPaymentBilling: []
}

export const WayOfPaymentsBillingReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.wayOfPaymentBillingStartLoading:
      return{
        ...state,
        wayOfPaymentBilling: [ ...action.payload ]
      }
      
    default:
      return state;
  }
}
