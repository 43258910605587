import { types } from "../../types/types";

const initialState = {
  rolesType: []
}

export const rolesReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.rolesStartLoading:
      return{
        ...state,
        rolesType: [ ...action.payload ]
      }

    default:
      return state;
  }
}
