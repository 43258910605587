import { MenuItem, TextField } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from '../../../../hooks/useForm';
import { useHistory } from 'react-router';
import { recordInvoices } from '../../../../actions/billing/catalogs';
import { startRegisterBilling } from '../../../../actions/billing/invoiceActivateModule';
import { validatePhoneValid, validateRfcValid } from '../../../../helpers/validateForm';
import { BILLING } from '../../../constants/Routes';

export const BillingActivation = () => {

    //bILLINGActivation
    let history = useHistory();

    const { user } = useSelector(state => state.auth);
    const { taxpayers } = useSelector(state => state.taxpayer)

    const dispatch = useDispatch()

    const [activation, setActivation] = useState(false)
    const [isOpenActivation, setIsOpenActivation] = useState(false)
    const [profileId, setProfileId] = useState('')
    const [msgErrors, setMsgErrors] = useState({});

    const { formValues, setFormValues, handleInputChange } = useForm({
        profile: '',
        name: '',
        motherLastName: '',
        fatherLastName: '',
        street: '',
        interior: '',
        exterior: '',
        zipCode: '',
        cologne: '',
        city: '',
        country: '',
        businessName: '',
        passwordSAT: '',
        rfc: '',
        curp: '',
        phoneNumber: '',
    });

    const { profile, name, motherLastName, fatherLastName, street, interior,
        exterior, zipCode, cologne, city, country, phoneNumber, businessName,
        passwordSAT, rfc, curp } = formValues;

    useEffect(() => {
        dispatch(recordInvoices(user.id));
    }, [])

    let actv = false

    useEffect(() => {
        if (profile) {
            actv = true
            setProfileId(profile.id)
            setFormValues(prevState => {
                return {
                    name: profile.userName,
                    motherLastName: profile.userMotherLastName,
                    fatherLastName: profile.userLastName,
                    businessName: profile.businessName,
                    passwordSAT: profile.passwordSAT,
                    rfc: profile.rfc,
                    curp: profile.curp,
                    street: profile.addresses[0].street,
                    interior: profile.addresses[0].interior,
                    exterior: profile.addresses[0].outdoor,
                    zipCode: profile.addresses[0].postalCode,
                    cologne: profile.addresses[0].suburb,
                    city: profile.addresses[0].town,
                    country: profile.addresses[0].country,
                    phoneNumber: profile.phoneMobile,
                }
            })
        }
    }, [formValues])

    const isFormValid = () => {
        const errors = {}

        if (name.trim().length === 0) {
            errors['msgName'] = "Es requerido"
        }
        if (motherLastName.trim().length === 0) {
            errors['msgMotherLastName'] = "Es requerido"
        }
        if (fatherLastName.trim().length === 0) {
            errors['msgFatherLastName'] = "Es requerido"
        }
        if (businessName.trim().length === 0) {
            errors['msgBusinessName'] = "Es requerido"
        }
        if (passwordSAT.trim().length === 0) {
            errors['msgPaswordSAT'] = "Es requerido"
        }
        if (!validateRfcValid(rfc, setMsgErrors)) {
            errors['msgRfc'] = 'RFC no es valido.';
        }
        if (!validatePhoneValid(phoneNumber, setMsgErrors)) {
            errors['msgPhoneNumber'] = 'RFC no es valido.';
        }
        if (curp.trim().length === 0) {
            errors['msgCurp'] = "Es requerido"
        }
        if (street.trim().length === 0) {
            errors['msgStreet'] = "Es requerido"
        }
        if (interior.trim().length === 0) {
            errors['msgInterior'] = "Es requerido"
        }
        if (exterior.trim().length === 0) {
            errors['msgExterior'] = "Es requerido"
        }
        if (zipCode.trim().length === 0) {
            errors['msgZipCode'] = "Es requerido"
        }
        if (cologne.trim().length === 0) {
            errors['msgCologne'] = "Es requerido"
        }
        if (city.trim().length === 0) {
            errors['msgCity'] = "Es requerido"
        }
        if (country.trim().length === 0) {
            errors['msgCountry'] = "Es requerido"
        }

        setMsgErrors(errors)

        if (Object.keys(errors).length !== 0) {
            return false;
        }

        return true;
    }


    const handleActivation = (e) => {
        e.preventDefault()
        setActivation(!activation)
        setIsOpenActivation(!isOpenActivation)
    }

    const handleFormActivation = (e) => {
        e.preventDefault()

        const activationForm = {
            "UserId": user.id,
            "ProfileId": profileId,
            "FullName": name + fatherLastName + motherLastName,
            "Profile": {
                "Address": {
                    "Street": street,
                    "Interior": interior,
                    "Outdoor": exterior,
                    "PostalCode": zipCode,
                    "Suburb": cologne,
                    "Town": city,
                    "Country": country,
                },
                "BusinessName": businessName,
                "Curp": curp,
                "Rfc": rfc,
                "PhoneMobile": phoneNumber
            }
        }
        if (isFormValid()) {
            dispatch(startRegisterBilling(activationForm, history, BILLING))
        }
    }
    //console.log('Hola Mundo')
    return (
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2 shadow-lg p-3 mb-2 bg-white rounded">
            <div className='row'>
                <div className='container col-xs-12 col-sm-12 col-md-6 text-center'>
                    <div>
                        <h5> ¿No tienes activado tu Módulo de Facturación? </h5>
                        <h5> <b> Puedes activarlo ahora mismo.</b> </h5>
                    </div>
                    <div>
                        <img className="text-center" height="300" src='logo.jpg' />
                    </div>
                    <div>
                        {
                            (isOpenActivation) ? (
                                <button
                                    className='btn btn-primary mt-4'
                                    onClick={handleActivation}
                                >
                                    Regresar
                                </button>
                            )
                                :
                                (
                                    <button
                                        className='btn btn-primary mt-4'
                                        onClick={handleActivation}
                                    >
                                        Activa tu Módulo de Facturación
                                    </button>
                                )
                        }
                    </div>
                </div>
                {
                    (activation) ? (
                        <div className='col-xs-12 col-sm-12 col-md-6 shadow-lg p-4 mb-2 bg-white rounded'>
                            <div className='row'>
                                <div className="col-xs-12 col-sm-12 mb-3">
                                    <h5 className='text-center'> SELECCIONA UN PERFIL </h5>
                                </div>
                            </div>
                            <div className='row'>
                                <div className="col-xs-12 col-sm-12 mb-3">
                                    <TextField
                                        id="profile"
                                        select
                                        name='profile'
                                        label="Perfiles"
                                        variant="outlined"
                                        onChange={handleInputChange}
                                        fullWidth={true}
                                        disabled={actv}
                                    >
                                        {taxpayers.map((option) => (
                                            <MenuItem key={option.id} value={option}>
                                                {option.businessName}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </div>
                            </div>
                            <div className='row'>
                                <div className="col-xs-12 col-sm-12 mb-3 mt-2">
                                    <TextField
                                        id="name"
                                        label="Nombre"
                                        name='name'
                                        value={name}
                                        onChange={handleInputChange}
                                        helperText={msgErrors.msgName}
                                        error={msgErrors.msgName !== undefined}
                                        variant="outlined"
                                        fullWidth={true}
                                    />
                                </div>
                            </div>
                            <div className='row'>
                                <div className="col-xs-4 col-sm-6 mb-3">
                                    <TextField
                                        id="fatherLastName"
                                        label="Apellido Paterno"
                                        name='fatherLastName'
                                        value={fatherLastName}
                                        onChange={handleInputChange}
                                        helperText={msgErrors.msgFatherLastName}
                                        error={msgErrors.msgFatherLastName !== undefined}
                                        variant="outlined"
                                        fullWidth={true}
                                    />
                                </div>
                                <div className="col-xs-4 col-sm-6 mb-3">
                                    <TextField
                                        id="motherLastName"
                                        label="Apellido Materno"
                                        name='motherLastName'
                                        value={motherLastName}
                                        onChange={handleInputChange}
                                        helperText={msgErrors.msgMotherLastName}
                                        error={msgErrors.msgMotherLastName !== undefined}
                                        variant="outlined"
                                        fullWidth={true}
                                    />
                                </div>
                            </div>
                            <div className='row'>
                                <div className="col-xs-12 col-sm-12 mb-3">
                                    <h5 className='text-center'> Dirección </h5>
                                </div>
                            </div>
                            <div className='row'>
                                <div className="col-xs-4 col-sm-4 mb-3">
                                    <TextField
                                        id="street"
                                        label="Calle"
                                        name='street'
                                        value={street}
                                        onChange={handleInputChange}
                                        helperText={msgErrors.msgStreet}
                                        error={msgErrors.msgStreet !== undefined}
                                        variant="outlined"
                                        fullWidth={true}
                                    />
                                </div>
                                <div className="col-xs-4 col-sm-4 mb-3">
                                    <TextField
                                        id="interior"
                                        label="N. Interior"
                                        name='interior'
                                        value={interior}
                                        onChange={handleInputChange}
                                        helperText={msgErrors.msgInterior}
                                        error={msgErrors.msgInterior !== undefined}
                                        variant="outlined"
                                        fullWidth={true}
                                    />
                                </div>
                                <div className="col-xs-4 col-sm-4 mb-3">
                                    <TextField
                                        id="exterior"
                                        label="N. Exterior"
                                        name='exterior'
                                        value={exterior}
                                        onChange={handleInputChange}
                                        helperText={msgErrors.msgExterior}
                                        error={msgErrors.msgExterior !== undefined}
                                        variant="outlined"
                                        fullWidth={true}
                                    />
                                </div>
                            </div>
                            <div className='row'>
                                <div className="col-xs-4 col-sm-4 mb-3">
                                    <TextField
                                        id="zipCode"
                                        label="Código Postal"
                                        name='zipCode'
                                        value={zipCode}
                                        onChange={handleInputChange}
                                        helperText={msgErrors.msgZipCode}
                                        error={msgErrors.msgZipCode !== undefined}
                                        variant="outlined"
                                        fullWidth={true}
                                    />
                                </div>
                                <div className="col-xs-4 col-sm-4 mb-3">
                                    <TextField
                                        id="cologne"
                                        label="Colonia"
                                        name='cologne'
                                        value={cologne}
                                        onChange={handleInputChange}
                                        helperText={msgErrors.msgCologne}
                                        error={msgErrors.msgCologne !== undefined}
                                        variant="outlined"
                                        fullWidth={true}
                                    />
                                </div>
                                <div className="col-xs-4 col-sm-4 mb-3">
                                    <TextField
                                        id="city"
                                        label="Ciudad"
                                        name='city'
                                        value={city}
                                        onChange={handleInputChange}
                                        helperText={msgErrors.msgCity}
                                        error={msgErrors.msgCity !== undefined}
                                        variant="outlined"
                                        fullWidth={true}
                                    />
                                </div>
                            </div>
                            <div className='row'>
                                <div className="col-xs-6 col-sm-6 mb-3">
                                    <TextField
                                        id="country"
                                        label="País"
                                        name='country'
                                        value={country}
                                        onChange={handleInputChange}
                                        helperText={msgErrors.msgCountry}
                                        error={msgErrors.msgCountry !== undefined}
                                        variant="outlined"
                                        fullWidth={true}
                                    />
                                </div>
                            </div>
                            <div className='row'>
                                <div className="col-xs-12 col-sm-12 mb-3">
                                    <h5 className='text-center'> Datos Fiscales </h5>
                                </div>
                            </div>
                            <div className='row'>
                                <div className="col-xs-6 col-sm-6 mb-3">
                                    <TextField
                                        id="businessName"
                                        label="Nombre"
                                        name='businessName'
                                        value={businessName}
                                        onChange={handleInputChange}
                                        helperText={msgErrors.msgBusinessName}
                                        error={msgErrors.msgBusinessName !== undefined}
                                        variant="outlined"
                                        fullWidth={true}
                                    />
                                </div>
                                <div className="col-xs-6 col-sm-6 mb-3">
                                    <TextField
                                        id="curp"
                                        label="Curp"
                                        name='curp'
                                        value={curp}
                                        onChange={handleInputChange}
                                        helperText={msgErrors.msgCurp}
                                        error={msgErrors.msgCurp !== undefined}
                                        variant="outlined"
                                        fullWidth={true}
                                    />
                                </div>
                            </div>
                            <div className='row'>
                                <div className="col-xs-6 col-sm-6 mb-3">
                                    <TextField
                                        id="rfc"
                                        label="RFC"
                                        name='rfc'
                                        value={rfc}
                                        onChange={handleInputChange}
                                        helperText={msgErrors.msgRfc}
                                        error={msgErrors.msgRfc !== undefined}
                                        variant="outlined"
                                        fullWidth={true}
                                    />
                                </div>
                                <div className="col-xs-6 col-sm-6 mb-3">
                                    <TextField
                                        id="passwordSAT"
                                        label="País"
                                        name='passwordSAT'
                                        value={passwordSAT}
                                        onChange={handleInputChange}
                                        helperText={msgErrors.msgPaswordSAT}
                                        error={msgErrors.msgPaswordSAT !== undefined}
                                        variant="outlined"
                                        fullWidth={true}
                                    />
                                </div>
                            </div>
                            <div className='row'>
                                <div className="col-xs-6 col-sm-6 mb-3">
                                    <TextField
                                        id="phoneNumber"
                                        label="Numero de Telefono"
                                        name='phoneNumber'
                                        value={phoneNumber}
                                        onChange={handleInputChange}
                                        helperText={msgErrors.msgPhoneNumber}
                                        error={msgErrors.msgPhoneNumber !== undefined}
                                        variant="outlined"
                                        fullWidth={true}
                                    />
                                </div>
                            </div>
                            <div className='row'>
                                <div className="col-xs-12 col-sm-12 mb-3 mt-2">
                                    <button className='btn btn-success  btn-block' onClick={handleFormActivation}> Activar </button>
                                </div>
                            </div>
                        </div>
                    )
                        :
                        ('')
                }
            </div>
        </div>
    )
}
