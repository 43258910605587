import { InputAdornment, TextField } from '@material-ui/core';
import React, { Fragment } from 'react';

export const IncomeFormNotDowloadedSat = ({ formValues, handleInputChange, handleThirdPartyChange,
  handleSubmit, handleCheckboxTrueFalseChange, serviceTypeRetentions, msgErrors, regimeSatCode, ivaTraslateds, thirdParties, wayOfPayments }) => {
  
  const { concept, folio, serviceTypeRetentionId,
    isrRetained, isrRetainedCheck, ivaRetained, ivaRetainedCheck, ivaTraslated, ivaTraslatedId,
    paymentAt, serie, subtotal, thirdPartyForign, thirdPartyId,
    total, wayOfPaymentId
  } = formValues;
  
  return (
    <div>
      <form onSubmit={ handleSubmit }>
        <div className="row">
          <div className="col-md-6 mb-3">
            <TextField
              label="Cliente" variant="outlined" size="small" autoComplete="off" fullWidth={true}
              select
              name="thirdPartyId"
              value={ thirdPartyId }
              onChange={ handleThirdPartyChange }
              SelectProps={{
                native: true,
              }}
              helperText={ msgErrors.thirdPartyId }
              error={ msgErrors.thirdPartyId !== undefined }
            >
              <option value='0' disabled>Selecciona un cliente</option>
              {
                thirdParties.map((thirdParty, index) => {
                  return(
                    <option
                      key={ index }
                      value={ thirdParty.id }
                    > 
                      { thirdParty.rfc } - { thirdParty.businessName }
                    </option>  
                  )
                })
              }
            </TextField>
          </div>
          <div className="col-md-6 mb-3">
            <TextField
              label="Concepto" variant="outlined" size="small" autoComplete="off" fullWidth={true}
              name="concept"
              value={ concept }
              onChange={ handleInputChange }
              helperText={ msgErrors.concept }
              error={ msgErrors.concept !== undefined }
            />
          </div>
          <div className="col-md-6 mb-3">
            <TextField
              label="Serie" variant="outlined" size="small" autoComplete="off" fullWidth={true}
              name="serie"
              value={ serie }
              onChange={ handleInputChange }
            />
          </div>
          <div className="col-md-6 mb-3">
            <TextField
              label="Folio" variant="outlined" size="small" autoComplete="off" fullWidth={true}
              name="folio"
              value={ folio }
              onChange={ handleInputChange }
            />
          </div>

          {
            regimeSatCode === '625' &&
            <div className="col-md-12 mb-3">
              <TextField
                label="Tipo de ingreso" variant="outlined" size="small" autoComplete="off" fullWidth={true}
                select
                name="serviceTypeRetentionId"
                value={ serviceTypeRetentionId }
                onChange={ handleInputChange }
                SelectProps={{
                  native: true,
                }}
              >
                {
                  serviceTypeRetentions.map((serviceTypeRetention, index) => {
                    return(
                      <option
                        key={index}
                        value={serviceTypeRetention.id}
                      > 
                        {serviceTypeRetention.key} - {serviceTypeRetention.description}
                      </option>  
                    )
                  })
                }
              </TextField>
            </div>
          }

          <div className="col-md-6 mb-3">
            <TextField
              label="Fecha de cobro" variant="outlined" size="small" autoComplete="off" fullWidth={true}
              type="date"
              name="paymentAt"
              value={ paymentAt }
              onChange={ handleInputChange }
              InputLabelProps={{shrink: true,}}
              helperText={ msgErrors.paymentAt }
              error={ msgErrors.paymentAt !== undefined }
            />
          </div>

          {/* <div className="col-md-6 mb-3">
            <TextField
              label="Cuenta asociada" variant="outlined" size="small" autoComplete="off" fullWidth={true}
              select
              name="accountIdentifierId"
              value={ accountIdentifierId }
              onChange={ handleInputChange }
              SelectProps={{
                native: true,
              }}
            >
              {
                accountIdentifiers.map((accountidentifier, index) => {
                  return(
                    <option
                      key={ index }
                      value={ accountidentifier.id }
                    > 
                      { accountidentifier.account }
                    </option>  
                  )
                })
              }
            </TextField>
          </div> */}

          <div className="col-md-6 mb-3">
            <TextField
              label="Forma de pago" variant="outlined" size="small" autoComplete="off" fullWidth={true}
              select
              name="wayOfPaymentId"
              value={ wayOfPaymentId }
              onChange={ handleInputChange }
              SelectProps={{
                native: true,
              }}
            >
              {
                wayOfPayments.map((wayofPayment, index) => {
                  return(
                    <option
                      key={ index }
                      value={wayofPayment.id}
                    > 
                      { wayofPayment.key } - { wayofPayment.description }
                    </option>  
                  )
                })
              }
            </TextField>
          </div>
          {
            !thirdPartyForign && (
              <Fragment>
                <div className="col-md-6 mb-3">
                  <TextField
                    label="Subtotal" variant="outlined" size="small" autoComplete="off" fullWidth={true}
                    type="number"
                    name="subtotal"
                    value={ subtotal }
                    onChange={ handleInputChange }
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          $
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>

                <div className="col-md-6 mb-3">
                  <TextField
                    label="Tipo de IVA" variant="outlined" size="small" autoComplete="off" fullWidth={true}
                    select
                    name="ivaTraslatedId"
                    value={ ivaTraslatedId }
                    onChange={ handleInputChange }
                    SelectProps={{
                      native: true,
                    }}
                  >
                    {
                      ivaTraslateds.map((ivaTraslated, index) => {
                        return(
                          <option
                            key={ index }
                            value={ ivaTraslated.id }
                          > 
                            { ivaTraslated.name }
                          </option>  
                        )
                      })
                    }
                  </TextField>
                </div>

                <div className="col-md-6 mb-3">
                  <TextField
                    label="IVA" variant="outlined" size="small" autoComplete="off" fullWidth={true}
                    name="ivaTraslated"
                    value={ ivaTraslated }
                    onChange={ handleInputChange }
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          $
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>

                <div className="col-md-12 d-flex justify-content-center mb-2">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="isrRetainedCheck"
                      name="isrRetainedCheck"
                      checked={ isrRetainedCheck }
                      onChange={ handleCheckboxTrueFalseChange }
                    />
                    <label className="form-check-label" htmlFor='isrRetainedCheck'>
                      ISR retenido
                    </label>
                  </div>

                  <div className="form-check ml-3">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="ivaRetainedCheck"
                      name="ivaRetainedCheck"
                      checked={ ivaRetainedCheck }
                      onChange={ handleCheckboxTrueFalseChange }
                    />
                    <label className="form-check-label" htmlFor='ivaRetainedCheck'>
                      IVA retenido
                    </label>
                  </div>
                </div>
                
                {
                  isrRetainedCheck && (
                    <div className="col-md-6 mb-3">
                      <TextField
                        label="ISR retenido" variant="outlined" size="small" autoComplete="off" fullWidth={true}
                        name="isrRetained"
                        value={ isrRetained }
                        onChange={ handleInputChange }
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              $
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>
                  )
                }

                {
                  ivaRetainedCheck && (
                    <div className="col-md-6 mb-3">
                      <TextField
                        label="IVA retenido" variant="outlined" size="small" autoComplete="off" fullWidth={true}
                        name="ivaRetained"
                        value={ ivaRetained }
                        onChange={ handleInputChange }
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              $
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>
                  )
                }
              </Fragment>
            )
          }
          
          <div className="col-md-6 mb-3">
            <TextField
              label="Total" variant="outlined" size="small" autoComplete="off" fullWidth={true}
              type="number"
              name="total"
              value={ total }
              onChange={ handleInputChange }
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    $
                  </InputAdornment>
                ),
              }}
              helperText={ msgErrors.total }
              error={ msgErrors.total !== undefined }
            />
          </div>

          <div className="col-md-12 mb-3 text-right">
            <button className="btn btn-primary">
              Guardar
            </button>
          </div>
        </div>
      </form>
    </div>
  )
}
