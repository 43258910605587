import { types } from "../../types/types";

const initialState = {
  unitTypes: []

}


export const unitTypeReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.unitTypesStartLoading:
      return{
        ...state,
        unitTypes: [ ...action.payload ]
      }

    default:
      return state;
  }
}