import React, { useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { ImageList } from '../../../layout/ImageList';
import { ModalContext } from '../../../modal/ModalContext';

export const RenovationVouchers = ({saleId}) => {
  const { handleModal } = useContext( ModalContext );

  const { crmSalesRenovations } = useSelector(state => state.crmSale);

  const [images, setImages] = useState([])

  useEffect(() => {
    const sale = crmSalesRenovations.filter(e => e.id === parseInt(saleId))[0];
    if (sale.vouchers !== undefined) {
      setImages(sale.vouchers);
    }
  }, [crmSalesRenovations, saleId, setImages])

  return (
    <div>
      <p className="text-center">
        <strong>Listado de comprobantes</strong>
      </p>

      <ImageList
        images={images}
      />

      <div className="col-md-12 text-right pr-0">
        <button className="btn btn-danger" onClick={handleModal}>
          Cerrar
        </button>
      </div>
    </div>
  )
}
