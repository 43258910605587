import { types } from "../../types/types";

const initialState = {
  referenceDataRedPay: {},
  referenceDataRedPayFinish:false
}

export const crmSubscriptionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.referenceRedPay:
      return{
        ...state,
        referenceDataRedPay: action.payload
      }
    case types.referenceRedPayFinish:
        return{
          ...state,
          referenceDataRedPayFinish: action.payload
        }
    case types.referenceRedPayClear:
      return{
        referenceDataRedPay : {},
        referenceDataRedPayFinish:false
      }
  
    default:
      return state;
  }
}