import moment from 'moment';
import React, { Fragment, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom';
import { preAuthorizationReportUpdateToken, preAutorizedReportUpdateStart } from '../../../../../../../../actions/accounting/preAuthorizedReports';
import { validateStringPresence } from '../../../../../../../../helpers/validateForm';
import { useForm } from '../../../../../../../../hooks/useForm';

export const CommentsScreen = ({formValuesDetailsSummary, token = null, status}) => {

  const dispatch = useDispatch();

  const { isAdmin } = useParams();

  const {user} = useSelector(state => state.auth)

  const { comments, reportId } = formValuesDetailsSummary;

  const { formValues, handleInputChange, setFormValues } = useForm({
    comment: ''
  });

  const { comment } = formValues;

  const [msgErrors, setMsgErrors] = useState({});

  const handleSubmit = (e) => {
    e.preventDefault();

    if(isFormValid()){
      let petition = {
        comment:{
          whoComment: 'taxpayer',
          comments: comment,
          dateResponse: moment(new Date()).format('YYYY-MM-DD hh:mm:ss')
        },
        isAuthorized: false
      }
      if(token){
        dispatch(preAuthorizationReportUpdateToken(token, petition))
      }else{
        petition.reportId = reportId;
        if (isAdmin) {
          petition['comment']['whoComment'] = `Cont. ${user.userName}`;
        }
        dispatch(preAutorizedReportUpdateStart(petition))
        setFormValues(prevState => {
          return{
            ...prevState,
            comment: ''
          }
        })
      }
    }
  }

  const isFormValid = () => {
    setMsgErrors({});

    const datasStringToValidate = { comment };
    const validateString = validateStringPresence( datasStringToValidate, setMsgErrors );

    if( validateString ){
      return true;
    }
    return false;
  }

  return (
    <div className="row">
      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2">
        <div className="card">
          <div className="card-header">
            <div className="row">
              <div className="col-xs-8 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <h5>Comentarios</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2">
        <div className="row">
          <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-center">
            {
              status !== 'Presentada' &&(
                <form onSubmit={handleSubmit}>
                  <div className="form-group">
                    <label><strong>Escribir comentario</strong></label>
                    <textarea
                      className={`form-control ${msgErrors.comment !== undefined ? 'is-invalid' : ''}`}
                      rows="6"
                      placeholder="Si tienes un comentario o duda sobre tu reporte, escribe tu comentario para poder aclarar tus dudas."
                      name='comment'
                      value={comment}
                      onChange={handleInputChange}
                    />
                    {
                      msgErrors.comment !== undefined && (
                        <div class="invalid-feedback">
                          {msgErrors.commen}
                        </div>
                      )
                    }
                  </div>
                  <button className="btn btn-primary">Enviar comentarios</button>
                </form>
              )
            }   
          </div>
          <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
            {
              comments ? (
                comments.map((comment, index) => {
                  return(
                    <Fragment key={index}>
                      {
                        comment.whoComments === 'taxpayer' ? 
                        (
                          <div className="text-left">
                              <strong>Mi comentario</strong><br/>
                              {comment.comments}<br/>
                              <small>{moment(comment.dateResponse).format('YYYY-MM-DD hh:mm:ss')}</small>
                          </div>
                        )
                        :
                        (
                          <div className="text-right">
                              <strong>{comment.whoComments}</strong><br/>
                              {comment.comments}<br/>
                              <small>{moment(comment.dateResponse).format('YYYY-MM-DD hh:mm:ss')}</small>
                          </div>
                        )
                      }
                    </Fragment>
                  )
                })
              )
              :
              (
                <div className="alert alert-danger col-md-12 text-center" role="alert">
                  No existen comentarios.
                </div>
              )
            }
          </div>
        </div>
      </div>
    </div>
  )
}
