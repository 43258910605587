import React from 'react';
import { GridList, GridListTile } from '@material-ui/core';

export const ImageList = ({images}) => {
  return (
    <div>
      {
        images.length > 0 &&
        <GridList className="mb-3" style={{width: 500, height: 450,}}>
          {
            images.map((img, index) => {
              return (
                <GridListTile key={index} cols={2} style={{ width: "100%", height: 'auto' }}>
                  <img src={`data:image/png;base64, ${img.content}`} alt={img.name}
                    style={{ width: "100%", height: "100%" }}
                  />
                </GridListTile>
              )
            })
          }
        </GridList>
      }
    </div>
  )
}
