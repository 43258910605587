
export const CheckboxFilter = (columnsToSearch, dataToSearch, setDataChange, originals,setTaxpayerAccount) => {
  if(parseInt(dataToSearch) > 0){
    const newData =searchInArray(columnsToSearch, originals, parseInt(dataToSearch));
    setDataChange(newData);
    setTaxpayerAccount(newData)
  }else{
    setTaxpayerAccount(originals)
    setDataChange(originals);
  }
}

const searchInArray = (columnsToSearch, originals, search) => {
  let newData = [];
  originals.forEach((original) => {
    let flag = false;
    columnsToSearch.forEach((column) => {
      (parseInt(original[column]) === search) && (flag = true)
    })
    if (flag) {
      newData.push(original)
    }
  })
  return newData;
}