import { types } from "../../types/types";

const initialState = {
  documentsBilling: []
}

export const TaxDocumentsBilling = (state = initialState, action) => {
  switch (action.type) {
    case types.taxDocumentBilling:
    return{
        ...state,
        documentsBilling: state.documentsBilling.map(
        e => (e.token === action.payload.token) ?
            {
            ...e,
            stepsJson:{
                ...e.stepsJson,
                stepFiles:action.payload
            }
            }
        :
            e
        )
    }
      
    default:
      return state;
  }
}
