import React from 'react';
import { LineItemForm } from './LineItemForm';
import { LineItemList } from './LineItemList';
import { TableLineItems } from './TableLineItems';

export const LineItemsDetails = () => {
  return (
    <div className="container">
      <h5 className="card-header">Detalle de los conceptos</h5>
      <div className="card-body">

        <div className="row mb-4">

          <LineItemList />

          <LineItemForm />

        </div>

        <TableLineItems />
        
      </div>
    </div>
  )
}
