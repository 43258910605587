import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TextField } from '@material-ui/core';
import { wayOfPaymentsCfdiStartLoading } from '../../../../../actions/catalogs/wayOfPaymentsCfdi';
import { paymentMethodsCfdiStartLoading } from '../../../../../actions/catalogs/paymentMethod';
import { coinsStartLoading } from '../../../../../actions/catalogs/coins';

export const AditionalDataForm = ({ formData, handleInputChange, setFormValues }) => {

  const { wayOfPayments } = useSelector(state => state.wayOfPayment);
  const { paymentMethods } = useSelector(state => state.paymentMethod);
  const { coins } = useSelector(state => state.coin);

  const { wayOfPaymentId, paymentMethodId, coinId } = formData; 

  const dispatch = useDispatch();

  useEffect(() => {
    if (wayOfPayments.length === 0) {
      dispatch(wayOfPaymentsCfdiStartLoading())
    }
  }, [wayOfPayments, dispatch]);

  useEffect(() => {
    if( wayOfPayments.length > 0 ){
      setFormValues(prevState => {
        return{
          ...prevState,
          wayOfPaymentId: wayOfPayments[0].id
        }
      })
    }
  }, [ wayOfPayments, setFormValues ]);

  useEffect(() => {
    if (paymentMethods.length === 0) {
      dispatch(paymentMethodsCfdiStartLoading())
    }
  }, [paymentMethods, dispatch]);

  useEffect(() => {
    if( paymentMethods.length > 0 ){
      setFormValues(prevState => {
        return{
          ...prevState,
          paymentMethodId: paymentMethods[0].id
        }
      })
    }
  }, [ paymentMethods, setFormValues ]);

  useEffect(() => {
    if (coins.length === 0) {
      dispatch(coinsStartLoading())
    }
  }, [coins, dispatch]);

  useEffect(() => {
    if( coins.length > 0 ){
      setFormValues(prevState => {
        return{
          ...prevState,
          coinId: coins[0].id
        }
      })
    }
  }, [ coins, setFormValues ]);

  return (
    <div className="col-md-6">
      <div className="form-group row">
        <label className="col-md-4"><strong>Forma de pago</strong></label>
        <div className="col-md-8">
          <TextField
              select
              variant="outlined"
              label="Forma de Pago"
              size="small"
              fullWidth={true}
              name="wayOfPaymentId"
              value={ wayOfPaymentId }
              onChange={handleInputChange}
              SelectProps={{
                native: true,
              }}
            >
              {
                wayOfPayments.length > 0 ? (
                  wayOfPayments.map((payment) => {
                    return(
                      <option key={ payment.id } value={ payment.id }> { payment.key } - { payment.description }</option>
                    )
                  })
                )
                :
                (
                  <option value={0}>No hay registros</option>
                )
              }
          </TextField>
        </div>
      </div>

      <div className="form-group row">
        <label className="col-md-4"><strong>Método de pago</strong></label>
        <div className="col-md-8">
          <TextField
            select
            variant="outlined"
            label="Método de pago"
            size="small"
            fullWidth={true}
            name="paymentMethodId"
            value={ paymentMethodId }
            onChange={handleInputChange}
            SelectProps={{
              native: true,
            }}
          >
            {
              paymentMethods.length > 0 ? (
                paymentMethods.map((payment) => {
                  return(
                    <option key={ payment.id } value={ payment.id }> { payment.key } - { payment.description }</option>
                  )
                })
              )
              :
              (
                <option value={0}>No hay registros</option>
              )
            }
            </TextField>
        </div>
      </div>

      <div className="form-group row">
        <label className="col-md-4"><strong>Condiciones pago</strong></label>
        <div className="col-md-8">
          <input className="form-control" />
        </div>
      </div>

      <div className="form-group row">
        <label className="col-md-4"><strong>Moneda</strong></label>
        <div className="col-md-8">
        <TextField
            select
            variant="outlined"
            label="Moneda"
            size="small"
            fullWidth={true}
            name="coinId"
            value={ coinId }
            onChange={handleInputChange}
            SelectProps={{
              native: true,
            }}
          >
            {
              coins.length > 0 ? (
                coins.map((payment) => {
                  return(
                    <option key={ payment.id } value={ payment.id }> { payment.key } - { payment.description }</option>
                  )
                })
              )
              :
              (
                <option value={0}>No hay registros</option>
              )
            }
            </TextField>
        </div>
      </div>

      <div className="form-group row">
        <label className="col-md-4"><strong>Tipo de cambio</strong></label>
        <div className="col-md-8">
          <input className="form-control" />
        </div>
      </div>

      <div className="form-group row">
        <label className="col-md-4"><strong>Información adicional</strong></label>
        <div className="col-md-8">
          <textarea
            className="form-control"
            rows="3"
          />
        </div>
      </div>
    </div>
  )
}
