import { types } from "../types/types"

export const identifierAddNew = ( identifier ) => ({
  type: types.identifierAddNew,
  payload: identifier
});

export const identifierSetSelected = ( identifier ) => ({
  type: types.identifierSetSelected,
  payload: identifier
})

export const identifierClearSelected = () => ({
  type:  types.identifierClearSelected
})

export const identifierUpdated = ( identifier ) => ({
  type: types.identifierUpdated,
  payload: identifier
})

export const identifierDeleted = ( identifier ) => ({
  type:  types.identifierDeleted,
  payload: identifier
})