import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TextField } from '@material-ui/core';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { cfdiRelatedsChangeTypeSelected, cfdiRelatedsTypeDeleteToList } from '../../../../../actions/invoices/cfdiRelationTypes';

export const TableRelatedDocuments = () => {
  
  const { cfdiRelationsList } = useSelector(state => state.cfdiRelationType);

  const { cfdiRelationTypeId } = useSelector(state => state.cfdiRelationType);

  const { cfdiRelationTypes } = useSelector(state => state.cfdiRelationType);

  const dispatch = useDispatch();

  const handleRelationType = ( { target } ) => {
    dispatch( cfdiRelatedsChangeTypeSelected( { id: target.value } ) );
  }

  const deleteCfdiRelated = ( id, e ) => {
    e.preventDefault();

    dispatch( cfdiRelatedsTypeDeleteToList(id) );
  }
  
  return (
    <div className="col-md-12">
      <table className="table">
        <thead>
          <tr>
            <th scope="col">UUID</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          <tr className="mt-3">
            <td colSpan="2">
              <TextField
                select
                variant="outlined"
                label="Tipo de relación"
                size="small"
                fullWidth={true}
                name="cfdiRelationTypeId"
                value={ cfdiRelationTypeId }
                onChange={ handleRelationType }
                SelectProps={{
                  native: true,
                }}
              >
                <option value={0}>Seleccionar CFDI relacionado</option>
                {
                  cfdiRelationTypes.map((cfdiRelationType, index) => {
                    return(
                      <option key={index} value={cfdiRelationType.id}>{ cfdiRelationType.description }</option>
                    )
                  })
                }
              </TextField>
            </td>
          </tr>
          {
            cfdiRelationsList.map((cfdiRelation, index) => {
              return(
                <tr key={index}>
                  <td>{cfdiRelation.uuid}</td>
                  <td className="text-right">
                    <button
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Eliminar CFDI relacionado"
                      className="btn btn-outline-danger px-2 ml-2"
                      onClick={ deleteCfdiRelated.bind(this, cfdiRelation.id) }
                    >
                      <FontAwesomeIcon className="" icon={"trash"}/>
                    </button>
                  </td>
                </tr>
              )
            })
          }
        </tbody>
      </table>
    </div>
  )
}
