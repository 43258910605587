import { InputAdornment, TextField } from '@material-ui/core';
import React, { Fragment, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { obtainIsrPlataformPorcentage } from '../../../../../../../../../../helpers/accounting/obtainIsrPlataformPorcentaje';
import NumberFormat from 'react-number-format';

export const ServiceAlienationScreen = ({formValuesDetailsSummary, setFormValuesDetailsSummary, autorizedReport, status}) => {
  
  const { IsrPlataformAlienationRanges } = useSelector(state => state.isrPlataformAlienation)

  const { taxISRPlataformsTechnologies, year } = formValuesDetailsSummary;

  const { serviceAlienation } = taxISRPlataformsTechnologies;

  const { ISR, subtotalServiceAlineationClasified, subtotalServiceLendingClasified, 
          totalServiceAlienationFinal, totalServiceLendingFinal,
          totalAlienationPlataform, totalLendingPlataform, totalIsr, totalService, totalRetained
        } = serviceAlienation;

  useEffect(() => {
    let totalServiceAlienation = (parseFloat(subtotalServiceAlineationClasified) - parseFloat(totalAlienationPlataform)).toFixed(2);
    let totalServiceLending = (parseFloat(subtotalServiceLendingClasified) - parseFloat(totalLendingPlataform)).toFixed(2)

    if (parseFloat(totalServiceAlienation) <= 0) {
      totalServiceAlienation = 0.00;
    }
    if(parseFloat(totalServiceLending) <= 0){
      totalServiceLending = 0.00;
    }

    const totalAlienationService = (
      parseFloat(totalServiceAlienation) +
      parseFloat(totalServiceLending) +
      parseFloat(totalAlienationPlataform) +
      parseFloat(totalLendingPlataform)
    ).toFixed(2);

    const totalForIntermediaries = (
      parseFloat(totalAlienationPlataform) +
      parseFloat(totalLendingPlataform)
    ).toFixed(2);

    const totalForUsers = (
      parseFloat(totalServiceAlienation) +
      parseFloat(totalServiceLending)
    ).toFixed(2);

    setFormValuesDetailsSummary(prevState => {
      return{
        ...prevState,
        taxISRPlataformsTechnologies: {
          ...prevState.taxISRPlataformsTechnologies,
          serviceAlienation: {
            ...prevState.taxISRPlataformsTechnologies.serviceAlienation,
            totalForIntermediaries: totalForIntermediaries,
            totalForUsers: totalForUsers,
            totalService: totalAlienationService,
            totalServiceAlienationFinal: totalServiceAlienation,
            totalServiceLendingFinal: totalServiceLending
          }
        }
      }
    })
  }, [setFormValuesDetailsSummary, totalAlienationPlataform, totalLendingPlataform, subtotalServiceAlineationClasified, subtotalServiceLendingClasified])

  useEffect(() => {
    setFormValuesDetailsSummary(prevState => {
      return{
        ...prevState,
        taxISRPlataformsTechnologies: {
          ...prevState.taxISRPlataformsTechnologies,
          serviceAlienation: {
            ...prevState.taxISRPlataformsTechnologies.serviceAlienation,
            ISR: obtainIsrPlataformPorcentage(IsrPlataformAlienationRanges, totalService, year)
          }
        }
      }
    })
  }, [setFormValuesDetailsSummary, IsrPlataformAlienationRanges, totalService, year])

  useEffect(() => {
    const totalIsrAlienation = (
      parseFloat(ISR.isrCaused) -
      parseFloat(totalRetained)
    ).toFixed(2);

    setFormValuesDetailsSummary(prevState => {
      return{
        ...prevState,
        taxISRPlataformsTechnologies: {
          ...prevState.taxISRPlataformsTechnologies,
          serviceAlienation: {
            ...prevState.taxISRPlataformsTechnologies.serviceAlienation,
            totalIsr: totalIsrAlienation
          }
        }

      }
    })
  }, [ISR, totalRetained, setFormValuesDetailsSummary])

  const handleInputChangeAlienation = (e) => {
    const { value, name } = e.target;
    setFormValuesDetailsSummary(prevState => {
      return{
        ...prevState,
        taxISRPlataformsTechnologies:{
          ...prevState.taxISRPlataformsTechnologies,
          serviceAlienation:{
            ...prevState.taxISRPlataformsTechnologies.serviceAlienation,
            [name]: value
          }
        }
      }
    })
  }

  return (
    <table className="table">
      <tbody>
        <tr>
          <td className="w-50">Ingresos intermediarios enajenación</td>
          <td className="w-50">
            {
              status === 'Presentada' ?
              (<TextField
                variant="outlined"
                size="small"
                name="totalAlienationPlataform"
                value={totalAlienationPlataform}
                onChange={handleInputChangeAlienation}
                autoComplete="off"
                fullWidth={true}
                InputProps={{
                  startAdornment: (<InputAdornment position="start">$</InputAdornment>),
                }}
                disabled={true}
              />)
              :
              (<TextField
                variant="outlined"
                size="small"
                name="totalAlienationPlataform"
                value={totalAlienationPlataform}
                onChange={handleInputChangeAlienation}
                autoComplete="off"
                fullWidth={true}
                InputProps={{
                  startAdornment: (<InputAdornment position="start">$</InputAdornment>),
                }}
                disabled={autorizedReport}
              />)
            }

            {/* <NumberFormat
              variant="outlined"
              size="small"
              name="totalAlienationPlataform"
              value={totalAlienationPlataform}
              customInput={TextField}
              prefix={'$'}
              type="text"
              thousandSeparator={true}
              onChange={handleInputChangeAlienation}
              disabled={autorizedReport}
            /> */}
          </td>
        </tr>
        <tr>
          <td className="w-50">Ingresos intermediarios prestacion servicios</td>
          <td className="w-50">
            {
              status === 'Presentada' ?
              (<TextField
                variant="outlined"
                size="small"
                name="totalLendingPlataform"
                value={totalLendingPlataform}
                onChange={handleInputChangeAlienation}
                autoComplete="off"
                fullWidth={true}
                InputProps={{
                  startAdornment: (<InputAdornment position="start">$</InputAdornment>),
                }}
                disabled={true}
              />)
              :
              (<TextField
                variant="outlined"
                size="small"
                name="totalLendingPlataform"
                value={totalLendingPlataform}
                onChange={handleInputChangeAlienation}
                autoComplete="off"
                fullWidth={true}
                InputProps={{
                  startAdornment: (<InputAdornment position="start">$</InputAdornment>),
                }}
                disabled={autorizedReport}
              />)
            }
          
          </td> 
        </tr>
        <tr>
          <td className="w-50">Ingresos directos enajenación</td>
          <td className="w-50"><NumberFormat value={parseFloat(totalServiceAlienationFinal).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td>
        </tr>
        <tr>
          <td className="w-50">Ingresos directos prestacion servicios</td>
          <td className="w-50"><NumberFormat value={parseFloat(totalServiceLendingFinal).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td>
        </tr>
        <tr>
          <td className="w-50">Ingresos totales menos retenciones</td>
          <td className="w-50"><NumberFormat value={parseFloat(totalService).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td>
        </tr>
        <tr>
          <td className="w-50">Tasa</td>
          <td className="w-50"><NumberFormat value={parseFloat(ISR.porcentage * 100).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'%'} /></td>
        </tr>
        <tr>
          <td className="w-50">ISR Causado</td>
          <td className="w-50"><NumberFormat value={parseFloat(ISR.isrCaused).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td>
        </tr>
        <tr>
          <td className="w-50">Retenciones por plataformas tecnológicas</td>
          <td className="w-50">
            {
              status === 'Presentada' ?
              (<TextField
                variant="outlined"
                size="small"
                name="totalRetained"
                value={totalRetained}
                onChange={handleInputChangeAlienation}
                autoComplete="off"
                fullWidth={true}
                InputProps={{
                  startAdornment: (<InputAdornment position="start">$</InputAdornment>),
                }}
                disabled={true}
              />)
              :
              (<TextField
                variant="outlined"
                size="small"
                name="totalRetained"
                value={totalRetained}
                onChange={handleInputChangeAlienation}
                autoComplete="off"
                fullWidth={true}
                InputProps={{
                  startAdornment: (<InputAdornment position="start">$</InputAdornment>),
                }}
                disabled={autorizedReport}
              />)
            }
          </td>
        </tr>
        <tr>
          {
            totalIsr >= 0 ? (
              <Fragment>
                <td className="w-50">ISR a cargo</td>
                <td className="w-50"><NumberFormat value={parseFloat(totalIsr).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td>
              </Fragment>
            )
            :
            (
              <Fragment>
                <td className="w-50">ISR a cargo</td>
                <td className="w-50"><NumberFormat value={0.00} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td>
              </Fragment>
            )
          }
        </tr>
      </tbody>
    </table>
  )
}
