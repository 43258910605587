export const initialOptionLease = {
  subtractLimitLower: 0.00,
  fixedFee: 0,
  forPorcentage: 0,
  lowerLimit: 0,
  plusFixedFee: 0,
  porcentage: 0,
}

export const obtainLeasePorcentaje = (columns, amount, year) => {
  let option = {}

  columns.forEach(column => {
    if (parseFloat(amount) >= column.lowerLimit && column.year === parseInt(year)) {
      const subtractLimitLower = (parseFloat(amount) - parseFloat(column.lowerLimit)).toFixed(6);
      const forPorcentage = (parseFloat(subtractLimitLower) * parseFloat(column.porcentage)).toFixed(6);
      const plusFixedFee = (parseFloat(forPorcentage) + parseFloat(column.fixedFee))
      option = {
        ...column,
        forPorcentage: forPorcentage,
        subtractLimitLower: subtractLimitLower,
        plusFixedFee: plusFixedFee,
      }
    }
  });

  if(Object.keys(option).length === 0){
    option = initialOptionLease;
  }

  return option;
}