import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import { useDispatch, useSelector } from 'react-redux';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { additionalProcedures } from '../../../../actions/catalogs/accounters';
import { validateAdditionalProcedures } from '../../../../actions/accounting/periods';
import { TableBody, TextField, Box } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { ModalContext } from '../../../modal/ModalContext';
import Swal from 'sweetalert2';
import Tooltip from '@material-ui/core/Tooltip';

export const AditionalProcedures = () => {
    const [loading, setLoading] = useState(false);
    const [showComponent, setShowComponent] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const dispatch = useDispatch();
    const datos = useSelector(state => state.additionalProceduresData.data.items);
    const { user } = useSelector(state => state.auth);
    const { handleModal } = useContext(ModalContext);
    const totalPages = 100;
    const pagesToShow = 4;
    const [search, setSearch] = useState("");
    const [search2, setSearch2] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    let result = []

    const handleSearchChange = (event) => {
        setSearch2(event.target.value);
    };

    const handleSearchClick = () => {
        setSearchTerm(search2);
    };

    useEffect(() => {
        if (!(user.descriptionRole === "Service Customer" && user.isBoss === true) && !(user.descriptionRole === "Seller" && user.isBoss === true)) {
            setTimeout(() => {
                window.location.href = '/';
            }, 3000);
        }
    }, [user, Link]);

    useEffect(() => {
        if (searchTerm !== '') {
            dispatch(additionalProcedures(null, searchTerm));
        }
    }, [dispatch, searchTerm, pageNumber]);


    // De aqui
    const handlePreviousPage = () => {
        if (pageNumber > 1) {
            setPageNumber(pageNumber - 1);
        }
    };

    const handleNextPage = () => {
        if (pageNumber < totalPages - pagesToShow + 1) {
            setPageNumber(pageNumber + 1);
        }
    };

    const handlePageChange = (page) => {
        setPageNumber(page);
    };

    const startPage = pageNumber;
    const endPage = Math.min(startPage + pagesToShow - 1, totalPages);
    // hasta aqui es para la paginación

    useEffect(() => {
        dispatch(additionalProcedures(pageNumber, null));
    }, [dispatch, pageNumber]);

    const searcher = (e) => {
        setSearch(e.target.value)
    }

    if (!search) {
        result = datos
    } else {
        result = datos.filter((dato) =>
            dato.rfc.toLowerCase().includes(search.toLocaleLowerCase()) ||
            dato.businessName.toLowerCase().includes(search.toLocaleLowerCase()) ||
            dato.email.toLowerCase().includes(search.toLocaleLowerCase()) ||
            dato.status.toLowerCase().includes(search.toLocaleLowerCase())
        )
    }


    const conluirTramite = (saleId) => {
        Swal.fire({
            title: "Al dar clic dará como terminado el tramite del cliente",
            text: `Este proceso no se podrá deshacer ¿Deseas continuar?`,
            showCancelButton: true,
            confirmButtonText: 'Sí',
            cancelButtonText: 'No',
        }).then((result) => {
            setLoading(false);
            if (result.value) {
                setShowComponent(true);
                setLoading(true);
                dispatch(validateAdditionalProcedures(saleId, handleModal, "Concluyendo tramite"))
            }
        });
    }






    return (
        <div style={{ margin: '10px', overflow: 'hidden' }}>
            {
                !(user.descriptionRole === "Service Customer" && user.isBoss === true) && !(user.descriptionRole === "Seller" && user.isBoss === true) ?
                    (
                        <div>
                            Usuario no autorizado para esta vista
                        </div>
                    )
                    :
                    (
                        <div>
                            <Box display="flex" alignItems="center" justifyContent='space-between' marginBottom="10px" style={{ width: '100%' }}>
                                <Tooltip title="Solo buscará usuarios en la pagina actual">
                                    <TextField
                                        onChange={searcher}
                                        value={search}
                                        variant="outlined"
                                        placeholder="Buscar usuario en la página actual"
                                        style={{ marginRight: '10px', width: '40%' }}
                                    />
                                </Tooltip>
                                <Tooltip title="Buscar usuario en todos los registros existentes">
                                    <TextField
                                        onChange={handleSearchChange}
                                        value={search2}
                                        variant="outlined"
                                        placeholder="Buscar usuario general"
                                        style={{ marginRight: '10px', width: '40%' }}
                                    />
                                </Tooltip>
                                <Button
                                    onClick={handleSearchClick}
                                    variant="contained"
                                    color="primary"
                                    style={{ borderRadius: '20px', marginRight: '3px' }}
                                >
                                    Buscar
                                </Button>
                                <nav aria-label="Page navigation example">
                                    <ul className="pagination justify-content-end" style={{ margin: 0 }}>
                                        <li className={`page-item ${pageNumber === 1 ? 'disabled' : ''}`}>
                                            <a className="page-link" onClick={handlePreviousPage}>Anterior</a>
                                        </li>
                                        {[...Array(endPage - startPage + 1)].map((_, i) => (
                                            <li className={`page-item ${pageNumber === startPage + i ? 'active' : ''}`} key={i}>
                                                <a className="page-link" onClick={() => handlePageChange(startPage + i)}>{startPage + i}</a>
                                            </li>
                                        ))}
                                        <li className={`page-item ${pageNumber >= totalPages - pagesToShow + 1 ? 'disabled' : ''}`}>
                                            <a className="page-link" onClick={handleNextPage}>Siguiente</a>
                                        </li>
                                    </ul>
                                </nav>
                            </Box>
                            <TableContainer style={{ borderRadius: '10px', maxHeight: '780px' }}>
                                <Table className="table table-responsive" size="small" aria-label="sticky table" stickyHeader>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell colSpan={12} align="center">
                                                <h2 style={{ whiteSpace: 'nowrap' }}>Tramites adicionales</h2>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell class="font-weight-bold" style={{ textAlign: 'center', width: '100px' }}>ID</TableCell>
                                            <TableCell class="font-weight-bold" style={{ textAlign: 'center' }}>RFC</TableCell>
                                            <TableCell class="font-weight-bold" style={{ textAlign: 'center' }}>Contribuyente</TableCell>
                                            <TableCell class="font-weight-bold" style={{ textAlign: 'center' }}>Correo</TableCell>
                                            <TableCell class="font-weight-bold" style={{ textAlign: 'center' }}>Fecha de solicitud</TableCell>
                                            <TableCell class="font-weight-bold" style={{ textAlign: 'center' }}>Tramites</TableCell>
                                            <TableCell class="font-weight-bold" style={{ textAlign: 'center' }}>Status</TableCell>
                                            <TableCell class="font-weight-bold" style={{ textAlign: 'center' }}>Ejecutó</TableCell>
                                            <TableCell class="font-weight-bold" style={{ textAlign: 'center' }}>Concluir tramite</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            datos == null || result == [] ?
                                                (<TableRow>
                                                    <TableCell colSpan="10">
                                                        <div className="alert alert-info text-center" role="alert">
                                                            No hay contribuyentes con dichos filtros
                                                        </div>
                                                    </TableCell>
                                                </TableRow>)
                                                :
                                                (
                                                    result.map((report, index) => {
                                                        return (
                                                            <TableRow key={index} style={{ backgroundColor: 'white' }}>
                                                                <TableCell component="th" scope="row" style={{ color: 'black', textAlign: 'center', width: '100px' }}>{report.saleProductId}</TableCell>
                                                                <TableCell component="th" scope="row" style={{ color: 'black', textAlign: 'center' }}>{report.rfc}</TableCell>
                                                                <TableCell component="th" scope="row" style={{ color: 'black', textAlign: 'center' }}>{report.businessName}</TableCell>
                                                                <TableCell component="th" scope="row" style={{ color: 'black', textAlign: 'center' }}>{report.email}</TableCell>
                                                                <TableCell component="th" scope="row" style={{ color: 'black', textAlign: 'center' }}>{report.createdAt}</TableCell>
                                                                <TableCell component="th" scope="row" style={{ color: 'black', textAlign: 'center' }}>{report.description}</TableCell>
                                                                <TableCell component="th" scope="row" style={{ color: 'black', textAlign: 'center' }}>{report.status}</TableCell>
                                                                <TableCell component="th" scope="row" style={{ color: 'black', textAlign: 'center' }}>{report.attended}</TableCell>
                                                                <TableCell align="center">
                                                                    {
                                                                        report.status == "Open" ?
                                                                            (

                                                                                <Button variant='contained' color='primary' style={{ marginTop: '2px' }}
                                                                                    onClick={() =>
                                                                                        conluirTramite(report.saleProductId)
                                                                                    }
                                                                                >
                                                                                    Validar tramite
                                                                                </Button>

                                                                            )
                                                                            :
                                                                            (
                                                                                <div class="alert alert-success" role="alert">
                                                                                    ¡Tramite concluido!
                                                                                </div>
                                                                            )
                                                                    }
                                                                </TableCell>
                                                            </TableRow>
                                                        )
                                                    }
                                                    )
                                                )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                    )
            }

        </div>
    );
};
