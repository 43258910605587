import React, { useEffect, useState } from 'react';
import validator from 'validator';
import { MenuItem, TextField } from '@material-ui/core';
import { useForm } from '../../../../hooks/useForm';
import { cfdiUsesBilling, customersBilling, productsBilling, regimesStartLoadingBilling, reportsBilling, wayOfPaymentsBilling, receiverDataStartLoading } from '../../../../actions/billing/catalogs';
import { useDispatch, useSelector } from 'react-redux';

const Transmitter = ({ setformCompletion, formCompletion, setFormDetails, formValid }) => {

    //Emisor

    const {receiversData} = useSelector(state => state.userData);
    const {invoicesActivation} = useSelector(state => state.invoiceBilling)
    const {regimesBilling} = useSelector(state => state.regimeBilling);

    const dispatch = useDispatch()
    const [msgErrors, setMsgErrors] = useState({});

    const { formValues, handleInputChange, setFormValues } = useForm({
        businessName: '',
        transmitter: '',
        rfc:'',
        social: '',
        zipCode: '',
        email: '',
        regimen: ''
    });

    const { businessName, transmitter, rfc, social, zipCode, email, regimen} = formValues

    useEffect(() => {
        dispatch(regimesStartLoadingBilling())
        dispatch(receiverDataStartLoading(invoicesActivation.data.invoicerUserId, invoicesActivation.data.appKey));
    }, [])

    const isFormValid = () => {
        const errors = {}

        if( typeof transmitter === 'string' && transmitter.trim().length === 0 ) {
            errors['msgTransmitter'] = "Es requerido"
        }
        if( typeof social === 'string' && social.trim().length === 0 ) {
            errors['msgSocial'] = "Es requerido"
        }
        if( typeof zipCode === 'string' && zipCode.trim().length === 0 ) {
            errors['msgZipCode'] = "Es requerido"
        }
        if(typeof email === 'string' && !validator.isEmail(email)) {
            errors['msgEmail'] = "'Correo electrónico no es valido.'"
        }
        if( typeof regimen === 'string' && regimen.trim().length === 0 ) {
            errors['msgRegimen'] = "Es requerido"
        }

        if( formValid ) {
            setMsgErrors(errors)
        }

        if(Object.keys(errors).length !== 0 ){
            return false;
          }

          return true;
    }

    useEffect(() => {
        isFormValid()
    }, [formValid])

    useEffect(() => {
        const isValid = isFormValid();

        setformCompletion({
            ...formCompletion,
            trasmitter: isValid
        });
    }, [formValues]);

    useEffect(() => {
        setFormDetails(prevState => {
            return{
                ...prevState,
                Transmitter: {
                    BusinessName: transmitter,
                    Rfc: rfc,
                    KeyRegime: regimen,
                    PostalCode: zipCode,
                    Emails: email
                }
            }
        })
    }, [ transmitter, rfc, regimen, zipCode, email ]);

    useEffect(()=> {
        if(businessName) {
            setFormValues(prevState => {
                return{
                    transmitter: businessName.profile.businessName,
                    rfc:businessName.rfc,
                    social: businessName.profile.businessName,
                    zipCode: businessName.profile.address.postalCode,
                    email: businessName.correoElectronico,
                    regimen: businessName.profile.regime.satCode,
                }
            })
        }
    })

    return (
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2">
            <div className='row'>
                <div className="col-xs-12 col-sm-12 mb-3">
                    <TextField
                        id="businessName"
                        select
                        label="Emisor*"
                        name='businessName'
                        variant="outlined"
                        onChange={ handleInputChange }
                        helperText={ msgErrors.msgTransmitter }
                        error={ msgErrors.msgTransmitter !== undefined }
                        fullWidth={true}
                    >
                        {receiversData.map((receiver) => (
                                        <MenuItem key={receiver.id} value={receiver}>
                                            {receiver.profile.businessName}
                                        </MenuItem>
                        ))}
                    </TextField>
                </div>
            </div>
            <div className='row'>
                <div className="col-xs-4 col-sm-4 mb-3">
                    <TextField
                        id="social"
                        label="Razón Social*"
                        name='social'
                        variant="outlined"
                        value={ social }
                        onChange={ handleInputChange }
                        helperText={ msgErrors.msgSocial }
                        error={ msgErrors.msgSocial !== undefined }
                        fullWidth={true}
                    />
                </div>
                <div className="col-xs-4 col-sm-4 mb-3">
                    <TextField
                        id="zipCode"
                        label="Cóodigo Postal*"
                        name='zipCode'
                        variant="outlined"
                        value={ zipCode }
                        onChange={ handleInputChange }
                        helperText={ msgErrors.msgZipCode }
                        error={ msgErrors.msgZipCode !== undefined }
                        fullWidth={true}
                    />
                </div>
                <div className="col-xs-4 col-sm-4 mb-3">
                    <TextField
                        id="email"
                        label="Correo Electrónico*"
                        name='email'
                        variant="outlined"
                        value={ email }
                        onChange={ handleInputChange }
                        helperText={ msgErrors.msgEmail }
                        error={ msgErrors.msgEmail !== undefined }
                        fullWidth={true}
                    />
                </div>
            </div>
            <div className='row'>
                <div className="col-xs-12 col-sm-12 mb-3">
                    <TextField
                        id="regimen"
                        select
                        name='regimen'
                        label="Regimenes*"
                        value={ regimen }
                        variant="outlined"
                        onChange={ handleInputChange }
                        helperText={ msgErrors.msgRegimen }
                        error={ msgErrors.msgRegimen !== undefined }
                        fullWidth={true}
                        SelectProps={{
                            MenuProps: {
                              PaperProps: {
                                style: {
                                  maxHeight: 150, // Altura máxima del menú desplegable
                                },
                              },
                            },
                        }}
                    >
                        {regimesBilling.map((option) => (
                            <MenuItem key={option.id} value={option.satCode}>
                                {option.satCode} - { option.officialName }
                            </MenuItem>
                        ))}
                    </TextField>
                </div>
            </div>
        </div>
    )
}

export default Transmitter;
