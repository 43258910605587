import { types } from "../types/types";

const initialState = {
    authPartner: null,
};

export const authPartnerReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.authValidatePartner:
            // Comprobamos si action.payload es un array antes de desestructurarlo
            const newData = Array.isArray(action.payload) ? action.payload : action.payload;
            return {
                ...state,
                authPartner: newData
            };

        default:
            return state;
    }
};
