
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Checkbox, FormControl, InputLabel, ListItemText, MenuItem, Select, TextField } from '@material-ui/core';
import { useParams, useHistory } from 'react-router'
import { Link } from 'react-router-dom'
import { CATALOG_EXPENSE_CLASSICATION } from '../../../../constants/Routes';
import { useForm } from '../../../../../hooks/useForm';
import { regimesStartLoading } from '../../../../../actions/catalogs/regimes';
import { professionTypesStartLoading } from '../../../../../actions/catalogs/professions';
import { wayOfPaymentsCfdiStartLoading } from '../../../../../actions/catalogs/wayOfPaymentsCfdi'
import { activitiesStartLoading } from '../../../../../actions/catalogs/activities';
import { expenseClassificationAction, selectExpenseClassifications, } from '../../../../../actions/catalogs/expenseClassifications';
import { ExpenseTypesStartLoading } from '../../../../../actions/accounting/expenseTypes';
import { validateArrayPresence, validateNumberPresence, validateStringPresence } from '../../../../../helpers/validateForm';
import swal from 'sweetalert';

export const FormExpenseClassification = () => {

  const {expenseId} = useParams();
  let history = useHistory();
  
  const {formValues, setFormValues,reset} = useForm({
    
    classificationConfig: {
        regimeId: 0,
        professionId: 0,
        activityId: 0,
        wayOfPayments: []
      },
      productKey: "",
      expenseTypeId: 0,
      notes: ""
    })

  const { productKey, notes, expenseTypeId, classificationConfig } = formValues;

  const [msgErrors, setMsgErrors] = useState({});
  const [allWayofPayments, setAllWayofPayments] = useState(false);
  const {regimes} = useSelector(state => state.regime);
  const {professionTypes} = useSelector(state => state.professionType);
  const {wayOfPayments} = useSelector(state => state.wayOfPayment);
  const {activities} = useSelector(state => state.activities);
  const { expenseTypes } = useSelector(state => state.expenseType)
  const {expenseClassificationSelect} = useSelector(state => state.expenseClassifications);

  
  const dispatch = useDispatch();

  useEffect (() =>{
    if(expenseId !== undefined) { 
        dispatch(selectExpenseClassifications(expenseId))
      } else{
        reset(formValues)
        //setFormValues(formValues)
      }
  },[dispatch,expenseId])

  useEffect (() =>{
    if (regimes.length === 0) {
    dispatch(regimesStartLoading())
    }
  },[dispatch,regimes])
  
  useEffect(() => {
    if (professionTypes.length === 0) {
      dispatch(professionTypesStartLoading())
    }
  }, [dispatch, professionTypes])

  useEffect(() => {
    if(wayOfPayments.length === 0){
      dispatch(wayOfPaymentsCfdiStartLoading());
    }
  }, [dispatch, wayOfPayments])

  useEffect(() => {
    if (activities.length === 0) {
      dispatch(activitiesStartLoading())
    }
  }, [dispatch, activities])

  useEffect(() => {
    if (expenseTypes.length === 0) {
      dispatch(ExpenseTypesStartLoading());
    }
  }, [dispatch, expenseTypes])


  useEffect(() => {
    if(expenseClassificationSelect && expenseId !== undefined){
      let wayPayments= [];
      expenseClassificationSelect.classificationConfigJson.wayOfPayments.forEach((wayPayment) =>{
        wayPayments.push(wayPayment.id)
      })
      setFormValues(prevState => {
        return{
          ...prevState,
          classificationConfig: {
            regimeId: expenseClassificationSelect.classificationConfigJson.regimen.id,
            professionId: expenseClassificationSelect.classificationConfigJson.profession.id,
            activityId:   expenseClassificationSelect.classificationConfigJson.activity.id,
            wayOfPayments: wayPayments
          },
          productKey: expenseClassificationSelect.productKey,
          expenseTypeId: expenseClassificationSelect.expenseType.id,
          notes: expenseClassificationSelect.notes
        }
      })
    }
  },[dispatch,expenseClassificationSelect, setFormValues,expenseId])

  const handleInputChange = ({ target }) => {
    const {name, value} = target;
    setFormValues({
      ...formValues,
      [name]: value
    })
  }
  const handleInputChangeClassificationConfig = ({ target }) => {
    const {name, value} = target;
    setFormValues(prevState => {
      return{
        ...prevState,
        classificationConfig: {
          ...prevState.classificationConfig,
          [name]: value
        }
      }
    })
  }

  const handleChangeSelectMultiple = (e) => {
    const { value,name } = e.target;
    setFormValues(prevState => {
      return{
        ...prevState,
        classificationConfig: {
            ...prevState.classificationConfig,
            wayOfPayments: [
              ...value
            ]
        }
      }
    })
  }
  const renderSelectMultiple = () => {
    if (classificationConfig.wayOfPayments.length === 0) {
      return <em>Seleccionar formas de pago</em>
    }

    if(classificationConfig.wayOfPayments.includes(0)){
      selectAllPayments()
    }
    let render = '';
    if(!classificationConfig.wayOfPayments.includes(0)){
    
      classificationConfig.wayOfPayments.forEach(wayOfPaymentId => {
      const wayOfPayment = wayOfPayments.filter(e => (e.id === wayOfPaymentId))[0];

      render += `${wayOfPayment.description}, `
      });
    }

    return render;

  }


  const handleSubmit = (e) => {
    e.preventDefault();
     if(isFormValid()){
      if(expenseId !== undefined) { 
          dispatch(expenseClassificationAction(formValues,true,history, CATALOG_EXPENSE_CLASSICATION))
      } else{
        dispatch(expenseClassificationAction(formValues,false,history, CATALOG_EXPENSE_CLASSICATION))
      }
    }
  }

  const selectAllPayments = (e) => {
    if(allWayofPayments){
      setFormValues(prevState => {
        return{
          ...prevState,
          classificationConfig: {
            ...prevState.classificationConfig,
          wayOfPayments:[]
          }
        }
      })
    }else{
    let wayPaymentsNew=[];
      wayOfPayments.forEach((wayPayment) =>{
        wayPaymentsNew.push(wayPayment.id)
      })
      setFormValues(prevState => {
        return{
          ...prevState,
          classificationConfig: {
            ...prevState.classificationConfig,
          wayOfPayments:wayPaymentsNew
          }
        }
      })
    }
    setAllWayofPayments(!allWayofPayments)
  }

  const isFormValid = () =>{
    setMsgErrors({});

    let stringToValidate = { 
      productKey:productKey
    }

    const numberToValidate = {
      regimeId: classificationConfig.regimeId,
      professionId: classificationConfig.professionId,
      activityId: classificationConfig.activityId,
      expenseTypeId:expenseTypeId
    }

    const arraysToValidate = {wayOfPayments: classificationConfig.wayOfPayments}
    
    const validateString = validateStringPresence(stringToValidate, setMsgErrors);
    const validateNumber = validateNumberPresence(numberToValidate, setMsgErrors);
    const validateAarray = validateArrayPresence(arraysToValidate, setMsgErrors);
    if( validateString && validateNumber  && validateAarray ){
      return true;
    }
    
    swal('Error al guardar', 'Se detectaron errores, favor de revisar', 'error');
    return false;
    
  }

  return (
    <div className="container">
      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-4">
        <div className="card-header">
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <h5>Datos de la Clasificación</h5>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <Link to={CATALOG_EXPENSE_CLASSICATION} className="btn btn-danger btn-sm float-right">
                <i className="fas fa-arrow-left"></i> Regresar
              </Link>
            </div>
          </div>
          
        </div>
      </div>

      <form className="mt-4" onSubmit={handleSubmit}>
        <div className="row">
             <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-3">
              <TextField
                label="Clave" variant="outlined" size="small" autoComplete="off" fullWidth={true}
                name="productKey"
                value={productKey}
                onChange={ handleInputChange }
                helperText={ msgErrors.productKey }
                error={ msgErrors.productKey !== undefined }
                disabled={ expenseId }
              />
            </div>
            <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-3">
            <TextField
                    label="Regimen" variant="outlined" size="small" autoComplete="off" fullWidth={true}
                    select
                    SelectProps={{
                        native: true,
                    }}
                    name="regimeId"
                    value={classificationConfig.regimeId}
                    onChange={ handleInputChangeClassificationConfig }
                    helperText={msgErrors.regimeId }
                    error={msgErrors.regimeId !== undefined }
                    >
                    <option value='0'>Selecciona un regimen</option>
                    {
                      regimes.map((regime, i) => {
                        return ( regime.accounting &&
                          <option
                            key={i}
                            value={ regime.id }
                          >
                            {regime.satCode} - {regime.officialName}
                          </option>
                        )
                      })
                    }
                </TextField>
            </div>             
             <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-3">
                <TextField
                    label="Profesión" variant="outlined" size="small" autoComplete="off" fullWidth={true}
                    select
                    SelectProps={{
                        native: true,
                    }}
                    name="professionId"
                    value={classificationConfig.professionId}
                    onChange={ handleInputChangeClassificationConfig }
                    helperText={msgErrors.professionId }
                    error={msgErrors.professionId !== undefined }
                    >
                    <option value='0'>Selecciona profesión</option>
                        {
                        professionTypes.map((profession, index) => {
                            return(
                            <option
                                key={ index }
                                value={ profession.id }
                            > 
                                { profession.description }
                            </option>  
                            )
                        })
                        }
                </TextField>
            </div>
            <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-3">
                <TextField
                    label="Actividad" variant="outlined" size="small" autoComplete="off" fullWidth={true}
                    select
                    SelectProps={{
                        native: true,
                    }}
                    name="activityId"
                    value={classificationConfig.activityId}
                    onChange={ handleInputChangeClassificationConfig }
                    helperText={msgErrors.activityId }
                    error={msgErrors.activityId !== undefined }
                    >
                    <option value='0'>Selecciona una actividad</option>
                    {
                      activities.map((activity, i) => {
                        return (
                          <option
                            key={i}
                            value={ activity.id }
                          >
                            { activity.description }
                          </option>
                        )
                      })
                    }
                </TextField>
            </div>
             <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-3">
                <FormControl variant="outlined" size="small" fullWidth={ true }
                error={ msgErrors.wayOfPayments !== undefined }
                >
                    <InputLabel htmlFor="wayOfPayments-oulined">
                    Formas de pago
                </InputLabel>
                <Select
                    multiple
                    value={classificationConfig.wayOfPayments}
                    onChange={handleChangeSelectMultiple}
                    renderValue={renderSelectMultiple}
                    labelWidth={80}
                    MenuProps={{
                    PaperProps: {
                        style: {
                        maxHeight: 48 * 4.5 + 8,
                        width: 750,
                        },
                    }}
                    }
                    inputProps={{
                    name:'wayOfPayments',
                    id:"wayOfPayments-oulined"
                    }}
                >
                    <MenuItem disabled value="">
                    <em>Selecciona los que apliquen</em>
                    </MenuItem>
                    <MenuItem value={0}>
                      <Checkbox
                        checked={allWayofPayments}
                        onChange={selectAllPayments}
                      />
                        <ListItemText style={{color:'gray'}}  primary={"Todas"} />
                    </MenuItem>
                    {wayOfPayments.map((wayOfPayment, index) => {
                        return(
                        <MenuItem key={wayOfPayment.id} value={wayOfPayment.id}>
                        <Checkbox checked={classificationConfig.wayOfPayments.indexOf(wayOfPayment.id) > -1}/>
                        <ListItemText primary={wayOfPayment.description} />
                        </MenuItem>
                        )
                        })
                    }
                </Select>

                </FormControl>
            </div>
            <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-3">        
                <TextField
                    label="Tipo de gasto" variant="outlined" size="small" autoComplete="off" fullWidth={true}
                    select
                    SelectProps={{
                        native: true,
                    }}
                    name="expenseTypeId"
                    value={expenseTypeId}
                    onChange={ handleInputChange}
                    helperText={msgErrors.activityId }
                    error={msgErrors.activityId !== undefined }
                    >
                    <option value='0'>Selecciona una actividad</option>
                    {
                      expenseTypes.map((expenseType, i) => {
                        return (
                          <option
                            key={i}
                            value={ expenseType.id }
                          >
                            { expenseType.name }
                          </option>
                        )
                      })
                    }
                </TextField>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3">
              <TextField
                label="Nota" variant="outlined" size="small" autoComplete="off" fullWidth={true}
                multiline
                rows={4}
                name="notes"
                value={notes}
                onChange={ handleInputChange }
                helperText={ msgErrors.notes }
                error={ msgErrors.notes !== undefined }
              />
            </div>
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 text-right">
                    <button className="btn btn-primary">
                      Guardar
                    </button>
            </div>
        </div>
      </form>
    </div>
  )
}
