import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { preReportsUpgradeApi, preReportsDetails } from '../../../../actions/catalogs/accounters';
import { Box, TextField } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { DETAILS_SUMMARY } from '../../../constants/Routes';
import { Link } from 'react-router-dom';
import Tooltip from '@material-ui/core/Tooltip';

const months = {
  enero: 1,
  febrero: 2,
  marzo: 3,
  abril: 4,
  mayo: 5,
  junio: 6,
  julio: 7,
  agosto: 8,
  septiembre: 9,
  octubre: 10,
  noviembre: 11,
  diciembre: 12,
};



export const PrereportsUpgrade = () => {
  // Declaramos las variables a usar
  const dispatch = useDispatch();
  const reports = useSelector(state => state.preReportUpgrade.data.headers);
  const datos = useSelector(state => state.preReportDetailsData.data);
  const [pageNumber, setPageNumber] = useState(1);
  const totalPages = 100;
  const pagesToShow = 4;
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [selectedOption, setSelectedOption] = useState("opcion0");
  const [selectedTaxRegimeId, setSelectedTaxRegimeId] = useState(0);
  const [selectedProfileId, setSelectedProfileId] = useState(0);
  const [selectedSatCode, setSelectedSatCode] = useState(0);
  const [shouldFetchDetails, setShouldFetchDetails] = useState(false);
  const [search, setSearch] = useState("");
  const [search2, setSearch2] = useState('');
  let result = []
  const [searchTerm, setSearchTerm] = useState('');


  // hacemos las llamadas a las apis
  useEffect(() => {
    dispatch(preReportsUpgradeApi(pageNumber, null));
  }, [dispatch, pageNumber]);

  useEffect(() => {
    if (shouldFetchDetails && selectedProfileId && selectedSatCode) {
      dispatch(preReportsDetails(selectedProfileId, selectedSatCode));
      setShouldFetchDetails(false);
    }
  }, [dispatch, selectedProfileId, selectedTaxRegimeId, shouldFetchDetails]);


  // De aqui
  const handleSearchChange = (event) => {
    setSearch2(event.target.value);
  };

  const handleSearchClick = () => {
    setSearchTerm(search2);
  };

  useEffect(() => {
    if (searchTerm !== '') {
      dispatch(preReportsUpgradeApi(null, searchTerm));
    }
  }, [dispatch, searchTerm, pageNumber]);
  // Hasta aqui es para la busqueda por algún parametro, nombre, rfc, correo


  // hacemos el filtrado de array en caso de que un usuario tenga más de un regimen, lo unimos
  const arrayFilter = reports.reduce((acc, current) => {
    const existingItem = acc.find(item => item.accountingPackageId === current.accountingPackageId);

    if (existingItem) {
      if (current.taxRegime) {
        if (!Array.isArray(existingItem.taxRegime)) {
          existingItem.taxRegime = existingItem.taxRegime ? [existingItem.taxRegime] : [];
        }
        existingItem.taxRegime.push([current.taxRegime, current.taxRegimeId]);
      }
    } else {
      acc.push({
        ...current,
        taxRegime: current.taxRegime ? [[current.taxRegime, current.taxRegimeId]] : [],
      });
    }
    return acc;
  }, []);


  // De aqui
  const handlePreviousPage = () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
  };

  const handleNextPage = () => {
    if (pageNumber < totalPages - pagesToShow + 1) {
      setPageNumber(pageNumber + 1);
    }
  };

  const handlePageChange = (page) => {
    setPageNumber(page);
  };

  const startPage = pageNumber;
  const endPage = Math.min(startPage + pagesToShow - 1, totalPages);
  // hasta aqui es para la paginación

  // Esto es para resetear las tablas cada que se cierre un perfil en la lista
  const toggleDetails = (index) => {
    if (selectedIndex === index) {
      setSelectedIndex(null);
      setSelectedTaxRegimeId(0);
      setSelectedProfileId(0);
      setSelectedOption('opcion0');
      dispatch({ type: 'CLEAR_PRE_REPORT_DETAILS_DATA' });
    } else {
      setSelectedIndex(index);
    }
  };

  const handleOptionChange = (event, report) => {
    // //console.log(report)
    // //console.log(event.target.value)
    // //console.log(report)
    setSelectedOption(event.target.value);
    setSelectedTaxRegimeId(report.taxRegimeId);
    setSelectedProfileId(report.profileId);
    setSelectedSatCode(event.target.value);
    // //console.log(selectedSatCode)
    setShouldFetchDetails(true);
  };
  // lo mismo de arriba


  // Esto es para separar el mes del año, ya que se entrega en array y para la redirección al prereport ocupa año y mes por separado
  const parsePeriod = (period) => {
    const [monthName, year] = period.split(' - ');
    const month = months[monthName.toLowerCase()];
    return { year: parseInt(year), month };
  };


  // De aqui para abajo es la busqueda en el search
  const searcher = (e) => {
    setSearch(e.target.value)
  }

  if (!search) {
    result = arrayFilter
  } else {
    result = arrayFilter.filter((dato) =>
      dato.businessName.toLowerCase().includes(search.toLocaleLowerCase()) || dato.rfc.toLowerCase().includes(search.toLocaleLowerCase()) || dato.email.toLowerCase().includes(search.toLocaleLowerCase())
    )
  }

  return (
    <div style={{ margin: '10px', overflow: 'hidden' }}>
      <Box display="flex" alignItems="center" justifyContent='space-between' marginBottom="10px" style={{ width: '100%' }}>
        <Tooltip title="Solo buscará usuarios en la pagina actual">
          <TextField
            onChange={searcher}
            value={search}
            variant="outlined"
            placeholder="Buscar usuario en la página actual"
            style={{ marginRight: '10px', width: '40%' }}
          />
        </Tooltip>
        <Tooltip title="Buscar usuario en todos los registros existentes">
          <TextField
            onChange={handleSearchChange}
            value={search2}
            variant="outlined"
            placeholder="Buscar usuario general"
            style={{ marginRight: '10px', width: '40%' }}
          />
        </Tooltip>
        <Button
          onClick={handleSearchClick}
          variant="contained"
          color="primary"
          style={{ borderRadius: '20px', marginRight: '3px' }}
        >
          Buscar
        </Button>
        <nav aria-label="Page navigation example">
          <ul className="pagination justify-content-end" style={{ margin: 0 }}>
            <li className={`page-item ${pageNumber === 1 ? 'disabled' : ''}`}>
              <a className="page-link" onClick={handlePreviousPage}>Anterior</a>
            </li>
            {[...Array(endPage - startPage + 1)].map((_, i) => (
              <li className={`page-item ${pageNumber === startPage + i ? 'active' : ''}`} key={i}>
                <a className="page-link" onClick={() => handlePageChange(startPage + i)}>{startPage + i}</a>
              </li>
            ))}
            <li className={`page-item ${pageNumber >= totalPages - pagesToShow + 1 ? 'disabled' : ''}`}>
              <a className="page-link" onClick={handleNextPage}>Siguiente</a>
            </li>
          </ul>
        </nav>
      </Box>

      <TableContainer style={{ borderRadius: '10px', maxHeight: '780px' }}>
        <Table className="table table-responsive" size="small" aria-label="sticky table" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell colSpan={12} align="center">
                <h2 style={{ whiteSpace: 'nowrap' }}>Pre-reportes</h2>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableHead>
            <TableRow>
              <TableCell class="font-weight-bold" style={{ textAlign: 'center', width: '50px' }}>Paquete</TableCell>
              <TableCell class="font-weight-bold" style={{ textAlign: 'center' }}>Datos Contribuyente</TableCell>
              <TableCell class="font-weight-bold" style={{ textAlign: 'center' }}>Declaraciones</TableCell>
              <TableCell class="font-weight-bold" style={{ textAlign: 'center' }}>Régimen</TableCell>
              <TableCell class="font-weight-bold" style={{ textAlign: 'center' }}>Contador</TableCell>
              <TableCell class="font-weight-bold" style={{ textAlign: 'center' }}>Vendedor</TableCell>
              <TableCell class="font-weight-bold" style={{ textAlign: 'center' }}>Alianza</TableCell>
              <TableCell class="font-weight-bold" style={{ textAlign: 'center' }}>Mostrar más</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {result.map((report, index) => (
              <React.Fragment key={index}>
                <TableRow>
                  <TableCell component="th" scope="row" style={{ color: 'black', width: '50px' }}>
                    {report.accountingPackageId}
                  </TableCell>
                  <TableCell align="center" style={{ fontSize: '80%', whiteSpace: 'nowrap', overflow: 'hidden', color: 'black' }}>Razón Social:{report.businessName}<br />RFC: {report.rfc}<br />Email: {report.email}</TableCell>
                  <TableCell align="center" style={{ fontSize: '80%', whiteSpace: 'nowrap', overflow: 'hidden', color: 'black' }}>Presentadas:{report.presented}<br />Por presentar: {report.toPresent}<br />Total:{report.presented + report.toPresent}</TableCell>
                  <TableCell align="center" style={{ color: 'black' }}>
                    {Array.isArray(report.taxRegime) ? (
                      report.taxRegime.map((regime, idx) => (
                        <div key={idx}>{regime[0]}</div>
                      ))
                    ) : (
                      <div>{report.taxRegime[0][0]}</div>
                    )}
                  </TableCell>
                  <TableCell align="center" style={{ color: 'black' }}>{report.accountant}</TableCell>
                  <TableCell align="center" style={{ color: 'black' }}>{report.seller}</TableCell>
                  <TableCell align="center" style={{ color: 'black' }}>
                    {report.partnership == null && report.partnership == "" ? report.partnership : "Sin alianza"}
                  </TableCell>
                  <TableCell align="center" style={{ color: '#FFFFFF' }}>
                    <Button onClick={() => toggleDetails(index)} variant="contained" color="secondary" style={{ backgroundColor: '#00AD87', color: '#221158' }}>
                      {selectedIndex === index ? 'Ocultar detalles' : 'Ver detalles'}
                    </Button>
                  </TableCell>
                </TableRow>
                {selectedIndex === index && (
                  <TableRow style={{ backgroundColor: '#B4A7D6' }}>
                    <TableCell colSpan={10}>
                      <div style={{ marginBottom: '10px' }}>
                        <Select value={selectedOption} onChange={(event) => handleOptionChange(event, report)}>
                          <MenuItem value="opcion0">Regimenes</MenuItem>
                          {Array.isArray(report.taxRegime) ? (
                            report.taxRegime.map((regime, idx) => (
                              <MenuItem key={idx} value={regime[1]}>
                                {regime[0]}
                              </MenuItem>
                            ))
                          ) : (
                            <MenuItem value="opcion1">{report.taxRegime}</MenuItem>
                          )}
                        </Select>
                      </div>

                      <TableContainer style={{ maxHeight: '400px' }}>
                        <Table size="small" aria-label="status table" stickyHeader>
                          <TableHead>
                            <TableRow>
                              <TableCell align="center">Régimen</TableCell>
                              <TableCell align="center">Período</TableCell>
                              <TableCell align="center">Plan contratado</TableCell>
                              <TableCell align="center">Descarga</TableCell>
                              <TableCell align="center">Clasificación</TableCell>
                              <TableCell align="center">Cálculo</TableCell>
                              <TableCell align="center">Reporte</TableCell>
                              <TableCell align="center">Ir a reporte</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {datos.length > 0 ? (
                              datos.map((dato, index) => {
                                const { year, month } = parsePeriod(dato.period);
                                return (
                                  <TableRow key={index}>
                                    <TableCell align="center">{dato.satCode}</TableCell>
                                    <TableCell align="center">{dato.period}</TableCell>
                                    <TableCell align="center">{dato.contractPlan}</TableCell>
                                    <TableCell align="center">{dato.status.length > 0 ? dato.status[0].descarga : 'Sin datos para mostrar'}</TableCell>
                                    <TableCell align="center">{dato.status.length > 0 ? dato.status[0].clasificacion : 'Sin datos para mostrar'}</TableCell>
                                    <TableCell align="center">{dato.status.length > 0 ? dato.status[0].calculo : 'Sin datos para mostrar'}</TableCell>
                                    <TableCell align="center">{dato.status.length > 0 ? dato.status[0].reporte : 'Sin datos para mostrar'}</TableCell>
                                    <TableCell align="center">
                                      <Button variant="contained" color="primary">
                                        <Link to={DETAILS_SUMMARY(report.profileId, dato.satCode, year, month, true)} style={{ color: 'white', textDecoration: 'none' }}>
                                          Ir a Reporte
                                        </Link>
                                      </Button>
                                    </TableCell>
                                  </TableRow>
                                );
                              })
                            ) : (
                              <TableRow>
                                <TableCell align="center" colSpan={8}>
                                  No hay datos disponibles.
                                </TableCell>
                              </TableRow>
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </TableCell>
                  </TableRow>
                )}
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};
