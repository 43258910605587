import { fetchWithApiKey } from "../../helpers/fetch";
import { types } from "../../types/types";

export const serviceTypeRetentionsStart = () => {
  return async(dispatch) => {
    const resp = await fetchWithApiKey( 'api/Catalogs/c_ServiceTypeRetention');
    const body = await resp.json();
    if (body.success){
      dispatch(serviceTypeRetentionLoaded(body.data));
    }
  }
}

const serviceTypeRetentionLoaded = ( serviceTypes ) => ({
  type: types.serviceTypeRetentionsStartLoading,
  payload: serviceTypes
})