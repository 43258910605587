import { TextField } from '@material-ui/core';
import React, { useContext, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from '../../../../hooks/useForm';
import { ModalContext } from '../../../modal/ModalContext';
import { startUpdateTaxpayer } from '../../../../actions/taxpayers';
import { validationPasswordSat } from '../../../../actions/taxpayers/validationPasswordSat';

export const ChangePasswordSatModal = ({ profile }) => {

  const dispatch = useDispatch();
  const { sesionSat, captcha } = useSelector(state => state.validationSat);
  const { handleModal } = useContext(ModalContext)

  const { formValues, handleInputChange } = useForm({
    profileId: profile.id,
    passwordSat: '',
    imgcaptcha: ''
  })

  const { passwordSat, imgcaptcha } = formValues;

  useEffect(() => {
    dispatch(validationPasswordSat({ rfc: profile.rfc, passwordSat: "prueba", captcha: "h8a2j1" }, null, 'Obteniendo captcha'))
  }, [])

  const validateSubmit = (e) => {
    e.preventDefault();
    dispatch(validationPasswordSat({ rfc: profile.rfc, passwordSat: passwordSat, captcha: imgcaptcha }, null, 'Validando usuario'))
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(startUpdateTaxpayer({
      ...formValues
    }, handleModal.bind()))
  }

  return (
    <div style={{ width: "300px" }}>
      <h4 className="text-center">Cambiar contraseña Sat</h4>
      <hr />
      <form onSubmit={handleSubmit}>
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3">
          <TextField
            label="Contraseña sat"
            variant="outlined"
            size="small"
            name="passwordSat"
            value={passwordSat}
            onChange={handleInputChange}
            autoComplete="off"
            fullWidth={true}
          />
        </div>
        <div className="text-center my-3">
          {!captcha ? (
            <div class="spinner-border" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          ) : (
            <img className="text-center" height="80" src={'data:image/jpeg;base64,' + captcha} />
          )
          }
        </div>
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3">
          <TextField
            label="Captcha" variant="outlined" size="small" autoComplete="off" fullWidth={true}
            name="imgcaptcha"
            value={imgcaptcha}
            onChange={handleInputChange}
          />
        </div>
        <div className="col-md-12 ">
          {imgcaptcha &&
            <button className="btn btn-danger ml-2 text-left" onClick={validateSubmit}>Validar</button>
          }
          {sesionSat ?
            (<button className="btn btn-primary">
              Guardar
            </button>
            ) :
            (<p class="h5 text-danger">No se pudo validar la contraseña SAT, revise RFC, Contraseña y Captcha. Reintente.</p>)
          }
        </div>
      </form>

    </div>
  )
}