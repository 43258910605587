import React, { useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { productToPaymentLinkStart, generatePaymentLinkStart, productsToPaymentLink } from '../../../../actions/catalogs/productToSales';
import validator from 'validator';
import Swal from 'sweetalert2';
import swal from 'sweetalert';

const Products = () => {

    const dispatch = useDispatch();
    const { productsToPaymentLink, productLink } = useSelector(state => state.productToSale);

    useEffect(() => {
        if (productsToPaymentLink.length === 0) {
            dispatch(productToPaymentLinkStart());
        }
    }, [dispatch]);


    const viewAlert = (product) => {
        const productLink=product.subscription.paymentLink
       
        swal({
            title: 'Recuerda que...',
            text: "Cuando se le solicite ingresar una dirección de correo electrónico, debe ser la misma que le entrego a nuestros vendedores.",
            icon: "info",
            buttons: ["Continuar", "Copiar link"],
            dangerMode: true,
            })
            .then((isCopy) => {
            if (isCopy) {
                navigator.clipboard.writeText(productLink)
            } else {
                window.location.href =productLink;
            }
        });
    }

    const addEmail = (id) => {
        Swal.fire({
            title: "Correo",
            text: "Escribe el correo al cual llegara la liga de pago",
            input: 'email',     
            confirmButtonText: 'Enviar', 
            showCancelButton: true,
            inputValidator: email => {
                if (!validator.isEmail(email)) {
                    return "Correo electrónico no es valido";
                } 
            },
            allowOutsideClick: false
            }) .then((result) => {
                if (result.value) {
                    dispatch(generatePaymentLinkStart(id,result.value))
                }
            });
    }


    return (
        <Fragment>
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3">
                <div class="row">
                    {productsToPaymentLink.length === 0 ?
                        (<div className='col-12'>
                            <div className="alert alert-primary text-center mb-0" role="alert">
                                No existen productos activos
                            </div>
                        </div>) :
                        (
                            productsToPaymentLink.map((product, index) => {
                                return (
                                    product.productConfig && product.productConfig.subscription && product.paymentReferenceType === "WEB" &&
                                    <div className="col-sm-12 col-md-6 col-lg-4 col-xl-3 mt-2" key={index}>
                                        <div >
                                            <div class="card text-center ">
                                                <div class="card-header text-success">
                                                    {product.key}
                                                </div>
                                                <div class="card-body">
                                                    <h5 class="card-title "><strong> $ {product.unitPrice} MXN </strong></h5>
                                                    <p class="card-text">{product.description}</p>
                                                </div>
                                                <div class="card-footer text-muted">
                                                    <button className="btn btn-sm btn-primary mr-1"
                                                        data-toggle="tooltip" data-placement="top" title="Generar venta"
                                                        onClick={ () => viewAlert(product.productConfig) }
                                                        >
                                                        Suscribirme
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        )
                    }
                </div>
            </div>
        </Fragment>
    )
}

export default withRouter(Products);