import React, { useContext, useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { TableBody } from '@material-ui/core';
import { Table, TableCell, TableHead, TableContainer, TableRow, TextField } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import NewClaveProdServByActivityModal from '../modals/NewClaveProdServByActivityModal/NewClaveProdServByActivityModal';
import { ModalContext } from '../../../../../modal/ModalContext';
import { getAllClaveProdServ, getClavProdNotValidated, getClavProdServByActiviy } from '../../../../../../actions/catalogs/configurationCatalogs';
import { loadingModal } from '../../../../../../helpers/UseSwal';
import EditClavProdServModal from '../modals/EditClavProdServModal/EditClavProdServModal';
import { cfdiUsesStartLoading } from '../../../../../../actions/catalogs/cfdiUses';
import { regimesConfigLoading } from '../../../../../../actions/catalogs/regimes';
import { wayOfPaymentsCfdiStartLoading } from '../../../../../../actions/catalogs/wayOfPaymentsCfdi'
import { paymentMethodsCfdiStartLoading } from '../../../../../../actions/catalogs/paymentMethod';
import { ExpenseTypesStartLoading } from '../../../../../../actions/accounting/expenseTypes';

const ConfiguracionClaveProdServ = ({ }) => {
	const { classify } = useSelector(state => state.clavProdServ);
	const { classifyNotValidated } = useSelector(state => state.clavProdServNotValidated);
	const { handleModal } = useContext(ModalContext);

	const dispatch = useDispatch();

	let params = useParams();

	useEffect(() => {
		loadingModal(true);
		dispatch(getClavProdServByActiviy({ "satCode": params.idRegimen, activityId: parseInt(params.idActivity) }));
	}, [dispatch, getClavProdServByActiviy, params.idActivity]);

	useEffect(() => {
		dispatch(getClavProdNotValidated({ "satCode": params.idRegimen, activityId: parseInt(params.idActivity) }))
		//console.log(classifyNotValidated);
	}, [])

	useEffect(() => {
		dispatch(getAllClaveProdServ());
	}, []);

	useEffect(() => {
		dispatch(regimesConfigLoading());
	}, []);

	useEffect(() => {
		dispatch(cfdiUsesStartLoading());
	}, [])

	useEffect(() => {
		dispatch(wayOfPaymentsCfdiStartLoading());
	}, [])

	useEffect(() => {
		dispatch(paymentMethodsCfdiStartLoading());
	}, []);

	useEffect(() => {
		dispatch(ExpenseTypesStartLoading());
	}, []);

	const [activeTab, setActiveTab] = useState('actual');

	const handleTabChange = (tabName) => {
		setActiveTab(tabName);
	}

	return (
		<div>
			<div className='col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12'>
				<div className='mt-2 ml-4'>
					<div className='row d-flex justify-content-start'>
						<div className='col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12'>
							<h4>
								Configuración de catálogos
							</h4>
						</div>
					</div>
				</div>
				<div className='col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-4'>
					<div className='card'>
						<div className='card-body'>
							<div className='row'>
								<div className='col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12'>
									<div className='row d-flex justify-content-between'>
										<ul className='nav nav-tabs'>
											<li className='nav-item'>
												<button
													className={`nav-link ${activeTab === 'actual' ? 'active' : ''}`}
													onClick={() => handleTabChange('actual')}
												>
													Listado de Claves de Producto y Servicio Validadas
												</button>
											</li>
											<li className='nav-item'>
												<button
													className={`nav-link ${activeTab === 'novalido' ? 'active' : ''}`}
													onClick={() => handleTabChange('novalido')}
												>
													Listado de Claves de Producto y Servicio Sin Validar
												</button>
											</li>
										</ul>
										<div className='col-md-2 float-right d-flex justify-content-end'>
											<button className='btn btn-sm btn-primary d-flex align-items-center'
												data-toggle="tooltip" data-placement="top" title=" Nueva actividad"
												onClick={() => handleModal(<NewClaveProdServByActivityModal idRegimen={params.idRegimen} idActivity={params.idActivity} />)}
											>
												<h6 style={{ margin: 0 }}>
													<i className="fas fa-plus" style={{ marginRight: '5px' }}></i> Nuevo Producto
												</h6>
											</button>
										</div>

									</div>
								</div>

								<div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
									{activeTab === 'actual' && (
										<TableContainer>
											<Table>
												<TableHead>
													<TableRow>
														<TableCell align="center">
															<strong>ID</strong>
														</TableCell>
														<TableCell align="center">
															<strong>Clave de Producto</strong>
														</TableCell>
														<TableCell align="center">
															<strong>Descripcion</strong>
														</TableCell>
														<TableCell align="center">
															<strong>USO DE CFDI</strong>
														</TableCell>
														<TableCell align="center">
															<strong>Régimen Receptor</strong>
														</TableCell>
														<TableCell align="center">
															<strong>Tipo de Gasto</strong>
														</TableCell>
														<TableCell align="center">
															<strong>Forma de pago</strong>
														</TableCell>
														<TableCell align="center">
															<strong>Método de pago</strong>
														</TableCell>
														<TableCell align="center">
															<strong>Límite</strong>
														</TableCell>
														<TableCell align="center">
															<strong>Límite de la forma de pago</strong>
														</TableCell>
														<TableCell align="center">
															<strong>Acciones</strong>
														</TableCell>
													</TableRow>
												</TableHead>
												<TableBody>
													{
														classify.map((clasificacion) => {
															return (
																<TableRow>
																	<TableCell align="center">
																		<h6>{clasificacion.id}</h6>
																	</TableCell>
																	<TableCell align="center">
																		<h6>{clasificacion.claveProdServ.key}</h6>
																	</TableCell>
																	<TableCell align="center">
																		<h6>{clasificacion.claveProdServ.description}</h6>
																	</TableCell>
																	<TableCell align='center'>
																		<h6>{clasificacion.useCfdi}</h6>
																	</TableCell>
																	<TableCell align='center'>
																		<h6>{clasificacion.regimen.satCode}</h6>
																	</TableCell>
																	<TableCell align='center'>
																		<h6>{clasificacion.expenseType.name}</h6>
																	</TableCell>
																	<TableCell align='center' className='row'>
																		{clasificacion.listWayOfPayment.map((formaPago) => {
																			return (
																				<div className='row d-flex bg-red'>
																					<h7>{formaPago.key} - {formaPago.description}</h7>
																				</div>
																			)
																		})}
																	</TableCell>
																	<TableCell align='center'>
																		<h6>{clasificacion.paymentMethod}</h6>
																	</TableCell>
																	<TableCell align='center'>
																		<h6>{clasificacion.limit}</h6>
																	</TableCell>
																	<TableCell align='center'>
																		<h6>{clasificacion.limitPaymentForm}</h6>
																	</TableCell>
																	<TableCell align="center">
																		{/* <Link to={VIEW_CONFIG_CLASSIFY(actividades.id)}> */}
																		<button className='btn btn-sm m-1'
																			onClick={() => handleModal(<EditClavProdServModal
																				idRegimen={params.idRegimen}
																				idActivity={params.idActivity}
																				metodosPago={clasificacion.paymentMethod}
																				claveProdServ={clasificacion.claveProdServ.id}
																				id={clasificacion.id}
																				idProffession={clasificacion.activity.professionIds}
																				description={clasificacion.observation}
																				usocfdi={clasificacion.useCfdi}
																				regimenRecep={clasificacion.regimen.id}
																				formasPago={clasificacion.wayOfPayment}
																				tipoGasto={clasificacion.expenseType.id}
																				limite={clasificacion.limit}
																				limiteFormaPago={clasificacion.limitPaymentForm}
																				check={clasificacion.check}
																				valido={clasificacion.isActive}
																			/>)}>
																			<i className="fas fa-edit"></i>
																		</button>
																		{/* </Link> */}
																	</TableCell>
																</TableRow>
															)
														})
													}
												</TableBody>
											</Table>
										</TableContainer>
									)}
									{
										activeTab === 'novalido' && (
											<TableContainer>
												<Table>
													<TableHead>
														<TableRow>
															<TableCell align="center">
																<strong>ID</strong>
															</TableCell>
															<TableCell align="center">
																<strong>Clave de Producto</strong>
															</TableCell>
															<TableCell align="center">
																<strong>Descripcion</strong>
															</TableCell>
															<TableCell align="center">
																<strong>USO DE CFDI</strong>
															</TableCell>
															<TableCell align="center">
																<strong>Régimen Receptor</strong>
															</TableCell>
															<TableCell align="center">
																<strong>Tipo de Gasto</strong>
															</TableCell>
															<TableCell align="center">
																<strong>Forma de pago</strong>
															</TableCell>
															<TableCell align="center">
																<strong>Método de pago</strong>
															</TableCell>
															<TableCell align="center">
																<strong>Límite</strong>
															</TableCell>
															<TableCell align="center">
																<strong>Límite de la forma de pago</strong>
															</TableCell>
															<TableCell align="center">
																<strong>Acciones</strong>
															</TableCell>
														</TableRow>
													</TableHead>
													<TableBody>
														{
															classifyNotValidated.map((noValidos) => {
																return (
																	<TableRow>
																		<TableCell align="center">
																			<h6>{noValidos.id}</h6>
																		</TableCell>
																		<TableCell align="center">
																			<h6>{noValidos.claveProdServ.key}</h6>
																		</TableCell>
																		<TableCell align="center">
																			<h6>{noValidos.claveProdServ.description}</h6>
																		</TableCell>
																		<TableCell align='center'>
																			<h6>{noValidos.useCfdi}</h6>
																		</TableCell>
																		<TableCell align='center'>
																			<h6>{noValidos.regimen.satCode}</h6>
																		</TableCell>
																		<TableCell align='center'>
																			<h6>{noValidos.expenseType ? noValidos.expenseType.name : 'No registrado'}</h6>
																		</TableCell>
																		<TableCell align='center' className='row'>
																			{noValidos.listWayOfPayment.map((formaPago) => {
																				return (
																					<div className='row d-flex bg-red'>
																						<h7>{formaPago.key} - {formaPago.description}</h7>
																					</div>
																				)
																			})}
																		</TableCell>
																		<TableCell align='center'>
																			<h6>{noValidos.paymentMethod}</h6>
																		</TableCell>
																		<TableCell align='center'>
																			<h6>{noValidos.limit}</h6>
																		</TableCell>
																		<TableCell align='center'>
																			<h6>{noValidos.limitPaymentForm ? noValidos.limitPaymentFor : 'No registrado'}</h6>
																		</TableCell>
																		<TableCell align="center">
																			{/* <Link to={VIEW_CONFIG_CLASSIFY(actividades.id)}> */}
																			<button className='btn btn-sm m-1'
																				onClick={() => handleModal(<EditClavProdServModal
																					idRegimen={params.idRegimen}
																					idActivity={params.idActivity}
																					metodosPago={noValidos.paymentMethod}
																					claveProdServ={noValidos.claveProdServ.id}
																					id={noValidos.id}
																					idProffession={noValidos.activity.professionIds}
																					description={noValidos.observation}
																					usocfdi={noValidos.useCfdi}
																					regimenRecep={noValidos.regimen.id}
																					formasPago={noValidos.wayOfPayment}
																					tipoGasto={0}
																					limite={noValidos.limit}
																					limiteFormaPago={noValidos.limitPaymentForm}
																					check={noValidos.check}
																					valido={noValidos.isActive}
																				/>)}>
																				<i className="fas fa-edit"></i>
																			</button>
																			{/* </Link> */}
																		</TableCell>
																	</TableRow>
																)
															})
														}
													</TableBody>
												</Table>
											</TableContainer>
										)
									}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

ConfiguracionClaveProdServ.propTypes = {};

export default ConfiguracionClaveProdServ;
