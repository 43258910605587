import swal from "sweetalert";
import { fetchWithToken, fetchWithTokenFormDataExist } from "../../helpers/fetch";
import { saveModal } from "../../helpers/UseSwal";
import { types } from "../../types/types";

export const startRegisterCertificate = (certificate, handleModal) => {
  return async(dispatch) => {
    saveModal(true);
    const resp = await fetchWithTokenFormDataExist('api/TaxFiles/upload', certificate, 'PUT');
    const body = await resp.json();
    if (body.success){
      dispatch(registerCertificate( body.data ));
      handleModal();
    }else{
      swal('Error al guardar', 'Comprueba la información de tus archivos', 'error');
    }
    saveModal(false);
  }
}

const registerCertificate = (certificate) => ({
  type: types.certificatesStartRegister,
  payload: certificate
})

export const certificatesStartLoading = (profileId) => {
  return async(dispatch) => {
    // loadingModal(true);
    const resp = await fetchWithToken(`api/TaxFiles/getInfo/${profileId}`);
    const body = await resp.json();    
    if (body.success){
      dispatch(loadedCertificates(body.data))
    }
    // loadingModal(false);
  }
}

const loadedCertificates = (certificates) => ({
  type: types.certificatesStartLoading,
  payload: certificates
})
