import { types } from "../../types/types";

const initialState = {
  incomes: [],
  finishIncomes:false,
  statusIncomeDownload:''
}

export const incomeReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.incomeStartRegister:
      return {
        ...state,
        incomes: [
          ...state.incomes,
          action.payload
        ]
      }
    case types.incomeStartUpdate:
      return {
        ...state,
        incomes: state.incomes.map(
          e => (e.id === action.payload.id) ? { ...e, ...action.payload } : e
        )
      }
    case types.incomeStartChangeAutorization:
      return {
        ...state,
        incomes: state.incomes.map(
          income => (income.id === action.payload.incomeId) ?
            {
              ...income,
              authorization: [ ...action.payload.authorization ]
            }
            :
            income
        )
      }
    
    case types.incomeStartLoading:
      return  {
        ...state,
        incomes: [ ...action.payload ]
      }
    
    case types.incomeFinishLoading:
      return  {
        ...state,
        finishIncomes: action.payload
      }

      case types.incomeFinishStatus:
      return  {
        ...state,
        statusIncomeDownload: action.payload
      }
    
    default:
      return state;
  }
}
