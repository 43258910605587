import { types } from "../types/types";

export const lineItemAddToList = ( lineItem ) => ({
  type: types.lineItemAddToList,
  payload: lineItem
});

export const lineItemDeletedToList = ( lineItem ) => ({
  type: types.lineItemDeletedToList,
  payload: lineItem
});

export const lineItemAddNew = ( lineItem ) => ({
  type: types.lineItemAddNew,
  payload: lineItem
});