import { fetchWithApiKey } from "../../helpers/fetch";
import { loadingModal } from "../../helpers/UseSwal";
import { types } from "../../types/types";

export const thirdPartyTypesStartLoading = () => {
  return async(dispatch) => {
    loadingModal(true);
    const resp = await fetchWithApiKey('api/Catalogs/c_ThirdPartieType');
    const body = await resp.json();
    if (body.success){
      dispatch(thirdPartyTypesLoaded(body.data))
    }
    loadingModal(false);
  }
}

const thirdPartyTypesLoaded = (thirdPartyTypes) => ({
  type: types.thirdPartyTypeStartLoadind,
  payload: thirdPartyTypes
})