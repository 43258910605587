import { type } from "jquery";
import { fetchWithApiKey, fetchWithToken } from "../../helpers/fetch";
import { types } from "../../types/types";
import { loadingModal, loadingModalMsn } from "../../helpers/UseSwal";

export const regimesStartLoading = () => {
  return async(dispatch) => {
    const resp = await fetchWithApiKey( 'api/Catalogs/c_Regimen');
    const body = await resp.json();
    if (body.success){
      dispatch( regimeLoaded( body.data ) )
    }
  }
}

const regimeLoaded = ( regimes ) => ({
  type: types.regimenesStartLoading,
  payload: regimes
})

export const regimesConfigLoading = () => {
  return async(dispatch) => {
    loadingModal(true);
    const resp = await fetchWithToken('api/Classification/GetRegimensActivitiesProfessions');
    const body = await resp.json();
    if(body.success){
      loadingModal(false);
      dispatch(regimeConfig(body.data));
    }
  }
};

const regimeConfig = (regimenes) => ({
  type: types.regimesConfig,
  payload: regimenes
})
