import React from 'react';
import { NavLink } from 'react-router-dom';
import * as ROUTES from '../../../constants/Routes';
import { DETAILS_EMPLOYEE } from '../../../constants/Routes';
import { Link } from 'react-router-dom';

const ShowEmployee = () => {
    return (
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2">
            <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <NavLink to={ROUTES.NEW_EMPLOYEE} className="btn btn-primary ml-2 mt-2">
                        <i className="fas fa-user-plus"></i> Agregar Empleado
                    </NavLink>
                    <NavLink to={ROUTES.NEW_EMPLOYEE} className="btn btn-primary ml-2 mt-2">
                        <i className="fas fa-file-upload"></i> Importar Empleados
                    </NavLink>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                    <input type="text" className="form-control mt-2" placeholder="Buscar" />
                </div>
                <div className="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                    <button className="btn btn-info mt-2"> <i className="fas fa-search"></i> Buscar</button>
                </div>
            </div>
            <div className="row mt-5">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <div className="table-responsive">
                        <table className="table table-striped">
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Número Nomina</th>
                                    <th scope="col">Empleado</th>
                                    <th scope="col">RFC</th>
                                    <th scope="col">Puesto</th>
                                    <th scope="col">Activo</th>
                                    <th scope="col">Operación</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>1</td>
                                    <td>646</td>
                                    <td>Ricardo Garcia</td>
                                    <td>GAAR960903LF6</td>
                                    <td>Desarrollador</td>
                                    <td>Si</td>
                                    <td>
                                        <Link
                                            to={DETAILS_EMPLOYEE(1)} data-toggle="tooltip" data-placement="top"
                                            title="Detalle Empleado" className="btn btn-warning btn-sm"
                                            > <i className="fas fa-edit"></i>
                                        </Link>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ShowEmployee;

