import React from 'react';
import Home from './options/home';
import Tuition from './options/tuition';
import Otherincome from './options/Otherincome';
import Additionalinformation from './options/Additionalinformation';


const ShowAnnualstatement = () => {
    return (
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2">
            <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2">
                    <nav>
                        <div className="nav nav-tabs" id="nav-tab" role="tablist">
                            <a className="nav-item nav-link active" id="nav-home-tab" data-toggle="tab" href="#nav-home" role="tab" aria-controls="nav-home" aria-selected="true">
                                Inicio
                            </a>
                            <a className="nav-item nav-link" id="nav-tuition-tab" data-toggle="tab" href="#nav-tuition" role="tab" aria-controls="nav-tuition" aria-selected="true">
                                Colegiatura
                            </a>
                            <a className="nav-item nav-link" id="nav-otherincome-tab" data-toggle="tab" href="#nav-otherincome" role="tab" aria-controls="nav-otherincome" aria-selected="true">
                                Otros Ingresos
                            </a>
                            <a className="nav-item nav-link" id="nav-additionalinformation-tab" data-toggle="tab" href="#nav-additionalinformation" role="tab" aria-controls="nav-additionalinformation" aria-selected="true">
                                Información Adicional
                            </a>
                        </div>
                    </nav>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2">
                    <div className="tab-content" id="nav-tabContent">
                        <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                            <Home />
                        </div>
                        <div className="tab-pane fade" id="nav-tuition" role="tabpanel" aria-labelledby="nav-tuition-tab">
                            <Tuition />
                        </div>
                        <div className="tab-pane fade" id="nav-otherincome" role="tabpanel" aria-labelledby="nav-otherincome-tab">
                            <Otherincome />
                        </div>
                        <div className="tab-pane fade" id="nav-additionalinformation" role="tabpanel" aria-labelledby="nav-additionalinformation-tab">
                            <Additionalinformation />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ShowAnnualstatement;
