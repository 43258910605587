import React from 'react';
import { TextField } from '@material-ui/core';

export const EmployeeInfoWork = () => {
    const handleInputChange = (e) => {}
    return (
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-3">
            <h5 className="card-header">Información Laboral</h5>
            <div className="row mt-4">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 mt-3">
                            <TextField label="No. Empleado" variant="outlined" size="small" name="employee_number" fullWidth={true}/>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 mt-3">
                            <TextField label="No. Seguro Social" variant="outlined" size="small" name="social_security" fullWidth={true}/>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 mt-3">
                            <TextField label="Lugar de Trabajo" variant="outlined" size="small" name="workplace" fullWidth={true}/>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 mt-3">
                            <TextField label="Puesto" variant="outlined" size="small" name="place" fullWidth={true}/>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 mt-3">
                            <TextField select variant="outlined" label="Tipo de Contrato" size="small" 
                                fullWidth={true} name="type_contract" value="0" onChange={handleInputChange}
                                SelectProps={{native: true,}}>
                                <option value="0">Seleccionar</option>
                                <option value="1">Contrato 1</option>
                                <option value="2">Contrato 2</option>
                            </TextField>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 mt-3">
                            <TextField select variant="outlined" label="Tipo de Jornada" size="small" 
                                fullWidth={true} name="type_shift" value="0" onChange={handleInputChange}
                                SelectProps={{native: true,}}>
                                <option value="0">Seleccionar</option>
                                <option value="1">Jornada 1</option>
                                <option value="2">Jornada 2</option>
                            </TextField>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 mt-3">
                            <TextField select variant="outlined" label="Entidad Laboral" size="small" 
                                fullWidth={true} name="labor_entity_id" value="0" onChange={handleInputChange}
                                SelectProps={{native: true,}}>
                                <option value="0">Seleccionar</option>
                                <option value="1">Entidad 1</option>
                                <option value="2">Entidad 2</option>
                            </TextField>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 mt-3">
                            <TextField label="Salario Diario Integrado" variant="outlined" size="small" name="daily_salary" fullWidth={true}/>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 mt-3">
                            <TextField label="Fecha Inicial Laboral" variant="outlined" size="small"
                                        name="ws_date" fullWidth={true} type="date" InputLabelProps={{shrink: true,}}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
