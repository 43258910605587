import { fetchWithToken } from "../../helpers/fetch";
import { saveModal } from "../../helpers/UseSwal";
import { types } from "../../types/types";
import swal from "sweetalert";

export const changeAccountantAssitedStart = (information) => {
  return async (dispatch) => {
    // saveModal(true);
    const resp = await fetchWithToken('api/Accounting/accounters/reasign', information, 'POST');
    const body = await resp.json();
    if (body.success) {
      dispatch(changeAccountantUpdated(information))
    }
    // saveModal(false);
  }
}

const changeAccountantUpdated = (information) => ({
  type: types.taxpayerAssistedChangeAccountant,
  payload: information
})


export const changeSellerAssistedStart = (information) => {
  return async (dispatch) => {
    const resp = await fetchWithToken('/api/PreSale/assignseller', information, 'PUT');
    const body = await resp.json();
    if (body.success) {
      swal({
        title: '¡Vendedor reasignado!',
        icon: 'success',
      }).then(() => {
        window.location.reload();
      });
    } else {
      swal({
        title: '¡Estás reasignando al mismo vendedor o no estás seleccionando a alguno de la lista!',
        icon: 'error',
      });
    }
  }
}