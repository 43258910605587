import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { ModalContext } from '../../../modal/ModalContext';
import { TaxAddressFormAloneScreen } from './TaxAddressFormAloneScreen';

export const TaxAddressDetailsScreen = () => {

  const { taxpayerId } = useParams();

  const { handleModal } = useContext(ModalContext);

  const { taxpayerSelected } = useSelector(state => state.taxpayer);

  return (
    <div className="row">
      <div className="col-md-12 mt-4">
        <button
          className="btn btn-primary mb-3"
          onClick={() => handleModal(
            <TaxAddressFormAloneScreen
              taxpayerId={ taxpayerId }
            />, true
          )}
        >Agregar Dirección</button>
      
        <table className="table">
          <thead>
            <tr className="text-center">
              <th scope="col">Dirección</th>
              <th scope="col">Código Postal</th>
              <th scope="col">Pais</th>
              <th scope="col">Acciones</th>
            </tr>
          </thead>
          <tbody>
            {
              taxpayerSelected.addresses.map((address, index) => {
                return(
                  <tr key={index} className="text-center">
                    <td>
                      <strong>Calle: </strong>{address.street} <br/>
                      <strong>Núm. Ext.: </strong>{ address.outdoor } <br />
                      <strong>Núm. Int.: </strong>{ address.interior } <br />
                    </td>
                    <td>
                      {address.postalCode}
                    </td>
                    <td>
                      <strong>Pais: </strong>{address.country} <br />
                      <strong>Municipio: </strong>{address.suburb} <br />
                      <strong>Colonia: </strong>{address.town} <br />
                    </td>
                    <td>
                      <button
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Editar Dirección"
                        className="btn btn-sm btn-outline-primary px-2"
                        onClick={ () => handleModal(
                          <TaxAddressFormAloneScreen 
                            taxpayerId={ taxpayerId }
                            taxAddress={ address }
                          />, true
                        )}
                      >
                        <FontAwesomeIcon className="fa-lg" icon={"pencil-alt"}/>
                      </button>
                    </td>
                  </tr>
                )
              })
            }
          </tbody>
        </table>
      </div>
    </div>
  )
}
