import React from 'react';
import { TextField } from '@material-ui/core';

const Extra = () => {
    return (
        <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2">
                <h6 className="card-header">Incapacidad por riesgo de trabajo</h6>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2">
                <p>Horas Extra Simples</p>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-2">
                <TextField label="Días de horas extra simples" variant="outlined" size="small" name="name_nomina" fullWidth={true}/>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-2">
                <TextField label="Horas extra simples" variant="outlined" size="small" name="name_nomina" fullWidth={true}/>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2">
                <TextField label="Importe pagado por horas extra dobles" variant="outlined" size="small" name="name_nomina" fullWidth={true}/>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2">
                <hr/>
                <p>Horas Extra Dobles</p>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-2">
                <TextField label="Días de Horas Extra Dobles" variant="outlined" size="small" name="name_nomina" fullWidth={true}/>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-2">
                <TextField label="Horas Extra Dobles" variant="outlined" size="small" name="name_nomina" fullWidth={true}/>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2">
                <TextField label="Importe Pagado por Horas Extra Dobles" variant="outlined" size="small" name="name_nomina" fullWidth={true}/>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2">
                <hr/>
                <p>Horas Extra Triples</p>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-2">
                <TextField label="Días de Horas Extra Triples" variant="outlined" size="small" name="name_nomina" fullWidth={true}/>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-2">
                <TextField label="Horas Extra Triples" variant="outlined" size="small" name="name_nomina" fullWidth={true}/>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2">
                <TextField label="Importe Pagado por Hora Extra Triples" variant="outlined" size="small" name="name_nomina" fullWidth={true}/>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2">
                <h6 className="card-header">Separación</h6>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-2">
                <TextField label="Total Pagado" variant="outlined" size="small" name="name_nomina" fullWidth={true}/>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-2">
                <TextField label="Años de Servicio" variant="outlined" size="small" name="name_nomina" fullWidth={true}/>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2">
                <TextField label="Último Sueldo Mensual Ordinario" variant="outlined" size="small" name="name_nomina" fullWidth={true}/>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-2">
                <TextField label="Ingreso Acumulable" variant="outlined" size="small" name="name_nomina" fullWidth={true}/>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-2">
                <TextField label="Ingreso No Acumulable" variant="outlined" size="small" name="name_nomina" fullWidth={true}/>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2">
                <h6 className="card-header">Jubilación, Pensión o Retiro</h6>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-2">
                <TextField label="Total pagado en una exhibición" variant="outlined" size="small" name="name_nomina" fullWidth={true}/>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-2">
                <TextField label="Total de pago parcial" variant="outlined" size="small" name="name_nomina" fullWidth={true}/>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-2">
                <TextField label="Monto diario" variant="outlined" size="small" name="name_nomina" fullWidth={true}/>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-2">
                <TextField label="Ingreso Acumulable" variant="outlined" size="small" name="name_nomina" fullWidth={true}/>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-2">
                <TextField label="Ingreso No Acumulable" variant="outlined" size="small" name="name_nomina" fullWidth={true}/>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2">
                <h6 className="card-header">CFDI Relacionados</h6>
            </div>
        </div>
    )
}

export default Extra;
