import { types } from "../../types/types";

const initialState = {
  complements: [],
  complementShow:null,
  complementExpense:[],
  complementsIncome:[]
}

export const complementReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.complementStartLoad:
        return{
            ...state,
            complements: [ ...action.payload ]
        }

    case types.complementStartShow:
        return{
            ...state,
            complementShow: [ ...action.payload ]
        }
    case types.complementAddExpense:
      return{
          ...state,
          complementExpense: action.payload 
      }
      case types.complementAddIncome:
        return{
            ...state,
            complementsIncome: action.payload 
        }

    default:
      return state;
  }
}
