import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { expenseRegisterStart, expenseUpdateStart } from '../../../../../../../../actions/accounting/expense';
import { searchThirdPartyById, thirdPartyObtainProviders } from '../../../../../../../../helpers/thirdParty';
import { validateNumberPresence, validateStringPresence } from '../../../../../../../../helpers/validateForm';
import { useForm } from '../../../../../../../../hooks/useForm';
import { ModalContext } from '../../../../../../../modal/ModalContext';
import { ExpenseFormDownloadedSat } from './ExpenseFormDownloadedSat';
import { ExpenseFormNotDownloadedSat } from './ExpenseFormNotDownloadedSat';
import moment from 'moment';
import { taxpayerSelectedLoading } from '../../../../../../../../actions/taxpayers';
import { wayOfPaymentsCfdiStartLoading } from '../../../../../../../../actions/catalogs/wayOfPaymentsCfdi';
import { thirdPartiesStartLoading } from '../../../../../../../../actions/thirdParties';

export const ExpenseForm = ({ regimeSatCode, taxpayerId, month, year, expenseEdit=null, refreshComplementPayments }) => {
  const { handleModal } = useContext(ModalContext)

  const { accountIdentifiers } = useSelector(state => state.identifier);
  const { expenseTypes } = useSelector(state => state.expenseType);
  const { ivaTraslateds } = useSelector(state => state.ivaTraslated);
  const { taxpayerSelected } = useSelector(state => state.taxpayer);
  const { thirdParties } = useSelector(state => state.thirdParty);
  const { wayOfPayments } = useSelector(state => state.wayOfPayment);

  const dispatch = useDispatch();

  const { formValues, handleInputChange,handleCheckboxTrueFalseChange, setFormValues } = useForm({
    accountIdentifierId: '',
    concept: '',
    concepts: [],
    expenseTypeId: '',
    folio: '',
    ivaTraslated: 0.00,
    ivaTraslatedId: '',
    paymentAt: '',
    serie: '',
    stampAt: '',
    subtotal: 0.00,
    taxesTraslateds: [],
    thirdParty: null,
    thirdPartyId: 0,
    thirdPartiesProviders: [],
    total: 0.00,
    wayOfPaymentId: '',
    globalAuthorization:false
  });

  const { concept, expenseTypeId, folio,
          ivaTraslatedId, paymentAt, serie, subtotal, taxesTraslateds, thirdParty,
          thirdPartyId, thirdPartiesProviders, total, wayOfPaymentId,globalAuthorization
        } = formValues;
  
  const [msgErrors, setMsgErrors] = useState({});

  useEffect(() => {
    dispatch(taxpayerSelectedLoading(taxpayerId));
  }, [dispatch, taxpayerId]);

  useEffect(() => {
    dispatch(thirdPartiesStartLoading(taxpayerId))
  }, [dispatch, taxpayerId])

  useEffect(() => {
    if (wayOfPayments.length === 0) {
      dispatch(wayOfPaymentsCfdiStartLoading());
    }
  }, [dispatch, wayOfPayments])

  useEffect(() => {
    if(expenseEdit){
      let precargeDatas = {}
      if(expenseEdit.satDownloaded){
        precargeDatas={
          expenseTypeId: expenseEdit.expenseTypeId,
          concepts: [ ...expenseEdit.concepts ],
          paymentAt: moment(expenseEdit.paymentAt).format('YYYY-MM-DD'),
          satDownloaded: expenseEdit.satDownloaded,
          stampAt: moment(expenseEdit.stampAt).format('YYYY-MM-DD'),
          thirdParty: {
            name: expenseEdit.issuedFullName,
            rfc: expenseEdit.issuedRfc,
          },
          subtotal: parseFloat(expenseEdit.subTotal).toFixed(2),
          total: parseFloat(expenseEdit.total).toFixed(2),
          wayOfPaymentId: expenseEdit.wayOfPayment.id,
          
        }
      }else{
        if(thirdParties.length > 0){
          const foundThirdParty = searchThirdPartyById(thirdParties, expenseEdit.thirdPartie.id)
          const { thirdParty } = foundThirdParty;
          precargeDatas={
            // accountIdentifierId: expenseEdit.accountIdentifierId,
            concept: expenseEdit.concepts[0].description,
            folio: expenseEdit.folio,
            satDownloaded: expenseEdit.satDownloaded,
            serie: expenseEdit.serie,
            subtotal: expenseEdit.subTotal,
            paymentAt: moment(expenseEdit.paymentAt).format('YYYY-MM-DD'),
            thirdPartyId: expenseEdit.thirdPartie.id,
            thirdParty: { ...thirdParty },
            total: parseFloat(expenseEdit.total).toFixed(2),
            wayOfPaymentId: expenseEdit.wayOfPayment.id
          }
        }
      }
      setFormValues(prevState => {
        return{
          ...prevState,
          ...precargeDatas
        }
      })
    }

    if(thirdParties.length > 0){
      const thirdPartiesProviders = thirdPartyObtainProviders(thirdParties);

      setFormValues(prevState => {
        return{
          ...prevState,
          thirdPartiesProviders: [...thirdPartiesProviders]
        }
      })
    }
  }, [expenseEdit, thirdParties, setFormValues])

  useEffect(() => {
    if( wayOfPayments.length > 0 && !expenseEdit){
      setFormValues(prevState => {
        return{
          ...prevState,
          wayOfPaymentId: wayOfPayments[0].id
        }
      })
    }
  }, [expenseEdit, setFormValues, wayOfPayments])

  useEffect(() => {
    if( accountIdentifiers.length > 0 && !expenseEdit){
      setFormValues(prevState => {
        return{
          ...prevState,
          accountIdentifierId: accountIdentifiers[0].id
        }
      })
    }
  }, [accountIdentifiers, expenseEdit, setFormValues])

  useEffect(() => {
    if(expenseTypes.length > 0){
      let expenseType = {};
      if(expenseEdit && expenseEdit.expenseTypeId !== 0){
        expenseType = {
          expenseTypeId: expenseEdit.expenseTypeId,
          concept: expenseEdit.description
        }
      }else{
        expenseType = {
          expenseTypeId: expenseTypes[0].id,
          concept: expenseTypes[0].name
        }
      }
      setFormValues(prevState => {
        return{
          ...prevState,
          ...expenseType
        }
      })
    }
  }, [expenseEdit, expenseTypes, setFormValues]);

  useEffect(() => {
    if (expenseTypes.length > 0 && expenseTypeId) {
      const expenseType = expenseTypes.filter(e => (e.id === parseInt(expenseTypeId)))[0];
      setFormValues(prevState => {
        return{
          ...prevState,
          concept: expenseType.name
        }
      })
    }
  }, [expenseTypeId, expenseTypes, setFormValues]);

  useEffect(() => {
    if (ivaTraslateds.length > 0) {
      if(expenseEdit && !expenseEdit.satDownloaded){
        if (expenseEdit.concepts[0].impuestos.traslados.length > 0) {
          const ivaTraslatedId = ivaTraslateds.filter(e => ( e.rate === parseFloat(expenseEdit.concepts[0].impuestos.traslados[0].tasaOCuota)))[0].id;
                    
          setFormValues(prevState => {
            return{
              ...prevState,
              ivaTraslatedId: ivaTraslatedId,
              ivaTraslated: expenseEdit.concepts[0].impuestos.traslados[0].importe
            }
          })
        }
      }else{
        setFormValues(prevState => {
          return{
            ...prevState,
            ivaTraslatedId: ivaTraslateds[0].id
          }
        })
      }
    }
  }, [expenseEdit, ivaTraslateds, setFormValues])

  useEffect(() => {
    const ivaTraslated = ivaTraslateds.filter(e => (e.id === parseInt(ivaTraslatedId)))[0];

    const taxesTraslateds = [];

    if (ivaTraslated) {
      const subtotalIvaTraslated = parseFloat(parseFloat(subtotal) * parseFloat(ivaTraslated.rate)).toFixed(2);

      let totalWithDiscounts = parseFloat( parseFloat(subtotal) + parseFloat(subtotalIvaTraslated) ).toFixed(2);

      taxesTraslateds.push({
        amount: subtotalIvaTraslated,
        base: 0,
        importe: subtotalIvaTraslated,
        importeSpecified: true,
        impuesto: "002",
        tasaOCuota: ivaTraslated.rate,
        tasaOCuotaSpecified: true,
        tipoFactor: "Tasa",
      })

      setFormValues(prevState =>{
        return{
          ...prevState,
          ivaTraslated: subtotalIvaTraslated || 0.00,
          taxesTraslateds: taxesTraslateds,
          total: totalWithDiscounts || 0.00
        }
      })
    }
  }, [expenseEdit, ivaTraslatedId, ivaTraslateds, setFormValues, subtotal])

  const handleSubmit = (e) => {
    e.preventDefault();
    if(expenseEdit){
      if(expenseEdit.satDownloaded){
        const update = {
          authorizations: expenseEdit.authorization.map(
            e => (e.regimeSatCode === regimeSatCode) ?
            {
              ...e,
              expenseTypeId: parseInt(expenseTypeId)
            }
            :
            e
          ),
          expenseId: expenseEdit.id,
          paymentAt: moment(paymentAt).format('MM/DD/YYYY'),
          profileId: taxpayerId,
          stampAt: expenseEdit.stampAt,
          subtotal: (parseFloat(subtotal) > 0 ? subtotal : total),
          total: total,
          globalAuthorization:globalAuthorization
        }
        dispatch(expenseUpdateStart( update, handleModal.bind(), refreshComplementPayments ));
      }else{
        if(isFormValid()){
          const update = {
            authorizations: [
              {
                authorized: expenseEdit['authorization'][0]['authorized'],
                expenseTypeId: parseInt(expenseTypeId),
                regimeSatCode: regimeSatCode,
              }
            ],
            concepts: [
              {
                claveProdServ: null,
                descripcion: concept,
                subtotal: (parseFloat(subtotal) > 0 ? subtotal : total),
                impuestos:{
                  traslados: taxesTraslateds,
                  retenciones: [],
                },
                total: total,
              }
            ],
            expenseId: expenseEdit.id,
            folio: folio,
            IssuedFullName: thirdParty.businessName,
            issuedRfc: thirdParty.rfc,
            receivedFullName: expenseEdit.profile.businessName,
            receivedRfc: expenseEdit.profile.rfc,
            regimeSatCode: expenseEdit.regimeSatCode,
            paymentAt: moment(paymentAt).format('MM/DD/YYYY'),
            profileId: taxpayerId,
            thirdPartieId: thirdParty.id,
            total: total,
            satDownloaded: false,
            serie: serie,
            stampAt: moment(paymentAt).format('MM/DD/YYYY'),
            subtotal: (parseFloat(subtotal) > 0 ? subtotal : total),
            wayOfPaymentId: wayOfPaymentId,
            globalAuthorization:globalAuthorization
          }
          dispatch(expenseUpdateStart( update, handleModal.bind(), refreshComplementPayments ));
        }
      }
    }else{
      if(isFormValid()){
        const register = {
          authorizations: [
            {
              autorized: true,
              expenseTypeId: parseInt(expenseTypeId),
              regimeSatCode: regimeSatCode,
            }
          ],
          concepts: [
            {
              impuestos: {
                traslados: taxesTraslateds
              },
              claveProdServ: null,
              descripcion: concept,
              subtotal: (parseFloat(subtotal) > 0 ? subtotal : total),
              total: total,
            }
          ],
          folio: folio,
          IssuedFullName: thirdParty.businessName,
          issuedRfc: thirdParty.rfc,
          receivedFullName: taxpayerSelected.businessName,
          receivedRfc: taxpayerSelected.rfc,
          satDownloaded: false,
          serie: serie,
          stampAt: moment(paymentAt).format('MM/DD/YYYY'),
          subtotal: (parseFloat(subtotal) > 0 ? subtotal : total),
          paymentAt: moment(paymentAt).format('MM/DD/YYYY'),
          profileId: taxpayerId,
          thirdPartie: thirdParty,
          thirdPartieId: thirdParty.id,
          total: total,
          wayOfPaymentId: wayOfPaymentId,
        }
        dispatch(expenseRegisterStart(register, handleModal.bind()));
      }
    }
  }

  const isFormValid = () => {
    setMsgErrors({});

    const datasStringToValidate = { paymentAt }
    const datasNumbersToValidate = { total, thirdPartyId }
    const validateString = validateStringPresence( datasStringToValidate, setMsgErrors );
    const validateNumbers = validateNumberPresence( datasNumbersToValidate, setMsgErrors );

    if( validateString && validateNumbers ){
      return true;
    }
    return false;
  }

  const handleThirdPartyChange = ( { target } ) => {
    const { name, value } = target;

    const thirdParty = thirdParties.filter(e => ( e.id === parseInt(value) ))[0];
    let flag = true;

    if (thirdParty.rfc.length >= 13) {
      flag = false;
    }
    
    setFormValues(prevState => {
      return{
        ...prevState,
        [name]: parseInt(value),
        thirdPartyMoral: flag,
        thirdParty: { ...thirdParty }
      }
    })
  }

  return (
    <div>
      <div className="text-center">
        {
          expenseEdit ? 
          ( <h4>Editar Egreso</h4> )
          :
          ( <h4>Agregar Nuevo Egreso</h4> )
        }
      </div>
      <hr/>
      
      {
        expenseEdit ? 
        (
           expenseEdit.satDownloaded ?
           (
            <ExpenseFormDownloadedSat
              accountIdentifiers={ accountIdentifiers }
              expenseTypes={ expenseTypes }
              formValuesExpense={ formValues }
              handleInputChangeExpense={ handleInputChange }
              handleCheckboxTrueFalseChange={ handleCheckboxTrueFalseChange }
              handleSubmit={handleSubmit}
              wayOfPayments={ wayOfPayments }
            />
           )
           :
           (
            <ExpenseFormNotDownloadedSat
              expenseTypes={ expenseTypes }
              formValues={ formValues }
              handleInputChange={ handleInputChange }
              handleThirdPartyChange={ handleThirdPartyChange }
              handleCheckboxTrueFalseChange={ handleCheckboxTrueFalseChange }
              handleSubmit={ handleSubmit }
              msgErrors={ msgErrors }
              accountIdentifiers={ accountIdentifiers }
              ivaTraslateds={ ivaTraslateds }
              thirdParties={ thirdPartiesProviders }
              wayOfPayments={ wayOfPayments }
            />
           )
        )
        :
        (
          <ExpenseFormNotDownloadedSat
            expenseTypes={ expenseTypes }
            formValues={ formValues }
            handleInputChange={ handleInputChange }
            handleThirdPartyChange={ handleThirdPartyChange }
            handleSubmit={ handleSubmit }
            msgErrors={ msgErrors }
            accountIdentifiers={ accountIdentifiers }
            ivaTraslateds={ ivaTraslateds }
            thirdParties={ thirdPartiesProviders }
            wayOfPayments={ wayOfPayments }
          />
        )
      }
    </div>
  )
}
