import { fetchWithApiKey } from "../../helpers/fetch";
import { types } from "../../types/types";

export const serviceTypesStartLoading = () => {
  return async(dispatch) => {
    const resp = await fetchWithApiKey('api/Catalogs/c_ServiceType');
    const body = await resp.json();
    if (body.success){
      dispatch( serviceTypeLoaded(body.data) )
    }
  }
}

const serviceTypeLoaded = (serviceTypes) => ({
  type: types.serviceTypeStartLoading,
  payload: serviceTypes
})
