
export const userAdministrators=(users)=>{
    const clasificate = []
    users.forEach(user => {
        if(user.roleDescription === 'Administration'){
            clasificate.push(user)
        }
    });
    return clasificate
}

export const userSellers=(users)=>{
    const clasificate = []
    users.forEach(user => {
        if(user.roleDescription === 'Seller'){
            clasificate.push(user)
        }
    });
    return clasificate
}

export const userAccounts=(users)=>{
    const clasificate = []
    users.forEach(user => {
        if(user.roleDescription === 'Accounter'){
            clasificate.push(user)
        }
    });
    return clasificate
}

export const userGuests=(users)=>{
    const clasificate = []
    users.forEach(user => {
        if(user.roleDescription === 'Guest'){
           
         const prepareData={
            ...user,
            id:user.id,
            fullName: `${user.fullName}`,
            email: user.email,
            register: user.createdAt,
            phoneMobile:user.phoneMobile,
            saleProduct:!!user.sales.length > 0 ? user.sales[0].product : 'Sin venta',
            saleSeller:!!user.sales.length > 0 ? user.sales[0].sellerName : 'Sin venta' ,
            saleActive:!!user.sales.length > 0 ? user.sales[0].activateAt : 'Sin venta'
            }

            if(user.profiles){
                prepareData['rfc'] = !!user.profiles.length > 0 ? user.profiles[0].rfc : 'Sin Rfc';
            }else{
                prepareData['rfc'] = user.rfc ?? 'Sin Rfc'
            }
            
            if(user.sales.length > 0){
                prepareData['saleAuthorized'] = user.sales[0].isAuthorized ? 'SI' : 'NO'
                if(user.package.length > 0){
                    prepareData['productIsAssisted'] = user.package[0].productAssisted ? 'SI' : 'NO'
                    if(!user.package[0].isActive){
                         prepareData['isFinish'] = 'Sin Activar'
                         prepareData['isActive'] = 'NO';
                    }else{
                        prepareData['isFinish'] = user.package[0].isFinished ? 'Terminado' : 'Activo'
                        prepareData['isActive'] = 'SI';
                    }
                }else{ 
                    if(!user.sales[0].isAuthorized){
                        prepareData['isActive']='Sin Autorizar'
                        prepareData['productIsAssisted'] ='Sin Autorizar'
                        prepareData['isFinish'] = 'Sin Autorizar'    
                    }
                }
            }else{
                prepareData['saleAuthorized'] =  'Sin venta' 
                prepareData['isActive'] = 'Sin Venta' ;
                prepareData['productIsAssisted'] = 'Sin Venta';
                prepareData['isFinish'] = 'Sin Venta'
            }
            // if(user.package.length > 0){
            //     prepareData['isActive'] = user.package[0].isActive ? 'SI' : 'NO';
            //     prepareData['productIsAssisted'] = user.package[0].productAssisted ? 'SI' : 'NO'
            //     prepareData['isFinish'] = user.package[0].isFinished ? 'Terminado' : 'Activo'
            // }else{
            //     if(user.sales.length == 0){
            //         prepareData['isActive'] = 'Sin Venta' ;
            //         prepareData['productIsAssisted'] = 'Sin Venta';
            //         prepareData['isFinish'] = 'Sin Venta'
            //     }else{
            //         prepareData['isActive'] =user.sales[0].isAuthorized ? 'SI' : 'Sin Autorizar'
            //         prepareData['productIsAssisted'] = user.sales[0].isAuthorized ? 'SI' : 'Sin Autorizar'
            //         prepareData['isFinish'] = user.sales[0].isAuthorized ? 'SI' : 'Sin Autorizar'
            //     }
            // }
            clasificate.push(prepareData)
        }
   
    });
    
    return clasificate
}
