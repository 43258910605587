import { types } from "../../types/types";

const initialState = {
  zoneTypes: []
};

export const zoneTypeReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.zoneTypeStartLoading:
      return {
        ...state,
        zoneTypes: [ ...action.payload ]
      }
  
    default:
      return state;
  }
}
