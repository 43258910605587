import React, { useEffect, useState, useContext } from 'react';
import moment from 'moment'
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@material-ui/core';
import { useSelector,useDispatch } from 'react-redux';
import { Pagination } from '../../../../../helpers/tables/Pagination'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ModalContext } from '../../../../modal/ModalContext';
import { startExpenseClassifications } from '../../../../../actions/catalogs/expenseClassifications';
import { FORM_EXPENSE_CLASSIFICATION, FORM_EXPENSE_CLASSIFICATION_UPDATE } from '../../../../constants/Routes';
import { Link } from 'react-router-dom';

const IndexExpenseClassification = () => {
    
    const {expenseClassifications} = useSelector(state => state.expenseClassifications)

    const dispatch=useDispatch();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);

    useEffect ( () =>{
        if(expenseClassifications.length === 0) {
            dispatch(startExpenseClassifications())
        }
    },[dispatch,expenseClassifications])


    return (
        <>
            <div className="col-12">
                <div className="card">
                    <div className="card-body">
                    <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <h5>Clasificaciones de Gastos</h5>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <Link to={FORM_EXPENSE_CLASSIFICATION} className="btn btn-primary btn-sm float-right">
                        <i className="fas fa-plus"></i> Crear Clasificación
                        </Link>
                        </div>
                    </div>
                    </div>
                </div>
             </div>
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2">
                <div className="card">
                    <div className="card-body">
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell aling="center">Clave</TableCell>
                                        <TableCell aling="center">Key</TableCell>
                                        <TableCell aling="center">Tipo de gasto</TableCell>
                                        <TableCell aling="center">Profesión</TableCell>
                                        <TableCell aling="center">Actividad</TableCell>
                                        <TableCell aling="center">Nota</TableCell>
                                        <TableCell aling="center">Regimenes</TableCell>
                                        <TableCell aling="center">Acciones</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                    expenseClassifications.length === 0 ?
                                    (<TableRow>
                                        <TableCell colSpan="10">
                                        <div className="alert alert-info text-center" role="alert">
                                            No hay clasificaciones de gastos
                                        </div>
                                        </TableCell>
                                    </TableRow>)
                                    :
                                    (
                                        expenseClassifications.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((expense, index) => {
                                        return(
                                            <TableRow key={index}>
                                            <TableCell>{expense.productKey}</TableCell>
                                            <TableCell align="center">
                                                {expense.expenseType.key} 
                                            </TableCell>
                                            <TableCell align="center">
                                                <strong>{expense.expenseType.name}</strong><br />
                                            </TableCell>
                                            <TableCell align="center">
                                                <strong>{expense.classificationConfigJson.profession.description}</strong><br />
                                            </TableCell>
                                            <TableCell align="center">
                                            { expense.classificationConfigJson.activity.description }
                                            </TableCell>
                                            <TableCell align="center">
                                                {expense.notes} 
                                            </TableCell>
                                            <TableCell align="center">
                                                {expense.classificationConfigJson.regimen.officialName} 
                                            </TableCell>
                                            <TableCell align="center">
                                                <Link to={FORM_EXPENSE_CLASSIFICATION_UPDATE(expense.productKey)} data-toggle="tooltip"
                                                    data-placement="top" 
                                                    title="Editar Clasificación"
                                                    className="btn btn-sm btn-outline-primary px-2">  
                                                  <FontAwesomeIcon className="fa-lg" icon={"pencil-alt"}/>
                                             </Link>
                                            </TableCell>
                                            </TableRow>
                                        )
                                        })
                                    )
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Pagination
                            data={expenseClassifications}
                            page={page}
                            rowsPerPage={rowsPerPage}
                            setPage={setPage}
                            setRowsPerPage={setRowsPerPage}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default IndexExpenseClassification;
