import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { prepareNewSales, prepareNewSalesNotActive, prepareNewSalesActive } from '../../../../../helpers/sales/prepareDataToSearch';
import { loadingModal } from '../../../../../helpers/UseSwal';
import { TableNewSale } from '../TableNewSale';

export const AdminNewSalesIndexScreen = ({setFormValueSalesIndex,formValues}) => {

  const {crmSales} = useSelector(state => state.crmSale);
  const { newUserActives, newUserNotActives} = formValues

  const [newSales, setNewSales] = useState([]);
  const [salesSearch, setSalesSearch] = useState([]);
  const [salesSearchActives, setSalesSearchActives] = useState([]);
  const [salesSearchNotActives, setSalesSearchNotActives] = useState([]);
  const [originalSalesSearchActives, setOriginalSalesSearchActives] = useState([]);
  const [originalSalesSearchNotActives, setOriginalSalesSearchNotActives] = useState([]);


  useEffect(() => {
    if (crmSales.length > 0) {
      setNewSales(prepareNewSales(crmSales, setFormValueSalesIndex));
      setSalesSearchNotActives(prepareNewSalesNotActive(crmSales, setFormValueSalesIndex))
      setSalesSearchActives(prepareNewSalesActive(crmSales, setFormValueSalesIndex))
      setOriginalSalesSearchNotActives(prepareNewSalesNotActive(crmSales, setFormValueSalesIndex))
      setOriginalSalesSearchActives(prepareNewSalesActive(crmSales, setFormValueSalesIndex))
      setSalesSearch(prepareNewSales(crmSales, setFormValueSalesIndex));
    }
    
      loadingModal(false)
  }, [crmSales, setFormValueSalesIndex]);



  return (
    <div className="row">
      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2">
        <div className="card">
          <div className="card-body">
            
            <div className="row justify-content-between align-items-center">
              <nav>
              <div className="nav  nav-pills" id="nav-tab" role="tablist">
                <a className="nav-item nav-link show active border m-1" id="nav-sales-tab" data-toggle="tab" href="#nav-sales" role="tab" aria-controls="nav-sales" aria-selected="true">
                  <strong >Todas - <span className="badge badge-info">{newUserNotActives + newUserActives}</span> </strong>
                </a>
                <a className="nav-item nav-link border m-1" id="nav-salesNotActives-tab" data-toggle="tab" href="#nav-salesNotActives" role="tab" aria-controls="nav-salesNotActives" aria-selected="true"> 
                  <strong >No Autorizadas - <span className="badge badge-danger">{newUserNotActives}</span></strong>
                </a>
                <a className="nav-item nav-link border m-1 boder" id="nav-salesActives-tab" data-toggle="tab" href="#nav-salesActives" role="tab" aria-controls="nav-salesActives" aria-selected="true">
                  <strong >Autorizadas - <span className="badge badge-success">{newUserActives}</span></strong>
                </a>
              </div>
            </nav>
            </div>
          </div>
        </div>
      </div>

      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2">
        <div className="card">
            <div className="tab-content" id="nav-tabContent">
              <div className="tab-pane fade show active " id="nav-sales" role="tabpanel" aria-labelledby="nav-sales-tab" aria-selected="true">      
                <TableNewSale 
                  salesSearch={salesSearch}
                  nameFile="Ventas" 
                  setSalesSearch={setSalesSearch}
                  originalSalesSearch={newSales}
                  />
              </div>
              <div className="tab-pane fade show" id="nav-salesNotActives" role="tabpanel" aria-labelledby="nav-salesNotActives-tab" aria-selected="true">      
                <TableNewSale 
                  salesSearch={salesSearchNotActives}
                  nameFile="Ventas No Autorizadas" 
                  setSalesSearch={setSalesSearchNotActives}
                  originalSalesSearch={originalSalesSearchNotActives}
                  />
              </div>
              <div className="tab-pane fade show" id="nav-salesActives" role="tabpanel" aria-labelledby="nav-salesActives-tab" aria-selected="true">      
                <TableNewSale 
                  salesSearch={salesSearchActives}
                  nameFile="Ventas Autorizadas" 
                  setSalesSearch={setSalesSearchActives}
                  originalSalesSearch={originalSalesSearchActives}
                  />
              </div>
            </div>
        </div>
      </div>
    </div>
  )
}
