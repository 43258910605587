import { types } from "../../types/types";

const initialState = {
    data: {
        headers: [],
        details: [],
        pagination: {},
        totals: {}
    },
};

export const preReportUpgrade = (state = initialState, action) => {
    switch (action.type) {
        case types.preReportUpgrade:
            return {
                ...state,
                data: {
                    ...action.payload
                }
            };

        default:
            return state;
    }
};


