import { types } from "../../types/types";

const initialState = {
  expenseClassifications: [],
  expenseClassificationSelect:null
}

export const expenseClassificationsReducer = ( state = initialState, action ) => {
  switch (action.type) {
    
    case types.expenseClassificationsStartLoading:
      return{
        ...state,
        expenseClassifications: action.payload 
      }
    case  types.expenseClassificationsSelect:
      return{
        ...state,
        expenseClassificationSelect: action.payload 
      }
    case types.expenseClassificationUpdate:
      return{
        ...state,
        expenseClassifications: state.expenseClassifications.map(
          e => ( e.productKey === action.payload.productKey ) ? action.payload : e
        ),
        expenseClassificationSelect: action.payload 
      }
    case types.expenseClassificationAdd:
      return{
        ...state,
        expenseClassifications:[action.payload,...state.expenseClassifications],
      }

    default:
      return state;
  }
}
