import { types } from "../../types/types";

const initialState = {
  expenses: [],
  finishExpenses:false,
  statusExpenseDownload:''
}

export const expenseReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.expenseStartRegister:
      return {
        ...state,
        expenses: [
          ...state.expenses,
          action.payload
        ]
      }

    case types.expenseStartUpdate:
      return {
        ...state,
        expenses: state.expenses.reduce((acc, current)=>{
          const aux=action.payload.find((item)=>item.id === current.id);
          acc.push(aux ? {...current, ...aux} : current);
          return acc;
        },[]  )
      }

    case types.expenseStartChangeAutorization:
      return {
        ...state,
        expenses: state.expenses.map(
          expense => (expense.id === action.payload.expenseId) ?
            {
              ...expense,
              authorization: [ ...action.payload.authorization ]
            }
            :
            expense
        )
      }

    case types.expenseStartLoading:
      return{
        ...state,
        expenses: [ ...action.payload ]
      }
    
      case types.expenseFinishLoading:
      return{
        ...state,
        finishExpenses:action.payload
      }

      case types.expenseFinishStatus:
        return{
          ...state,
          statusExpenseDownload:action.payload
        }

    default:
      return state;
  }
}
