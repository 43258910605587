import React, { useEffect, useState, useContext } from 'react';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import { useDispatch, useSelector } from 'react-redux';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { monitorDownload } from '../../../../actions/catalogs/accounters';
import { TableBody, TextField, Box } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { ModalContext } from '../../../modal/ModalContext';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { DETAILS_SUMMARY } from '../../../constants/Routes';
import { downloadSatStartLoading } from '../../../../actions/accounting/periods';
import Swal from 'sweetalert2';
import Tooltip from '@material-ui/core/Tooltip';

const months = {
    enero: 1,
    febrero: 2,
    marzo: 3,
    abril: 4,
    mayo: 5,
    junio: 6,
    julio: 7,
    agosto: 8,
    septiembre: 9,
    octubre: 10,
    noviembre: 11,
    diciembre: 12,
};

export const DownloadXml = () => {
    const [loading, setLoading] = useState(false);
    const [showComponent, setShowComponent] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const dispatch = useDispatch();
    const datos = useSelector(state => state.monitorDownloadData.data.data);
    const { handleModal } = useContext(ModalContext);
    const totalPages = 100;
    const pagesToShow = 4;
    const [search, setSearch] = useState("");
    const [search2, setSearch2] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    let result = []

    const handleSearchChange = (event) => {
        setSearch2(event.target.value);
    };

    const handleSearchClick = () => {
        setSearchTerm(search2);
    };

    useEffect(() => {
        if (searchTerm !== '') {
            dispatch(monitorDownload(null, searchTerm));
        }
    }, [dispatch, searchTerm, pageNumber]);




    // De aqui
    const handlePreviousPage = () => {
        if (pageNumber > 1) {
            setPageNumber(pageNumber - 1);
        }
    };

    const handleNextPage = () => {
        if (pageNumber < totalPages - pagesToShow + 1) {
            setPageNumber(pageNumber + 1);
        }
    };

    const handlePageChange = (page) => {
        setPageNumber(page);
    };

    const startPage = pageNumber;
    const endPage = Math.min(startPage + pagesToShow - 1, totalPages);
    // hasta aqui es para la paginación

    useEffect(() => {
        dispatch(monitorDownload(pageNumber, null));
    }, [dispatch, pageNumber]);

    const parsePeriod = (period) => {
        const [monthName, year] = period.split(' - ');
        const month = months[monthName.toLowerCase()];
        return { year: parseInt(year), month };
    };

    const searcher = (e) => {
        setSearch(e.target.value)
    }

    if (!search) {
        result = datos
    } else {
        result = datos.filter((dato) =>
            dato.rfc.toLowerCase().includes(search.toLocaleLowerCase())
        )
    }


    const descargaXML = (mes, taxpayerId, year) => {
        Swal.fire({
            title: "Este proceso descargará todos los XML (Ingresos y gastos) del contribuyente, que estén comprendidos dentro del periodo del reporte.",
            text: `Este proceso podría tardar algún tiempo dependiendo de la cantidad de documentos a descargar. ¿Deseas continuar?`,
            showCancelButton: true,
            confirmButtonText: 'Sí',
            cancelButtonText: 'No',
        }).then((result) => {
            setLoading(false);
            if (result.value) {
                setShowComponent(true);
                setLoading(true);
                dispatch(downloadSatStartLoading({ month: mes, profileId: taxpayerId, year: year, requestType: '1', captcha: 'GNJHKJ' }, handleModal, "Verificando sesión"))
            }
        });
    }




    return (
        <div style={{ margin: '10px', overflow: 'hidden' }}>
            <Box display="flex" alignItems="center" justifyContent='space-between' marginBottom="10px" style={{ width: '100%' }}>
                <Tooltip title="Solo buscará usuarios en la pagina actual">
                    <TextField
                        onChange={searcher}
                        value={search}
                        variant="outlined"
                        placeholder="Buscar usuario en la página actual"
                        style={{ marginRight: '10px', width: '40%' }}
                    />
                </Tooltip>
                <Tooltip title="Buscar usuario en todos los registros existentes">
                    <TextField
                        onChange={handleSearchChange}
                        value={search2}
                        variant="outlined"
                        placeholder="Buscar usuario general"
                        style={{ marginRight: '10px', width: '40%' }}
                    />
                </Tooltip>
                <Button
                    onClick={handleSearchClick}
                    variant="contained"
                    color="primary"
                    style={{ borderRadius: '20px', marginRight: '3px' }}
                >
                    Buscar
                </Button>
                <nav aria-label="Page navigation example">
                    <ul className="pagination justify-content-end" style={{ margin: 0 }}>
                        <li className={`page-item ${pageNumber === 1 ? 'disabled' : ''}`}>
                            <a className="page-link" onClick={handlePreviousPage}>Anterior</a>
                        </li>
                        {[...Array(endPage - startPage + 1)].map((_, i) => (
                            <li className={`page-item ${pageNumber === startPage + i ? 'active' : ''}`} key={i}>
                                <a className="page-link" onClick={() => handlePageChange(startPage + i)}>{startPage + i}</a>
                            </li>
                        ))}
                        <li className={`page-item ${pageNumber >= totalPages - pagesToShow + 1 ? 'disabled' : ''}`}>
                            <a className="page-link" onClick={handleNextPage}>Siguiente</a>
                        </li>
                    </ul>
                </nav>
            </Box>
            <TableContainer style={{ borderRadius: '10px', maxHeight: '780px' }}>
                <Table className="table table-responsive" size="small" aria-label="sticky table" stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell colSpan={12} align="center">
                                <h2 style={{ whiteSpace: 'nowrap' }}>Descarga de xml</h2>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableHead>
                        <TableRow>
                            <TableCell class="font-weight-bold" style={{ textAlign: 'center' }}>Fecha de solicitud</TableCell>
                            <TableCell class="font-weight-bold" style={{ textAlign: 'center' }}>Cliente</TableCell>
                            <TableCell class="font-weight-bold" style={{ textAlign: 'center' }}>Período</TableCell>
                            <TableCell class="font-weight-bold" style={{ textAlign: 'center' }}>Régimen&nbsp;(es)</TableCell>
                            <TableCell class="font-weight-bold" style={{ textAlign: 'center' }}>Plan</TableCell>
                            <TableCell class="font-weight-bold" style={{ textAlign: 'center' }}>Tipo de doc.</TableCell>
                            <TableCell class="font-weight-bold" style={{ textAlign: 'center' }}>Descarga</TableCell>
                            <TableCell class="font-weight-bold" style={{ textAlign: 'center' }}>Clasificación</TableCell>
                            <TableCell class="font-weight-bold" style={{ textAlign: 'center' }}>Cálculo</TableCell>
                            <TableCell class="font-weight-bold" style={{ textAlign: 'center' }}>Ultima actualización</TableCell>
                            <TableCell class="font-weight-bold" style={{ textAlign: 'center' }}>Reporte</TableCell>
                            <TableCell class="font-weight-bold" style={{ textAlign: 'center' }}>Acciones</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            datos == null || result == [] ?
                                (<TableRow>
                                    <TableCell colSpan="10">
                                        <div className="alert alert-info text-center" role="alert">
                                            No hay contribuyentes con dichos filtros
                                        </div>
                                    </TableCell>
                                </TableRow>)
                                :
                                (
                                    result.map((report, index) => {
                                        const { year, month } = parsePeriod(report.period);
                                        return (
                                            <TableRow key={index}>
                                                <TableCell component="th" scope="row" style={{ color: 'black', textAlign: 'center' }}>{report.createAt}</TableCell>
                                                <TableCell component="th" scope="row" style={{ color: 'black', textAlign: 'center' }}>{report.rfc}</TableCell>
                                                <TableCell component="th" scope="row" style={{ color: 'black', textAlign: 'center' }}>{report.period}</TableCell>
                                                <TableCell component="th" scope="row" style={{ color: 'black', textAlign: 'center' }}>{report.taxRegime}</TableCell>
                                                <TableCell component="th" scope="row" style={{ color: 'black', textAlign: 'center' }}>{report.planType}</TableCell>
                                                <TableCell component="th" scope="row" style={{ color: 'black', textAlign: 'center' }}>{report.documentType}</TableCell>
                                                <TableCell component="th" scope="row" style={{ color: 'black', textAlign: 'center' }}>{report.statusDownload}</TableCell>
                                                <TableCell component="th" scope="row" style={{ color: 'black', textAlign: 'center' }}>{report.statusClassification}</TableCell>
                                                <TableCell component="th" scope="row" style={{ color: 'black', textAlign: 'center' }}>{report.statusCalculation}</TableCell>
                                                <TableCell component="th" scope="row" style={{ color: 'black', textAlign: 'center' }}>{report.updateAt}</TableCell>
                                                <TableCell component="th" scope="row" style={{ color: 'black', textAlign: 'center' }}>{report.statusReport}</TableCell>
                                                <TableCell align="center">
                                                    <Button variant='contained' title="Descarga XML" style={{ marginBottom: '4px', backgroundColor: '#00AD87', color: '#221158' }}
                                                        onClick={() =>
                                                            descargaXML(month, report.profileId, year, "1")
                                                        }
                                                    >
                                                        Descarga de XML
                                                    </Button>
                                                    <Button variant='contained' color='primary' style={{ marginTop: '2px' }}>
                                                        <Link to={DETAILS_SUMMARY(report.profileId, report.satCode, year, month, true)} style={{ color: 'white', textDecoration: 'none' }}>
                                                            Ir a Reporte
                                                        </Link>
                                                    </Button>
                                                </TableCell>
                                            </TableRow>
                                        )
                                    }
                                    )
                                )}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};
