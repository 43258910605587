import React, { useState } from 'react';
import { useDispatch } from "react-redux";
import { TextField } from '@material-ui/core';
import { useForm } from '../../../../hooks/useForm';
import { sendMailRecoveryPassword } from '../../../../actions/auth';
import { NavLink } from 'react-router-dom';
import * as ROUTES from '../../../constants/Routes';
import validator from 'validator';
import '../Style.scss';

export const RecuperatePassword = () => {

  const dispatch = useDispatch();
  const { formValues, handleInputChange } = useForm({
    email: ''
  });

  const { email } = formValues;

  const [msgErrors, setMsgErrors] = useState({});

  const handleSubmit = (e) => {
    e.preventDefault();

    if (isFormValid()) {
      dispatch(sendMailRecoveryPassword(formValues))
    }
  }

  const isFormValid = () => {
    const errors = {};

    if (!validator.isEmail(email)) {
      errors['msgEmail'] = 'Correo electrónico no es valido.';
    }

    setMsgErrors(errors);

    if (Object.keys(errors).length !== 0) {
      return false;
    }

    return true;
  }

  return (
    <div className="container">
      <div className="col-12 d-flex justify-content-center mt-2 animate__animated animate__fadeIn">
        <div className="card col-md-8 shadow">
          <div className="card-body">
            <div className="text-center pb-1">
              <h3>Recuperar Contraseña</h3>
            </div>

            <form onSubmit={handleSubmit}>

              <div className="col-md-12 mb-3">
                <TextField
                  label="Correo Electrónico"
                  id="email"
                  variant="outlined"
                  size="small"
                  name="email"
                  value={email}
                  onChange={handleInputChange}
                  autoComplete="off"
                  fullWidth={true}
                  helperText={msgErrors.msgEmail}
                  error={msgErrors.msgEmail !== undefined}
                />
              </div>

              <div className="col-md-12 text-right mb-3">
                <button className="btn btn-primary">
                  <i className="far fa-paper-plane"></i> Enviar correo
                </button>
              </div>

            </form>

            <div className="row col-md-12">
              <div className="col-md-6 text-center">
                <strong>¿Ya eres usuario?</strong>
                <p>
                  <NavLink to={ROUTES.LOGIN}>
                    Haz click aqui para iniciar sesión.
                  </NavLink>
                </p>
              </div>
              <div className="col-md-6 text-center">
                <strong>¿Eres un nuevo usuario?</strong>
                <p>
                  <NavLink to={ROUTES.REGISTER2}>
                    Haz click aqui para registrarte.
                  </NavLink>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
