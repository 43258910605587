// errorActions.js

export const handleErrorRegister = (errorType) => {
    switch (errorType) {
        case 1:
            return {
                type: 'EMAIL_ERROR',
                message: 'El Email ya tiene una venta asignada, favor de contactarse con nuestro asesor, dando clic en "OK" será redirigido a nuestro chat de atención al cliente'
            };
        case 2:
            return {
                type: 'CIEC_ERROR',
                message: 'Clave CIEC o RFC erroneos, favor de verificar.'
            };
        case 3:
            return {
                type: 'RFC_INVALID_ERROR',
                message: 'El RFC no cumple con los caracteres reglamentarios.'
            };
        case 4:
            return {
                type: 'RFC_ERROR',
                message: 'El RFC ya tiene una preventa asignada, favor de contactarse con nuestro asesor, dando clic en "OK" será redirigido a nuestro chat de atención al cliente'
            };
        case 5:
            return {
                type: 'RFC_ERROR',
                message: 'El RFC ya tiene una venta asignada, favor de contactarse con nuestro asesor, dando clic en "OK" será redirigido a nuestro chat de atención al cliente'
            };
    }
};

export const getErrorType = (error) => {
    if (error.includes('El Email ya tiene una preventa asignada. ya existe. Revise por favor.')) {
        return 1;
    } else if (error.includes('Ha ocurrido un error al iniciar sesión. Intente nuevamente')) {
        return 2;
    } else if (error.includes('El Rfc tiene un formato inválido.')) {
        return 3;
    } else if (error.includes('El RFC ya tiene una preventa asignada. ya existe. Revise por favor.')) {
        return 4;
    } else if (error.includes('El RFC ya tiene una venta asignada. ya existe. Revise por favor.')) {
        return 5;
    }
};
