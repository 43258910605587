import { types } from "../types/types";
const initialState = {
  checking: true,
  users:[],
  user: null
};

export const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.authLogin:
      return {
        ...state,
        checking: false,
        user: action.payload
      }

    case types.authRegister:
      return{
        ...state,
        checking: false,
        user: action.payload
      }

    case types.authCheckingFinish:
      return {
        ...state,
        checking:false
      }
    
    case types.authLogout:
      return {
        checking: false,
        users:[],
        user: null
      }

    default:
      return state
  }
}