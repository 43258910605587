import { TextField } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useForm } from '../../../../../../../../../hooks/useForm';
import NumberFormat from 'react-number-format';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { complementsAddExpense } from '../../../../../../../../../actions/accounting/complement';

const AuthorizaedDeductionsComplement = ({ complementAutorized, authorizations, monthComplement, yearComplement }) => {

  const deductionComplement = authorizations.complementsExpenses || [];
  // //console.log('authorizations-DETAILS',authorizations)
  // //console.log('complementAutorized-DETAILS',complementAutorized)
  return (
    <div className="row">
      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2">
        <div className="card">
          <div className="card-header">
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <h5>Deduccion Autorizada</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2">
        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr className="text-center">
                <th>Proveedor</th>
                <th>N. Complementos</th>
                <th>Clasificación</th>
                <th>Subtotal</th>
                <th>IVA</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              {
                deductionComplement.map((complement, index) => {
                  const complementsDetails = complement.details || []
                  const complementDetail = complementsDetails.find(detail => {
                    const stampAt = new Date(detail.paymentAt)
                    const yearDetail = stampAt.getFullYear()
                    const monthDetail = stampAt.getMonth() + 1
                    return yearDetail == yearComplement && monthDetail == monthComplement
                  })
                  const detailByCalculate = complementsDetails.filter(detail => detail.byCalculate)

                  const isAuthorized = complement?.authorizationFull
                  if (!isAuthorized) {
                    return null
                  }

                  return (
                    <tr key={index} className="text-center" >
                      <td> {complement.IssuedRfc} </td>
                      <td> {detailByCalculate?.length} / {complementsDetails?.length} </td>
                      <td> {complement.descripcion}</td>

                      <td><NumberFormat value={parseFloat(complement.paymentComplementSubtotal).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td>
                      <td><NumberFormat value={parseFloat(complement.paymentComplementIva).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td>
                      <td><NumberFormat value={parseFloat(complement.paymentComplementAmount).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td>
                    </tr>
                  )
                })
              }
              <tr className={"text-center text-info"}>
                <td colSpan="3" className="text-right"><strong>Totales</strong></td>
                <td><NumberFormat value={parseFloat(authorizations.paymentComplementExpensesSubtotal).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td>
                <td><NumberFormat value={parseFloat(authorizations.paymentComplementExpensesIva).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td>
                <td><NumberFormat value={parseFloat(authorizations.paymentComplementExpensesAmount).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default AuthorizaedDeductionsComplement;
