import { TextField } from '@material-ui/core';
import React, { useContext, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from '../../../../../../../hooks/useForm';
import { ModalContext } from '../../../../../../modal/ModalContext';
import { updateStatementsBalanceRequest } from '../../../../../../../actions/accounting/preAuthorizedReports';

export const ChangePackageToPresentModal = ({profileId, regimeId, assistedFinish,taxpayer}) => {

  const dispatch = useDispatch();
  
  const { handleModal } = useContext(ModalContext)

  const { formValues, handleInputChange } = useForm({
    profileId: profileId,
    regimeId: regimeId,
    increase: 0
  })

  const {increase} = formValues;

  const handleSubmit = (e) => {
    e.preventDefault();  
            dispatch(updateStatementsBalanceRequest({
                ...formValues
            }, handleModal.bind(),assistedFinish,taxpayer))
  }

  return (
    <div style={{width: "300px"}}>
      <h4 className="text-center">Cambiar Paquetes Por Presentar</h4>
      <hr />
      <form onSubmit={handleSubmit}>
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3">
              <TextField
                label="Declaraciones a presentar" 
                variant="outlined"
                size="small"
                name="increase"
                value={ increase }
                onChange={ handleInputChange }
                autoComplete="off"
                fullWidth={true}
              />
          </div>
        <div className="col-md-12 ">
            <button className="btn btn-primary">
                Guardar
            </button>
        </div>
      </form>

    </div>
  )
}