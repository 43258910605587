import React from 'react';
import { NavLink } from 'react-router-dom';
import * as ROUTES from '../constants/Routes';
import './Style.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Navbar = () => {
  return (
    <div>
      <nav className="navbar navbar-expand-lg navbar-dark bg-dark-contabox-landing animate__animated animate__fadeIn">
        <div className="collapse navbar-collapse" id="navbarContent">
          <ul className="navbar-nav mr-auto">
            <li className="nav-item active">
              <p className="text-white pt-1 mb-0">
                <span className="font-weight-bold">VENTAS:</span>
                <a className="a-subrayado" href="https://wa.me/522211934646" target="_blank" rel="noopener noreferrer"> 221 193 4646</a>,{'    '}
                <a className="a-subrayado" href="https://wa.me/message/YP7STLVO2WT7M1" target="_blank" rel="noopener noreferrer">221 588 1830</a>,{'    '}
                55 416 125 69
                <span className="font-weight-bold" style={{ marginLeft: "20px" }}>|A.CLIENTE: </span>
                222 240 1091,{'    '}
                222 431 4422,{'    '}
                222 431 4423
                Y
                <a className="a-subrayado" href="https://wa.me/message/H6URMEGPZRWUM1" target="_blank" rel="noopener noreferrer"> 221 108 1900</a>

              </p>
            </li>
          </ul>
          <ul className="navbar-nav align-right">
            <li className="contabox-login-register">
              <NavLink to={ROUTES.LOGIN} className="nav-link text-white">
                <FontAwesomeIcon className="fas" icon={"lock"} /> &nbsp;
                Iniciar sesión
              </NavLink>
            </li>
            <li className="contabox-login-register">
              <NavLink to={ROUTES.REGISTER} className="nav-link text-white">
                <FontAwesomeIcon className="fas" icon={"user"} /> &nbsp;
                Registro
              </NavLink>
            </li>
          </ul>
        </div>
      </nav>


      <nav className="navbar navbar-expand-lg navbar-light bg-light-contabox-landing shadow p-3 mb-5 bg-body rounded animate__animated animate__fadeIn">
        <button className="navbar-toggler" type="button" data-toggle="collapse"
          data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
          aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <p className="contabox navbar-toggler">
          CONTABOX
        </p>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav text-header-landing">
            <li className="nav-item">
              <a className="a-landing" href="/#">Contabilidad En Línea</a>
            </li>
            <li className="nav-item">
              <a className="a-landing" href="/#">Facturación Electrónica</a>
            </li>
            <li className="nav-item">
              <a className="a-landing" href="/#">Centro De Ayuda</a>
            </li>
            <li className="nav-item">
              <a className="a-landing" href="/#">Aliados</a>
            </li>
            <li className="nav-item">
              <a className="a-landing" href="/#">Contacto</a>
            </li>
            {/* <li className="nav-item">
              < NavLink to={ROUTES.PRODUCTS} className="a-landing ">
                Productos
                </NavLink>
              </li> */}
            <li className="nav-item" >
              <a className="a-landing" href="CALCULOANUAL.xlsx" download="Cálculo_Anual">
                <i class="fas fa-file-excel"></i> Cálculo Anual
              </a>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;