import React, { useEffect } from 'react';
import { TextField } from '@material-ui/core';
import { useForm } from '../../../../../../../../../hooks/useForm';
import { useDispatch, useSelector } from 'react-redux';
import { serviceTypeRetentionsStart } from '../../../../../../../../../actions/accounting/serviceTypeRetentions';
import NumberFormat from 'react-number-format';
import { complementsAddIncome } from '../../../../../../../../../actions/accounting/complement';

const ConsideredIncome = ({ autorizedReport = false, consideredIncomes, setFormValuesDetailsSummary}) => {

  const dispatch = useDispatch();

  const { serviceTypeRetentions } = useSelector(state => state.ServiceTypeRetention)

  const { formValues, setFormValues } = useForm({
    isrRetaineds: 0.00,
    ivaRetaineds: 0.00,
    ivaTranslateds: 0.00,
    subtotal: 0.00,
    total: 0.00,
    totalIVATraslateds: {},
    totalISRRetaineds: {},
    totalIVARetaineds: {},
  });

  const { isrRetaineds, ivaRetaineds, ivaTranslateds, subtotal, total, totalISRRetaineds, totalIVARetaineds, totalIVATraslateds,  } = formValues;

  useEffect(() => {
    if(serviceTypeRetentions.length === 0){
      dispatch(serviceTypeRetentionsStart());
    }
  }, [dispatch, serviceTypeRetentions])

  useEffect(() => {
    let totalSum = 0.00;
    let totalSumGeneralPublic = 0.00;
    let totalSumRate0 = 0.00;
    let totalSumRate16 = 0.00;
    let subtotalSumRateExento = 0.00;
    let totalTaxesRate16 = 0.00;
    let subtotalSum = 0.00;
    let subtotalSumGeneralPublic = 0.00;
    let subtotalSumRate0 = 0.00;
    let subtotalSumRate16 = 0.00;
    let ivaTrasSum = 0.00;
    let ivaRetSum = 0.00;
    let isrRetSum = 0.00;
    let complements = []

    let serviceGroundIncomes = {
      subtotalServiceDealerClasified: 0.00,
      subtotalServicePassengersClasified: 0.00
    };

    let serviceLodgingIncomes = {
      subtotalServiceLodgingClasified: 0.00
    }

    let serviceAlienationIncomes ={
      subtotalServiceAlineationClasified: 0.00,
      subtotalServiceLendingClasified: 0.00
    }

    if (consideredIncomes.length > 0) {
      consideredIncomes.forEach((income, i) => {

        if(income.paymentMethod.key === 'PPD' && income.wayOfPayment.key === 99){
          //se agrega la condicion de ppd y por definir se meterian esas 2 lineas en un if...
          //Nota al parecer si cumplen esa condicion pasaria a la siguiente posicion para que no altere las otras variables.... 
          
          complements.push(income)
          return false;
        }

        totalSum = (parseFloat(totalSum) + parseFloat(income.total)).toFixed(2);
        subtotalSum = (parseFloat(subtotalSum) + parseFloat(income.subTotal)).toFixed(2);

        if(serviceTypeRetentions.length > 0){
          const retentionType = serviceTypeRetentions.filter(e => (e.id === parseInt(income.retentionTypeId)))[0];
          
          if (retentionType.key === '01') {
            serviceGroundIncomes.subtotalServicePassengersClasified = (
              parseFloat(serviceGroundIncomes.subtotalServicePassengersClasified) +
              parseFloat(income.subTotal)
            ).toFixed(2);
          }
          if (retentionType.key === '02' || retentionType.key === '03') {
            serviceGroundIncomes.subtotalServiceDealerClasified = (
              parseFloat(serviceGroundIncomes.subtotalServiceDealerClasified) +
              parseFloat(income.subTotal)
            ).toFixed(2);
          }
          if (retentionType.key === '04') {
            serviceLodgingIncomes.subtotalServiceLodgingClasified = (
              parseFloat(serviceLodgingIncomes.subtotalServiceLodgingClasified) +
              parseFloat(income.subTotal)
            ).toFixed(2);
          }
          if (retentionType.key === '05') {
            serviceAlienationIncomes.subtotalServiceAlineationClasified = (
              parseFloat(serviceAlienationIncomes.subtotalServiceAlineationClasified) +
              parseFloat(income.subTotal)
            ).toFixed(2);
          }
          if (retentionType.key === '06') {
            serviceAlienationIncomes.subtotalServiceLendingClasified = (
              parseFloat(serviceAlienationIncomes.subtotalServiceLendingClasified) +
              parseFloat(income.subTotal)
            ).toFixed(2);
          }
        }

        if (income.receivedRfc === 'XEXX010101000' ||  income.receivedRfc === 'XAXX010101000' ) {          
          subtotalSumRateExento = (parseFloat(subtotalSumRateExento) + parseFloat(income.total)).toFixed(6);
        }
        let totalIvaTras = 0.00;
        let totalIsrRet = 0.00;
        let totalIvaRet = 0.00;

        income.concepts.forEach((concept) => {
          if (concept.impuestos) {
            if (concept.impuestos.traslados == null && income.receivedRfc !== 'XEXX010101000' && income.receivedRfc !== 'XAXX010101000') {
              // totalSumRate0 = (parseFloat(totalSumRate0) + parseFloat(income.total)).toFixed(2);
              // subtotalSumRate0 = (parseFloat(subtotalSumRate0) + parseFloat(income.subTotal)).toFixed(2);
              totalSumRate0 = concept.impuestos.retenciones
              for (let i = 0; i < totalSumRate0.length; i++) {
                const valueToAdd = totalSumRate0[i].base;
                subtotalSumRate0 = (parseFloat(valueToAdd) + parseFloat(subtotalSumRate0)).toFixed(2);
              }
            }
            if (concept.impuestos.traslados == null && income.receivedRfc === 'XAXX010101000') {
              totalSumGeneralPublic = (parseFloat(totalSumGeneralPublic) + parseFloat(income.total)).toFixed(2);
              subtotalSumGeneralPublic = (parseFloat(subtotalSumGeneralPublic) + parseFloat(income.subTotal)).toFixed(2);
            }
            if(concept.impuestos.traslados){
              concept.impuestos.traslados.forEach(taxe => {
                if (taxe.impuesto === '002') {//se agrega la condicion de ppd y por definir
                  if((taxe.tasaOCuota === 0.16 || taxe.tasaOCuota === 0.08) && income.receivedRfc !== 'XEXX010101000' ) {
                    if (!income.satDownloaded) {
                      subtotalSumRate16 = (parseFloat(subtotalSumRate16) + parseFloat(income.subTotal)).toFixed(2);
                    }else{
                      subtotalSumRate16 = (parseFloat(subtotalSumRate16) + parseFloat(taxe.base)).toFixed(2);
                    }
                    totalSumRate16 = (parseFloat(totalSumRate16) + parseFloat(income.total)).toFixed(2);
                    totalTaxesRate16 = (parseFloat(totalTaxesRate16) + parseFloat(taxe.importe)).toFixed(2);
                  }
                  if(parseFloat(taxe.tasaOCuota) === 0 && income.receivedRfc !== 'XEXX010101000') {
                    totalSumRate0 = (parseFloat(totalSumRate0) + parseFloat(income.total)).toFixed(2);
                    if (!income.satDownloaded) {
                      subtotalSumRate0 = (parseFloat(subtotalSumRate0) + parseFloat(income.subTotal)).toFixed(2);
                    }else{
                      subtotalSumRate0 = (parseFloat(subtotalSumRate0) + parseFloat(taxe.base)).toFixed(2);
                    }
                  }
                  if(taxe.tipoFactor === 'Exento') {
                    subtotalSumRateExento = (parseFloat(subtotalSumRateExento) + parseFloat(taxe.base)).toFixed(2);
                 }
                 if( taxe.tipoFactor === 'Exento' ) {
                  totalIvaTras = 0.0
                 } else {
                   totalIvaTras = (parseFloat(totalIvaTras) + parseFloat(taxe.importe)).toFixed(2);
                 }
                }
              })
            }
  
            if(concept.impuestos.retenciones){
              concept.impuestos.retenciones.forEach((taxe) => {
                if (taxe.impuesto === '002') { //se agrega la condicion de ppd y por definir
                  totalIvaRet = (parseFloat(totalIvaRet) + parseFloat(taxe.importe)).toFixed(2);
                }
                if (taxe.impuesto === '001') {
                  totalIsrRet = (parseFloat(totalIsrRet) + parseFloat(taxe.importe)).toFixed(2);
                }
              })
            }
          }
          else{
              totalSumGeneralPublic = (parseFloat(totalSumGeneralPublic) + parseFloat(concept.importe)).toFixed(2);
              subtotalSumGeneralPublic = (parseFloat(subtotalSumGeneralPublic) + parseFloat(concept.importe)).toFixed(2);
           }
        });

        ivaTrasSum = (parseFloat(ivaTrasSum) + parseFloat(totalIvaTras)).toFixed(2);
        ivaRetSum = (parseFloat(ivaRetSum) + parseFloat(totalIvaRet)).toFixed(2);
        isrRetSum = (parseFloat(isrRetSum) + parseFloat(totalIsrRet)).toFixed(2);

        setFormValues(prevState => {
          return{
            ...prevState,
            totalIVATraslateds: {
              ...prevState.totalIVATraslateds,
              [i]: totalIvaTras
            },
            totalISRRetaineds: {
              ...prevState.totalISRRetaineds,
              [i]: totalIsrRet
            },
            totalIVARetaineds: {
              ...prevState.totalIVARetaineds,
              [i]: totalIvaRet
            }
          }
        })
      });
       dispatch(complementsAddIncome(complements))
      setFormValues(prevState => {
        return{
          ...prevState,
          isrRetaineds: isrRetSum,
          ivaRetaineds: ivaRetSum,
          ivaTranslateds: ivaTrasSum,
          subtotal: subtotalSum,
          total: totalSum
        }
      });
    }else{
      setFormValues(prevState => {
        return{
          ...prevState,
          isrRetaineds: 0.00,
          ivaRetaineds: 0.00,
          ivaTranslateds: 0.00,
          subtotal: 0.00,
          total: 0.00
        }
      })
    }
    setFormValuesDetailsSummary(prevState => {
      return{
        ...prevState,
        subtotalIncomesAutorized: subtotalSum,
        subtotalIncomesGeneralPublic: subtotalSumGeneralPublic,
        subtotalIncomesRate0: subtotalSumRate0,
        subtotalIncomesRate16: subtotalSumRate16,
        subtotalSumRateExento: subtotalSumRateExento,
        totalIncomesAutorized: totalSum,
        totalIncomesGeneralPublic: totalSumGeneralPublic,
        totalIncomesRate0: totalSumRate0,
        totalIncomesRate16: totalSumRate16,
        totalTaxesRate16: totalTaxesRate16,
        totalISRIncomeAutorized: isrRetSum,
        totalIVARetainedIncomeAutorized: ivaRetSum,
        totalIVATraslatedIncomeAutorized: ivaTrasSum,
        taxISRPlataformsTechnologies:{
          ...prevState.taxISRPlataformsTechnologies,
          serviceAlienation:{
            ...prevState.taxISRPlataformsTechnologies.serviceAlienation,
            ...serviceAlienationIncomes
          },
          serviceGround:{
            ...prevState.taxISRPlataformsTechnologies.serviceGround,
            ...serviceGroundIncomes
          },
          serviceLodging:{
            ...prevState.taxISRPlataformsTechnologies.serviceLodging,
            ...serviceLodgingIncomes
          }
        }
      }
    })
  }, [autorizedReport, consideredIncomes, setFormValues, setFormValuesDetailsSummary, serviceTypeRetentions])

  return (
    <div className="row">
      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2">
        <div className="card">
          <div className="card-header">
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <h5>Ingresos Considerados</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2">
        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr className="text-center">
                <th>Fecha de cobro</th>
                <th>Cliente</th>
                <th>Concepto</th>
                <th>Folio</th>
                <th>Subtotal</th>
                <th>IVA Trasladado</th>
                <th>IVA Retenido</th>
                <th>ISR Retenido</th>
                <th>Total recibido</th>
              </tr>
            </thead>
            <tbody>
              {
                consideredIncomes.map((considered, index) => {
                  return (
                    <tr key={ index } className={`text-center ${considered.paymentMethod.key === 'PPD' && "text-info"}`}>
                      <td> { considered.paymentAt } </td>
                      <td>
                        {considered.receivedRfc}
                        <br />
                        {considered.receivedFullName}
                      </td>
                      <td>
                        <TextField
                          label="Concepto" variant="outlined" size="small" autoComplete="off" fullWidth={ true }
                          select
                          SelectProps={{
                            native: true,
                          }}
                          readOnly
                        >
                          {
                            considered.concepts.map((concept, i) => {
                              return (
                                <option
                                  key={ i }
                                >
                                  { concept.claveProdServ ? `${ concept.claveProdServ } - ` : '' } 
                                  { concept.descripcion }
                                </option>
                              )
                            })
                          }
                        </TextField>
                      </td>
                      <td>{ considered.folio }</td>
                      <td><NumberFormat value={parseFloat(considered.subTotal).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td>
                      <td><NumberFormat value={parseFloat(totalIVATraslateds[index]).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td>
                      <td><NumberFormat value={parseFloat(totalIVARetaineds[index]).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td>
                      <td><NumberFormat value={parseFloat(totalISRRetaineds[index]).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td>
                      <td><NumberFormat value={parseFloat(considered.total).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td>
                    </tr>
                  )
                })
              }
              <tr className="text-center">
                <td colSpan="4" className="text-right"><strong>Totales</strong></td>
                <td><NumberFormat value={parseFloat(subtotal).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td>
                <td><NumberFormat value={parseFloat(ivaTranslateds).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td>
                <td><NumberFormat value={parseFloat(ivaRetaineds).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td>
                <td><NumberFormat value={parseFloat(isrRetaineds).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td>
                <td><NumberFormat value={parseFloat(total).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default ConsideredIncome;
