import { Checkbox, FormControl, InputLabel, ListItemText, MenuItem, Select } from '@material-ui/core';
import React, { useContext, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { regimesStartLoading } from '../../../../../../../actions/catalogs/regimes';
import { useForm } from '../../../../../../../hooks/useForm';
import { startUpdateTaxpayer } from '../../../../../../../actions/taxpayers';
import { ModalContext } from '../../../../../../modal/ModalContext';

export const ChangeRegimenModal = ({taxpayer, year,month}) => {
  const { handleModal } = useContext(ModalContext)

  const dispatch = useDispatch();

  const { regimes } = useSelector(state => state.regime);

  const {formValues, setFormValues} = useForm({
    profileData:{
      regimes: []
    }
  });

  const { profileData } = formValues 

  useEffect(() => {
    if (regimes.length <= 0){
      dispatch( regimesStartLoading() )
    }
  }, [dispatch, regimes]);

  useEffect(() => {
    
    if (taxpayer.regimes.length > 0) {
      taxpayer.regimes.forEach(regime => {
        setFormValues(prevState => {
          return {
            ...prevState,
            profileData:{
              ...prevState.profileData,
              regimes: [
                ...prevState.profileData.regimes,
                regime.id,
              ]
            }
  
          }
        })
      })
    }
  }, [taxpayer])

  const handleChangeSelectMultiple = (e) => {
    const { value } = e.target;
    setFormValues(prevState => {
      return{
        ...prevState,
        profileData: {
          ...prevState.profileData,
          regimes: [
            ...value
          ]
        }
      }
    })
  }

  const renderSelectMultiple = () => {
    if (taxpayer.regimes.length === 0) {
      return <em>Seleccionar regímen</em>
    }
    let render = '';
    taxpayer.regimes.forEach(regime => {
      render += `${regime.officialName}`
    });

    return render;
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    let regimesSelecteds = [];
    let day = month;
    let period = day.concat('/',month,'/',year);

    profileData.regimes.forEach(regimeSelected => {
      const regime = regimes.filter(e => e.id === parseInt(regimeSelected))[0];
      regimesSelecteds.push({
        accounting: regime.accounting,
        invoicing: regime.invoicing,
        regimeId: regime.id,
      })
    })
    dispatch(startUpdateTaxpayer({profileId: taxpayer.id, regimes: regimesSelecteds, period: period}, handleModal.bind()))
  }
  
  return (
    <div style={{width: "500px"}}>
      <h4 className="text-center">Aumentar reducir regimenes</h4>
      <hr />
      <form onSubmit={handleSubmit}>
        <div className="col-md-12 mb-3">
          <FormControl variant="outlined" size="small" fullWidth={ true }>
            <InputLabel htmlFor="regimenes-oulined">
              Regímenes
            </InputLabel>
            <Select
              multiple
              value={profileData.regimes}
              onChange={handleChangeSelectMultiple}
              renderValue={renderSelectMultiple}
              labelWidth={80}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 48 * 4.5 + 8,
                    width: 750,
                  },
                }}
              }
              inputProps={{
                name:'regimes',
                id:"regimenes-oulined"
              }}
            >
              <MenuItem disabled value="">
                <em>Selecciona los que apliquen</em>
              </MenuItem>
              {regimes.map((regime) => (
                regime.accounting &&
                (
                  <MenuItem key={regime.id} value={regime.id}>
                    <Checkbox checked={profileData.regimes.indexOf(regime.id) > -1}/>
                    <ListItemText primary={regime.officialName} />
                  </MenuItem>
                )
              ))}
            </Select>
          </FormControl>
        </div>

        <div className="col-md-12 text-right">
          <button className="btn btn-primary">
            Guardar
          </button>
        </div>
      </form>
    </div>
  )
}
