import { types } from "../../types/types";

const initialState = {
  serviceTypes: []
};

export const serviceTypeReducer = ( state = initialState, action ) => {
  switch (action.type) {
    case types.serviceTypeStartLoading:
      return {
        ...state,
        serviceTypes: [ ...action.payload ]
      }

    default:
      return state;
  }
}
