import React, { Fragment, useContext, useEffect } from 'react';
import { ModalContext } from '../../../../../../../../modal/ModalContext';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import NumberFormat from 'react-number-format';

export const Details = ({ details, month, autorizedReport = false, status }) => {

  const { handleModal } = useContext(ModalContext);
  const dispatch = useDispatch();



    return (
      <div className="row">
      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2">
        <div className="card">
          <div className="card-header">
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <h5>Detalles del complemento</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2">
        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr className="text-center">
                <th>Folio Fiscal complemento</th>
                <th>Fecha de emisión</th>
                <th>Clave del producto y/o servicio</th>
                <th>Forma de pago</th>
                <th>Fecha de pago</th>
                <th>Numero de parcialidad</th>
                <th>UUID relacionado</th>
                <th>Importe del saldo anterior</th>
                <th>Importe pago</th>
                <th>Importe del saldo insoluto</th>
              </tr>
            </thead>
             <tbody>
              {details.length === 0 ?
                (<tr>
                  <td colSpan="8">
                    <div className="alert alert-primary text-center mb-0" role="alert">
                      No se hay detalles del complemento
                    </div>
                  </td>
                </tr>) : (
                  details.map((detail, index) => {
                    const monthDetail = detail.stampAt.split('-')[1];
                    return (
                      <tr key={index} className="text-center" style={{ color: monthDetail === month ? 'green' : '', fontWeight: 'bold'}}>
                      <td>{detail.uuid}</td>
                      <td>{detail.stampAt}</td>
                      <td>{detail.productAndOrServiceKey}</td>
                      <td>{detail.wayOfPayment.description}</td>
                      <td>{detail.paymentAt}</td>
                      <td>{detail.partNumber}</td>
                      <td>{detail.relationedUUID}</td>
                      <td>                          
                        <NumberFormat value={parseFloat(detail.previusBalance).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                      </td>
                      <td>
                      <NumberFormat value={parseFloat(detail.paidAmount).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                     </td>
                     <td>                          
                        <NumberFormat value={parseFloat(detail.paidSubtotal).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                      </td>
                    </tr>
                    )
                  })
                )
              }
            </tbody> 
          </table>
        </div>
      </div>
    </div>
    )
}
