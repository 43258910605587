import { TextField } from '@material-ui/core';
import React, { useContext, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { identifierAddNew, identifierUpdated } from '../../../../actions/identifiers';
import { useForm } from '../../../../hooks/useForm';
import { ModalContext } from '../../../modal/ModalContext';

export const IdentifierForm = ({ identifier = null }) => {

  const { handleModal } = useContext(ModalContext)

  const { accountTypes } = useSelector(state => state.accountType)

  const dispatch = useDispatch();

  const { formValues, handleInputChange, setFormValues } = useForm({
    account: '',
    accountTypeId: accountTypes[0].id
  });

  const { account, accountTypeId } = formValues;

  useEffect(() => {
    if (identifier) {
      setFormValues(
        identifier
      )
    }
  }, [identifier, setFormValues])

  const handleSubmit = (e) => {
    e.preventDefault();
    const accountType = accountTypes.filter(e => ( e.id === parseInt(accountTypeId) ))
    if (identifier) {
      dispatch(identifierUpdated({
        ...formValues,
        accountType: accountType[0] 
      }))
    }else{
      dispatch(identifierAddNew({
        ...formValues,
        id: new Date().getTime(),
        accountType: accountType[0]
      }));
    }
    handleModal();
  }

  return (
    <div>
      <div className="text-center">
        <h4>Agregar Identificador</h4>
      </div>
      <hr/>

      <form onSubmit={ handleSubmit }>
        <div className="form-group">
          <TextField
            label="Identificador" 
            id="account"
            variant="outlined"
            size="small"
            name="account"
            value={ account }
            onChange={ handleInputChange }
            autoComplete="off"
            fullWidth={true}
          />
        </div>

        <div className="form-group">
          <TextField
            select
            variant="outlined"
            label="Tipos de cuenta"
            size="small"
            fullWidth={true}
            name="accountTypeId"
            value={accountTypeId}
            onChange={handleInputChange}
            SelectProps={{
              native: true,
            }}
          >
            {
              accountTypes.map((accountType) => {
                return(
                  <option
                    key={ accountType.id }
                    value={ accountType.id }
                  > 
                    { accountType.description }
                  </option>  
                )
              })
            }
          </TextField>
        </div>

        <div className="col-md-12 text-right pr-0">
          <button type="submit" className="btn btn-primary ml-2">Guardar</button>
        </div>
      </form>

    </div>
  )
}
