import { types } from "../types/types";

const initialState = {
  seriesFolios: [],
};

export const SerieFolioReducer = ( state = initialState, action ) => {
  switch (action.type) {
    case types.serieFolioAddNew:
      return {
        ...state,
        seriesFolios: [
          ...state.seriesFolios,
          action.payload
        ]
      }
    
    case types.serieFolioSetSelected:
      return {
        ...state,
        serieFolioSelected: action.payload
      }

    case types.serieFolioUpdated:
      return {
        ...state,
        seriesFolios: state.seriesFolios.map(
          e => ( e.id === action.payload.id ) ? action.payload : e
        )
      }
    
    case types.serieFolioDeleted:
      return {
        ...state,
        seriesFolios: state.seriesFolios.filter(
          e => ( e.id !== action.payload.id )
        )
      }
    default:
      return state;
  }
  
}
