import React, { useEffect } from 'react';
import { Link, useParams, withRouter } from 'react-router-dom';
import ShowFixed from './fixedAssets/showFixed';
import ShowAnnualsummary from './annualSummary/showAnnualsummary';
import ShowAnnualstatement from './annualStatement/showAnnualstatement';
import { TextField } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { taxpayerStartLoading } from '../../../../actions/taxpayers';
import { taxpayersObtainAccounting } from '../../../../helpers/taxpayer';
import { useForm } from '../../../../hooks/useForm';
import { Fragment } from 'react';
import { obtainYears } from '../../../../helpers/declarationPeriod';
import { ACCOUNTING } from '../../../constants/Routes';

const Index = () => {
  const { taxpayerId, regimeSatCode, year } = useParams();

  const { taxpayers } = useSelector(state => state.taxpayer)

  const dispatch = useDispatch();
  
  const { formValues, setFormValues, handleInputChange } = useForm({
    regimesAccounting: [],
    regimeSatCodeAccounting: null,
    taxpayersAccounting: [],
    taxpayerAccountingId: 0,
    years: obtainYears().reverse(),
    yearselected: '',
  });

  const { regimesAccounting, regimeSatCodeAccounting, taxpayersAccounting, taxpayerAccountingId, years, yearselected } = formValues;

  useEffect(() => {
    let taxpayerDefault = 0;
    let taxpayersAccountingData = [];
    if ( taxpayers.length === 0 ){
      dispatch( taxpayerStartLoading() );
    }

    if(taxpayers.length > 0){
      taxpayersAccountingData = taxpayersObtainAccounting(taxpayers);
      if(taxpayerId === 'null'){
        taxpayerDefault = taxpayersAccountingData[0].id;
      }else{
        taxpayerDefault = parseInt(taxpayerId)
      }
    }
    setFormValues( prevState =>  {
      return{
        ...prevState,
        taxpayersAccounting: taxpayersAccountingData,
        taxpayerAccountingId: taxpayerDefault
      }
    })
  }, [ dispatch, setFormValues, taxpayerId, taxpayers ])

  useEffect(() => {
    taxpayersAccounting.forEach(taxpayerAccounting => {
      if(taxpayerAccounting.id === taxpayerAccountingId){
        taxpayerAccounting['profileRegimes'].forEach(profileRegime => {
          if(profileRegime.accounting){
            const regime = taxpayerAccounting['regimes'].filter(e => (e.id === parseInt(profileRegime.regimeId)))[0];
            setFormValues(prevState => {
              return{
                ...prevState,
                regimesAccounting: [ ...prevState.regimesAccounting, regime ]
              }
            })
          }
        })
      }
    });
  }, [ taxpayerAccountingId, taxpayersAccounting, setFormValues ])

  useEffect(() => {
    if( regimeSatCode === 'null' && regimesAccounting.length > 0 ){
      setFormValues(prevState => {
        return{
          ...prevState,
          regimeSatCodeAccounting: regimesAccounting[0].satCode
        }
      })
    }else{
      setFormValues(prevState => {
        return{
          ...prevState,
          regimeSatCodeAccounting: regimeSatCode,
        }
      })
    }
  }, [ regimesAccounting, regimeSatCode, setFormValues])

  useEffect(() => {
    if (year === 'null' && years.length > 0) {
      setFormValues(prevState => {
        return{
          ...prevState,
          yearselected: years[0]
        }
      })
    }else{
      setFormValues(prevState => {
        return{
          ...prevState,
          yearselected: year
        }
      })
    }
  }, [setFormValues, year, years])
  
  return (
    <div className="container">
      <div className="row">
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <h4 className="card-header">Contabilidad</h4>
        </div>
      </div>

      { taxpayersAccounting.length === 0 ?
        (
          <div className="alert alert-danger col-md-12 text-center" role="alert">
            No existe información de algún contribuyente de facturación
          </div>
        ) 
        :
        (
          <Fragment>
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-3">
                <div className="row">
                  <div className="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2 mb-3">
                    <TextField select variant="Emisor" label="Emisor" size="small" 
                      fullWidth={true} name="yearselected" value={ yearselected } onChange={handleInputChange}
                      SelectProps={{native: true,}}>
                      {
                        years.map((yearItem, index) => {
                          return(
                            <option key={ index } value={ yearItem }>
                              { yearItem }
                            </option>
                          )
                        })
                      }
                    </TextField>
                  </div>
                  <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 mb-3">
                    <TextField select variant="outlined" size="small"
                      label="Contribuyente" fullWidth={true} name="taxpayerAccountingId"
                      value={ taxpayerAccountingId } onChange={handleInputChange}
                      SelectProps={{native: true,}}>
                      {
                        taxpayersAccounting.map((taxpayer, index) => {
                          return(
                            <option key={ index } value={ taxpayer.id } >
                              { taxpayer.rfc } - { taxpayer.businessName }
                            </option>
                          )
                        })
                      }
                    </TextField>
                  </div>
                  <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-3">
                    <TextField select variant="outlined" size="small"
                      label="Régimen" fullWidth={true} name="regimeSatCodeAccounting" 
                      value={ regimeSatCodeAccounting } onChange={ handleInputChange }
                      SelectProps={{native: true,}}>
                      {
                        regimesAccounting.map((regime, index) => {
                          return(
                            <option
                              key={ index }
                              value={ regime.satCode }
                            >
                              { regime.satCode } - { regime.officialName }
                            </option>
                          )
                        })
                      }
                    </TextField>
                  </div>
                  <div className="col-md-12 text-center">
                    <Link to={ ACCOUNTING( taxpayerAccountingId, regimeSatCodeAccounting, yearselected ) } className="btn btn-primary float-right">
                      <i className="fas fa-search"></i> Buscar
                    </Link>
                  </div>
                </div>
              </div>

              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-3">
                <nav>
                  <div className="nav nav-tabs" id="nav-tab" role="tablist">
                    <a className="nav-item nav-link active" id="nav-annualSummary-tab" data-toggle="tab" href="#nav-annualSummary" role="tab" aria-controls="nav-annualSummary" aria-selected="true">
                      Resumen Anual
                    </a>
                    <a className="nav-item nav-link" id="nav-fixedAssets-tab" data-toggle="tab" href="#nav-fixedAssets" role="tab" aria-controls="nav-fixedAssets" aria-selected="true">
                      Activos Fijos
                    </a>
                    <a className="nav-item nav-link" id="nav-annualStatement-tab" data-toggle="tab" href="#nav-annualStatement" role="tab" aria-controls="nav-annualStatement" aria-selected="true">
                      Declaración Anual
                    </a>
                  </div>
                </nav>
              </div>
            </div>

            { taxpayerId === 'null' || regimeSatCode === 'null' || year === 'null' ? 
              (
                <div className="col-md-12 mt-3">
                  <div className="alert alert-danger text-center" role="alert">
                    <i className="fas fa-exclamation-triangle"></i> Por favor carga los parámetros de búsqueda
                  </div>
                </div>
              )
              :
              (
                <div className="tab-content" id="nav-tabContent">
                  <div className="tab-pane fade show active" id="nav-annualSummary" role="tabpanel" aria-labelledby="nav-annualSummary-tab">
                    <ShowAnnualsummary
                      taxpayerId={ taxpayerId }
                      regimeSatCode={ regimeSatCode }
                      year={ year }
                    />
                  </div>
                  <div className="tab-pane fade" id="nav-fixedAssets" role="tabpanel" aria-labelledby="nav-fixedAssets-tab">
                    <ShowFixed />
                  </div>
                  <div className="tab-pane fade" id="nav-annualStatement" role="tabpanel" aria-labelledby="nav-annualStatement-tab">
                    <ShowAnnualstatement />
                  </div>
                </div>
              )
            }
          </Fragment>
        )
      }
    </div>
  )
}

export default withRouter(Index);