import React from 'react';

function EyeOpenIcon(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={24}
            height={24}
            viewBox="0 0 24 24"
            {...props}
        >
            <path d="M12 2C5.373 2 2 12 2 12s3.373 10 10 10 10-9.998 10-9.998S18.627 2 12 2zm0 16c-3.313 0-6-2.687-6-6s2.687-6 6-6 6 2.687 6 6-2.687 6-6 6zm0-8c-1.657 0-3 1.344-3 3s1.343 3 3 3 3-1.344 3-3-1.343-3-3-3zm0 4c-.552 0-1-.448-1-1s.448-1 1-1 1 .448 1 1-.448 1-1 1z" />
        </svg>
    );
}

export default EyeOpenIcon;
