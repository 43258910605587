import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from '../../../../../../../hooks/useForm';
import { TextField } from '@material-ui/core';
import React, { useContext, useEffect, useState, useRef } from 'react';
import { regimesConfigLoading, regimesStartLoading } from '../../../../../../../actions/catalogs/regimes';
import { useParams, useHistory } from 'react-router-dom';
import { registerNewActivity } from '../../../../../../../actions/configuration/configurationActions';
import { VIEW_CONFIG_ACTIVITIES } from '../../../../../../constants/Routes';

const NewActivityByRegimenModal = ({ idRegimen }) => {
	const { regimes } = useSelector(state => state.regimesConfig);

	let history = useHistory();

	const dispatch = useDispatch();

	const { formValues, setFormValues } = useForm({
		profession: '',
		activity: '',
		regimenId: 0
	});

	const { profession, activity, regimenId } = formValues;

	const handleSubmit = (e) => {
		e.preventDefault();
		dispatch(registerNewActivity({ ...formValues}))
	};

	useEffect(() => {
		if (regimes.length === 0) {
			dispatch(regimesConfigLoading());
		}
	}, [dispatch, regimes]);

	const handleInputChange = ({ target }) => {
		const { name, value, type } = target;

		// Si el tipo es 'number', convierte el valor a número
		const newValue = type === 'number' ? parseInt(value, 10) : value;

		setFormValues(prevState => {
			return {
				...prevState,
				[name]: name === 'regimenId' ? parseInt(newValue, 10) : newValue
			};
		});
	};

	return (
		<div style={{ width: "800px" }}>
			<div className='text-center'>
				<h5>Registrar nueva actividad</h5>
			</div>
			<hr />
			<form onSubmit={handleSubmit}>
				<div className="row">
					<div className="col-6 mb-3">
						<TextField
							label="Nombre de la actividad:"
							variant='outlined'
							size="small"
							name='activity'
							value={activity}
							onChange={handleInputChange}
							autoComplete='off'
							fullWidth
							inputProps={{ autoCapitalize: 'off' }}
						/>
					</div>
					<div className="col-6 mb-3">
						<TextField
							select
							label="Régimen:"
							variant='outlined'
							size="small"
							name='regimenId'
							value={regimenId}
							onChange={handleInputChange}
							autoComplete='off'
							fullWidth
							inputProps={{
								autoCapitalize: 'off',
								type: 'number'
							}}
							SelectProps={{
								native: true,
							}}
						>
							{
								regimes.map((regimen) => {
									return (
										<option key={regimen.id} value={parseInt(regimen.id)}>
											{regimen.satCode} - {regimen.officialName}
										</option>
									)
								})
							}
						</TextField>
					</div>
					<div className="col-6 mb-3">
						<TextField
							label="Profesión:"
							variant='outlined'
							size="small"
							name='profession'
							value={profession}
							onChange={handleInputChange}
							autoComplete='off'
							fullWidth
							inputProps={{ autoCapitalize: 'off' }}
						/>
					</div>
				</div>
				<div className="row">
					<div className="col-12 d-flex justify-content-end align-items-end">
						<button className="btn btn-primary">
							Registrar actividad
						</button>
					</div>
				</div>
			</form>
		</div>
	);
};

NewActivityByRegimenModal.propTypes = {};

export default NewActivityByRegimenModal;
