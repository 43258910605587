import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const MorePaidComplement = () => {
    return (
        <div className="mt-2">
            <div className="card-header">
                <h5>Agregar Segundo Pago</h5>
            </div>
            <button className="btn btn-success btn-sm float-right mt-2">
                <FontAwesomeIcon icon={"plus"}></FontAwesomeIcon> Agregar
            </button>
        </div>
    )
}
