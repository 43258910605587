import moment from "moment";
/* agregar condicion para incomes y expenses si el regimen es 621 */
export const clasificateIncomes = ( incomes, month, regimeSatCode, year ) => {
  let  clasificates= [];
  incomes.forEach(income => {
    if(income.period.year === parseInt(year) && income.period.month === parseInt(month) && regimeSatCode !== '621') {
      const paymentAtMonth = moment(income.paymentAt).month();
      const paymentAtYear = moment(income.paymentAt).year();
      if ((parseInt(paymentAtMonth) + 1) === parseInt(month) && parseInt(paymentAtYear) === parseInt(year) ) {
        income.authorization.forEach(authorized => {
          if(authorized.regimeSatCode === regimeSatCode){
            clasificates.push(
              { ...income, authorized: authorized.authorized, regimeSatCode: regimeSatCode, retentionTypeId: authorized.retentionTypeId }
            )
          }
        })
      }
    }

    if (regimeSatCode === '621') {
      let monthRif = parseInt(month) + 1;
      if (parseInt(month) % 2 === 0 ) {
        monthRif = parseInt(month) - 1;
      }
      if (income.period.year === parseInt(year) && income.period.month === parseInt(month)) {
        income.authorization.forEach(authorized => {
          if(authorized.regimeSatCode === regimeSatCode){
            clasificates.push(
              { ...income, authorized: authorized.authorized, regimeSatCode: regimeSatCode, retentionTypeId: authorized.retentionTypeId }
            )
          }
        })
      }
      if(income.period.year === parseInt(year) && income.period.month === parseInt(monthRif)){
        income.authorization.forEach(authorized => {
          if(authorized.regimeSatCode === regimeSatCode){
            clasificates.push(
              { ...income, authorized: authorized.authorized, regimeSatCode: regimeSatCode, retentionTypeId: authorized.retentionTypeId }
            )
          }
        })
      }
    }
  });
  return clasificates;
}

export const clasificateIncomesRIF = ( incomes, month, regimeSatCode, year ) => {
  let  clasificates= [];
  incomes.forEach(income => {
    if(income.period.year === parseInt(year) && income.period.month === parseInt(month)) {      
      const paymentAtMonth = moment(income.paymentAt).month();
      const paymentAtYear = moment(income.paymentAt).year();



      if ((parseInt(paymentAtMonth) + 1) === parseInt(month) && parseInt(paymentAtYear) === parseInt(year)) {
        income.authorization.forEach(authorized => {
          if(authorized.regimeSatCode === regimeSatCode){
            clasificates.push(
              { ...income, authorized: authorized.authorized, regimeSatCode: regimeSatCode, retentionTypeId: authorized.retentionTypeId }
            )
          }
        })
      }
    }
  });
  return clasificates;
}

export const consideredsIncomes = ( incomes ) => {
  let  considereds= [];
  incomes.forEach(income => {
    if (income.authorized && income.validateCfdi.estado === 'Vigente') {
      considereds.push({ ...income });
    }
  });
  return considereds;
}

export const notConsideredsIncomes = ( incomes ) => {
  let  notConsidereds= [];
  incomes.forEach(income => {
    if (!income.authorized && income.validateCfdi.estado === 'Vigente') {
      notConsidereds.push({ ...income });
    }
  });
  return notConsidereds;
}

//aqui debo cambiar por los que son cancelados
export const canceledsIncomes = ( incomes ) => {
  let  canceledsIncome= [];
  incomes.forEach(income => {
    if (income.validateCfdi.estado !== 'Vigente') {
      canceledsIncome.push({ ...income });
    }
  });
  return canceledsIncome;
}

export const clasificateExpenses = ( expenseTypes, expenses, month, regimeSatCode, year ) => {
  let  clasificates= [];
  expenses.forEach(expense => {
    if (expense.period.year === parseInt(year) && expense.period.month === parseInt(month) && regimeSatCode !== '621' && regimeSatCode !== '606') {
      const paymentAtMonth = moment(expense.paymentAt).month();
      const paymentAtYear = moment(expense.paymentAt).year();
      if ((parseInt(paymentAtMonth) + 1) === parseInt(month) && parseInt(paymentAtYear) === parseInt(year)) {
        expense.authorization.forEach( authorized => {
          if (authorized.regimeSatCode === regimeSatCode) {
            if(authorized.expenseTypeId !== 0) {
              const expenseType = expenseTypes.filter(e => (e.id === parseInt(authorized.expenseTypeId)))[0];
              clasificates.push( { ...expense, authorized: authorized.authorized, expenseTypeId: authorized.expenseTypeId, regimeSatCode: regimeSatCode, description: expenseType?.name, expenseKeyType: expenseType?.key, expenseFixedAssetType: expenseType?.fixedAsset } )
            }else{
              clasificates.push( { ...expense, authorized: authorized.authorized, expenseTypeId: authorized.expenseTypeId, regimeSatCode: regimeSatCode, description: 'No se ha clasificado', expenseKeyType: '', expenseFixedAssetType: false } )
            }
          }
        })
      }
    }

    if (regimeSatCode === '621' || regimeSatCode === '606') {
      let monthRif = parseInt(month) + 1;
      if (parseInt(month) % 2 === 0) {
        monthRif = parseInt(month) - 1;
      }
      if (expense.period.year === parseInt(year) && expense.period.month === parseInt(month)) {
        expense.authorization.forEach( authorized => {
          if (authorized.regimeSatCode === regimeSatCode) {
            if(authorized.expenseTypeId !== 0) {
              const expenseType = expenseTypes.filter(e => (e.id === parseInt(authorized.expenseTypeId)))[0];
              clasificates.push( { ...expense, authorized: authorized.authorized, expenseTypeId: authorized.expenseTypeId, regimeSatCode: regimeSatCode, description: expenseType.name, expenseKeyType: expenseType.key, expenseFixedAssetType: expenseType.fixedAsset } )
            }else{
              clasificates.push( { ...expense, authorized: authorized.authorized, expenseTypeId: authorized.expenseTypeId, regimeSatCode: regimeSatCode, description: 'No se ha clasificado', expenseKeyType: '', expenseFixedAssetType: false } )
            }
          }
        })
      }
      if(expense.period.year === parseInt(year) && expense.period.month === parseInt(monthRif)){
        expense.authorization.forEach( authorized => {
          if (authorized.regimeSatCode === regimeSatCode) {
            if(authorized.expenseTypeId !== 0) {
              const expenseType = expenseTypes.filter(e => (e.id === parseInt(authorized.expenseTypeId)))[0];
              clasificates.push( { ...expense, authorized: authorized.authorized, expenseTypeId: authorized.expenseTypeId, regimeSatCode: regimeSatCode, description: expenseType.name, expenseKeyType: expenseType.key, expenseFixedAssetType: expenseType.fixedAsset } )
            }else{
              clasificates.push( { ...expense, authorized: authorized.authorized, expenseTypeId: authorized.expenseTypeId, regimeSatCode: regimeSatCode, description: 'No se ha clasificado', expenseKeyType: '', expenseFixedAssetType: false } )
            }
          }
        })
      }
    }
  });
  return clasificates;
}

export const consideredsExpenses = (expenseTypes, expenses, regimeSatCode) => {
  let  considereds = [];
  expenses.forEach(expense => {
    expense.authorization.forEach( authorized => {
      if (authorized.regimeSatCode === regimeSatCode) {
        if(authorized.expenseTypeId !== 0) {
          const expenseType = expenseTypes.filter(e => ( e.id === parseInt(authorized.expenseTypeId) ))[0];          
          if (expenseType.deductionType.key === 'DA' && authorized.authorized && expense.validateCfdi.estado === 'Vigente') {
            considereds.push({ ...expense });
          }
        }
      }
    })
  });
  return considereds;
}

export const personalConsideredsExpenses = (expenseTypes, expenses, regimeSatCode) => {
  let  personalConsidereds = [];
  expenses.forEach(expense => {
    expense.authorization.forEach( authorized => {
      if (authorized.regimeSatCode === regimeSatCode && authorized.expenseTypeId !== 0 && expense.validateCfdi.estado === 'Vigente') {
        const expenseType = expenseTypes.filter(e => (e.id === parseInt(authorized.expenseTypeId)))[0];
        if (expenseType.deductionType.key === 'DP' && authorized.authorized) {
          personalConsidereds.push({ ...expense });
        }
      }
    })
  });
  return personalConsidereds;
}

export const notConsideredsExpenses = (expenseTypes, expenses, regimeSatCode) => {
  let  notConsidereds = [];
  expenses.forEach(expense => {
    expense.authorization.forEach( authorized => {
      if (authorized.regimeSatCode === regimeSatCode) {
        if (authorized.expenseTypeId !== 0 && expense.validateCfdi.estado === 'Vigente') {
          const expenseType = expenseTypes.filter(e => ( e.id === parseInt(authorized.expenseTypeId) ))[0];          
          if (expenseType.deductionType.key === 'ND' || !authorized.authorized) {
            notConsidereds.push({ ...expense });
          }
        }else{
          notConsidereds.push({ ...expense });
        }
      }
    })
  });
  return notConsidereds;
}

//aqui se debe cambiar por los gastos cancelados
export const canceledsExpenses = (expenses) => {
  let  canceledsExpense = [];
  expenses.forEach(expense => {
    if (expense.validateCfdi.estado !== 'Vigente') {
        canceledsExpense.push({ ...expense });
    }
  });
  return canceledsExpense;
}

export const canceledsRetentions = (retentions) => {
  let  canceledsRetentions = [];
  retentions.forEach(retention => {
    if (retention.validateCfdi.estado !== 'Vigente') {
        canceledsRetentions.push({ ...retention });
    }
  });
  return canceledsRetentions;
}

export const initializeDownloaded = ( dispatch, month, regimeSatCode, startDownloadXMLSATRequest, taxpayerId, year) => {
  dispatch(startDownloadXMLSATRequest({profileId: taxpayerId, month: month, year: year, requestType: 1}));
  dispatch(startDownloadXMLSATRequest({profileId: taxpayerId, month: month, year: year, requestType: 2}));
  if (regimeSatCode === '621') {
    let monthRif = parseInt(month) + 1;
    if (parseInt(month) % 2 === 0 ) {
      monthRif = parseInt(month) - 1
    }
    dispatch(startDownloadXMLSATRequest({profileId: taxpayerId, month: monthRif, year: year, requestType: 1}));
    dispatch(startDownloadXMLSATRequest({profileId: taxpayerId, month: monthRif, year: year, requestType: 2}));
  }
}