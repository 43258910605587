import { types } from "../../types/types";

const initialState = {
  receptorAccountBanksTypes: []
}

export const receptorAccountBankReducer = (state=initialState, action) => {
  switch (action.type) {
    case types.receptorAccountBanksStartLoading:
      return{
        ...state,
        receptorAccountBanksTypes: [...action.payload]
      }    

    default:
      return state;
  }
}
