import { TextField } from '@material-ui/core';
import React, { Fragment, useEffect } from 'react'
import { useForm } from '../../../../../hooks/useForm';

export const ModuleFielScreen = ({saleFormValues, salaHandleInputChange, saleSetFormValues}) => {

  const {taxEfirmaFiles} = saleFormValues;

  const { formValues, handleCheckboxTrueFalseChange } = useForm({
    efirmaPresent: false
  });

  const { efirmaPresent } = formValues;

  useEffect(() => {
    if(efirmaPresent){
      saleSetFormValues(prevState => {
        return{
          ...prevState,
          taxEfirmaFiles: {
            fileCer: '',
            fileKey: '',
            isFiel: true,
            password: ''
          }
        }
      })
    }else{
      saleSetFormValues(prevState => {
        return{
          ...prevState,
          taxEfirmaFiles: {}
        }
      })
    }
  }, [efirmaPresent, saleSetFormValues]);

  const handleFileInputChange = ({target}) => {
    const { name, files } = target;

    saleSetFormValues(prevState => {
      return{
        ...prevState,
        taxEfirmaFiles:{
          ...prevState.taxEfirmaFiles,
          [name]: files[0]
        }
      }
    })
  }

  const handleInputChange = ({target}) => {
    const { name, value } = target;

    saleSetFormValues(prevState => {
      return{
        ...prevState,
        taxEfirmaFiles:{
          ...prevState.taxEfirmaFiles,
          [name]: value
        }
      }
    })
  }

  return (
    <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-3">
      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3">
        <h5>E-firma(Fiel)</h5>
        <hr />
      </div>

      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 text-center">
        <div className="form-group">
          <label><strong>¿El contribuyente cuenta con un E-firma vigente?</strong></label>
          <div className="row">
            <div className="col-md-6 form-check">
              <input 
                className="form-check-input" 
                type="radio" 
                name="efirmaPresent" 
                id="efirmaNo"
                value={false}
                onChange={handleCheckboxTrueFalseChange}
                defaultChecked
              />
              <label className="form-check-label" htmlFor="efirmaNo">
                No
              </label>
            </div>

            <div className="col-md-6 form-check">
              <input
                className="form-check-input" 
                type="radio" 
                name="efirmaPresent" 
                id="efirmaYes" 
                value={true}
                onChange={handleCheckboxTrueFalseChange}
              />
              <label className="form-check-label" htmlFor="efirmaYes">
                Si
              </label>
            </div>
          </div>
        </div>
      </div>

      {
        efirmaPresent &&
          <Fragment>
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3">
              <TextField label="Certificado (.cer)" variant="outlined" size="small" autoComplete="off" fullWidth={true} 
                InputLabelProps={{shrink: true,}}
                type="file"
                name="fileCer"
                onChange={ handleFileInputChange }
                // helperText={ msgErrors.fileCer }
                // error={ msgErrors.fileCer !== undefined }
                inputProps={{ accept: '.cer' }}
              />
            </div>
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3">
              <TextField label="Llave privada (.key)" variant="outlined" size="small" autoComplete="off" fullWidth={true} 
                InputLabelProps={{shrink: true,}}
                type="file"
                name="fileKey"
                onChange={ handleFileInputChange }
                // helperText={ msgErrors.fileCer }
                // error={ msgErrors.fileCer !== undefined }
                inputProps={{ accept: '.key' }}
              />
            </div>
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 text-center">
              <TextField 
                label="Contraseña" variant="outlined" size="small" autoComplete="off" fullWidth={true}
                type="password"
                name="password"
                value={taxEfirmaFiles.password || ''}
                onChange={handleInputChange}
              />
            </div>
          </Fragment>
        // (
        //   <Fragment>
        //     <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3">
        //       <TextField
        //         label="Cita en el SAT" variant="outlined" size="small" autoComplete="off" fullWidth={true}
        //         type="date"
        //         InputLabelProps={{shrink: true,}}
        //         name="meetingFiel"
        //         value={meetingFiel}
        //         onChange={ salaHandleInputChange }
        //         // helperText={ msgErrors.endServiceAt }
        //         // error={ msgErrors.endServiceAt !== undefined }
        //         // disabled={ !!crmSaleSelected }
        //       />
        //     </div>
        //   </Fragment>
        // )
      }
    </div>
  )
}
