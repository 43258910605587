import { TextField } from '@material-ui/core';
import React, { useContext, useState } from 'react'
import { useDispatch } from 'react-redux';
import { startRegisterCertificate } from '../../../../actions/taxpayers/certificates';
import { validateFilePresence, validateStringPresence } from '../../../../helpers/validateForm';
import { useForm } from '../../../../hooks/useForm';
import { ModalContext } from '../../../modal/ModalContext';

export const CertificateForm = ({ taxpayerId, isFiel = false }) => {
  const { handleModal } = useContext(ModalContext)

  const dispatch = useDispatch();

  const { formValues, handleFileInputChange, handleInputChange } = useForm({
    fileCer: '',
    fileKey: '',
    password: ''
  });

  const { fileCer, fileKey, password } = formValues;

  const [msgErrors, setMsgErrors] = useState({});

  const handleSubmit = (e) => {
    e.preventDefault();
    if(isFormValid()){

      let formData = new FormData();
      formData.append('Files', fileCer);
      formData.append('Files', fileKey);
      formData.append('IsFiel', isFiel);
      formData.append('Password', password);
      formData.append('ProfileId', taxpayerId);

      dispatch(
        startRegisterCertificate(formData, handleModal.bind())
      )
    }
  }

  const isFormValid = () => {
    setMsgErrors({});

    const datasStringToValidate = { password }
    const datasFilesToValidate = { fileCer, fileKey }

    const validateString = validateStringPresence( datasStringToValidate, setMsgErrors );
    const validateFiles = validateFilePresence( datasFilesToValidate, setMsgErrors ); 
  
    if( validateString && validateFiles ){
      return true;
    }
    return false;
  }

  return (
    <div>
      <div className="text-center">
        {
          isFiel ? 
          (
            <h4> Agregar nueva E-Firma </h4>
          )
          :
          (
            <h4> Agregar nuevo certificado </h4>
          )
        }
      </div>
      <hr/>

      <form onSubmit={ handleSubmit }>
        <div className="form-group">
          <label>Certificado (.cer)</label>
          <TextField  variant="outlined" autoComplete="off" fullWidth={true} 
            type="file"
            name="fileCer"
            onChange={ handleFileInputChange }
            helperText={ msgErrors.fileCer }
            error={ msgErrors.fileCer !== undefined }
            inputProps={{ accept: '.cer' }}
          />
        </div>

        <div className="form-group">
          <label>Llave privada (.key)</label>
          <TextField variant="outlined" autoComplete="off" fullWidth={true}
            type="file"
            name="fileKey"
            onChange={ handleFileInputChange }
            helperText={ msgErrors.fileKey }
            error={ msgErrors.fileKey !== undefined }
            inputProps={{ accept: '.key' }}
          />
        </div>

        <div className="form-group">
          <label>Contraseña de Llave privada</label>
          <TextField variant="outlined" size="small" autoComplete="off" fullWidth={true} 
            type="password"
            name="password"
            value={ password }
            onChange={ handleInputChange }
            helperText={ msgErrors.password }
            error={ msgErrors.password !== undefined }
          />
        </div>

        <div className="col-md-12 text-right pr-0">
          <button type="submit" className="btn btn-primary ml-2">Guardar</button>
        </div>
      </form>
    </div>
  )
}
