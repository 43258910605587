const initialState = {
  currencyTypes: [
    {
      id: 1,
      satCode: 'MXN',
      description: 'Peso Mexicano'
    },
    {
      id: 2,
      satCode: 'USD',
      description: 'Dolar americano'
    }

  ]
}

export const currencyTypeReducer = ( state = initialState, action ) => {
  switch (action.type) {
    default:
      return state;
  }
}
