import React, { Fragment, useEffect } from 'react';
import { InputAdornment, TextField } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { obtainIsrPlataformPorcentage } from '../../../../../../../../../../helpers/accounting/obtainIsrPlataformPorcentaje';
import NumberFormat from 'react-number-format';

export const ServiceGroundScreen = ({ formValuesDetailsSummary, setFormValuesDetailsSummary, autorizedReport, status }) => {

  const { IsrPlataformRanges } = useSelector(state => state.isrPlataform);

  const { taxISRPlataformsTechnologies, year } = formValuesDetailsSummary;

  const { serviceGround } = taxISRPlataformsTechnologies;

  const { ISR, totalIsr, subtotalServiceDealerClasified, subtotalServicePassengersClasified, totalServiceDealerFinal, totalServicePassengersFinal,
    totalService, totalDealerPlataform, totalPassengerPlataform, totalRetained
  } = serviceGround;

  useEffect(() => {
    let totalServicePassengers = (parseFloat(subtotalServicePassengersClasified) - parseFloat(totalPassengerPlataform)).toFixed(2);
    let totalServiceDealer = (parseFloat(subtotalServiceDealerClasified) - parseFloat(totalDealerPlataform)).toFixed(2);

    if (parseFloat(totalServicePassengers) <= 0) {
      totalServicePassengers = 0.00;
    }
    if (parseFloat(totalServiceDealer) <= 0) {
      totalServiceDealer = 0.00;
    }

    const totalGroundService = (
      parseFloat(totalDealerPlataform) +
      parseFloat(totalPassengerPlataform) +
      parseFloat(totalServiceDealer) +
      parseFloat(totalServicePassengers)
    ).toFixed(2);

    const totalForIntermediaries = (
      parseFloat(totalDealerPlataform) +
      parseFloat(totalPassengerPlataform)
    ).toFixed(2);

    const totalForUsers = (
      parseFloat(totalServicePassengers) +
      parseFloat(totalServiceDealer)
    ).toFixed(2);

    setFormValuesDetailsSummary(prevState => {
      return {
        ...prevState,
        taxISRPlataformsTechnologies: {
          ...prevState.taxISRPlataformsTechnologies,
          serviceGround: {
            ...prevState.taxISRPlataformsTechnologies.serviceGround,
            totalService: totalGroundService,
            totalServiceDealerFinal: totalServiceDealer,
            totalServicePassengersFinal: totalServicePassengers,
            totalForIntermediaries: totalForIntermediaries,
            totalForUsers: totalForUsers
          }
        }
      }
    })
  }, [setFormValuesDetailsSummary, subtotalServiceDealerClasified, subtotalServicePassengersClasified, totalDealerPlataform, totalPassengerPlataform])

  useEffect(() => {
    setFormValuesDetailsSummary(prevState => {
      return {
        ...prevState,
        taxISRPlataformsTechnologies: {
          ...prevState.taxISRPlataformsTechnologies,
          serviceGround: {
            ...prevState.taxISRPlataformsTechnologies.serviceGround,
            ISR: obtainIsrPlataformPorcentage(IsrPlataformRanges, totalService, year),
          }
        }
      }
    })
  }, [IsrPlataformRanges, setFormValuesDetailsSummary, totalService, year])

  useEffect(() => {
    const totalIsr = (
      parseFloat(ISR.isrCaused) -
      parseFloat(totalRetained)
    ).toFixed(2)

    setFormValuesDetailsSummary(prevState => {
      return {
        ...prevState,
        taxISRPlataformsTechnologies: {
          ...prevState.taxISRPlataformsTechnologies,
          serviceGround: {
            ...prevState.taxISRPlataformsTechnologies.serviceGround,
            totalIsr: totalIsr
          }
        }
      }
    })
  }, [ISR, totalRetained, setFormValuesDetailsSummary]);

  const handleInputChangeGround = (e) => {
    const { value, name } = e.target;
    setFormValuesDetailsSummary(prevState => {
      return {
        ...prevState,
        taxISRPlataformsTechnologies: {
          ...prevState.taxISRPlataformsTechnologies,
          serviceGround: {
            ...prevState.taxISRPlataformsTechnologies.serviceGround,
            [name]: value
          }
        }
      }
    })
  }

  return (
    <table className="table">
      <tbody>
        <tr>
          <td className="w-50">Ingresos intermediarios servicio de pasajeros</td>
          <td className="w-50">
            {
              status === 'Presentada' ?
                (<TextField
                  variant="outlined"
                  size="small"
                  name="totalPassengerPlataform"
                  value={totalPassengerPlataform}
                  onChange={handleInputChangeGround}
                  autoComplete="off"
                  fullWidth={true}
                  InputProps={{
                    startAdornment: (<InputAdornment position="start">$</InputAdornment>),
                  }}
                  disabled={true}
                />)
                :
                (<TextField
                  variant="outlined"
                  size="small"
                  name="totalPassengerPlataform"
                  value={totalPassengerPlataform}
                  onChange={handleInputChangeGround}
                  autoComplete="off"
                  fullWidth={true}
                  InputProps={{
                    startAdornment: (<InputAdornment position="start">$</InputAdornment>),
                  }}
                  disabled={autorizedReport}
                />)
            }
          </td>
        </tr>

        <tr>
          <td className="w-50">Ingresos intermediarios entrega bienes</td>
          <td className="w-50">
            {
              status === 'Presentada' ?
                (<TextField
                  variant="outlined"
                  size="small"
                  name="totalDealerPlataform"
                  value={totalDealerPlataform}
                  onChange={handleInputChangeGround}
                  autoComplete="off"
                  fullWidth={true}
                  InputProps={{
                    startAdornment: (<InputAdornment position="start">$</InputAdornment>),
                  }}
                  disabled={true}
                />)
                :
                (<TextField
                  variant="outlined"
                  size="small"
                  name="totalDealerPlataform"
                  value={totalDealerPlataform}
                  onChange={handleInputChangeGround}
                  autoComplete="off"
                  fullWidth={true}
                  InputProps={{
                    startAdornment: (<InputAdornment position="start">$</InputAdornment>),
                  }}
                  disabled={autorizedReport}
                />)
            }
          </td>
        </tr>
        <tr>
          <td className="w-50">Ingresos directos servicio de pasajeros</td>
          <td className="w-50"><NumberFormat value={parseFloat(totalServicePassengersFinal).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td>
        </tr>
        <tr>
          <td className="w-50">Ingresos directos entrega bienes</td>
          <td className="w-50"><NumberFormat value={parseFloat(totalServiceDealerFinal).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td>
        </tr>
        <tr>
          <td className="w-50">Ingresos totales</td>
          <td className="w-50"><NumberFormat value={parseFloat(totalService).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td>
        </tr>
        <tr>
          <td className="w-50">Tasa</td>
          <td className="w-50"><NumberFormat value={parseFloat(ISR.porcentage * 100).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'%'} /></td>
        </tr>
        <tr>
          <td className="w-50">ISR causado</td>
          <td className="w-50"><NumberFormat value={parseFloat(ISR.isrCaused).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td>
        </tr>
        <tr>
          <td className="w-50">Retenciones por plataformas tecnológicas</td>
          <td className="w-50">
            {
              status === 'Presentada' ?
                (<TextField
                  variant="outlined"
                  size="small"
                  name="totalRetained"
                  value={totalRetained}
                  onChange={handleInputChangeGround}
                  autoComplete="off"
                  fullWidth={true}
                  InputProps={{
                    startAdornment: (<InputAdornment position="start">$</InputAdornment>),
                  }}
                  disabled={true}
                />)
                :
                (<TextField
                  variant="outlined"
                  size="small"
                  name="totalRetained"
                  value={totalRetained}
                  onChange={handleInputChangeGround}
                  autoComplete="off"
                  fullWidth={true}
                  InputProps={{
                    startAdornment: (<InputAdornment position="start">$</InputAdornment>),
                  }}
                  disabled={autorizedReport}
                />)
            }
          </td>
        </tr>
        <tr>
          {
            totalIsr >= 0 ? (
              <Fragment>
                <td className="w-50">ISR a cargo</td>
                <td className="w-50"><NumberFormat value={parseFloat(totalIsr).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td>
              </Fragment>
            )
              :
              (
                <Fragment>
                  <td className="w-50">ISR a cargo</td>
                  <td className="w-50"><NumberFormat value={0.00} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td>
                </Fragment>
              )
          }
        </tr>
      </tbody>
    </table>
  )
}
