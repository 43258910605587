import { InputAdornment, TextField } from '@material-ui/core';
import React, { Fragment, useEffect } from 'react';
import NumberFormat from 'react-number-format';
import { useSelector } from 'react-redux';

export const SimplifiedIvaCalculation = ({ formValuesDetailsSummary, setFormValuesDetailsSummary, status }) => {

  const { authorizationReport, authorizations } = useSelector(state => state.authorizationReport);
  const { authorizationPrev } = useSelector(state => state.authorizationReport);

  const { subtotalIncomesRate0, subtotalIncomesRate16, subtotalSumRateExento, totalTaxesRate16, totalIVAExpenseAutorized, totalIVARetainedIncomeAutorized, taxIVASimplified } = formValuesDetailsSummary;

  const { IVAMonthsPrev, subtotalIncome16, subtotalIncome0, subtotalIncomeExento, subtotalIVA, totalIvaRetained, totalIvaCreditable, totalTaxe16, totalIVA } = taxIVASimplified;

  const ivaTotal = authorizations.paymentComplementExpensesIva || authorizationReport.paymentComplementExpensesIva || 0
  let ivaExpenseTotalAcreditable = parseFloat(totalIVAExpenseAutorized) + parseFloat(ivaTotal) 

  useEffect(() => {
    const subtotalIncome16 = parseFloat(subtotalIncomesRate16);
    const subtotalIncome0 = parseFloat(subtotalIncomesRate0);
    const subtotalIncomeExento = parseFloat(subtotalSumRateExento);
    const totalTaxe16 = parseFloat(totalTaxesRate16);
    const totalIvaRetained = parseFloat(totalIVARetainedIncomeAutorized);
    const totalIvaCreditable = parseFloat(totalIVAExpenseAutorized);
    const subtotalIVA = (parseFloat(totalTaxe16) - (parseFloat(totalIVAExpenseAutorized) + parseFloat(totalIvaRetained))).toFixed(6)
    setFormValuesDetailsSummary(prevState => {
      return{
        ...prevState,
        taxIVASimplified: {
          ...prevState.taxIVASimplified,
          subtotalIncome16: subtotalIncome16,
          subtotalIncome0: subtotalIncome0,
          subtotalIncomeExento: subtotalIncomeExento,
          totalTaxe16: totalTaxe16,
          totalIvaCreditable: totalIvaCreditable,
          totalIvaRetained: totalIvaRetained,

          subtotalIVA: subtotalIVA
        }
      }
    })
  }, [setFormValuesDetailsSummary, subtotalIncomesRate16, subtotalIncomesRate0, subtotalSumRateExento, totalTaxesRate16, totalIVAExpenseAutorized, totalIVARetainedIncomeAutorized])



  useEffect(() => {
    let ivaPrev = parseFloat(IVAMonthsPrev);
    let totalIvaFinal = 0.00;

    if (Object.keys(authorizationPrev).length > 0) {
      const iva = JSON.parse(authorizationPrev.ivaJson);
      if (Object.keys(iva).length > 0) {
        if (parseFloat(iva.totalIVA) < 0) {
          if (parseFloat(IVAMonthsPrev) !== (parseFloat(iva.totalIVA) * -1) && parseFloat(IVAMonthsPrev) === 0) {
            ivaPrev = parseFloat(iva.totalIVA) * -1;
          }
        }
      }
    }
    // totalIvaFinal = (parseFloat(subtotalIVA) + (ivaPrev * -1)).toFixed(2);
    totalIvaFinal = (parseFloat(totalTaxe16) - parseFloat(ivaExpenseTotalAcreditable) - parseFloat(totalIvaRetained) + (ivaPrev * -1)).toFixed(2);
    let newIvaPrevs = ivaPrev * 1;
    setFormValuesDetailsSummary(prevState => {
      return{
        ...prevState,
        taxIVASimplified:{
          ...prevState.taxIVASimplified,
          totalIVA: totalIvaFinal,
          IVAMonthsPrev: newIvaPrevs
        }
      }
    });
  }, [authorizationPrev, IVAMonthsPrev, setFormValuesDetailsSummary, subtotalIVA]);

  const handleInputChange = ({target}) => {
    setFormValuesDetailsSummary(prevState => {
      return{
        ...prevState,
        taxIVASimplified:{
          ...prevState.taxIVASimplified,
          [target.name]: target.value
        }
      }
    })
  }

  return (
    <Fragment>
      <tr>
        <td className="w-50">Actividades gravadas a la tasa del 16%</td>
        <td className="w-25"><NumberFormat value={parseFloat(subtotalIncome16).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td>
      </tr>

      <tr>
        <td className="w-50">Actividades gravadas a la tasa del 0%</td>
        <td className="w-25"><NumberFormat value={parseFloat(subtotalIncome0).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td>
      </tr>

      <tr>
        <td className="w-50">Actividades exentas</td>
        <td className="w-25"><NumberFormat value={parseFloat(subtotalIncomeExento).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td>
      </tr>

      <tr>
        <td className="w-50">IVA cobrado del periodo a la tasa del 16%</td>
        <td className="w-25"><NumberFormat value={parseFloat(totalTaxe16).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td>
      </tr>

      <tr>
        <td className="w-50">IVA acreditable del periodo</td>
        <td className="w-25"><NumberFormat value={parseFloat(ivaExpenseTotalAcreditable).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td>
      </tr>

      <tr>
        <td className="w-50">IVA RETENIDO</td>
        <td className="w-25"><NumberFormat value={parseFloat(totalIvaRetained).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td>
      </tr>

      <tr>
        <td className="w-50">IVA A FAVOR DE PERIODOS ANTERIORES</td>
        <td className="w-25"><NumberFormat value={parseFloat(IVAMonthsPrev).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td>
          {/* {
            status === 'Presentada' ?
            (<TextField
              label="IVA" variant="outlined" size="small" autoComplete="off" fullWidth={true}
              type="number"
              name="IVAMonthsPrev"
              value={IVAMonthsPrev}
              onChange={handleInputChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    $
                  </InputAdornment>
                ),
              }}
              disabled={true}
            />)
            :
            (<TextField
              label="IVA" variant="outlined" size="small" autoComplete="off" fullWidth={true}
              type="number"
              name="IVAMonthsPrev"
              value={IVAMonthsPrev}
              onChange={handleInputChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    $
                  </InputAdornment>
                ),
              }}
            /> )
          } */}
      </tr>
      { parseFloat(totalIVA) > 0 ? 
        (
          <tr>
            <td className="w-50">IVA a pagar</td>
            <td className="w-25"><NumberFormat value={parseFloat(totalIVA).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td>
          </tr>
        )
        :
        (
          <tr>
            <td className="w-50">IVA a Favor</td>
            <td className="w-25"><NumberFormat value={(parseFloat(totalIVA) * -1).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td>
          </tr>
        )
      }
    </Fragment>
  )
}