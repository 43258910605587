import React, { Fragment, useContext, useEffect } from 'react';
import { ModalContext } from '../../../../../../../../modal/ModalContext';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import NumberFormat from 'react-number-format';
import { Details } from './Details';
import AuthorizaedDeductionsComplementIncomes from '../../expenses/options/authorizaedDeductionsComplementIncomes';

export const ComplementIncome = ({ formValuesDetailsSummary, setFormValuesDetailsSummary, autorizedReport = false, status, refreshComplementPayments }) => {

  const { regimeSatCode, taxpayerId, month, year } = useParams();
  const { complements, complementsIncome } = useSelector(state => state.complement);
  const { authorizationReport, authorizations } = useSelector(state => state.authorizationReport)
  const { handleModal } = useContext(ModalContext);
  const dispatch = useDispatch();

  const complementsIcomeData = authorizations.complementsIncomes || authorizationReport.complementsIncomes || [];

  useEffect(() => {
    //console.log(complementsIncome)
  }, [])

  return (
    <div className="row">
      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2">
        <div className="card">
          <div className="card-header">
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <h5>Complementos Relacionados</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2">
        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr className="text-center">
                <th>Check</th>
                <th>Fecha de emisión</th>
                <th>Status</th>
                <th>Proveedor</th>
                <th>Folio Fiscal</th>
                <th>Clave de producto y servicios</th>
                <th>Descripción</th>
                <th>Forma de pago</th>
                <th>Metodo de pago</th>
                <th>Clasificación</th>
                <th>Subtotal</th>
                <th>Descuento</th>
                <th>Iva</th>
                <th>Total</th>
                <th>Importe a pagar</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {complementsIcomeData.length === 0 ?
                (<tr>
                  <td colSpan="14">
                    <div className="alert alert-primary text-center mb-0" role="alert">
                      No se ha agregado información
                    </div>
                  </td>
                </tr>) : (
                  complementsIcomeData.map((complement, index) => {
                    return (
                      <tr key={index} className={`text-center ${complement.withMaster === false && complement.details !== 'null' && "text-success"}`}>
                        <td>
                          {
                            complement.details !== null && (
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  name="typeOwner"
                                  defaultChecked={complement.details?.every(detail => { return detail.authorized })}
                                  // onChange={ (e) => {changedConsideredComplement( e, complement ) }}
                                  disabled={false}
                                />
                              </div>
                            )
                          }
                        </td>
                        <td>{complement.paymentAt}</td>
                        <td style={{ color: complement.statusComplementParent === 'Retrasado' ? 'red' : complement.statusComplementParent === 'Al Corriente' ? 'yellow' : complement.statusComplementParent === 'Pagado' ? 'green' : '', fontWeight: 'bold' }}>{complement.statusComplementParent}</td>
                        <td>
                          {complement.IssuedRfc}
                        </td>
                        <td>
                          {complement.RelationedUUID}
                        </td>
                        <td>{complement.claveProdServ}</td>
                        <td>{complement?.descripcion}</td>
                        <td><strong>{complement.wayOfPayment?.description}</strong></td>
                        <td><strong>{complement.paymentMethod?.description}</strong></td>
                        <td>{complement.clasificacion}</td>
                        <td>
                          <NumberFormat value={parseFloat(complement.subtotal).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                        </td>
                        <td>
                          <NumberFormat value={parseFloat(complement.descuento).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                        </td>
                        <td>
                          <NumberFormat value={parseFloat(complement.iva).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                        </td>
                        <td>
                          <NumberFormat value={parseFloat(complement.montoTotalFactura).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                        </td>
                        <td>
                          <NumberFormat value={parseFloat(complement.impSaldoInsoluto).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                        </td>
                        <td>
                          {
                            complement.withMaster === true && complement.details !== null && (
                              <button className="btn btn-warning"
                                onClick={() => handleModal(
                                  <Details
                                    details={complement.details}
                                    month={month}
                                  // autorizedReport={autorizedReport}
                                  // status={status}
                                  />
                                )}
                              >
                                <i className='fas fa-edit'></i>
                              </button>
                            )
                            || complement.withMaster === false && complement.details !== null && (
                              <Fragment>
                                {/* <label> Sin padres </label>  */}
                                <button className="btn btn-warning"
                                  onClick={() => handleModal(
                                    <Details
                                      details={complement.details}
                                    // autorizedReport={autorizedReport}
                                    // status={status}
                                    />
                                  )}
                                >
                                  <i className='fas fa-edit'></i>
                                </button>
                              </Fragment>
                            )
                            || complement.withMaster === true && complement.details === null && (
                              "Sin detalles"
                            )
                          }
                        </td>
                      </tr>
                    )
                  })
                )
              }
            </tbody>
          </table>
        </div>
      </div>

      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2">
        <nav>
          <div className="nav nav-tabs" id="nav-tab" role="tablist">
            <a className="nav-item nav-link active" id="nav-AuthorizedDeductions-tab" data-toggle="tab" href="#nav-AuthorizedDeductions" role="tab" aria-controls="nav-AuthorizedDeductions" aria-selected="true">
              <strong>Gastos considerados</strong>
            </a>
          </div>
        </nav>
      </div>

      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2">
        <div className="tab-content" id="nav-tabContent">
          <div className="tab-pane fade show active" id="nav-AuthorizedDeductions" role="tabpanel" aria-labelledby="nav-AuthorizedDeductions-tab">
            <AuthorizaedDeductionsComplementIncomes
            // complementAutorized={complementAutorized}
            // authorizations={authorizations}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
