import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TextField } from '@material-ui/core';
import { ModalContext } from '../../../../modal/ModalContext';
import { ThirdPartyForm } from '../../thirdParties/ThirdPartyForm';
import { cfdiUsesStartLoading } from '../../../../../actions/catalogs/cfdiUses';
import { thirdPartiesStartLoading } from '../../../../../actions/thirdParties';
import { useParams } from 'react-router-dom';

export const RecipientDetails = ({ formData, handleInputChange, setFormValues }) => {

  const {taxpayerId} = useParams();

  const { handleModal } = useContext( ModalContext );

  const { cfdiUses } = useSelector(state => state.cfdiUse);
  const { thirdParties } = useSelector(state => state.thirdParty);

  const { cfdiUseId, thirdPartyId } = formData;

  const dispatch = useDispatch();

  useEffect(() => {
    if (cfdiUses.length === 0) {
      dispatch(cfdiUsesStartLoading())
    }
  }, [cfdiUses, dispatch]);

  useEffect(() => {
    if( cfdiUses.length > 0 ){
      setFormValues(prevState => {
        return{
          ...prevState,
          cfdiUseId: cfdiUses[0].id
        }
      })
    }
  }, [ cfdiUses, setFormValues ]);

  useEffect(() => {
    dispatch(thirdPartiesStartLoading(taxpayerId));
  }, [dispatch, taxpayerId])

  useEffect(() => {
    if( thirdParties.length > 0 ){
      setFormValues(prevState => {
        return{
          ...prevState,
          thirdPartyId: thirdParties[0].id
        }
      })
    }
  }, [setFormValues, thirdParties ])

  return (
    <div className="col-md-12">
      <h5 className="card-header">Datos del Receptor</h5>
      <div className="card-body pt-0">
        <div className="row">
          <div className="col-md-6 mt-4">
            <TextField
              select
              variant="outlined"
              label="Uso CFDI"
              size="small"
              fullWidth={true}
              name="cfdiUseId"
              value={ cfdiUseId }
              onChange={handleInputChange}
              SelectProps={{
                native: true,
              }}
            >
              {
                cfdiUses.length > 0 ? (
                  cfdiUses.map((cfdiUse) => {
                    return(
                      <option key={ cfdiUse.id } value={ cfdiUse.id }> { cfdiUse.key } - { cfdiUse.description }</option>
                    )
                  })
                )
                :
                (
                  <option value={0}>No hay registros</option>
                )
              }
            </TextField>
          </div>

          <div className="col-md-6 mt-4 mb-3">
            <TextField
              select
              variant="outlined"
              label="Cliente"
              size="small"
              fullWidth={true}
              name="thirdPartyId"
              value={ thirdPartyId }
              onChange={handleInputChange}
              SelectProps={{
                native: true,
              }}
            >
              {
                thirdParties.length > 0 ? (
                  thirdParties.map((thirdParty, index) => {
                    return(
                      <option key={ index } value={ thirdParty.id }>
                        { thirdParty.rfc } - { thirdParty.businessName }
                      </option>
                    )
                  })
                )
                :
                (
                  <option value={0}>No hay registros</option>
                )
              }
            </TextField>
            <div className="mt-2">
              <button
                className="btn btn-sm btn-primary"
                onClick={()=> handleModal(
                <ThirdPartyForm
                  taxpayerId={taxpayerId}
                />, true)}
              >
                Agregar cliente
              </button>
              
              {/* {thirdParties.length > 0 &&
                (<button
                  className="btn btn-sm btn-warning ml-2"
                >
                  Editar cliente
                </button>)
              } */}

            </div>
          </div>

        </div>
      </div>
    </div>
  )
}
