import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { TextField } from '@material-ui/core';
import { clasificateRegimesInvoicing } from '../../../../../helpers/ClasificateRegimes';

export const IssuerDetails = ({ formData, invoiceHandleInputChange, invoiceSetFormValues }) => {

  const { taxpayerSelected } = useSelector(state => state.taxpayer)

  const { businessName, rfc, regimes, profileRegimes } = taxpayerSelected;

  const { regimeId } = formData;

  const [regimesInvoicing, setRegimesInvoicing] = useState([])

  useEffect(() => {
    if (profileRegimes !== undefined && regimes!== undefined) {
      setRegimesInvoicing(clasificateRegimesInvoicing(regimes, profileRegimes))
    }
  },[profileRegimes, regimes, setRegimesInvoicing]);

  useEffect(() => {
    if(regimesInvoicing.length > 0){
      invoiceSetFormValues(prevState => {
        return{
          ...prevState,
          regimeId: regimesInvoicing[0].id
        }
      })
    }
  }, [invoiceSetFormValues, regimesInvoicing])

  return (
    <div className="col-md-12">
      <h5 className="card-header">Datos del Emisor</h5>
      <div className="card-body pt-0">

        <div className="row">
          <div className="col-md-6 mt-4">
            <TextField
              label="Nombre"
              variant="outlined"
              size="small"
              value={ businessName || '' }
              fullWidth={true}
              disabled
            />
          </div>
          
          <div className="col-md-6 mt-4">
            <TextField
              select
              variant="outlined"
              label="Régimen"
              size="small"
              fullWidth={true}
              name="regimeId"
              value={ regimeId }
              onChange={ invoiceHandleInputChange }
              SelectProps={{
                native: true,
              }}
            >
              { regimesInvoicing.length > 0 ? (
                regimesInvoicing.map((regime, index) => {
                  return(
                    <option key={ index } value={ regime.id }>
                      { regime.satCode } - { regime.officialName }
                    </option>
                  )
                }))
                :
                (
                  <option value={0}>No hay registros</option>
                )
              }
            </TextField>
          </div>

          <div className="col-md-6 mt-4">
            <TextField
              label="RFC"
              variant="outlined"
              size="small"
              name="rfc"
              value={ rfc || '' }
              fullWidth={true}
              disabled
            />
          </div>
        </div>
      </div>
    </div>
  )
}
