import React, { useEffect, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { TextField } from '@material-ui/core';
import ShowInvoices  from './InvoicesTypeIncomes/showInvoices';
import ShowPaidComplement  from './InvoicesTypeComplements/showPaidComplement';
import { useDispatch, useSelector } from 'react-redux';
import { taxpayerStartLoading } from '../../../../actions/taxpayers';
import { useForm } from '../../../../hooks/useForm';
import { taxpayersObtainInvoicing } from '../../../../helpers/taxpayer';

const Index = () => {

  const { taxpayers } = useSelector(state => state.taxpayer)

  const dispatch = useDispatch();

  const { formValues, setFormValues, handleInputChange } = useForm({
    taxpayersInvoicing: [],
    taxpayerSelected: 0
  });

  const { taxpayersInvoicing, taxpayerSelected } = formValues;

  useEffect(() => {
    let taxpayerDefault = 0;
    let taxpayersInvoicingData = [];
    if ( taxpayers.length === 0 ){
      dispatch( taxpayerStartLoading() );
    }

    if(taxpayers.length > 0){
      taxpayersInvoicingData = taxpayersObtainInvoicing(taxpayers);
      taxpayerDefault = taxpayersInvoicingData[0].id;
    }

    setFormValues( prevState =>  {
      return{
        ...prevState,
        taxpayersInvoicing: taxpayersInvoicingData,
        taxpayerSelected: taxpayerDefault
      }
    })
  }, [ dispatch, setFormValues, taxpayers ])

  return (
    <div className="container">
      <div className="row">
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3">
            <h4 className="card-header">Emisión de CFDI</h4>
        </div>
      </div>

      { taxpayersInvoicing.length === 0 ?
        (
          <div className="alert alert-danger col-md-12 text-center" role="alert">
            No existe información de algún contribuyente de facturación
          </div>
        ) 
        :
        (
          <Fragment>
            <div className="col-md-6 ml-0 pl-0">
              <TextField
                select
                variant="outlined"
                label="Contribuyentes"
                size="small"
                fullWidth={true}
                name="taxpayerSelected"
                SelectProps={{
                  native: true,
                }}
                value={ taxpayerSelected }
                onChange={ handleInputChange }
              >
                {
                  taxpayersInvoicing.map((taxpayer, index) => {
                    return(
                      <option
                        key={ index }
                        value={ taxpayer.id }
                      >
                        { taxpayer.rfc } - { taxpayer.businessName }
                      </option>
                    )
                  })
                }
              </TextField>
            </div>

            <nav className="mt-4">
              <div className="nav nav-tabs" id="nav-tab" role="tablist">
                <a className="nav-item nav-link active" id="nav-invoices-tab" data-toggle="tab" href="#nav-invoices" role="tab" aria-controls="nav-invoices" aria-selected="true">
                  Facturas
                </a>
                <a className="nav-item nav-link" id="nav-information-tab" data-toggle="tab" href="#nav-information" role="tab" aria-controls="nav-information" aria-selected="true">
                  Recibo de Honorarios
                </a>
                <a className="nav-item nav-link" id="nav-information-tab" data-toggle="tab" href="#nav-information" role="tab" aria-controls="nav-information" aria-selected="true">
                  Recibo de Arrendamiento
                </a>
                <a className="nav-item nav-link" id="nav-information-tab" data-toggle="tab" href="#nav-information" role="tab" aria-controls="nav-information" aria-selected="true">
                  Recibos de pago
                </a>
                <a className="nav-item nav-link" id="nav-paidcomplement-tab" data-toggle="tab" href="#nav-paidcomplement" role="tab" aria-controls="nav-information" aria-selected="true">
                  Complemento de pago
                </a>
              </div>
            </nav>

            <div className="tab-content" id="nav-tabContent">
              <div className="tab-pane fade show active" id="nav-invoices" role="tabpanel" aria-labelledby="nav-invoices-tab">
                <ShowInvoices taxpayerId={ parseInt(taxpayerSelected) } />
              </div>
              <div className="tab-pane fade" id="nav-paidcomplement" role="tabpanel" aria-labelledby="nav-paidcomplement-tab">
                <ShowPaidComplement taxpayerId={ parseInt(taxpayerSelected) } />
              </div>
            </div>
          </Fragment>
        )
      }
    </div>
  );
}

export default withRouter(Index);