import React from 'react';

function EyeClosedIcon(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={24}
            height={24}
            viewBox="0 0 24 24"
            {...props}
        >
            <path d="M12 4.254C6.833 4.254 2.676 8.146.809 12 2.676 15.854 6.833 19.746 12 19.746c5.167 0 9.324-3.892 11.191-7.746C21.324 8.146 17.167 4.254 12 4.254zm0 11.492c-3.306 0-5.992-2.686-5.992-5.992S8.694 4.762 12 4.762s5.992 2.686 5.992 5.992-2.686 5.992-5.992 5.992zm0-8.486c-.696 0-1.258.562-1.258 1.258s.562 1.258 1.258 1.258c.695 0 1.258-.562 1.258-1.258s-.563-1.258-1.258-1.258z" />
        </svg>
    );
}

export default EyeClosedIcon;
