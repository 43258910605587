import { TableBody } from '@material-ui/core';
import { Table, TableCell, TableHead, TableContainer, TableRow, TextField } from '@material-ui/core';
import moment from 'moment';
import React, { useContext, useEffect, useState, useRef } from 'react';
import * as ROUTES from '../../../../components/constants/Routes';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { regimesStartLoading } from '../../../../actions/catalogs/regimes';
import { searchtaxpayerAssisted, taxpayerAssistedFinish, taxpayerAssitedsStartLoading, taxpayerStatusLoading } from '../../../../actions/taxpayers';
import { obtainMonths, obtainYears } from '../../../../helpers/declarationPeriod';
import { Pagination } from '../../../../helpers/tables/Pagination';
import { useForm } from '../../../../hooks/useForm';
import { DETAILS_SUMMARY } from '../../../constants/Routes';
import { InputSearching } from '../../../../helpers/tables/InputSearching';
import { prepareTaxpayers } from '../../../../helpers/accounting/prepareDataToSearch';
import { accountersStartLoading } from '../../../../actions/catalogs/accounters';
import { CheckboxFilter } from '../../../../helpers/tables/CheckboxFilter';
import { ViewCommentsModal } from './ViewCommentsModal';
import { ModalContext } from '../../../modal/ModalContext';
import { ChangeAccountantModal } from './ChangeAccountantModal';
import { ChangeDateServiceModal } from './ChangeDateServiceModal';
import { CSVLink } from 'react-csv';
import { ChangePasswordSatModal } from './ChangePasswordSatModal';
import { ChangePackageToPresentModal } from '../../customer/accounting/annualSummary/detailedSummary/informationReport/ChangePackageToPresentModal';
import { ConstancyOpinion } from '../../customer/taxFiles/ConstancyOpinion';
import { loadingModalMsn } from '../../../../helpers/UseSwal';
import { UseSwal } from '../../../../hooks/UseSwal';
import { updateStatusReport } from '../../../../actions/accounting/preAuthorizedReports';
import { productToSalesStartLoading } from '../../../../actions/catalogs/productToSales';
import UploadCSVModal from '../../crm/sales/modals/UploadCSVModal/UploadCSVModal';

export const AdminReportsIndexScreen = () => {

  const headers = [
    { label: 'Contribuyente', key: 'fullName' },
    { label: 'Contraseña Sat', key: 'profile.passwordSAT' },
    { label: 'Correo', key: 'email' },
    { label: 'Telefono', key: 'user.phoneMobile' },
    { label: 'RFC', key: 'profile.rfc' },
    { label: 'Contador', key: 'accountantFullName' },
    { label: 'Vendedor', key: 'sellerFullName' },
    { label: 'Vigencia inicio', key: 'sale.startServiceAt' },
    { label: 'Vigencia fin', key: 'sale.endServiceAt' },
    { label: 'Regimenes', key: 'satCodes' },
  ]

  const headersJob = [
    { label: 'ProfileId', key: 'profile.id' },
    { label: 'Contribuyente', key: 'profile.businessName' },
    { label: 'RFC', key: 'profile.rfc' },
    { label: 'Regimenes', key: 'satCodes' },
    { label: 'Contraseña Sat', key: 'profile.passwordSAT' },
    { label: 'Contador', key: 'accountantFullName' },
    { label: 'Periodo inicio', key: '' },
    { label: 'Periodo fin', key: '' },
  ]

  const headersReportStatus = [
    { label: 'id', key: 'id' },
    { label: 'Contribuyente', key: 'bussinessName' },
    { label: 'RFC', key: 'rfc' },
    { label: 'Regimen', key: 'regimeName' },
    { label: 'Status', key: 'status' },
    { label: 'Periodo', key: 'period' },
    { label: 'Contador', key: 'accountant' }
  ]

  const csvLink = useRef();
  const csvLinkReport = useRef();
  const { handleModal } = useContext(ModalContext);
  const { changeStatusReportModal } = UseSwal();

  const { accounters } = useSelector(state => state.accounter);
  const { isBoss } = useSelector(state => state.auth.user);
  const { taxpayersAssisteds, taxpayeAssitedFinish, searchTaxpayerAssitedFinish, dashboard, byAccountant, taxpayerReportStatus } = useSelector(state => state.taxpayer);
  const { regimes } = useSelector(state => state.regime);
  const { productToSales } = useSelector(state => state.productToSale);

  const [newTaxpayers, setNewTaxpayers] = useState([]);
  const [taxpayersJob, setTaxpayersJob] = useState([]);
  const [taxpayersSearch, setTaxpayersSearch] = useState([]);
  const [taxpayerAccount, setTaxpayerAccount] = useState([]);
  const [taxpayerPlan, setTaxpayerPlan] = useState([]);
  const [dataDash, setDataDash] = useState({
    presented: 0,
    toPresented: 0,
    authorized: 0,
    pendingToAuthorized: 0
  });

  const dispatch = useDispatch();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [firstLoad, setFirstLoad] = useState(true)
  const [searchAssistedFinish, setSearchAssistedFinish] = useState(false)

  const { formValues, handleInputChange, setFormValues } = useForm({
    monthsOb: obtainMonths(),
    monthSelected: '',
    years: obtainYears().reverse(),
    yearselected: '',
    accounterId: 0,
    satCode: 0,
    taxpayerRfc: '',
    planSelect: 0,
    activeDate: '',
    filterStatus: 'null'
  });

  const { monthSelected, monthsOb, years, yearselected, accounterId, taxpayerRfc, satCode, planSelect, activeDate, filterStatus } = formValues;

  useEffect(() => {
    if (accounters.length === 0) {
      dispatch(accountersStartLoading())
    }
  }, [accounters, dispatch]);

  useEffect(() => {
    const date = new Date();
    setFormValues(prevState => {
      return {
        ...prevState,
        monthSelected: date.getMonth() + 1,
        yearselected: date.getFullYear()
      }
    })
  }, [setFormValues]);

  useEffect(() => {
    if (taxpayersAssisteds.length > 0 && regimes.length > 0) {
      setNewTaxpayers(prepareTaxpayers(taxpayersAssisteds, regimes));
      setTaxpayersSearch(prepareTaxpayers(taxpayersAssisteds, regimes));
      setDataDash(dashboard)
    }
  }, [taxpayersAssisteds, regimes]);

  useEffect(() => {
    if (regimes.length === 0) {
      dispatch(regimesStartLoading());
    }
  }, [dispatch, regimes]);

  useEffect(() => {
    if (taxpayersAssisteds.length === 0 && firstLoad) {
      dispatch(taxpayerAssitedsStartLoading());
      setFirstLoad(false)
    }
  }, [dispatch, taxpayersAssisteds]);

  useEffect(() => {
    if (searchTaxpayerAssitedFinish && searchAssistedFinish) {
      setTaxpayersSearch(prepareTaxpayers(taxpayeAssitedFinish, regimes))
      dispatch(searchtaxpayerAssisted())
    }
  }, [searchTaxpayerAssitedFinish, taxpayerRfc])

  useEffect(() => {
    if (taxpayerReportStatus.length > 0) {
      setTimeout(() => {
        csvLinkReport.current.link.click()
      }, 2000);
    }
  }, [taxpayerReportStatus])

  const handleChangeRegime = (assistedId, e) => {

    const columnsToSearch = ['assistedId'];
    const value = parseInt(e.target.value);

    const regime = regimes.filter(e => (e.id === value))[0];
    const newAssisteds = [];

    taxpayersSearch.forEach(taxpayer => {
      if (parseInt(taxpayer.assistedId) === parseInt(assistedId)) {
        taxpayer['regimeSelectedId'] = regime.id;
        taxpayer['regimeSatCode'] = regime.satCode;
        taxpayer.profileRegimes.forEach(r => {
          if (parseInt(taxpayer.regimeSelectedId) === r.regimeId) {
            taxpayer['estadisticas'] = r.statics
          }
        })
        newAssisteds.push(taxpayer)
      } else {
        newAssisteds.push(taxpayer)
      }
    })
    setTaxpayersSearch(newAssisteds)
    // CheckboxFilter(columnsToSearch, newAssisteds, setTaxpayersSearch, newTaxpayers,setTaxpayerAccount)
  }
  useEffect(() => {
    if (productToSales.length === 0) {
      dispatch(productToSalesStartLoading());
    }
  }, [dispatch, productToSales]);

  const handleChangeAccountant = (e) => {
    const columnsToSearch = ['accountantId'];
    const dataToSearch = e.target.value;

    setFormValues(prevState => {
      return {
        ...prevState,
        accounterId: parseInt(dataToSearch)
      }
    })
    if (dataToSearch == 0) {
      setDataDash(dashboard)
    } else {
      const accounterSelected = byAccountant.filter(e => (e.id == dataToSearch))[0];
      if (accounterSelected) {
        setDataDash(accounterSelected)
      } else {
        let dash = {
          presented: 0,
          toPresented: 0,
          authorized: 0,
          pendingToAuthorized: 0,
        };
        setDataDash(dash)
      }
    }
    CheckboxFilter(columnsToSearch, dataToSearch, setTaxpayersSearch, newTaxpayers, setTaxpayerAccount)
  }

  const handleChangePlan = (e) => {
    const columnsToSearch = ['productId'];
    let dataToSearch = e.target.value;
    setFormValues(prevState => {
      return {
        ...prevState,
        planSelect: parseInt(dataToSearch)
      }
    })

    if (dataToSearch == 0) {
      setDataDash(dashboard)
    } else {
      const newAssisteds = newTaxpayers.filter(e => (e.productId === dataToSearch));
      setDataDash(newAssisteds)
    }
    CheckboxFilter(columnsToSearch, dataToSearch, setTaxpayersSearch, newTaxpayers, setTaxpayerPlan)
  }


  const searchAssisted = () => {
    if (taxpayerRfc) {
      setSearchAssistedFinish(true)
      dispatch(taxpayerAssistedFinish(taxpayerRfc))
    }
    else {
      setSearchAssistedFinish(false)
      setTaxpayersSearch(prepareTaxpayers(taxpayersAssisteds, regimes));
    }
  }

  const changeConsideredTaxpayer = (newTaxpayer) => {

    const newAuthorize = newTaxpayers.map(
      taxpayer => (taxpayer.id == newTaxpayer.id) ?
        {
          ...taxpayer,
          job: !newTaxpayer.job
        }
        :
        taxpayer
    );

    setTaxpayersSearch(newAuthorize)
    setNewTaxpayers(newAuthorize)

  }

  const consideredAllTaxpayer = () => {
    const newAuthorize = [];
    newTaxpayers.forEach(taxpayer => {
      const prepareData = {
        ...taxpayer,
        job: !taxpayer.job
      }
      newAuthorize.push(prepareData);
    });
    setTaxpayersSearch(newAuthorize)
    setNewTaxpayers(newAuthorize)
  }

  const downloadJob = () => {

    loadingModalMsn(true, "Generando reporte")
    const newTaxpayersJob = newTaxpayers.filter(
      e => (e.job === true)
    )
    setTaxpayersJob(newTaxpayersJob)
    setTimeout(() => {
      loadingModalMsn(false)
      csvLink.current.link.click()
    }, 2000);

  }

  useEffect(() => {
    let newData = [];
    if (satCode != 0 && taxpayerAccount.length > 0) {
      taxpayerAccount.forEach((original) => {
        if (original.satCodes.includes(satCode.toString())) {
          newData.push(original)
        }
      })
      setTaxpayersSearch(newData)
    } else {
      if (taxpayerAccount.length > 0) {
        setTaxpayersSearch(taxpayerAccount)
      } else {
        if (satCode == 0) {
          setTaxpayersSearch(newTaxpayers)
        } else {
          let data = [];
          newTaxpayers.forEach((original) => {
            if (original.satCodes.includes(satCode.toString())) {
              data.push(original)
            }
          })
          setTaxpayersSearch(data)
        }
      }
    }
  }, [accounterId, satCode])

  useEffect(() => {
    let newData = [];
    if (activeDate.length > 5) {
      taxpayerPlan.forEach((original) => {
        if (original.activeDate.includes(activeDate)) {
          newData.push(original)
        }
      })
      setTaxpayersSearch(newData)
    } else {
      setTaxpayersSearch(taxpayerPlan)
    }
  }, [activeDate, planSelect])

  const downloadReportStatus = () => {
    dispatch(taxpayerStatusLoading(monthSelected, yearselected, satCode, filterStatus))
  }

  return (
    <div>
      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3">
        <div class="row">
          <div class="col-sm-3 ">
            <div class="card bg-secondary">
              <div class="card-body">
                <h5 class="card-title">Por Presentar</h5>
                <p class="card-text">{dataDash.toPresented - dataDash.presented}</p>
              </div>
            </div>
          </div>
          <div class="col-sm-3">
            <div class="card bg-primary">
              <div class="card-body">
                <h5 class="card-title">Declaraciones Presentadas</h5>
                <p class="card-text align-center">{dataDash.presented}</p>
              </div>
            </div>
          </div>
          <div class="col-sm-3">
            <div class="card bg-warning">
              <div class="card-body">
                <h5 class="card-title">Pendientes Por Autorizar</h5>
                <p class="card-text">{dataDash.pendingToAuthorized}</p>
              </div>
            </div>
          </div>
          <div class="col-sm-3">
            <div class="card bg-success" >
              <div class="card-body">
                <h5 class="card-title">Reportes Autorizados</h5>
                <p class="card-text">{dataDash.authorized}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="card-header mt-2">
          <div className="row d-flex justify-content-between">
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <h5>Contribuyentes asistidos</h5>
            </div>
            <div className="col-md-2 float-right align-items-center">
              <button className="btn btn-sm btn-primary " target="_blank"
                data-toggle="tooltip" data-placement="top" title="Reporte de status" onClick={downloadReportStatus}>Reporte de status</button>
              <CSVLink
                data={taxpayerReportStatus}
                headers={headersReportStatus}
                filename='contribuyentes-status.csv'
                className='hidden m-5'
                ref={csvLinkReport}
                target='_blank'
              />
            </div>
            <div className="col-md-2 float-right">
              <button className="btn btn-sm btn-info m-10" target="_blank"
                data-toggle="tooltip" data-placement="top" title="Contribuyentes a trabajar" onClick={downloadJob}>Contribuyentes a trabajar</button>
              <CSVLink
                data={taxpayersJob}
                headers={headersJob}
                filename='contribuyentes-a-trabajar.csv'
                className='hidden'
                ref={csvLink}
                target='_blank'
              />
            </div>
            <div className="col-md-2">
              <CSVLink data={taxpayersSearch} headers={headers} filename={"contribuyentes-activos.csv"} className="btn btn-success">
                Descargar CSV
              </CSVLink>
            </div>
          </div>
        </div>
      </div>

      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-2">
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-md-3">
                <TextField select variant="outlined" label="Mes" size="small"
                  fullWidth={true} name="monthSelected"
                  value={monthSelected} onChange={handleInputChange}
                  SelectProps={{ native: true, }}>
                  {
                    monthsOb.map((month, index) => {
                      return (
                        <option key={index} value={month.month + 1}>
                          {month.label}
                        </option>
                      )
                    })
                  }
                </TextField>
              </div>
              <div className="col-md-2">
                <TextField select variant="outlined" label="Año" size="small"
                  fullWidth={true} name="yearselected"
                  value={yearselected} onChange={handleInputChange}
                  SelectProps={{ native: true, }}>
                  {
                    years.map((yearItem, index) => {
                      return (
                        <option key={index} value={yearItem}>
                          {yearItem}
                        </option>
                      )
                    })
                  }
                </TextField>
              </div>
              <div className='col-md-2'>
                <TextField
                  select
                  variant="outlined"
                  label="Status"
                  size="small"
                  fullWidth={true}
                  name="filterStatus"
                  value={filterStatus}
                  onChange={handleInputChange}
                  SelectProps={{
                    native: true,
                  }}
                >
                  <option value={'null'} >Todos</option>
                  <option value={'En Proceso'} >En Proceso</option>
                  <option value={'Por Autorizar'} >Por Autorizar</option>
                  <option value={'Por Presentar'} >Por Presentar</option>
                  <option value={'Presentada'} >Presentadas</option>
                </TextField>
              </div>
              {/* Input para buscar los contribuyentes que ya no estan activos */}
              <div className="col-md-5">
                <TextField
                  variant="outlined" label="Asistidos terminados" size="small"
                  name="taxpayerRfc"
                  className="float-right"
                  onChange={handleInputChange}
                  // onBlur={(e) => {
                  //   searchAssisted()
                  //  }}
                  onKeyPress={(ev) => {
                    if (ev.key === 'Enter') {
                      searchAssisted()
                    }
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3">
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-md-3">
                <TextField
                  select
                  variant="outlined"
                  label="Contador"
                  size="small"
                  fullWidth={true}
                  name="accounterId"
                  value={accounterId}
                  onChange={handleChangeAccountant}
                  SelectProps={{
                    native: true,
                  }}
                >
                  <option value={0}>Todos</option>
                  {
                    accounters.map((accounter) => {

                      return (
                        <option key={accounter.id} value={accounter.id}> {accounter.fullName.toUpperCase()}</option>
                      )
                    })
                  }
                </TextField>
              </div>
              <div className="col-md-2">
                <TextField
                  select
                  variant="outlined"
                  label="Regimen"
                  size="small"
                  fullWidth={true}
                  name="satCode"
                  value={satCode}
                  onChange={handleInputChange}
                  SelectProps={{
                    native: true,
                  }}
                >
                  <option value={0}>Todos</option>
                  {
                    regimes.map((regimen) => {

                      return (regimen.accounting &&
                        <option key={regimen.id} value={regimen.satCode}>   {regimen.satCode} - {regimen.officialName} </option>
                      )
                    })
                  }
                </TextField>
              </div>
              <div className="col-md-2">
                <TextField
                  select
                  variant="outlined"
                  label="Planes"
                  size="small"
                  fullWidth={true}
                  name="planSelect"
                  value={planSelect}
                  onChange={handleChangePlan}
                  SelectProps={{
                    native: true,
                  }}
                >
                  <option value={0}>Todos</option>
                  {
                    productToSales.map((product) => {
                      return (product.isAssisted &&
                        <option key={product.id} value={product.id}> {product.description} </option>
                      )
                    })
                  }
                </TextField>
              </div>


              <div className="col-md-2">
                {
                  accounterId !== 0 && isBoss &&
                  (
                    <button className="btn btn-sm btn-info" target="_blank"
                      data-toggle="tooltip" data-placement="top" title="Reasignar contador"
                      onClick={() => handleModal(
                        <ChangeAccountantModal
                          accounters={accounters}
                          accountantId={accounterId}
                          packagesToChange={taxpayersSearch}
                          handleChangeAccountant={handleChangeAccountant}
                        />
                      )}>Reasignar</button>
                  )
                }
              </div>
              <div className="col-md-3">
                <TextField
                  variant="outlined" label="Fecha Activación" size="small"
                  name="activeDate"
                  fullWidth={true}
                  onChange={handleInputChange}
                />
              </div>


            </div>
            <div className="row">
              <div className='col-md-9 mt-2'>

              </div>
              <div className="col-md-3 mt-2">
                <InputSearching
                  columnsToSearch={['email', 'fullName', 'rfc']}
                  setDataChange={setTaxpayersSearch}
                  originals={newTaxpayers}
                />
              </div>
            </div>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell className='row col-2' align="center">
                      <div className=' '>
                        <strong> Seleccionar todo</strong>
                      </div>
                      <div className="form-check ">
                        <input
                          className="form-check-input float- left"
                          type="checkbox"
                          name="typeOwner"
                          defaultChecked={false}
                          onClick={() => consideredAllTaxpayer()}
                        />
                      </div>
                      <br />
                    </TableCell>
                    <TableCell align="center"><strong>Opinión de cumplimiento</strong></TableCell>
                    <TableCell align="center"><strong>Cliente</strong></TableCell>
                    <TableCell align="center"><strong>Contribuyente</strong></TableCell>
                    <TableCell align="center"><strong>Presentación automatica</strong></TableCell>
                    <TableCell align="center"><strong>Contador</strong></TableCell>
                    <TableCell align="center"><strong>Vendedor</strong></TableCell>
                    <TableCell align="center"><strong>Vigencia</strong></TableCell>
                    <TableCell align="center"><strong>Producto</strong></TableCell>
                    <TableCell align="center"><strong>Contraseña SAT</strong></TableCell>
                    <TableCell align="center"><strong>E-Firma</strong></TableCell>
                    <TableCell align="center"><strong>Regimen a Trabajar</strong></TableCell>
                    <TableCell align="center"><strong>Acciones</strong></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    taxpayersSearch.length === 0 ?
                      (<TableRow>
                        <TableCell colSpan="10">
                          <div className="alert alert-info text-center" role="alert">
                            No hay contribuyentes con dichos filtros
                          </div>
                        </TableCell>
                      </TableRow>)
                      :
                      (
                        taxpayersSearch.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((assisted, index) => {
                          return (
                            <TableRow key={index}>
                              <TableCell align="center">
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    name="typeOwner"
                                    defaultChecked={assisted.job}
                                    checked={assisted.job}
                                    onChange={changeConsideredTaxpayer.bind(this, assisted)}
                                  />
                                </div>
                              </TableCell>
                              <TableCell>
                                <button
                                  className="btn btn-sm btn-info"
                                  target="_blank"
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  style={{
                                    backgroundColor:
                                      assisted.profile.statusOC === 'POSITIVO'
                                        ? '#5353ec'
                                        : assisted.profile.statusOC === 'NEGATIVO'
                                          ? '#ec5353'
                                          : 'gray',
                                    cursor:
                                      assisted.profile.statusOC === null ? 'not-allowed' : 'pointer',
                                  }}
                                  disabled={assisted.profile.statusOC === null}
                                  onClick={() => {
                                    if (assisted.profile.statusOC) {
                                      window.location.href = ROUTES.OPINION_CONSTANCE(assisted.profile.rfc);
                                    }
                                  }}
                                >
                                  {assisted.profile.statusOC === null
                                    ? 'NO DISPONIBLE'
                                    : assisted.profile.statusOC}
                                </button>
                                <p style={{ marginTop: '5px' }}>
                                  <span style={{ fontStyle: 'italic' }}>Fecha de obtención:</span>
                                  <br />
                                  <span style={{ fontWeight: 'bold' }}>
                                    {new Date(assisted.profile.lastDownloadOc).toLocaleDateString()}
                                  </span>
                                </p>

                              </TableCell>

                              <TableCell align="center">
                                {assisted.fullName}<br />
                                {assisted.email}<br />
                                {assisted.user.phoneNumber}
                              </TableCell>

                              <TableCell align="center">
                                {assisted.profile.businessName}<br />
                                {assisted.profile.rfc}<br />
                              </TableCell>
                              <TableCell align="center">
                                {
                                  assisted.profile.automaticStatement ?
                                    (
                                      <span className="badge badge-success">Si</span>
                                    )
                                    :
                                    (
                                      <span className="badge badge-danger">No</span>
                                    )
                                }
                              </TableCell>
                              <TableCell align="center">
                                {assisted.accountant.name} {assisted.accountant.lastName} {assisted.accountant.motherLastName}<br />
                                {isBoss &&
                                  <button className="btn btn-sm btn-info" target="_blank"
                                    data-toggle="tooltip" data-placement="top" title="Reasignar contador"
                                    onClick={() => handleModal(
                                      <ChangeAccountantModal
                                        accounters={accounters}
                                        accountantId={assisted.accountant.id}
                                        packagesToChange={[assisted]}
                                      />
                                    )}>Reasignar</button>
                                }
                              </TableCell>
                              <TableCell align="center">
                                <strong>{assisted.sellerFullName}</strong><br />
                              </TableCell>
                              <TableCell align="center">
                                <strong>{moment(assisted.sale.startServiceAt).format('YYYY-MM-DD')}</strong> al<br />
                                <strong>{moment(assisted.sale.endServiceAt).format('YYYY-MM-DD')}</strong><br />
                                {/* <button className="btn btn-sm btn-primary" target="_blank"
                                  data-toggle="tooltip" data-placement="top" title="Editar fecha"
                                  onClick={() => handleModal(
                                    <ChangeDateServiceModal
                                      sale={assisted.sale}
                                    />
                                  )}>
                                  Editar fecha
                                </button> */}

                                <button className="badge badge-primary"
                                  onClick={() => handleModal(
                                    <ChangeDateServiceModal
                                      sale={assisted.sale}
                                    />
                                  )}>
                                  Editar fecha
                                </button>
                              </TableCell>
                              <TableCell align="center">
                                <strong>{assisted.product}</strong><br />
                                <strong>{moment(assisted.sale.activateAt).format('YYYY-MM-DD')}</strong>
                              </TableCell>
                              <TableCell align="center">
                                {
                                  assisted.profile.passwordSAT ?
                                    (
                                      <span>{assisted.profile.passwordSAT}</span>
                                    )
                                    :
                                    (
                                      <span>"Sin contraseña"</span>
                                    )
                                }
                                {
                                  assisted.profile.passwordSAT ?
                                    (
                                      <button className="badge badge-success" onClick={() => handleModal(
                                        <ChangePasswordSatModal
                                          profile={assisted.profile}
                                        />
                                      )}>Cambiar contraseña</button>
                                    )
                                    :
                                    (
                                      <button className="badge badge-danger" onClick={() => handleModal(
                                        <ChangePasswordSatModal
                                          profile={assisted.profile}
                                        />
                                      )}>Sin contraseña</button>
                                    )
                                }
                              </TableCell>
                              <TableCell align="center">
                                {
                                  assisted.existFiel ?
                                    (
                                      <span className="badge badge-success">Si</span>
                                    )
                                    :
                                    (
                                      <span className="badge badge-danger">No</span>
                                    )
                                }
                                {<span className="badge badge-info m-1">Total paquete {assisted.estadisticas.toPresent}</span>}
                                {<span className="badge badge-secondary m-1">Por presentar {assisted.estadisticas.toPresent - assisted.estadisticas.presented}</span>}
                                {<span className="badge badge-primary">Presentadas {assisted.estadisticas.presented}</span>}
                              </TableCell>
                              <TableCell align="center">
                                <TextField
                                  label="Regimen" variant="outlined" size="small" autoComplete="off" fullWidth={true}
                                  select
                                  SelectProps={{
                                    native: true,
                                  }}
                                  style={{ width: '150px' }}
                                  className="m-1"
                                  value={assisted.regimeSelectedId}
                                  onChange={handleChangeRegime.bind(this, assisted.assistedId)}
                                >
                                  <option value="0" disabled>Selecciona regimen a trabajar</option>
                                  {
                                    assisted.regimes.map((regime, j) => {
                                      return (
                                        regime &&
                                        <option
                                          key={j}
                                          value={regime.id}
                                        >
                                          {regime.satCode} - {regime.officialName}
                                        </option>

                                      )
                                    })
                                  }

                                </TextField>
                                <TextField
                                  label="Estatus" variant="outlined" size="small" autoComplete="off" fullWidth={true}
                                  select
                                  SelectProps={{
                                    native: true,
                                  }}
                                  style={{ width: '150px' }}
                                  className="m-1"
                                >
                                  {
                                    assisted.estadisticas.semaphores.map((period, j) => {
                                      return (
                                        period &&
                                        <option
                                          key={j}
                                          value={period.id}

                                        >

                                          {period.status} - {period.period} Descarga CFDIS: {moment(period.lastCfdiDownload).format('YYYY-MM-DD')} - Descarga RET: {moment(period.lastRetDownload).format('YYYY-MM-DD')}
                                        </option>
                                      )
                                    })
                                  }
                                </TextField>
                              </TableCell>
                              <TableCell align="center">
                                <Link
                                  to={DETAILS_SUMMARY(assisted.profile.id, assisted.regimeSatCode, yearselected, monthSelected, true)}
                                  className="badge badge-primary m-1"
                                  target="_blank"
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title="Ir a reporte"
                                >
                                  Ir a reporte
                                </Link>

                                {/* <button
                                  className="btn btn-sm btn-secondary m-1" target="_blank"
                                  data-toggle="tooltip" data-placement="top" title="Ver comentarios"
                                  onClick={() => handleModal(
                                    <ViewCommentsModal
                                      comments={assisted.comments}
                                    />
                                  )}
                                >
                                  Ver comentarios
                                </button> */}

                                <button
                                  className="badge badge-secondary"
                                  data-placement="top"
                                  data-toggle="tooltip"
                                  title="Ver comentarios"
                                  onClick={() => handleModal(
                                    <ViewCommentsModal
                                      comments={assisted.comments}
                                    />
                                  )}>comentarios</button>




                                {/* <button className="btn btn-sm btn-warning m-1"
                                  data-toggle="tooltip" data-placement="top" title=" Cambiar Paquete Por Presentar"
                                  onClick={() => handleModal(
                                    <ChangePackageToPresentModal
                                      profileId={assisted.profile.id}
                                      regimeId={assisted.regimeSelectedId}
                                      assistedFinish={searchAssistedFinish}
                                      taxpayer={assisted}
                                    />
                                  )}
                                >
                                  Cambiar paquete por presentar
                                </button> */}
                                <button
                                  className="badge badge-warning"
                                  data-placement="top"
                                  data-toggle="tooltip"
                                  title=" Cambiar Paquete Por Presentar"
                                  onClick={() => handleModal(
                                    <ChangePackageToPresentModal
                                      profileId={assisted.profile.id}
                                      regimeId={assisted.regimeSelectedId}
                                      assistedFinish={searchAssistedFinish}
                                      taxpayer={assisted}
                                    />
                                  )}
                                >Cambiar paquete por presentar</button>
                                {/* <button
                                  className="btn btn-sm btn-success m-1" target="_blank"
                                  data-toggle="tooltip" data-placement="top" title="Agregar archivo"
                                  id="addFile"
                                  onClick={() => handleModal(
                                    <ConstancyOpinion
                                      profileId={assisted.profile.id}
                                    />
                                  )}
                                >
                                  Agregar archivo
                                </button> */}
                                <button
                                  className="badge badge-success"
                                  data-placement="top"
                                  data-toggle="tooltip"
                                  title="Agregar archivo"
                                  onClick={() => handleModal(
                                    <ConstancyOpinion
                                      profileId={assisted.profile.id}
                                    />
                                  )}>Agregar archivo</button>


                                {/* <button data-toggle="tooltip" data-placement="top"
                                  title="Cambiar Status" class="btn btn-sm btn-info m-1"
                                  onClick={() => changeStatusReportModal(updateStatusReport(assisted), assisted.profile.automaticStatement)}>
                                  Cambiar status
                                </button> */}

                                <button
                                  className="badge badge-info m-1"
                                  data-placement="top"
                                  data-toggle="tooltip"
                                  title="Cambiar Status"
                                // onClick={() => changeStatusReportModal(
                                //   updateStatusReport(assisted),
                                //   assisted.profile.automaticStatement)}
                                >
                                  Cambiar status</button>




                                {/* <button
                                  className='btn btn-success m-1'
                                  data-placement="top"
                                  value={assisted.profile.rfc}
                                  onClick={() => handleModal(<UploadCSVModal rfcValue={assisted.profile.rfc} />)}
                                >
                                  <i className="fa fa-file-excel-o"></i>
                                </button> */}
                              </TableCell>
                            </TableRow>
                          )
                        })
                      )
                  }
                </TableBody>
              </Table>
            </TableContainer>
            <Pagination
              data={taxpayersSearch}
              page={page}
              rowsPerPage={rowsPerPage}
              setPage={setPage}
              setRowsPerPage={setRowsPerPage}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
