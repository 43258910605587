import { types } from "../../types/types";

const initialState = {
    activities: []
}

export const activitiesByRegimeReducer = (state = initialState, action ) => {
    switch (action.type) {
        case types.activitiesByRegimen:
            return{
                ...state,
                activities: [ ...action.payload]
            }

        default:
            return state;
    }
}
