import swal from "sweetalert";
import { fetchWithToken } from "../../helpers/fetch";
import { loadingModal } from "../../helpers/UseSwal";
import { types } from "../../types/types";

export const crmReferenceRedPay = (date) => {
  return async(dispatch) => {
    loadingModal(true)
    const resp = await fetchWithToken('api/PaymentGateway/getDataByReference', date, 'POST');
    const body = await resp.json();
    if (body.success){
      dispatch(referenceRedPayStatus(true))
      dispatch(referenceRedPay(body.data));
    }else{
        swal('Error', body.error, 'error');
    }
    loadingModal(false)
  }
}

const referenceRedPay = (reference) => ({
    type: types.referenceRedPay,
    payload: reference
  })

export const crmReferenceRedPayFinish = () => {
return async(dispatch) => {
    dispatch(referenceRedPayStatus(false))
}
}

const referenceRedPayStatus = (flag) => ({
    type: types.referenceRedPayFinish,
    payload: flag
  })

  export const crmReferenceRedPayReset = () =>{
    return async(dispatch) => {
      dispatch(referenceRedPayReset())
  }
  }

  const referenceRedPayReset = () => ({
    type: types.referenceRedPayClear,
  })