export const HOME = '/';
export const LOGIN = '/login';
export const REGISTER = (code = null) => `/registro/${code}`;
export const REGISTER2 = `/registro`;
export const RECUPERATE_PASSWORD = '/recuperar_contraseña';
export const CHANGE_PASSWORD = (token) => `/cambiar_contrasena/${token}`;
export const PRODUCTS = `/productos`;

export const TAXPAYERS = '/contribuyentes';
export const NEW_TAXPAYER = '/contribuyente/agregar';
export const DETAILS_TAXPAYER = (taxpayerId) => `/contribuyente/${taxpayerId}`;

export const INVOICES = '/facturas';
export const NEW_INVOICES = (taxpayerId) => `/factura/${taxpayerId}/nueva`;
export const NEW_PAIDCOMPLEMENT = (taxpayerId) => `/complemento/${taxpayerId}/nueva`;

export const PAYROLL = '/nominas';
export const BILLING = '/facturacionContribuyente';
export const NEW_PAYROLL = '/nomina/nueva';
export const DETAILS_PAYROLL = (payrollId) => `/nomina/editar/${payrollId}`;
export const NEW_EMPLOYEE = '/nomina/empleado/nuevo';
export const DETAILS_EMPLOYEE = (employeeId) => `/nomina/empleado/editar/${employeeId}`;

export const REFERENCE_CODE = '/codigo_referencia';

export const ACCOUNTING = (taxpayerId = null, regimeSatCode = null, year = null) => `/contabilidad/${taxpayerId}/${regimeSatCode}/${year}`;
export const OPINION_CONSTANCE = (rfc = null) => `/contabilidad/opinionCumplimiento/${rfc}`;
export const DETAILS_SUMMARY = (taxpayerId = null, regimeSatCode = null, year = null, month = null, isAdmin = false) => `/contabilidad/${taxpayerId}/${regimeSatCode}/${year}/detalle-mensual/${month}/${isAdmin}`;

export const AUTORIZATION_PREREPORT = (token) => `/reportes/${token}`;

export const ADMIN = '/admin';
export const ADMIN_SALES = '/admin/ventas';
export const ADMIN_SALE_UPDATE = (saleId) => `/admin/ventas/editar/${saleId}`
export const ADMIN_ACCOUNTING_SALE_FORM = '/admin/ventas/contabilidad/agregar';
export const ADMIN_ACCOUNTING_SALE_UPDATE = (saleId) => `/admin/ventas/contabilidad/editar/${saleId}`;
export const ADMIN_INVOICING_SALE_FORM = '/admin/ventas/facturas/agregar';
export const ADMIN_INVOICING_SALE_UPDATE = (saleId) => `/admin/ventas/facturas/editar/${saleId}`;
export const ADMIN_ADMINISTRATORS = '/admin/usuarios/administradores';
export const ADMIN_CUSTOMERS = '/admin/usuarios/clientes';

export const ACCOUNT_REPORTS = '/contador/reportes';
export const ACCOUNT_PREREPORTSUPGRADE = '/contador/v2/reportes';
export const ACCOUNT_DOWNLOADXML = '/contador/v2/downloadXML';
export const ACCOUNT_ADITIONALPROCEDURES = '/contador/v2/tramites_adicionales';
export const CATALOG_EXPENSE_CLASSICATION = '/catalogos/clasificacion_gastos';
export const FORM_EXPENSE_CLASSIFICATION = (expenseId) => `/clasificacion/agregar`;
export const FORM_EXPENSE_CLASSIFICATION_UPDATE = (expenseId) => `/clasificacion/editar/${expenseId}`;
export const FORM_PRESALE = '/presale/registrar';
export const FORM_PRESALE_PASSAT = (token) => `/presale/${token}/password_sat`;
export const FORM_PRESALE_FILES = (token) => `/presale/${token}/archivos_ciec`;
export const FORM_PRESALE_TAXPAYER = (token) => `/presale/${token}/informacion_contribuyente`;
export const FORM_PRESALE_PRODUCT = (token) => `/presale/${token}/productos`;
export const FORM_PRESALE_PAYMENT = (token) => `/presale/${token}/pago`;
export const FORM_PRESALE_DETAILS = (token) => `/presale/${token}/detalles`;

// ruta para la configuracion de catalogos
export const VIEW_CONFIG_CATALOGS = '/configuracion/regimenes'
export const VIEW_CONFIG_ACTIVITIES = (idRegimen) => `/configuracion/regimenes/${idRegimen}/actividades`;
export const VIEW_CONFIG_NEWS = `/configuracion/regimenes/noticias`;
export const VIEW_CONFIG_CLASSIFY = (idRegimen, idActivity) => `/configuracion/regimenes/${idRegimen}/actividades/${idActivity}/clasificacion`


export const CRM_SALES = `/ventas`;
export const CRM_DIAGNOSTIC = '/ventas/v2/diagnostico';
export const CRM_PAYMENTVALIDATE = '/validatepayment';
//export const CRM_SALES_FORM = '/ventas/agregar';
export const CRM_SALES_PRODUCTS = '/ventas/agregar_productos';
export const CRM_SALES_UPDATE_FORM = (saleId) => `/ventas/editar/${saleId}`;

// export const CRM_RENOVATIONS = '/renovaciones';
export const CRM_ACCOUNTING_RENOVATIONS_NEW = '/renovaciones/contabilidad/agregar';
export const CRM_ACCOUNTING_RENOVATIONS_UPDATE = (saleId) => `/renovaciones/contabilidad/editar/${saleId}`;

export const CRM_INVOICING_RENOVATIONS_NEW = '/renovaciones/facturacion/agregar';
export const CRM_INVOICING_RENOVATIONS_UPDATE = (saleId) => `/renovaciones/facturacion/editar/${saleId}`;

export const ADMIN_REPORTS = '/admin/reportes';

export const METRIC = '/metricas';
