import { TextField } from '@material-ui/core'
import React, { Fragment } from 'react'
import { useSelector } from 'react-redux';
import { LogoContabilizate } from './LogoContabilizate'

export const DetailsPromotion = ( {presale} ) => {
    
    const { name, message, urlImagen, discount } = presale 
  return (
    <Fragment>
        <div className='col-12 mt-2'>
            <div className='row'>
                <div className="col-12 mb-3 text-center">
                <LogoContabilizate />
                    <h5><b> { `¡Felicidades ${ name }!`} </b></h5>
                </div>
            </div>
            <div className='row'>
                <div className="col-12 mb-3 text-center">
                    <label> { message } </label><br/>
                    <label className='text-success'> { discount }</label> 
                    <div className="col-12">
                        <img  className="text-center" height="280" src={ urlImagen } />
                    </div>
                </div>
            </div>
        </div>
</Fragment>
  )
}
