import React, { Fragment, useState, useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormControl, FormHelperText, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import validator from 'validator';
import { useHistory } from 'react-router';
import { useForm } from '../../../../../hooks/useForm'
import { LogoContabilizate } from './LogoContabilizate';
import { AvisoPrivacidad } from './AvisoPrivacidad';
import { startRegisterPreSale, updateEmailToken, updateInfoPresales } from '../../../../../actions/crm/preSales';
import { validateRfcValid } from '../../../../../helpers/validateForm';
import { CRM_SALES } from '../../../../constants/Routes';
import { socialMediaTypeActives } from '../../../../../actions/catalogs/socialMediaTypes';
import { useParams } from 'react-router';
import { ModalContext } from '../../../../modal/ModalContext';
import { EmailToken } from './EmailToken';

export const PreSaleForm = () => {

  let history = useHistory();

  const { handleModal } = useContext(ModalContext);
  const dispatch = useDispatch();
  const { token } = useParams();

  const { SMTActives } = useSelector(state => state.socialMediaType);
  const [msgErrors, setMsgErrors] = useState({});
  const { crmSalePreSelectedInfo } = useSelector(state => state.crmPreSale);
  const [disabledText, setDisabledText] = useState(false);
  const [editando, setEditando] = useState(false);

  const { formValues, setFormValues, handleEmail } = useForm({
    email: '',
    emailConfirm: '',
    name: '',
    lastName: '',
    motherLastName: '',
    rfc: '',
    phone: '',
    socialMediaId: '',
    tokenEmail: '',
  });

  const { name, lastName, motherLastName, phone, email, emailConfirm, rfc, socialMediaId, tokenEmail } = formValues;


  const [disableName, setDisableName] = useState(false);
  const [disableLastName, setDisableLastName] = useState(false);
  const [disableMotherLastName, setDisableMotherLastName] = useState(false);
  const [disableRfc, setDisableRfc] = useState(false);
  const [disablePhone, setDisablePhone] = useState(false);
  const [disableEmail, setDisableEmail] = useState(false);
  const [disableEmailConfirm, setDisableEmailConfirm] = useState(false);
  const [disableSocialMediaId, setDisableSocialMediaId] = useState(false);

  useEffect(() => {
    dispatch(socialMediaTypeActives())
    if (crmSalePreSelectedInfo) {
      setDisabledText(true);
      setDisableName(crmSalePreSelectedInfo.name ? true : false);
      setDisableLastName(crmSalePreSelectedInfo.lastName ? true : false);
      setDisableMotherLastName(crmSalePreSelectedInfo.motherLastName ? true : false);
      setDisableRfc(crmSalePreSelectedInfo.rfc ? true : false);
      setDisablePhone(crmSalePreSelectedInfo.phone ? true : false);
      setDisableEmail(crmSalePreSelectedInfo.email ? true : false);
      setDisableEmailConfirm(crmSalePreSelectedInfo.email ? true : false);
      setDisableSocialMediaId(crmSalePreSelectedInfo.socialMediaId !== 0);

      setFormValues(prevState => ({
        email: crmSalePreSelectedInfo.email,
        emailConfirm: crmSalePreSelectedInfo.email,
        name: crmSalePreSelectedInfo.name,
        lastName: crmSalePreSelectedInfo.lastName,
        motherLastName: crmSalePreSelectedInfo.motherLastName,
        rfc: crmSalePreSelectedInfo.rfc,
        phone: crmSalePreSelectedInfo.phone,
        socialMediaId: crmSalePreSelectedInfo.socialMediaId
      }));
    }
  }, [crmSalePreSelectedInfo]);

  const [formData, setFormData] = useState({
    token: token,
    newEmail: email || "prueba@gmail.com",
    authorizationToken: 'x'
  });

  const handleRegister = (e) => {
    e.preventDefault();

    if (isFormValid()) {
      dispatch(startRegisterPreSale(formValues, history, CRM_SALES))
    }
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });

    setFormData({ ...formData, [name]: value });
  }

  const handleUpdateData = () => {
    dispatch(updateInfoPresales(formData))
  }


  const isFormValid = () => {
    const errors = {};
    if (name.trim().length === 0) {
      errors['msgName'] = "Es requerido."
    }
    if (lastName.trim().length === 0) {
      errors['msglastName'] = 'Es requerido.';
    }
    if (motherLastName.trim().length === 0) {
      errors['msgmotherLastName'] = 'Es requerido.';
    }
    if (!validator.isMobilePhone(phone, ['es-MX'])) {
      errors['msgPhone'] = 'Teléfono no es valido.';
    }
    if (!validator.isEmail(email)) {
      errors['msgEmail'] = 'Correo electrónico no es valido.';
    }
    if (email !== emailConfirm) {
      errors['msgEmailConfirm'] = 'Los correos no coinciden.';
    }
    if (!validateRfcValid(rfc, setMsgErrors)) {
      errors['msgRfc'] = 'RFC no es valido.';
    }
    if (!socialMediaId) {
      errors['msgSmt'] = 'Es requerido.';
    }

    setMsgErrors(errors);

    if (Object.keys(errors).length !== 0) {
      return false;
    }

    return true;
  }

  // const handleClickEdiit = () => {
  //   setEditando( true );
  // }

  const handleSendEmailToken = () => {
    dispatch(updateEmailToken(token, email, tokenEmail))
  }

  const getSMTActives = () => {
    if (crmSalePreSelectedInfo) {
      return SMTActives
    } else {
      return SMTActives.filter(active => active.isActive == true)
    }
  }

  return (
    <Fragment>
      <div className="container">
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 d-flex justify-content-center my-2">
          <div className="card col-sm-8 shadow mb-4">
            <div className="card-body">
              <LogoContabilizate name={"Pre-Registro"} />
              <form className="mt-4" onSubmit={handleRegister}>
                <div className='row'>
                  <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-3">

                    <div className="row">
                      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3">
                        <TextField
                          label="Nombre*"
                          variant="outlined"
                          size="small"
                          id="name"
                          name="name"
                          value={name}
                          onChange={handleInputChange}
                          autoComplete="off"
                          helperText={msgErrors.msgName}
                          fullWidth={true}
                          error={msgErrors.msgName !== undefined}
                          disabled
                        />
                      </div>

                      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3">
                        <TextField
                          label="Apellido Paterno*"
                          variant="outlined"
                          size="small"
                          id="lastName"
                          name="lastName"
                          value={lastName}
                          onChange={handleInputChange}
                          autoComplete="off"
                          fullWidth={true}
                          helperText={msgErrors.msglastName}
                          error={msgErrors.msglastName !== undefined}
                          disabled
                        />
                      </div>

                      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3">
                        <TextField
                          label="Apellido Materno*"
                          variant="outlined"
                          size="small"
                          id="motherLastName"
                          name="motherLastName"
                          value={motherLastName}
                          onChange={handleInputChange}
                          autoComplete="off"
                          fullWidth={true}
                          helperText={msgErrors.msgmotherLastName}
                          error={msgErrors.msgmotherLastName !== undefined}
                          disabled
                        />
                      </div>

                      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3">
                        <TextField
                          label="RFC*"
                          variant="outlined"
                          size="small"
                          id="rfc"
                          name="rfc"
                          value={rfc}
                          onChange={handleInputChange}
                          autoComplete="off"
                          fullWidth={true}
                          helperText={msgErrors.msgRfc}
                          error={msgErrors.msgRfc !== undefined}
                          disabled
                        />
                      </div>

                      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3">
                        <TextField
                          label="Teléfono*"
                          id="phone"
                          variant="outlined"
                          size="small"
                          name="phone"
                          value={phone}
                          type="number"
                          placeholder='7778612712'
                          onChange={handleInputChange}
                          autoComplete="off"
                          fullWidth={true}
                          helperText={msgErrors.msgPhone}
                          error={msgErrors.msgPhone !== undefined}
                          disabled
                        />
                      </div>

                      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3">
                        <TextField
                          label="Correo Electrónico*"
                          id="email"
                          variant="outlined"
                          size="small"
                          name="email"
                          value={email}
                          onChange={handleInputChange}
                          autoComplete="off"
                          fullWidth={true}
                          helperText={msgErrors.msgEmail}
                          error={msgErrors.msgEmail !== undefined}
                          disabled
                          onCopy={(event) => {
                            event.preventDefault();
                          }}
                        />
                      </div>

                      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3">
                        <TextField
                          label="Confirma Correo Electrónico*"
                          id="emailConfirm"
                          variant="outlined"
                          size="small"
                          name="emailConfirm"
                          value={emailConfirm}
                          onChange={handleInputChange}
                          autoComplete="off"
                          fullWidth={true}
                          helperText={msgErrors.msgEmailConfirm}
                          error={msgErrors.msgEmailConfirm !== undefined}
                          disabled
                        />
                      </div>

                      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3">
                        <FormControl variant="outlined" size="small" fullWidth={true} error={msgErrors.msgEmail !== undefined}>
                          <InputLabel id="email-select-label">Como nos conociste?*</InputLabel>
                          <Select
                            labelId="socialMediaId-select-label"
                            id="socialMediaId"
                            name="socialMediaId"
                            value={socialMediaId}
                            onChange={handleInputChange}
                            label="Correo Electrónico*"
                            disabled
                          >
                            <MenuItem value="">
                              <em>Seleccione una opción</em>
                            </MenuItem>
                            {
                              getSMTActives().map(item => <MenuItem value={item.id}>{item.description}</MenuItem>)
                            }
                          </Select>
                          {msgErrors.msgEmail && <FormHelperText>{msgErrors.msgEmail}</FormHelperText>}
                        </FormControl>
                      </div>
                      {/* {!disabledText &&
                        <div className="row col-12">
                          <div className="col-12 text-center">
                            <button type="submit" className="btn btn-success">Registrar</button>
                          </div>
                        </div>
                      } */}
                      {/* {disabledText && disableSocialMediaId &&
                        <div>
                          <h3 className='text-info text-center'>Pre-Registro completo</h3>
                        </div>
                      } */}
                      {/* {disabledText && !disableSocialMediaId &&
                        <div>
                          <h3>
                            Faltan algunos datos
                          </h3>
                          <div className="col-12 text-center">
                            <button type="button" className="btn btn-success" onClick={handleUpdateData}>Actualizar Datos</button>
                          </div>
                        </div>

                      } */}
                    </div>

                  </div>
                  <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-3">
                    <div className="col-12 text-right">
                      <img className="text-center" height="280" src='registerPreSale.png' />
                    </div>
                    {/* {crmSalePreSelectedInfo &&
                      <div>
                        <button
                          className="btn btn-success"
                          type='button'
                          onClick={() => handleModal(
                            <EmailToken />
                          )}
                        >
                          Editar Correo
                        </button>
                      </div>
                    } */}
                    {
                      editando ?

                        <div className='row'>
                          <div className='my-3 ml-3'>
                            <TextField
                              label="Introduce el Token"
                              id="token"
                              variant="outlined"
                              size="small"
                              name="tokenEmail"
                              onChange={handleEmail}
                              autoComplete="off"
                              fullWidth={true}
                              helperText={''}
                              error={''}
                            />
                          </div>
                          <div className='my-3'>
                            <button
                              className='btn btn-primary ml-3'
                              type='button'
                              onClick={handleSendEmailToken}
                            >
                              Enviar
                            </button>
                          </div>
                        </div>
                        :
                        ''
                    }
                  </div>


                  <AvisoPrivacidad />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}
