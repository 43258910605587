import { types } from "../../types/types";

const initialState = {
  productToSales: [],
  productsToPaymentLink: [],
  productLink:''
}


export const productToSaleReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.productToSaleStartLoading:
      return{
        ...state,
        productToSales: [
          ...action.payload
        ]
      }
      case types.productsToPaymentLinkStartLoading:
        return{
          ...state,
          productsToPaymentLink: [
            ...action.payload
          ]
        }
      case types.productsToPaymentLink:
        return{
          ...state,
          productLink:action.payload
        }

    default:
      return state;
  }
}
