import React, { useEffect, Fragment, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IconButton, InputAdornment, TextField } from '@material-ui/core';
import { useForm } from '../../../../../hooks/useForm';
import { LogoContabilizate } from './LogoContabilizate';
import { AvisoPrivacidad } from './AvisoPrivacidad';
import { crmPreSaleStartSelected, registerTaxDocumentAF, registerTaxDocumentEF } from '../../../../../actions/crm/preSales';
import { useParams, useHistory } from 'react-router';
import { CRM_SALES } from '../../../../constants/Routes';
import swal from 'sweetalert';
import { validateArrayPresence, validateFilePresence } from '../../../../../helpers/validateForm';
import { Visibility, VisibilityOff } from '@material-ui/icons';

export const UploadFiles = ({ load = true }) => {

    const { formValues, handleFileInputChange, handleArrayInputChange, setFormValues, handleInputChange, handlePasswordFieles, handlePasswordCSD } = useForm({
        files: [],
        filesCSD: [],
        filePDF: [],
        passwordFieles: '',
        passwordCSD: ''
    }
    );

    const { crmPreSales } = useSelector(state => state.crmPreSale);

    const dispatch = useDispatch();
    const { token } = useParams();

    const [msgErrors, setMsgErrors] = useState({});
    const [taxDocuments, setTaxDocuments] = useState({});
    const [taxDocumentsCSD, setTaxDocumentsCSD] = useState({});
    const [disabledText, SetDisabledText] = useState(false);
    const [disabledTextFieles, SetDisabledTextFieles] = useState(false);
    const [disabledTextCSD, SetDisabledTextCSD] = useState(false);

    const [showPassword, setShowPassword] = useState(false);
    const [showPassword2, setShowPassword2] = useState(false);

    let history = useHistory();

    const { crmSalePreSelectedInfo } = useSelector(state => state.crmPreSale);
    const { files, filesCSD, filePDF, passwordFieles, passwordCSD } = formValues;

    useEffect(() => {
        if (token) {
            dispatch(crmPreSaleStartSelected(token))
        }
    }, [token])

    useEffect(() => {
        if (crmSalePreSelectedInfo) {
            if (crmSalePreSelectedInfo.stepsJson && crmSalePreSelectedInfo.stepsJson.stepTaxDocuments) {
                if (crmSalePreSelectedInfo.stepsJson.stepTaxDocuments) {
                    setTaxDocuments(crmSalePreSelectedInfo.stepsJson.stepTaxDocuments.documentsData)
                }
                if (crmSalePreSelectedInfo.stepsJson.stepTaxDocuments.documentsData?.length == 2) SetDisabledText(true)
            }
            if (crmSalePreSelectedInfo.stepsJson && crmSalePreSelectedInfo.stepsJson.stepTaXFiles) {
                if (crmSalePreSelectedInfo.stepsJson.stepTaxDocuments.documentsData) {
                    setTaxDocuments(crmSalePreSelectedInfo.stepsJson.stepTaxDocuments.documentsData)
                    SetDisabledTextFieles(true)
                }
            }
        }
    }, [crmSalePreSelectedInfo])


    useEffect(() => {
        if (disabledText && load) {
            swal('Archivos cargados', 'Tu asesor se pondrá en contacto', 'success');
            history.push(CRM_SALES)
        }
    }, [disabledText, taxDocumentsCSD])

    //Archivos CSD
    const handleSubmitEfirma = (e) => {

        e.preventDefault();
        setMsgErrors({})

        const datasFilesToValidate = { files, filesCSD, passwordFieles, passwordCSD }
        const validateFiles = validateArrayPresence(datasFilesToValidate, setMsgErrors);

        if (validateFiles) {
            let formData = new FormData();

            formData.append('Token', token);

            for (let i = 0; i < files.length; i++) {
                formData.append('TaxFiles[0].Files', files[i]);
            }
            formData.append('TaxFiles[0].Password', passwordFieles);
            formData.append('TaxFiles[0].IsFiel', true);

            for (let i = 0; i < filesCSD.length; i++) {
                formData.append('TaxFiles[1].Files', filesCSD[i]);
            }
            formData.append('TaxFiles[1].Password', passwordCSD);
            formData.append('TaxFiles[1].IsFiel', false);

            dispatch(registerTaxDocumentEF(formData, history, CRM_SALES))
        } else {
            return swal('Error al guardar', 'No se llenaron todos los campos, favor de verificar los archivos fiscales', 'error');
        }
    }
    // Archivos Fiscales
    const handleSubmit = (e) => {

        e.preventDefault();
        setMsgErrors({})
        const datasFilesToValidate = { filePDF }
        const validateFiles = validateFilePresence(datasFilesToValidate, setMsgErrors);

        if (validateFiles) {
            let formData = new FormData();

            formData.append('Token', token);

            for (let i = 0; i < filePDF.length; i++) {
                formData.append('Files', filePDF[i]);
            }

            setFormValues(prevState => {
                return {
                    ...prevState,
                    filePDF: ''
                }
            })

            dispatch(registerTaxDocumentAF(formData, history, CRM_SALES))
        } else {
            return swal('Error al guardar', 'No se llenaron todos los campos, favor de verificar los documentos fiscales', 'error');
        }
    }

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleClickShowPassword2 = () => {
        setShowPassword2(!showPassword2);
    };
    const handleMouseDownPassword2 = (event) => {
        event.preventDefault();
    };

    const handleChange = (event) => {
        const files = event.target.files;
        let arrayFles = [];
        Array.from(files).map(file => {
            arrayFles.push(file)
        });

        setFormValues(prevState => {
            return {
                ...prevState,
                files: arrayFles
            }
        })
    }

    const handleChangeCSD = (event) => {
        const files = event.target.files;
        let arrayFles = [];
        Array.from(files).map(file => {
            arrayFles.push(file)
        });

        setFormValues(prevState => {
            return {
                ...prevState,
                filesCSD: arrayFles
            }
        })
    }

    const handleChangePDF = (event) => {
        const files = event.target.files;
        let arrayFles = [];
        Array.from(files).map(file => {
            arrayFles.push(file)
        });

        setFormValues(prevState => {
            return {
                ...prevState,
                filePDF: arrayFles
            }
        })
    }

    return (
        <Fragment>
            <div className="container">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 d-flex justify-content-center my-2 ">

                    <div className="card col-sm-8 shadow mb-4">
                        <div className="card-body">
                            <LogoContabilizate />
                            <nav className="mt-4 mb-4">
                                <div className="nav  nav-pills" id="nav-tab" role="tablist">
                                    <a className="nav-item nav-link show active border m-1" id="nav-archivos-tab" data-toggle="tab" href="#nav-archivos" role="tab" aria-controls="nav-archivos" aria-selected="true">
                                        Archivos Fiscales
                                    </a>
                                    <a className="nav-item nav-link border m-1" id="nav-efirma-tab" data-toggle="tab" href="#nav-efirma" role="tab" aria-controls="nav-efirma" aria-selected="true">
                                        Documentos Fiscales
                                    </a>
                                </div>
                            </nav>

                            <div className='row'>
                                <div className="col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 mb-3">
                                    <div>
                                        <div className="tab-content" id="nav-tabContent">
                                            <div className="tab-pane fade show active " id="nav-archivos" role="tabpanel" aria-labelledby="nav-archivos-tab" aria-selected="true">
                                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-3 mb-3">
                                                    <form className="mt-4" onSubmit={handleSubmitEfirma}>
                                                        {crmSalePreSelectedInfo?.stepsJson?.stepTaxFiles === null || crmSalePreSelectedInfo?.stepsJson?.stepTaxFiles === undefined ? (
                                                            <Fragment>
                                                                <Fragment> {/* Primer Fragment de ARCHIVOS FIELES */}
                                                                    <h6> Archivos Fieles </h6>
                                                                    <TextField label="Archivos .key, .cer" variant="outlined" size="small" autoComplete="off" fullWidth={true}
                                                                        InputLabelProps={{ shrink: true, }}
                                                                        type="file"
                                                                        name="files"
                                                                        onChange={handleChange}
                                                                        helperText={msgErrors.files}
                                                                        error={msgErrors.files !== undefined}
                                                                        inputProps={{ accept: '.KEY,.CER', multiple: true }}
                                                                    />
                                                                    <br />
                                                                    <br />
                                                                    <h6> Escribe tu contraseña de Clave Privada* </h6>
                                                                    <TextField
                                                                        type={showPassword ? 'text' : 'password'}
                                                                        name='passwordFieles'
                                                                        label="Contraseña"
                                                                        onChange={handlePasswordFieles}
                                                                        InputProps={{
                                                                            endAdornment: (
                                                                                <InputAdornment position='end'>
                                                                                    <IconButton
                                                                                        aria-label='toggle password visibility'
                                                                                        onClick={handleClickShowPassword}
                                                                                        onMouseDown={handleMouseDownPassword}
                                                                                    >
                                                                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                                                                    </IconButton>
                                                                                </InputAdornment>
                                                                            ),
                                                                        }}
                                                                    />

                                                                </Fragment>
                                                                <Fragment> {/* Segundo Fragment DE ARCHIVOS CSD */}
                                                                    <br />
                                                                    <br />
                                                                    <h6> Archivos CSD </h6>
                                                                    <TextField label="Archivos .key, .cer" variant="outlined" size="small" autoComplete="off" fullWidth={true}
                                                                        InputLabelProps={{ shrink: true, }}
                                                                        type="file"
                                                                        name="filesCSD"
                                                                        onChange={handleChangeCSD}
                                                                        helperText={msgErrors.files}
                                                                        error={msgErrors.files !== undefined}
                                                                        inputProps={{ accept: '.KEY,.CER', multiple: true }}
                                                                    />
                                                                    <br />
                                                                    <br />
                                                                    <h6> Escribe tu contraseña de Clave Privada* </h6>
                                                                    <TextField
                                                                        type={showPassword2 ? 'text' : 'password'}
                                                                        name='passwordCSD'
                                                                        label="Contraseña"
                                                                        onChange={handlePasswordCSD}
                                                                        InputProps={{
                                                                            endAdornment: (
                                                                                <InputAdornment position='end'>
                                                                                    <IconButton
                                                                                        aria-label='toggle password visibility'
                                                                                        onClick={handleClickShowPassword2}
                                                                                        onMouseDown={handleMouseDownPassword2}
                                                                                    >
                                                                                        {showPassword2 ? <Visibility /> : <VisibilityOff />}
                                                                                    </IconButton>
                                                                                </InputAdornment>
                                                                            ),
                                                                        }}
                                                                    />

                                                                    <div className="row col-md-12 mt-3 text-center">
                                                                        <div className="col-12">
                                                                            <button type="submit" className="btn btn-success">Guardar</button>
                                                                        </div>
                                                                    </div>
                                                                </Fragment>
                                                            </Fragment>

                                                        ) : <h4 className='text-info text-center'> Archivos CSD Completos </h4>}
                                                    </form>
                                                </div>

                                            </div>
                                            <div className="tab-pane fade " id="nav-efirma" role="tabpanel" aria-labelledby="nav-efirma-tab" aria-selected="true">
                                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3">
                                                    {crmSalePreSelectedInfo?.stepsJson?.stepTaxDocuments === null || crmSalePreSelectedInfo?.stepsJson?.stepTaxDocuments === undefined || crmSalePreSelectedInfo?.stepsJson?.stepTaxDocuments.taxDocumentData?.length < 2 ? (
                                                        <div>
                                                            <form className="" onSubmit={handleSubmit}>
                                                                <TextField label="Constancia.PDF, Opinión.PDF" variant="outlined" size="small" autoComplete="off" fullWidth={true}
                                                                    InputLabelProps={{ shrink: true, }}
                                                                    type="file"
                                                                    name="filePDF"
                                                                    onChange={handleChangePDF}
                                                                    helperText={msgErrors.filePDF}
                                                                    error={msgErrors.filePDF !== undefined}
                                                                    inputProps={{ accept: '.PDF', multiple: true }}
                                                                    className='my-4'
                                                                />
                                                                <div className="row col-md-12 text-center">
                                                                    <div className="col-12">
                                                                        <button type="submit" className="btn btn-success">Guardar</button>
                                                                    </div>
                                                                </div>
                                                            </form>
                                                            <div>
                                                                {
                                                                    crmSalePreSelectedInfo?.stepsJson?.stepTaxDocuments?.taxDocumentData?.map((data, index) => {
                                                                        return (
                                                                            <div>
                                                                                <h5 className='text-success'> Archivos subidos: </h5>
                                                                                <label> {`${index + 1}.- ${data.documentType}`} </label>
                                                                                <br />
                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                        </div>

                                                    ) : (<h4 className='text-info text-center'> Documentos Fiscales Completos </h4>)

                                                    }

                                                </div>
                                            </div>
                                        </div>
                                    </div>



                                </div>
                                <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 mb-3">
                                    <div className="col-12 text-start">
                                        <img className="text-start" height="250" src='filePreSale.png' />
                                    </div>
                                </div>

                                <AvisoPrivacidad />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}
