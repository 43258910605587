import swal from "sweetalert";
import { fetchWithToken, fetchWithTokenFormDataExist, fetchWithoutToken, fetchWithApiKey, fetchWithTokenFormData } from "../../helpers/fetch";
import { loadingModal, loadingModalMsn, saveModal, SendingEmailModal } from "../../helpers/UseSwal";
import { types } from "../../types/types";
import Swal from "sweetalert2";
import { validatePaymentbySaleID } from "../accounting/periods";
import { ModalContext } from "../../components/modal/ModalContext";

export const crmPreSalesStartLoading = () => {
  return async (dispatch) => {
    loadingModal(true)
    const resp = await fetchWithToken('api/PreSale');
    const body = await resp.json();
    if (body.success) {
      dispatch(crmPreSalesLoaded(body.data));
    }
    loadingModal(false)
  }
}

const crmPreSalesLoaded = (preSales) => ({
  type: types.crmPreSalesStartLoading,
  payload: preSales
})

export const startRegisterPreSale = (preSale, history, route) => {
  return async (dispatch) => {
    saveModal(true);
    const resp = await fetchWithToken('api/PreSale/register', preSale, 'POST');
    const body = await resp.json();
    if (body.success) {
      dispatch(crmPreSaleRegister(body.data))
      swal('Exito', 'Preventa guardada', 'success');
      history.push(route);
    } else {
      let msgErrors = "";
      if (body.errors) {
        Object.keys(body.errors).forEach(function (key) {
          msgErrors += `${body.errors[key]}\n`
        });
      } else {
        msgErrors = body.error;
      }
      swal('Error al guardar', msgErrors, 'error');
    }
    saveModal(false);
  }
}

const crmPreSaleRegister = (preSales) => ({
  type: types.crmPreSalesRegister,
  payload: preSales
})

export const crmPreSaleStartSelected = (token) => {
  return async (dispatch) => {
    loadingModal(true)
    const resp = await fetchWithToken(`api/PreSale/${token}`);
    const body = await resp.json();

    if (body.success) {
      dispatch(crmPreSalesLoadedSelected(body.data))
    } else {
      swal('Error al cargar', 'Intente más tarde', 'error');
    }

    loadingModal(false)
  }
}

const crmPreSalesLoadedSelected = (preSales) => ({
  type: types.crmSalePreSelected,
  payload: preSales
})


export const startValidateSatPreSale = (token, history) => {
  return async (dispatch) => {
    saveModal(true);
    const resp = await fetchWithToken('api/PreSale/stepCiec', token, 'POST');
    const body = await resp.json();
    if (body.success) {
      swal('Contraseña SAT guardada', 'Tu asesor seguira con el proceso', 'success');
      history.push("/")
    } else {
      dispatch(crmPreSalesLoadedValidateSat(body.data))
    }
    saveModal(false);
  }

}

const crmPreSalesLoadedValidateSat = (preSales) => ({
  type: types.crmSalePreValidateSat,
  payload: preSales
})


export const sendEmailNotifyInfo = (token, ciec) => {
  return async (dispatch) => {

    SendingEmailModal(true)
    const resp = await fetchWithToken(`api/PreSale/sendMessageToAccessSAT/${token}/${ciec}`, token, 'POST');
    const body = await resp.json();
    if (body.success) {
      swal('Exito', 'Correo enviado', 'success');
    } else {
      swal('Error', body.error, 'error');
    }
    SendingEmailModal(false)
  }
}



export const activateAccount = (dataAccount) => {

  return async (dispatch) => {
    saveModal(true);
    const resp = await fetchWithTokenFormDataExist('api/WebHooks/banWire/sucess', dataAccount, 'POST');
    const body = await resp.json();
    if (body.success) {
      swal('Pago aprobado', 'La cuenta está activa', 'success');
      window.location.reload()
    } else {
      let msgErrors = "";
      if (body.errors) {
        Object.keys(body.errors).forEach(function (key) {
          msgErrors += `${body.errors[key]}\n`
        });
      } else {
        msgErrors = body.error;
      }
      swal('Error al guardar', msgErrors, 'error');
    }
    saveModal(false);
  }
}


export const updateInfoPresales = (data) => {
  return async (dispatch) => {
    saveModal(true);

    const resp = await fetchWithToken('api/PreSale/updateDataStep1', data, 'POST');
    const body = await resp.json();
    if (body.success) {
      swal('Información actualizada', 'La información ha sido guardada', 'success');
    } else {
      swal('Te faltan estos datos', body.error, 'error');
    }
    saveModal(false);
  }
}



export const startInfoTaxPreSale = (preSale, history, route) => {
  return async (dispatch) => {
    saveModal(true);

    const resp = await fetchWithToken('api/PreSale/stepTaxPayerData', preSale, 'POST');
    const body = await resp.json();

    if (body.success) {
      swal('Información guardada', 'Tu asesor seguira con el proceso', 'success');
      history.push(route);
    } else {
      let msgErrors = "";
      if (body.errors) {
        Object.keys(body.errors).forEach(function (key) {
          msgErrors += `${body.errors[key]}\n`
        });
      } else {
        msgErrors = body.error;
      }
      swal('Error al guardar', msgErrors, 'error');
    }
    saveModal(false);
  }
}

const crmPreSaleInfoTax = (preSales) => ({
  type: types.crmPreSaleInfoTax,
  payload: preSales
})

export const generatePaymentPreSale = (product, handleModal, total) => {
  return async (dispatch) => {
    try {
      loadingModalMsn(true, "Generando link");
      const resp = await fetchWithToken('api/PaymentGateway/getPaymentLink', product, 'POST');
      const body = await resp.json();

      if (body.success) {
        if (total != 0) {
          await swal('Exito', 'Liga generada, copiado en portapapeles', 'success');
          await navigator.clipboard.writeText(body.data.payLink);
        } else {
          await swal('Exito', 'Promoción guardada, no olvides activarlo manualmente', 'success');
        }
        dispatch(crmPreSaleGenerateLink(body.data));
        handleModal();
        window.location.reload();
      } else {
        swal('Error', 'Intente de nuevo, por favor', 'error');
      }
    } catch (error) {
      console.error('Error:', error);
      swal('Error', 'Hubo un error, por favor intenta de nuevo', 'error');
    } finally {
      loadingModalMsn(false);
    }
  };
};

const crmPreSaleGenerateLink = (preSales) => ({
  type: types.crmPreSaleLink,
  payload: preSales
})


export const paymentPreSale = (preSale, history, route) => {
  return async (dispatch) => {
    saveModal(true);

    const resp = await fetchWithToken('api/PaymentGateway/CreatePlan', preSale, 'POST');
    const body = await resp.json();
    if (body.success) {
      swal('Pago Exitoso', 'Tu asesor se pondrá en contacto', 'success');
      history.push(route);
    } else {
      let msgErrors = "";
      if (body.errors) {
        Object.keys(body.errors).forEach(function (key) {
          msgErrors += `${body.errors[key]}\n`
        });
      } else {
        msgErrors = body.error;
      }
      swal('Error al guardar', msgErrors, 'error');
    }
    saveModal(false);
  }
}

export const registerTaxDocumentAF = (files, history, route) => {
  return async (dispatch) => {
    saveModal(true);

    const resp = await fetchWithTokenFormDataExist('api/Presale/stepUploadTaxDocuments', files, 'POST');
    const body = await resp.json();

    if (body.success) {
      swal({
        title: 'Archivo cargado',
        text: "Desea cargar otro archivo",
        icon: "success",
        buttons: ["Continuar"],
        dangerMode: true,
      })
        .then((isCopy) => {
          if (!isCopy) {
            history.push(route)
          }
        });
      dispatch(crmPreSaleLoadFiles(body.data.stepTaxDocument))
    } else {
      let msgErrors = "";
      if (body.errors) {
        Object.keys(body.errors).forEach(function (key) {
          msgErrors += `${body.errors[key]}\n`
        });
      } else {
        msgErrors = body.error;
      }
      swal('Error al guardar', msgErrors, 'error');
    }
    saveModal(false);
  }
}

const crmPreSaleLoadFiles = (files) => ({
  type: types.crmPreSaleFiles,
  payload: files
})

export const registerTaxDocumentEF = (files, history, route) => {
  return async (dispatch) => {
    saveModal(true);

    const resp = await fetchWithTokenFormDataExist('api/PreSale/StepUploadTaxFiles', files, 'POST');
    // const resp = await fetchWithTokenFormDataExist('/archivos', files, 'POST');
    const body = await resp.json();
    if (body.success) {
      swal({
        title: 'Archivo cargado',
        text: "Desea cargar otro archivo",
        icon: "success",
        buttons: ["Continuar"],
        dangerMode: true,
      })
        .then((isCopy) => {
          if (!isCopy) {
            history.push(route)
          }
        });
      //dispatch(crmPreSaleLoadFilesCSD(body.data.stepTaxDocument))
    } else {
      let msgErrors = "";
      if (body.errors) {
        Object.keys(body.errors).forEach(function (key) {
          msgErrors += `${body.errors[key]}\n`
        });
      } else {
        msgErrors = body.error;
      }
      swal('Error al guardar', msgErrors, 'error');
    }
    saveModal(false);
  }
}

const crmPreSaleLoadFilesCSD = (files) => ({
  type: types.crmPreSaleFilesCSD,
  payload: files
})

export const getTokenEmail = (password) => {
  const data = { "PASSWORD": password }
  return async (dispatch) => {

    const resp = await fetchWithToken('api/User/GetAuthorizationToken', data, 'POST');
    const body = await resp.json();

    if (body.success) {
      const msg = body.data.extraMessage
      const toke = body.data.toke
      navigator.clipboard.writeText(body.data.toke)
      swal('Exito', msg + ": " + toke, 'success');
    } else {
      swal('Error', body.error, 'error');
    }
  }
}


export const getValidate = (password, saleId) => {
  return async (dispatch) => {
    const data = { "PASSWORD": password };

    const resp = await fetchWithToken('api/User/GetAuthorizationToken', data, 'POST');
    const body = await resp.json();

    if (body.success) {
      Swal.fire({
        title: "Al dar clic se validará el pago del cliente",
        text: `Este proceso no se podrá deshacer ¿Deseas continuar?`,
        showCancelButton: true,
        confirmButtonText: 'Sí',
        cancelButtonText: 'No',
      }).then((result) => {
        if (result.value) {
          dispatch(validatePaymentbySaleID(saleId, null, "!Concluyendo tramite¡"));
        }
      });
    } else {
      swal('Error', body.error, 'error');
    }
  };
};


export const updateEmailToken = (token, email, tokenEmail) => {
  const data = {
    "token": token,
    "newEmail": email,
    "authorizationToken": tokenEmail
  }
  return async (dispatch) => {

    const resp = await fetchWithToken('api/PreSale/UpdateEmailRegisterPresale', data, 'POST');
    const body = await resp.json();

    if (body.success) {
      await swal('Exito', 'Correo cambiado Exitosamente', 'success');
      window.location.reload();
    } else {
      await swal('Error', body.error, 'error');
    }
  }
}

export const codeReferencePromotion = (id, codeReference, setIsActive, setPromotionCodeReference, setProdcutToSalePromotionId, setPromotionCodeProduct) => {
  return async (dispatch) => {
    loadingModalMsn(true, "Validando código")
    const resp = await fetchWithToken(`api/Promotion/${id}/${codeReference}`);
    const body = await resp.json();
    if (body.success) {
      dispatch(crmPromotionsCode(body.data))
      setIsActive(body.data.isListPromotion)
      setPromotionCodeReference(body.data.promotionCode)
      setProdcutToSalePromotionId(body.data.product.id)
      setPromotionCodeProduct(body.data.referenceCode)
    } else {
      swal('Error', body.error, 'error');
    }
    loadingModalMsn(false)
  }
}

const crmPromotionsCode = (promotionData) => ({
  type: types.crmReferenceCodePromotion,
  payload: promotionData
})

export const crmPreSaleSelectedClear = () => ({ type: types.crmPresSaleClearSelected })