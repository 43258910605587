import React, { Fragment, useContext, useEffect, useState } from 'react';
import { ModalContext } from '../../../../../../../modal/ModalContext';
import { ExpenseForm } from './ExpenseForm';
import AuthorizedDeductions from './options/authorizaedDeductions';
import PersonalDeductions from './options/personalDeductions';
import NoDeductibles from './options/noDeductibles';
import CanceledDeductions from './options/canceledDeductions';
import { ThirdPartyForm } from '../../../../../thirdParties/ThirdPartyForm';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from '../../../../../../../../hooks/useForm';
import { clasificateExpenses, consideredsExpenses, notConsideredsExpenses, personalConsideredsExpenses, canceledsExpenses } from '../../../../../../../../helpers/clasificateDownloadAccounting';
import { changeAuthorizationExpense } from '../../../../../../../../actions/accounting/expense';
import { TextField } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ExpenseTypesStartLoading } from '../../../../../../../../actions/accounting/expenseTypes';
import NumberFormat from 'react-number-format';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  circuloRojo: {
    height: '30px',
    padding: '5px',
    backgroundColor: 'red',
    color: 'white',
    display: 'flex',
    placeItems: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '14px',
  },
  circuloAzul: {
    height: '30px',
    padding: '5px',
    backgroundColor: 'blue',
    color: 'white',
    display: 'flex',
    placeItems: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '14px',
  },
  circuloVerde: {
    height: '30px',
    padding: '5px',
    backgroundColor: 'green',
    color: 'white',
    display: 'flex',
    placeItems: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '14px',
  },
});

const Expenses = ({ formValuesDetailsSummary, setFormValuesDetailsSummary, autorizedReport = false, status, refreshComplementPayments }) => {

  const [isVisible, setIsVisible] = useState(false);

  const { regimeSatCode, taxpayerId, month, year } = useParams();

  const { handleModal } = useContext(ModalContext);

  const { expenses, statusExpenseDownload } = useSelector(state => state.expense);
  const { expenseTypes } = useSelector(state => state.expenseType)
  const { taxpayerProfession } = useSelector(state => state.taxpayer);
  const dispatch = useDispatch();

  const { formValues, setFormValues } = useForm({
    consideredExpenses: [],
    notConsideredExpenses: [],
    personalConsideredExpenses: [],
    canceledExpenses: [],
    regimeExpenses: [],
  });

  const { consideredExpenses, notConsideredExpenses, canceledExpenses, personalConsideredExpenses, regimeExpenses } = formValues;

  const { subtotalExpenseAutorized, subtotalExpenseNotAutorized, subtotalExpensePersonalAutorized, total } = formValuesDetailsSummary;

  useEffect(() => {
    if (expenseTypes.length === 0) {
      dispatch(ExpenseTypesStartLoading());
    }
  }, [dispatch, expenseTypes])

  useEffect(() => {
    if (expenses.length > 0 && expenseTypes.length > 0) {
      let regimeSatCodeSearch = regimeSatCode;
      let monthSearch = month;
      let yearSearch = year;

      if (autorizedReport) {
        regimeSatCodeSearch = formValuesDetailsSummary.regimeSatCode;
        monthSearch = formValuesDetailsSummary.month;
        yearSearch = formValuesDetailsSummary.year;
      }

      const clasifications = clasificateExpenses(expenseTypes, expenses, monthSearch, regimeSatCodeSearch, yearSearch);

      setFormValues(prevState => {
        return {
          ...prevState,
          regimeExpenses: [...clasifications]
        }
      })
    }
  }, [autorizedReport, formValuesDetailsSummary.regimeSatCode, formValuesDetailsSummary.month, formValuesDetailsSummary.year, expenseTypes, expenses, regimeSatCode, setFormValues, month, year])

  useEffect(() => {
    if (regimeExpenses.length > 0) {
      let regimeSatCodeSearch = regimeSatCode;

      if (autorizedReport) {
        regimeSatCodeSearch = formValuesDetailsSummary.regimeSatCode;
      }

      let considereds = consideredsExpenses(expenseTypes, regimeExpenses, regimeSatCodeSearch);
      let personalConsidereds = personalConsideredsExpenses(expenseTypes, regimeExpenses, regimeSatCodeSearch);
      let noConsidereds = notConsideredsExpenses(expenseTypes, regimeExpenses, regimeSatCodeSearch);
      let canceleds = canceledsExpenses(regimeExpenses);

      if (regimeSatCode === '621' || regimeSatCode === '626') {
        noConsidereds = [...noConsidereds, ...personalConsidereds]
        personalConsidereds = []
      }

      setFormValues(prevState => {
        return {
          ...prevState,
          consideredExpenses: [...considereds],
          notConsideredExpenses: [...noConsidereds],
          personalConsideredExpenses: [...personalConsidereds],
          canceledExpenses: [...canceleds],
        }
      })
    }
  }, [autorizedReport, expenseTypes, formValuesDetailsSummary.regimeSatCode, regimeExpenses, regimeSatCode, setFormValues])

  const classes = useStyles();

  const changeConsideredExpense = (expense) => {
    const newAuthorize = expense.authorization.map(
      authorize => (authorize.regimeSatCode === regimeSatCode) ?
        {
          ...authorize,
          authorized: !authorize.authorized
        }
        :
        authorize
    );

    dispatch(changeAuthorizationExpense({
      expenseId: expense.id,
      authorization: newAuthorize,
    },
      refreshComplementPayments
    ));
  }

  return (
    <div className="row">
      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2">
        <div className="card">
          <div className="card-header">
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <h5>Gastos Relacionados</h5>
                <b>{statusExpenseDownload}</b>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2">

        {
          !autorizedReport && status !== 'Presentada' && (
            <Fragment>
              <button className="btn btn-sm btn-success" onClick={() => handleModal(
                <ExpenseForm
                  regimeSatCode={regimeSatCode}
                  taxpayerId={taxpayerId}
                  month={month}
                  year={year}
                  refreshComplementPayments={refreshComplementPayments}
                />, true)}
              >
                <i className="fas fa-plus"></i> Agregar Gasto
              </button>

              <button className="btn btn-sm btn-primary ml-3"
                onClick={() => handleModal(
                  <ThirdPartyForm
                    formAccounting={true}
                    taxpayerId={taxpayerId}
                  />, true
                )}
              >
                <i className="fas fa-plus"></i> Agregar Cliente
              </button>

              <button className="btn btn-sm btn-primary float-right">
                <i className="fas fa-file-import"></i> Importar XMLs
              </button>
            </Fragment>
          )
        }
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2">
          <h3 className="text-center">Glosario</h3>
          <div className="d-flex justify-content-center mt-4 gap-3">
            <div className="d-flex flex-column align-items-center rounded p-2">
              <h6 className={classes.circuloRojo}>Observación</h6>
            </div>
            <div className="d-flex flex-column align-items-center rounded p-2">
              <h6 className={classes.circuloVerde}>Reclasificado manualmente</h6>
            </div>
            <div className="d-flex flex-column align-items-center rounded p-2">
              <h6 className={classes.circuloAzul}>Reclasificado automáticamente</h6>
            </div>
          </div>
        </div>


      </div>
      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2">
        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr className="text-center">
                <th>Deducir</th>
                <th>UUID</th>
                <th>Fecha de timbrado</th>
                <th>Fecha de pago</th>
                <th>Proveedor</th>
                <th>Folio</th>
                <th>Conceptos</th>
                <th>Clasificación</th>
                <th>Forma de pago</th>
                <th>Observaciones</th>
                <th>Metodo de pago</th>
                <th>Total</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {regimeExpenses.length === 0 ?
                (<tr>
                  <td colSpan="9">
                    <div className="alert alert-primary text-center mb-0" role="alert">
                      No se ha agregado información
                    </div>
                  </td>
                </tr>) :
                (
                  regimeExpenses.map((expense, index) => {
                    // //console.log('REGIMEN EXPENSE:', expense)
                    return (
                      parseFloat(expense.total) > 0 &&
                      <tr key={index} className="text-center">
                        <td>
                          {
                            status === 'Presentada' ?
                              (
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    name="typeOwner"
                                    defaultChecked={expense.authorized}
                                    onChange={changeConsideredExpense.bind(this, expense)}
                                    disabled={true}
                                  />
                                </div>
                              )
                              :
                              (<div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  name="typeOwner"
                                  defaultChecked={expense.authorized}
                                  onChange={changeConsideredExpense.bind(this, expense)}
                                  disabled={autorizedReport}
                                />
                              </div>)
                          }
                        </td>
                        <td>{expense.uuid}</td>
                        <td>{expense.stampAt}</td>
                        <td>{expense.paymentAt}</td>
                        <td>
                          {expense.issuedRfc}
                          <br />
                          {expense.issuedFullName}
                        </td>
                        <td>{expense.folio}</td>
                        <td>
                          <TextField
                            label="Concepto" variant="outlined" size="small" autoComplete="off" fullWidth={true}
                            select
                            SelectProps={{
                              native: true,
                            }}
                            readOnly
                          >
                            {
                              expense.concepts.map((concept, i) => {
                                return (
                                  <option
                                    key={i}
                                  >
                                    {concept.claveProdServ ? `${concept.claveProdServ} - ` : ''}
                                    {concept.descripcion}
                                  </option>
                                )
                              })
                            }
                          </TextField>
                        </td>
                        <td><strong>{expense?.description}</strong></td>
                        <td><strong>{expense?.wayOfPayment?.description}</strong></td>
                        <td>
                          <div>
                            <p className='d-flex row gap-4'>
                              {expense.authorization.map((datos, index) => {
                                let message = '';

                                if (datos.observations != "") {
                                  message = <strong style={{ color: 'red' }}>{datos.observations}</strong>;
                                } else if (datos.classifiedAutoMessage != "") {
                                  message = <strong style={{ color: 'blue' }}>{datos.classifiedAutoMessage}</strong>;
                                } else if (datos.classifiedManualMessage != "") {
                                  message = <strong style={{ color: 'green' }}>{datos.classifiedManualMessage}</strong>;
                                } else {
                                  message = <strong style={{ color: 'black' }}>Sin información</strong>;
                                }

                                return <span key={index}>{message}</span>;
                              })}
                            </p>
                          </div>
                        </td>
                        <td><strong>{expense?.paymentMethod?.description}</strong></td>
                        <td><NumberFormat value={parseFloat(expense.total).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td>
                        <td>
                          {
                            !autorizedReport && status !== 'Presentada' && (
                              <button className="btn btn-warning"
                                onClick={() => handleModal(
                                  <ExpenseForm
                                    regimeSatCode={regimeSatCode}
                                    taxpayerId={taxpayerId}
                                    month={month}
                                    year={year}
                                    expenseEdit={expense}
                                    refreshComplementPayments={refreshComplementPayments}
                                  />, true)}
                              >
                                <FontAwesomeIcon className="fas" icon={"edit"} />
                              </button>
                            )
                          }
                        </td>
                      </tr>
                    )
                  })
                )
              }
            </tbody>
          </table>
        </div>
      </div>

      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2">
        <nav>
          <div className="nav nav-tabs" id="nav-tab" role="tablist">
            <a className="nav-item nav-link active" id="nav-AuthorizedDeductions-tab" data-toggle="tab" href="#nav-AuthorizedDeductions" role="tab" aria-controls="nav-AuthorizedDeductions" aria-selected="true">
              <strong>Deducciones Autorizadas</strong> - <span className="badge badge-success">$ {regimeSatCode === '612' && taxpayerProfession === 'Médico/Dentista' ? parseFloat(total).toFixed(2) : subtotalExpenseAutorized}</span>
            </a>
            {regimeSatCode !== '626' &&
              <a className="nav-item nav-link" id="nav-PersonalDeductions-tab" data-toggle="tab" href="#nav-PersonalDeductions" role="tab" aria-controls="nav-PersonalDeductions" aria-selected="true">
                <strong>Deducciones Personales</strong> - <span className="badge badge-primary">$ {subtotalExpensePersonalAutorized}</span>
              </a>
            }
            <a className="nav-item nav-link" id="nav-NoDeductibles-tab" data-toggle="tab" href="#nav-NoDeductibles" role="tab" aria-controls="nav-NoDeductibles" aria-selected="true">
              <strong>No Deducibles</strong> - <span className="badge badge-warning">$ {subtotalExpenseNotAutorized}</span>
            </a>
            <a className="nav-item nav-link" id="nav-canceledDeductibles-tab" data-toggle="tab" href="#nav-canceledDeductibles" role="tab" aria-controls="nav-canceledDeductibles" aria-selected="true">
              <strong>Cancelados</strong>
            </a>
          </div>
        </nav>
      </div>

      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2">
        <div className="tab-content" id="nav-tabContent">
          <div className="tab-pane fade show active" id="nav-AuthorizedDeductions" role="tabpanel" aria-labelledby="nav-AuthorizedDeductions-tab">
            <AuthorizedDeductions
              consideredExpenses={consideredExpenses}
              formValuesDetailsSummary={formValuesDetailsSummary}
              setFormValuesDetailsSummary={setFormValuesDetailsSummary}
            />
          </div>
          <div className="tab-pane fade" id="nav-PersonalDeductions" role="tabpanel" aria-labelledby="nav-PersonalDeductions-tab">
            <PersonalDeductions
              personalConsideredExpenses={personalConsideredExpenses}
              setFormValuesDetailsSummary={setFormValuesDetailsSummary}
            />
          </div>
          <div className="tab-pane fade" id="nav-NoDeductibles" role="tabpanel" aria-labelledby="nav-NoDeductibles-tab">
            <NoDeductibles
              notConsideredExpenses={notConsideredExpenses}
              setFormValuesDetailsSummary={setFormValuesDetailsSummary}
            />
          </div>
          <div className="tab-pane fade" id="nav-canceledDeductibles" role="tabpanel" aria-labelledby="nav-canceledDeductibles-tab">
            <CanceledDeductions
              canceledExpenses={canceledExpenses}
              setFormValuesDetailsSummary={setFormValuesDetailsSummary}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Expenses;
