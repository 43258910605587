import React, { Fragment } from 'react';

export const InformationDiot = ({informationDiots}) => {

  const exportDiot = (e) => {
    e.preventDefault();

    const element = document.createElement("a");

    let text = '';

    informationDiots.forEach(diot => {

      let { amountPaidNotCreditable1011, amountPaidNotCreditableForeing, amountPaidNotCreditable1516, countryCode, expenses0, expenses10, expenses1011, expenses1516, expenses15, expenseForeing,
            expensesExents, fiscalId, foreignName, imported1011, importedNotCreditable1011, imported1516, importedNotCreditable1516, ivaDiscount, ivaRetained, nationality,
            importedExents,
          } = diot.information

      let taxpayerInformation = `${diot.rfc === 'XEXX010101000' ? '05' : '04'}|85|${diot.rfc !== 'XEXX010101000' ? diot.rfc : ''}|${fiscalId ? fiscalId : ''}|${foreignName ? foreignName : ''}|${countryCode ? countryCode : ''}|${nationality ? nationality : ''}|`
      
      let taxpayerInformation1 = `${parseInt(expenses1516) > 0 ? parseInt(expenses1516) : '' }|${parseInt(expenses15) > 0 ? parseInt(expenses15) : '' }|${parseInt(amountPaidNotCreditable1516) > 0 ? parseInt(amountPaidNotCreditable1516) : '' }|`
      let taxpayerInformation2 = `${parseInt(expenses1011) > 0 ? parseInt(expenses1011) : '' }|${parseInt(expenses10) > 0 ? parseInt(expenses10) : '' }|${parseInt(expenseForeing) > 0 ? parseInt(expenseForeing) : '' }|`
      let taxpayerInformation3 = `${parseInt(amountPaidNotCreditable1011) > 0 ? parseInt(amountPaidNotCreditable1011) : '' }|${parseInt(amountPaidNotCreditableForeing) > 0 ? parseInt(amountPaidNotCreditableForeing) : '' }|${parseInt(imported1516) > 0 ? parseInt(imported1516) : '' }|`
      let taxpayerInformation4 = `${parseInt(importedNotCreditable1516) > 0 ? parseInt(importedNotCreditable1516) : '' }|${parseInt(imported1011) > 0 ? parseInt(imported1011) : '' }|${parseInt(importedNotCreditable1011) > 0 ? parseInt(importedNotCreditable1011) : '' }|`
      let taxpayerInformation5 = `${parseInt(importedExents) > 0 ? parseInt(importedExents) : '' }|${parseInt(expenses0) > 0 ? parseInt(expenses0) : '' }|${parseInt(expensesExents) > 0 ? parseInt(expensesExents) : '' }|`
      let taxpayerInformation6 = `${parseInt(ivaRetained) > 0 ? parseInt(ivaRetained) : '' }|${parseInt(ivaDiscount) > 0 ? parseInt(ivaDiscount) : '' }|`

      text += `${taxpayerInformation}${taxpayerInformation1}${taxpayerInformation2}${taxpayerInformation3}${taxpayerInformation4}${taxpayerInformation5}${taxpayerInformation6}\n`
    });

    const file = new Blob([text], {type: 'text/plain;charset=utf-8'});
    
    element.href = URL.createObjectURL(file);
    
    element.download = "Diot.txt";
    
    document.body.appendChild(element);
    
    element.click();
  }

  return (
    <div>
      {
        informationDiots.length === 0 ?
        (
          <div className="alert alert-primary text-center mb-0" role="alert">
             No se encontraron egresos considerados
          </div>
        )
        :
        (
          <Fragment>
            <ul className="nav nav-tabs" id="rfcTab" role="tablist">
              {
                informationDiots.map((diot, index) => {
                  return(
                    <li className="nav-item" key={diot.rfc}>
                      <a className={`nav-link `} id={`${diot.rfc}-tab`} data-toggle="tab" href={`#${diot.rfc}`} role="tab" aria-controls={`${diot.rfc}`} aria-selected="true">{diot.rfc}</a>
                    </li>
                  )

                })
              }
            </ul>
            <div className="tab-content" id="rfcTabContent">
              {
                informationDiots.map((diot, index) => {
                  return(
                    <div className={`tab-pane fade ${ index === 0 && 'show active'}`} id={`${diot.rfc}`} role="tabpanel" aria-labelledby={`${diot.rfc}-tab`}  key={diot.rfc}>
                      <table className="table table-sm">
                        <tbody>
                          <tr>
                            <th>
                              <button className="btn btn-primary"
                                onClick={exportDiot}
                              >
                                Descargar DIOT
                              </button>
                            </th>
                            <th colSpan="2" className="text-right">
                              {diot.rfc}
                            </th>
                          </tr>

                          <tr>
                            <td>
                              Tipo de tercero
                            </td>
                            <td className="text-right">
                              {diot.information.thirdPartyType}
                            </td>
                          </tr>

                          <tr>
                            <td>
                              Tipo de operación
                            </td>
                            <td className="text-right">
                              {diot.information.operationType}
                            </td>
                          </tr>

                          <tr>
                            <td>
                              rfc
                            </td>
                            <td className="text-right">
                              {diot.rfc}
                            </td>
                          </tr>

                          <tr>
                            <td>
                              Número de ID Fiscal
                            </td>
                            <td className="text-right">
                              {diot.information.fiscalId}
                            </td>
                          </tr>

                          <tr>
                            <td>
                              Nombre del Extranjero
                            </td>
                            <td className="text-right">
                              {diot.information.foreignName}
                            </td>
                          </tr>

                          <tr>
                            <td>
                              País de Residencia
                            </td>
                            <td className="text-right">
                              {diot.information.country}
                            </td>
                          </tr>

                          <tr>
                            <td>
                              Nacionalidad
                            </td>
                            <td className="text-right">
                              {diot.information.nationality}
                            </td>
                          </tr>

                          <tr>
                            <td>
                              Valor de los actos o actividades pagados a la tasa del 15% o 16% de IVA
                            </td>
                            <td className="text-right">
                              {diot.information.expenses1516}
                            </td>
                          </tr>

                          <tr>
                            <td>
                              Valor de los actos o actividades pagados a la tasa del 15% de IVA
                            </td>
                            <td className="text-right">
                              {diot.information.expenses15}
                            </td>
                          </tr>

                          <tr>
                            <td>
                              Monto del IVA pagado no acreditable a la tasa del 15% o 16% (correspondiente en la proporción de las deducciones autorizadas)
                            </td>
                            <td className="text-right">
                              {diot.information.amountPaidNotCreditable1516}
                            </td>
                          </tr>

                          <tr>
                            <td>
                              Valor de los actos o actividades pagados a la tasa del 10% o 11% de IVA	
                            </td>
                            <td className="text-right">
                              {diot.information.expenses1011}
                            </td>
                          </tr>

                          <tr>
                            <td>
                              Valor de los actos o actividades pagados a la tasa del 10% de IVA	
                            </td>
                            <td className="text-right">
                              {diot.information.expenses10}
                            </td>
                          </tr>

                          <tr>
                            <td>
                              Valor de los actos o actividades pagados sujetos al estímulo de la región fronteriza norte
                            </td>
                            <td className="text-right">
                              {diot.information.expenseForeing}
                            </td>
                          </tr>

                          <tr>
                            <td>
                              Monto del IVA pagado no acreditable a la tasa del 10% o 11% (correspondiente en la proporción de las deducciones autorizadas)
                            </td>
                            <td className="text-right">
                              {diot.information.amountPaidNotCreditable1011}
                            </td>
                          </tr>

                          <tr>
                            <td>
                              Monto del IVA pagado no acreditable sujeto al estimulo de region fronteriza norte (correspondiente en la proporción de las deducciones autorizadas)
                            </td>
                            <td className="text-right">
                              {diot.information.amountPaidNotCreditableForeing}
                            </td>
                          </tr>

                          <tr>
                            <td>
                              Valor de los actos o actividades pagados en la importación de bienes o servicios a la tasa del 15% o 16% de IVA.	
                            </td>
                            <td className="text-right">
                              {diot.information.imported1516}
                            </td>
                          </tr>

                          <tr>
                            <td>
                              Monto de IVA pagado no acreditable por la importación a la tasa de 15% o 16% de IVA (correspondiente en la deducción de las deducciones autorizadas)	
                            </td>
                            <td className="text-right">
                              {diot.information.importedNotCreditable1516}
                            </td>
                          </tr>

                          <tr>
                            <td>
                              Valor de los actos o actividades pagados en la importación de bienes o servicios a la tasa del 10% o 11% de IVA	
                            </td>
                            <td className="text-right">
                              {diot.information.imported1011}
                            </td>
                          </tr>

                          <tr>
                            <td>
                              Monto de IVA pagado no acreditable por la importación a la tasa de 10% o 11% de IVA (correspondiente en la deducción de las deducciones autorizadas)	
                            </td>
                            <td className="text-right">
                              {diot.information.importedNotCreditable1011}
                            </td>
                          </tr>

                          <tr>
                            <td>
                              Valores de los actos o actividades pagados en la importación de bienes y servicios por los que no se pagara el IVA (Exentos)	
                            </td>
                            <td className="text-right">
                              {diot.information.importedExents}
                            </td>
                          </tr>

                          <tr>
                            <td>
                              Valor de los demas actos o actividades pagados a la tasa del 0% de IVA	
                            </td>
                            <td className="text-right">
                              {diot.information.expenses0}
                            </td>
                          </tr>

                          <tr>
                            <td>
                              Valor de los actos o actividades pagados por los que no se pagara el IVA (Exentos)	
                            </td>
                            <td className="text-right">
                              {diot.information.expensesExents}
                            </td>
                          </tr>

                          <tr>
                            <td>
                              IVA retenido por el contribuyente	
                            </td>
                            <td className="text-right">
                              {diot.information.ivaRetained}
                            </td>
                          </tr>

                          <tr>
                            <td>
                              IVA correspondiente a las devoluciones, descuentos y bonificaciones, sobre las compras	
                            </td>
                            <td className="text-right">
                              {diot.information.ivaDiscount}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  )

                })
              }
            </div>
          </Fragment>
        )
      }
    </div>
  )
}
