import { types } from "../../types/types";

const initialState = {
  captcha: null,
  sesionSat:false,
  loadingValidationSat:false,
}

export const validationSatReducer = (state=initialState, action) => {
  switch (action.type) {
    case types.taxpayerStartValidationSat:
      return{
        ...state,
        captcha: action.payload
      }
    case types.getSessionSat:
      return{
        ...state,
        sesionSat: action.payload
      }
    case types.sessionSatTaxpayerLoading:
      return{
        ...state,
        loadingValidationSat: action.payload
      }

    default:
      return state;
  }
}


