import Swal from "sweetalert2";

const loadingModal = ( flag ) => {
  Swal.fire({
    title: '¡Cargando información!',
    html: 'Por favor espere...',
    allowOutsideClick: false,
    allowEscapeKey: false,
    backdrop:true,
    didOpen: () => {
      if( flag ){
        Swal.showLoading();
      }else{
        Swal.close();
      }

    }
  })
}
const loadingModalMsn = ( flag ,msn) => {
  Swal.fire({
    title: msn,
    html: 'Por favor espere...',
    allowOutsideClick: false,
    allowEscapeKey: false,
    didOpen: () => {
      if( flag ){
        Swal.showLoading();
      }else{
        Swal.close();
      }

    }
  })
}

const SendingEmailModal = ( flag ) => {
  Swal.fire({
    title: '¡Enviando Correo electronico!',
    html: 'Por favor espere...',
    didOpen: () => {
      if( flag ){
        Swal.showLoading();
      }else{
        Swal.close();
      }

    }
  })
}

const saveModal = ( flag ) => {
  Swal.fire({
    title: 'Guardando información!',
    html: 'Por favor espere...',
    allowOutsideClick: false,
    allowEscapeKey: false,
    didOpen: () => {
      if( flag ){
        Swal.showLoading();
      }else{
        Swal.close();
      }

    }
  })
}

export{
  loadingModal,
  loadingModalMsn,
  SendingEmailModal,
  saveModal
}