import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { lineItemDeletedToList } from '../../../../../actions/lineItems';

export const TableLineItems = () => {

  const { lineItemList  } = useSelector(state => state.lineItem);

  const dispatch = useDispatch();

  const deleteLineItem = (lineItemId, e) => {
    dispatch( lineItemDeletedToList({ id: lineItemId }) )
  }

  return (
    <div className="col-md-12">
      <table className="table">
        <thead>
          <tr className="text-center">
            <th scope="col">Cantidad</th>
            <th scope="col">Unidad</th>
            <th scope="col">Clave</th>
            <th scope="col">Concepto</th>
            <th scope="col">Precio unitario</th>
            <th scope="col">Importe</th>
            <th scope="col">Acciones</th>
          </tr>
        </thead>
        <tbody>
          {
            lineItemList.map( ( lineItem, index ) => {
              return(
                <tr className="text-center" key={index}>
                  <td> { lineItem.quantity } </td>
                  <td> Unidad </td>
                  <td> { lineItem.productService } </td>
                  <td> { lineItem.concept } </td>
                  <td> { lineItem.amount } </td>
                  <td> { lineItem.total } </td>
                  <td> 
                    <button
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Eliminar contribuyente"
                      className="btn btn-sm btn-outline-danger px-2 ml-2"
                      onClick={ deleteLineItem.bind(this, lineItem.id) }
                    >
                      <FontAwesomeIcon className="fa-lg" icon={"trash"}/>
                    </button>
                  </td>
                </tr>
              )
            })

          }
        </tbody>
      </table>
    </div>
  )
}
