import swal from "sweetalert";
import { fetchWithApiKey, fetchWithToken } from "../../helpers/fetch";
import { loadingModal, saveModal } from "../../helpers/UseSwal";
import { types } from "../../types/types";

export const preAutorizedReportRegisterStart = (preAutorization, onSucces = null) => {
  return async (dispatch) => {
    loadingModal(true);
    const resp = await fetchWithToken('api/PreAuthorizedReport/create', preAutorization, 'POST');
    const body = await resp.json();
    if (body.success) {
      dispatch(preAutorizationReportCreated(body.data))
      onSucces && onSucces()
    } else {
      if (body.data === 'Finished') {
        dispatch(finishPackage(body.error))
      } else {
        swal(body.error, '', 'info').then(() => {
          window.location.href = '/contador/reportes';
        });
      }
    }
    loadingModal(false);
  }
}

export const changeAuthorizationPreAuthorized = (complement, refreshComplementPayments) => {
  return async (dispatch) => {
    saveModal(true);
    const resp = await fetchWithToken('api/PaymentComplement/updateAuthorization', complement, 'POST');
    const body = await resp.json();
    if (body.success) {
      refreshComplementPayments && refreshComplementPayments()
      saveModal(false);
    }
  }
}

const complementChangeAuthorization = (complement) => ({
  type: types.expenseStartChangeAutorization,
  payload: complement
})

const preAutorizationReportCreated = (preAutorization) => ({
  type: types.preAuthorizationReportStartRegister,
  payload: preAutorization
});

const finishPackage = (taxpayer) => ({
  type: types.finishPackage,
  payload: taxpayer
})

export const preAutorizedReportRegisterMonthPrevStart = (preAutorization) => {
  return async (dispatch) => {
    //saveModal(true);
    const resp = await fetchWithToken('api/PreAuthorizedReport/create', preAutorization, 'POST');
    const body = await resp.json();
    // //console.log(body)
    ////console.log('BODY MONTHPREV:', body, 'PREAUTORIZATION:', preAutorization )    
    if (body.success) {
      dispatch(preAutorizationReportMonthPrevCreated(body.data))
    }
    //saveModal(false);
  }
}

const preAutorizationReportMonthPrevCreated = (preAutorization) => ({
  type: types.preAuthorizationReportStartMonthPrevRegister,
  payload: preAutorization
})

export const preAutorizedReportUpdateStart = (preAutorization, res = false, updateStatus = false) => {
  return async (dispatch) => {
    saveModal(true);
    const resp = await fetchWithToken('api/PreAuthorizedReport/update', preAutorization, 'POST');
    const body = await resp.json();
    if (body.success) {
      dispatch(preAutorizedReportUpdated(body.data))
      if (preAutorization.sendReport == true || res == true) {
        window.location.reload();
      }
      if (updateStatus) {
        dispatch(updateDash(updateStatus))
        swal('Exito', 'Se ha actualizado el estatus', 'success');
      }
    }
    else {
      if (updateStatus) {
        swal('Error', 'No se pudo actualizar el estatus', 'error');
      }
    }
    saveModal(false);
  }
}

const updateDash = (updateData) => ({
  type: types.updateDashInfo,
  payload: updateData
})

export const preAutorizedReportUpdated = (preAutorization) => ({
  type: types.preAuthorizationReportUpdateRegister,
  payload: preAutorization
})

export const preAutorizationReportGetByProfileId = (profileId) => {
  return async (dispatch) => {
    loadingModal(true);
    const resp = await fetchWithToken(`api/PreAuthorizedReport/getByProfileId/${profileId}`);
    const body = await resp.json();
    if (body.success) {
      dispatch(preAutorizationReportByProfileLoaded(body.data))
    }
    loadingModal(false);
  }
}

const preAutorizationReportByProfileLoaded = (preAutorizations) => ({
  type: types.preAuthorizationReportGetByProfile,
  payload: preAutorizations
})

export const preAuthorizedReportCheckingToken = (token) => {
  return async (dispatch) => {
    loadingModal(true);
    const resp = await fetchWithApiKey(`api/PreAuthorizedReport/getFromLink/${token}`);
    const body = await resp.json();
    if (body.success) {
      dispatch(preAuthorizedReportLoadedToken(body.data));
      dispatch(activityLoaded(body.data.profile))
      body.data.periods.incomes && dispatch(incomesLoaded(body.data.periods.incomes));
      body.data.periods.expenses && dispatch(expensesLoaded(body.data.periods.expenses));
      body.data.periods.retentions && dispatch(retentionsLoaded(body.data.periods.retentions));

    }
    loadingModal(false);
  }
}

const preAuthorizedReportLoadedToken = (authorizations) => ({
  type: types.preAuthorizationReportCheckToken,
  payload: authorizations
})

const incomesLoaded = (incomes) => ({
  type: types.incomeStartLoading,
  payload: incomes
})

const expensesLoaded = (expenses) => ({
  type: types.expenseStartLoading,
  payload: expenses
})

const retentionsLoaded = (retentions) => ({
  type: types.retentionsStartLoading,
  payload: retentions
})

const activityLoaded = (profile) => ({
  type: types.taxpayerActivity,
  payload: profile
})

export const preAuthorizationReportUpdateToken = (token, updated) => {
  return async (dispatch) => {
    const resp = await fetchWithApiKey(`api/PreAuthorizedReport/updateFromLink/${token}`, updated, 'POST');
    const body = await resp.json();
    if (body.success) {
      dispatch(preAuthorizedReportUpdatedToken(updated.comment))
    }
  }
}

const preAuthorizedReportUpdatedToken = (report) => ({
  type: types.preAuthorizationReportUpdateToken,
  payload: report
});

export const preAuthorizationReportAutorized = (token, updated) => {
  return async (dispatch) => {
    saveModal(true);
    const resp = await fetchWithApiKey(`api/PreAuthorizedReport/updateFromLink/${token}`, updated, 'POST');
    const body = await resp.json();
    if (body.success) {
      dispatch(autorizationReport(true))
    }
    saveModal(false);
  }
}

const autorizationReport = (isAuthorized) => ({
  type: types.authorizationReportStatus,
  payload: isAuthorized
})

export const preAuthorizationReportAutorizedDepreciation = (irsJson, handleModal = null) => {

  return async (dispatch) => {
    dispatch(autorizationReportAddDepreciation(irsJson))
    if (handleModal) {
      handleModal();
    }
    saveModal(false);

  }
}

const autorizationReportAddDepreciation = (irsJson) => ({
  type: types.preAuthorizationReportDepreciation,
  payload: irsJson
})

export const updateStatementsBalanceRequest = (data, handleModal, assistedFinish, taxpayer) => {
  return async (dispatch) => {
    saveModal(true);
    const resp = await fetchWithToken('api/PreAuthorizedReport/updateStatementsBalance', data, 'POST');
    const body = await resp.json();
    if (body.success) {
      dispatch(updatePackageToPresentTaxpayer(data));
      handleModal();
      let profileRegimesNew = taxpayer.profileRegimes.map(
        e => (e.regimeId === data.regimeId) ?
          {
            ...e,
            statics: {
              ...e.statics,
              toPresent: data.increase
            }
          }
          : e
      )
      let newData = {
        ...taxpayer,
        profileRegimes: profileRegimesNew
      }
      if (!assistedFinish) {
        dispatch(updateDash(newData))
      } else {
        dispatch(updateDashPush(newData))
      }
    }
    else {
      swal('Error', body.error, 'error');
    }
    saveModal(false);
  }
}

const updateDashPush = (updateData) => ({
  type: types.updateDashInfoPush,
  payload: updateData
})

const updatePackageToPresentTaxpayer = (requests) => ({
  type: types.updatePackageToPresent,
  payload: requests
})

export const updateStatusReport = (data) => {
  return async (dispatch) => {
    saveModal(true);
    let newData = {
      ...data,
      profile: {
        ...data.profile,
        automaticStatement: !data.profile.automaticStatement
      }
    }
    let flag = !data.profile.automaticStatement;
    let profileId = data.profile.id;
    const resp = await fetchWithToken(`api/Profile/updateFlagAutomaticStatement/${profileId}/${flag}`);
    const body = await resp.json();
    if (body.success) {
      dispatch(updateDash(newData))
    }
    else {
      swal('Error', body.error, 'error');
    }
    saveModal(false);
  }
}