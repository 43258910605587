import { TextField } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { documentTaxTaxpayerTypesStartLoading } from '../../../../../../../../actions/catalogs/documentTaxTaxpayerTypes';
import { TaxdocumentsStartRegister, TaxDocumentsStartLoading } from '../../../../../../../../actions/taxpayers/TaxDocuments';
import { validateFilePresence } from '../../../../../../../../helpers/validateForm';
import { useForm } from '../../../../../../../../hooks/useForm';

export const FilesScreen = React.memo(({month, reportId, taxDocuments, taxpayerId, year, status}) => {

  const {documentTaxTaxpayerTypes} = useSelector(state => state.documentTaxTaxpayerType)

  const dispatch = useDispatch();

  const {formValues, handleFileInputChange, handleInputChange, setFormValues} = useForm({
    documentTaxId: 0,
    files: '',
  });

  const [msgErrors, setMsgErrors] = useState({});

  const { documentTaxId, files } = formValues;

  useEffect(() => {
    if (documentTaxTaxpayerTypes.length === 0) {
      dispatch(documentTaxTaxpayerTypesStartLoading());
    }
  }, [dispatch, documentTaxTaxpayerTypes]);

  useEffect(() => {
    if (reportId > 0) {
      dispatch(TaxDocumentsStartLoading(reportId))
    }
  }, [dispatch, reportId])

  useEffect(() => {
    if (documentTaxTaxpayerTypes.length > 0) {
      setFormValues(prevState => {
        return{
          ...prevState,
          documentTaxId: documentTaxTaxpayerTypes[0].id
        }
      })
    }
  }, [documentTaxTaxpayerTypes, setFormValues]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if(isFormValid()) {
      let formData = new FormData();
      formData.append('Files', files);
      formData.append('taxDocumentTypeId', documentTaxId);
      formData.append('profileId', taxpayerId);
      formData.append('ExtraData[0].key', 'Period');
      formData.append('ExtraData[0].Value', `${month}-${year}`);
      formData.append('PreAuthorizedReportId', reportId)

      dispatch(TaxdocumentsStartRegister(formData))
    }
  }

  const isFormValid = () => {
    setMsgErrors({});
    const datasFilesToValidate = { files }
    const validateFiles = validateFilePresence( datasFilesToValidate, setMsgErrors );
    if( validateFiles ){
      return true;
    }
    return false;
  }

  const donwloadFile = (taxDocument, e) => {
    e.preventDefault();

    const linkSource = `data:application/pdf;base64,${taxDocument.documentContent}`;
    const downloadLink = document.createElement("a");
    const fileName = `${taxDocument.documentName}.pdf`;

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }

  return (
    <div className="row">
      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2">
        <div className="card">
          <div className="card-header">
            <div className="row">
              <div className="col-xs-8 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <h5>Documentos fiscales</h5>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2 mt-4">
        <div className="row">
          <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-center">
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <TextField
                  select
                  variant="outlined"
                  label="Tipos de documento"
                  size="small"
                  fullWidth={true}
                  name="documentTaxId"
                  value={documentTaxId}
                  onChange={handleInputChange}
                  SelectProps={{
                    native: true,
                  }}
                >
                  {
                    documentTaxTaxpayerTypes.length === 0 ?
                    (
                      <option value="0">No se encontró algún registro</option>
                    )
                    :
                    (
                      documentTaxTaxpayerTypes.map((documentType) => {
                        return(
                          <option
                            key={ documentType.id }
                            value={ documentType.id }
                          > 
                            { documentType.description }
                          </option>  
                        )
                      })
                    )
                  }
                </TextField>
              </div>
              <div className="form-group">
                <TextField label="Archivo (.PDF)" variant="outlined" size="small" autoComplete="off" fullWidth={true} 
                  InputLabelProps={{shrink: true,}}
                  type="file"
                  name="files"
                  onChange={ handleFileInputChange }
                  helperText={ msgErrors.files }
                  error={ msgErrors.files !== undefined }
                  inputProps={{ accept: '.pdf' }}
                />
              </div>
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3 text-right pr-0">
                {
                  status !== 'Presentada' &&(
                    <button className="btn btn-outline-primary">
                      <i className="fas fa-plus"></i> Guardar
                    </button>
                  )
                }
              </div>
            </form>
          </div>

          <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-2 mt-4 text-center">
            {
              taxDocuments.map((taxDocument, index) => {
                return(
                  <div key={index}>
                    <button className="btn btn-primary"  
                      onClick={donwloadFile.bind(this, taxDocument)}
                    >
                      Descargar - {taxDocument.documentName}
                    </button>
                  </div>   
                )
              })
            }
          </div>
        </div>
      </div>
    </div>
  )
})