import { types } from "../../types/types";

const initialState = {
  crmSalesRenovations: [],
  crmSales: [],
  crmSaleSelected: null,
}

export const crmSaleReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.crmSalesStartLoading:
      return{
        ...state,
        crmSales: [ ...action.payload ],
        crmSaleSelected: null
      }

    case types.crmSaleRenovationsStartLoading:
      return{
        ...state,
        crmSalesRenovations: [...action.payload]
      }

    case types.crmSaleOptainSelected:
      return{
        ...state,
        crmSaleSelected: action.payload
      }
    
    case types.crmSaleClearSelected:
      return{
        ...state,
        crmSaleReducer: null
      }

    case types.crmSaleActive:
      return{
        ...state,
        crmSales: state.crmSales.map(
          e => ( e.saleId === action.payload ) ? 
            {
              ...e,
              isAuthorized: true
            } 
          : 
            e
        )
      }

      case types.crmRenovationActive:
        return{
          ...state,
          crmSalesRenovations: state.crmSalesRenovations.map(
            e => ( e.id === action.payload ) ? 
              {
                ...e,
                isAuthorized: true
              } 
            : 
              e
          )
        }

    case types.crmSaleRenovationVouchersStartLoading:
      return{
        ...state,
        crmSalesRenovations: state.crmSalesRenovations.map(
          e => (e.id === action.payload.saleId) ?
            {
              ...e,
              vouchers: action.payload.vouchers
            }
          :
            e
        )
      }

    case types.crmSaleVoucherStartLoading:
      return{
        ...state,
        crmSales: state.crmSales.map(
          e => (e.saleId === action.payload.saleId) ?
            {
              ...e,
              vouchers: action.payload.vouchers
            }
          :
            e
        )
      }
  
    default:
      return state;
  }
}
