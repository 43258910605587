import { useDispatch } from "react-redux";
import swal from "sweetalert";

export const UseSwal = () => {

  const dispatch = useDispatch();
  
  const changeActivationModal = ( action ) => {
    swal({
      title: "¿Estas seguro?",
      text: "Una vez desactivado ¡no podrá usar en ningun proceso posterior!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
    .then((willDelete) => {
      if (willDelete) {
        dispatch( action )
      }
    })
  }

  const activationModal = ( action ) => {
    swal({
      title: "¿Estas seguro?",
      text: "¿Quieres activar este registro?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
    .then((willDelete) => {
      if (willDelete) {
        dispatch( action )
      }
    })
  }

  const navigationPage = ( history,msn) => {
    swal({
      title: msn,
     // text: "",
      icon: "warning",
      dangerMode: true,
      closeOnClickOutside:false
    })
    .then((willDelete) => {
      if (willDelete) {
        history.push('/')
      }
    })
  }

  const changeStatusReportModal = ( action,status) => {
    let msn='Una vez cambiado el status ¡no podrá presentarse de manera automatica!';
    if(!status){
      msn='Una vez cambiado el status ¡se presentaran de manera automatica!';
    }
    swal({
      title: "¿Estas seguro?",
      text: msn,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
    .then((willDelete) => {
      if (willDelete) {
        dispatch( action )
      }
    })
  }

  return {
    activationModal,
    changeActivationModal,
    navigationPage,
    changeStatusReportModal
  }
}
