import { fetchWithToken } from "../../helpers/fetch";
import { saveModal } from "../../helpers/UseSwal";
import { types } from "../../types/types";

export const complementStartLoading = (period) => {
  return async (dispatch) => {
    const resp = await fetchWithToken(`api/PaymentComplement/get/${period.profileId}/${period.paymentAt}/${period.regimeSatCode}`);
    const body = await resp.json();
    if (body.success) {
      //console.log(body)
      dispatch(complementsLoaded(body.data.sort(function (a, b) { return new Date(a.stampAt) - new Date(b.stampAt) })))
    }
  }
}

const complementsLoaded = (complement) => ({
  type: types.complementStartLoad,
  payload: complement
})

export const complementsAddExpense = (data) => ({
  type: types.complementAddExpense,
  payload: data
})

export const complementsAddIncome = (data) => ({
  type: types.complementAddIncome,
  payload: data
})


