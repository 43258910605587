import { types } from "../../types/types"

const initialState = {
  SMTActives: []
}

export const socialMediaTypeReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.socialMediaTypeLoadActives:
      return{
        ...state,
        SMTActives: action.payload
      }

    default:
      return state
  }
}
