import { types } from "../../types/types";

const initialState = {
  thirdPartyTypes: []
}

export const thirdPartyTypeReducer = (state=initialState, action) => {
  switch (action.type) {
    case types.thirdPartyTypeStartLoadind:
      return{
        ...state,
        thirdPartyTypes: [ ...action.payload ]
      }

    default:
      return state;
  }
}
