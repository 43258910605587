export const initialoptionIsrSimplified = {
    lowerLimit: 0,
    upperLimit:0,
    porcentage: 0.00,
    taxeBeforeRetentions:0.00,
    isrRetentions:0.00,
    retentionToPay:0.00,
  }

  export const obtainIsrSimplifiedPorcentage = (columns, amount, isrRetention) => {
    let option = {};
    columns.forEach(column => {
      if(parseFloat(amount) >= column.lowerLimit && parseFloat(amount)<=column.upperLimit){
        const porcentage = column.porcentage;
        const taxeBeforeRetentions = (parseFloat(porcentage) * parseFloat(amount)).toFixed(6);
        const isrRetentions = parseFloat(isrRetention);

        let retentionToPay = parseFloat(taxeBeforeRetentions) - (parseFloat(isrRetentions))

        if(retentionToPay < 0){
          retentionToPay = 0.00;
        } 

        option = {
          ...column,
          porcentage:porcentage,
          taxeBeforeRetentions:taxeBeforeRetentions,
          isrRetentions: isrRetentions,
          retentionToPay:retentionToPay
        }
      }
    });

    if(Object.keys(option).length === 0){
      option = initialoptionIsrSimplified;
    }

    return option;
  } 