import { library } from '@fortawesome/fontawesome-svg-core';

import {
  faAddressBook,
  faAddressCard,
  faBook,
  faEdit,
  faEnvelope,
  faIdCard,
  faLock,
  faPencilAlt,
  faPhoneAlt,
  faPlus,
  faTrash,
  faUser,
  faUserCheck,
  faUserLock,
  faUsers,
  faCheckCircle,
  faTimesCircle,
  faMapMarkedAlt,
  faShoppingCart,
  faCheck,
  faTimes,
  faSearch,
  faUserTie,
  faChartLine,
  faEllipsisV,
  faEnvelopeOpenText,
  faList,
  faUserTag,
  faLink,
  faCopy
} from '@fortawesome/free-solid-svg-icons';

library.add(
  faAddressBook,
  faAddressCard,
  faEdit,
  faBook,
  faEnvelope,
  faIdCard,
  faLock,
  faPencilAlt,
  faPhoneAlt,
  faPlus,
  faTrash,
  faUser,
  faUserCheck,
  faUserLock,
  faUsers,
  faCheckCircle,
  faTimesCircle,
  faMapMarkedAlt,
  faShoppingCart,
  faCheck,
  faTimes,
  faSearch,
  faUserTie,
  faChartLine,
  faEllipsisV,
  faEnvelopeOpenText,
  faList,
  faUserTag,
  faLink,
  faCopy
);