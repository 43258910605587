import React from 'react';
import { useSelector } from 'react-redux';
import { HonorariumBussinessIvaCalculation } from './ivaOptions/HonoratiumBussinessIvaCalculation';
import { RifIvaCalculation } from './ivaOptions/RifIvaCalculation';
import { TechnologyPlataformIvaCalculation } from './ivaOptions/TechnologyPlataformIvaCalculation';
import { LeaseIvaCalculation } from './ivaOptions/LeaseIvaCalculation';
import { SimplifiedIvaCalculation } from './ivaOptions/SimplifiedIvaCalculation';

export const IvaTaxCalculation = ({ formValuesDetailsSummary, regimeSatCode, setFormValuesDetailsSummary, autorizedReport, status }) => {
  const { taxpayerProfession } = useSelector(state => state.taxpayer);
  return (
    <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
      <h5 className="card-header">IVA</h5>
      <div className="row">
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2">
          <table className="table">
            <tbody>
              {
                regimeSatCode === '621' && (
                  <RifIvaCalculation
                    formValuesDetailsSummary={ formValuesDetailsSummary }
                    setFormValuesDetailsSummary={setFormValuesDetailsSummary}
                    status={status}
                  />
                )
              }

              {
                regimeSatCode === '612' && taxpayerProfession !== 'Médico/Dentista' && (
                  <HonorariumBussinessIvaCalculation
                    formValuesDetailsSummary={formValuesDetailsSummary}
                    setFormValuesDetailsSummary={setFormValuesDetailsSummary}
                    status={status}
                  />
                )
              }

              {
                regimeSatCode === '625' && (
                  <TechnologyPlataformIvaCalculation
                    autorizedReport={autorizedReport}
                    formValuesDetailsSummary={ formValuesDetailsSummary }
                    setFormValuesDetailsSummary={setFormValuesDetailsSummary}
                    status={status}
                  />
                )
              }

              {
                regimeSatCode === '606' && (
                  <LeaseIvaCalculation
                    autorizedReport={autorizedReport}
                    formValuesDetailsSummary={ formValuesDetailsSummary }
                    setFormValuesDetailsSummary={ setFormValuesDetailsSummary }
                    status={status}
                  />
                )
              }
              {
                regimeSatCode === '626' && (
                  <SimplifiedIvaCalculation
                    autorizedReport={autorizedReport}
                    formValuesDetailsSummary={ formValuesDetailsSummary }
                    setFormValuesDetailsSummary={ setFormValuesDetailsSummary }
                    status={status}
                  />
                )
              }
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}
