

export const searchThirdPartyById = (thirdParties, thirdPartyId) => {
  const thirdParty = thirdParties.filter(e => ( e.id === parseInt(thirdPartyId) ))[0];
  let flag = true;

  if (thirdParty.rfc.length >= 13) {
    flag = false;
  }

  return{
    flag: flag,
    thirdParty: thirdParty
  }
}

export const searchThirdPartyByIdForeign = (thirdParties, thirdPartyId) => {
  const thirdParty = thirdParties.filter(e => ( e.id === parseInt(thirdPartyId) ))[0];
  let flag = false;

  if (thirdParty.rfc === 'XEXX010101000') {
    flag = true;
  }

  return{
    flag: flag,
    thirdParty: thirdParty
  }
}

export const thirdPartyObtainClients = (thirdParties) => {
  let thirdPartyClients = [];
  thirdParties.forEach(thirdParty => {
    if (thirdParty.thirdPartieType !== undefined) {
     JSON.parse(thirdParty.thirdPartieType).forEach(thirdPartyType => {
      if(thirdPartyType.Key === 'CLIE'){
        thirdPartyClients.push(thirdParty);
      }
    })
    }else{
      thirdParty.thirdPartieTypes.forEach(thirdPartyType => {
        if(thirdPartyType.Key === 'CLIE'){
          thirdPartyClients.push(thirdParty);
        }
      })
    }

  });
  return thirdPartyClients;
}

export const thirdPartyObtainProviders = (thirdParties) => {
  let thirdPartyProviders = [];
  thirdParties.forEach(thirdParty => {
    if (thirdParty.thirdPartieType !== undefined) {
      JSON.parse(thirdParty.thirdPartieType).forEach(thirdPartyType => {
        if(thirdPartyType.Key === 'PROV'){
          thirdPartyProviders.push(thirdParty);
        }
      })
    }else{
      thirdParty.thirdPartieTypes.forEach(thirdPartyType => {
        if(thirdPartyType.Key === 'PROV'){
          thirdPartyProviders.push(thirdParty);
        }
      })
    }
  });
  return thirdPartyProviders;
}