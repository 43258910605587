import React, { useEffect, useState } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import validator from 'validator';
import { Checkbox, FormControlLabel, FormGroup, FormHelperText, TextField, InputAdornment, IconButton } from '@material-ui/core';
import { useForm } from '../../../hooks/useForm';
import { startRegister, validatePartner, validatePartnerLink } from '../../../actions/auth';
import * as ROUTES from '../../constants/Routes';
import './Style.scss';
import img_Register from '../principal/img/img_Register.png';
import EyeOpenIcon from './eyeIcon/EyeOpen';
import EyeClosedIcon from './eyeIcon/EyeClose';
import { useLocation } from "react-router-dom";


const RegisterScreen = () => {
  let location = useLocation();
  const [, , codigo] = location.pathname.split("/");
  const { authPartner } = useSelector(state => state.authPartner);
  const initialFormValues = {
    email: '',
    rfc: '',
    phone: '',
    ciec: '',
    code: codigo !== null ? codigo : ''
  };

  const dispatch = useDispatch();
  const { formValues, handleCheckboxTrueFalseChange, setFormValues } = useForm(initialFormValues);
  const { rfc, ciec, phone, email, code, acceptTerms } = formValues;
  const [msgErrors, setMsgErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {

    if (codigo != null || codigo != undefined) {
      dispatch(validatePartnerLink(codigo));
    };
    if (authPartner === 'null') {
      dispatch(validatePartner(code));
    }
  }, [authPartner, dispatch]);

  const validatePartnerCode = (code) => {
    if (code.trim().length === 0) {
      return;
    }
    dispatch(validatePartner(code));
  }

  const handleRegister = (e) => {
    e.preventDefault();

    if (isFormValid()) {
      const { acceptTerms, ...formData } = formValues;
      dispatch(startRegister(formData));
    }
  }






  let typingTimer;

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    let normalizedValue = value.trim();
    if (e.target.name === 'rfc') {
      normalizedValue = normalizedValue.toUpperCase();
      normalizedValue = normalizedValue.replace(/[^a-zA-Z0-9]/g, '');
    }
    if (e.target.name === 'phone') {
      normalizedValue = normalizedValue.replace(/[^0-9]/g, '');
    }
    setFormValues({
      ...formValues,
      [name]: normalizedValue
    });

    if (name !== 'code') {
      clearTimeout(typingTimer);
    }

    if (name === 'code' || name === 'codigo') {
      clearTimeout(typingTimer);
      typingTimer = setTimeout(() => validatePartnerCode(normalizedValue), 2000);
    }

    if (name === 'code' || name === 'codigo') {
      if (normalizedValue === '') {
        setFormValues({
          ...formValues,
          [name]: normalizedValue,
          codeIsEmpty: false
        });
      } else {
        setFormValues({
          ...formValues,
          [name]: normalizedValue,
          codeIsEmpty: true
        });
      }
    }
  }


  const isFormValid = () => {
    const errors = {};
    if (rfc.trim().length === 0) {
      errors['msgrfc'] = 'Es requerido.';
    }
    if (ciec.trim().length === 0) {
      errors['msgciec'] = 'Es requerido.';
    }
    if (!validator.isMobilePhone(phone, ['es-MX'])) {
      errors['msgPhone'] = 'Teléfono no es valido.';
    }
    if (!validator.isEmail(email)) {
      errors['msgEmail'] = 'Correo electrónico no es valido.';
    }
    if (!acceptTerms) {
      errors['msgAcceptTerms'] = "Es necesario aceptar."
    }

    setMsgErrors(errors);

    if (Object.keys(errors).length !== 0) {
      return false;
    }

    return true;
  }

  return (
    <div className="container" style={{ marginRight: '200px' }}>
      <div className="row justify-content-center mt-2 animate__animated animate__fadeInRight">
        <div className="col-lg-8 col-md-12">
          <div className="card shadow">
            <div className="card-body">
              <div className="text-center pb-1">
                <h3>Registrar nueva cuenta</h3>
              </div>

              <form className="mt-4" onSubmit={handleRegister}>
                <div className="row">
                  <div className="col-md-6 mb-3">
                    <TextField
                      label="Correo Electrónico"
                      id="email"
                      variant="outlined"
                      size="small"
                      name="email"
                      value={email}
                      onChange={handleInputChange}
                      autoComplete="off"
                      fullWidth={true}
                      helperText={msgErrors.msgEmail}
                      error={msgErrors.msgEmail !== undefined}
                    />
                  </div>
                  <div className="col-md-6 mb-3">
                    <TextField
                      label="RFC"
                      variant="outlined"
                      size="small"
                      id="rfc"
                      name="rfc"
                      value={rfc}
                      onChange={handleInputChange}
                      autoComplete="off"
                      fullWidth={true}
                      helperText={msgErrors.rfc}
                      error={msgErrors.rfc !== undefined}
                      inputProps={{
                        maxLength: 13
                      }}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 mb-3">
                    <TextField
                      label="Teléfono"
                      id="phone"
                      variant="outlined"
                      size="small"
                      name="phone"
                      value={phone}
                      onChange={handleInputChange}
                      autoComplete="off"
                      fullWidth={true}
                      helperText={msgErrors.msgPhone}
                      error={msgErrors.msgPhone !== undefined}
                    />
                  </div>
                  <div className="col-md-6 mb-3">
                    <TextField
                      label="Clave CIEC"
                      id="ciec"
                      variant="outlined"
                      size="small"
                      name="ciec"
                      value={ciec}
                      onChange={handleInputChange}
                      autoComplete="off"
                      fullWidth={true}
                      helperText={msgErrors.msgciec}
                      error={msgErrors.msgciec !== undefined}
                      type={showPassword ? 'text' : 'password'}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                              {showPassword ? <EyeOpenIcon /> : <EyeClosedIcon />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                  <div className="col-md-12 mb-3">
                    <TextField
                      label="Código de Promoción"
                      id="code"
                      variant="outlined"
                      size="small"
                      name="code"
                      value={code}
                      onChange={(e) => handleInputChange(e, 'code')}
                      autoComplete="off"
                      fullWidth={true}
                      inputProps={{ style: { textTransform: 'none' } }}
                      disabled={authPartner !== null} // Deshabilitar el campo si authPartner no es null
                    />
                  </div>

                </div>

                <div className="col-md-12 text-center mb-3">
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          name="acceptTerms"
                          value={acceptTerms}
                          onChange={handleCheckboxTrueFalseChange}
                          checked={acceptTerms}
                        />
                      }
                      label={
                        <span>
                          Acepto los <a href="https://contabilizate.com/terminos_y_condiciones">Términos y condiciones</a>
                        </span>
                      }
                    />

                    {msgErrors.msgAcceptTerms && (
                      <FormHelperText
                        align="center"
                        style={{ textAlign: 'center' }}
                        error
                      >
                        {msgErrors.msgAcceptTerms}
                      </FormHelperText>
                    )}

                  </FormGroup>
                </div>

                <div className="row">
                  <div className="col-md-12 text-center">
                    <button type="submit" className="btn btn-success" disabled={formValues.codeIsEmpty && authPartner === null}>Registrar</button>
                  </div>
                  <div className="col-md-12 mt-3 text-center">
                    <p style={{ fontSize: '14px', color: '#555', fontStyle: 'italic' }}>Después de registrarse, favor de esperar a que nuestro asesor se contacte con usted.</p>
                  </div>
                  <div className="col-md-12 text-center">
                    <button
                      className="btn btn-success"
                      type='button'
                      onClick={() => {
                        window.location.href = "https://wa.me/5617564391";
                      }}
                    >
                      <i className="fab fa-whatsapp"></i> Ayuda
                    </button>
                  </div>
                </div>
                <hr />
              </form>

              <div className="row">
                <div className="col-md-6 text-center">
                  <strong>¿Olvidaste tu contraseña?</strong>
                  <p>
                    <NavLink to={ROUTES.RECUPERATE_PASSWORD}>
                      No te preocupes, haz click aquí para recuperarla.
                    </NavLink>
                  </p>
                </div>
                <div className="col-md-6 text-center">
                  <strong>¿Ya eres usuario?</strong>
                  <p>
                    <NavLink to={ROUTES.LOGIN}>
                      Haz click aquí para iniciar sesión.
                    </NavLink>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 d-none d-md-block">
          <img src={img_Register} alt="Imagen al lado del formulario" className="img-fluid" />
        </div>
      </div>
    </div>
  );
}

export default withRouter(RegisterScreen);