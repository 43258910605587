import React, { useEffect, useState, useContext } from 'react';
import moment from 'moment'
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { userGuests } from '../../../../../helpers/clasificateUsers';
import { Pagination } from '../../../../../helpers/tables/Pagination';
import { UserForm } from '../userForm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ModalContext } from '../../../../modal/ModalContext';
import { InputSearching } from '../../../../../helpers/tables/InputSearching';
import { CSVLink } from 'react-csv';
import { taxpayerAssitedsStartLoading } from '../../../../../actions/taxpayers';

const ShowCustomers = () => {

    const { dashboard } = useSelector(state => state.taxpayer);
    const { handleModal } = useContext( ModalContext );
    
    const {users} = useSelector(state => state.userType)

    const { isBoss } = useSelector(state => state.auth.user);
    const [usersCustomers, setUsersCustomers] = useState([]);

    const [customersSearch, setCustomersSearch] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);

    const [nameFilter, setNameFilter] = useState('Todos');
    const [countAll, setCountAll] = useState(0);
    const [countAuthorized, setCountAuthorized] = useState(0);
    const [countNotAuthorized, setCountNotAuthorized] = useState(0);
    const [countActive, setCountActive] = useState(0);
    const [countNotActive, setCountNotActive] = useState(0);
    const [countAssited, setCountAssited] = useState(0);
    const [countFinish, setCountFinish] = useState(0);
    const [countNotSale, setCountNotSale] = useState(0);
    const [selfManagement, SetSelfManagement] =useState(0)

    const dispatch = useDispatch();
    const [showDash, setShowDash] = useState(false);

    let dashPresented = dashboard.presented || 0;
    let dashToPresented = dashboard.toPresented || 0;
    let dashTotal = dashToPresented - dashPresented || 0;

    const headers = [
        { label: 'Cliente', key: 'fullName' },
        { label: 'Rfc', key: 'rfc' },
        { label: 'Correo', key: 'email' },
        { label: 'Telefono', key: 'phoneMobile' },
        { label: 'Venta autorizada', key: 'saleAuthorized' },
        { label: 'Cuenta activa', key: 'isActive' },
        { label: 'Vendedor', key: 'saleSeller' },
      ]

    useEffect(()=>{
        dispatch(taxpayerAssitedsStartLoading());
        setCustomersSearch(userGuests(users))
        setUsersCustomers(userGuests(users))
    },[users])

    useEffect(()=>{
        setCountAll(usersCustomers.length);
        setCountAuthorized(usersCustomers.filter(e => ( e.saleAuthorized === 'SI')).length);
        setCountNotAuthorized(usersCustomers.filter(e => ( e.saleAuthorized === 'NO')).length);
        setCountActive(usersCustomers.filter( e => ( e.isActive === 'SI')).length);
        setCountNotActive(usersCustomers.filter( e => ( e.isActive === 'NO')).length);
        setCountAssited(usersCustomers.filter( e => ( e.isFinish === 'Activo')).length);
        setCountFinish(usersCustomers.filter( e => ( e.isFinish === 'Terminado')).length);
        setCountNotSale(usersCustomers.filter( e => ( e.saleAuthorized == 'Sin venta')).length);
        SetSelfManagement(usersCustomers.filter( e => ( e.productIsAssisted == 'NO')).length);

    },[customersSearch])

   
   // useEffect(() => {
    const cambioPrueba = (typee) => {
        switch (typee) {
            case 1:
                    setCustomersSearch(usersCustomers.filter(e => ( e.saleAuthorized === 'SI')))
                    setNameFilter('Usuarios Venta Aprobada')
                    return
            case 2:
                    setCustomersSearch(usersCustomers.filter(e => ( e.saleAuthorized === 'NO')))
                    setNameFilter('Venta en Proceso de Autorización')
                    return
            case 3:
                    setCustomersSearch(usersCustomers.filter( e => ( e.saleAuthorized == 'Sin venta')))
                    setNameFilter('Pre-registro desde App')
                    return
            case 4:
                    setCustomersSearch(usersCustomers.filter( e => ( e.isActive === 'SI')))
                    setNameFilter('Usuarios Cuenta Activa')
                    return
            case 5:
                    setCustomersSearch(usersCustomers.filter( e => ( e.isActive === 'NO')))
                    setNameFilter('Pendiente activación App')
                    return
            case 6:
                    setCustomersSearch(usersCustomers.filter( e => ( e.isFinish === 'Activo')))
                    setNameFilter('Usuarios Asistidos Activos')
                    return
            case 7:
                    setCustomersSearch(usersCustomers.filter( e => ( e.isFinish === 'Terminado')))
                    setNameFilter('Usuarios Asistidos Terminados')
                    return
            case 8:
                    setCustomersSearch(usersCustomers.filter( e => ( e.productIsAssisted === 'NO')))
                    setNameFilter('Usuarios Autogestión')
                    return
            case 9: 
                    setCustomersSearch(usersCustomers.filter( e => ( e.isFinish === 'Activo' || e.saleAuthorized === 'NO' || e.isActive === 'NO' || e.productIsAssisted === 'NO')))
                    setNameFilter('Usuarios Activos')
                    return
            default:
                 setCustomersSearch(usersCustomers)
                 setNameFilter('Todos')
        }
    }

    return (
        <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2">
                <div className="card">
                    <div className="card-body">
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3">
                        <span>Tipo de grafica</span>
                            <div className="row">
                                <div className="col-md-6 mb-3">
                                <div className="form-check">
                                    <input 
                                    className="form-check-input" 
                                    type="checkbox"
                                    name="showDash" 
                                    onChange={ () => setShowDash(!showDash) }
                                    value={ showDash }
                                    checked={showDash}
                                  
                                    />
                                    <label className="form-check-label">
                                    Reporte General
                                    </label>
                                </div>
                                </div>
                                <div className="col-md-6 mb-3">
                                <div className="form-check">
                                    <input 
                                    className="form-check-input" 
                                    type="checkbox"
                                    name="showDash" 
                                    onChange={ () => setShowDash(!showDash) }
                                    value={ showDash }
                                    checked={!showDash}
                                    />
                                    <label className="form-check-label">
                                    Reporte Activos
                                    </label>
                                </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            {showDash &&
                                <div className="col-xs-12 col-sm-12 col-md-12 table-responsive">
                                    <table class="table table-bordered table-striped">
                                    <tbody>
                                        <tr>
                                            <td className='text-center text-white bg-info align-middle' data-toggle="tooltip" data-placement="bottom" title="Usuarios registrados en contabox" colspan="12" style={{cursor: 'pointer',BorderTopRounded:"6px"}} onClick={ () => cambioPrueba(0)}><div class="d-flex justify-content-center"> <i className=" fas fa-users fa-3x mr-3"></i>Todos <br/>{countAll} </div></td>
                                        </tr>
                                        <tr>
                                            <td className='text-center text-white bg-primary' colspan="8"  style={{cursor: 'pointer'}} onClick={ () => cambioPrueba(1)}><div class="d-flex justify-content-center"> <i className=" fas fa-check fa-3x mr-3"></i>Venta Autorizadas <br/>{countAuthorized} </div></td>  
                                            <td className='text-center text-dark bg-warning align-middle  ' rowSpan={3} colspan="2"  style={{cursor: 'pointer'}} onClick={ () => cambioPrueba(2)} ><div class="d-flex justify-content-center"> <i className=" fas fa-lock fa-3x m-2 md-mr-3"></i>Venta en Proceso de Autorización  <br/>{countNotAuthorized} </div></td>
                                            <td className='text-center text-white bg-secondary align-middle' rowSpan={3} colspan="2"  style={{cursor: 'pointer'}} onClick={ () => cambioPrueba(3)} ><div class="d-flex justify-content-center"> <i className=" fas fa-address-card fa-3x mr-3"></i>Pre-registro desde App <br/>{countNotSale} </div></td>
                                        </tr>
                                        <tr>
                                            <td className='text-center text-white bg-success' colspan="6"  style={{cursor: 'pointer'}} onClick={ () => cambioPrueba(4)} ><div class="d-flex justify-content-center"> <i className=" fas fa-user-check fa-3x mr-3"></i>Cuenta Activa <br/>{countActive} </div></td>
                                            <td className='text-center text-white bg-danger align-middle' rowSpan={3} colspan="2"  style={{cursor: 'pointer'}} onClick={ () => cambioPrueba(5)} ><div class="d-flex justify-content-center"> <i className=" fas fa-user-lock fa-3x m-2"></i>Pendiente activación App <br/>{countNotActive} </div></td>
                                        </tr>
                                        <tr>
                                            <td className='text-center text-dark bg-light' colspan="2"   style={{cursor: 'pointer'}} onClick={ () => cambioPrueba(6)} ><div class="d-flex justify-content-center"> <i className=" fas fa-book fa-3x mr-3"></i>Asistidos Activos <br/>{countAssited} </div></td>
                                            <td className='text-center text-white bg-dark' colspan="2"  style={{cursor: 'pointer'}} onClick={ () => cambioPrueba(7)} ><div class="d-flex justify-content-center"> <i className=" fas fa-list-ol fa-3x mr-3"></i>Asistidos Terminados <br/>{countFinish} </div></td>
                                        </tr>
                                    </tbody>
                                    </table>
                                </div>
                            }
                        </div>
                        <div>
                            {!showDash &&
                                <div className="col-xs-12 col-sm-12 col-md-12 table-responsive">
                                    <table class="table table-bordered table-striped">
                                    <tbody>
                                        
                                        <tr>
                                            <td className='text-center text-white bg-primary' colspan="6"  style={{cursor: 'pointer'}} onClick={ () => cambioPrueba(1)}><div class="d-flex justify-content-center"> <i className=" fas fa-check fa-3x mr-3"></i>Venta Autorizadas <br/>{countAuthorized} </div></td>  
                                            <td className='text-center text-dark bg-warning align-middle  ' rowSpan={3} colspan="2"  style={{cursor: 'pointer'}} onClick={ () => cambioPrueba(2)} ><div class="d-flex justify-content-center"> <i className=" fas fa-lock fa-3x m-2 md-mr-3"></i>Venta en Proceso de Autorización <br/>{countNotAuthorized} </div></td>
                                            <td className='text-center text-white bg-secondary align-middle' rowSpan={3} colspan="2"  style={{cursor: 'pointer'}} onClick={ () => cambioPrueba(3)} ><div class="d-flex justify-content-center"> <i className=" fas fa-address-card fa-3x mr-3"></i>Pre-registro desde App <br/>{countNotSale} </div></td>
                                            <td className='text-center text-white bg-success align-middle' rowSpan={3} colspan="2"  style={{cursor: 'pointer'}} onClick={ () => cambioPrueba(8)} ><div class="d-flex justify-content-center"> <i className=" fas fa-user-tag fa-3x mr-3"></i>Autogestión<br/>{selfManagement} </div></td>
                                        </tr>
                                        <tr>
                                            <td className='text-center text-white bg-danger align-middle' rowSpan={2} colspan="2"  style={{cursor: 'pointer'}} onClick={ () => cambioPrueba(5)} ><div class="d-flex justify-content-center"> <i className=" fas fa-user-lock fa-3x m-2"></i>Pendiente activación App <br/>{countNotActive} </div></td>
                                        </tr>
                                        <tr>
                                            <td className='text-center text-dark bg-light' colspan="2"   style={{cursor: 'pointer'}} onClick={ () => cambioPrueba(6)} ><div class="d-flex justify-content-center"> <i className=" fas fa-book fa-3x mr-3"></i>Asistidos Activos <br/>{countAssited} </div></td>
                                            <td className='text-center text-white bg-dark' colspan="2"  style={{cursor: 'pointer'}} onClick={ () => cambioPrueba(7)} ><div class="d-flex justify-content-center"> <i className=" fas fa-list-ol fa-3x mr-3"></i> Asistidos Terminados <br/>{countFinish} </div></td>
                                        </tr>
                                        <tr colSpan="12" rowSpan={2}> <br/></tr>
                                        <tr className='mt-4'>
                                            <td className='text-center text-dark bg-warning align-middle' data-toggle="tooltip" data-placement="bottom" title="Declarasiones" colspan="12" style={{cursor: 'pointer',BorderTopRounded:"6px"}} ><div class="d-flex justify-content-center">  <i className="fas fa-layer-group fa-3x mr-3"></i> Numero de declaraciones Adquiridas <br/> { dashToPresented } </div></td>
                                        </tr>
                                        <tr className='mt-4'>
                                            <td className='text-center text-white bg-success align-middle' data-toggle="tooltip" data-placement="bottom" title="Declaraciones presentadas" colspan="6" style={{cursor: 'pointer',BorderTopRounded:"6px"}} ><div class="d-flex justify-content-center">  <i className="fas fa-check fa-3x mr-3"></i> Presentadas <br/>{  dashPresented } </div></td>
                                            <td className='text-center text-white bg-secondary align-middle' data-toggle="tooltip" data-placement="bottom" title="Declaraciones pendientes por presentar" colspan="6" style={{cursor: 'pointer',BorderTopRounded:"6px"}} ><div class="d-flex justify-content-center">  <i className="fas fa-plus fa-3x mr-3"></i> Pendientes por presentar <br/>{ dashTotal } </div></td>
                                        </tr>
                                        <tr colSpan="12" rowSpan={2}> <br/></tr>
                                        <tr className='mt-4'>
                                            <td className='text-center text-white bg-info align-middle' data-toggle="tooltip" data-placement="bottom" title="Usuarios registrados en contabox" colspan="12" style={{cursor: 'pointer',BorderTopRounded:"6px"}} onClick={ () => cambioPrueba(9)}><div class="d-flex justify-content-center">  <i className="fas fa-hand-holding-usd fa-3x mr-3"></i>Usuarios Activos <br/>{ countAssited+selfManagement+countNotActive+countNotAuthorized} </div></td>
                                        </tr>
                                    </tbody>
                                    </table>
                                </div>
                            }
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-12 row">
                            <div className="col-xs-12 col-sm-12 col-md-6">
                                <h4>{nameFilter}</h4>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-6">
                                 <div className="col-xs-12 "> 
                                    <InputSearching
                                    columnsToSearch={ [ 'email', 'fullName', 'rfc' ] }
                                    setDataChange={ setCustomersSearch }
                                    originals={ usersCustomers }
                                    />
                                </div>
                                <div className='text-right col-xs-12 mt-2'>    
                                        <CSVLink data={customersSearch} headers={headers} filename={nameFilter+'.csv'} className="btn btn-success">
                                            Descargar CSV
                                        </CSVLink>
                                </div>
                            </div>
                         </div>
                    </div>
                </div>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2">
                <div className="card">
                    <div className="card-body">
                        <Pagination
                            data={customersSearch}
                            page={page}
                            rowsPerPage={rowsPerPage}
                            setPage={setPage}
                            setRowsPerPage={setRowsPerPage}
                        />
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                         <TableCell className='text-center'>ID</TableCell>
                                        <TableCell className='text-center'>Nombre</TableCell>
                                        <TableCell className='text-center'>Correo</TableCell>
                                        <TableCell className='text-center'>Fecha Registro</TableCell>
                                        <TableCell className='text-center'>Venta Autorizada</TableCell>
                                        <TableCell className='text-center'>Cuenta Activa</TableCell>
                                        <TableCell className='text-center'>Producto Asistido</TableCell>
                                        <TableCell className='text-center'>Status</TableCell>
                                        <TableCell className='text-center'>Venta Producto</TableCell>
                                        <TableCell className='text-center'>Vendedor</TableCell>
                                        <TableCell className='text-center'>Operaciones</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                    customersSearch.length === 0 ?
                                    (<TableRow>
                                        <TableCell colSpan="10">
                                        <div className="alert alert-info text-center" role="alert">
                                            No hay Clientes
                                        </div>
                                        </TableCell>
                                    </TableRow>)
                                    :
                                    (
                                        customersSearch.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((userGuest, index) => {
                                        return(
                                            <TableRow key={index}>
                                            <TableCell>{userGuest.id}</TableCell>
                                            <TableCell align="center">
                                               {userGuest.fullName}<br/>
                                               {userGuest.rfc}
                                            </TableCell>
                                            <TableCell>
                                                {userGuest.email}<br/>
                                                {userGuest.phoneMobile}
                                            </TableCell>
                                             <TableCell align="center">
                                                { userGuest.register ? 
                                                    (
                                                        moment(userGuest.register).format('YYYY-MM-DD')
                                                    )
                                                    :
                                                    (
                                                        'Sin fecha'
                                                    )
                                                }
                                            </TableCell>
                                            <TableCell align="center">
                                             { userGuest.saleAuthorized !== 'Sin venta' ?
                                                (
                                                    userGuest.saleAuthorized === 'SI' ? (
                                                        <>
                                                        <span className="badge badge-success">SI</span> <br/>
                                                           {moment(userGuest.saleActive).format('YYYY-MM-DD') }
                                                        </>
                                                    ):(
                                                        <span className="badge badge-danger">NO</span>
                                                    )
                                                )
                                                :
                                                (
                                                    <span className="badge badge-secondary">Sin venta</span>
                                                )
                                            }
                                            </TableCell>
                                            <TableCell align="center">
                                             { userGuest.isActive !== 'Sin Venta' ?
                                                (
                                                    userGuest.isActive === 'SI' ?
                                                    (
                                                        <span className="badge badge-success">SI</span> 
                                                    )
                                                    :
                                                    (
                                                        <span className="badge badge-danger">NO</span>
                                                    )
                                                )
                                                :
                                                (
                                                    userGuest.saleAuthorized === 'NO' ? 
                                                    (
                                                        <span className="badge badge-danger">Sin Autorizar</span>
                                                    ):(
                                                        <span className="badge badge-secondary">Sin Venta</span>
                                                    )
                                                )
                                            }
                                            </TableCell>
                                            <TableCell align="center">
                                             { userGuest.productIsAssisted !== 'Sin Venta' ?
                                                (
                                                    userGuest.productIsAssisted === 'SI' ?
                                                    (
                                                        <span className="badge badge-success">SI</span> 
                                                    )
                                                    :
                                                    (
                                                        <span className="badge badge-danger">NO</span>
                                                    )
                                                )
                                                :
                                                (
                                                    userGuest.productIsAssisted !== 'Sin Venta' ? 
                                                    (
                                                        <span className="badge badge-danger">Sin Autorizar</span>
                                                    ):(
                                                        <span className="badge badge-secondary">Sin Venta</span>
                                                    )
                                                )
                                            }
                                            </TableCell>
                                            <TableCell align="center">
                                             { userGuest.isFinish !== 'Sin Venta' ?
                                                (
                                                    userGuest.isFinish === 'Activo' ?
                                                    (
                                                        <span className="badge badge-success">Activo</span> 
                                                    )
                                                    :
                                                    (
                                                        <span className="badge badge-danger">{ userGuest.isFinish}</span>
                                                    )
                                                )
                                                :
                                                (
                                                    userGuest.saleAuthorized === 'NO' ? 
                                                    (
                                                        <span className="badge badge-danger">Sin Autorizar</span>
                                                    ):(
                                                        <span className="badge badge-secondary">Sin Venta</span>
                                                    )
                                                )
                                            }
                                            </TableCell>
                                            <TableCell align="center">
                                            { userGuest.saleProduct !== 'Sin venta' ?
                                                (
                                                 userGuest.saleProduct
                                                )
                                                :
                                                (
                                                    <span className="badge badge-secondary">Sin venta</span>
                                                )
                                            }
                                            </TableCell>
                                            <TableCell align="center">
                                            { userGuest.saleSeller !== 'Sin venta' ?
                                                (
                                                    userGuest.saleSeller
                                                )
                                                :
                                                (
                                                    <span className="badge badge-secondary">Sin venta</span>
                                                )
                                            }
                                            </TableCell> 
                                            <TableCell align="center">
                                                {isBoss &&
                                                <button
                                                    data-toggle="tooltip"
                                                    data-placement="top"
                                                    title="Editar Usuario"
                                                    className="btn btn-sm btn-outline-primary px-2"
                                                    onClick={ () => handleModal(
                                                    <UserForm 
                                                        userGuest={ userGuest }
                                                    />
                                                    )}
                                                >
                                                    <FontAwesomeIcon className="fa-lg" icon={"pencil-alt"}/>
                                                </button>
                                                }
                                            </TableCell>
                                            </TableRow>
                                        )
                                        })
                                    )
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Pagination
                            data={customersSearch}
                            page={page}
                            rowsPerPage={rowsPerPage}
                            setPage={setPage}
                            setRowsPerPage={setRowsPerPage}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ShowCustomers;
