import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { localTaxeAddToList } from '../../../../../actions/invoices/localTaxes';
import { TableLocalTaxes } from './TableLocalTaxes';

export const LocalTaxesDetailsScreen = () => {

  const dispatch = useDispatch();
  const { localTaxeTypes } = useSelector(state => state.localTaxe);

  const handleAddLocalTaxeToList = (e) => {
    e.preventDefault();
    dispatch(localTaxeAddToList({
      description: '',
      rate: 0,
      localTaxeType: localTaxeTypes[0],
      amount: 0,
      id: new Date().getTime()
    }))
  }

  return (
    <div className="col-md-12">
      <h5 className="card-header">Impuestos locales</h5>
      <div className="card-body">

        <button
          className="btn btn-primary mb-3"
          onClick={ handleAddLocalTaxeToList }  
        >
          Agregar impuesto local 
        </button>
  
        <div className="row">

          <TableLocalTaxes
            localTaxeTypes={ localTaxeTypes }
          />
        
        </div>
      </div>
    </div>
  )
}
