const initialState = {
  expenseDepresiationTypes: [
    {
      depresiationMonths: 240,
      depresiationYears: 20,
      expenseKey: 'EDIFICIOS',
      maxAmount: null,
      porcentage: 0.05,
      yearInitial: 2011,
      yearFinal: null,
    },
    {
      depresiationMonths: 120,
      depresiationYears: 10,
      expenseKey: 'MOBILIARIO',
      maxAmount: null,
      porcentage: 0.1,
      yearInitial: 2011,
      yearFinal: null,
    },
    {
      depresiationMonths: 48,
      depresiationYears: 4,
      expenseKey: 'AUTO',
      maxAmount: 175000.00,
      porcentage: 0.25,
      yearInitial: 2011,
      yearFinal: null,
    },
    {
      depresiationMonths: 48,
      depresiationYears: 4,
      expenseKey: 'AUTOELECTRICO',
      maxAmount: 250000.00,
      porcentage: 0.25,
      yearInitial: 2011,
      yearFinal: null,
    },
    {
      depresiationMonths: 40,
      depresiationYears: 3,
      expenseKey: 'COMPU',
      maxAmount: null,
      porcentage: 0.3,
      yearInitial: 2011,
      yearFinal: null,
    },
    {
      depresiationMonths: 120,
      depresiationYears: 10,
      expenseKey: 'MAQUINARIA',
      maxAmount: null,
      porcentage: 0.1,
      yearInitial: 2011,
      yearFinal: null,
    },
    {
      depresiationMonths: 240,
      depresiationYears: 20,
      expenseKey: 'GINSTALA',
      maxAmount: null,
      porcentage: 0.05,
      yearInitial: 2011,
      yearFinal: null,
    },
    {
      depresiationMonths: 240,
      depresiationYears: 20,
      expenseKey: 'GPREOPERA',
      maxAmount: null,
      porcentage: 0.05,
      yearInitial: 2011,
      yearFinal: null,
    },
  ]
}

export const expenseDepresiationReducer = (state = initialState, action) => {
  switch (action.type) {
  
    default:
      return state;
  }
}
